import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectTemplateData, projectsActions } from 'src/old-app/store/projectsSlice'
import { getTaskTypes } from 'src/old-app/store/tasksSlice'
import tasksStructure from 'src/old-app/utils/tasksStructure'
import TaskListItemWithSubTasks from 'src/old-app/main/tasks/TaskListItemWithSubTasks'
import { useParams } from 'react-router-dom'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import Typography from '@mui/material/Typography'
import { Button, Popover } from '@mui/material'
import { useGetUser } from '../../../../shared/hooks'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const _commonID = getID({ entity: 'project-template' })

const ProjectTemplateTasks = () => {
	const { t } = useTranslation()
	const { templateID } = useParams()
	const dispatch = useDispatch()
	const taskTypes = useSelector(getTaskTypes)
	const projectTempalteData = useSelector(getProjectTemplateData)

	const user = useGetUser()
	const isAdmin = useSelector(getAdminRole)
	const isCanEdit = isAdmin || projectTempalteData?.author_view?.id === user?.id

	useLayoutEffect(() => {
		if (projectTempalteData) {
			const users = projectTempalteData?.template?.Users ?? []
			const statuses = projectTempalteData?.template?.Statuses ?? []
			const priorities = projectTempalteData?.template?.Priorities ?? []

			const newProjectTempalteTasks = projectTempalteData?.template?.Tasks?.map((task) => {
				const type = taskTypes?.find((item) => item.id === task?.type_id)
				const assignee = users?.find((item) => item?.user_id === task?.assignee_id)?.user
				const priority = priorities?.find((item) => item.id === task?.task_priority_id)
				const status = statuses?.find((item) => item.id === task?.actual_status_id)

				const deadline = task?.actual_deadline
				const newTask = {
					...task,
					type,
					assignee,
					priority,
					task_status: { project_status: status, ...status },
					deadline,
				}

				return newTask
			})
			dispatch(
				projectsActions.getProjectTemplateData({
					...projectTempalteData,
					template: { ...projectTempalteData?.template, Tasks: newProjectTempalteTasks },
				})
			)
		}
	}, [taskTypes])

	const preparedTasks = useMemo(
		() => tasksStructure(JSON.parse(JSON.stringify(projectTempalteData?.template?.Tasks ?? []))) ?? [],
		[projectTempalteData?.template?.Tasks]
	)

	const onDeleteClick = (ev, task) => {
		ev.preventDefault()
		setAnchorEl(ev.currentTarget)
		setToDeleteTask(task)
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [toDeleteTask, setToDeleteTask] = useState(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	function closeDeletePopover() {
		setAnchorEl(null)
	}

	const [toDeleteTaskChildrenCount, isSubtasksIncluded, toDeleteTasksIDS] = useMemo(() => {
		if (!toDeleteTask) {
			return [null, null, null]
		}

		const haveSubTasks = Boolean(toDeleteTask?.child?.length)
		const toDeleteTasksIDS = []

		if (!haveSubTasks) {
			toDeleteTasksIDS.push(toDeleteTask.id)
		}

		function getChildrenCount() {
			function getAllChildrenCount(task) {
				toDeleteTasksIDS.push(task.id)

				return (
					task?.child?.reduce((count, subtask) => {
						return count + 1 + getAllChildrenCount(subtask)
					}, 0) || 0
				)
			}
			return getAllChildrenCount(toDeleteTask)
		}

		return [haveSubTasks ? getChildrenCount() : null, haveSubTasks, toDeleteTasksIDS]
	}, [toDeleteTask])

	const deleteTasks = (tasks, head) => {
		tasks.map((task) => {
			tasks = tasks.filter((task) => task.id !== head)
			if (task.parent_id == head) {
				tasks = tasks.filter((task) => task.parent_id !== head)
				tasks = deleteTasks(tasks, task.id)
			}
		})

		return tasks
	}

	function onTaskDelete() {
		const filteredTasks = deleteTasks(projectTempalteData?.template?.Tasks, toDeleteTask?.id)
		dispatch(projectsActions.putProjectTemplateTasks(filteredTasks))
		closeDeletePopover()
	}

	return (
		<div className={styles['project-template-tasks']}>
			<div className="tt-overflow-auto">
				<table className="tt-w-full tt-overflow-hidden">
					{preparedTasks?.map((item, index) => (
						<TaskListItemWithSubTasks
							id={_commonID}
							index={index}
							hideSkelet={{ deadline: true, project: true }}
							activeClassName="tt-bg-primary/10 hover:tt-bg-primary/10"
							url={`/projects-templates/${templateID}/`}
							accordionSx={{ paddingBottom: '8px' }}
							className="hover:tt-bg-primary/10"
							projectId={'project-id'}
							key={item.id}
							data={item}
							rightRender={
								isCanEdit
									? (task, _index) => (
											<HighlightOffOutlinedIcon
												id={getID({
													prefix: _commonID,
													action: 'delete',
													type: 'button_open',
													index: _index,
												})}
												onClick={(ev) => {
													onDeleteClick(ev, task)
												}}
												className={'tt-text-gray-500 hover:tt-text-primary'}
											/>
									  )
									: undefined
							}
						/>
					))}
					{isCanEdit && (
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={closeDeletePopover}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
						>
							<Typography sx={{ p: 2 }} maxWidth={'20em'} align={'center'}>
								{t('delete_task_template_dialog.header')}
								<Typography fontWeight={'bold'} sx={{ p: 1 }}>
									{toDeleteTask?.title}
								</Typography>
								{isSubtasksIncluded && (
									<Typography fontWeight={'bold'} color={'error'} sx={{ p: 1 }}>
										{t('delete_task_template_dialog.annotation', { value: toDeleteTaskChildrenCount })}
									</Typography>
								)}
								<Button
									type={'button'}
									size={'small'}
									id={getID({
										prefix: _commonID,
										action: 'confirm',
										type: 'button_delete',
									})}
									onClick={onTaskDelete}
									variant={'outlined'}
									color={'error'}
								>
									{t('delete')}
									{isSubtasksIncluded && t('types.epic')}
								</Button>
							</Typography>
						</Popover>
					)}
				</table>
			</div>
		</div>
	)
}

export default ProjectTemplateTasks
