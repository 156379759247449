import { UserShow } from 'src/shared/components'
import ProjectProtocolsTableCell from './ProjectProtocolsTableCell'
import styles from './ProjectProtocolsTableRow.module.css'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useMemo } from 'react'
import EllipsisTextLine from 'src/@shared/lib/EllipsisTextLine'
import dayjs from 'dayjs'

const ProjectProtocolsTableRow = ({ columnsSettings, protocolData, isChoosen = false }) => {
	const navigateTo = useNavigate()

	const sameStartAndFinishDate =
		protocolData?.meeting_date_start?.substring(0, 10) === protocolData?.meeting_date_end?.substring(0, 10)

	const committeeFormattedDate = `${dayjs(protocolData?.meeting_date_start).format('DD.MM.YYYY HH:mm')}-${
		sameStartAndFinishDate
			? dayjs(protocolData?.meeting_date_end).format('HH:mm')
			: dayjs(protocolData?.meeting_date_end).format('DD.MM.YYYY HH:mm')
	}`

	const columnsComponents = useMemo(
		() => ({
			// hid: {
			// 	component: <EllipsisTextLine>{protocolData?.hid}</EllipsisTextLine>,
			// },
			topic: {
				component: <EllipsisTextLine>{protocolData?.topic}</EllipsisTextLine>,
			},
			committee_time: {
				component: (
					<EllipsisTextLine>
						<span className="tt-text-gray-600">{committeeFormattedDate}</span>
					</EllipsisTextLine>
				),
			},
			protocol_created_at: {
				component: (
					<EllipsisTextLine>
						<span className="tt-text-gray-600">{dayjs(protocolData?.created_at).format('DD.MM.YYYY HH:mm')}</span>
					</EllipsisTextLine>
				),
			},
			author: {
				component: <UserShow user={protocolData?.creator_view} />,
			},
		}),
		[protocolData]
	)

	const onRowClickHandler = () => {
		if (!isChoosen) {
			navigateTo(`pr/${protocolData?.id}`)
		}
	}

	return (
		<div key={protocolData?.id} className={styles.row_wrapper}>
			<div
				className={[styles.row, isChoosen ? styles.row_choosen : styles.row_active].join(' ')}
				onClick={onRowClickHandler}
			>
				{Object.entries(columnsComponents).map((columnsComponent) => {
					const columnID = columnsComponent[0]
					return (
						<ProjectProtocolsTableCell
							key={columnID}
							canGrow={columnsSettings[columnID]?.canGrow}
							basicWidth={columnsSettings[columnID]?.basicWidth}
							component={columnsComponents[columnID]?.component}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ProjectProtocolsTableRow
