import React, { useEffect } from 'react'
import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import TaskSideBarCheck from 'src/old-app/components/task/TaskSideBarCheck'
import TaskSideBar from 'src/old-app/components/task/TaskSideBar'
import LinkMyProject from './LinkMyProject'
import { AllMyTasksList } from './AllMyTasksList'
import ExportMyAllTasksKPG from '../../components/dialogs/my/ExportAllUserTasksKPG'
import { useLocation, useSearchParams } from 'react-router-dom'
import TasksProvider from '../tasks/TasksProvider'
import { getID } from '../../../shared/config/fields-ids'
import TaskAcceptButton from '../../../entities/task/components/task-accept-button'
import { TaskIncomingToggle } from 'src/@features/task'
import { ViewStatus } from '../tasks/TaskRow'
import { interfaceActions } from '../../store/interfaceSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetUser } from 'src/shared/hooks'

const _commonID = getID({ entity: 'logged_user_tasks' })

const My = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { search } = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const isTasksIncoming = (searchParams.get('tasks_incoming') ?? 'false') === 'true'
	const currentUser = useGetUser()

	useEffect(() => {
		if (isTasksIncoming) {
			dispatch(interfaceActions.setIsOpenEpic(false))
		}
	}, [isTasksIncoming])

	return (
		<PageWithSideBar
			id={_commonID}
			param="taskId"
			hideCreateButton
			pageLink="my"
			headerTitle={t('my_tasks')}
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			content={
				<AllMyTasksList
					isMyWorkspace={true}
					statusSlot={(data) =>
						data?.incoming && data?.assignee_id === currentUser?.id ? (
							<TaskAcceptButton label={t('accept_task')} />
						) : (
							<ViewStatus status={data?.task_status} />
						)
					}
					taskParams={
						isTasksIncoming
							? {
									incoming: true,
									for_board: true,
									only_epic: undefined,
							  }
							: {}
					}
					hideSkelet={
						isTasksIncoming
							? {
									assignee: true,
									project: false,
									complete_date: true,
									leftDisabled: true,
									order_sort: false,
							  }
							: {
									assignee: true,
									project: false,
									complete_date: true,
									order_sort: false,
							  }
					}
					id={_commonID}
				/>
			}
			headerTopRightLeft={
				<div className="tt-flex tt-items-center tt-gap-2">
					<TaskIncomingToggle />
					{<ExportMyAllTasksKPG isTasksIncoming={isTasksIncoming} id={_commonID} allProjectsMode />}
					<LinkMyProject id={_commonID} label={t('personal_tasks')} />
				</div>
			}
			rightSidebarContent={
				<TaskSideBarCheck>
					<TasksProvider skeletonType="tasks" forMyTask={true}>
						<TaskSideBar id={_commonID} backTo={`/my${search}`} onlyMyTasks />
					</TasksProvider>
				</TaskSideBarCheck>
			}
		/>
	)
}

export default My
