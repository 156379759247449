import { Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getID } from 'src/shared/config/fields-ids'
import { uuid } from 'src/shared/lib/utils'

interface FileInputProps {
	onChange: (files: any[]) => void
	multiple?: boolean
	value?: any[]
}

export const FileInput: React.FC<FileInputProps> = ({ onChange, multiple, value = [] }) => {
	const { t } = useTranslation()
	
	const onChangeHandler = (event: any) => {
		const files = [...event.target.files]
		const mapFiles = files.map(file=> ({ name: file?.name, data: file, id: uuid() }))
		onChange([...value, ...mapFiles])
	}

	const deleteFile = (fileId: string) => {
		onChange(value.filter(file=> file.id !== fileId))
	}

	return (
		<div className="tt-p-8 tt-text-center tt-gap-12  tt-cursor-pointer tt-rounded-lg tt-border tt-border-dashed tt-border-gray-200 tt-flex tt-text-14 tt-flex-col">
			{value?.length !== 0 &&
				<div className='tt-flex tt-flex-wrap tt-gap-4 tt-w-full'>
					{value?.map((file) => (
						<Chip key={file.id} label={file?.name} variant="outlined" onDelete={(event) => {
							event.stopPropagation()
							deleteFile(file.id)
						}} />
					))}
				</div>
			}
			<label className='tt-w-full'>
				<div className='tt-bg-primary/10 tt-rounded-full tt-cursor-pointer tt-px-8 tt-py-4 tt-w-full'>{t('choose_files')}</div>
				<input
					id={getID({ prefix: '1', field: 'files_add', type: 'input_file' })}
					multiple={multiple}
					type="file"
					hidden
					onChange={onChangeHandler}
				/>
			</label>
		</div>
	)
}
