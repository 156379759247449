/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Badge } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({ label, icon, link, onClick, badgeContent, activeIcon, id }) => {
	const [hover, setHover] = useState(false)

	const onMouseEnterHandler = () => {
		setHover(true)
	}
	const onMouseLeaveHandler = () => {
		setHover(false)
	}

	return (
		<NavLink
			to={link}
			id={id}
			onMouseEnter={onMouseEnterHandler}
			onMouseLeave={onMouseLeaveHandler}
			className={({ isActive }) => {
				return [
					'tt-h-40 tt-min-h-40 tt-max-h-40 tt-hover tt-cursor-pointer tt-w-full  tt-duration-150 tt-flex tt-items-center tt-justify-center tt-relative',
					isActive ? 'tt-text-primary tt-bg-primary/10' : 'tt-text-gray-600 hover:tt-text-black ',
				].join(' ')
			}}
		>
			{({ isActive }) => (
				<>
					<Badge
						classes={{ badge: 'tt-bg-primary tt-text-white' }}
						badgeContent={badgeContent}
						className="tt-w-full  tt-block tt-text-center"
					>
						{icon}
					</Badge>

					{isActive && <div className="tt-w-4 tt-h-full tt-bg-primary tt-absolute tt-left-0 tt-top-0" />}

					{hover && (
						<div
							className={[
								'tt-absolute tt-whitespace-nowrap  tt-left-68  tt-duration-200 tt-top-4 tt-h-32 tt-bg-white tt-shadow-sm tt-border-gray-300 tt-px-10 tt-flex  tt-items-center  tt-border tt-rounded-full',
							].join(' ')}
						>
							{label}
						</div>
					)}
				</>
			)}
		</NavLink>
	)
}

export default MenuItem
