import React from 'react'
import styles from './styles.module.scss'
import { TaskIncomingTable } from 'src/features/task'
import TaskSideBarCheck from '../../../old-app/components/task/TaskSideBarCheck'
import TaskSideBar from '../../../old-app/components/task/TaskSideBar'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import { taskPaths } from '../config'
import { useLocation } from 'react-router-dom'
import TasksProvider from 'src/old-app/main/tasks/TasksProvider'
import { getID } from '../../../shared/config/fields-ids'

const _commonID = getID({ entity: 'incoming_task' })

const TaskIncoming = () => {
	const { search } = useLocation()
	const backTo = '/' + taskPaths.TASK_INCOMING + search

	return (
		<PageWithSideBar
			id={_commonID}
			param="taskId"
			hideCreateButton
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			pageLink={taskPaths.TASK_INCOMING}
			headerTitle="Входящие задачи"
			content={<TaskIncomingTable id={_commonID} />}
			rightSidebarContent={
				<TaskSideBarCheck>
					<TasksProvider>
						<TaskSideBar id={_commonID} backTo={backTo} />
					</TasksProvider>
				</TaskSideBarCheck>
			}
		/>
	)
}

export default TaskIncoming
