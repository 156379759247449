import { MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateUserProjectMutation } from 'src/old-app/service/Projects'
import { UserShow } from 'src/shared/components'
import { getProject, getProjectsUsers } from 'src/old-app/store/projectsSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTeamShow } from '../../hooks'
import { useUpdateTeamUserMutation } from '../../../../old-app/service/Teams'
import { useTranslation } from 'react-i18next'

export const HeadTableCell = ({ name, className, align = 'left', sx, onClick, sort }) => {
	return (
		<TableCell
			onClick={onClick}
			sx={sx}
			className={[
				'tt-px-16  tt-py-6 tt-text-gray-700 tt-font-semibold tt-border-y  tt-border-gray-200',
				className,
			].join(' ')}
			align={align}
		>
			<span className="tt-flex tt-items-center tt-gap-4">
				{name}
				<div className="tt-w-14">
					{sort && (
						<svg
							className={[
								'tt-w-14  tt-fill-gray-600 tt-stroke-gray-600  tt-ml-5',
								sort === 'desc' ? 'tt-rotate-0' : 'tt-rotate-180',
							].join(' ')}
							viewBox="0 0 24 24"
						>
							<path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
						</svg>
					)}
				</div>
			</span>
		</TableCell>
	)
}
export const BodyTableCell = ({ children, className, align = 'left', sx }) => {
	return (
		<TableCell
			id="project-name"
			sx={sx}
			className={['tt-px-16 tt-py-8  tt-border-b tt-border-gray-200', className].join(' ')}
			align={align}
		>
			{children}
		</TableCell>
	)
}

const Roles = ({ user, isDisabled, teamId }) => {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	const [updateTeamUser] = useUpdateTeamUserMutation()
	const [alignment, setAlignment] = useState('USER')

	const handleChange = async (event) => {
		const { value } = event.target
		const confirms = value === 'USER' ? false : undefined
		await updateTeamUser({
			id: teamId,
			body: {
				user_id: user.user_id,
				command_id: teamId,
				role: value,
				confirms,
			},
		})
		setAlignment(value)
	}

	useEffect(() => {
		setAlignment(user.role)
	}, [user])

	return (
		<div className="text-12">
			<Select
				disabled={isDisabled}
				labelId="demo-select-small"
				// id="demo-select-small"
				value={alignment}
				size="small"
				className="dialig-input"
				label={null}
				onChange={handleChange}
			>
				<MenuItem id={getID({ type: 'select_item', index: 0 })} value="USER">
					{t('roles.user')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value="CONFIRMING_USER">
					{t('confirms_the_project')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 2 })} value="ADMIN">
					{t('roles.administrator')}
				</MenuItem>
			</Select>
		</div>
	)
}

const Confirm = ({ user, isDisabled, id, index, teamId }) => {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	const [updateTeamUser] = useUpdateTeamUserMutation()
	const [alignment, setAlignment] = useState(false)

	const handleChange = async (event) => {
		const { value } = event.target

		await updateTeamUser({
			id: teamId,
			body: {
				user_id: user.user_id,
				command_id: teamId,
				confirms: value,
			},
		})
		setAlignment(value)
	}

	useEffect(() => {
		setAlignment(user.confirms)
	}, [user])

	return (
		<div>
			<Select
				disabled={isDisabled || user?.role === 'USER'}
				// labelId='demo-select-small'
				id={getID({ prefix: id, type: 'select', action: 'confirm', index })}
				value={alignment}
				size="small"
				className="dialig-input"
				label={null}
				onChange={handleChange}
			>
				<MenuItem id={getID({ type: 'select_item', index: 0 })} value={true}>
					{t('yes').toUpperCase()}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value={false}>
					{t('no').toUpperCase()}
				</MenuItem>
			</Select>
		</div>
	)
}

const TeamRoles = ({ isDisabled }) => {
	const { t } = useTranslation()
	const { team } = useTeamShow()
	const users = team?.users ?? []

	return (
		<div>
			<TableContainer className="tt-rounded-lg tt-overflow-hidden tt-border-x tt-border-gray-200 tt-w-full">
				<Table>
					<TableHead className="tt-bg-white ">
						<TableRow>
							<HeadTableCell name={t('user')} />
							<HeadTableCell name={t('role')} />
							{/*<HeadTableCell name='Подтверждает' />*/}
						</TableRow>
					</TableHead>
					<TableBody>
						{users?.map((user, index) => {
							const isUserDisabled = team?.author_view?.id === user?.user_id
							return (
								<TableRow className=" hover:tt-bg-gray-100/50" key={user.user_id}>
									<BodyTableCell>
										<UserShow isShowEmail isShowMiddleName user={user.user} />
									</BodyTableCell>
									<BodyTableCell className="tt-w-224">
										<Roles teamId={team?.id} isDisabled={isUserDisabled || isDisabled} user={user} />
									</BodyTableCell>

									{/*<BodyTableCell className='tt-w-224'>*/}
									{/*    <Confirm teamId={team?.id} index={index} isDisabled={isDisabled} user={user} />*/}
									{/*</BodyTableCell>*/}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

export default TeamRoles
