import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isOpen: false,
	component: '',
	backComponent: '',
	componentProps: {},
	state: null,
}

const reducers = {
	setDialog: (state, action) => {
		state.isOpen = action.payload
	},
	setComponent: (state, action) => {
		state.backComponent = state.component
		state.component = action.payload
	},
	setDialogState: (state, action) => {
		state.state = action.payload
	},
}

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers,
})

export const getDialogIsOpen = (state) => state.dialog.isOpen
export const getDialogComponent = (state) => state.dialog.component
export const getDialogBackComponent = (state) => state.dialog.backComponent
export const getDialogState = (state) => state.dialog.state

export const dialogReducer = dialogSlice.reducer
export const dialogActions = dialogSlice.actions
