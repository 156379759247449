import { InputBase } from '@mui/material'
import { getPrioritys, getPUsers } from 'src/old-app/store/projectsSlice'
import { getStatuses, getTaskTypes } from 'src/old-app/store/tasksSlice'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProjectStatusSelect from 'src/old-app/components/task/ProjectStatusSelect'
import ProjectUsersSelect from 'src/old-app/components/task/ProjectUsersSelect'
import TaskSkelet from './TaskSkelet'
import TaskMoreFilters from 'src/old-app/components/task/TaskMoreFilters'
import FilterDeadline from 'src/old-app/components/task/FilterDeadline'
import TaskTypeSelect from 'src/old-app/components/task/TaskTypeSelect'
import TaskEpicSwitch from 'src/old-app/components/task/TaskEpicSwitch'
import TaskHashtagSelect from 'src/old-app/components/shared/TaskHashtagSelect'
import { UserProjectsSelect } from './UserProjectsSelect'
import { UserProjectsStatusesSelect } from './UserProjectsStatusesSelect'
import { UserProjectsPrioritiesSelect } from './UserProjectsPrioritiesSelect'
import ProjectPrioritySelect from '../../components/task/ProjectPrioritySelect'
import { getID } from '../../../shared/config/fields-ids'
import UsersSelect from '../../components/shared/UserSelect'
import { useTranslation } from 'react-i18next'
import { useGetUser } from 'src/shared/hooks';

const TaskFilters = ({
	hideStatus,
	hideSkelet,
	prefix = '',
	allProjectsMode,
	hideTaskParamsFilter,
	left,
	isRightFilterLabel,
	skeletClassName,
	id,
	isMyWorkspace,
	isTeamWorkspace,
	projectIds,
}) => {
	const { t } = useTranslation()
	const users = useSelector(getPUsers)
	const { projectId } = useParams()
	const user = useGetUser()
	const {pathname} = useLocation()

	const statuses = useSelector(getStatuses)
	const types = useSelector(getTaskTypes)
	const prioritys = useSelector(getPrioritys)
	const { search } = useLocation()
	const navigate = useNavigate()

	const [searchParams] = useSearchParams()
	const assignee = searchParams.get(`${prefix}assignee`) ?? []
	const author = searchParams.get(`${prefix}author`) ?? []
	const projects = searchParams.get(`${prefix}projects`) ?? []
	const searchTask = searchParams.get(`${prefix}searchTask`) ?? undefined
	const slug = searchParams.get(`${prefix}slug`) ?? undefined
	const [searchTaskText, setSearchTaskText] = useState(searchTask)
	const [slugText, setSlugText] = useState(slug)
	const priority = searchParams.get(`${prefix}priority`) ?? []
	const status = searchParams.get(`${prefix}status`) ?? []

	const type = searchParams.get(`${prefix}type`) ?? []
	const tags = searchParams.get(`${prefix}tags`) ?? []

	const [StatusSelect, PrioritySelect, MoreUsersSelect] = useMemo(
		() =>
			allProjectsMode
				? [UserProjectsStatusesSelect, UserProjectsPrioritiesSelect, UsersSelect]
				: [ProjectStatusSelect, ProjectPrioritySelect, ProjectUsersSelect],
		[allProjectsMode]
	)

	const assigneeChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}assignee`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}
	const authorChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}author`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	const projectsChange = (value) => {
		const params = new URLSearchParams(search)

		if (projects && value.id === projects) {
			params.delete(`${prefix}projects`)
		} else {
			params.set(`${prefix}projects`, value.id)
		}

		if (allProjectsMode) {
			params.delete(`${prefix}status`)
			params.delete(`${prefix}priority`)
		}
		navigate({
			search: params.toString(),
		})
	}

	const tagsChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}tags`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	const priorityChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}priority`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	const statusChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}status`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	const typeChange = (value) => {
		const ids = value.map((item) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(`${prefix}type`, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	const searchChange = (text) => {
		if (typeof text === 'undefined') return
		const params = new URLSearchParams(search)
		params.set(`${prefix}searchTask`, text)
		navigate({
			search: params.toString(),
		})
	}

	const slugChange = (text) => {
		if (typeof text === 'undefined') return
		const params = new URLSearchParams(search)
		params.set(`${prefix}slug`, text)
		navigate({
			search: params.toString(),
		})
	}

	useEffect(() => {
		const handler = setTimeout(() => {
			searchChange(searchTaskText)
		}, 300)

		return () => {
			clearTimeout(handler)
		}
	}, [searchTaskText])

	useEffect(() => {
		const handler = setTimeout(() => {
			slugChange(slugText)
		}, 300)

		return () => {
			clearTimeout(handler)
		}
	}, [slugText])

	const getPriorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	const priorityValue = allProjectsMode
		? getPriorityValue
		: getPriorityValue.map((item) => prioritys.find((p) => p.id === item))

	const getStatusValue = Array.isArray(status) ? status : JSON.parse(status)
	const statusValue = allProjectsMode
		? getStatusValue
		: getStatusValue.map((item) => statuses.find((p) => p.id === item))

	const getAssigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	const assigneeValue = allProjectsMode
		? getAssigneeValue
		: getAssigneeValue.map((item) => users.find((p) => p.id === item))

	const getAuthorValue = Array.isArray(author) ? author : JSON.parse(author)
	const authorValue = allProjectsMode ? getAuthorValue : getAuthorValue.map((item) => users.find((p) => p.id === item))

	const getTagsValue = Array.isArray(tags) ? tags : JSON.parse(tags)

	const getTypeValue = Array.isArray(type) ? type : JSON.parse(type)
	const typeValue = getTypeValue.map((item) => types.find((p) => p.id === item))

	const _commonID = getID({ prefix: id, action: 'filter' })

	return (
		<tr className="filters tt-px-6 tt-h-40 tt-py-4 tt-pt-[1px] tt-w-full tt-gap-6 tt-flex tt-flex-shrink-0 tt-flex-grow-0 tt-items-center">
			<TaskSkelet
				skeletClassName={skeletClassName}
				hideSkelet={hideSkelet}
				left={left ?? <TaskEpicSwitch disabled={hideSkelet?.leftDisabled} id={id} />}
				slug={
					<InputBase
						id={getID({ prefix: _commonID, field: 'slug', type: 'text' })}
						placeholder={t('slug')}
						className="new-select select-border tt-w-full tt-rounded-full tt-border  tt-px-12 tt-flex-1"
						value={slugText}
						onChange={(event) => setSlugText(event.target.value)}
					/>
				}
				right={
					<TaskMoreFilters
						prefix={prefix}
						id={id}
						hideSkelet={hideSkelet}
						isLabel={isRightFilterLabel}
						allProjectsMode={allProjectsMode}
						hideTaskParamsFilter={hideTaskParamsFilter}
						projectId={allProjectsMode ? projects?.toString() : projectId}
					/>
				}
				deadline={
					<FilterDeadline
						id={getID({ prefix: _commonID, field: 'deadline' })}
						// idStart={getID({prefix: _commonID, field: 'deadline_start' })}
						// idStart={getID({prefix: _commonID, field: 'deadline_end', fieldPostfix: '' })}
						label={t('deadline')}
						field="deadline"
						prefix={prefix}
					/>
				}
				complete_date={<FilterDeadline label={t('completion_date')} field="completed_at" prefix={prefix} />}
				type={
					<TaskTypeSelect
						selectClassName="new-select filter-select-miltipe"
						id={_commonID}
						wrapperClassName="w-auto"
						onChange={typeChange}
						value={typeValue}
						displayEmpty
						field="type"
						label={t('type')}
						hideError
						hideLabel
						multiple
					/>
				}
				priority={
					<PrioritySelect
						id={_commonID}
						requestProjectID={allProjectsMode ? projects : null}
						multiple
						selectClassName="new-select filter-select-miltipe"
						wrapperClassName="w-auto"
						hideError
						hideLabel
						displayEmpty
						field="priority"
						label={t('priority')}
						value={priorityValue}
						onChange={priorityChange}
					/>
				}
				status={
					!hideStatus && (
						<>
							{!hideSkelet?.status && (
								<StatusSelect
									id={_commonID}
									requestProjectID={allProjectsMode ? projects : null}
									multiple
									selectClassName="new-select tt-text-left filter-select-miltipe"
									wrapperClassName="tt-w-auto"
									hideError
									hideLabel
									displayEmpty
									field="status"
									label={t('status')}
									value={statusValue}
									onChange={statusChange}
								/>
							)}
						</>
					)
				}
				author={
					<MoreUsersSelect
						allProjectsMode={allProjectsMode}
						multiple
						selectClassName="new-select tt-text-left filter-select-miltipe"
						wrapperClassName="tt-w-auto"
						hideError
						displayEmpty
						hideLabel
						field="author"
						label={t('author')}
						value={authorValue}
						onChange={authorChange}
					/>
				}
				assignee={
					<MoreUsersSelect
						id={_commonID}
						allProjectsMode={allProjectsMode}
						multiple
						selectClassName="new-select tt-text-left filter-select-miltipe"
						wrapperClassName="tt-w-auto"
						hideError
						hideLabel
						displayEmpty
						field="user"
						label={t('performer')}
						value={assigneeValue}
						onChange={assigneeChange}
					/>
				}
				project={
					<UserProjectsSelect
						id={_commonID}
						selectClassName="new-select tt-text-left filter-select-miltipe"
						wrapperClassName="tt-w-auto"
						hideError
						hideLabel
						filters={{ confirmation_status: 'APPROVED,COMPLETED' }}
						displayEmpty
						field="project"
						label={t('project')}
						onChange={projectsChange}
						value={projects}
					/>
				}
				tags={
					<TaskHashtagSelect
						id={_commonID}
						projectId={projectId}
						multiple
						selectClassName="new-select tt-text-left filter-select-miltipe"
						wrapperClassName="tt-w-auto"
						hideError
						hideLabel
						hideAdd={isTeamWorkspace || isMyWorkspace}
						displayEmpty
						field="user"
						label={t('tags')}
						useInnerValue
						value={getTagsValue}
						onChange={tagsChange}
						projectIds={projectIds ?? []}
					/>
				}
				text={
					<InputBase
						id={getID({ prefix: _commonID, field: 'title', type: 'text' })}
						placeholder={t('title')}
						className="new-select select-border tt-w-full tt-rounded-full tt-border  tt-px-12 tt-flex-1"
						value={searchTaskText}
						onChange={(event) => setSearchTaskText(event.target.value)}
					/>
				}
			/>
		</tr>
	)
}

export default TaskFilters
