import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetProtocolsDecisionQuery } from 'src/old-app/service/Protocol'

const DecisionShow = memo(({ decision, index }) => {
	const { t } = useTranslation()
	return (
		<div>
			<div className="tt-flex tt-gap-10">
				<div className="tt-bg-gray-200  tt-p-10 tt-w-16 tt-h-16 tt-flex tt-items-center tt-justify-center tt-text-gray-800 tt-rounded-full">
					{index}
				</div>
				<div className="tt-flex tt-flex-col">
					<div className="tt-font-medium tt-text-xs -tt-mt-12 tt-text-gray-500">{t('decision')}</div>
					<div dangerouslySetInnerHTML={{ __html: decision?.decision }}  className="tt-font-medium tt-anywhere" />
				</div>
			</div>
		</div>
	)
})
DecisionShow.displayName = 'DecisionShow'
export default DecisionShow
