import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const taskFileAPI = createApi({
	reducerPath: 'tasks-file-api',
	tagTypes: ['TaskFile', 'Task'],
	baseQuery,
	endpoints: (build) => ({
		// getFilesTask: build.query({
		// 	query: (params) => ({
		// 		url: '/file-task/',
		// 		params,
		// 	}),
		// 	providesTags: (result) => ['File'],
		// }),
		createFileTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/file-task/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ({ data: { task_id } }) => [
				{ type: 'TaskFile', id: task_id },
				{ type: 'Task', id: task_id },
			],
		}),
		getTaskFiles: build.query({
			query: (params) => ({
				url: '/file-task/',
				params,
			}),
			providesTags: (result, _, { task_id }) => [
				{ type: 'TaskFile', id: task_id },
				// ...result.data.map(({ task_id }) => ({ type: 'TaskFile', id: task_id })),
			],
		}),
		deleteFileTask: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: '/file-task/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: (result, _, { task_id }) => [
				{ type: 'TaskFile', id: task_id },
				{ type: 'Task', id: task_id },
			],
		}),
	}),
})

export const { useGetTaskFilesQuery, useCreateFileTaskMutation, useDeleteFileTaskMutation } = taskFileAPI
