import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL } from 'src/shared/config'
import { getToken } from 'src/@entities/session'

import { baseQuery } from './base-query'

export const usersAPI = createApi({
	reducerPath: 'users-api',
	tagTypes: ['Users'],
	baseQuery,
	endpoints: (build) => ({
		getUsers: build.query({
			query: (params) => ({
				url: `/user/`,
				params,
			}),
			providesTags: (response) => [
				...response?.data?.map((user) => ({ type: 'Users', id: user.id }), { type: 'Users', id: 'List' }),
			],
		}),
		getUsersByIds: build.query({
			query: (id) => ({
				url: `/user/${id}/users`,
			}),
			providesTags() {
				const ids = arguments[2].split(',')
				return ids.map((id) => ({ type: 'Users', id }))
			},
		}),
		getOneUser: build.query({
			query: (id) => ({
				url: `/user/${id}`,
			}),
			providesTags() {
				const id = arguments[2]
				return [{ type: 'Users', id }]
			},
		}),
		getSearchUser: build.query({
			query: (params) => ({
				url: `/user/`,
				params,
			}),
			providesTags: (response) => response?.data?.map((user) => ({ type: 'Users', id: user.id })),
		}),
		updateUserLanguage: build.mutation({
			query: (lang) => ({
				method: 'PUT',
				url: '/user/lang',
				body: JSON.stringify({ lang: lang }),
			}),
		}),
	}),
})

export const getOneUser = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/user/${id}`, requestOptions).then((res) => res.json())
}

export const {
	useGetSearchUserQuery,
	useGetOneUserQuery,
	useGetUsersQuery,
	useGetUsersByIdsQuery,
	useUpdateUserLanguageMutation,
} = usersAPI
