/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react'
import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material'
import { getUsers } from 'src/old-app/store/userSlice'
import { useSelector } from 'react-redux'
import UsersSelect from '../../shared/UserSelect'
import SelectTaskPriority from '../../task/SelectTaskPriority'
import ProjectsSelect from '../../shared/ProjectsSelect'
import { Controller } from 'react-hook-form'
import ProjectUsersSelect from '../../task/ProjectUsersSelect'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { InputContainer, UsersChoice } from '../../../../shared/components'
import ProjectsChoice from '../../../../shared/components/projects-choice'
import TaskPriorityChoice from '../../../../shared/components/task-priority-choice'
import { useTranslation } from 'react-i18next'
import { ContractDirectorySelect } from 'src/@features/task'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

const DeleteButton = memo(({ removeHandler }) => {
	return (
		<div
			onClick={removeHandler}
			className="tt-h-42 tt-w-42 tt-flex tt-items-center tt-justify-center tt-cursor-pointer tt-mb-10"
		>
			<svg
				className="tt-stroke-gray-700"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M3.33331 5.83333H16.6666M15.8333 5.83333L15.1108 15.9517C15.0809 16.3722 14.8927 16.7657 14.5842 17.053C14.2758 17.3403 13.8699 17.5 13.4483 17.5H6.55165C6.1301 17.5 5.7242 17.3403 5.41572 17.053C5.10723 16.7657 4.91908 16.3722 4.88915 15.9517L4.16665 5.83333H15.8333ZM12.5 5.83333V3.33333C12.5 3.11232 12.4122 2.90036 12.2559 2.74408C12.0996 2.5878 11.8877 2.5 11.6666 2.5H8.33331C8.1123 2.5 7.90034 2.5878 7.74406 2.74408C7.58778 2.90036 7.49998 3.11232 7.49998 3.33333V5.83333H12.5Z"
					stroke="#94A3B8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	)
})
DeleteButton.displayName = 'DeleteButton'

const Decision = memo(({ index, item, changeHandler, removeHandler, errors }) => {
	const { t } = useTranslation()
	return (
		<div className="tt-flex tt-gap-16 tt-items-end">
			<div className="tt-flex tt-flex-col tt-w-full">
				<span className="tt-text-14 tt-font-semibold tt-mb-4">
					{t('decision')} №{index}
				</span>
				<TextField
					onChange={(event) => changeHandler(event, item)}
					value={item.value}
					placeholder={t('decision')}
					hiddenLabel
					className="dialig-input"
					id="filled-hidden-label-small"
					size="small"
				/>
				<div className="tt-h-10">
					{errors?.value && <p className="tt-text-12 tt-text-red-600 tt-pl-16">{errors?.value?.message}</p>}
				</div>
			</div>
			<DeleteButton removeHandler={() => removeHandler(item?.id)} />
		</div>
	)
})
Decision.displayName = 'Decision'

const Task = memo(
	({
		index,
		item,
		changeHandler,
		removeHandler,
		changeProjectHandler,
		toggleHasDeadlineHandler,
		changeContractDirectoryHandler,
		changePrioriryHandler,
		changeNeedReportHandler,
		errors,
		changeUsersHandler,
		changeDateHandler,
		changeTypeHandler,
		project,
	}) => {
		const { t } = useTranslation()
		const users = useSelector(getUsers)

		const onChangeHandler = (value) => {
			changeUsersHandler(value, item)
		}
		const onChangePriorityHandler = (value) => {
			changePrioriryHandler(value, item)
		}

		const onChangeNeedReportHandler = (value) => {
			changeNeedReportHandler(value, item)
		}

		const onChangeProjectsHandler = (value) => {
			changeProjectHandler(value, item)

			changeDateHandler('', item)
		}
		const onChangeContractDirectoryHandler = (value) => {
			changeContractDirectoryHandler(value, item)
		}

		const onChangeDateHandler = (dateValue) => {
			changeDateHandler(dayjs(dateValue).startOf('date').format(), item)
		}

		const isTask = item.type === 'task'

		useEffect(() => {
			if (!item?.project && project) {
				onChangeProjectsHandler(project)
			}
		}, [])

		const projectId = item?.project?.id

		const isContract = item?.project?.type?.name === 'contract'

		return (
			<div id={`pd-${index}`} className="tt-flex tt-flex-col tt-gap-10">
				<div className="tt-flex tt-gap-16 tt-items-end ">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">
							{t('decision')} №{index}
						</span>
						<TextField
							error={!!errors?.value?.message}
							onChange={(event) => changeHandler(event, item)}
							value={item.value}
							placeholder={t('task')}
							hiddenLabel
							className="dialig-input"
							id="filled-hidden-label-small"
							size="small"
						/>
						<div className="tt-h-10">
							{errors?.value && <p className="tt-text-12 tt-text-red-600 tt-pl-16">{errors?.value?.message}</p>}
						</div>
					</div>

					<DeleteButton removeHandler={() => removeHandler(item?.id)} />
				</div>
				<div className="tt-pr-54 ">
					<div className="tt-flex tt-gap-16 -tt-mb-5">
						<InputContainer label={t('responsibles')} error={errors?.users}>
							<UsersChoice
								placeholder={t('responsibles')}
								error={errors?.users}
								value={item?.users}
								onChange={onChangeHandler}
							/>
						</InputContainer>
					</div>
				</div>

				{isTask && (
					<>
						<div
							// className="pr-54 flex gap-16 duration-150 overflow-hidden "
							className="tt-pr-54 tt-grid tt-grid-cols-3  tt-gap-16 tt-w-full"
						>
							<InputContainer label={t('project')} error={errors?.project}>
								<ProjectsChoice placeholder={t('project')} value={item?.project} onChange={onChangeProjectsHandler} />
							</InputContainer>

							{isContract && (
								<InputContainer label={t('works_type')} error={errors?.contract_directory}>
									<ContractDirectorySelect
										placeholder={t('works_type')}
										onChange={onChangeContractDirectoryHandler}
										value={item.contract_directory}
									/>
								</InputContainer>
							)}

							{projectId && (
								<InputContainer label={t('priority')} error={errors?.priority}>
									<TaskPriorityChoice
										placeholder={t('priority')}
										projectId={projectId}
										value={item.priority}
										onChange={(val) => onChangePriorityHandler(val)}
									/>
								</InputContainer>
							)}

							{item?.hasDeadline && (
								<div className="tt-flex tt-flex-col ">
									<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('implementation_date')}</span>
									<DatePickerInput value={item.deadline} onChange={onChangeDateHandler} />
									<div className="tt-h-10">
										{errors?.deadline && (
											<p className="tt-text-12 tt-text-red-600 tt-pl-16">{errors?.deadline?.message}</p>
										)}
									</div>
								</div>
							)}
							{item?.project?.with_administration && (
								<InputContainer
									className="tt-col-span-3 tt-z-0"
									label={t('report_is_required')}
									error={errors?.need_report}
								>
									<FormControlLabel
										id="task-need_report"
										control={
											<Checkbox
												checked={item?.need_report}
												onChange={(event) => onChangeNeedReportHandler(event.target.checked)}
											/>
										}
										label={t('report_is_required')}
									/>
								</InputContainer>
							)}
						</div>

						<FormControlLabel
							className="tt-w-256 tt-z-0"
							control={
								<Checkbox
									checked={item?.hasDeadline}
									onChange={({ target }) => toggleHasDeadlineHandler(target.checked, item, index)}
								/>
							}
							label={t('implementation_date_is_known')}
						/>
					</>
				)}
			</div>
		)
	}
)

Task.displayName = 'Task'

const ProtocolDecision = memo(({ value, onChange, errors, setError, project }) => {
	const changeHandler = (event, item) => {
		const v = event.target.value
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.value = v
			}
			return val
		})
		onChange(newValue)
	}
	const changePrioriryHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.priority = v
			}
			return val
		})
		onChange(newValue)
	}

	const changeNeedReportHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.need_report = v
			}
			return val
		})
		onChange(newValue)
	}
	const changeTypeHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.type = val.type === 'task' ? 'decision' : 'task'
			}
			return val
		})
		onChange(newValue)
	}

	const changeProjectHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.project = v
				val.priority = null
			}
			return val
		})
		onChange(newValue)
	}

	const changeContractDirectoryHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.contract_directory = v
			}
			return val
		})
		onChange(newValue)
	}

	const changeDateHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.deadline = v
			}
			return val
		})
		onChange(newValue)
	}

	const changeUsersHandler = (v, item) => {
		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.users = v
				if (v.length === 0) {
					val.type = 'decision'
				} else {
					val.type = 'task'
				}
			}
			return val
		})
		onChange(newValue)
	}

	const toggleHasDeadlineHandler = (checked, item, i) => {
		if (checked) {
			changeDateHandler('', item)
		}

		const newValue = value.map((val) => {
			if (val?.id === item?.id) {
				val.hasDeadline = checked
			}
			return val
		})
		onChange(newValue)
	}

	const removeHandler = (id) => {
		const newValue = value.filter((val) => val.id !== id)
		onChange(newValue)
	}

	return (
		<div className="tt-flex tt-flex-col tt-gap-24 tt-mt-28">
			{value.map((item, index) => {
				const i = index + 1
				return (
					<>
						{index !== 0 && <Divider />}
						<Task
							changeProjectHandler={changeProjectHandler}
							changePrioriryHandler={changePrioriryHandler}
							changeNeedReportHandler={changeNeedReportHandler}
							toggleHasDeadlineHandler={toggleHasDeadlineHandler}
							key={item?.id}
							project={project}
							changeContractDirectoryHandler={changeContractDirectoryHandler}
							changeTypeHandler={changeTypeHandler}
							errors={errors && errors[index]}
							changeUsersHandler={changeUsersHandler}
							index={i}
							changeDateHandler={changeDateHandler}
							removeHandler={removeHandler}
							changeHandler={changeHandler}
							item={item}
						/>
					</>
				)
			})}
		</div>
	)
})

ProtocolDecision.displayName = 'ProtocolDecision'
export default ProtocolDecision
