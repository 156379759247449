import React, { memo } from 'react'
import { useGetProtocolOnePlaceQuery } from 'src/old-app/service/Protocol'

const GetPlace = memo(({ place }) => {
	const { data } = useGetProtocolOnePlaceQuery(place)

	return data?.data?.Place
})
GetPlace.displayName = 'GetPlace'
export default GetPlace
