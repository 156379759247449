import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLoading: false,
	isAuth: false,
	isViewerOpen: false,
	isOpenEpic: false,
	currentImage: 0,
	images: [],
	isAutoUpdate: true,
	isMediaViewerOpen: false,
	mediaSource: [],
	currentMediaItem: 0,
	appLanguage: null,
}

//isMediaViewerOpen - триггер на модальное окно с галереей изображений/видео
//mediaSource - принимает массив {mediaType: image || video, title: string, url: string} для отображения в MediaViewer

const reducers = {
	setIsLoading: (state, action) => {
		state.isLoading = action.payload
	},
	setIsOpenEpic: (state, action) => {
		state.isOpenEpic = action.payload
	},
	setCurrentImage: (state, action) => {
		state.currentImage = action.payload
	},
	setImages: (state, action) => {
		state.images = action.payload
	},
	setIsViewerOpen: (state, action) => {
		state.isViewerOpen = action.payload
	},
	setIsAuth: (state, action) => {
		state.isAuth = action.payload
	},
	setIsAutoUpdate: (state, action) => {
		state.isAutoUpdate = action.payload
	},
	setIsMediaViewerOpen: (state, action) => {
		state.isMediaViewerOpen = action.payload
	},
	setMediaSource: (state, action) => {
		state.mediaSource = action.payload
	},
	setCurrentMediaItem: (state, action) => {
		state.currentMediaItem = action.payload || 0
	},
	setAppLanguage: (state, action) => {
		state.appLanguage = action.payload
	},
}

const interfaceSlice = createSlice({
	name: 'interface',
	initialState,
	reducers,
})

export const getIsLoading = (state) => state.interface.isLoading
export const getIsAuth = (state) => state.interface.isAuth
export const getImages = (state) => state.interface.images
export const getIsViewerOpen = (state) => state.interface.isViewerOpen
export const getIsOpenEpic = (state) => state.interface.isOpenEpic
export const getCurrentImage = (state) => state.interface.currentImage
export const getIsAutoUpdate = (state) => state.interface.isAutoUpdate
export const getIsMediaViewerOpen = (state) => state.interface.isMediaViewerOpen
export const getMediaSource = (state) => state.interface.mediaSource
export const getCurrentMediaItem = (state) => state.interface.currentMediaItem
export const getAppLanguage = (state) => state.interface.appLanguage

export const interfaceReducer = interfaceSlice.reducer
export const interfaceActions = interfaceSlice.actions
