import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserID } from 'src/old-app/store/userSlice'
import { useGetOneProjectQuery, useGetProjectsQuery } from '../../service/Projects'
import useDebounce from '../../hooks/useDebounce'
import MySelect from '../../components/ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'

const labelRender = (value) => value?.name

export function UserProjectsSelect({ value, onChange, id, filters, ...props }) {
	const [search, setSearch] = useState('')
	const userID = useSelector(getUserID)

	const searchParams = search ? { name: search } : {}

	const {
		data: projectsData,
		refetch,
		isSuccess,
	} = useGetProjectsQuery(
		{
			user_id: userID,
			per_page: 100,
			...searchParams,
			...filters,
		},
		{}
	)

	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) refetch()
	}, [debouncedSearch])

	const [selected, setSelected] = useState(props.multiple ? [] : '')
	const isSelectedEmpty = Boolean(selected?.length)

	const oneProjectQuery = useGetOneProjectQuery({ id: value }, { init: false })

	useEffect(() => {
		if (!isSelectedEmpty && value.length) oneProjectQuery.refetch()
	}, [value])

	useEffect(() => {
		if (!isSelectedEmpty && oneProjectQuery.isSuccess) setSelected(oneProjectQuery.data.data)
	}, [oneProjectQuery.data])

	function _onChange(item) {
		setSelected(item)
		onChange?.(item)
	}

	return (
		<MySelect
			idSelect={getID({ prefix: id, field: 'project', type: 'select' })}
			label="Проект"
			disabled={!isSuccess}
			menuItemRander={labelRender}
			labelRender={labelRender}
			items={projectsData?.data ?? []}
			isSearch
			setSearch={setSearch}
			search={search}
			value={selected}
			onChange={_onChange}
			{...props}
		/>
	)
}
