import React, { useEffect } from 'react'
import { getUser, useSession, User } from 'src/@entities/session'
import { usersActions } from '../old-app/store/userSlice'
import rolesType from '../old-app/constants/rolesType'
import { TOKEN } from '../shared/config'
import { useDispatch } from 'react-redux'
import { useActions } from '../shared/hooks'
import { LoadPage } from 'src/shared/components'

export const AppAuth: React.FC<React.PropsWithChildren> = ({ children }) => {
	const session = useSession()

	useEffect(() => {
		session.init()
	}, [])

	if (!session.auth.authenticated) return <LoadPage />

	return <AppAuthSet>{children}</AppAuthSet>
}

export const AppAuthSet: React.FC<React.PropsWithChildren> = ({ children }) => {
	const session = useSession()
	const dispatch = useDispatch()
	const { setUser } = useActions()

	useEffect(() => {
		if (session.auth.authenticated) {
			const token = session.auth.token
			const user = getUser()
			if (!user) return

			const newUser: User = {
				first_name: user?.given_name,
				last_name: user?.family_name,
				middle_name: user.middle_name,
				srp: '',
				id: user?.sub,
				avatar: user?.avatar_256,
				name: user?.name,
				email: user?.email,
				// roles,
			}

			const roles = user?.resource_access['task-manager']?.roles
			dispatch(usersActions.getRoles(roles ?? [rolesType.USER]))
			setUser(newUser)
			localStorage.setItem(TOKEN, JSON.stringify(token))
		}
	}, [session.auth.authenticated])

	return <>{children}</>
}
