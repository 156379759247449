import React, { memo, useState } from 'react'
import Button from '@mui/material/Button'
import randomId from 'src/old-app/utils/randomId'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const ProtocolAddButton = memo(({ onChange, value, id, project }) => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const addHandler = () => {
		const last = value[value.length - 1] ?? { order: 0 }
		const newDecision = {
			id: randomId('decisionsTaken'),
			order: last.order + 1,
			type: 'decision',
			deadline: undefined,
			hasDeadline: false,
			priority: null,
			need_report: false,
			project: project,
			users: [],
			value: '',
		}
		onChange([...value, newDecision])
		setAnchorEl(null)
	}

	return (
		<div>
			<Button
				id={getID({
					prefix: id,
					field: 'decisions_taken',
					type: 'button_add',
				})}
				onClick={addHandler}
				startIcon={
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M10 5V10M10 10V15M10 10H15M10 10H5"
							stroke="#94A3B8"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				}
				className="tt-border-gray-200  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
				variant="outlined"
			>
				{t('add_decision')}
			</Button>
		</div>
	)
})

ProtocolAddButton.displayName = 'ProtocolAddButton'
export default ProtocolAddButton
