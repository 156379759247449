/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState } from 'react'
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material'
import { getProject, getUsers, projectsActions } from 'src/old-app/store/projectsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import {
	useCreateUserProjectMutation,
	useDeleteProjectUserMutation,
	useUpdateProjectMutation,
} from 'src/old-app/service/Projects'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ErrorText from '../ui/ErrorText'
import UsersSelect from '../shared/UserSelect'
import UserCell from '../tables/UserCell'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import {
	useCreateTeamHashtagLinkMutation,
	useCreateTeamUsersMutation,
	useDeleteTeamHashtagMutation,
	useDeleteTeamUsersMutation,
	useGetTeamUsersQuery,
	useUpdateTeamMutation,
} from 'src/old-app/service/Teams'
import { InputContainer, UsersChoice } from '../../../shared/components'
import TaskTagsChoice from '../../../shared/components/task-tags-choice'
import TeamTagsChoice from '../../../shared/components/team-tags-choice'
import { useCreateTaskHashtagLinkMutation, useDeleteTaskHashtagMutation } from '../../service/Tasks'
import { useTeamShow } from '../../../entities/team/hooks'
import { useTranslation } from 'react-i18next'

const TeamEdit = ({ setEdit }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		name: yup.string().required(t('team_title_is_required')),
	})

	const dispatch = useDispatch()

	const { team } = useTeamShow()

	const [updateTeams, teamResult] = useUpdateTeamMutation()
	const [deleteTeamUser] = useDeleteTeamUsersMutation()
	const [createUserTeam] = useCreateTeamUsersMutation()
	const [deleteTeamHashtag] = useDeleteTeamHashtagMutation()
	const [createTeamHashtagLink] = useCreateTeamHashtagLinkMutation()

	const users = team?.users ?? []

	const teamAuthorID = team?.author_view?.id

	const {
		setValue,
		handleSubmit,
		control,
		register,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			users: [],
			deleteUsers: [],
			hashtags: [],
		},
		resolver: yupResolver(schema),
	})

	const deleteUsers = watch('deleteUsers')

	useEffect(() => {
		if (team) {
			setValue('name', team.name)
			setValue('hashtags', team?.hashtags ?? [])
			setValue(
				'users',
				users?.map((item) => item.user)
			)
		}
		if (teamResult.isError) {
			dispatch(interfaceActions.setIsLoading(false))

			dispatch(
				showMessage({
					message: teamResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [team, teamResult, users])

	const closeEdit = () => {
		dispatch(interfaceActions.setIsLoading(false))
		setEdit(false)
	}

	const updateProjectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		const teamId = team.id
		const body = { body: { name: data.name }, id: teamId }
		const updateData = await updateTeams(body)

		const newUsers = data.users.map((item) => item.id) ?? []
		const oldUsers = users.map((item) => item?.user_id) ?? []

		const createUsers = newUsers.filter((newUserId) => !oldUsers.find((oldUserId) => oldUserId === newUserId))
		const deleteUsers = oldUsers.filter(
			(oldUserId) => !newUsers.find((newUserId) => newUserId === oldUserId) && oldUserId !== teamAuthorID
		)

		await Promise.all(
			createUsers.map(async (userId) => {
				await createUserTeam({
					command_id: teamId,
					user_id: userId,
				})
			})
		)

		await Promise.all(
			deleteUsers.map(async (userId) => {
				await deleteTeamUser({
					command_id: teamId,
					user_id: userId,
				})
			})
		)

		const newTags = data.hashtags.map((item) => item.id) ?? []
		const oldTags = team?.hashtags.map((item) => item?.id) ?? []

		const createTags = newTags.filter((newUserId) => !oldTags.find((oldUserId) => oldUserId === newUserId))
		const deleteTags = oldTags.filter((oldUserId) => !newTags.find((newUserId) => newUserId === oldUserId))

		await Promise.all(
			createTags.map(async (hashtagId) => {
				await createTeamHashtagLink({ id: team.id, hashtagId: hashtagId })
			})
		)

		await Promise.all(
			deleteTags.map(async (hashtagId) => {
				await deleteTeamHashtag({ command_id: team.id, hashtag_id: hashtagId })
			})
		)

		dispatch(
			showMessage({
				message: t('successfully_updated.team'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		closeEdit()
	}

	return (
		<form className="tt-py-16" onSubmit={handleSubmit(updateProjectHandler)}>
			<div className="tt-flex tt-items-center tt-gap-10 tt-mb-20 tt-flex-col">
				<div className="tt-flex tt-flex-col tt-w-full">
					<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>
					<TextField
						{...register('name')}
						error={!!errors.name}
						hiddenLabel
						placeholder={t('title')}
						className="dialig-input"
						size="small"
					/>
					<ErrorText errors={errors} field="name" />
				</div>
				<InputContainer label={t('participants')} error={errors?.users}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<UsersChoice
								placeholder={t('participants')}
								getOptionDisabled={(option) => option?.id === teamAuthorID}
								value={value}
								onChange={onChange}
							/>
						)}
						name="users"
						control={control}
					/>
				</InputContainer>
				<InputContainer label={t('tags')} error={errors?.hashtags}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TeamTagsChoice placeholder={t('tags')} value={value} onChange={onChange} />
						)}
						name="hashtags"
						control={control}
					/>
				</InputContainer>

				{/* <Controller
					control={control}
					name="tags"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple
							id="tags"
							className="mt-32"
							options={[]}
							disableCloseOnSelect
							getOptionLabel={(option) => option.title}
							renderOption={(_props, option, { selected }) => (
								<li {..._props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.title}
								</li>
							)}
							value={value ? value.map((id) => _.find([], { id })) : []}
							onChange={(event, newValue) => {
								onChange(newValue.map((item) => item.id))
							}}
							fullWidth
							renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
						/>
					)}
				/> */}
			</div>
			<div className="tt-flex tt-gap-12 tt-items-center">
				<Button onClick={closeEdit} variant="outlined" className="tt-border-gray-200 tt-min-h-32 tt-h-32 tt-px-16">
					{t('cancel')}
				</Button>
				<Button
					type="submit"
					variant="contained"
					className="tt-bg-primary hover:tt-bg-primary-600  tt-text-white tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('save')}
				</Button>
			</div>
		</form>
	)
}

TeamEdit.displayName = 'TeamEdit'
export default TeamEdit
