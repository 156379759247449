import React from 'react'
import addAlpha from 'src/old-app/utils/addAlpha'
import { CellEmpty } from '../../../shared/components'

const TaskPriorityShow = ({ priority, className }) => {
	if (!priority) return <CellEmpty />
	return (
		<div
			className={[
				'tt-text-center  tt-text-12 tt-font-medium tt-rounded-full tt-px-8 tt-py-2  tt-truncate',
				className,
			].join(' ')}
			style={{ color: priority?.color, backgroundColor: addAlpha(priority?.color, 0.1) }}
		>
			{priority.name}
		</div>
	)
}

export default TaskPriorityShow
