import React from 'react'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'

export const TaskEpicButton = ({ task }: { task: any }) => {
	return (
		<div className="tt-flex tt-gap-8 tt-items-center tt-px-11 tt-py-8 tt-border-t tt-border-gray-200/50">
			<BookmarkBorderOutlinedIcon
				sx={{ fill: task?.epic?.type?.color ?? '#5249E5' }}
				className={`tt-gray-400   tt-w-20 tt-h-20`}
			/>

			<p style={{ color: '#5d5c69' }} className="tt-font-medium text-hyphens tt-line-clamp-2">
				{task?.epic?.title}
			</p>
		</div>
	)
}
