import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

const ProtocolSideBarLoading = memo(() => {
	return (
		<div className="tt-w-full tt-flex-1 tt-flex tt-items-center tt-justify-center tt-min-h-full tt-p-24">
			<CircularProgress className="tt-text-primary-600" />
		</div>
	)
})

ProtocolSideBarLoading.displayName = 'ProtocolSideBarLoading'
export default ProtocolSideBarLoading
