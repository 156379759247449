import React from 'react'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useGetOneProjectQuery } from 'src/old-app/service/Projects'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../shared/config/fields-ids'

const LinkMyProject = ({ label = 'Личные задачи' }) => {
	const user = useGetUser()
	const { data: project } = useGetOneProjectQuery({ id: user?.id }, { skip: !user?.id })

	const to = project?.data?.id ? `/project/${user?.id}/list` : `/projects/${user?.id}/start`

	return (
		<Link to={to} id={getID({ type: 'link', entity: 'logged_user_tasks' })}>
			<Button startIcon={<AssignmentIndOutlinedIcon />} className="tt-text-primary  tt-min-h-32 tt-h-32 tt-px-16">
				{label}
			</Button>
		</Link>
	)
}

export default LinkMyProject
