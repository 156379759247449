import React from 'react'
import styles from './styles.module.scss'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import SwipeLeftOutlinedIcon from '@mui/icons-material/SwipeLeftOutlined'
import moment from 'moment'
import TaskApproved from '../task-approved'
import { Tooltip } from '@mui/material'
import { UserShow } from 'src/shared/components'
import { useGetUser } from 'src/shared/hooks'
import TaskDecline from '../task-decline'
import { requestType } from '../../types'
import { getID } from '../../../../shared/config/fields-ids'
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined'
import CancelRequest from '../../../../shared/components/cancel-request'
import { useSelector } from 'react-redux'
import { getTask } from 'src/old-app/store/tasksSlice'
import { useTranslation } from 'react-i18next'
import { getProject } from 'src/old-app/store/projectsSlice'

interface IProps {
	confirmations: any[]
	type: requestType
	id?: string
	requestId: string
	comment?: string
	isShowCancel?: boolean
}

const TaskСonfirmatory: React.FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { confirmations, type, requestId, isShowCancel = true, id, comment } = p
	const user = useGetUser()
	const task = useSelector(getTask)
	const project = useSelector(getProject)

	const statuses: any = {
		ON_CONFIRMATION: {
			label: t('statuses.on_confirmation'),
			isInitial: true,
			className: 'tt-bg-gray-500/10 tt-text-gray-500',
			icon: <AccessTimeOutlinedIcon />,
		},
		APPROVED: {
			label: t('statuses.approved'),
			className: 'tt-bg-green-500/10 tt-text-green-500',
			icon: <CheckOutlinedIcon />,
		},
		CANCELLED: {
			label: t('statuses.canceled'),
			className: 'tt-bg-primary-500/10 tt-text-primary-500',
			icon: <CancelScheduleSendOutlinedIcon />,
		},
		REJECTED: {
			label: t('statuses.rejected'),
			className: 'tt-bg-red-500/10 tt-text-red-500',
			icon: <SwipeLeftOutlinedIcon />,
		},
	}

	return (
		<div className={styles['task-confirmatory']}>
			{isShowCancel && task.isMyTask && !task?.project?.isEnd && (
				<div className="tt-px-24 tt-pb-12 tt-w-full tt-flex tt-items-center tt-justify-end">
					<CancelRequest label={t('cancel_request')} type={type} id={requestId} />
				</div>
			)}

			{comment && (
				<div className="tt-px-24 tt-py-12">
					<div
						className={['tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden '].join(
							' '
						)}
					>
						<div className="tt-text-12 tt-flex tt-flex-col tt-items-center tt-justify-between tt-font-semibold tt-px-8 tt-py-4 tt-bg-gray-200 tt-text-center tt-text-black">
							<span>{t('comment')}</span>
						</div>
						<div className="tt-px-8 tt-py-6">
							<p className="tt-break-all">{comment}</p>
						</div>
					</div>
				</div>
			)}

			<Timeline
				position="right"
				sx={{
					[`& .${timelineItemClasses.root}:before`]: {
						flex: 0,
						padding: 0,
					},
				}}
			>
				{confirmations?.map((item: any) => {
					const status = statuses[item?.confirm]

					return (
						<TimelineItem>
							<TimelineSeparator>
								<TimelineConnector />
								<Tooltip arrow title={status?.label} placement="top-start">
									<TimelineDot className={[status?.className, 'shadow-none'].join(' ')}>{status?.icon}</TimelineDot>
								</Tooltip>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent className="tt-flex tt-gap-4  tt-flex-col tt-justify-center">
								<div className="tt-flex tt-items-center tt-gap-8">
									<UserShow user={item?.user} />
								</div>
								{item?.comment && (
									<div
										className={[
											'tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden ',
										].join(' ')}
									>
										<div className="tt-text-12 tt-flex tt-flex-col tt-items-center tt-justify-between tt-font-semibold tt-px-8 tt-py-4 tt-bg-gray-200 tt-text-center tt-text-black">
											<span className="tt-text-gray-500 tt-text-10">{moment(item?.updated_at).format('LLLL')}</span>
											<span>{t('comment')}</span>
										</div>
										<div className="tt-px-8 tt-py-6">
											<p className="tt-break-all">{item?.comment}</p>
										</div>
									</div>
								)}
								{!task?.project?.isEnd && (
									<div className="tt-flex tt-gap-12">
										{status?.isInitial && user?.id === item.user_id && (
											<>
												<TaskApproved
													disabled={
														type === requestType.TASK_STATUS &&
														task?.contract_type?.contract_type?.id &&
														project?.isContract
															? task?.deadlines &&
															  task?.deadlines?.length &&
															  task?.deadlines[0]?.confirmation_status === 'ON_CONFIRMATION'
															: false
													}
													type={type}
													id={requestId}
												/>
												<TaskDecline type={type} id={requestId} />
											</>
										)}
									</div>
								)}
							</TimelineContent>
						</TimelineItem>
					)
				})}
			</Timeline>
		</div>
	)
}

export default TaskСonfirmatory
