import { createContext } from 'react'
import { ETeamRole } from '../components/team-provider'

interface IProps {
	isManageTeam?: boolean

	team?: any
	curUserRole?: string
}

const TeamShowContext = createContext<IProps>({
	isManageTeam: false,
	curUserRole: ETeamRole.USER,
	team: null,
})
export default TeamShowContext
