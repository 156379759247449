import React from 'react'
import { Button } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useDispatch } from 'react-redux'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const DialogFooter = ({ rightLabel, isDisabled, left, id }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onCloseDialog = () => {
		dispatch(dialogActions.setDialog(false))
	}

	return (
		<div className="tt-px-24 tt-py-16 tt-z-50 tt-mt-1 tt-bg-gray-50 tt-flex tt-justify-end tt-border-t tt-sticky tt-bottom-0">
			{left}
			<div className="tt-flex tt-gap-12 tt-items-center">
				<Button
					id={getID({ prefix: id, place: 'modal', type: 'button_cancel' })}
					onClick={onCloseDialog}
					className="tt-bg-gray-200 hover:tt-bg-gray-300 tt-text-black  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('cancel')}
				</Button>
				<Button
					id={getID({ prefix: id, place: 'modal', type: 'button_submit' })}
					disabled={isDisabled}
					type="submit"
					className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{rightLabel ?? t('create')}
				</Button>
			</div>
		</div>
	)
}

export default DialogFooter
