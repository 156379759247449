import React from 'react'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import NavLinkAdapter from 'src/@fuse/core/NavLinkAdapter'
import { IconButton } from '@mui/material'
import { useParams } from 'react-router-dom'
import ActionsMenu from './ActionsMenu'
import { getID } from '../../../shared/config/fields-ids'

const TaskHeader = ({ backTo, left, id, hideActionMenu = false }) => {
	const params = useParams()

	return (
		<div className="tt-flex tt-sticky tt-tt-h-60 tt-z-30 tt-top-0 tt-bg-white tt-items-center tt-justify-between tt-border-b-1 tt-w-full  tt-px-24 tt-py-12">
			<div>{left}</div>
			<div className="tt-flex tt-items-center tt-gap-8">
				{!hideActionMenu && <ActionsMenu id={getID({ prefix: id, action: 'manage' })} backTo={backTo} />}
				<IconButton
					className="tt-w-32 tt-h-32"
					id={getID({ prefix: id, type: 'button_close' })}
					component={NavLinkAdapter}
					to={!backTo ? `/projects/${params?.projectId}` : backTo}
					size="large"
				>
					<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
				</IconButton>
			</div>
		</div>
	)
}

export default TaskHeader
