import { MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const TaskSortRow = ({ label, field, defaultValue, id }) => {
	const { t } = useTranslation()
	const { search } = useLocation()
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const getValue = searchParams.get(field) ?? defaultValue
	const [value, setValue] = useState(getValue)

	const onChange = (event) => {
		const newValue = event.target.value
		const params = new URLSearchParams(search)

		params.set(field, newValue)
		setValue(newValue)

		navigate({
			search: params.toString(),
		})
	}

	return (
		<div className="tt-flex tt-items-center  tt-gap-12 ">
			<span className="tt-text-14 tt-whitespace-nowrap tt-text-gray-500 tt-flex-1">{label}:</span>
			<Select
				className="new-select tt-rounded-full tt-max-h-32 tt-w-160 tt-min-h-32 tt-h-32"
				labelId="demo-select-small"
				id={getID({ prefix: id, action: 'filter', field: 'sort_direction', type: 'select' })}
				value={value}
				size="small"
				label={null}
				onChange={onChange}
			>
				<MenuItem className="tt-gray-500" id={getID({ type: 'select_item', index: 0 })} value="">
					{t('none')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value="asc">
					{t('increase')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 2 })} value="desc">
					{t('decrease')}
				</MenuItem>
			</Select>
		</div>
	)
}

export default TaskSortRow
