import { Chip, Popover } from '@mui/material'
import React from 'react'

const TagsView = ({ tags, isHidePlaceholder, className = "" }) => {
	const views = 2
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const viewTags = tags.slice(0, views)

	return (
		<div className={className}>
			<div
				className="tt-flex tt-gap-4"
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				{viewTags.map((item, ind) => (
					<Chip key={item?.hashtag || ind} className="tt-truncate tt-h-20 tt-font-medium" label={item?.hashtag} />
				))}

				<span className="tt-truncate tt-w-20 tt-px-2 tt-text-gray-500 tt-text-center">
					{tags.length > views && '...'}
				</span>
			</div>

			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<div className="tt-flex tt-flex-wrap tt-gap-12 tt-p-16">
					{tags.map((item, ind) => (
						<Chip key={item?.hashtag || ind} className="tt-truncate tt-h-20" label={item?.hashtag} />
					))}
				</div>
			</Popover>

			{tags.length === 0 && !isHidePlaceholder && <div className="tt-text-14 tt-w-full tt-text-gray-500">-</div>}
		</div>
	)
}

export default TagsView
