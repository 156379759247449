/* eslint-disable no-plusplus */
import { Button } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFiles, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import Tabs from '../ui/Tabs'
import AddFileButton from './AddFileButton'
import FilesTable from './FilesTable'
import { useDeleteFileTaskMutation } from 'src/old-app/service/TasksFile'

const items = [
	{ id: 1, label: 'Сначала новые', slug: 'DESC' },
	{ id: 2, label: 'Сначала старые', slug: 'ASC' },
]

const paramsKey = 'file_sort'

const Files = ({ hideFilesSort, id, files }) => {
	const dispatch = useDispatch()
	const task = useSelector(getTask)

	const [searchParams, setSearchParams] = useSearchParams()
	const taskTab = searchParams.get(paramsKey) ?? items[0].slug
	const [value, setValue] = useState(taskTab)
	const [sortFiles, serSortFiles] = useState(files)

	useEffect(() => {
		setValue(taskTab)
	}, [taskTab])

	useEffect(() => {
		if (value === items[0].slug) {
			const newFiles = files.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
			serSortFiles(newFiles)
		}
		if (value === items[1].slug) {
			const newFiles = files.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
			serSortFiles(newFiles)
		}
	}, [value, files])

	const [deleteFileTask] = useDeleteFileTaskMutation()

	const deleteFileHandler = (fileId) => {
		dispatch(tasksActions.deleteFile(fileId))
		const data = { file_id: fileId, task_id: task.id }
		deleteFileTask(data)
	}

	const hideFileDelete = task?.project?.isEnd ? true : !task?.isMyTask

	return (
		<div className="tt-w-full">
			<div className="tt-mt-16">
				<FilesTable id={id} deleteFile={deleteFileHandler} files={sortFiles} hideFileDelete={hideFileDelete} />
			</div>
		</div>
	)
}

export default Files
