import React, { useEffect, useState } from 'react'
import { getTasks, tasksActions } from 'src/old-app/store/tasksSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useGetTasksQuery } from 'src/old-app/service/Tasks'
import { getProject } from 'src/old-app/store/projectsSlice'
import TaskListItem from './TaskListItem'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import TaskFilters from './TaskFilters'
import { TASK_UPDATE } from 'src/shared/config'
import { getIsAutoUpdate } from 'src/old-app/store/interfaceSlice'
import moment from 'moment'
import queryString from 'query-string'
import EmptyPlaceholder from '../../../shared/components/empty-placeholder'
import { JsonParam, withDefault } from 'use-query-params'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const pageHideSkelet = { project: true, author: true, complete_date: true }
const initialPerPage = 100

export const setParamsFilters = ({ statuses, assignee, priority, author, type, tags, initParams }) => {
	const params = new URLSearchParams(initParams)
	statuses?.map((item) => {
		params.append('project_status_id', item)
	})
	type?.map((item) => {
		params.append('type_id', item)
	})
	priority?.map((item) => {
		params.append('task_priority_id', item)
	})
	assignee?.map((item) => {
		params.append('assignee_id', item)
	})
	author?.map((item) => {
		params.append('author', item)
	})
	tags?.map((item) => {
		params.append('hashtag_id', item)
	})
	return params.toString()
}

const TaskList = ({ taskParams, childrenRender, isAccordionHandler, right, propsHideSkelet }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isAutoUpdate = useSelector(getIsAutoUpdate)
	// const navigate = useNavigate();
	const getTasksData = useSelector(getTasks)
	const project = useSelector(getProject)
	const params = useParams()
	const projectId = params?.projectId

	const [perPage, serPerPage] = useState(initialPerPage)
	const [total, setTotal] = useState(0)
	const { search } = useLocation()
	const [fetchLoading, setFetchLoading] = useState(false)
	const [fetching, setFetching] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [paramsSearch, setParamsSearch] = useState({})

	const parsedSearch = queryString.parse(searchParams.toString())
	const {
		searchTask,
		slug,
		order_sort: createdSort = 'desc',
		deadline_sort: deadlineSort,
		deadline_start: deadlineStart,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = [],
		status = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		fields = JSON.stringify({}),
	} = parsedSearch

	const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue, fieldsValue] = [
		status,
		assignee,
		priority,
		author,
		type,
		tags,
		fields,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
		tags: tagsValue,
	})

	const {
		data: tasks,
		isLoading,
		refetch,
		isFetching,
	} = useGetTasksQuery(
		{
			url: multipleParamsString,
			params: {
				project_id: projectId,
				created_at_sort: createdSort,
				current_page: 1,
				per_page: perPage,
				only_epic: true,
				slug,
				title: searchTask,
				deadline_sort: deadlineSort ?? undefined,
				// start_date_deadline: deadlineStart ? moment(deadlineStart).format() : undefined,
				start_date_deadline: deadlineStart,
				// end_date_deadline: deadlineStart ? moment(deadlineEnd + 'T23:59:59').format() : undefined,
				end_date_deadline: deadlineEnd,

				// start_completed_at: completedAtStart ? moment(completedAtStart).format() : undefined,
				start_completed_at: completedAtStart,
				// end_completed_at: completedAtEnd ? moment(completedAtEnd + 'T23:59:59').format() : undefined,
				end_completed_at: completedAtEnd,

				// end_date_created_at: createdAtEnd ? moment(createdAtEnd + 'T23:59:59').format() : undefined,
				end_date_created_at: createdAtEnd,
				// start_date_created_at: createdAtStart ? moment(createdAtStart).format() : undefined,
				start_date_created_at: createdAtStart,
				...fieldsValue,
				...taskParams,
			},
		},
		{
			refetchOnMountOrArgChange: true,
			pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
		}
	)

	useEffect(() => {
		dispatch(scrumboardActions.createList(null))
	}, [])

	const refetchList = async () => {
		if (total === getTasksData.length) return null
		if (fetchLoading) return null

		setFetchLoading(true)

		serPerPage((prev) => prev + initialPerPage)
		refetch()
	}

	useEffect(() => {
		if (fetching) {
			refetchList()
		}
	}, [fetching])

	useEffect(() => {
		if (tasks?.data) {
			dispatch(tasksActions.getTasks(tasks.data))
			setTotal(tasks?.meta?.total_entries)
			setFetchLoading(false)
			setFetching(false)
		}
	}, [tasks, isLoading])

	const scrollHandler = (e) => {
		const scrollHeight = e.target.scrollHeight
		const scrollTop = e.target.scrollTop
		const innerHeight = window.innerHeight

		const left = scrollHeight - 5
		const right = scrollTop + innerHeight

		if (left < right) {
			setFetching(true)
		}
	}

	useEffect(() => {
		const b = document.querySelector('#project-content')
		if (!b) return
		b.addEventListener('scroll', scrollHandler)
		return function () {
			b.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	if (!searchTask && getTasksData.length === 0 && isLoading)
		return (
			<div className="tt-w-full tt-flex-col tt-flex tt-gap-4 tt-border tt-border-gray-200 tt-rounded-lg tt-overflow-hidden">
				<Skeleton className=" tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
				<Skeleton className=" tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
				<Skeleton className=" tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
				<Skeleton className=" tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
				<Skeleton className=" tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
			</div>
		)

	return (
		<table className="tt-w-full tt-overflow-hidden">
			<TaskFilters id={getID({ entity: 'task' })} hideSkelet={propsHideSkelet ?? pageHideSkelet} />

			{getTasksData?.map((item, index) => (
				<TaskListItem
					index={index}
					isAccordionHandler={isAccordionHandler}
					childrenRender={childrenRender}
					right={right ?? <div className={'tt-w-[36px]'} />}
					hideSkelet={propsHideSkelet ?? pageHideSkelet}
					className="hover:tt-bg-primary/10"
					activeClassName="tt-bg-primary/10 hover:tt-bg-primary/10"
					accordionSx={{ paddingBottom: '8px' }}
					takeStatusesFromRedux
					projectId={projectId}
					key={item.id}
					data={item}
				/>
			))}
			{!isLoading && tasks?.meta?.total_entries === 0 && <EmptyPlaceholder text={t('empty_tasks_list')} />}
			{fetchLoading && <EmptyPlaceholder text={t('is_loading')} />}
		</table>
	)
}

export default TaskList




