import FusePageSimple from 'src/@fuse/core/FusePageSimple'
import { styled } from '@mui/material/styles'
import ProfileContent from './ProfileContent'
import DateRangePicker from '../../../shared/components/date-range-picker/DateRangePicker'
import ProfileHeader from './ProfileHeader'

const Root = styled(FusePageSimple)(({ theme }) => ({
	'& .FusePageSimple-header': {
		backgroundColor: theme.palette.background.paper,
		borderBottomWidth: 1,

		borderStyle: 'solid',
		borderColor: theme.palette.divider,
		'& > .container': {
			width: '100%',
		},
	},
	'& .FusePageSimple-contentWrapper': {
		padding: 0,
	},
}))

function Profile() {
	return (
		<Root
			header={<ProfileHeader />}
			// header={
			// 	<DateRangePicker />
			// }
			content={
				<>
					<ProfileContent />
				</>
			}
		/>
	)
}

export default Profile
