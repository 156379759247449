/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import ErrorText from '../../ui/ErrorText'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { useUpdateStatusTaskMutation } from 'src/old-app/service/Tasks'
import { getProject } from 'src/old-app/store/projectsSlice'
import { getStatus, getStatuses, tasksActions } from 'src/old-app/store/tasksSlice'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const EditStatus = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		status: yup.string().required(t('title_is_required')),
	})
	const dispatch = useDispatch()

	const status = useSelector(getStatus)
	const project = useSelector(getProject)
	const statuses = useSelector(getStatuses)
	const isAdmin = useSelector(getAdminRole)

	const stateDefaultValue = () => {
		if (status?.is_request) {
			return 'is_request'
		}
		if (status?.is_final) {
			return 'is_final'
		}
		if (status?.is_initial) {
			return 'is_initial'
		}
	}
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		setError,
	} = useForm({
		defaultValues: {
			state: stateDefaultValue(),
		},
		resolver: yupResolver(schema),
	})

	const [updateStatusTask, result] = useUpdateStatusTaskMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(dialogActions.setDialog(false))
			dispatch(interfaceActions?.setIsLoading(false))
		}
	}, [result])

	useEffect(() => {
		if (status) {
			setValue('color', status?.color)
			setValue('status', status?.status)
		}
	}, [status])

	const isValidation = (data) => {
		// const today = moment().format()
		// if (moment(data.complete_at).isBefore(today)) {
		// 	setError('complete_at', { message: 'Нельзя выбрать дату завершения проекта меньше сегодняшней' })
		// 	return true
		// }

		return false
	}

	const updateStatusHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const body = {
				id: status.id,
				body: {
					project_id: project.id,
					status: data.status,
					color: data.color,
					order: status.order,
					is_final: data?.state === 'is_final' ? true : false,
					is_initial: data?.state === 'is_initial' ? true : false,
					is_request: data?.state === 'is_request' ? true : false,
				},
			}
			const createData = await updateStatusTask(body)
			dispatch(scrumboardActions.updateOneList({ ...body.body, id: body.id }))
			dispatch(tasksActions.updateStatus({ ...body.body, id: body.id }))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(updateStatusHandler)} className="tt-w-600">
			<DialogHeader title={t('status_editing')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">Название</span>
						<div className="tt-flex tt-gap-12">
							<Controller
								name="status"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										id={getID({
											place: 'modal',
											entity: 'status',
											action: 'edit',
											field: 'status_name',
											type: 'text',
										})}
										onChange={onChange}
										error={!!errors.status}
										hiddenLabel
										placeholder={t('title')}
										className="dialig-input tt-w-full"
										size="small"
									/>
								)}
							/>
							<Controller
								name="color"
								control={control}
								render={({ field: { value, onChange } }) => (
									<input
										value={value}
										id={getID({
											place: 'modal',
											entity: 'status',
											action: 'edit',
											field: 'status',
											type: 'color',
										})}
										onChange={onChange}
										placeholder={t('color')}
										className="dialig-input"
										size="small"
										type="color"
									/>
								)}
							/>
						</div>

						<ErrorText errors={errors} field="status" />
					</div>

					<div className="tt-flex">
						<Controller
							name="state"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControl>
									<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('state')}</span>
									<RadioGroup
										value={value}
										onChange={onChange}
										aria-labelledby="demo-radio-buttons-group-label"
										name="radio-buttons-group"
										className="tt-flex tt-gap-2 tt-flex-row"
										defaultValue={stateDefaultValue()}
									>
										<FormControlLabel
											disabled={!!statuses.find((item) => item.is_initial === true)}
											value="is_initial"
											control={
												<Radio
													id={getID({
														place: 'modal',
														entity: 'status',
														action: 'edit',
														field: 'status_type_is_initial',
														type: 'radio',
													})}
												/>
											}
											label={t('statuses.initial')}
										/>
										<FormControlLabel
											disabled={!!statuses.find((item) => item.is_request === true)}
											value="is_request"
											control={
												<Radio
													id={getID({
														place: 'modal',
														entity: 'status',
														action: 'edit',
														field: 'status_type_is_request',
														type: 'radio',
													})}
												/>
											}
											label={t('statuses.request')}
										/>
										<FormControlLabel
											disabled={!!statuses.find((item) => item.is_final === true)}
											value="is_final"
											control={
												<Radio
													id={getID({
														place: 'modal',
														entity: 'status',
														action: 'edit',
														field: 'status_type_is_final',
														type: 'radio',
													})}
												/>
											}
											label={t('statuses.final')}
										/>
									</RadioGroup>
								</FormControl>
							)}
						/>
					</div>
				</div>
			</DialogMain>
			<DialogFooter
				id={getID({
					place: 'modal',
					entity: 'status',
					action: 'edit',
					// name: 'status',
				})}
				rightLabel={t('save')}
				isDisabled={isDisabled}
			/>
		</form>
	)
}

export default EditStatus
