import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { getStatuses, getTask } from 'src/old-app/store/tasksSlice'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import { useCreateTimeTrackButtonMutation, useUpdateTimeTrackButtonMutation } from 'src/old-app/service/Tasks'
import moment from 'moment'
import { getID } from '../../../shared/config/fields-ids'
import GradedTimeStapm from '../shared/GradedTimeStamp'
import { useTranslation } from 'react-i18next'

const TimeButton = ({ id }) => {
	const { t } = useTranslation()
	const [createTimeTrackButton] = useCreateTimeTrackButtonMutation()
	const [updateTimeTrackButton] = useUpdateTimeTrackButtonMutation()
	const task = useSelector(getTask)

	const [isStart, setIsStart] = useState(false)
	const [stopWatch, setStopWatch] = useState(0)

	const statuses = useSelector(getStatuses)
	const statusId = task?.task_status?.project_status_id

	const isFinal = statuses?.find((item) => item?.id === statusId)?.is_final

	const lastTime =
		task?.time_tracking_by_button?.length > 0
			? task?.time_tracking_by_button[task?.time_tracking_by_button?.length - 1]
			: undefined

	const tick = () => {
		setStopWatch((prev) => prev + 1)
	}

	useEffect(() => {
		if (isStart) {
			setInterval(tick, 1000)
			return () => clearInterval(tick, 1000)
		}
	}, [isStart])

	useEffect(() => {
		if (task) {
			const stop = lastTime?.stop
			const start = lastTime?.start

			if (start && !stop) {
				setIsStart(true)
			}
			if (start && stop) {
				setIsStart(false)
			}
			if (!lastTime) {
				setIsStart(false)
			}
		}
	}, [task, lastTime])

	if (isFinal) return null

	const startHandler = async () => {
		setStopWatch(0)
		await createTimeTrackButton({ task_id: task.id, start: moment.utc().format() })
		setIsStart(true)
	}

	const stopHandler = async () => {
		setStopWatch(0)
		const last = task?.time_tracking_by_button[task?.time_tracking_by_button?.length - 1]
		await updateTimeTrackButton({ id: last.id, body: { stop: moment.utc().format() } })
		setIsStart(false)
	}

	return (
		<>
			{isStart ? (
				<div
					onClick={stopHandler}
					id={getID({ prefix: id, type: 'button', fieldPostfix: 'end', field: 'time_tracking_by_button' })}
					className="tt-h-32 tt-min-h-32  tt-flex tt-items-center tt-cursor-pointer tt-justify-center tt-rounded-full tt-px-10 tt-bg-red-500 tt-text-white tt-text-14 tt-gap-12"
				>
					{lastTime?.start && <GradedTimeStapm timeStamp={moment().diff(moment(lastTime?.start), 'seconds')} />}
					<AlarmOffIcon className="tt-w-24 tt-h-24 tt-text-white" />
				</div>
			) : (
				<div
					onClick={startHandler}
					id={getID({ prefix: id, type: 'button', fieldPostfix: 'start', field: 'time_tracking_by_button' })}
					className="tt-h-32 tt-min-h-32 tt-flex tt-items-center tt-cursor-pointer tt-justify-center tt-rounded-full tt-px-10 tt-bg-green-500 tt-text-white tt-text-14 tt-gap-12"
				>
					{t('start')}
					<SlowMotionVideoIcon className="tt-w-24 tt-h-24" />
				</div>
			)}
		</>
	)
}

export default TimeButton
