import { Tooltip } from '@mui/material'
import { getTaskTypes } from 'src/old-app/store/tasksSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import addAlpha from 'src/old-app/utils/addAlpha'
import { CellEmpty } from '../../../shared/components'
import { useTranslation } from 'react-i18next'

const Type = ({ label, className, style }) => {
	return (
		<div
			className={`${className} tt-text-center tt-w-full tt-truncate tt-whitespace-nowrap tt-text-12 tt-font-medium tt-rounded-full tt-px-9 tt-py-4`}
			style={style}
		>
			{label}
		</div>
	)
}

const TaskType = ({ taskType, className }) => {
	const { t } = useTranslation()
	if (!taskType) return <CellEmpty />

	const taskTypeLabel = taskType?.name ? t(`types.${taskType?.name}`) : '-'

	return (
		<div className={['tt-w-full ', className].join(' ')}>
			<Type style={{ color: taskType?.color, backgroundColor: addAlpha(taskType?.color, 0.1) }} label={taskTypeLabel} />
		</div>
	)
}

export default TaskType
