import React, { useState } from 'react'
import styles from './styles.module.scss'
import { InputBase } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { UserProjectsStatusesSelect } from '../../../../old-app/main/tasks/UserProjectsStatusesSelect'
import { UserProjectsPrioritiesSelect } from '../../../../old-app/main/tasks/UserProjectsPrioritiesSelect'
import UsersSelect from '../../../../old-app/components/shared/UserSelect'
import ProjectStatusSelect from '../../../../old-app/components/task/ProjectStatusSelect'
import ProjectPrioritySelect from '../../../../old-app/components/task/ProjectPrioritySelect'
import ProjectUsersSelect from '../../../../old-app/components/task/ProjectUsersSelect'
import TaskHashtagSelect from '../../../../old-app/components/shared/TaskHashtagSelect'
import ProjectHashtagSelect from '../../../../old-app/components/shared/ProjectHashtagSelect'
import TeamHashtagSelect from '../../../../old-app/components/shared/TeamHashtagSelect'
import { getID } from '../../../config/fields-ids'
import MySelect from '../../../../old-app/components/ui/MySelect'
import { useTranslation } from 'react-i18next'

interface IProps {
	field?: string
	placeholder?: string
}

const ProjectStatusesFilter: React.FC<IProps> = (p) => {
	const { t } = useTranslation()

	const items = [
		{ id: 'ON_CONFIRMATION', label: t('statuses.on_confirmation') },
		{ id: 'APPROVED', label: t('statuses.approved') },
		{ id: 'REJECTED', label: t('statuses.rejected') },
		{ id: 'COMPLETED', label: t('statuses.completed') },
	]
	const { field = 'confirmation_status', placeholder = 'Статус' } = p

	const { search } = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const statuses = searchParams.get(field) ?? []

	const statusesValue = Array.isArray(statuses) ? statuses : JSON.parse(statuses)
	const getStatusesValue = statusesValue.map((item: any) => items.find((p) => p.id === item))

	const setParams = (value: any) => {
		const ids = value.map((item: any) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(field, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	return (
		<>
			<MySelect
				selectClassName="tt-text-left new-select filter-select-miltipe"
				items={items}
				wrapperClassName="w-auto"
				onChange={setParams}
				value={getStatusesValue}
				displayEmpty
				field={field}
				label={placeholder}
				hideError
				hideLabel
				labelRender={(item: any) => item?.label}
				multiple
				errors={undefined}
				defaultValue={undefined}
				isShowCheckbox={undefined}
				errorField={undefined}
				isSearch={undefined}
				search={undefined}
				setSearch={undefined}
				disabled={undefined}
				menuItemRander={undefined}
				addComponent={undefined}
				idSelect={undefined}
				withSubHeadersMode={undefined}
				// displayEmpty
				// hideError
				// hideLabel
				// selectClassName="new-select tt-text-left filter-select-miltipe"
				// idSelect={getID({prefix: 'status', field: 'type', type: 'select'})}
				//
				// menuItemRander={(item: any) => item?.label}
				// value={getStatusesValue}
				// onChange={setParams}
				// items={items}
				// multiple
				// label={placeholder}
				// errors={undefined} field={field} defaultValue={undefined}
				// isShowCheckbox={undefined}
				// errorField={undefined} isSearch={undefined} search={undefined} setSearch={undefined}
				// disabled={undefined} addComponent={undefined}
				// wrapperClassName={undefined} withSubHeadersMode={undefined}
			/>
		</>
	)
}

export default ProjectStatusesFilter
