import React, { FC, useState, useEffect } from 'react'
import Autocomplete from '../autocomplete'
import { useGetSearchUserQuery, useGetUsersByIdsQuery } from '../../../old-app/service/Users'
import useDebounce from 'src/shared/hooks/useDebounce'
import { useGetUserProjectsQuery } from '../../../old-app/service/Projects'
import { UserShow } from '../index'

interface IProps {
	value: any
	getOptionDisabled?: any
	disabled?: boolean
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	projectId: string
	defaultList?: any[]
}

const ProjectUsersChoice: FC<IProps> = (p) => {
	const {
		value,
		onChange,
		placeholder = 'Участники',
		isIds,
		projectId,
		defaultList,
		disabled = false,
		getOptionDisabled,
	} = p

	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)
	const { data: usersData } = useGetUserProjectsQuery(
		{
			name: debounceSearch,
			project_id: projectId,
		},
		{ skip: !projectId }
	)

	const usersByIdQuery = useGetUsersByIdsQuery(value.toString(), {
		skip: !value.length || !isIds || !!defaultList,
	})

	useEffect(() => {
		if (usersByIdQuery.isSuccess && isIds) {
			const users = usersByIdQuery?.data?.data ?? []
			const newUsers = value.map((val: any) => users.find((user: any) => user.id == val))
			onChange(newUsers)
		}
	}, [usersByIdQuery?.data])

	const list: any[] = defaultList ? defaultList : usersData?.data?.map((item: any) => item.user) ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	return (
		<Autocomplete
			getOptionDisabled={getOptionDisabled}
			placeholder={placeholder}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
			renderOptionLabel={(option: any) => <UserShow isShowEmail isShowMiddleName user={option} />}
			disabled={disabled}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default ProjectUsersChoice
