import React from 'react'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import MenuItem from './MenuItem'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import GroupsIcon from '@mui/icons-material/GroupsOutlined'
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded'
import { useSelector } from 'react-redux'
import { getAdminProjectContractRole, getAdminRole, getUserRole } from 'src/old-app/store/userSlice'
import Novu from '../../shared/novu/Novu'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import { taskPaths } from 'src/pages/task'
import { DashboardOutlined } from '@mui/icons-material'
import { useGetTaskIncomingCountQuery } from '../../../service/Tasks'
import { useGetOnConfirmationTeamsCountQuery } from 'src/old-app/service/Teams'
import { useGetOnConfirmationTeamProjectsCountQuery } from 'src/old-app/service/Projects'
import { navAsideWidth } from 'src/old-app/configs/themesConfig'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import { TASK_UPDATE } from 'src/shared/config'
import { getIsAutoUpdate } from 'src/old-app/store/interfaceSlice'
import { useTranslation } from 'react-i18next'

const navStyles = { width: navAsideWidth }

const Aside = () => {
	const { t } = useTranslation()
	const isUser = useSelector(getUserRole)
	const isAutoUpdate = useSelector(getIsAutoUpdate)
	const isAdmin = useSelector(getAdminRole)
	const isAdminProjectContract = useSelector(getAdminProjectContractRole)

	//запрос счетчика входящих задач
	const { data: taskData } = useGetTaskIncomingCountQuery(
		{},
		{
			pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
		}
	)

	const count = taskData?.data ?? 0
	const viewCount = count > 99 ? '+99' : count

	//запрос счетчика проектов на подтверждение
	const { data: onConfirmationTeamProjectsCountData } = useGetOnConfirmationTeamProjectsCountQuery(
		{},
		{
			pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
		}
	)

	const onConfirmationTeamProjectsCount = onConfirmationTeamProjectsCountData?.data ?? 0
	const viewOnConfirmationTeamProjectsCount =
		onConfirmationTeamProjectsCount > 99 ? '+99' : onConfirmationTeamProjectsCount

	//запрос счетчика команд на подтверждение
	const { data: onConfirmationTeamsCountData } = useGetOnConfirmationTeamsCountQuery(
		{},
		{
			pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
			skip: !isAdmin,
		}
	)

	const onConfirmationTeamsCount = onConfirmationTeamsCountData?.data ?? 0
	const viewOnConfirmationTeamsCount = onConfirmationTeamsCount > 99 ? '+99' : onConfirmationTeamsCount

	return (
		<div style={navStyles} className="tt-w-50 tt-z-20 tt-absolute tt-left-0 tt-top-0 tt-h-full">
			<div className="tt-py-16 tt-w-full tt-flex tt-flex-col tt-gap-6 tt-bg-white tt-h-screen tt-border-r tt-border-gray-200">
				
				<MenuItem
					badgeContent={viewCount}
					id="my-menu"
					link="/my"
					label={t('my_tasks')}
					icon={<EmojiPeopleRoundedIcon className="tt-w-24 tt-h-24 " />}
				/>

				<MenuItem
					id="projects-menu"
					link="/project"
					label={t('projects')}
					icon={<AccountTreeOutlinedIcon className="tt-w-24 tt-h-24 " />}
				/>

				{isAdmin && (
					<MenuItem
						id="projects-request-menu"
						link="/project-requests"
						label={t('projects_confirmation')}
						icon={<RuleOutlinedIcon className="tt-w-24 tt-h-24 " />}
					/>
				)}

				<MenuItem
					id="protocols-menu"
					link="/protocol"
					label={t('protocols')}
					icon={<ArticleOutlinedIcon className="tt-w-24 tt-h-24 " />}
				/>

				<MenuItem
					badgeContent={viewOnConfirmationTeamProjectsCount}
					id="teams-menu"
					link="/team"
					label={t('teams')}
					icon={<GroupsIcon className="tt-w-24 tt-h-24 " />}
				/>

				{isAdmin && (
					<MenuItem
						badgeContent={viewOnConfirmationTeamsCount}
						id="teams-request-menu"
						link="/team-requests"
						label={t('teams_confirmation')}
						icon={<GroupAddOutlinedIcon className="tt-w-24 tt-h-24" />}
					/>
				)}

				{(isAdmin) && (
					<>
						<MenuItem
							id="analysis-menu"
							link="/analysis-violations"
							label={t('interrupted_tasks')}
							icon={<AccountBalanceOutlinedIcon />}
						/>
					</>
				)}
			
				<MenuItem
					id="projects-templates-menu"
					link="/projects-templates"
					label={t('project_templates')}
					icon={<DashboardOutlined className="tt-w-24 tt-h-24 " />}
				/>
				
				<MenuItem id="profile-menu" link="/profile" label={t('user_profile')} icon={<AccountCircleOutlinedIcon />} />
				
				<Novu />
			</div>
		</div>
	)
}

export default Aside
