/* eslint-disable no-return-assign */
/* eslint-disable prefer-const */
import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
	lists: null,
}

const reducers = {
	reorderList: (state, action) => {
		if (!state.lists) return
		const result = action.payload
		const { lists } = state
		const { source, destination } = result

		const sourceColumn = lists[source?.droppableId]
		const destColumn = lists[destination.droppableId]
		const sourceItems = [...sourceColumn.cards]
		const destItems = [...destColumn.cards]
		const [removed] = sourceItems.splice(source.index, 1)
		destItems.splice(destination.index, 0, removed)
		state.lists = {
			...lists,
			[source.droppableId]: {
				...sourceColumn,
				cards: sourceItems,
			},
			[destination.droppableId]: {
				...destColumn,
				cards: destItems,
			},
		}
	},

	reorderStatus: (state, action) => {
		if (!state.lists) return
		const { lists } = state
		const result = action.payload

		const { source, destination } = result

		const copyList = Object.entries(lists)
		const [removed] = copyList.splice(source.index, 1)
		copyList.splice(destination.index, 0, removed)

		const objList = {}
		copyList.map(([listId, list], index) => {
			objList[listId] = list
		})

		state.lists = objList
	},

	reorderCard: (state, action) => {
		const result = action.payload

		const { lists } = state

		const { source, destination } = result

		const column = lists[source.droppableId]
		const copiedItems = column?.cards ? [...column.cards] : []
		const [removed] = copiedItems.splice(source.index, 1)
		copiedItems.splice(destination.index, 0, removed)
		state.lists = {
			...lists,
			[source.droppableId]: {
				...column,
				cards: copiedItems,
			},
		}
	},
	createList: (state, action) => {
		state.lists = action.payload
	},
	syncListWithStatuses: (state, action) => {
		const { lists } = current(state)

		let freshList = {}
		action.payload.forEach((status) => {
			freshList[status.id] = { ...status, cards: lists[status.id]?.cards ?? [] }
		})
		state.lists = freshList
	},

	createListCard: (state, action) => {
		const listId = action.payload.list.id
		const { lists } = state

		state.lists[listId] = { ...lists[listId], cards: action.payload.cards }
	},
	createOneList: (state, action) => {
		if (!state?.lists) return
		state.lists[action.payload.id] = { ...action.payload, cards: [] }
	},
	updateOneList: (state, action) => {
		if (!state?.lists) return
		state.lists[action.payload.id] = { ...state.lists[action.payload.id], ...action.payload }
	},
	deleteOneList: (state, action) => {
		if (!state?.lists) return
		delete state.lists[action.payload]
	},
	deleeteOneListCard: (state, action) => {
		const { lists } = state
		if (!lists) return
		state.lists = {
			...lists,
			[action.payload.listId]: {
				...action.payload.listId,
				cards: lists[action.payload.listId].cards.filter((item) => item.id !== action.payload.cardId),
			},
		}
	},
	updateOneListCard: (state, action) => {
		const { lists } = state
		const { listId, cardId, data } = action.payload

		if (!lists) return

		state.lists = {
			...lists,
			[listId]: {
				...lists[listId],
				cards: lists[listId]?.cards.map((item) => {
					if (item?.id === cardId) {
						item = { ...item, ...data }
					}
					return item
				}),
			},
		}
	},
}

const scrumboardSlice = createSlice({
	name: 'scrumboard',
	initialState,
	reducers,
})

export const getLists = (state) => state.scrumboard?.lists
export const getListsCards = (state, listId) => (state.scrumboard?.lists ? state.scrumboard?.lists[listId]?.cards : [])

export const scrumboardReducer = scrumboardSlice.reducer
export const scrumboardActions = scrumboardSlice.actions
