import React from 'react'

const Error404Page: React.FC<{}> = () => {
	return (
		<div className="tt-flex tt-items-center tt-justify-center tt-w-full tt-h-full tt-text-gray-800">
			<div className="tt-flex tt-flex-col tt-items-center">
				<div className="tt-text-128 md:tt-text-128">404</div>
				<div className="tt-text-32 md:tt-text-52">Страница не найдена</div>

				<div className="tt-text-10 md:tt-text-20">Страница, которую вы ищете, не существует.</div>
			</div>
		</div>
	)
}

export default Error404Page
