import styles from './ProjectProtocolsTableDummyCell.module.css'

const ProjectProtocolsTableDummyCell = ({ canGrow, basicWidth, animationDelay }) => {
	return (
		<div
			className={styles.cell_wrapper}
			style={{ flexGrow: canGrow ? '1' : '0', width: basicWidth, animationDelay: `${animationDelay}ms` }}
		/>
	)
}

export default ProjectProtocolsTableDummyCell
