/* eslint-disable react/no-children-prop */
// import React from 'react'
// import ReactMarkdown from 'react-markdown'
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
// import remarkGfm from 'remark-gfm'
// import rehypeRaw from 'rehype-raw'
// import remarkMath from 'remark-math'
// import remarkMdx from 'remark-mdx'
// import remarkToc from 'remark-toc'
// import rehypeKatex from 'rehype-katex'
// import MarkdownIt from 'markdown-it'
// import rehypeHighlight from 'rehype-highlight'
import MDEditor from '@uiw/react-md-editor'

import 'src/styles/markdown.scss'

const Markdown = ({ value }) => {
	return (
		<div className="tt-container" data-color-mode="light">
			<MDEditor.Markdown source={value} />
		</div>
	)
}

export default Markdown

/* <div className="markdown-body">
			<ReactMarkdown skipHtml children={value} remarkPlugins={[remarkGfm, rehypeHighlight]} rehypePlugins={[rehypeKatex, rehypeRaw]} />
		</div> */
