import React, { useMemo, useState } from 'react'
import styles from './styles.module.scss'
import TextFilter from '../../../../shared/components/filters/text-filter'
import UserFilter from '../../../../shared/components/filters/user-filter'
import UnTable from '../../../../shared/components/un-table'
import { UserShow } from '../../../../shared/components'
import { Chip } from '@mui/material'
import { useProjects } from 'src/entities/project'
import TagsView from '../../../../old-app/components/ui/TagsView'
import ProjectTagsFilter from '../../../../shared/components/filters/project-tags-filter'
import moment from 'moment/moment'
import DateFilter from '../../../../shared/components/filters/date-filter'
import useProtocols from '../../model/useProtocols'
import { useLocation, useParams } from 'react-router-dom'
import ProtocolTagsFilter from '../../../../shared/components/filters/protocol-tags-filter'
import TaskMoreFilters from '../../../../old-app/components/task/TaskMoreFilters'
import { useTranslation } from 'react-i18next'

interface IProps {}

const defaultPerPage = 100

const ProtocolsTable: React.FC<IProps> = () => {
	const { t } = useTranslation()
	const columns = useMemo(
		() => [
			{
				id: 2,
				label: 'Название',
				field: 'name',
				className: 'tt-flex-1',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap tt-break-all tt-flex-1 tt-min-w-76  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.topic}
					</p>
				),
			},
			{
				id: 3,
				label: 'Отвественный',
				field: 'author',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => <>{listItem?.creator_view && <UserShow user={listItem?.creator_view} />}</>,
			},
			{
				id: 4,
				label: 'Теги',
				field: 'tags',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => (
					<>
						<TagsView isHidePlaceholder={false} tags={listItem?.hashtags ?? []} />
					</>
				),
			},
			// {
			//     id: 6,
			//     label: "Место проведения",
			//     field: 'place',
			//     className: "max-w-192 min-w-192 w-192",
			//     render: (listItem: any) => <p
			//         className='my-truncate-one whitespace-pre-wrap break-all flex-1 min-w-76  text-14 text-left font-medium text-black'>{listItem?.place.Place}</p>
			//
			// },
			{
				id: 5,
				label: 'Время совещания',
				field: 'meeting_date',
				className: 'tt-max-w-224 tt-min-w-224 tt-w-224',
				render: (listItem: any) => (
					<>
						<span className="tt-text-14 tt-font-medium tt-text-black">
							{moment(listItem?.meeting_date_start).format('ll')}
							<span className="tt-pl-4">
								{moment(listItem?.meeting_date_start).format('LT')}-{moment(listItem?.meeting_date_end).format('LT')}
							</span>
						</span>
					</>
				),
			},
			{
				id: 5,
				label: '',
				field: '',
				className: 'tt-max-w-[44px] tt-min-w-[44px] tt-w-[44px]',
				render: (listItem: any) => <></>,
			},
		],
		[]
	)

	const filters = useMemo(
		() => [
			{
				id: 2,
				render: (listItem: any) => (
					<>
						<TextFilter placeholder={t('title')} field="topic" />
					</>
				),
			},
			{
				id: 3,
				render: (listItem: any) => (
					<>
						<UserFilter isAll placeholder={t('author')} field="author" />
					</>
				),
			},
			{
				id: 4,
				render: (listItem: any) => (
					<>
						<ProtocolTagsFilter placeholder={t('tags')} />
					</>
				),
			},

			{
				id: 5,
				render: (listItem: any) => (
					<>
						<DateFilter placeholder={t('meeting_time')} field="meeting_date" />
					</>
				),
			},
			{
				id: 5,
				render: (listItem: any) => (
					<>
						<TaskMoreFilters
							prefix={''}
							hideTaskParamsFilter
							hideSkelet={{ deadline_sort: true }}
							isLabel={undefined}
							allProjectsMode={true}
							projectId={undefined}
							id={undefined}
						/>
					</>
				),
			},
		],
		[]
	)

	const heads = columns.map((column, index) => {
		return { ...column, render: filters[index]?.render }
	})

	const [perPage, setPerPage] = useState(defaultPerPage)

	const { search } = useLocation()
	const { protocolId } = useParams()
	const { protocols, isLoading, meta, isFetching } = useProtocols({ params: { per_page: perPage, page: 1 } })
	const page = '/protocol'

	const link = (id: Id) => `${page}/${id}${search}`

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	const rowActive = (id: Id) => {
		return id === protocolId
	}

	return (
		<div className="tt-flex tt-flex-col tt-overflow-auto table-scroll">
			<UnTable
				isFetching={isFetching}
				rowActive={rowActive}
				loadMore={loadMore}
				meta={meta}
				isLoading={isLoading}
				emptyPlaceholder={t('empty_protocols_list')}
				link={link}
				heads={heads}
				list={protocols}
				columns={columns}
			/>
		</div>
	)
}

export default ProtocolsTable
