import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import DialogFooter from 'src/old-app/components/dialogs/DialogFooter'
import DialogHeader from 'src/old-app/components/dialogs/DialogHeader'
import DialogMain from 'src/old-app/components/dialogs/DialogMain'
import { useDeleteCompleteFileMutation, useUpdateTaskMutation } from 'src/old-app/service/Tasks'
import { getRequestStatus, getTask } from 'src/old-app/store/tasksSlice'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, InputContainer } from 'src/shared/components'
import Files from 'src/old-app/components/dialogs/task/Files'
import { useCreateTaskCompleteFileMutation } from 'src/old-app/service/TaskCompleteFile'
import { getProject } from 'src/old-app/store/projectsSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { checkFileSize } from 'src/shared/lib/checkFileSize'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { fileUpload } from 'src/old-app/service/File'
import { useGetUser } from 'src/shared/hooks'
import UploadedTaskCompleteFilesList from './components/UploadedTaskCompleteFilesList'
import { useTranslation } from 'react-i18next'

export const TaskFinish = () => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const user = useGetUser()
	const project = useSelector(getProject)
	const dispatch = useDispatch()
	const requestStatus = useSelector(getRequestStatus)
	const [updateTask] = useUpdateTaskMutation()
	const [createTaskCompleteFile, taskCompleteFileResult] = useCreateTaskCompleteFileMutation()
	const [drag, setDrag] = useState(false)
	const [uploadedTaskCompleteFiles, setUploadedTaskCompleteFiles] = useState([])
	const [deleteCompleteFile] = useDeleteCompleteFileMutation()

	const formSchema = yup.object().shape({
		complete_files: yup.array().when('is_task_report', {
			is: true,
			then: yup.array().min(uploadedTaskCompleteFiles?.length > 0 ? 0 : 1, t('completion_report_is_required')),
		}),
		contract_directory_type: yup
			.object()
			.when('is_contract_type_params', {
				is: true,
				then: yup.object().required(t('regulatory_document_parameter_is_required')).nullable(),
			})
			.nullable(),
	})

	const taskId = task.id
	const isContract = Boolean(project?.type?.name === 'contract')
	const isContractTypeParams = Boolean(
		task?.contract_type && task?.contract_type?.contract_type?.params?.length !== 0 && isContract
	)
	const isTaskReport = Boolean(task?.need_report)

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useForm<any>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			complete_files: [],
			is_task_report: false,
			is_contract_type_params: false,
			contract_directory_type: null,
		},
	})

	useEffect(() => {
		if (!task?.complete_file?.length) return
		setUploadedTaskCompleteFiles(task?.complete_file)
	}, [task])

	useEffect(() => {
		setValue('is_contract_type_params', isContractTypeParams)
		setValue('is_task_report', isTaskReport)
		if (task?.contract_type?.selected_param) {
			setValue('contract_directory_type', task?.contract_type?.selected_param)
		}
	}, [isContractTypeParams, isTaskReport, task])

	const onSubmit = async (values: any) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))

			if (isTaskReport) {
				for (const file of values.complete_files) {
					if (!checkFileSize(file.size)) {
						dispatch(interfaceActions.setIsLoading(false))
						dispatch(
							showMessage({
								message: t('maximum_file_size'),

								anchorOrigin: {
									vertical: 'top',
									horizontal: 'top-right',
								},
								variant: 'warning',
							})
						)
						return
					}
				}

				await Promise.all(
					values.complete_files.map(async (item: any) => {
						const fileData = await fileUpload(item)
						if (!fileData.data) return
						const fileId = fileData?.data?.id
						await createTaskCompleteFile({
							author_id: user.id,
							file_id: fileId,
							task_id: taskId,
						})
					})
				)
			}

			const body = {
				body: {
					selected_param: isContractTypeParams ? values.contract_directory_type.id : undefined,
					project_status_id: requestStatus.id,
					type_id: task?.type_id,
					task_priority_id: task?.priority?.id,
				},
				id: task.id,
			}

			await updateTask(body)

			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
		} catch (e) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
		}
	}

	const dragStartHandler = (e: any) => {
		e.preventDefault()
		setDrag(true)
	}
	const dragLeaveHandler = (e: any) => {
		e.preventDefault()
		setDrag(false)
	}
	const dropHandler = (e: any) => {
		e.preventDefault()

		const newFiles = [...e.dataTransfer.files]
		const oldFiles = getValues('complete_files')
		const files = [...oldFiles, ...newFiles]

		setValue('complete_files', files)
		setDrag(false)
	}

	const removeTaskCompleteFileHandler = (taskFileID: string) => {
		if (uploadedTaskCompleteFiles?.length > 1) {
			const preFilteredTaskCompleteFiles = uploadedTaskCompleteFiles.filter((uploadedTaskCompleteFile: any) => {
				return uploadedTaskCompleteFile?.id !== taskFileID
			})
			setUploadedTaskCompleteFiles(preFilteredTaskCompleteFiles)
		} else {
			setUploadedTaskCompleteFiles([])
		}
		const data = { file_id: taskFileID, task_id: taskId }
		deleteCompleteFile(data)
	}

	//console.log('errors', errors)

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="tt-w-[800px] tt-h-full tt-relative tt-overflow-x-hidden"
			onDragStart={dragStartHandler}
			onDragLeave={dragLeaveHandler}
			onDragOver={dragStartHandler}
			onDrop={dropHandler}
		>
			<div>
				<DialogHeader title={t('provide_additional_information')} back={undefined} backHandler={undefined} />
				<DialogMain className={undefined}>
					{isContractTypeParams && (
						<div>
							<InputContainer
								className="tt-col-span-3"
								label={t('regulatory_document_parameter')}
								error={errors?.contract_directory_type}
							>
								<Controller
									render={({ field: { value, onChange } }) => {
										const list = task?.contract_type?.contract_type?.params ?? []
										return (
											<Autocomplete
												multiple={false}
												placeholder={t('regulatory_document_parameter')}
												optionLabel={(option: any) => option?.name}
												renderOptionLabel={(option: any) => option?.name}
												list={list}
												value={list?.find((l: any) => l?.id === value?.id)}
												onChange={onChange}
											/>
										)
									}}
									name="contract_directory_type"
									control={control}
								/>
							</InputContainer>
						</div>
					)}
					{isTaskReport && (
						<div>
							<InputContainer
								className="tt-col-span-3"
								label={t('task_completion_report')}
								error={errors?.complete_files}
							>
								<Controller
									render={({ field: { value, onChange } }) => (
										<Files
											id={''}
											label={''}
											withDropHandlers={undefined}
											className={undefined}
											onChange={onChange}
											value={value}
										/>
									)}
									name="complete_files"
									control={control}
								/>
							</InputContainer>
							<UploadedTaskCompleteFilesList
								title={t('previosly_uploaded_reports')}
								uploadedTaskCompleteFiles={uploadedTaskCompleteFiles}
								removeTaskCompleteFile={removeTaskCompleteFileHandler}
							/>
						</div>
					)}
				</DialogMain>
				<DialogFooter isDisabled={undefined} left={undefined} id={undefined} rightLabel={t('send')} />
			</div>
		</form>
	)
}
