import { InputBase } from '@mui/material'
import { getProjects, getProjectsUsers } from 'src/old-app/store/projectsSlice'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetProjectsQuery } from 'src/old-app/service/Projects'
import { useGetSearchUserQuery } from 'src/old-app/service/Users'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'

const labelRender = (value) => value?.name

const ProjectsSelectFilter = memo((props) => {
	const [search, setSearch] = useState('')
	const {
		data: projectsData,
		isLoading,
		refetch,
	} = useGetProjectsQuery({ name: search, current_page: 1, per_page: 999 })
	const debouncedSearch = useDebounce(search, 2000)
	const userProjects = useSelector(getProjectsUsers)

	const availableProjects = useMemo(() => {
		const idsFromUserProjects = userProjects?.map((obj) => obj.project_id)
		return projectsData?.data?.filter((obj) => !idsFromUserProjects.some((id) => obj.id === id))
	}, [projectsData, userProjects])

	useEffect(() => {
		if (debouncedSearch) {
			refetch()
		}
	}, [debouncedSearch])

	if (!projectsData?.data) return null
	const projects = projectsData?.data ?? []

	return (
		<>
			<MySelect
				label="Проект"
				menuItemRander={labelRender}
				labelRender={labelRender}
				items={availableProjects ?? []}
				isSearch
				setSearch={setSearch}
				search={search}
				{...props}
			/>
		</>
	)
})

export default ProjectsSelectFilter
