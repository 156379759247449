import React from 'react'
import PropTypes from 'prop-types'
import { getID } from '../../../../shared/config/fields-ids'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export const File = ({ item, onRemove, id }) => {
	return (
		<div className="tt-h-32 tt-px-8 tt-py-6 tt-bg-gray-200 tt-rounded-full tt-flex tt-items-center tt-gap-4  tt-pl-16">
			<div className="tt-text-12 tt-font-medium">
				{item.name.length > 32 ? item.name.slice(0, 28) + '...' : item.name}
			</div>
			{onRemove && (
				<div id={id} onClick={onRemove} className="tt-cursor-pointer">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM7 9C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H7Z"
							fill="#64748B"
						/>
					</svg>
				</div>
			)}
		</div>
	)
}

const Files = ({ onChange, value, id, label = 'Файлы', withDropHandlers, className }) => {
	const { t } = useTranslation()
	const onRemoveHandler = (index) => {
		onChange(value.filter((_, _index) => _index !== index))
	}

	const onChangeHandler = (event) => {
		const files = [...event.target.files]
		onChange([...value, ...files])
	}

	function preventDefaultResolver(ev) {
		if (withDropHandlers) ev.preventDefault()
	}

	const dropHandler = (ev) => {
		preventDefaultResolver(ev)
	}

	function onDragOver(ev) {
		preventDefaultResolver(ev)
		ev.target.classList.add('tt-bg-gray-300')
	}

	function onDragLeave(ev) {
		preventDefaultResolver(ev)
		ev.target.classList.remove('tt-bg-gray-300')
	}

	return (
		<div className={clsx('tt-flex tt-flex-col tt-z-0 tt-w-full', className)}>
			{label && <div className="tt-text-14 tt-font-semibold tt-mb-4">{t('files')}</div>}
			<label
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={dropHandler}
				className="tt-px-16 tt-py-11 tt-cursor-pointer tt-bg-gray-100 tt-rounded-lg tt-border tt-border-dashed tt-border-gray-200 tt-flex tt-items-center tt-justify-center tt-text-14"
			>
				{withDropHandlers ? t('choose_or_drag_files') : t('choose_files')}
				<input
					key={value.length}
					id={getID({ prefix: id, field: 'files_add', type: 'input_file' })}
					multiple
					type="file"
					hidden
					onChange={onChangeHandler}
				/>
			</label>

			<div className="tt-mt-14 tt-flex tt-flex-wrap tt-gap-8">
				{value.map((item, index) => (
					<File
						id={getID({ prefix: id, field: 'files', type: 'button_delete', index })}
						onRemove={() => onRemoveHandler(index)}
						key={index}
						item={item}
					/>
				))}
			</div>
		</div>
	)
}

Files.propTypes = {
	id: PropTypes.string.isRequired,
}

export default Files
