/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { v4 } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'

import {
	useCreateHastagTaskMutation,
	useCreateTaskDeadlineMutation,
	useCreateTaskHashtagLinkMutation,
	useCreateTaskMutation,
	useCreateTaskSubscriberMutation,
} from 'src/old-app/service/Tasks'
import {
	getProject,
	getProjectTemplateData,
	getProjects,
	getProjectsUsers,
	projectsActions,
} from 'src/old-app/store/projectsSlice'
import { getUsers } from 'src/old-app/store/userSlice'
import Files from './Files'
import { fileUpload, useCreateFileMutation } from 'src/old-app/service/File'
import { getTask, getTaskTypes } from 'src/old-app/store/tasksSlice'
import ErrorText from '../../ui/ErrorText'
import MySelect from '../../ui/MySelect'
import { useCreateFileTaskMutation } from 'src/old-app/service/TasksFile'
import { useNavigate, useParams } from 'react-router-dom'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import getMentionIds from 'src/old-app/utils/getMentionIds'
import ProjectUsersSelect from '../../task/ProjectUsersSelect'
import SelectTaskPriority from '../../task/SelectTaskPriority'
import moment from 'moment'
import TaskHashtagSelect from '../../shared/TaskHashtagSelect'
import MD from './MD'
import TaskParams from './TaskParams'

import TaskTemplateSelect from '../../shared/TaskTemplateSelect'
import { useGetUser } from 'src/shared/hooks'
import { Autocomplete, InputContainer } from 'src/shared/components'
import { uniqueId } from 'lodash'
import FuseUtils from 'src/@fuse/utils/FuseUtils'
import TaskPriorityChoice from '../../../../shared/components/task-priority-choice'
import TaskTypesChoice from '../../../../shared/components/task-types-choice'
import TaskTagsChoice from '../../../../shared/components/task-tags-choice'
import ProjectUsersChoice from '../../../../shared/components/project-users-choice'
import { useTranslation } from 'react-i18next'

const CreateTaskForTemplate = ({ isMy, isEpic }) => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		assignees: yup.array().min(1, t('performer_is_required')),
		deadline: yup.string().max(10, t('invalid_date')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
	})
	const mySchema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		deadline: yup.string().max(10, t('invalid_date')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
		params: yup.array().of(
			yup.object().shape({
				value: yup.string().required(t('title_is_required')),
				label: yup.string().required(t('value_is_required')),
			})
		),
	})
	const projectsUsers = useSelector(getProjectsUsers)
	const project = useSelector(getProject)
	const projects = useSelector(getProjects)
	const taskTypes = useSelector(getTaskTypes)
	const curTask = useSelector(getTask)
	const users = useSelector(getUsers)
	const user = useGetUser()
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const projectTempalteData = useSelector(getProjectTemplateData)
	const {
		setValue,
		handleSubmit,
		register,
		control,
		getValues,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			files: [],
			subscribers: [],
			assignees: [],
			hashtags: [],
			params: [],
			type: null,
			priority: null,
			template: {},
			need_report: false,
			need_deadline_confirme: false,
		},
		resolver: yupResolver(isMy ? mySchema : schema),
	})

	// const [drag, setDrag] = useState(false)
	const [isDisabled, setIsDisabled] = useState(false)

	const template = watch('template')
	const paramsData = watch('params')

	useEffect(() => {
		if (template) {
			const p = []

			Object.entries(template?.params_template ?? {}).map(([field, value], index) => {
				p.push({
					id: index,
					label: field,
					order: index,
					value,
				})
			})

			setValue('params', p)
			setValue('description', template?.description)
		}
	}, [template])

	const createTasksHandler = async (data) => {
		try {
			const mentionsIds = data?.description ? getMentionIds(data?.description) : []

			const deadline = moment(data?.deadline).format()
			const projectId = data?.project?.id
			const assigneeIds = data?.assignees
			const subscriberIds = data?.subscribers

			const myReduceParams = data.params
				.filter((item) => item.label && item.value)
				.reduce((acc, object) => {
					const field = object.label
					acc[field] = object.value

					return acc
				}, {})

			const tasks = []

			assigneeIds.map(({ id: assigneeId }) => {
				const curDate = moment().format()
				const assignee = projectTempalteData?.template?.Users?.find((item) => item.user_id === assigneeId)
				const status = projectTempalteData?.template?.Statuses?.find((item) => item.is_initial)
				const priority = projectTempalteData?.template?.Priorities?.find((item) => item.id === data?.priority?.id)

				const taskUuid = v4()
				const taskBody = {
					id: taskUuid,

					deleted_at: null,
					title: data?.title,
					author: projectTempalteData.author,
					author_view: null,
					description: data?.description,
					deadlines: null,
					type_id: data?.type?.id,
					type: data?.type,

					task_priority_id: data?.priority?.id,
					priority: priority,
					task_status: {
						project_status: status,
						is_actual: true,
						task_id: taskUuid,
						project_status_id: status.id,
						created_at: moment().format(),
						updated_at: moment().format(),
					},
					actual_status_id: status.id,
					assignee_id: assigneeId,
					assignee: assignee.user,

					protocol_id: null,
					order: +uniqueId(),
					complete_file: null,
					slug: projectTempalteData.name + uniqueId(),
					need_deadline_confirm: data?.need_deadline_confirm,
					hashtags: data.hashtags,
					need_report: data?.need_report,
					time_tracking_by_button: null,
					spent_time_by_button: 0,
					time_tracking_by_column: null,
					time_tracking_by_input: null,
					spent_time_by_input: 0,
					time_tracking_by_input_hours: null,
					spent_time_by_input_hours: 0,
					total_time: 0,
					epic: null,
					subscribers: null,
					result: '',
					updater_id: projectTempalteData.author,
					updater: null,
					subtask_completed: false,
					result_representation: data?.result_representation,
					hierarchy: projectTempalteData.name + uniqueId(),
					create_order: +uniqueId(),
					incoming: false,
					params: myReduceParams,
					created_at: curDate,
					updated_at: curDate,
					parent_id: isEpic && curTask ? curTask?.id : undefined,
					// has_subtask: isEpic && curTask ? true : false,
				}

				tasks.push(taskBody)
			})

			let newMoreTasks = [...tasks, ...(projectTempalteData?.template?.Tasks ?? [])]

			if (isEpic) {
				newMoreTasks = newMoreTasks.map((newTaskItem) => {
					if (newTaskItem.id === curTask?.id) {
						return {
							...newTaskItem,
							type_id: '9ff9911d-d33e-4909-96b5-4730b2db1623',
							type: {
								id: '9ff9911d-d33e-4909-96b5-4730b2db1623',
								created_at: '2022-12-08T16:45:32Z',
								updated_at: '2022-12-08T16:45:32Z',
								deleted_at: null,
								name: 'Эпик',
								color: '#5249e5',
							},
						}
					}
					return newTaskItem
				})
			}

			dispatch(projectsActions.putProjectTemplateTasks(newMoreTasks))
			dispatch(dialogActions.setDialog(false))
		} catch (error) {
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	useEffect(() => {
		if (taskTypes.length !== 0) {
			setValue('type', taskTypes[0])
		}
		if (project) {
			setValue('project', project)
		}
	}, [taskTypes, project])

	const performerLabelRender = (value) => `${value.first_name} ${value.last_name}`

	const typeLabelRender = (value) => {
		return value.name
	}

	const dragStartHandler = (e) => {
		e.preventDefault()
		// setDrag(true)
	}

	const dragLeaveHandler = (e) => {
		e.preventDefault()
		// setDrag(false)
	}

	const dropHandler = (e) => {
		e.preventDefault()
		const newfiles = [...e.dataTransfer.files]
		const oldFiles = getValues('files')
		const files = [...oldFiles, ...newfiles]
		setValue('files', files)
		// setDrag(false)
	}

	return (
		<form
			onDragStart={dragStartHandler}
			onDragLeave={dragLeaveHandler}
			onDragOver={dragStartHandler}
			onDrop={dropHandler}
			onSubmit={handleSubmit(createTasksHandler)}
			className="tt-w-600 tt-h-full  tt-relative tt-overflow-x-hidden"
		>
			<div className="tt-flex tt-flex-col tt-h-full ">
				<DialogHeader title={t('new.task')} />
				<DialogMain>
					<div className="tt-flex tt-flex-col tt-gap-8">
						{/* <Controller
							render={({ field: { value, onChange } }) => (
								<TaskTemplateSelect
									project_id={project?.id}
									errors={errors}
									displayEmpty
									field="template"
									label="Шаблоны"
									onChange={onChange}
									value={value}
								/>
							)}
							name="template"
							control={control}
						/> */}
						<div className="tt-flex tt-flex-col tt-w-full tt-flex-3">
							<div className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</div>
							<TextField
								id="task-title"
								{...register('title')}
								error={!!errors.title}
								placeholder={t('title')}
								className="dialig-input"
								size="small"
							/>
							<ErrorText errors={errors} field="title" />
						</div>
						<div className="tt-flex tt-items-center tt-gap-8">
							<InputContainer label={t('priority')} error={errors?.priority}>
								<Controller
									render={({ field: { value, onChange } }) => (
										<TaskPriorityChoice
											placeholder={t('priority')}
											defaultList={projectTempalteData?.template?.Priorities ?? []}
											value={value}
											onChange={onChange}
										/>
									)}
									name="priority"
									control={control}
								/>
							</InputContainer>
							<InputContainer label={t('type')} error={errors?.type}>
								<Controller
									render={({ field: { value, onChange } }) => (
										<TaskTypesChoice placeholder={t('type')} value={value} onChange={onChange} />
									)}
									name="type"
									control={control}
								/>
							</InputContainer>
						</div>

						<div className="tt-flex tt-flex-col tt-w-full">
							<div className="tt-text-14 tt-font-semibold tt-mb-4">{t('description')}</div>

							<Controller
								render={({ field: { onChange, value } }) => <MD value={value} onChange={onChange} />}
								name="description"
								control={control}
							/>

							<ErrorText errors={errors} field="description" />
						</div>
						<div className="tt-flex tt-flex-col tt-w-full">
							<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('expected_results')}</span>

							<TextField
								multiline
								{...register('result_representation')}
								error={!!errors.result_representation}
								placeholder={t('expected_results')}
								className="dialig-input"
								size="small"
							/>
							<ErrorText errors={errors} field="result_representation" />
						</div>

						{/* <Controller
							render={({ field: { value, onChange } }) => <Files id="task-files" onChange={onChange} value={value} />}
							name="files"
							control={control}
						/> */}

						<div className="tt-flex tt-items-center tt-gap-8">
							{/* deadline */}

							<InputContainer label={t('tags')} error={errors?.hashtags}>
								<Controller
									render={({ field: { value, onChange } }) => (
										<TaskTagsChoice project={project?.id} placeholder={t('tags')} value={value} onChange={onChange} />
									)}
									name="hashtags"
									control={control}
								/>
							</InputContainer>
						</div>

						<div className="tt-flex tt-items-center tt-gap-8">
							{!isMy && (
								<InputContainer label={t('performers')} error={errors?.assignees}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<ProjectUsersChoice
												placeholder={t('performers')}
												defaultList={projectTempalteData?.template?.Users?.map((user) => user?.user) ?? []}
												value={value}
												onChange={onChange}
											/>
										)}
										name="assignees"
										control={control}
									/>
								</InputContainer>
							)}
						</div>

						{/* <div className="flex flex-col w-full">
							<span className="text-14 font-semibold mb-4">Наблюдатели</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										placeholder="Наблюдатели"
										optionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
										renderOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
										list={projectsUsers.map((item) => item.user)}
										value={value}
										onChange={onChange}
									/>
								)}
								name="subscribers"
								control={control}
							/>

							<ErrorText errors={errors} field="subscribers" />
						</div> */}

						{/* performer */}
						<div>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TaskParams errors={errors} value={value} onChange={onChange} />
								)}
								name="params"
								control={control}
							/>
						</div>
						{!isMy && (
							<div>
								{project?.with_administration && (
									<Controller
										render={({ field: { value, onChange } }) => (
											<FormControlLabel
												id="task-need_report"
												control={<Checkbox checked={value} onChange={onChange} />}
												label={t('report_is_required')}
											/>
										)}
										name="need_report"
										control={control}
									/>
								)}
							</div>
						)}
					</div>
				</DialogMain>
				<DialogFooter isDisabled={isDisabled} />
			</div>
		</form>
	)
}

export default CreateTaskForTemplate
