import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { requestType, TaskСonfirmatory, TaskСonfirmatoryDeadline } from 'src/entities/task'
import { useSelector } from 'react-redux'
import { getTask } from 'src/old-app/store/tasksSlice'
import { Tab, Tabs } from '@mui/material'
import CompleteFiles from 'src/old-app/components/task/CompleteFiles'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

interface IProps {
	id: string
}

function TabPanel(props: any) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`task-adminpanel-${index}`}
			aria-labelledby={`task-admin-${index}`}
			{...other}
			className="tt-py-16 tt-w-full"
		>
			{children}
		</div>
	)
}

function a11yProps(index: any) {
	return {
		// id: `task-admin-${index}`,
		'aria-controls': `task-adminpanel-${index}`,
	}
}

const TaskAdministration: React.FC<IProps> = ({ id }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const taskStatus = task.task_status

	const isShowCancel = taskStatus?.project_status?.is_request

	// const isShowCancel = task?.confirmation_status === 'ON_CONFIRMATION'

	const [value, setValue] = React.useState(0)

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue)
	}

	const items = useMemo(() => {
		const is = [
			{
				id: 2,
				label: t('task_completion_report'),
				slug: 'report',
				children: <CompleteFiles id={getID({ prefix: id, field: 'report' })} />,
			},
			{
				id: 3,
				label: t('confirms_the_task'),
				slug: 'confirmation',
				children: (
					<TaskСonfirmatory
						id={getID({ prefix: id, field: 'confirmation' })}
						isShowCancel={isShowCancel}
						type={requestType.TASK_STATUS}
						requestId={task?.id}
						confirmations={task?.confirmations ?? []}
					/>
				),
			},
			{
				id: 4,
				label: t('confirms_the_deadline'),
				slug: 'deadline',
				children: <TaskСonfirmatoryDeadline id={getID({ prefix: id, field: 'deadline' })} />,
			},
		]
		return is
	}, [task])

	return (
		<div className={styles['task-administration']}>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider' }}
			>
				{items?.map((item, index) => (
					<Tab
						id={getID({ prefix: [id, item.slug].join('-'), type: 'button_tab' })}
						label={item.label}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>

			{items?.map((item, index) => (
				<TabPanel value={value} index={index}>
					{item.children}
				</TabPanel>
			))}
		</div>
	)
}

export default TaskAdministration
