/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { getStatuses, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProject, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useClearTaskContractTypeParamsMutation,
	useCreateTaskHashtagLinkMutation,
	useCreateTaskSubscriberMutation,
	useDeleteCompleteFileMutation,
	useDeleteTaskHashtagMutation,
	useDeleteTaskSubscriberMutation,
	useUpdateTaskMutation,
} from 'src/old-app/service/Tasks'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import getMentionIds from 'src/old-app/utils/getMentionIds'
import MD from '../dialogs/task/MD'
import TaskParams from '../dialogs/task/TaskParams'
import { Autocomplete, InputContainer, UsersChoice } from 'src/shared/components'
import FilesTable from './FilesTable'
import Files from '../dialogs/task/Files'
import { fileUpload } from '../../service/File'
import { useCreateFileTaskMutation, useDeleteFileTaskMutation } from '../../service/TasksFile'
import TaskTagsChoice from '../../../shared/components/task-tags-choice'
import ProjectUsersChoice from '../../../shared/components/project-users-choice'
import TaskTypesChoice from '../../../shared/components/task-types-choice'
import { checkFileSize } from '../../../shared/lib/checkFileSize'
import { useTranslation } from 'react-i18next'
import { ContractDirectorySelect } from 'src/@features/task'
import LocalTemporaryChangesController from './components/LocalTemporaryChangesController'
import TaskStatusesChoice from 'src/shared/components/task-statuses-choice'
import UploadedTaskCompleteFilesList from 'src/@widgets/task/ui/task-finish/components/UploadedTaskCompleteFilesList'
import { useCreateTaskCompleteFileMutation } from 'src/old-app/service/TaskCompleteFile'
import { useGetUser } from 'src/shared/hooks'

const EditTasks = ({ taskRefetch, setIsEdit }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const taskFiles = task.task_files ?? []
	const project = useSelector(getProject) ?? task?.project
	const taskStatuses = useSelector(getStatuses)
	const [attachedCompleteFilesAreRequired, setAttachedCompleteFilesAreRequired] = useState(false)
	const [uploadedTaskCompleteFiles, setUploadedTaskCompleteFiles] = useState([])
	const [deleteCompleteFile] = useDeleteCompleteFileMutation()
	const [createTaskCompleteFile, taskCompleteFileResult] = useCreateTaskCompleteFileMutation()
	const [clearTaskContractTypeParams, clearTaskContractTypeParamsResult] = useClearTaskContractTypeParamsMutation()
	const user = useGetUser()

	const urlParams = useParams()

	const isUser = useSelector(getProjectUserRole)

	const schema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
		status: yup.object().required(t('task_status_is_required')).nullable(),
		assignees: yup.array().min(1, t('performer_is_required')),
		task_complete_files: yup.array().min(attachedCompleteFilesAreRequired ? 1 : 0, t('completion_report_is_required')),
		contract_type_param: yup
			.object()
			.when('selected_contract_type_param_is_required', {
				is: true,
				then: yup.object().required(t('regulatory_document_parameter_is_required')).nullable(),
			})
			.nullable(),
	})

	const {
		setValue,
		handleSubmit,
		control,
		register,
		formState: { errors },
		watch,
		getValues,
	} = useForm({
		defaultValues: {
			assignees: [],
			type: null,
			status: null,
			contract_type: null,
			deleteHashtags: [],
			hashtags: [],
			subscribers: [],
			params: [],
			need_report: false,
			is_contract: false,
			files: [],
			task_complete_files: [],
			contract_type_param: null,
			selected_contract_type_param_is_required: false,
		},
		resolver: yupResolver(schema),
	})

	const selectedStatus = watch('status')
	const taskReportIsRequired = watch('need_report')
	const selectedContactType = watch('contract_type')

	const currentTaskStatus = task?.task_status?.project_status
	const currentTaskContractType = task?.contract_type?.contract_type ?? null
	const currentTaskContractTypeParam = task?.contract_type?.selected_param ?? null

	useEffect(() => {
		if (selectedContactType?.id !== task?.contract_type?.contract_type?.id) {
			setValue('contract_type_param', null)
		}

		if (selectedStatus?.is_request && currentTaskStatus?.is_request) {
			setValue('contract_type', currentTaskContractType)
			setValue('contract_type_param', currentTaskContractTypeParam)
		}

		setValue(
			'selected_contract_type_param_is_required',
			selectedStatus?.is_request && selectedContactType && selectedContactType?.params?.length > 0
		)
	}, [selectedStatus, selectedContactType])

	/////////////// Функционал для временного локального сохранения данных из полей ///////////////
	const setValueHandler = (fieldName, fieldValue) => setValue(fieldName, fieldValue)
	const getValueHandler = (fieldName) => getValues(fieldName)
	///////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////// Перечисление и описание полей для локального сохранения ///////////////////
	const fieldsForTemporaryStorage = [
		{ fieldName: 'title', fieldTitle: t('title') },
		{ fieldName: 'description', fieldTitle: t('description') },
		{ fieldName: 'result_representation', fieldTitle: t('expected_results') },
	]
	///////////////////////////////////////////////////////////////////////////////////////////////

	const [updateTask, taskResult] = useUpdateTaskMutation()
	const [createTaskSubscriber] = useCreateTaskSubscriberMutation()
	const [deleteTaskSubscriber] = useDeleteTaskSubscriberMutation()

	const [deleteHashtag] = useDeleteTaskHashtagMutation()
	const [createTasksHashtagLink] = useCreateTaskHashtagLinkMutation()

	useEffect(() => {
		if (taskResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: taskResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [taskResult])

	useEffect(() => {
		setValue('is_contract', project?.isContract)
	}, [project?.isContract])

	useEffect(() => {
		if (!task?.complete_file?.length) return
		setUploadedTaskCompleteFiles(task?.complete_file)
	}, [task])

	useEffect(() => {
		if (selectedStatus?.is_request && taskReportIsRequired && !uploadedTaskCompleteFiles?.length) {
			setAttachedCompleteFilesAreRequired(true)
		} else {
			setAttachedCompleteFilesAreRequired(false)
		}
	}, [selectedStatus, taskReportIsRequired, uploadedTaskCompleteFiles])

	//todo refactor form.reset
	const setValues = async () => {
		setValue('title', task?.title)
		setValue('hashtags', task?.hashtags ?? [])
		setValue('subscribers', task?.subscribers ?? [])
		setValue('description', task?.description ?? '')
		setValue('result_representation', task?.result_representation ?? '')
		setValue('assignees', [task?.assignee] ?? [])
		setValue('need_report', task?.need_report ?? false)
		setValue('type', task?.type)
		setValue('status', task?.task_status?.project_status)
		setValue('contract_type', task?.contract_type?.contract_type)
		setValue('contract_type_param', currentTaskContractTypeParam)

		const paramsArr = []
		Object.entries(task.params).map(([label, value], index) => {
			paramsArr.push({ id: index + 1, order: index + 1, label, value })
		})
		setValue('params', paramsArr)
	}

	useEffect(() => {
		if (task) setValues()
	}, [task])

	const closeEdit = () => {
		setIsEdit(false)
	}

	const [createFileTask] = useCreateFileTaskMutation()
	const [deleteFileTask] = useDeleteFileTaskMutation()

	const [forDeleteFilesIDs, setForDeleteFilesIDs] = useState([])
	const withoutToDeleteFiles = useMemo(
		() => taskFiles.filter(({ id }) => !forDeleteFilesIDs.includes(id)),
		[taskFiles, forDeleteFilesIDs]
	)

	const updateTaskHandler = async (data) => {
		for (const file of data.files) {
			if (!checkFileSize(file.size)) {
				dispatch(
					showMessage({
						message: t('maximum_file_size'),
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'warning',
					})
				)
				return
			}
		}

		for (const task_complete_file of data.task_complete_files) {
			if (!checkFileSize(task_complete_file.size)) {
				dispatch(
					showMessage({
						message: t('maximum_file_size'),
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'warning',
					})
				)
				return
			}
		}

		dispatch(interfaceActions.setIsLoading(true))

		await Promise.all(
			data?.task_complete_files.map(async (item) => {
				const fileData = await fileUpload(item)
				if (!fileData.data) return
				await createTaskCompleteFile({
					author_id: user?.id,
					file_id: fileData?.data?.id,
					task_id: task?.id,
				})
			})
		)

		const newMentionsIds = data?.description ? getMentionIds(data?.description) : []
		const newSubscribers = data?.subscribers?.map((item) => item?.id) ?? []
		const oldSubscribers = task?.subscribers?.map((item) => item?.id) ?? []

		const createSubscribers = newSubscribers?.filter(
			(newSubsId) => !oldSubscribers.find((oldSubsId) => oldSubsId === newSubsId)
		)
		const deleteSubscribers = oldSubscribers?.filter(
			(oldSubsId) => !newSubscribers.find((newSubsId) => newSubsId === oldSubsId)
		)

		const myReduceParams = data?.params
			?.filter((item) => item.label && item.value)
			?.reduce((acc, object) => {
				const field = object.label
				acc[field] = object.value

				return acc
			}, {})

		//>Очистка селектед_парам и контракт_тайп через дополнительную ручку
		const clearContractTypeIsNeeded = project?.isContract
			? !data?.contract_type?.id && data?.contract_type?.id !== currentTaskContractType?.id
			: false
		const clearContractTypeSelectedParamIsNeeded = project?.isContract
			? !data?.contract_type_param?.id && data?.contract_type_param?.id !== currentTaskContractTypeParam?.id
			: false

		if (clearContractTypeIsNeeded) {
			await clearTaskContractTypeParams({
				taskID: task?.id,
				body: {
					dictionary_id: null,
				},
			})
		}
		if (clearContractTypeSelectedParamIsNeeded && !clearContractTypeIsNeeded) {
			await clearTaskContractTypeParams({
				taskID: task?.id,
				body: {
					dictionary_id: data?.contract_type?.id,
					selected_param: null,
				},
			})
		}
		//<

		const body = {
			body: {
				title: data?.title,
				//> Обновление селектед_парам и контракт_тайп (если null, то бэк ничего не делает - очистка выше)
				contract_type:
					data?.contract_type?.id && data?.contract_type?.id !== currentTaskContractType?.id
						? data?.contract_type?.id
						: null,
				selected_param:
					data?.contract_type_param?.id && data?.contract_type_param?.id !== currentTaskContractTypeParam?.id
						? data?.contract_type_param?.id
						: null,
				//<
				users_ids: newMentionsIds,
				description: data?.description,
				result_representation: data?.result_representation,
				assignee_id: data?.assignees?.map((item) => item?.id),
				params: myReduceParams,
				need_report: data?.need_report,
				type_id: data?.type?.id,
				project_status_id: data?.status?.id,
				task_priority_id: task?.priority?.id,
			},

			id: urlParams.taskId,
		}

		if (forDeleteFilesIDs.length) {
			const deleteFileHandler = async (fileId) => {
				await deleteFileTask({ file_id: fileId, task_id: task?.id })
				dispatch(tasksActions.deleteFile(fileId))
			}

			await Promise.all(forDeleteFilesIDs.map(async (toDelID) => deleteFileHandler(toDelID)))
		}

		if (data?.files?.length) {
			await Promise.all(
				await data?.files?.map(async (fileItem) => {
					const fileData = await fileUpload(fileItem)
					if (!fileData.data) return
					const fileId = fileData?.data?.id
					await createFileTask({
						file_id: fileId,
						task_id: urlParams.taskId,
					})
				})
			)
		}

		const newTags = data?.hashtags?.map((item) => item?.id) ?? []
		const oldTags = task?.hashtags?.map((item) => item?.id) ?? []

		const createTags = newTags?.filter((newUserId) => !oldTags.find((oldUserId) => oldUserId === newUserId))
		const deleteTags = oldTags?.filter((oldUserId) => !newTags.find((newUserId) => newUserId === oldUserId))

		await Promise.all(
			createTags?.map(async (hashtagId) => {
				await createTasksHashtagLink({ id: task?.id, hashtagId: hashtagId })
			})
		)

		await Promise.all(
			deleteTags?.map(async (hashtagId) => {
				await deleteHashtag({ task_id: task?.id, hashtag_id: hashtagId })
			})
		)

		await Promise.all(
			createSubscribers?.map(async (subscriberId) => {
				await createTaskSubscriber({ task_id: task?.id, user_id: subscriberId })
			})
		)

		await Promise.all(
			deleteSubscribers?.map(async (subscriberId) => {
				await deleteTaskSubscriber({ task_id: task?.id, user_id: subscriberId })
			})
		)

		await updateTask(body)

		dispatch(
			showMessage({
				message: t('task_is_updated'),
				autoHideDuration: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
		closeEdit()
		////////////////////// Очистка временных локальных сохранений //////////////////////
		if (!taskResult?.isError) {
			window.localStorage.removeItem(task?.id)
		}
		////////////////////////////////////////////////////////////////////////////////////
	}

	const taskFilesDropHandler = (e) => {
		e.preventDefault()
		const newfiles = [...e.dataTransfer?.files]
		const oldFiles = getValues('files')
		const files = [...oldFiles, ...newfiles]
		setValue('files', files)
	}

	const taskCompleteFilesDropHandler = (e) => {
		e.preventDefault()
		const newfiles = [...e.dataTransfer?.files]
		const oldFiles = getValues('task_complete_files')
		const files = [...oldFiles, ...newfiles]
		setValue('task_complete_files', files)
	}

	const checkStatusDisability = (status) => {
		if (task?.subtasks && !task?.subtask_completed && (status?.is_final || status?.is_request)) {
			return true
		}
		if (status?.is_final && project?.with_administration) {
			return true
		}
		return false
	}

	const removeTaskCompleteFileHandler = (taskFileID) => {
		if (uploadedTaskCompleteFiles?.length > 1) {
			const preFilteredTaskCompleteFiles = uploadedTaskCompleteFiles.filter((uploadedTaskCompleteFile) => {
				return uploadedTaskCompleteFile?.id !== taskFileID
			})
			setUploadedTaskCompleteFiles(preFilteredTaskCompleteFiles)
		} else {
			setUploadedTaskCompleteFiles([])
		}
		const data = { file_id: taskFileID, task_id: task?.id }
		deleteCompleteFile(data)
	}

	const canChangeContractType = !(currentTaskStatus?.is_final || currentTaskStatus?.is_request)
		? true
		: currentTaskStatus?.is_final && !selectedStatus?.is_final
		? true
		: false

	const showContractTypeParamsSelect =
		project?.isContract && selectedContactType && selectedContactType?.params?.length > 0

	return (
		<form onSubmit={handleSubmit(updateTaskHandler)}>
			<div className="tt-grid tt-grid-cols-6 tt-gap-8 tt-pb-24">
				<div className="tt-col-span-6">
					<LocalTemporaryChangesController
						id={`${task?.id}_controller`}
						localStorageKey={task?.id}
						observableFields={fieldsForTemporaryStorage}
						setFieldValue={setValueHandler}
						getFieldValue={getValueHandler}
					/>
				</div>
				<InputContainer className="tt-col-span-6" label={t('title')} error={errors?.title}>
					<TextField
						id="task-title"
						{...register('title')}
						error={!!errors.title}
						placeholder={t('title')}
						className="dialig-input"
						size="small"
					/>
				</InputContainer>
				<InputContainer label={t('performers')} className="tt-col-span-6" error={errors?.assignees}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<ProjectUsersChoice
								projectId={project?.id}
								value={value}
								placeholder={t('performers')}
								onChange={onChange}
							/>
						)}
						name="assignees"
						control={control}
					/>
				</InputContainer>

				{project?.isContract && (
					<InputContainer className="tt-col-span-6" label={t('works_type')} error={errors?.contract_type}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ContractDirectorySelect
									placeholder={t('works_type')}
									disabled={!canChangeContractType}
									onChange={onChange}
									value={value}
								/>
							)}
							name="contract_type"
							control={control}
						/>
					</InputContainer>
				)}

				{showContractTypeParamsSelect && (
					<InputContainer
						className="tt-col-span-6"
						label={t('regulatory_document_parameter')}
						error={errors?.contract_type_param}
					>
						<Controller
							render={({ field: { value, onChange } }) => {
								return (
									<Autocomplete
										multiple={false}
										placeholder={t('regulatory_document_parameter')}
										optionLabel={(option) => option?.name}
										renderOptionLabel={(option) => option?.name}
										list={selectedContactType?.params ?? []}
										value={value}
										onChange={onChange}
										disabled={!canChangeContractType}
									/>
								)
							}}
							name="contract_type_param"
							control={control}
						/>
					</InputContainer>
				)}

				<InputContainer className="tt-col-span-3" label={t('type')} error={errors?.type}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskTypesChoice placeholder={t('type')} value={value} onChange={onChange} />
						)}
						name="type"
						control={control}
					/>
				</InputContainer>

				<InputContainer className="tt-col-span-3" label={t('status')} error={errors?.status}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskStatusesChoice
								placeholder={t('status')}
								value={value}
								taskStatuses={taskStatuses}
								onChange={onChange}
								checkStatusDisability={checkStatusDisability}
								disabled={
									(project?.with_administration && task?.task_status?.project_status?.is_request) ||
									taskStatuses?.length < 2
								}
							/>
						)}
						name="status"
						control={control}
					/>
				</InputContainer>

				<InputContainer className="tt-col-span-6" label={t('description')} error={errors?.description}>
					<Controller
						render={({ field: { onChange, value } }) => <MD value={value} onChange={onChange} />}
						name="description"
						control={control}
					/>
				</InputContainer>

				<InputContainer className="tt-col-span-6" label={t('expected_results')} error={errors?.result_representation}>
					<TextField
						multiline
						{...register('result_representation')}
						error={!!errors.result_representation}
						placeholder={t('expected_results')}
						className="dialig-input"
						size="small"
					/>
				</InputContainer>

				<InputContainer className="tt-col-span-6" label={t('observers')} error={errors?.subscribers}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<UsersChoice placeholder={t('observers')} value={value} onChange={onChange} />
						)}
						name="subscribers"
						control={control}
					/>
				</InputContainer>

				<InputContainer label={t('tags')} className="tt-col-span-6" error={errors?.hashtags}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskTagsChoice projectId={project?.id} placeholder={t('tags')} value={value} onChange={onChange} />
						)}
						name="hashtags"
						control={control}
					/>
				</InputContainer>

				{project?.with_administration || (!project?.with_administration && task?.need_report) ? (
					<Controller
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								disabled={!!isUser}
								id="task-need_report"
								className="tt-col-span-6 tt-z-0"
								control={<Checkbox disabled={!!isUser} checked={value} onChange={onChange} />}
								label={t('report_is_required')}
							/>
						)}
						name="need_report"
						control={control}
					/>
				) : null}

				{selectedStatus?.is_request && taskReportIsRequired && (
					<>
						<InputContainer
							className="tt-col-span-6 tt-z-0"
							label={t('task_completion_report')}
							error={errors?.task_complete_files}
						>
							<Controller
								render={({ field: { value, onChange } }) => (
									<div className="w-full" onDrop={taskCompleteFilesDropHandler}>
										<Files
											id={'task-complete-files'}
											label={''}
											withDropHandlers
											className={undefined}
											onChange={onChange}
											value={value}
										/>
									</div>
								)}
								name="task_complete_files"
								control={control}
							/>
						</InputContainer>
						<div className="tt-col-span-6">
							<UploadedTaskCompleteFilesList
								title={t('previosly_uploaded_reports')}
								uploadedTaskCompleteFiles={uploadedTaskCompleteFiles}
								removeTaskCompleteFile={removeTaskCompleteFileHandler}
							/>
						</div>
					</>
				)}

				<div className="tt-col-span-6 tt-z-0">
					<span className="tt-text-14 tt-self-start tt-font-semibold tt-mb-4">{t('files')}</span>
					<FilesTable
						files={withoutToDeleteFiles}
						deleteFile={(id) => {
							setForDeleteFilesIDs((curr) => [...curr, id])
						}}
						hideEmptyPlaceholder
					/>

					<Controller
						render={({ field: { value, onChange } }) => (
							<div className="w-full" onDrop={taskFilesDropHandler}>
								<Files
									className="tt-col-span-6 tt-w-full"
									withDropHandlers
									label={null}
									id="task-files"
									onChange={onChange}
									value={value}
								/>
							</div>
						)}
						name="files"
						control={control}
					/>
				</div>

				<Controller
					render={({ field: { value, onChange } }) => (
						<TaskParams className="tt-col-span-6" errors={errors} value={value} onChange={onChange} />
					)}
					name="params"
					control={control}
				/>
			</div>
			<div className="tt-sticky tt-bottom-0 tt-z-50 tt-translate-y-16">
				<div className="tt-w-full tt-max-w-5xl tt-mx-auto tt-flex tt-gap-12 tt-items-center  tt-py-24 tt-border-t tt-border-gray-200 tt-bg-white">
					<Button onClick={closeEdit} variant="outlined" className="tt-border-gray-200   tt-min-h-32 tt-h-32 tt-px-16">
						{t('cancel')}
					</Button>
					<Button
						type="submit"
						variant="contained"
						className="tt-bg-primary hover:tt-bg-primary-600  tt-text-white tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('save')}
					</Button>
				</div>
			</div>
		</form>
	)
}

export default EditTasks
