import React from 'react'
import TaskDetails from './TaskDetails'

const TaskInfo = ({ offEditDeadline, id }) => {
	return (
		<div>
			<TaskDetails id={id} offEditDeadline={offEditDeadline} />
		</div>
	)
}

export default TaskInfo
