import React, { useState } from 'react'
import styles from './styles.module.scss'
import { InputBase } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { UserProjectsStatusesSelect } from '../../../../old-app/main/tasks/UserProjectsStatusesSelect'
import { UserProjectsPrioritiesSelect } from '../../../../old-app/main/tasks/UserProjectsPrioritiesSelect'
import UsersSelect from '../../../../old-app/components/shared/UserSelect'
import ProjectStatusSelect from '../../../../old-app/components/task/ProjectStatusSelect'
import ProjectPrioritySelect from '../../../../old-app/components/task/ProjectPrioritySelect'
import ProjectUsersSelect from '../../../../old-app/components/task/ProjectUsersSelect'

interface IProps {
	field?: string
	placeholder?: string
	isAll?: boolean
	users?: any[]
}

const UserFilter: React.FC<IProps> = (p) => {
	const { field = 'user', placeholder = 'Пользователь', isAll = false, users } = p

	const { search } = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const value = searchParams.get(field) ?? []

	const getAssigneeValue = Array.isArray(value) ? value : JSON.parse(value)
	const assigneeValue = isAll
		? getAssigneeValue
		: getAssigneeValue.map((item: any) => users?.find((p) => p.id === item))

	const [MoreUsersSelect]: any = isAll ? [UsersSelect] : [ProjectUsersSelect]

	const setParams = (value: any) => {
		const ids = value.map((item: any) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(field, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	return (
		<>
			<MoreUsersSelect
				allProjectsMode={isAll}
				multiple
				selectClassName="new-select tt-text-left filter-select-miltipe"
				wrapperClassName="tt-w-auto"
				hideError
				hideLabel
				displayEmpty
				field={field}
				label={placeholder}
				value={assigneeValue}
				onChange={setParams}
			/>
		</>
	)
}

export default UserFilter
