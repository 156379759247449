import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Menu, MenuItem, Tooltip } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { fileUpload } from 'src/old-app/service/File'
import { getProjectUserRole } from 'src/old-app/store/projectsSlice'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { getUserName } from '../../../../entities/user'
import { useQueryParams } from 'use-query-params'
import { AllMyTasksPageQueryParams } from '../../../main/my/AllMyTasksList'
import { serializeParams } from '../../../utils/serializeRequestParams'
import { getUserID } from 'src/old-app/store/userSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

// const schema = yup.object().shape({})

// const MyKPG = () => {
// 	const dispatch = useDispatch()
// 	const user = useGetUser()
// 	// const project = useSelector(getProject)
// 	// const [isDisabled, setIsDisabled] = useState(false)
//
// 	const {
// 		handleSubmit,
// 		control,
// 		// formState: { errors },
// 		// watch,
// 		// setError,
// 	} = useForm({
// 		defaultValues: {
// 			on_one_page: true,
// 		},
// 		resolver: yupResolver(schema),
// 	})
//
// 	const exportFileHandler = (path, data) => {
// 		const name = `${user?.name}`
//
// 		const params = new URLSearchParams()
//
// 		params.append('assignee_id', user?.id)
// 		params.append('on_one_page', !data.on_one_page)
//
// 		const url = `/${path}/?${params.toString()}`
// 		exportKPGHandler({ url, params, name, dispatch })
// 	}
//
// 	const createProjectHandler = async (data) => {
// 		exportFileHandler('report', data)
// 	}
//
// 	return (
// 		<form onSubmit={handleSubmit(createProjectHandler)} className="w-600">
// 			<DialogHeader title="Выгрузка КПГ" />
// 			<DialogMain>
// 				<div className="flex flex-col mt-10">
// 					<Controller
// 						render={({ field: { value, onChange } }) => (
// 							<FormControlLabel
// 								id="need_deadline_confirm"
// 								className="-mt-6"
// 								control={<Checkbox checked={value} onChange={onChange} />}
// 								label="Разделить проекты по страницам"
// 							/>
// 						)}
// 						name="on_one_page"
// 						control={control}
// 					/>
// 				</div>
// 			</DialogMain>
// 			<DialogFooter rightLabel="Выгрузить КПГ" />
// 		</form>
// 	)
// }

const ExportAllMyTasksKPG = ({ allProjectsMode, hideLeft, id, isTasksIncoming }) => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const isUser = useSelector(getProjectUserRole)
	const userID = useSelector(getUserID)
	const userName = useSelector(getUserName)
	// const project = useSelector(getProject)
	const dispatch = useDispatch()
	const fileRef = useRef(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	// const [searchParams, setSearchParams] = useSearchParams()
	//
	// const searchTask = searchParams.get('searchTask') ?? undefined
	// const slug = searchParams.get('slug') ?? undefined
	//
	// const deadlineStart = searchParams.get(`deadline_start`) ?? undefined
	// const deadlineEnd = searchParams.get(`deadline_end`) ?? undefined
	// const completedAtStart = searchParams.get(`completed_at_start`) ?? undefined
	// const completedAtEnd = searchParams.get(`completed_at_end`) ?? undefined
	// const createdAtStart = searchParams.get(`date_created_at_end`) ?? undefined
	// const createdAtEnd = searchParams.get(`date_created_at_start`) ?? undefined
	// const assignee = searchParams.get('assignee') ?? []
	// const status = searchParams.get('status') ?? []
	// const author = searchParams.get('author') ?? []
	// const priority = searchParams.get('priority') ?? []
	// const type = searchParams.get('type') ?? []
	//
	// const statusValue = Array.isArray(status) ? status : JSON.parse(status)
	// const assigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	// const priorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	// const authorValue = Array.isArray(author) ? author : JSON.parse(author)
	// const typeValue = Array.isArray(type) ? type : JSON.parse(type)
	//
	// const multipleParamsString = setParamsFilters({
	// 	type: typeValue,
	// 	author: authorValue,
	// 	priority: priorityValue,
	// 	statuses: statusValue,
	// 	assignee: assigneeValue,
	// })

	const [searchQueryParams] = useQueryParams(AllMyTasksPageQueryParams)

	const exportFileHandler = (path) => {
		const params = serializeParams({
			params: {
				project_id: searchQueryParams.projects,
				order_sort: searchQueryParams.order_sort,
				slug: searchQueryParams.slug,
				title: searchQueryParams.searchTask,
				start_date_deadline: searchQueryParams.deadline_start,
				end_date_deadline: searchQueryParams.deadline_end,
				start_completed_at: searchQueryParams.completedAtStart,
				end_completed_at: searchQueryParams.completedAtEnd,
				end_date_created_at: searchQueryParams.createdAtEnd,
				start_date_created_at: searchQueryParams.createdAtStart,
				assignee_id: userID,
				...searchQueryParams.fields,
			},
			arraysParams: {
				project_status_id: searchQueryParams.status,
				parent_id: searchQueryParams.author,
				task_priority_id: searchQueryParams.priority,
				type_id: searchQueryParams.type,
				hashtag_id: searchQueryParams.tags,
			},
		})
		// console.log({ params: params.toString() });
		// params.append('project_id', project.id)
		//
		// if (searchTask) {
		// 	params.append('title', searchTask)
		// }
		// if (slug) {
		// 	params.append('slug', slug)
		// }
		// if (deadlineStart) {
		// 	params.append('start_date_deadline', moment(deadlineStart).format())
		// }
		// if (deadlineEnd) {
		// 	params.append('end_date_deadline', moment(deadlineEnd).format())
		// }
		// if (completedAtStart) {
		// 	params.append('start_completed_at', moment(completedAtStart).format())
		// }
		// if (completedAtEnd) {
		// 	params.append('end_completed_at', moment(completedAtEnd).format())
		// }
		// if (createdAtStart) {
		// 	params.append('start_date_created_at', moment(createdAtStart).format())
		// }
		// if (createdAtEnd) {
		// 	params.append('end_date_created_at', moment(createdAtEnd).format())
		// }
		// return;
		const url = `/${path}/?${params}`
		exportKPGHandler({ url, params: new URLSearchParams(params), name: userName, dispatch })
	}

	const exportKPG = () => {
		// exportFileHandler('report')

		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('my-kpg'))
		handleClose()
	}

	const exportTime = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('my-time-kpg'))
		// exportFileHandler('report/time')
		// handleClose()
	}

	const uploadTasksKpg = () => {
		fileRef.current?.focus()
		fileRef.current?.click()
	}

	const onChangeFilesHandler = async (event) => {
		handleClose()

		const file = event.target.files[0]

		if (file) {
			dispatch(interfaceActions?.setIsLoading(true))

			dispatch(
				showMessage({
					message: t('cpg_upload.sending_file'),
					autoHideDuration: null,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'info',
				})
			)

			const toKPG = true //отдает только fetch(), без json()

			const data = await fileUpload(file, 'report/task/upload', toKPG)

			//успешная отправка
			if (data.status >= 200 && data.status < 300) {
				dispatch(
					showMessage({
						message: t('cpg_upload.sent_successfully'),
						autoHideDuration: null,
						variant: 'info',
					})
				)
			}

			try {
				const jsonData = await data.json()

				//Всё ОЧЕНЬ криво! Сразу после успешной отправки файла бэк должен отдавать код 200, а уже потом обрабатывать файл.
				//if (jsonData?.error) {
				if (data.status >= 400) {
					dispatch(
						showMessage({
							//Здесь выводить ошибку с бэка, когда будет готово
							message: t('cpg_upload.error'),
							autoHideDuration: null,
							variant: 'error',
						})
					)
				} else {
					dispatch(
						showMessage({
							message: t('cpg_upload.success'),
							autoHideDuration: null,
							variant: 'success',
						})
					)
				}
			} catch (e) {
				dispatch(
					showMessage({
						message: t('cpg_upload.unknown_error'),
						autoHideDuration: null,
						variant: 'error',
					})
				)
			}

			dispatch(interfaceActions?.setIsLoading(false))
		}
	}

	const _id = getID({ prefix: id, field: 'kpg' })

	return (
		<>
			<Tooltip arrow title={t('cpg_actions')} placement="top-start">
				<Button
					id={getID({ prefix: _id, action: 'manage', type: 'button_open' })}
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					startIcon={<UploadFileIcon />}
					className="tt-text-primary hover:tt-bg-transparent tt-border tt-border-primary-600 tt-text-[0] md:tt-text-14  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('download_or_upload_cpg')}
				</Button>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					disabled={isTasksIncoming}
					id={getID({ prefix: _id, action: 'download', type: 'button_open' })}
					onClick={exportKPG}
				>
					{t('download_cpg')}
				</MenuItem>

				{/*{!isUser && !allProjectsMode &&
				<MenuItem id={getID({ prefix: _id,  action: 'download', field: 'time', type: 'button_open' })} onClick={exportTime}>
					Выгрузить Время
				</MenuItem>}*/}

				{!isUser && (
					<MenuItem
						id={getID({ prefix: _id, action: 'upload', field: 'tasks', type: 'button_open' })}
						onClick={uploadTasksKpg}
					>
						{t('upload_cpg')}
					</MenuItem>
				)}
			</Menu>

			<input
				id={getID({ prefix: _id, action: 'upload', field: 'tasks', type: 'input_file' })}
				accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				onChange={onChangeFilesHandler}
				ref={fileRef}
				hidden
				type="file"
			/>
		</>
	)
}

export default ExportAllMyTasksKPG
