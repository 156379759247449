import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { getProjectTemplateData } from 'src/old-app/store/projectsSlice'
import { AssignmentLateOutlined, TaskAltOutlined } from '@mui/icons-material'
import Button from 'src/shared/components/button'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import FieldContent from 'src/old-app/components/shared/FieldContent'
import { CellEmpty, UserShow } from 'src/shared/components'
import ProjectTemplateInfoEdit from '../project-template-info-edit'
import { getID } from '../../../../shared/config/fields-ids'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ProjectTemplateInfo = ({ isCanEdit }) => {
	const { t } = useTranslation()

	const getStatusState = (status) => {
		if (status?.is_request) {
			return t('statuses.request')
		}
		if (status?.is_final) {
			return t('statuses.final')
		}
		if (status?.is_initial) {
			return t('statuses.initial')
		}
	}

	const projectTempalteData = useSelector(getProjectTemplateData)

	const [isEdit, setIsEdit] = useState(false)

	return (
		<div className={styles['project-template-tasks']}>
			<div
				className={[
					'tt-w-full tt-flex tt-flex-col tt-border tt-border-gray-200/50  tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden ',
				].join(' ')}
			>
				<div className="tt-px-10 tt-py-5  tt-bg-gray-200 tt-flex tt-items-center tt-justify-between">
					<div className="tt-text-13 tt-font-semibold tt-text-black">{t('info')}</div>
					{isCanEdit && (
						<Button
							id={getID({ entity: 'project_template', action: 'edit', type: 'button_toggle' })}
							onClick={() => setIsEdit(!isEdit)}
							size="small"
							variant="text"
						>
							{isEdit ? t('cancel') : t('edit')}
						</Button>
					)}
				</div>
				<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
					{isEdit ? (
						<div className={'tt-flex tt-w-full tt-gap-48'}>
							<ProjectTemplateInfoEdit setIsEdit={setIsEdit} />
						</div>
					) : (
						<div className={'tt-flex tt-w-full tt-gap-48 tt-flex-wrap'}>
							<FieldContent
								content={
									<div className="tt-flex tt-flex-col tt-gap-3">
										{projectTempalteData?.template?.Statuses?.map((item) => {
											return (
												<div key={item.order} className="tt-flex tt-gap-8 group tt-items-center tt-truncate">
													<div className="tt-w-12 tt-h-12 tt-rounded-full" style={{ background: item.color }} />
													<div className="tt-flex tt-flex-col tt-gap-2">
														<span className="tt-font-medium tt-truncate">{item.status}</span>
														<span className="tt-truncate tt-text-12 tt-text-gray-500">{getStatusState(item)}</span>
													</div>
												</div>
											)
										})}
									</div>
								}
								icon={<TaskAltOutlined className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('template_statuses')}
							/>

							<FieldContent
								className={'tt-flex-grow-1'}
								content={
									<div className="tt-flex tt-flex-col tt-gap-3">
										{projectTempalteData?.template?.Priorities?.map((item) => (
											<div key={item.level} className="tt-flex tt-gap-8 tt-group tt-items-center ">
												<div className="tt-w-12 tt-h-12 tt-rounded-full " style={{ background: item.color }} />
												<div className="tt-flex tt-flex-col tt-gap-2">
													<span className="tt-font-medium tt-truncate">{item.name}</span>
													<span className="tt-truncate tt-text-12 tt-text-gray-500">
														{`${t('level')}: `}
														{item.level}
													</span>
												</div>
											</div>
										))}
									</div>
								}
								icon={<AssignmentLateOutlined className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('template_priorities')}
							/>
							<FieldContent
								content={
									<div className="tt-flex tt-flex-col tt-gap-3">
										{projectTempalteData?.template?.Commands?.map((item) => (
											<div className={'tt-font-medium'} key={item.command.id}>
												{item.command.name}
											</div>
										))}
									</div>
								}
								icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('teams')}
							/>
							<FieldContent
								content={
									<div className="tt-flex tt-flex-col tt-gap-3">
										{projectTempalteData?.template?.Users?.map((item) => (
											<div className="tt-flex tt-items-center tt-gap-8">
												<UserShow key={item.user_id} user={item.user} />
											</div>
										))}
									</div>
								}
								icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('participants')}
							/>
							<FieldContent
								content={
									projectTempalteData?.template?.Type ? (
										<Chip
											sx={{ height: 24 }}
											className="tt-truncate tt-font-medium tt-inline-flex tt-text-12"
											label={t(`types.${projectTempalteData?.template?.Type?.name}`)}
										/>
									) : (
										<CellEmpty />
									)
								}
								icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('type')}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProjectTemplateInfo
