import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const protocolAPI = createApi({
	reducerPath: 'protocol-api',
	tagTypes: ['Protocol'],

	baseQuery,
	endpoints: (build) => ({
		getProtocols: build.query({
			query: (params) => ({
				url: `/protocol/`,
				params,
			}),
			providesTags: (result) => ['Protocol'],
		}),
		getHashtags: build.query({
			query: (params) => ({
				url: `/protocol-hashtag`,
				params,
			}),
		}),
		createHashtag: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/protocol-hashtag/`,
				body: JSON.stringify(body),
			}),
		}),
		createProtocolHashtag: build.mutation({
			query: ({ id, hashtagId }) => ({
				method: 'POST',
				url: `/protocol/${id}/hashtag/${hashtagId}/link`,
				body: JSON.stringify({}),
			}),
		}),
		sendProtocol: build.mutation({
			query: (id) => ({
				method: 'POST',
				url: `/protocol/${id}/send`,
				body: JSON.stringify({}),
			}),
		}),

		getOneProtocol: build.query({
			query: (id) => ({
				url: `/protocol/${id}`,
			}),
			// providesTags: (result) => ['Protocol'],
		}),

		getProtocolUsers: build.query({
			query: (params) => ({
				method: 'GET',
				url: '/protocol-users/',
				params,
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
		getProtocolAssignee: build.query({
			query: (params) => ({
				method: 'GET',
				url: '/assignee/',
				params,
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
		getProtocolDecisionTask: build.query({
			query: (params) => ({
				method: 'GET',
				url: '/protocol-decision-task/',
				params,
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),

		getProtocolsPlace: build.query({
			query: (params) => ({
				url: `/protocol-place/`,
				params,
			}),
			// providesTags: (result) => ['Protocol'],
		}),
		getProtocolsDecision: build.query({
			query: (id) => ({
				url: `/decision/${id}`,
			}),
		}),

		getProtocolOnePlace: build.query({
			query: (id) => ({
				url: `/protocol-place/${id}`,
			}),
			// providesTags: (result) => ['Protocol'],
		}),

		createProtocol: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/protocol/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Protocol'],
			providesTags: (result) => ['Protocol'],
		}),
		createProtocolUsers: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/protocol-users/',
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
		createDecision: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/decision/',
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
		createProtocolDecisionTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/protocol-decision-task/',
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
		createAssignee: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/assignee/',
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Protocol'],
			// providesTags: (result) => ['Protocol'],
		}),
	}),
})

export const {
	useGetOneProtocolQuery,
	useCreateAssigneeMutation,
	useCreateProtocolDecisionTaskMutation,
	useGetProtocolOnePlaceQuery,
	useGetProtocolsPlaceQuery,
	useCreateProtocolMutation,
	useGetProtocolsQuery,
	useCreateProtocolUsersMutation,
	useCreateDecisionMutation,
	useSendProtocolMutation,
	useGetProtocolUsersQuery,
	useGetProtocolDecisionTaskQuery,
	useGetProtocolsDecisionQuery,
	useGetProtocolAssigneeQuery,
	useGetHashtagsQuery,
	useCreateHashtagMutation,
	useCreateProtocolHashtagMutation,
} = protocolAPI
