/* eslint-disable prettier/prettier */
import React from 'react'

const ErrorText = ({ errors, field, errorField, className }) => {
	return (
		<div className={['tt-h-10', className].join(' ')}>
			{errors
				? errors[field] && <p className="tt-text-12 tt-text-red-600 tt-pl-16">{errorField ?? errors[field]?.message}</p>
				: null}
		</div>
	)
}

export default ErrorText
