import React from 'react'
import styles from './styles.module.scss'
import { Skeleton } from '@mui/material'

interface IProps {}

const UnSkeleton: React.FC<IProps> = () => {
	return (
		<div className="tt-w-full tt-flex-col tt-flex tt-gap-4 tt-border tt-border-gray-200 tt-rounded-lg tt-overflow-hidden">
			<Skeleton sx={{ bgcolor: 'white' }} height={44} />
			<Skeleton sx={{ bgcolor: 'white' }} height={44} />
			<Skeleton sx={{ bgcolor: 'white' }} height={44} />
			<Skeleton sx={{ bgcolor: 'white' }} height={44} />
			<Skeleton sx={{ bgcolor: 'white' }} height={44} />
		</div>
	)
}

export default UnSkeleton
