import React, { memo } from 'react'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import NavLinkAdapter from 'src/@fuse/core/NavLinkAdapter'
import { IconButton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ActionsMenu from '../project/ActionsMenu'
import { getID } from '../../../shared/config/fields-ids'

const SideBarHeader = ({ left, to, hideActions }) => {
	const { search } = useLocation()
	return (
		<div className="tt-flex tt-sticky tt-bg-white tt-h-60 tt-z-30 tt-top-0 tt-bg-white tt-items-center tt-justify-between tt-border-b-1 tt-w-full  tt-px-24 tt-py-12">
			<div>{left}</div>
			<div className="tt-flex tt-items-center tt-gap-8">
				{/*{!hideActions && <ActionsMenu backTo={to} />}*/}
				<IconButton
					className="w-32 h-32"
					id={getID({ place: 'sidebar', type: 'button_close' })}
					component={NavLinkAdapter}
					to={`${to}${search}`}
					size="large"
				>
					<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
				</IconButton>
			</div>
		</div>
	)
}

SideBarHeader.displayName = 'SideBarHeader'
export default SideBarHeader
