import React, { useState } from 'react'
import styles from './styles.module.scss'
import { InputBase } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { UserProjectsStatusesSelect } from '../../../../old-app/main/tasks/UserProjectsStatusesSelect'
import { UserProjectsPrioritiesSelect } from '../../../../old-app/main/tasks/UserProjectsPrioritiesSelect'
import UsersSelect from '../../../../old-app/components/shared/UserSelect'
import ProjectStatusSelect from '../../../../old-app/components/task/ProjectStatusSelect'
import ProjectPrioritySelect from '../../../../old-app/components/task/ProjectPrioritySelect'
import ProjectUsersSelect from '../../../../old-app/components/task/ProjectUsersSelect'
import TaskHashtagSelect from '../../../../old-app/components/shared/TaskHashtagSelect'
import ProjectHashtagSelect from '../../../../old-app/components/shared/ProjectHashtagSelect'
import ProtocolHashtagSelect from '../../../../old-app/components/shared/ProtocolHashtagSelect'

interface IProps {
	field?: string
	placeholder?: string
}

const ProtocolTagsFilter: React.FC<IProps> = (p) => {
	const { field = 'tags', placeholder = 'Теги' } = p

	const { search } = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const tags = searchParams.get(field) ?? []

	const getTagsValue = Array.isArray(tags) ? tags : JSON.parse(tags)

	const setParams = (value: any) => {
		const ids = value.map((item: any) => item.id)
		const params = new URLSearchParams(search)
		if (value) {
			params.set(field, JSON.stringify(ids))
		}
		navigate({
			search: params.toString(),
		})
	}

	return (
		<>
			<ProtocolHashtagSelect
				multiple
				selectClassName="new-select tt-text-left filter-select-miltipe"
				wrapperClassName="tt-w-auto"
				hideError
				hideLabel
				displayEmpty
				field="user"
				label={placeholder}
				useInnerValue
				value={getTagsValue}
				onChange={setParams}
				idAddComponent={undefined}
				id={undefined}
			/>
		</>
	)
}

export default ProtocolTagsFilter
