import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import { Tooltip } from '@mui/material'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineContent from '@mui/lab/TimelineContent'
import { UserShow } from '../index'
import moment from 'moment/moment'
import TaskApproved from '../../../entities/task/components/task-approved'
import TaskDecline from '../../../entities/task/components/task-decline'
import Timeline from '@mui/lab/Timeline'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	confirmations?: any[]
}

const ConfirmsTimeline: FC<IProps> = ({ confirmations }) => {
	const { t } = useTranslation()
	const statuses: any = {
		ON_CONFIRMATION: {
			label: t('statuses.on_confirmation'),
			className: 'tt-bg-gray-600/10 tt-text-gray-600',
		},
		APPROVED: {
			label: t('statuses.approved'),
			className: 'tt-bg-green-600/10 tt-text-green-600',
		},
		REJECTED: {
			label: t('statuses.rejected'),
			className: 'tt-bg-red-600/10 tt-text-red-600',
		},
	}
	return (
		<Timeline
			position="right"
			sx={{
				[`& .${timelineItemClasses.root}:before`]: {
					flex: 0,
					padding: 0,
				},
			}}
		>
			{confirmations?.map((item: any) => {
				const status = statuses[item?.decision]

				return (
					<TimelineItem>
						<TimelineSeparator>
							<TimelineConnector />
							<Tooltip arrow title={status?.label} placement="top-start">
								<TimelineDot className={[status?.className, 'shadow-none'].join(' ')}>{status?.icon}</TimelineDot>
							</Tooltip>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent className="tt-flex tt-gap-4 tt-flex-col tt-justify-center">
							<div className="tt-flex tt-items-center tt-gap-8">
								<UserShow user={item?.user} />
							</div>
							{item?.comment && (
								<div className="tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden">
									<div className="tt-text-12 tt-flex tt-flex-col tt-items-center tt-justify-between tt-font-semibold tt-px-8 tt-py-4 tt-bg-gray-200 tt-text-center tt-text-black">
										<span className="tt-text-gray-500 tt-text-10">{moment(item?.updated_at).format('LLLL')}</span>
										<span>{t('comment')}</span>
									</div>
									<div className="tt-px-8 tt-py-6">
										<p className="tt-w-full tt-break-all">{item?.comment}</p>
									</div>
								</div>
							)}
						</TimelineContent>
					</TimelineItem>
				)
			})}
		</Timeline>
	)
}

export default ConfirmsTimeline
