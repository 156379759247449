import React from 'react'
import { useGetOneUserQuery } from 'src/old-app/service/Users'
import PersonIcon from '@mui/icons-material/Person'
import CustomAvatar from '../ui/CustomAvatar'
import getUser from 'src/old-app/utils/getUser'
import { UserShow } from '../../../shared/components'

const UserCell = ({ id, className, hideAvatar }) => {
	const { data } = useGetOneUserQuery(id)

	const user = data?.data ?? {}
	return <UserShow user={user} />
}

export default UserCell
