import { IconButton, Tooltip } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import React from 'react'
import { useDeleteCommentMutation } from 'src/old-app/service/Comment'
import { getID } from 'src/shared/config/fields-ids'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'

interface CommentDeleteButtonProps {
	commentId: Id
	taskId: Id
}

export const CommentDeleteButton: FC<CommentDeleteButtonProps> = (p) => {
	const { t } = useTranslation()
	const { commentId, taskId } = p
	const [deleteComment] = useDeleteCommentMutation()

	const deleteCommentFn = async () => {
		try {
			await deleteComment({ taskId, commentId })
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<ConfirmDelete
			text={t('delete_comment_dialog.are_you_shure')}
			onSuccess={deleteCommentFn}
			title={t('delete_comment_dialog.header')}
		>
			<Tooltip title={t('delete')}>
				<IconButton id={getID({ prefix: commentId, action: 'delete', type: 'button_delete' })} size="small">
					<DeleteOutlineIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</ConfirmDelete>
	)
}
