import React, { memo, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Controller, useForm } from 'react-hook-form'
import MySelect from 'src/old-app/components/ui/MySelect'
import UsersSelect from 'src/old-app/components/shared/UserSelect'
import TeamsSelect from 'src/old-app/components/shared/TeamsSelect'
import { getProjectTemplateData, projectsActions } from 'src/old-app/store/projectsSlice'
import { useSelector } from 'react-redux'
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import Button from 'src/shared/components/button'
import { uniqueId } from 'lodash'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import moment from 'moment'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import UserCell from 'src/old-app/components/tables/UserCell'
import UserCellShow from 'src/old-app/components/tables/UserCellShow'
import { getMoreTeamUsers } from '../../../../old-app/service/Teams'
import { InputContainer, UsersChoice } from '../../../../shared/components'
import CommandsChoice from '../../../../shared/components/commands-choice'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ProjectTypes from '../../../../shared/components/project-types'
import { useTranslation } from 'react-i18next'

const stateDefaultValue = (status) => {
	if (status?.is_request) {
		return 'is_request'
	}
	if (status?.is_final) {
		return 'is_final'
	}
	if (status?.is_initial) {
		return 'is_initial'
	}
}

function getUniqueUsers(arr) {
	let result = []

	arr.map((item) => {
		if (!result.some((res) => res.user_id === item.user_id)) {
			result.push(item)
		}
	})

	return result
}

const ProjectTemplateInfoEdit = ({ setIsEdit }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		name: yup.string().required(t('template_title_is_required')),
	})

	const projectTempalteData = useSelector(getProjectTemplateData)
	const dispatch = useDispatch()

	const {
		setValue,
		handleSubmit,
		control,
		register,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			teams: [],
			deleteTeams: [],
			users: [],
			deleteUsers: [],
			statuses: [],
			type: null,
			priorities: [],
		},
		resolver: yupResolver(schema),
	})

	const setValues = () => {
		setValue('statuses', projectTempalteData?.template?.Statuses ?? [])
		setValue('priorities', projectTempalteData?.template?.Priorities ?? [])
		setValue('name', projectTempalteData?.name)
		setValue('type', projectTempalteData?.template?.Type)
		setValue('users', projectTempalteData?.template?.Users?.map((item) => item.user) ?? [])
		setValue('teams', projectTempalteData?.template?.Commands?.map((item) => item.command) ?? [])
	}

	useEffect(() => {
		if (projectTempalteData) {
			setValues()
		}
	}, [projectTempalteData])

	const updateTaskHandler = async (data) => {
		let commands = [...projectTempalteData?.template?.Commands]
		let users = [...projectTempalteData?.template?.Users]
		let tasks = [...projectTempalteData?.template?.Tasks]

		const newTeams = data.teams ?? []
		const oldTeams = commands.map((item) => item?.command) ?? []

		const createTeams = newTeams.filter(
			({ id: newUserId }) => !oldTeams.find(({ id: oldUserId }) => oldUserId === newUserId)
		)
		const deleteTeams = oldTeams.filter(
			({ id: oldUserId }) => !newTeams.find(({ id: newUserId }) => newUserId === oldUserId)
		)

		const newUsers = data.users ?? []
		const oldUsers = users.map((item) => item?.user) ?? []

		const createUsers = newUsers.filter(
			({ id: newUserId }) => !oldUsers.find(({ id: oldUserId }) => oldUserId === newUserId)
		)
		const deleteUsers = oldUsers.filter(
			({ id: oldUserId }) => !newUsers.find(({ id: newUserId }) => newUserId === oldUserId)
		)

		await Promise.all(
			deleteTeams?.map(async (item) => {
				commands = commands.filter((c) => c?.command_id !== item?.id)
				const commandUsersData = await getMoreTeamUsers(item?.id)
				const commandUsers = commandUsersData?.data ?? []
				commandUsers.map((commandUser) => {
					users = users.filter((c) => c?.user_id !== commandUser?.user_id)
					tasks = tasks.map((taskItem) => {
						if (taskItem.assignee_id === commandUser?.user_id) {
							return { ...taskItem, assignee_id: undefined, assignee: undefined }
						}
						return taskItem
					})
				})
			})
		)

		await Promise.all(
			createTeams?.map(async (item) => {
				commands = [...commands, { command: item, command_id: item.id }]

				const commandUsersData = await getMoreTeamUsers(item?.id)
				const commandUsers = commandUsersData?.data ?? []
				commandUsers.map((commandUser) => {
					const newUser = { user_id: commandUser?.user_id, user: commandUser?.user }
					users = [...users, newUser]
				})
			})
		)

		deleteUsers?.map((item) => {
			users = users.filter((c) => c?.user_id !== item?.id)
			tasks = tasks.map((taskItem) => {
				if (taskItem.assignee_id === item?.id) {
					return { ...taskItem, assignee_id: undefined, assignee: undefined }
				}
				return taskItem
			})
		})

		createUsers?.map((item) => users.push({ user_id: item.id, user: item }))

		const newStatuses = data?.statuses?.filter((item) => item.status) ?? []
		const newPriorities = data?.priorities?.filter((item) => item.name && item.level) ?? []

		const uniqueUsers = getUniqueUsers(users)
		dispatch(
			projectsActions.getProjectTemplateData({
				...projectTempalteData,
				name: data?.name,
				template: {
					...projectTempalteData?.template,
					Type: data?.type,
					Priorities: newPriorities,
					Statuses: newStatuses,
					Commands: commands,
					Users: uniqueUsers,
					Tasks: tasks.map((task) => {
						const newStatus = newStatuses.find((status) => status.id === task?.actual_status_id)
						const newPriority = newPriorities.find((priority) => priority.id === task?.task_priority_id)

						return {
							...task,
							actual_status_id: newStatus?.id,
							task_status: { project_status: newStatus, ...newStatus },
							task_priority_id: newPriority?.id,
							priority: newPriority,
						}
					}),
				},
			})
		)
		setIsEdit(false)
	}

	const deleteTeams = watch('deleteTeams')
	const deleteUsers = watch('deleteUsers')

	return (
		<div className={styles['project-template-info-edit']}>
			<form className="tt-flex tt-gap-12 tt-flex-col" onSubmit={handleSubmit(updateTaskHandler)}>
				<div className="tt-flex tt-gap-24 tt-flex-wrap">
					<InputContainer label={t('template_title')} error={errors?.name}>
						<TextField
							{...register('name')}
							error={!!errors.name}
							hiddenLabel
							placeholder={t('template_title')}
							className="dialig-input"
							size="small"
						/>
					</InputContainer>
					<div className="tt-flex tt-flex-col tt-w-full tt-flex-1">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('template_statuses')}</span>
						<div className="tt-flex tt-flex-col tt-gap-2">
							<Controller
								render={({ field: { value, onChange } }) => (
									<>
										{value.map((item) => {
											const valueChangeHandler = (newValue, field) => {
												onChange(
													value.map((v) => {
														if (v?.id === item?.id) {
															v[field] = newValue
														}
														return v
													})
												)
											}
											const deleteHandler = () => {
												onChange(value.filter((v) => v.id !== item.id))
											}
											const statusIsChangeHandler = (event) => {
												const state = event.target._wrapperState.initialValue
												const checked = event.target.checked

												onChange(
													value.map((v) => {
														if (v?.id === item?.id) {
															v[state] = checked
														}
														return v
													})
												)
											}

											return (
												<div>
													<div className="tt-flex tt-gap-8 tt-items-center">
														<input
															className=" tt-min-h-32 tt-max-h-32 tt-rounded-lg"
															value={item.color}
															onChange={(event) => valueChangeHandler(event.target.value, 'color')}
															type="color"
														/>
														<TextField
															value={item.status}
															onChange={(event) => valueChangeHandler(event.target.value, 'status')}
															hiddenLabel
															placeholder={t('status_title')}
															className="dialig-input tt-min-h-22 tt-max-h-22 tt-min-w-128 tt-rounded-full"
															size="small"
														/>

														<DeleteOutlineOutlinedIcon
															className="tt-text-gray-500 hover:tt-text-black tt-cursor-pointer"
															onClick={deleteHandler}
														/>
													</div>
													<FormControl>
														<RadioGroup
															value={stateDefaultValue(item)}
															onChange={statusIsChangeHandler}
															aria-labelledby="demo-radio-buttons-group-label"
															name="radio-buttons-group"
															className="tt-flex tt-gap-2 tt-flex-row"
															defaultValue={stateDefaultValue(item)}
														>
															<FormControlLabel
																disabled={!!value.find((item) => item.is_initial === true)}
																value="is_initial"
																control={<Radio />}
																label={t('statuses.initial')}
															/>
															<FormControlLabel
																disabled={!!value.find((item) => item.is_request === true)}
																value="is_request"
																control={<Radio />}
																label={t('statuses.request')}
															/>
															<FormControlLabel
																disabled={!!value.find((item) => item.is_final === true)}
																value="is_final"
																control={<Radio />}
																label={t('statuses.final')}
															/>
														</RadioGroup>
													</FormControl>
												</div>
											)
										})}
										<Button
											variant="outlined"
											type="button"
											className="mt-8"
											onClick={() =>
												onChange([
													...value,
													{
														id: v4(),
														color: '#000000',
														is_final: false,
														is_request: false,
														is_initial: false,
														status: '',
													},
												])
											}
											size="small"
										>
											{t('add')}
										</Button>
									</>
								)}
								name="statuses"
								control={control}
							/>
						</div>
					</div>
					<div className="tt-flex tt-flex-col tt-w-full tt-flex-1">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('template_priorities')}</span>
						<div className="tt-flex tt-flex-col tt-gap-2">
							<Controller
								render={({ field: { value, onChange } }) => (
									<>
										{value.map((item) => {
											const valueChangeHandler = (event, field) => {
												const eventValue = field === 'level' ? Number(event?.target?.value) : event?.target?.value
												onChange(
													value.map((v) => {
														if (v?.id === item?.id) {
															v[field] = eventValue
														}
														return v
													})
												)
											}
											const deleteHandler = () => {
												onChange(value.filter((v) => v.id !== item.id))
											}
											return (
												<div className="tt-flex tt-gap-8 tt-items-center">
													<input
														className=" tt-min-h-32 tt-max-h-32 tt-rounded-lg"
														value={item.color}
														onChange={(event) => valueChangeHandler(event, 'color')}
														type="color"
													/>
													<TextField
														value={item.name}
														onChange={(event) => valueChangeHandler(event, 'name')}
														hiddenLabel
														placeholder={t('priority_title')}
														className="dialig-input tt-min-h-22 tt-max-h-22 tt-min-w-128 tt-rounded-full"
														size="small"
													/>
													<TextField
														value={item.level}
														onChange={(event) => valueChangeHandler(event, 'level')}
														hiddenLabel
														placeholder={t('level')}
														className="dialig-input tt-min-h-22 tt-max-h-22 tt-min-w-128 tt-rounded-full"
														size="small"
														type="number"
													/>

													<DeleteOutlineOutlinedIcon
														className="tt-text-gray-500 hover:tt-text-black tt-cursor-pointer"
														onClick={deleteHandler}
													/>
												</div>
											)
										})}
										<Button
											variant="outlined"
											type="button"
											className="tt-mt-8"
											onClick={() =>
												onChange([
													...value,
													{
														id: v4(),
														color: '#000000',

														name: '',
													},
												])
											}
											size="small"
										>
											{t('add')}
										</Button>
									</>
								)}
								name="priorities"
								control={control}
							/>
						</div>
					</div>
					<InputContainer label={t('project_type')} error={errors?.type}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectTypes value={value} placeholder={t('project_type')} onChange={onChange} />
							)}
							name="type"
							control={control}
						/>
					</InputContainer>
					<InputContainer label={t('teams')} error={errors?.teams}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<CommandsChoice value={value} placeholder={t('teams')} onChange={onChange} />
							)}
							name="teams"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('participants')} error={errors?.users}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<UsersChoice value={value} placeholder={t('participants')} onChange={onChange} />
							)}
							name="users"
							control={control}
						/>
					</InputContainer>
				</div>
				<div>
					<Button>{t('update_data')}</Button>
				</div>
			</form>
		</div>
	)
}

export default ProjectTemplateInfoEdit
