import React, { useEffect, useState } from 'react'
import Calendar from './forkPicker'
import './forkPicker/Calendar.css'

import dayjs from 'dayjs'
import { PickedDateInputs } from './parts/PickedDateInputs'
import { ViewToggle } from './parts/ViewToggle'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAppLanguage } from 'src/old-app/store/interfaceSlice'

require('dayjs/locale/ru')
dayjs.locale('ru')

const startDate = dayjs().startOf('month').toDate()

const MONTH = 'month',
	YEAR = 'year',
	WEEK = 'week',
	DAY = 'day',
	WEEK_MONTHS = 'month-weeks'

function getStartEndDates(dates, datePeriod) {
	return dates.map((date, index) => dayjs(date)[index === 0 ? 'startOf' : 'endOf'](datePeriod).toDate())
}

function getAscSortDates(dates) {
	return [...dates].sort((a, b) => a - b)
}

const rangeByView = {
	[WEEK_MONTHS]: WEEK,
	[YEAR]: MONTH,
	[MONTH]: DAY,
}

const DateRangePicker = ({ dates, onChangeDate }) => {
	const { t } = useTranslation()
	const appLanguage = useSelector(getAppLanguage)
	const [_dates, setDates] = useState(dates ? [dates].flat() : [])
	const [skipOnChange, setSkipOnChange] = useState(true)

	useEffect(() => {
		!skipOnChange && onChangeDate?.(_dates)
		setSkipOnChange(false)
	}, [_dates])

	useEffect(() => {
		if (!dates) return

		const sameDates = getAscSortDates(dates).every((date, index) => +date === +_dates[index])

		if (!sameDates) {
			setSkipOnChange(true)
			setDates(dates)
		}
	}, [dates])

	const [activeDate, setActiveDate] = useState(dates[0] || startDate)
	const [hoverRange, setHoverRange] = useState([])

	//"month", "month-weeks", "year", "decade" or "century"
	const [view, setView] = useState(MONTH)

	useEffect(() => {
		if (_dates.length !== 1) return

		setHoverRange([])
		setDates([])
		rerenderCalendar()
	}, [view])

	function onClickOneDate(clickedDate) {
		const finalDate =
			_dates.length === 1
				? getStartEndDates(getAscSortDates([..._dates, clickedDate]), rangeByView[view])
				: [clickedDate]

		setDates(finalDate)
	}

	function onHoverDate(hoveredDate) {
		const firstPick = !_dates.length
		const twoDatePicked = _dates.length === 2
		if (firstPick || twoDatePicked) {
			setHoverRange(hoveredDate ? getStartEndDates([hoveredDate, hoveredDate], WEEK) : [])
			return
		}

		const secondPick = _dates.length === 1
		if (secondPick) {
			const dates = hoveredDate ? getAscSortDates([_dates[0], hoveredDate]) : [_dates[0], _dates[0]]
			setHoverRange(getStartEndDates(dates, WEEK))
		}
	}

	function onActiveStartDateChange({ action, view }) {
		//prevent onPick change
		if (action === 'onChange' || action.includes('drill')) return

		const dayAction = action.includes('prev') ? 'subtract' : 'add'

		let countAction
		let periodAction

		if (action.includes('2')) {
			;[countAction, periodAction] = view === MONTH ? [1, YEAR] : [2, YEAR]
		} else {
			;[countAction, periodAction] = view === MONTH ? [1, MONTH] : [1, YEAR]
		}

		setActiveDate(dayjs(activeDate)[dayAction](countAction, periodAction).toDate())
	}

	const [key, setKey] = useState(1)
	function rerenderCalendar() {
		setKey(key + 1)
	}

	return (
		<div className={'react-calendar__wrap'}>
			<h2 className={'react-calendar__head'}>{t('choose_date_range')}</h2>

			<ViewToggle {...{ view, setView }} />
			<PickedDateInputs date={_dates} />

			<Calendar
				selectRange
				key={key}
				hoverRange={hoverRange}
				onHoverDate={view === WEEK_MONTHS ? onHoverDate : undefined}
				activeStartDate={activeDate}
				onActiveStartDateChange={onActiveStartDateChange}
				value={_dates}
				showNeighboringMonth={false}
				view={view.replace('-weeks', '')}
				calendarType={'ISO 8601'}
				locale={appLanguage}
				onClickMonth={onClickOneDate}
				onClickDay={onClickOneDate}
				showTripleView
			/>
		</div>
	)
}

export default DateRangePicker
