import { MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import MySelect from 'src/old-app/components/ui/MySelect'
import { useGetProjectContractDirectoryQuery } from 'src/old-app/service/Projects'
import { Autocomplete } from 'src/shared/components'
import { useDebounce } from 'src/shared/hooks'

interface ContractDirectorySelectProps {
	value: any
	onChange: (value: any) => void
	placeholder?: string
	disabled?: boolean
}

export const ContractDirectorySelect: React.FC<ContractDirectorySelectProps> = (p) => {
	const { value, onChange, placeholder = 'Тип работ', disabled } = p
	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)
	const { data: contractDirectoriesData } = useGetProjectContractDirectoryQuery({ name: debounceSearch })

	const list: any[] = contractDirectoriesData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	return (
		<Autocomplete
			disabled={disabled}
			multiple={false}
			placeholder={placeholder}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.name}
			renderOptionLabel={(option: any) => option?.name}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}
