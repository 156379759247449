import { getTask } from 'src/old-app/store/tasksSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import { UserShow } from 'src/shared/components'
import Markdown from '../ui/Markdown'
import ChangeStatusButton from './ChangeStatusButton'
import ChangeTaskPriority from './ChangeTaskPriority'
import GridFiles from './GridFiles'
import TaskDeadline from './TaskDeadline'
import TaskSlug from './TaskSlug'
import Epic from './Epic'
import { TaskDeadlineInfo } from '../../../features/task'
import { getProject, getProjectAdminContractRole, getProjectCuratorRole } from '../../store/projectsSlice'
import FieldContent from '../shared/FieldContent'
import TaskType from './TaskType'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import { getContractDirectoryParamsPrice } from '../../../@entities/project'
import { useTranslation } from 'react-i18next'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'

const TaskRow = ({ label, content }) => {
	return (
		<div className="tt-flex tt-gap-12">
			<div className="tt-min-w-144 tt-w-144 tt-text-gray-600">{label}:</div>
			<div>{content}</div>
		</div>
	)
}

const TaskContent = ({ id }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const project = useSelector(getProject)
	const isAdminProjectContract = useSelector(getAdminProjectContractRole) || useSelector(getProjectAdminContractRole)
	const isCurator = useSelector(getProjectCuratorRole)
	const isAdmin = useSelector(getAdminRole)

	const isFinal = Boolean(task?.task_status?.project_status?.is_final)
	const isDeadlineConfirmationRequired = task?.deadline
		? task?.deadline?.confirmation_status === 'ON_CONFIRMATION'
		: false

	return (
		<div className="tt-flex tt-flex-col tt-gap-12">
			<div className="tt-flex tt-gap-8 tt-items-center">
				<h3 className="tt-font-bold text-hyphens">{task?.title}</h3>
			</div>
			<TaskRow
				label={t('status')}
				content={
					task?.task_status?.project_status_id && <ChangeStatusButton isContract={project?.isContract} id={id} />
				}
			/>
			<TaskRow label={t('priority')} content={task.project_id && <ChangeTaskPriority id={id} />} />
			<TaskRow label={t('performer')} content={<UserShow user={task?.assignee} />} />
			<TaskRow label={t('slug')} content={<TaskSlug isMultiline slug={task?.slug} />} />
			<TaskRow label={t('type')} content={<TaskType taskType={task.type} />} />
			<TaskRow
				label={t('deadline')}
				content={
					<TaskDeadlineInfo isEditDeadline={task?.isMyTask} task={task} isShowStatus={project?.with_administration} />
				}
			/>
			{project?.isContract && task?.contract_type?.contract_type?.name && (
				<>
					<TaskRow label={t('works_type')} content={<div>{task?.contract_type?.contract_type?.name}</div>} />

					{task?.contract_type?.selected_param?.name && (
						<TaskRow label={t('parameter')} content={<div>{task?.contract_type?.selected_param?.name}</div>} />
					)}

					{project?.isContract && (isAdminProjectContract || isCurator || isAdmin || task?.isMyTask) && !isFinal && (
						<TaskRow
							label={t('motivation')}
							content={
								<div className="tt-px-4 tt-inline tt-py-1 tt-rounded tt-bg-gray-100 tt-border-gray-200 tt-border tt-text-gray-600">
									{task?.assignee_id === project?.curator_id ? (
										t('additional_motivation_not_provided')
									) : task?.contract_type?.contract_type?.price ? (
										<>₽ {Number(task?.contract_type?.contract_type?.price).toLocaleString('ru')}</>
									) : task?.contract_type?.selected_param ? (
										<>₽ {Number(task?.contract_type?.selected_param?.price).toLocaleString('ru')}</>
									) : (
										getContractDirectoryParamsPrice(task?.contract_type?.contract_type?.params ?? [])
									)}
								</div>
							}
						/>
					)}

					{project?.isContract && (isAdminProjectContract || isCurator || isAdmin || task?.isMyTask) && isFinal && (
						<TaskRow
							label={t('motivation_final_amount')}
							content={
								<>
									{isDeadlineConfirmationRequired ? (
										<div className="tt-text-orange">Требуется подтверждение дедлайна</div>
									) : (
										<div className="tt-px-4 tt-inline tt-py-1 tt-rounded tt-bg-gray-100 tt-border-gray-200 tt-border tt-text-gray-600">
											₽ {Number(task?.accrual_motivation).toLocaleString('ru')}
										</div>
									)}
								</>
							}
						/>
					)}
				</>
			)}
			{task?.description ? (
				<div className="tt-bg-gray-100 tt-rounded-lg tt-p-16 tt-mt-6">
					<Markdown value={task?.description} />
				</div>
			) : null}
			{task?.task_files && <GridFiles id={id} files={task?.task_files} />}
			{task?.subtasks && <Epic id={id} limit={10} hideHead />}
		</div>
	)
}

export default TaskContent
