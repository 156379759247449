import { useSelector } from 'react-redux'
import FuseScrollbars from 'src/@fuse/core/FuseScrollbars'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { selectContrastMainTheme } from 'src/old-app/store/fuse/settingsSlice'
import clsx from 'clsx'

function FusePageSimpleSidebarContent(props) {
	const theme = useTheme()
	const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main))

	return (
		<FuseScrollbars enable={props.innerScroll}>
			{props.header && (
				<ThemeProvider theme={contrastTheme}>
					<div className={clsx('FusePageSimple-sidebarHeader', props.variant)}>{props.header}</div>
				</ThemeProvider>
			)}

			{props.content && (
				<div className="FusePageSimple-sidebarContent tt-border-l-1 tt-border-gray-300 lg:tt-border-0">
					{props.content}
				</div>
			)}
		</FuseScrollbars>
	)
}

export default FusePageSimpleSidebarContent
