import { MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUpdateUserProjectMutation } from 'src/old-app/service/Projects'
import { UserShow } from 'src/shared/components'
import { getID } from '../../../shared/config/fields-ids'
import { useProjectShow } from '../../../entities/project'
import { useTranslation } from 'react-i18next'
import rolesType from 'src/old-app/constants/rolesType'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const HeadTableCell = ({ name, className, align = 'left', sx, onClick, sort }) => {
	return (
		<TableCell
			onClick={onClick}
			sx={sx}
			className={[
				'tt-px-16  tt-py-6 tt-text-gray-700 tt-font-semibold tt-border-y  tt-border-gray-200',
				className,
			].join(' ')}
			align={align}
		>
			<span className="tt-flex tt-items-center tt-gap-4">
				{name}
				<div className="tt-w-14">
					{sort && (
						<svg
							className={[
								'tt-w-14  tt-fill-gray-600 tt-stroke-gray-600  tt-ml-5',
								sort === 'desc' ? 'tt-rotate-0' : 'tt-rotate-180',
							].join(' ')}
							viewBox="0 0 24 24"
						>
							<path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
						</svg>
					)}
				</div>
			</span>
		</TableCell>
	)
}
export const BodyTableCell = ({ children, className, align = 'left', sx }) => {
	return (
		<TableCell
			id="project-name"
			sx={sx}
			className={['tt-px-16 tt-py-8  tt-border-b tt-border-gray-200', className].join(' ')}
			align={align}
		>
			{children}
		</TableCell>
	)
}

const Roles = ({ user, isDisabled }) => {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	const [updateUserProject] = useUpdateUserProjectMutation()
	const [alignment, setAlignment] = useState('USER')

	const projectShow = useProjectShow()

	const isContract = projectShow?.isContract

	const handleChange = async (event) => {
		const { value } = event.target
		const confirms = value === 'USER' ? false : undefined
		const closing = value === 'USER' ? false : undefined
		await updateUserProject({
			user_id: user.user_id,
			project_id: user.project_id,
			role: value,
			confirms,
			closing,
		})
		setAlignment(value)
	}

	useEffect(() => {
		setAlignment(user.role)
	}, [user])

	return (
		<div className="text-12">
			<Select
				disabled={isDisabled}
				labelId="demo-select-small"
				// id="demo-select-small"
				value={alignment}
				size="small"
				className="dialig-input"
				label={null}
				onChange={handleChange}
			>
				<MenuItem id={getID({ type: 'select_item', index: 0 })} value={rolesType.USER}>
					{t('roles.user')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value={rolesType.BA}>
					{t('roles.business_administrator')}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 2 })} value={rolesType.PROJECT_MANAGER}>
					{t('roles.project_manager')}
				</MenuItem>
				<MenuItem
					hidden={!isContract}
					disabled
					id={getID({ type: 'select_item', index: 3 })}
					value={rolesType.ADMIN_PROJECT_CONTRACT}
				>
					{t('roles.admin_project_contract')}
				</MenuItem>
				<MenuItem hidden={!isContract} disabled id={getID({ type: 'select_item', index: 4 })} value={rolesType.CURATOR}>
					{t('roles.curator')}
				</MenuItem>
			</Select>
		</div>
	)
}

const Confirm = ({ user, isDisabled, id, index }) => {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	const [updateUserProject] = useUpdateUserProjectMutation()
	const [alignment, setAlignment] = useState(false)

	const handleChange = async (event) => {
		const { value } = event.target

		await updateUserProject({ user_id: user.user_id, project_id: user.project_id, confirms: value })
		setAlignment(value)
	}

	useEffect(() => {
		setAlignment(user.confirms)
	}, [user])

	return (
		<div>
			<Select
				disabled={isDisabled || user?.role === 'USER'}
				// labelId='demo-select-small'
				id={getID({ prefix: id, type: 'select', action: 'confirm', index })}
				value={alignment}
				size="small"
				className="dialig-input"
				label={null}
				onChange={handleChange}
			>
				<MenuItem id={getID({ type: 'select_item', index: 0 })} value={true}>
					{t('yes').toUpperCase()}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value={false}>
					{t('no').toUpperCase()}
				</MenuItem>
			</Select>
		</div>
	)
}

const Closed = ({ user, isDisabled, id, index }) => {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	const [updateUserProject] = useUpdateUserProjectMutation()
	const [alignment, setAlignment] = useState(false)

	const handleChange = async (event) => {
		const { value } = event.target

		await updateUserProject({ user_id: user.user_id, project_id: user.project_id, closing: value })
		setAlignment(value)
	}

	useEffect(() => {
		setAlignment(Boolean(user?.closing))
	}, [user])

	return (
		<div>
			<Select
				disabled={isDisabled || user?.role === 'USER'}
				// labelId='demo-select-small'
				id={getID({ prefix: id, type: 'select', action: 'confirm', index })}
				value={alignment}
				size="small"
				className="dialig-input"
				label={null}
				onChange={handleChange}
			>
				<MenuItem id={getID({ type: 'select_item', index: 0 })} value={true}>
					{t('yes').toUpperCase()}
				</MenuItem>
				<MenuItem id={getID({ type: 'select_item', index: 1 })} value={false}>
					{t('no').toUpperCase()}
				</MenuItem>
			</Select>
		</div>
	)
}

const ProjectRoles = ({ isDisabled, id }) => {
	const { t } = useTranslation()
	const projectShow = useProjectShow()
	const project = projectShow?.project
	const isContract = projectShow?.isContract
	const users = project?.users ?? []

	const isCommittee = projectShow?.isCommittee

	return (
		<Accordion style={{boxShadow:"none"}} defaultExpanded={true} className="tt-col-span-6 !tt-p-0 !tt-border-none !tt-m-0 !tt-bg-transparent !tt-rounded-none">
		<AccordionDetails className="!tt-border-none tt-border-gray-200 !tt-bg-transparent !tt-p-0 ">
		<div>
			<TableContainer className="tt-rounded-lg tt-overflow-hidden tt-border-x tt-border-gray-200 tt-w-full">
				<Table>
					<TableHead className="tt-bg-white ">
						<TableRow>
							<HeadTableCell name={t('roles.user')} />
							<HeadTableCell name={t('role')} />
							{!projectShow?.isMyProject && <HeadTableCell name={t('completes_project')} />}
							{project?.with_administration && <HeadTableCell name={t('confirms')} />}
						</TableRow>
					</TableHead>
					<TableBody>
						{users?.map((user, index) => (
							<TableRow className=" hover:tt-bg-gray-100/50" key={user.user_id}>
								<BodyTableCell>
									<UserShow isShowEmail isShowMiddleName user={user.user} />
								</BodyTableCell>

								<BodyTableCell className="tt-w-224">
									<CommentedDisability
										optionDisabled={isCommittee}
										disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
									>
										<Roles isDisabled={isDisabled} user={user} />
									</CommentedDisability>
								</BodyTableCell>

								{!projectShow?.isMyProject && (
									<BodyTableCell className="tt-w-224">
										<CommentedDisability
											optionDisabled={isCommittee}
											disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
										>
											<Closed index={index} id={id} isDisabled={isDisabled} user={user} />
										</CommentedDisability>
									</BodyTableCell>
								)}

								{project?.with_administration && (
									<BodyTableCell className="tt-w-224">
										<CommentedDisability
											optionDisabled={isCommittee}
											disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
										>
											<Confirm index={index} id={id} isDisabled={isContract ? true : isDisabled} user={user} />
										</CommentedDisability>
									</BodyTableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
		</AccordionDetails>
	</Accordion>
		
	)
}

export default ProjectRoles
