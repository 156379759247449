import React, { useEffect } from 'react'
import clsx from 'clsx'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import TaskSlug from 'src/old-app/components/task/TaskSlug'
import TaskPriorityShow from 'src/old-app/components/task/TaskPriorityShow'
import TaskType from 'src/old-app/components/task/TaskType'
import { UserShow } from 'src/shared/components'
import TagsView from 'src/old-app/components/ui/TagsView'
import GradedTimeStapm from 'src/old-app/components/shared/GradedTimeStamp'
import { useGetUser } from '../../../../../shared/hooks'
import TaskDeadlineInfo from '../../../../../features/task/components/task-deadline-info'
import { TaskEpicButton } from '../../../../../@features/task'
import { useTranslation } from 'react-i18next'
import { CardDragNDropWrapper } from '../components/ScrumboardDragNDrop'

function BoardCard(props) {
	const { card, index, columnHandlingParams } = props
	const { t } = useTranslation()
	const user = useGetUser()
	const { search } = useLocation()
	const { taskId } = useParams()

	const cardHandlingParams = {
		userIsOwner: card?.assignee_id === user?.id,
		subtasksCompleted: card?.subtask_completed,
	}

	return (
		<CardDragNDropWrapper
			cardID={card?.id}
			cardIndex={index}
			cardHandlingParams={cardHandlingParams}
			columnHandlingParams={columnHandlingParams}
		>
			<div className="tt-py-4">
				<Link to={`${card.id}${search}`} draggable={false} className="">
					<div
						className={clsx(
							'tt-w-full tt-rounded-lg tt-shadow-sm tt-border tt-border-gray-200 tt-cursor-pointer tt-bg-white',
							taskId === card.id && 'tt-outline tt-outline-2 tt-border-transparent tt-outline-primary-400'
						)}
					>
						<div className="tt-p-12 tt-pb-0">
							<div className="tt-flex tt-items-center tt-gap-16 tt-justify-between tt-w-full">
								<div className="tt-flex tt-gap-8 tt-items-center">
									<TaskType className="tt-w-auto tt-inline-flex" taskType={card.type} />
								</div>

								{card?.priority && <TaskPriorityShow className="tt-inline-flex" priority={card?.priority} />}
							</div>

							<p className="tt-font-medium tt-my-8 text-hyphens">{card?.title}</p>
						</div>

						<div className="tt-flex tt-flex-col tt-justify-between tt-p-12">
							<div className="tt-flex tt-justify-between tt-gap-12">
								{card?.assignee && (
									<div className="tt-mb-5 tt-flex tt-items-center tt-justify-between">
										<UserShow user={card?.assignee} />
									</div>
								)}
								<GradedTimeStapm timeStamp={card?.total_time * 60} />
							</div>

							<div className="tt-mt-4">
								<TaskSlug message={t('project_slug')} slug={card?.slug} />
							</div>

							<div className="tt-mt-6 tt-pb-12">
								<TaskDeadlineInfo placeholder="" isShowStatusInText task={card} />
							</div>

							<TagsView className="tt-truncate" isHidePlaceholder tags={card?.hashtags ?? []} />
						</div>
						{card?.epic && <TaskEpicButton task={card} />}
					</div>
				</Link>
			</div>
		</CardDragNDropWrapper>
	)
}

export default BoardCard
