import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

const ProjectSideBarLoading = memo(() => {
	return (
		<div className="tt-w-full tt-flex-1 tt-items-center  tt-flex tt-justify-center tt-min-h-full tt-p-24">
			<CircularProgress className="tt-text-primary-600" />
		</div>
	)
})

ProjectSideBarLoading.displayName = 'ProjectSideBarLoading'
export default ProjectSideBarLoading
