import React from 'react'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'

const TaskDetailsLabel = ({ label, text, textClassName, className, onClick, isEdit, id }) => {
	return (
		<div id={id} onClick={onClick} className={[className].join(' ')}>
			<div className="tt-flex tt-items-center tt-gap-4 tt-group">
				<div
					className={['tt-flex tt-gap-6 tt-items-center', isEdit ? 'tt-cursor-pointer' : 'tt-cursor-default'].join(' ')}
				>
					<TimelapseOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />
					<span className="tt-text-14 tt-text-gray-700 tt-whitespace-nowrap">{label}</span>
				</div>
				{isEdit ? (
					<div className={['tt-w-20 tt-h-20', isEdit ? 'tt-cursor-pointer' : 'tt-cursor-default'].join(' ')}>
						<svg
							className="tt-stroke-gray-700 tt-hidden group-hover:tt-block"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
								strokeWidth="2"
								stroke="#94A3B8"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				) : null}
			</div>

			<p className={[' tt-text-14 ', textClassName].join(' ')}>{text}</p>
		</div>
	)
}

export default TaskDetailsLabel
