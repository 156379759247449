import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { Button } from '../../../../shared/components'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import ConfirmDelete from '../../../../shared/components/confirm-delete'
import useProjectEnd from '../../../../entities/project/model/useProjectEnd'
import { getID } from '../../../../shared/config/fields-ids'
import ListItemIcon from '@mui/material/ListItemIcon'
import FuseSvgIcon from '../../../../@fuse/core/FuseSvgIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined'
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import { useProjectShow } from 'src/entities/project'

interface IProps {
	curStatus: string
	projectId: string
	isSubProject?: boolean
	isMenu?: boolean
	childProjectsCompleted?: boolean
}

const ProjectEnd: React.FC<IProps> = ({ projectId, curStatus, isMenu, childProjectsCompleted, isSubProject }) => {
	const { t } = useTranslation()
	
	const { resumeProject, endProject } = useProjectEnd(projectId)

	const projectShow = useProjectShow()

	const isCommittee = projectShow?.isCommittee

	const P = isSubProject ? t('subproject') : t('project')

	const statuses = useMemo(
		() => ({
			END: {
				label: `${t('to_complete')} ${P.toLowerCase()}`,
				className: styles.end,
			},
			RESUME: {
				label: `${t('to_resume')} ${P.toLowerCase()}`,
				className: '',
			},
		}),
		[]
	)

	const isCompleted = curStatus === 'COMPLETED'
	const status = isCompleted ? statuses.RESUME : statuses.END

	if (isMenu) {
		return (
			<>
				{!isCompleted ? (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<ConfirmDelete
							disabled={!childProjectsCompleted}
							title={t('complete_project_dialog.are_you_shure', { value: P.toLowerCase() })}
							text={t('complete_project_dialog.annotation', { value: P.toLowerCase() })}
							onSuccess={endProject}
						>
							<Tooltip
								//sx={{ zIndex: 9999999 }}
								enterDelay={500}
								arrow
								title={
									childProjectsCompleted
										? null
										: t('complete_project_dialog.with_incompleted_subprojects', { value: P })
								}
								placement="top-start"
							>
								<MenuItem className={childProjectsCompleted ? '' : 'tt-cursor-not-allowed'}>
									<ListItemIcon className="tt-min-w-40">
										<SportsScoreOutlinedIcon className="tt-text-gray-500 tt-w-24 tt-h-24" />
									</ListItemIcon>
									<ListItemText primary={status?.label} />
								</MenuItem>
							</Tooltip>
						</ConfirmDelete>
					</CommentedDisability>
				) : (
					<MenuItem onClick={resumeProject}>
						<ListItemIcon className="tt-min-w-40">
							<SettingsBackupRestoreOutlinedIcon className="tt-text-gray-500 tt-w-24 tt-h-24" />
						</ListItemIcon>
						<ListItemText primary={status?.label} />
					</MenuItem>
				)}
			</>
		)
	}

	return (
		<div className={styles['project-end']}>
			<CommentedDisability
				optionDisabled={isCommittee}
				disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
			>
				{!isCompleted ? (
					<ConfirmDelete
						disabled={!childProjectsCompleted}
						title={t('complete_project_dialog.are_you_shure', { value: P.toLowerCase() })}
						text={t('complete_project_dialog.annotation', { value: P.toLowerCase() })}
						onSuccess={endProject}
					>
						<Tooltip
							enterDelay={500}
							arrow
							title={
								childProjectsCompleted ? null : t('complete_project_dialog.with_incompleted_subprojects', { value: P })
							}
							placement="top-start"
						>
							<span>
								<Button
									className={[status?.className, childProjectsCompleted ? '' : 'tt-cursor-not-allowed'].join(' ')}
								>
									{status?.label}
								</Button>
							</span>
						</Tooltip>
					</ConfirmDelete>
				) : (
					<Button onClick={resumeProject} className={status?.className}>
						{status?.label}
					</Button>
				)}
			</CommentedDisability>
		</div>
	)
}

export default ProjectEnd
