import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	notifs: [],
	counts: 0,
	notif: null,
}

const reducers = {
	getNotifs: (state, action) => {
		state.notifs = action.payload
	},
	getNotif: (state, action) => {
		state.notif = action.payload
	},
	getNotifsCount: (state, action) => {
		state.counts = action.payload
	},
	deleteNotif: (state, action) => {
		state.counts = state.counts - 1
		state.notifs = state.notifs.filter((item) => item.id !== action.payload)
	},
}

const notifsSlice = createSlice({
	name: 'notifs',
	initialState,
	reducers,
})

export const getNotifs = (state) => state.notifs.notifs
export const getNotif = (state) => state.notifs.notif
export const getNotifsCounts = (state) => state.notifs.counts

export const notifsReducer = notifsSlice.reducer
export const notifsActions = notifsSlice.actions
