/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetPriorityQuery } from 'src/old-app/service/Projects'
import { useDeletePriorityMutation, useUpdateTaskMutation } from 'src/old-app/service/Tasks'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getPrioritys, getProjectUserRole, projectsActions } from 'src/old-app/store/projectsSlice'
import { getTask } from 'src/old-app/store/tasksSlice'
import addAlpha from 'src/old-app/utils/addAlpha'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const ChangeTaskPriority = ({ isNewTask, id }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [updateTask] = useUpdateTaskMutation()
	const [deletePriority] = useDeletePriorityMutation()
	const priorities = useSelector(getPrioritys)
	const task = useSelector(getTask)
	const [value, setValue] = useState(task.priority)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const isUser = useSelector(getProjectUserRole)
	const { data: priority } = useGetPriorityQuery({ project_id: task.project_id, level_sort: 'asc' })

	useEffect(() => {
		setValue(task?.priority)
		if (priority?.data) {
			dispatch(projectsActions.getPrioritys(priority.data))
		}
	}, [task, priority])

	const changeTaskPriorityHandler = async (event) => {
		const id = event.target.value

		const item = priorities?.find((p) => p.id === id)

		setValue(item)

		const data = {
			body: { task_priority_id: item?.id, project_status_id: task?.project_status_id },
			id: task.id,
		}
		await updateTask(data)

		dispatch(
			showMessage({
				message: t('successfully_updated.priority'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
	}

	const disabled = task?.project?.isEnd ? true : isNewTask ? false : isUser ? !task?.isMyTask : false

	const clickPriority = async (id) => {
		if (id === value?.id) {
			const data = {
				body: { task_priority_id: null, project_status_id: task?.project_status_id },
				id: task.id,
			}
			updateTask(data)

			return
		}
	}

	return (
		<div className="tt-flex tt-gap-8 tt-items-center">
			<Select
				disabled={disabled}
				id={getID({ prefix: id, type: 'select', field: 'priority', entity: 'task' })}
				onChange={changeTaskPriorityHandler}
				IconComponent={null}
				className="task-card-select tt-w-160 tt-text-14 tt-font-medium "
				value={value?.id ?? 'null'}
				sx={{ backgroundColor: addAlpha(value?.color ?? '#1E293B', 0.1), color: value?.color ?? '#1E293B' }}
			>
				{priorities.map((item, index) => (
					<MenuItem
						onClick={() => clickPriority(item.id)}
						id={getID({ prefix: id, type: 'select_item', field: 'priority', entity: 'task', index })}
						key={item.id}
						value={item.id}
						sx={{ color: item?.color }}
					>
						{item?.name}
					</MenuItem>
				))}
				{!value && (
					<MenuItem disabled value="null">
						{t('not_selected')}
					</MenuItem>
				)}
				{/* {value && (
					<MenuItem className='tt-text-red-600 ' value='remove-priority'>
						<RemoveCircleOutlineIcon className="tt-text-red-600 tt-w-18 tt-mr-6" />
						Убрать приоритет
					</MenuItem>
				)} */}
				{priorities.length === 0 && (
					<div className="tt-text-14 tt-text-gray-500 tt-text-center tt-w-full">{t('not_found')}</div>
				)}
			</Select>

			{/* <div
			
				className={['rounded-full px-4 truncate task-card-select bg-gray-100 text-14 max-w-160 flex items-center justify-center cursor-pointer'].join(' ')}
				id="priority-button"
				aria-controls="priority-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				{value ? value.name : <span className="text-center text-gray-600 truncate">Приоритет не выбран</span>}
			</div>
			<Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
				{priorities.map((item) => (
					<MenuItem sx={{ color: item?.color }} onClick={() => changeTaskPriorityHandler(item)} key={item.id} className={['flex w-full items-center justify-between gap-12 truncate', value?.id === item?.id && 'bg-gray-100'].join(' ')}>
						{item.name}
					</MenuItem>
				))}
				{priorities.length === 0 && <div className="text-14 text-gray-500 text-center w-full">Не найдено</div>}
			</Menu> */}
		</div>
	)
}

export default ChangeTaskPriority
