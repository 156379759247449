import { useState, useRef, useLayoutEffect, createContext, useContext } from 'react'

import styles from './ScrumboardDragNDrop.module.css'

export const DragNDropContext = createContext(null)

const offsetDistanceToStartDragging = 5

export const GlobalDragNDropWrapper = ({ children, onCardDrop, onColumnDrop, onDrag }) => {
	const [draggableCard, setDraggableCard] = useState(null)
	const [draggableColumn, setDraggableColumn] = useState(null)

	const draggableCardSetter = (cardInfo) => {
		setDraggableCard(cardInfo)
	}

	const draggableColumnSetter = (columnID) => {
		setDraggableColumn(columnID)
	}

	return (
		<DragNDropContext.Provider
			value={{
				draggableCard: draggableCard,
				draggableCardSetter: draggableCardSetter,
				onCardDrop: onCardDrop,
				draggableColumn: draggableColumn,
				onColumnDrop: onColumnDrop,
				draggableColumnSetter: draggableColumnSetter,
				onDrag: onDrag, //затычка для сращивания со старой логикой
			}}
		>
			{children}
		</DragNDropContext.Provider>
	)
}

////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////// CARD WRAPPER /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export const CardDragNDropWrapper = (props) => {
	const { cardID, cardIndex, cardHandlingParams, columnHandlingParams, children } = props

	const { draggableCard, draggableColumn, draggableCardSetter, onCardDrop, onDrag } = useContext(DragNDropContext)

	const cardInfo = {
		cardIndex: cardIndex,
		cardID: cardID,
		columnID: columnHandlingParams.columnID,
		subtasksCompleted: cardHandlingParams.subtasksCompleted,
	}

	const isTaskOwner =
		cardHandlingParams.userIsOwner || !columnHandlingParams.userIsUser || columnHandlingParams.userIsGlobalAdmin

	const isDragExpecting =
		!(
			columnHandlingParams.projectIsClosed ||
			(columnHandlingParams.projectIsCommittee && !cardHandlingParams.userIsOwner)
		) &&
		((!columnHandlingParams.projectIsAdministrated && isTaskOwner) ||
			(columnHandlingParams.projectIsAdministrated &&
				columnHandlingParams.userIsUser &&
				!columnHandlingParams.statusIsRequest &&
				!columnHandlingParams.statusIsFinal &&
				isTaskOwner) ||
			(columnHandlingParams.projectIsAdministrated &&
				!columnHandlingParams.userIsUser &&
				!columnHandlingParams.statusIsRequest))

	const contentWrapperRef = useRef(null)
	const transformerRef = useRef(null)
	const onMouseDownCursorCoordinates = useRef({ x: 0, y: 0 })
	const onMouseDownContentWrapperSize = useRef({ width: 0, height: 0 })
	const onMouseDownContentWrapperCursorOffset = useRef({ left: 0, top: 0 })

	const [isDragging, setIsDragging] = useState(false)
	const [isDropExpecting, setIsDropExpecting] = useState(false)
	const [isRejecting, setIsRejecting] = useState(false)

	const [isDragOver, setIsDragOver] = useState(false)

	useLayoutEffect(() => {
		if (draggableCard && draggableCard?.cardID !== cardID) {
			const isDroppable =
				!columnHandlingParams.projectIsClosed &&
				((columnHandlingParams.projectIsAdministrated &&
					columnHandlingParams.userIsUser &&
					!columnHandlingParams.statusIsRequest &&
					!columnHandlingParams.statusIsFinal) ||
					(columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.userIsUser &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.userIsUser &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.userIsUser &&
						!columnHandlingParams.statusIsRequest &&
						!columnHandlingParams.statusIsFinal) ||
					(!columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.statusIsRequest &&
						!columnHandlingParams.statusIsFinal) ||
					(!columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(!columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.statusIsFinal &&
						draggableCard.subtasksCompleted))

			setIsDropExpecting(isDroppable)
		} else {
			setIsDropExpecting(false)
		}
	}, [draggableCard])

	useLayoutEffect(() => {
		if (!draggableCard) {
			setIsDragOver(false)
		}
	}, [draggableCard])

	useLayoutEffect(() => {
		if (draggableCard || draggableColumn) return
		contentWrapperRef.current.addEventListener('mousedown', prepareToGrag)
		return () => {
			contentWrapperRef.current.removeEventListener('mousedown', prepareToGrag)
		}
	}, [isDragExpecting, draggableCard, draggableColumn])

	useLayoutEffect(() => {
		if (!isDragging) return
		transformerRef.current.style.width = `${onMouseDownContentWrapperSize.current.width}px`
		transformerRef.current.style.height = `${onMouseDownContentWrapperSize.current.height}px`
		contentWrapperRef.current.style.height = `${onMouseDownContentWrapperSize.current.height}px`
		draggableCardSetter(cardInfo)
	}, [isDragging])

	function prepareToGrag(event) {
		event.stopPropagation()
		document.addEventListener('mousemove', startDraggingTrigger)
		document.addEventListener('mouseup', resetToDefault)
		onMouseDownCursorCoordinates.current = { x: event.clientX, y: event.clientY }
		onMouseDownContentWrapperSize.current = {
			width: contentWrapperRef.current.getBoundingClientRect().width,
			height: contentWrapperRef.current.getBoundingClientRect().height,
		}
		onMouseDownContentWrapperCursorOffset.current = {
			left: event.clientX - contentWrapperRef.current.getBoundingClientRect().left,
			top: event.clientY - contentWrapperRef.current.getBoundingClientRect().top,
		}
	}

	function startDraggingTrigger(event) {
		if (
			Math.abs(event.clientX - onMouseDownCursorCoordinates.current.x) > offsetDistanceToStartDragging ||
			Math.abs(event.clientY - onMouseDownCursorCoordinates.current.y) > offsetDistanceToStartDragging
		) {
			document.removeEventListener('mousemove', startDraggingTrigger)
			document.addEventListener('mousemove', followTheCursor)
			if (isDragExpecting) {
				setIsDragging(true)
				onDrag({ initialValue: cardInfo })
			} else {
				setIsRejecting(true)
				setTimeout(() => setIsRejecting(false), 500)
			}
		}
	}

	function followTheCursor(event) {
		transformerRef.current.style.left = `${event.clientX - onMouseDownContentWrapperCursorOffset.current.left}px`
		transformerRef.current.style.top = `${event.clientY - onMouseDownContentWrapperCursorOffset.current.top}px`
	}

	function resetToDefault() {
		setIsDragging(false)
		draggableCardSetter(null)
		document.removeEventListener('mousemove', startDraggingTrigger)
		document.removeEventListener('mousemove', followTheCursor)
		document.removeEventListener('mouseup', resetToDefault)
		document.removeEventListener('mouseleave', resetToDefault)
		if (!contentWrapperRef.current) return
		contentWrapperRef.current.style.width = 'initial'
		contentWrapperRef.current.style.height = 'initial'
	}

	function onDrop() {
		onCardDrop({ initialValue: draggableCard, targetValue: cardInfo })
	}

	return (
		<div key={`${cardID}_dnd`} ref={contentWrapperRef} className={styles.card_content_wrapper}>
			<div
				ref={transformerRef}
				className={[
					styles.transformer,
					isDragging ? styles.card_sticky : '',
					isDragOver ? styles.card_drag_over : '',
					isRejecting ? styles.card_rejected : '',
				].join(' ')}
			>
				{children}
			</div>
			{isDropExpecting && (
				<div
					className={styles.drop_control}
					onMouseEnter={() => setIsDragOver(true)}
					onMouseLeave={() => setIsDragOver(false)}
					onMouseUp={onDrop}
				/>
			)}
		</div>
	)
}

////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// COLUMN WRAPPER ////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export const ColumnDragNDropWrapper = (props) => {
	const { columnID, columnIndex, columnHandlingParams, grabArea, children } = props

	const { draggableCard, draggableColumn, draggableColumnSetter, onColumnDrop } = useContext(DragNDropContext)

	const columnInfo = {
		columnIndex: columnIndex,
		columnID: columnID,
		columnOrder: columnHandlingParams.columnOrder,
	}

	const isDragExpecting =
		!columnHandlingParams.projectIsClosed &&
		!columnHandlingParams.userIsUser &&
		!columnHandlingParams.projectIsCommittee

	const contentWrapperRef = useRef(null)
	const transformerRef = useRef(null)
	const onMouseDownCursorCoordinates = useRef({ x: 0, y: 0 })
	const onMouseDownContentWrapperSize = useRef({ width: 0, height: 0 })
	const onMouseDownContentWrapperCursorOffset = useRef({ left: 0, top: 0 })

	const [isDragging, setIsDragging] = useState(false)
	const [isDropExpecting, setIsDropExpecting] = useState(false)

	const [isDragOver, setIsDragOver] = useState(false)

	useLayoutEffect(() => {
		if (draggableColumn && draggableColumn?.columnID !== columnID) {
			const isExpecting = true
			setIsDropExpecting(isExpecting)
		} else {
			setIsDropExpecting(false)
		}
	}, [draggableColumn])

	useLayoutEffect(() => {
		if (!draggableColumn) {
			setIsDragOver(false)
		}
	}, [draggableColumn])

	useLayoutEffect(() => {
		if (!grabArea.current) return
		if (!isDragExpecting || draggableCard || draggableColumn) return
		grabArea.current.addEventListener('mousedown', prepareToGrag)
		grabArea.current.style.cursor = 'grab'
	}, [grabArea.current, isDragExpecting, draggableCard, draggableColumn])

	// useLayoutEffect(() => {
	// 	if (!isDragExpecting || draggableCard || draggableColumn) return
	// 	contentWrapperRef.current.addEventListener('mousedown', prepareToGrag)
	// 	return () => {
	// 		contentWrapperRef.current.removeEventListener('mousedown', prepareToGrag)
	// 	}
	// }, [isDragExpecting, draggableCard, draggableColumn])

	useLayoutEffect(() => {
		if (!isDragging) return
		transformerRef.current.style.width = `${onMouseDownContentWrapperSize.current.width}px`
		transformerRef.current.style.height = `${onMouseDownContentWrapperSize.current.height}px`
		contentWrapperRef.current.style.width = `${onMouseDownContentWrapperSize.current.width}px`
		draggableColumnSetter(columnInfo)
	}, [isDragging])

	function prepareToGrag(event) {
		event.stopPropagation()
		document.addEventListener('mousemove', startDraggingTrigger)
		document.addEventListener('mouseup', resetToDefault)
		onMouseDownCursorCoordinates.current = { x: event.clientX, y: event.clientY }
		onMouseDownContentWrapperSize.current = {
			width: contentWrapperRef.current.getBoundingClientRect().width,
			height: contentWrapperRef.current.getBoundingClientRect().height,
		}
		onMouseDownContentWrapperCursorOffset.current = {
			left: event.clientX - contentWrapperRef.current.getBoundingClientRect().left,
			top: event.clientY - contentWrapperRef.current.getBoundingClientRect().top,
		}
	}

	function startDraggingTrigger(event) {
		if (
			Math.abs(event.clientX - onMouseDownCursorCoordinates.current.x) > offsetDistanceToStartDragging ||
			Math.abs(event.clientY - onMouseDownCursorCoordinates.current.y) > offsetDistanceToStartDragging
		) {
			document.removeEventListener('mousemove', startDraggingTrigger)
			document.addEventListener('mousemove', followTheCursor)
			setIsDragging(true)
		}
	}

	function followTheCursor(event) {
		transformerRef.current.style.left = `${event.clientX - onMouseDownContentWrapperCursorOffset.current.left}px`
		transformerRef.current.style.top = `${event.clientY - onMouseDownContentWrapperCursorOffset.current.top}px`
	}

	function resetToDefault() {
		setIsDragging(false)
		draggableColumnSetter(null)
		document.removeEventListener('mousemove', startDraggingTrigger)
		document.removeEventListener('mousemove', followTheCursor)
		document.removeEventListener('mouseup', resetToDefault)
		document.removeEventListener('mouseleave', resetToDefault)
		if (!contentWrapperRef.current) return
		contentWrapperRef.current.style.width = 'initial'
		contentWrapperRef.current.style.height = 'initial'
	}

	function onDrop() {
		onColumnDrop({ initialValue: draggableColumn, targetValue: columnInfo })
	}

	return (
		<div key={`${columnID + columnIndex}_dnd`} ref={contentWrapperRef} className={styles.column_content_wrapper}>
			<div
				ref={transformerRef}
				className={[
					styles.transformer,
					isDragging ? styles.column_sticky : '',
					isDragOver ? styles.column_drag_over : '',
				].join(' ')}
			>
				{children}
			</div>
			{isDropExpecting && (
				<div
					className={styles.drop_control}
					onMouseEnter={() => setIsDragOver(true)}
					onMouseLeave={() => setIsDragOver(false)}
					onMouseUp={onDrop}
				/>
			)}
		</div>
	)
}

export const EmptyColumnPlaceholder = (props) => {
	const { columnIndex, columnID, columnHandlingParams } = props
	const { draggableCard, onCardDrop } = useContext(DragNDropContext)

	const [isDropExpecting, setIsDropExpecting] = useState(false)

	const placeholderInfo = {
		columnIndex: columnIndex,
		columnID: columnID,
		columnOrder: columnHandlingParams.columnOrder,
	}

	useLayoutEffect(() => {
		if (draggableCard) {
			const isDroppable =
				!columnHandlingParams.projectIsClosed &&
				((columnHandlingParams.projectIsAdministrated &&
					columnHandlingParams.userIsUser &&
					!columnHandlingParams.statusIsRequest &&
					!columnHandlingParams.statusIsFinal) ||
					(columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.userIsUser &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.userIsUser &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.userIsUser &&
						!columnHandlingParams.statusIsRequest &&
						!columnHandlingParams.statusIsFinal) ||
					(!columnHandlingParams.projectIsAdministrated &&
						!columnHandlingParams.statusIsRequest &&
						!columnHandlingParams.statusIsFinal) ||
					(!columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.statusIsRequest &&
						draggableCard.subtasksCompleted) ||
					(!columnHandlingParams.projectIsAdministrated &&
						columnHandlingParams.statusIsFinal &&
						draggableCard.subtasksCompleted))
			setIsDropExpecting(isDroppable)
		} else {
			setIsDropExpecting(false)
		}
	}, [draggableCard])

	function onDrop() {
		if (!isDropExpecting) return
		onCardDrop({ initialValue: draggableCard, targetValue: placeholderInfo })
	}

	return (
		<div
			onMouseUp={onDrop}
			className={[styles.empty_card_placeholder, isDropExpecting ? styles.empty_card_placeholder_await : ''].join(' ')}
		/>
	)
}
