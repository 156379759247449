import TaskCompleteFileView from './TaskCompleteFileView'

const UploadedTaskCompleteFilesList = ({ title, uploadedTaskCompleteFiles, removeTaskCompleteFile }) => {
	return (
		<>
			{uploadedTaskCompleteFiles?.length > 0 ? (
				<div>
					<span className="tt-font-semibold">{title}</span>
					<div className="tt-flex tt-flex-col tt-gap-4 tt-w-full tt-max-h-[22rem] tt-mt-6 tt-overflow-y-auto tt-overflow-x-hidden">
						{uploadedTaskCompleteFiles.map((taskCompleteFile) => {
							const { id, link, original_name } = taskCompleteFile
							return (
								<TaskCompleteFileView
									key={id}
									id={id}
									link={link}
									originalName={original_name}
									removeTaskCompleteFile={removeTaskCompleteFile}
								/>
							)
						})}
					</div>
				</div>
			) : null}
		</>
	)
}

export default UploadedTaskCompleteFilesList
