import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getFiles } from 'src/old-app/store/tasksSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getID } from '../../../shared/config/fields-ids'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Tooltip, Typography } from '@mui/material'
import LocalMoviesIcon from '@mui/icons-material/LocalMovies'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import styles from './GridFiles.module.scss'
import { useTranslation } from 'react-i18next'

const supportedVideoFormats = ['video/mp4', 'video/ogg', 'video/webm']
const supportedImageFormats = ['image/gif', 'image/jpeg', 'image/png', 'image/webp']

const File = ({ id, item, deleteFileFn, viewableItems, inGalleryIndex }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const openMediaViewer = (itemToShow) => {
		dispatch(interfaceActions.setCurrentMediaItem(itemToShow))
		dispatch(interfaceActions.setMediaSource(viewableItems))
		dispatch(interfaceActions.setIsMediaViewerOpen(true))
	}

	let needShortenName = false
	let shortenName = ''

	if (item?.original_name?.length > 25) {
		needShortenName = true
		shortenName = item?.original_name?.slice(0, 15) + '...' + item?.original_name?.slice(-7)
	}

	const tooltipHeaders = [t('play_video'), t('show_image'), t('download_file')]

	const fileFormat = supportedVideoFormats.includes(item.format)
		? 0 //video
		: supportedImageFormats.includes(item.format)
		? 1 //image
		: 2 //file

	return (
		<Tooltip arrow title={tooltipHeaders[fileFormat]} placement="top">
			<div className={styles.gridItem}>
				<div
					id={id}
					className="tt-flex tt-flex-col tt-justify-center tt-justify-items-center tt-w-full tt-h-full tt-br-10 tt-border tt-rounded-lg tt-cursor-pointer tt-overflow-hidden"
					onClick={() => {
						fileFormat != 2 && openMediaViewer(inGalleryIndex)
					}}
				>
					{fileFormat == 0 && (
						<div className="tt-flex tt-justify-center tt-mt-10">
							<LocalMoviesIcon className="tt-w-40 tt-h-40 tt-text-[#0f766d]" />
						</div>
					)}
					{fileFormat == 1 && (
						<img onClick={() => openMediaViewer(inGalleryIndex)} id={id} src={item.link} alt={item.saved_name} />
					)}
					{fileFormat == 2 && (
						<a href={item.link}>
							<div className="tt-flex tt-justify-center tt-mt-10">
								<InsertDriveFileIcon className="tt-w-40 tt-h-40 tt-text-[#0f766d]" />
							</div>
						</a>
					)}
					{fileFormat != 1 && (
						<>
							{shortenName ? (
								<Tooltip title={item.original_name} placement="top">
									<Typography className="tt-text-center tt-max-w-full tt-px-20 tt-my-10 tt-break-all" variant="caption">
										{shortenName}
									</Typography>
								</Tooltip>
							) : (
								<Typography className="tt-text-center tt-max-w-full tt-px-20 tt-my-10 tt-break-all" variant="caption">
									{item.original_name}
								</Typography>
							)}
						</>
					)}
					{deleteFileFn && (
						<div
							onClick={(event) => {
								event.stopPropagation()
								deleteFileFn(item.id)
							}}
							className={styles.button}
						>
							<DeleteOutlinedIcon className="tt-w-18 tt-h-18 tt-text-black/60" />
						</div>
					)}
				</div>
			</div>
		</Tooltip>
	)
}

const GridFiles = ({ id, files, deleteFileFn, toCommentCard }) => {
	const viewableItems = []
	const allToViewIndexes = []
	let vieweableItemIndex = 0

	//const taskFiles = useSelector(getFiles)

	const sortedByCreateTimeFiles = [...files].sort((file1, file2) => {
		const [firstValue, secondValue] = [file1, file2]
			// eslint-disable-next-line camelcase
			.map(({ created_at }) => (created_at ? new Date(created_at) : 0))
		return firstValue - secondValue
	})

	sortedByCreateTimeFiles.map((item) => {
		if (supportedVideoFormats.includes(item.format) || supportedImageFormats.includes(item.format)) {
			const itemMediaType = item.format.includes('image') ? 'image' : 'video'

			const viewableItem = {
				id: item.id,
				mediaType: itemMediaType,
				title: item.original_name,
				url: item.link,
			}
			viewableItems.push(viewableItem)
			allToViewIndexes.push(vieweableItemIndex)
			vieweableItemIndex++
		} else {
			allToViewIndexes.push(null)
		}
	})

	return (
		<div
			className={`tt-grid ${toCommentCard ? 'tt-grid-cols-5' : 'tt-grid-cols-3'} tt-grid-rows-1 md:${
				toCommentCard ? 'tt-grid-cols-6' : 'tt-grid-cols-4'
			} tt-gap-6 tt-mt-6`}
		>
			{sortedByCreateTimeFiles?.map((item, index) => (
				<File
					deleteFileFn={deleteFileFn}
					id={getID({ prefix: id, type: 'img', index })}
					key={item.id}
					item={item}
					viewableItems={viewableItems}
					inGalleryIndex={allToViewIndexes[index]}
				/>
			))}
		</div>
	)
}

export default GridFiles
