import React, { memo } from 'react'
import { useParams } from 'react-router-dom'

const TaskSideBarCheck = memo(({ children }) => {
	const { taskId } = useParams()
	// const dispatch = useDispatch()

	return <> {taskId && children}</>
})

export default TaskSideBarCheck
