import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import { useDeleteProjectMutation } from 'src/old-app/service/Projects'
import { getProject, getProjectUserRole, projectsActions } from 'src/old-app/store/projectsSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getID } from '../../../shared/config/fields-ids'
import AddTaskIcon from '@mui/icons-material/AddTask'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import { useProjectShow } from '../../../entities/project'
import ProjectEnd from '../../../features/project/ui/project-end'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'
import { getAdminRole } from 'src/old-app/store/userSlice'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

function ActionsMenu() {
	const { t } = useTranslation()
	const { typeSlug } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const projectShow = useProjectShow()
	const isAdmin = useSelector(getAdminRole)
	const isUser = useSelector(getProjectUserRole)
	const project = projectShow?.project
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [deleteProject] = useDeleteProjectMutation()

	const isCommittee = projectShow?.isCommittee

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	async function handleRemoveTask() {
		handleClose()
		await deleteProject(project?.id)
		dispatch(projectsActions.deleteProject(project?.id))

		if (project?.parent_id) {
			navigate(`/project/${project?.parent_id}/${typeSlug}`)
		} else {
			navigate('/project')
		}
	}

	const openDialog = (comp) => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(comp))
		handleClose()
	}

	return (
		<div>
			<IconButton
				sx={{ width: 32, height: 32 }}
				id={getID({ place: 'sidebar', entity: 'project', action: 'manage', type: 'button_open' })}
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
			</IconButton>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-label': 'basic-button',
				}}
			>
				<CommentedDisability
					optionDisabled={isCommittee}
					disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
				>
					<MenuItem
						disabled={!isAdmin && projectShow.isContract}
						id={getID({ entity: 'project_template', type: 'button_create' })}
						onClick={() => openDialog('create-template-project')}
					>
						<ListItemIcon className="tt-min-w-40">
							<FuseSvgIcon>heroicons-outline:template</FuseSvgIcon>
						</ListItemIcon>
						<ListItemText primary={t('save_project_as_template')} />
					</MenuItem>
				</CommentedDisability>

				{!projectShow?.project?.isEnd && projectShow.isManageProject && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<MenuItem
							id={getID({ entity: 'child_project', type: 'button_create' })}
							onClick={() => openDialog('project-child-create')}
						>
							<ListItemIcon className="tt-min-w-40">
								<AccountTreeOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary={t('create_new.subproject')} />
						</MenuItem>
					</CommentedDisability>
				)}

				{!projectShow?.project?.isEnd && projectShow.isShowCreateTaskButton && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<MenuItem id={getID({ entity: 'task', type: 'button_create' })} onClick={() => openDialog('task-create')}>
							<ListItemIcon className="tt-min-w-40">
								<AddTaskIcon />
							</ListItemIcon>
							<ListItemText primary={t('create_new.task')} />
						</MenuItem>
					</CommentedDisability>
				)}

				{projectShow?.canCloseProject && (
					<ProjectEnd
						isSubProject={Boolean(projectShow?.project?.parent_id)}
						childProjectsCompleted={project?.child_projects_completed}
						isMenu
						projectId={project?.id ?? ''}
						curStatus={project?.confirmation_status}
					/>
				)}
				<MenuItem id={getID({ entity: 'task', type: 'button_create' })} onClick={() => openDialog('project-public')}>
					<ListItemIcon className="tt-min-w-40">
						<PublicOutlinedIcon />
					</ListItemIcon>
					<ListItemText primary={t('public_board')} />
				</MenuItem>
				{!projectShow?.isMyProject && !projectShow?.project?.isEnd && projectShow.isManageProject && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<ConfirmDelete
							text={t('delete_project_dialog.are_you_shure')}
							onSuccess={handleRemoveTask}
							title={t('delete_project_dialog.header')}
						>
							<MenuItem id={getID({ entity: 'project', type: 'button_delete' })}>
								<ListItemIcon className="tt-min-w-40">
									<FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
								</ListItemIcon>
								<ListItemText primary={t('delete_the.project')} />
							</MenuItem>
						</ConfirmDelete>
					</CommentedDisability>
				)}
			</Menu>
		</div>
	)
}

export default ActionsMenu
