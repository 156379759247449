import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { TextareaAutosize } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useCreateTaskDeadlineMutation } from 'src/old-app/service/Tasks'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import DialogFooter from '../DialogFooter'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../../shared/config/fields-ids'
import { getProject } from '../../../store/projectsSlice'
import { useTranslation } from 'react-i18next'
import { InputContainer } from 'src/shared/components'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

const EditDeadline = () => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		deadline: yup.date().required(t('deadline_is_required')).typeError(t('invalid_date')),
	})

	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const project = useSelector(getProject)
	const user = useGetUser()
	const {
		setValue,
		handleSubmit,
		register,
		control,
		setError,
		formState: { errors },
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const [createTaskDeadline, taskDeadlinesResult] = useCreateTaskDeadlineMutation()

	useEffect(() => {
		if (taskDeadlinesResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: taskDeadlinesResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					variant: 'error',
				})
			)
		}
	}, [taskDeadlinesResult])

	const isValidation = (data) => {
		const deadlines = task?.deadlines ?? []
		const lastDeadline = deadlines?.length ? deadlines[0] : undefined

		if (!lastDeadline) return true
		if (
			lastDeadline?.confirmation_status === 'ON_CONFIRMATION' &&
			dayjs(lastDeadline?.deadline).format('YYYY-MM-DD') === dayjs(data?.deadline).format('YYYY-MM-DD')
		) {
			dispatch(
				showMessage({
					message: t('deadline_already_submitted'),
					autoHideDuration: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					variant: 'warning',
				})
			)
			return false
		}

		if (
			lastDeadline?.confirmation_status === 'APPROVED' &&
			dayjs(lastDeadline?.deadline).format('YYYY-MM-DD') === dayjs(data?.deadline).format('YYYY-MM-DD')
		) {
			dispatch(
				showMessage({
					message: t('deadline_already_confirmed'),
					autoHideDuration: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					variant: 'success',
				})
			)
			return false
		}

		return true
	}
	const editDeadlineHandler = async (data) => {
		try {
			const isValidate = isValidation(data)
			if (!isValidate) return
			dispatch(interfaceActions.setIsLoading(true))

			const deadline = dayjs(data?.deadline).format()

			const taskDeadlinesData = await createTaskDeadline({ task_id: task.id, deadline, comment: data?.comment })
			const taskDeadlines = taskDeadlinesData?.data?.data

			if (!taskDeadlines) return
			dispatch(dialogActions.setDialog(false))
			dispatch(interfaceActions.setIsLoading(false))

			if (project?.with_administration) {
				dispatch(
					showMessage({
						message: t('deadline_sent_for_confirmation'),
						autoHideDuration: 1000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
						variant: 'success',
					})
				)
			} else {
				dispatch(
					showMessage({
						message: t('deadline_changed_to', { value: dayjs(deadline).format('DD.MM.YYYY') }),
						autoHideDuration: 1000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
						variant: 'success',
					})
				)
			}
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					variant: 'error',
				})
			)
		}
	}

	const isFirstDeadline =
		project?.with_administration || project?.type?.name === 'committee' ? task?.deadlines?.length === 0 : true

	return (
		<form onSubmit={handleSubmit(editDeadlineHandler)} className="tt-w-600">
			<DialogHeader title={t('select_deadline')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<InputContainer label={t('deadline')} error={errors?.deadline}>
						<Controller
							render={({ field: { value, onChange } }) => <DatePickerInput value={value} onChange={onChange} />}
							name="deadline"
							control={control}
						/>
					</InputContainer>

					{!isFirstDeadline && (
						<InputContainer label={t('comment')} error={errors?.comment}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextareaAutosize
										id={getID({ place: 'modal', field: 'comment', type: 'date' })}
										minRows={3}
										maxRows={10}
										onChange={onChange}
										value={value}
										error={!!errors.comment}
										placeholder={t('comment')}
										size="small"
										aria-label="minimum height"
										className="tt-border tt-border-gray-300 tt-p-12  tt-rounded-lg"
									/>
								)}
								name="comment"
								control={control}
							/>
						</InputContainer>
					)}
				</div>
			</DialogMain>
			<DialogFooter rightLabel={t('change')} />
		</form>
	)
}

export default EditDeadline
