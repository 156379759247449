import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const authAPI = createApi({
	reducerPath: 'auth-api',
	baseQuery,
	endpoints: (build) => ({
		register: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/register/`,
				body: JSON.stringify(body),
			}),
			// providesTags: (result) => ['Users'],
		}),
		login: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/login/`,
				body: JSON.stringify(body),
			}),
			// providesTags: (result) => ['Users'],
		}),
	}),
})

export const { useRegisterMutation, useLoginMutation } = authAPI
