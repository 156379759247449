import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import ProjectSideBar from './ProjectSideBar'

const ProjectSideBarCheck = ({ backTo, paramId = 'projectId' }) => {
	const params = useParams()

	const projectId = params[paramId]

	return (
		<div className="tt-w-full tt-flex-1 tt-flex tt-flex-col">
			{projectId && <ProjectSideBar paramId={paramId} backTo={backTo} />}
		</div>
	)
}

export default ProjectSideBarCheck
