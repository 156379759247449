import React, { lazy, Suspense, memo } from 'react'
import { CircularProgress } from '@mui/material'
const TaskSideBar = lazy(() => import('src/old-app/components/task/TaskSideBar'))

const Task = memo(() => {
	return (
		<div className="tt-w-full ">
			<div className="tt-max-w-900 tt-w-full tt-mx-auto">
				<Suspense fallback={<CircularProgress className="tt-text-primary-600" />}>
					<TaskSideBar hideHeader />
				</Suspense>
			</div>
		</div>
	)
})

export default Task
