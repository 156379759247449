import React from 'react'
import styles from './styles.module.scss'
import { CircularProgress } from '@mui/material'

interface IProps {}

const LoadPage: React.FC<IProps> = () => {
	return (
		<div
			style={{
				padding: 20,
				height: '100vh',
				width: '100vw',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: '#fff',
			}}
		>
			<CircularProgress />
		</div>
	)
}

export default LoadPage
