import React, { ChangeEvent, useState } from 'react'
import styles from './styles.module.scss'
import './styles.scss'
import classnames from 'classnames'

import usePagination, { DOTS } from '../usePagination'
import { useTable } from '../useTable'
import { SelectChangeEvent } from '@mui/material'
import Select from '../../select'
import { uniqueId } from 'lodash'

const pageSizes: { id: Id; size: number }[] = [
	{
		id: uniqueId(),
		size: 5,
	},
	{
		id: uniqueId(),
		size: 10,
	},
	{
		id: uniqueId(),
		size: 15,
	},
]

interface IProps {}

const TablePagination: React.FC<IProps> = (props) => {
	const table = useTable()

	const [inputPage, setInputPage] = useState<number>(1)

	const { current_page: currentPage, per_page: pageSize, total_entries: totalCount } = table.state.pagination

	const onPageChange = (page: number | string) => {
		table.setState({
			...table.state,
			pagination: {
				...table.state.pagination,
				current_page: +page,
			},
		})
	}

	const siblingCount = 1

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	})

	if (!paginationRange) {
		return null
	}

	const onNext = () => {
		onPageChange(currentPage + 1)
	}

	const onPrevious = () => {
		onPageChange(currentPage - 1)
	}

	const onChangeEnterPage = (event: ChangeEvent<HTMLInputElement>) => {
		const page = +event.target.value
		if (page > totalCount / pageSize + 1) return
		setInputPage(page)
	}

	const onBlurEnterPage = () => {
		if (1 > inputPage) setInputPage(1)
		if (!inputPage) return setInputPage(1)
		onPageChange(inputPage)
	}

	const onChangePageSize = (event: SelectChangeEvent) => {
		const size = +event.target.value

		table.setState({
			...table.state,
			pagination: {
				...table.state.pagination,
				per_page: +size,
			},
		})
	}

	let lastPage = paginationRange[paginationRange.length - 1]

	const isFetching = table.isFetching

	const countRangeCurrentPage = currentPage - 1
	const startRangeCount = countRangeCurrentPage * pageSize
	const endRangeCount = startRangeCount + pageSize

	return (
		<div className={styles['table-pagination']}>
			<div className={styles['pagination-side']}>
				<span className={classnames(styles['pagination-count'], styles['pagination-label'])}>
					{startRangeCount} - {endRangeCount > totalCount ? totalCount : endRangeCount} из {totalCount}
				</span>
				<div className={styles['pagination-page-size']}>
					<span className={classnames(styles['pagination-page-size-label'], styles['pagination-label'])}>
						Показывать
					</span>
					<Select
						renderLabel={(item) => item.size}
						valueField="size"
						list={pageSizes}
						onChange={onChangePageSize}
						value={pageSize}
					/>
				</div>
			</div>
			<div className={styles['pagination-side']}>
				<div className={styles['pagination-slide']}>
					<ul className={classnames('pagination-container')}>
						<li
							className={classnames('pagination-item', {
								disabled: currentPage === 1 || isFetching,
							})}
							onClick={onPrevious}
						>
							<div className="arrow left" />
						</li>
						{paginationRange.map((pageNumber) => {
							if (pageNumber === DOTS) {
								return <li className="pagination-item dots">&#8230;</li>
							}

							return (
								<li
									className={classnames('pagination-item', {
										selected: pageNumber === currentPage,
									})}
									onClick={() => onPageChange(pageNumber)}
								>
									{pageNumber}
								</li>
							)
						})}
						<li
							className={classnames('pagination-item', {
								disabled: currentPage === lastPage || isFetching,
							})}
							onClick={onNext}
						>
							<div className="arrow right" />
						</li>
					</ul>
				</div>
				<div className={styles['pagination-enter-page']}>
					<span className={classnames(styles['pagination-enter-label'], styles['pagination-label'])}>
						Перейти к странице
					</span>
					<input
						disabled={isFetching}
						defaultValue={0}
						onBlur={onBlurEnterPage}
						onChange={onChangeEnterPage}
						className={styles['pagination-enter-input']}
						type="number"
						value={inputPage}
					/>
				</div>
			</div>
		</div>
	)
}

export default TablePagination
