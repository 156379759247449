import Protocol from './Protocol'
import ProtocolCreate from './ProtocolCreate'

const ProtocolConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'protocol',
			element: <Protocol />,
			children: [
				{
					path: ':protocolId',
					element: <Protocol />,
				},
			],
		},
		{
			path: 'protocol/create',
			element: <ProtocolCreate />,
		},
	],
}

export default ProtocolConfig
