import React, { useEffect, useMemo } from 'react'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import TaskList from 'src/old-app/main/tasks/TaskList'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import ScrumboardList from 'src/old-app/main/tasks/scrumboard/ScrumboardList'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Chip, Tab, Tabs } from '@mui/material'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { ProjectAssimilation, ProjectCofirmirmation, ProjectSettings } from 'src/pages/project'
import { useDispatch, useSelector } from 'react-redux'
import {
	getProject,
	getProjectAdminContractRole,
	getProjectAdminRole,
	getProjectCuratorRole,
} from 'src/old-app/store/projectsSlice'
import TasksViolations from 'src/old-app/components/analysis/TasksViolations'
import ExportKPG from '../../../../old-app/components/project/ExportKPG'
import { ProjectSubprojects, ProjectToParent, useProjectShow } from 'src/entities/project'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import { Button } from '../../../../shared/components'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import ActionsMenu from '../../../../old-app/components/project/ActionsMenu'
import ProjectPlan from '../../../../pages/project/project-plan'
import ProjectContract from '../../../../pages/project/project-contract'
import { useGetUser } from '../../../../shared/hooks'
import ProjectSubprojectRequests from '../project-subproject-requests'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import { useTranslation } from 'react-i18next'
import { ProjectContractDirectoryTable } from 'src/@widgets/project'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import ProjectProtocolsTable from '../project-protocols-table/ProjectProtocolsTable'

interface IProps {
	page: string
}

const defaultTab = 'list'

const ProjectContent: React.FC<IProps> = ({ page }) => {
	const { t } = useTranslation()
	const { typeSlug, taskId } = useParams()
	const { search } = useLocation()
	const navigate = useNavigate()
	const projectShow = useProjectShow()
	const isAdminProjectContract = useSelector(getAdminProjectContractRole) || useSelector(getProjectAdminContractRole)
	const isCurator = useSelector(getProjectCuratorRole)
	const isAdmin = useSelector(getAdminRole)
	const user = useGetUser()
	const dispatch = useDispatch()
	const project = projectShow?.project
	const [value, setValue] = React.useState(typeSlug ?? defaultTab)

	const currentPage = `${page}/${value}`

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue)
		const baseTo = `${page}/${newValue}`

		let to
		if (newValue === 'list' || newValue === 'board') {
			to = taskId ? baseTo + `/${taskId}` + search : baseTo + search
		} else {
			to = taskId ? baseTo + `/${taskId}` : baseTo
		}
		navigate(to)
	}

	const tabs: any = useMemo(() => {
		const viewIsAdmin = project?.with_administration
		const viewIsCommittee = project?.type?.name === 'committee'

		const items: any[] = [
			{
				id: 1,
				label: t('table'),
				icon: <ViewWeekOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				slug: 'list',
				className: 'tt-px-16 tt-py-16',
				children: (
					<TaskList
						propsHideSkelet={undefined}
						right={undefined}
						isAccordionHandler={undefined}
						childrenRender={undefined}
						taskParams={undefined}
					/>
				),
				isShow: true,
				buttonComponent: {
					label: t('create_new.task'),
					component: 'task-create',
					isShow: projectShow.isShowCreateTaskButton,
					isDisabled: viewIsCommittee,
				},
			},

			{
				id: 2,
				icon: <ViewKanbanOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('kanban'),
				slug: 'board',
				className: 'tt-pt-16 overflow-hidden',
				children: <ScrumboardList />,
				isShow: true,
				buttonComponent: {
					label: t('create_new.task'),
					component: 'task-create',
					isShow: projectShow.isShowCreateTaskButton,
					isDisabled: viewIsCommittee,
				},
			},
			{
				id: 3,
				icon: <AccountBalanceOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('interrupted_tasks'),
				slug: 'sanction',
				className: 'tt-px-16 tt-py-16',
				children: (
					<TasksViolations
						isManage={projectShow.isContract ? isCurator : isAdmin || projectShow.isManageProject}
						isUrl={false}
						allProjectsMode={false}
						pageLink={currentPage}
						projectId={project?.id}
					/>
				),
				isShow:
					viewIsAdmin &&
					(projectShow?.isContract
						? isAdminProjectContract || isCurator || projectShow.isManageProject
						: projectShow.isManageProject) &&
					!viewIsCommittee,
			},
			{
				id: 4,
				icon: <FactCheckOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('my_requests'),
				slug: 'my-requests',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectCofirmirmation myRequest />,
				isShow: (projectShow?.project?.isEnd ? false : project.with_administration) && !viewIsCommittee,
			},
			{
				id: 5,
				icon: <FactCheckOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('confirmations'),
				slug: 'confirmations',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectCofirmirmation />,
				isShow:
					(projectShow?.project?.isEnd
						? false
						: project.with_administration &&
						  (projectShow.isContract ? projectShow?.isManageProject || isCurator : projectShow.isManageProject)) &&
					!viewIsCommittee,
			},
			{
				id: 6,
				icon: <FolderOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('catalog'),
				slug: 'contract-directory',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectContractDirectoryTable />,
				isShow: projectShow.isContract && !viewIsCommittee,
				buttonComponent: {
					label: t('create_new.catalog'),
					component: 'create-project-contract-directory',
					isShow: projectShow.isContract && (isAdminProjectContract || isAdmin) && !projectShow?.project?.isEnd,
				},
			},
			{
				id: 7,
				icon: <AccountTreeOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('subprojects'),
				slug: 'sub-projects',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectSubprojects curPage={`${page}/${typeSlug}/p`} />,
				isShow: !viewIsCommittee,
				buttonComponent: {
					label: t('create_new.project'),
					component: 'project-child-create',
					isShow: projectShow.isManageProject && !projectShow?.project?.isEnd,
					isDisabled: false,
				},
			},
			{
				id: 8,
				icon: <RuleOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('subprojects_confirmation'),
				slug: 'sub-project-requests',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectSubprojectRequests curPage={`${page}/${typeSlug}/p`} />,
				isShow: (projectShow?.project?.isEnd ? false : projectShow.isManageProject) && !viewIsCommittee,
			},
			{
				id: 9,
				icon: <ArticleOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('protocols'),
				slug: 'protocols',
				className: 'tt-px-0 tt-py-0 tt-overflow-hidden',
				children: <ProjectProtocolsTable />,
				isShow: viewIsCommittee,
			},
			{
				id: 10,
				icon: <SettingsOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				label: t('settings'),
				slug: 'settings',
				className: 'tt-px-16 tt-py-16',
				children: <ProjectSettings projectId={project?.id} />,
				isShow: true,
			},
		]
		return items.filter((item) => item.isShow)
	}, [project, projectShow, isAdminProjectContract, isCurator, isAdmin])

	useEffect(() => {
		if (typeSlug) {
			setValue(typeSlug)
		}
	}, [typeSlug])

	const openDialog = (components: string) => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(components))
	}

	const currentButton = tabs.find((tab: any) => tab.slug === typeSlug)

	return (
		<div className="tt-w-full tt-flex tt-flex-col tt-h-full">
			<div className="tt-sticky tt-px-16 tt-border-b tt-border-gray-200 tt-top-0 tt-pt-24 tt-bg-white z-[999]">
				<div className="tt-flex tt-justify-between tt-min-h-[42px] tt-gap-16 tt-pb-10">
					<div className="tt-flex tt-gap-8 tt-flex-col">
						<div className="tt-flex tt-gap-8 tt-items-center">
							{project.parent_id && (
								<ProjectToParent
									title={`${t('back_to')} '${project?.parent?.name}'`}
									projectId={project.id}
									parentId={project.parent_id}
								/>
							)}
							<div className="tt-flex tt-items-end tt-gap-12">
								<div className="my-truncate tt-text-2xl tt-font-semibold  tt-flex-1 tt-max-w-512  tt-w-full">
									{project?.name}{' '}
								</div>
								{project?.type && (
									<Chip
										sx={{ height: 24 }}
										className="tt-truncate tt-inline-flex tt-font-medium tt-text-12"
										label={t(`types.${project?.type?.name}`)}
									/>
								)}
							</div>
						</div>
					</div>
					<div>
						<div className="tt-flex tt-items-center tt-gap-6">
							<ExportKPG />
							{currentButton?.buttonComponent?.isShow && (
								<Button
									disabled={currentButton?.buttonComponent?.isDisabled}
									onClick={() => openDialog(currentButton?.buttonComponent?.component)}
								>
									{currentButton?.buttonComponent?.label}
								</Button>
							)}
							<ActionsMenu />
						</div>
					</div>
				</div>

				<Box sx={{ width: '100%', minHeight: 'auto' }}>
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						value={value}
						classes={{
							flexContainer: 'tt-flex tt-gap-18',
						}}
						sx={{ minHeight: 'auto' }}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						{tabs?.map((item: any) => (
							<Tab
								key={item.slug}
								sx={{ minHeight: 'auto', padding: '12px 0px' }}
								iconPosition="start"
								icon={item.icon}
								label={item.label}
								value={item.slug}
							/>
						))}
					</Tabs>
				</Box>
			</div>

			<Routes>
				{tabs?.map((item: any) => (
					<Route
						key={item.slug}
						path={item.slug + '/*'}
						element={
							<div
								id="project-content"
								className={['tt-overflow-auto tt-h-full table-scroll', item.className].join(' ')}
							>
								{item.children}
							</div>
						}
					/>
				))}
			</Routes>
		</div>
	)
}

export default ProjectContent
