import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MySelect from 'src/old-app/components/ui/MySelect'
import { Autocomplete } from 'src/shared/components'

interface ProjectContractCategoryProps {
	value: any
	onChange: (value: any) => void
	placeholder?: string
	field?: string
}

export const ProjectContractCategory: React.FC<ProjectContractCategoryProps> = (p) => {
	const { t } = useTranslation()
	const { value, onChange, placeholder = 'Категория НР', field = 'category' } = p

	const list = [
		{ id: 'concepts' },
		{ id: 'org_structure_job_description' },
		{ id: 'wage_motivation' },
		{ id: 'employees' },
		{ id: 'instructions_positions_regulations' },
		{ id: 'other' },
	]

	return (
		<Autocomplete
			multiple={false}
			placeholder={placeholder}
			optionLabel={(option: any) => t(`contract_categories.${option?.id}`)}
			renderOptionLabel={(option: any) => t(`contract_categories.${option?.id}`)}
			list={list}
			value={value?.id ? { id: value?.id } : null}
			onChange={onChange}
		/>
	)
}
