import React from 'react'
import { getIsOpenEpic, interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useSelector, useDispatch } from 'react-redux'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { IconButton } from '@mui/material'
import { getID } from '../../../shared/config/fields-ids'

const TaskEpicSwitch = ({ id, disabled }) => {
	const dispatch = useDispatch()
	const isOpenEpic = useSelector(getIsOpenEpic)

	const changeHandler = () => {
		dispatch(interfaceActions.setIsOpenEpic(!isOpenEpic))
	}

	return (
		<div>
			<IconButton
				disabled={disabled}
				id={getID({ prefix: id, field: 'sub_tasks', fieldPostfix: 'show', type: 'button_toggle' })}
				onClick={changeHandler}
				className="tt-w-20 tt-h-20 tt-mr-3 disabled:tt-text-gray-400 disabled-hover:tt-text-gray-400"
			>
				<ArrowDropDownCircleOutlinedIcon
					className={['tt-duration-500 tt-w-15 tt-h-15 ', isOpenEpic ? 'tt-rotate-180' : ''].join(' ')}
				/>
			</IconButton>
		</div>
	)
}

export default TaskEpicSwitch
