/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unreachable */
import React, { memo } from 'react'
import moment from 'moment'

import TaskText from './TaskText'
import ProjectText from './ProjectText'
import RequestApproved from '../../components/requests/RequestApproved'
import { useGetOneTaskQuery } from 'src/old-app/service/Tasks'
import TasksDeadlineText from './TasksDeadlineText'
import RequestCancel from 'src/old-app/components/requests/RequestCancel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { requestType } from 'src/entities/task'
import { useTranslation } from 'react-i18next'

const RequestItemId = memo(({ item, type, id, disabledLink }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { search } = useLocation()
	const { requestId } = useParams()

	const task = item.task

	const goInfo = () => {
		if (disabledLink) return
		navigate(`/requests/${id}/${type}${search}`)
	}

	return (
		<div
			className={[
				'tt-rounded-lg tt-border group tt-p-12  tt-flex tt-flex-wrap tt-gap-32 tt-relative',
				'tt-bg-white  tt-border-gray-200',
			].join(' ')}
		>
			<div className="tt-flex tt-flex-col tt-gap-12 tt-w-full">
				<div onClick={goInfo} className="tt-flex tt-gap-16 tt-flex-1 tt-cursor-pointer">
					<div className="tt-flex tt-flex-col tt-gap-2 tt-flex-1">
						<div className="tt-text-16 tt-font-medium ">
							{requestType.TASK_STATUS === type && task?.title}
							{requestType.TASKS_DEADLINE === type && item?.deadline?.task?.title}
						</div>
						<div className="tt-text-gray-600">
							{requestType.TASK_STATUS === type && <TaskText item={item} />}
							{requestType.TASKS_DEADLINE === type && <TasksDeadlineText item={item} />}
						</div>
					</div>
				</div>
				<div>
					{requestType.TASKS_DEADLINE === type && (
						<div className="tt-flex tt-items-center tt-gap-12">
							<RequestApproved item={item} id={id} type={type} label={t('confirm_change')} />
							<RequestCancel type={type} label={t('reject')} id={item.deadline_id} />
						</div>
					)}
					{requestType.TASK_STATUS === type && (
						<>
							<div className="tt-flex tt-items-center tt-gap-12">
								<RequestApproved item={item} id={id} type={type} label={t('confirm_completion')} />
								<RequestCancel type={type} id={id} label={t('reject')} />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
})

RequestItemId.displayName = 'RequestItemId'
export default RequestItemId
