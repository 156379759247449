// export const requestTypes = {
// 	PROJECT: 'PROJECT', // подтверждение заявок на ввод новых проектов,
// 	TASKS_DEADLINES: 'TASKS_DEADLINES', // подтверждение заявок на ввод новых проектов,
// 	TASK: 'TASK', // подтверждение заявок на ввод новых проектов,
// 	REQ_CREATE_PROJECT: 'REQ_CREATE_PROJECT', // подтверждение заявок на ввод новых проектов,
// 	REQ_REQUESTS_DEDUCTION_SANCTIONS: 'REQ_REQUESTS_DEDUCTION_SANCTIONS', // запросов на неначисление санкций,
// 	REQ_CHANGING_USER_RIGHTS_STATUS_THEIR_PROCESSING:
// 		'REQ_CHANGING_USER_RIGHTS_STATUS_THEIR_PROCESSING', // запросов на изменение прав пользователя и статус их обработки,
// 	REQ_CONFIRMATION_TASK_COMPLETION_REPORT: 'REQ_CONFIRMATION_TASK_COMPLETION_REPORT', // запросов на подтверждение отчета о выполнении задачи,
// 	REQ_CHANGING_DEADLINES_COMPLETING_TASK: 'REQ_CHANGING_DEADLINES_COMPLETING_TASK', // подтверждение изменение сроков выполнения задачи,
// 	REQ_POSTPONEMENT_OF_DEADLINE: 'REQ_POSTPONEMENT_OF_DEADLINE', // подтверждение переноса срока выполнения,
// 	ADDING_ACCOUNTING_DOCUMENTS: 'REQ_ADDING_ACCOUNTING_DOCUMENTS', // подтверждение Добавление отчетных документов с возможностью отправки их на проверку и подтверждение ДСМ проекта / ответственному за учет задач по Протоколу совещания,
// }

export enum requestType {
	TASK_STATUS = 'TASK_STATUS',
	USER_CANCEL_TASK_STATUS = 'USER_CANCEL_TASK_STATUS',
	USER_CANCEL_TASKS_DEADLINE = 'USER_CANCEL_TASKS_DEADLINE',
	USER_CANCEL = 'USER_CANCEL',
	TASKS_DEADLINE = 'TASKS_DEADLINE',
}
