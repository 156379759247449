import React, { useMemo, useState } from 'react'
import styles from './styles.module.scss'
import UnTable from '../../../../shared/components/un-table'
import { useTeamRequests } from '../../../../entities/team'
import UserCell from '../../../../old-app/components/tables/UserCell'
import TeamUsersCell from '../../../../old-app/components/team/TeamUsersCell'
import TextFilter from '../../../../shared/components/filters/text-filter'
import UserFilter from '../../../../shared/components/filters/user-filter'
import { useLocation, useParams } from 'react-router-dom'
import { TeamApproved, TeamRequestButton } from '../../../../entities/team/components'
import { UserShow } from '../../../../shared/components'
import TagsView from '../../../../old-app/components/ui/TagsView'
import TeamTagsFilter from '../../../../shared/components/filters/team-tags-filter'
import TaskMoreFilters from '../../../../old-app/components/task/TaskMoreFilters'
import { useTranslation } from 'react-i18next'

interface IProps {}

const defaultPerPage = 100

const TeamRequestsContent: React.FC<IProps> = () => {
	const { t } = useTranslation()
	const columns = useMemo(
		() => [
			{
				id: 2,
				label: 'Название',
				field: 'name',
				className: 'tt-flex-1',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap tt-break-all tt-flex-1 tt-min-w-76  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.name}
					</p>
				),
			},
			{
				id: 3,
				label: 'Автор',
				field: 'author',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => <>{listItem?.author_view && <UserShow user={listItem?.author_view} />}</>,
			},
			{
				id: 4,
				label: 'Теги',
				field: 'tags',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => (
					<>
						<TagsView isHidePlaceholder={false} tags={listItem?.hashtags ?? []} />
					</>
				),
			},
			{
				id: 5,
				label: '',
				field: '',
				className: 'tt-max-w-[162px] tt-min-w-[162px] tt-w-[162px]',
				render: (listItem: any) => (
					<>
						<TeamRequestButton teamId={listItem.id} />
					</>
				),
			},
		],
		[]
	)

	const filters = useMemo(
		() => [
			{
				id: 2,
				render: (listItem: any) => (
					<>
						<TextFilter placeholder={t('title')} />
					</>
				),
			},
			{
				id: 3,
				render: (listItem: any) => (
					<>
						<UserFilter isAll placeholder={t('author')} field="author" />
					</>
				),
			},
			{
				id: 4,
				render: (listItem: any) => (
					<>
						<TeamTagsFilter placeholder={t('tags')} />
					</>
				),
			},
			{
				id: 5,
				render: (listItem: any) => (
					<>
						<TaskMoreFilters
							prefix={''}
							hideTaskParamsFilter
							hideSkelet={{ deadline_sort: true }}
							isLabel
							allProjectsMode={true}
							projectId={undefined}
							id={undefined}
						/>
					</>
				),
			},
		],
		[]
	)

	const heads = columns.map((column, index) => {
		return { ...column, render: filters[index]?.render }
	})

	const { search } = useLocation()
	const { teamId } = useParams()
	const [perPage, setPerPage] = useState(defaultPerPage)
	const { teams, meta, isLoading, isFetching } = useTeamRequests({ params: { per_page: perPage, page: 1 } })
	const page = '/team-requests'

	const link = (id: Id) => `${page}/${id}${search}`

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	const rowActive = (id: Id) => {
		return id === teamId
	}

	return (
		<div className="tt-flex tt-flex-col tt-overflow-auto table-scroll">
			<UnTable
				isLoading={isLoading}
				isFetching={isFetching}
				meta={meta}
				rowActive={rowActive}
				emptyPlaceholder={t('empty_teams_confirmation_list')}
				loadMore={loadMore}
				link={link}
				heads={heads}
				list={teams}
				columns={columns}
			/>
		</div>
	)
}

export default TeamRequestsContent
