import React, { Suspense, useEffect, useState } from 'react'
import FusePageSimple from 'src/@fuse/core/FusePageSimple'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import PageHeader from '../components/layout/PageHeader'
import clsx from 'clsx'

import { navAsideWidth } from 'src/old-app/configs/themesConfig'
const _fullWidth = {
	// width: `calc(100vw - ${navAsideWidth})`,
	minWidth: `calc(100vw - ${navAsideWidth} - 8px)`,
}

const PageWithSideBar = ({
	content,
	className,
	contentClassName,
	headerCreateComponent,
	headerOnCreate,
	headerTitle,
	headerTopRight,
	headerTopRightLeft,
	hideCreateButton,
	id,
	leftTitleComponent,
	pageLink,
	param,
	rightSidebarContent,
	showCreateButton,
	buttonLabel,
}) => {
	const params = useParams()
	const { search } = useLocation()
	const navigate = useNavigate()
	const getParam = params[param]
	const [rightSidebarOpen, setRightSidebarOpen] = useState(Boolean(getParam))

	useEffect(() => {
		setRightSidebarOpen(Boolean(getParam))
	}, [getParam])

	const onSidebarOnClose = () => {
		setRightSidebarOpen(false)
		navigate({ pathname: `/${pageLink}`, search })
	}

	return (
		<FusePageSimple
			className={className}
			content={
				<div
					style={_fullWidth}
					className={clsx('tt-flex tt-flex-col tt-w-full tt-pl-16 tt-pr-8 tt-py-24 tt-pb-42', contentClassName)}
				>
					{headerTitle && (
						<Suspense fallback={<CircularProgress className="tt-text-primary-600" />}>
							<PageHeader
								leftTitleComponent={leftTitleComponent}
								id={id}
								buttonLabel={buttonLabel}
								hideCreateButton={hideCreateButton}
								showCreateButton={showCreateButton}
								onCreate={headerOnCreate}
								createComponent={headerCreateComponent}
								title={headerTitle}
								headerTopRightLeft={headerTopRightLeft}
								headerTopRight={headerTopRight}
							/>
						</Suspense>
					)}
					{content}
				</div>
			}
			rightSidebarContent={rightSidebarContent}
			rightSidebarOpen={rightSidebarOpen}
			rightSidebarOnClose={onSidebarOnClose}
			rightSidebarWidth={675}
			// scroll={rightSidebarOpen ? 'content' : 'page'}
			scroll={'content'}
		/>
	)
}

export default PageWithSideBar
