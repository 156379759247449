import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import { useCreateTaskMutation } from 'src/old-app/service/Tasks'
import { useParams } from 'react-router-dom'
import { Popover, TextareaAutosize, TextField } from '@mui/material'
import ProjectUsersSelect from 'src/old-app/components/task/ProjectUsersSelect'
import ErrorText from 'src/old-app/components/ui/ErrorText'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import clsx from 'clsx'
import TaskTypeSelect from '../../../../components/task/TaskTypeSelect'
import { getTaskTypes } from 'src/old-app/store/tasksSlice'
import Stack from '@mui/material/Stack'
import { InputContainer, UsersChoice } from '../../../../../shared/components'
import TaskTypesChoice from '../../../../../shared/components/task-types-choice'
import ProjectUsersChoice from '../../../../../shared/components/project-users-choice'
import { useTranslation } from 'react-i18next'
import { getProject } from 'src/old-app/store/projectsSlice'
import { ContractDirectorySelect } from 'src/@features/task'
import { useProjectShow } from 'src/entities/project'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
// import { newCard } from '../../store/cardsSlice';

/**
 * Form Validation Schema
 */

function BoardAddCard({ listId, className }) {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		assignees: yup.array().min(1, t('performer_is_required')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
	})
	const dispatch = useDispatch()
	const types = useSelector(getTaskTypes)
	const { projectId } = useParams()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const project = useSelector(getProject)
	const projectShow = useProjectShow()

	const isCommittee = projectShow?.isCommittee

	const [createTask] = useCreateTaskMutation()

	const defaultValues = {
		title: '',
		need_report: false,
		contract_directory: null,
		assignees: [],
		type: types[0],
	}

	const { control, formState, handleSubmit, reset } = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState

	const handleClick = (event) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const onSubmit = async (newData) => {
		try {
			dispatch(interfaceActions?.setIsLoading(true))
			const assigneeIds = newData?.assignees.map((item) => item.id)
			const taskBody = {
				project_id: projectId,
				title: newData?.title,
				contract_type: project?.isContract ? newData?.contract_directory?.id : undefined,
				project_status_id: listId,
				assignee_id: assigneeIds,
				need_report: newData?.need_report,
				type_id: newData.type.id,
			}

			await createTask(taskBody)

			dispatch(interfaceActions?.setIsLoading(false))
			reset()
			handleClose()
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			reset()
			handleClose()
		}
	}

	return (
		<div className={clsx('tt-w-full', className)}>
			<CommentedDisability
				optionDisabled={isCommittee}
				disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
			>
				<Button
					onClick={handleClick}
					aria-describedby={id}
					classes={{
						root: 'tt-font-medium tt-text-gray-600 tt-w-full tt-rounded-lg tt-p-24 tt-justify-start',
					}}
					startIcon={<FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>}
				>
					{t('add_task')}
				</Button>
			</CommentedDisability>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="MuiPaper-root tt-w-256 sm:tt-w-320 tt-p-14 MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root tt-w-full  tt-rounded-lg tt-shadow-sm tt-border tt-border-gray-200 tt-cursor-pointer  css-9t94pm-MuiPaper-root-MuiCard-root"
				>
					<div className="tt-flex tt-flex-col tt-gap-10 tt-mb-8 tt-w-full">
						<div className="tt-w-full">
							<Controller
								name="title"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										id="task-title"
										onChange={onChange}
										value={value}
										error={!!errors.title}
										placeholder={t('task_title')}
										className="dialig-input tt-w-full"
										size="small"
									/>
								)}
							/>
							<ErrorText errors={errors} field="title" />
						</div>

						<InputContainer error={errors?.type}>
							<Controller
								name="type"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TaskTypesChoice placeholder={t('task_type')} value={value} onChange={onChange} />
								)}
							/>
						</InputContainer>

						{project?.isContract && (
							<InputContainer error={errors?.contract_directory}>
								<Controller
									render={({ field: { value, onChange } }) => (
										<ContractDirectorySelect onChange={onChange} placeholder={t('works_type')} value={value} />
									)}
									name="contract_directory"
									control={control}
								/>
							</InputContainer>
						)}

						<InputContainer error={errors?.assignees}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<ProjectUsersChoice
										placeholder={t('participants')}
										projectId={projectId}
										value={value}
										onChange={onChange}
									/>
								)}
								name="assignees"
								control={control}
							/>
						</InputContainer>
					</div>

					<div className="tt-flex tt-gap-8 tt-items-center tt-w-full tt-justify-end">
						<Button
							onClick={handleClose}
							className="tt-bg-gray-200 hover:tt-bg-gray-300 tt-text-black  tt-min-h-32 tt-h-32 tt-px-16"
						>
							{t('cancel')}
						</Button>
						<Button
							type="submit"
							className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
						>
							{t('add')}
						</Button>
					</div>
				</form>
			</Popover>
		</div>
	)
}

export default BoardAddCard
