import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getProjects, projectsActions } from 'src/old-app/store/projectsSlice'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useCreateUserProjectMutation } from 'src/old-app/service/Projects'
import ProjectsSelect from '../../shared/ProjectsSelect'
import ProjectsSelectFilter from '../../shared/ProjectsSelectFilter'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { useTranslation } from 'react-i18next'

const AddUserProject = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isDisabled, setIsDisabled] = useState(false)

	const loc = useLocation()

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			projects: [],
		},
	})

	const [createProjectUser, result] = useCreateUserProjectMutation()

	const addUserProjectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		setIsDisabled(true)

		const updateUserProjectsBody = data.projects.map((project) => ({
			project_id: project.id,
			user_id: loc.pathname.split('/')[2],
			role: '',
		}))

		if (updateUserProjectsBody?.length > 0) {
			updateUserProjectsBody.forEach(async (item) => {
				const projectUser = await createProjectUser(item)
			})
		}
		// data.projects.forEach((item) => {
		// 	dispatch(
		// 		projectsActions.getProjectUser({
		// 			user: null,
		// 			project_id: item.id,
		// 			project: item,
		// 			user_id: loc.pathname.split('/')[2],
		// 		})
		// 	)
		// })

		dispatch(
			showMessage({
				message: t('user_is_added'),
				autoHideDuration: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
		reset({ projects: [] })
		dispatch(dialogActions.setDialog(false))
	}

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [result])

	return (
		<form onSubmit={handleSubmit(addUserProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('add_user_to_project')} />
			<DialogMain>
				<Controller
					render={({ field: { value, onChange } }) => (
						<ProjectsSelectFilter
							multiple
							errors={errors}
							displayEmpty
							field="projects"
							label={t('projects')}
							onChange={onChange}
							value={value}
						/>
					)}
					name="projects"
					control={control}
				/>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} rightLabel={t('add')} />
		</form>
	)
}

export default AddUserProject
