/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import ErrorText from '../../ui/ErrorText'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { useCreateStatusTaskMutation } from 'src/old-app/service/Tasks'
import { getProject } from 'src/old-app/store/projectsSlice'
import { getStatuses, tasksActions } from 'src/old-app/store/tasksSlice'
import getColor from 'src/old-app/utils/getColor'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const CreateStatus = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		status: yup.string().required(t('title_is_required')),
	})
	const dispatch = useDispatch()

	const project = useSelector(getProject)
	const user = useGetUser()
	const statuses = useSelector(getStatuses)
	const isAdmin = useSelector(getAdminRole)

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		setError,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const [createStatusTask, result] = useCreateStatusTaskMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
		}
	}, [result])

	useEffect(() => {
		setValue('color', getColor())
	}, [])

	const isValidation = (data) => {
		// const today = moment().format()
		// if (moment(data.complete_at).isBefore(today)) {
		// 	setError('complete_at', { message: 'Нельзя выбрать дату завершения проекта меньше сегодняшней' })
		// 	return true
		// }

		return false
	}

	const createStatusHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))

			const createData = await createStatusTask({
				project_id: project.id,
				status: data.status,
				color: data.color,
				is_final: data?.state === 'is_final' ? true : false,
				is_initial: data?.state === 'is_initial' ? true : false,
				is_request: data?.state === 'is_request' ? true : false,
			})

			dispatch(scrumboardActions.createOneList(createData.data.data))
			dispatch(tasksActions.addStatus(createData.data.data))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const getFinalStatus = () => {
		const isBusy = !!statuses.find((item) => item.is_final === true)
		return isBusy
	}
	const getRequestStatus = () => {
		const isBusy = !!statuses.find((item) => item.is_request === true)
		const isFinalBusy = !!statuses.find((item) => item.is_final === true)
		return isFinalBusy ? isBusy : true
	}
	const getInitialStatus = () => {
		const isBusy = !!statuses.find((item) => item.is_initial === true)
		return isBusy
	}

	const isHideIsRequest = user?.id === project.id

	return (
		<form onSubmit={handleSubmit(createStatusHandler)} className="tt-w-600">
			<DialogHeader title={t('new.status')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>
						<div className="tt-flex tt-gap-12">
							<Controller
								name="status"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										id={getID({
											place: 'modal',
											entity: 'status',
											action: 'create',
											field: 'status_name',
											type: 'text',
										})}
										onChange={onChange}
										error={!!errors.status}
										hiddenLabel
										placeholder={t('title')}
										className="dialig-input tt-w-full"
										size="small"
									/>
								)}
							/>
							<Controller
								name="color"
								control={control}
								render={({ field: { value, onChange } }) => (
									<input
										value={value}
										id={getID({
											place: 'modal',
											entity: 'status',
											action: 'create',
											field: 'status',
											type: 'color',
										})}
										onChange={onChange}
										placeholder={t('color')}
										className="dialig-input"
										size="small"
										type="color"
									/>
								)}
							/>
						</div>

						<ErrorText errors={errors} field="status" />
					</div>

					<div className="flex">
						<Controller
							name="state"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControl>
									<span className="text-14 font-semibold mb-4">{t('state')}</span>
									<RadioGroup
										value={value}
										onChange={onChange}
										aria-labelledby="demo-radio-buttons-group-label"
										name="radio-buttons-group"
										className="tt-flex tt-gap-2 tt-flex-row"
									>
										<FormControlLabel
											disabled={getInitialStatus()}
											value="is_initial"
											control={
												<Radio
													id={getID({
														place: 'modal',
														entity: 'status',
														action: 'create',
														field: 'status_type_is_initial',
														type: 'radio',
													})}
												/>
											}
											label={t('statuses.initial')}
										/>

										{!isHideIsRequest && (
											<FormControlLabel
												disabled={getRequestStatus()}
												value="is_request"
												control={
													<Radio
														id={getID({
															place: 'modal',
															entity: 'status',
															action: 'create',
															field: 'status_type_is_request',
															type: 'radio',
														})}
													/>
												}
												label={t('statuses.request')}
											/>
										)}

										<FormControlLabel
											disabled={getFinalStatus()}
											value="is_final"
											control={
												<Radio
													id={getID({
														place: 'modal',
														entity: 'status',
														action: 'create',
														field: 'status_type_is_final',
														type: 'radio',
													})}
												/>
											}
											label={t('statuses.final')}
										/>
									</RadioGroup>
								</FormControl>
							)}
						/>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} />
		</form>
	)
}

export default CreateStatus
