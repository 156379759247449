import React, { useEffect } from 'react'
import DialogFooter from 'src/old-app/components/dialogs/DialogFooter'
import DialogHeader from 'src/old-app/components/dialogs/DialogHeader'
import DialogMain from 'src/old-app/components/dialogs/DialogMain'
import * as yup from 'yup'
import { InputContainer } from 'src/shared/components'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { ProjectContractCategory } from 'src/@features/project'
import { useCreateProjectContractDirectoryMutation } from 'src/old-app/service/Projects'
import { getID } from 'src/shared/config/fields-ids'
import AddIcon from '@mui/icons-material/Add'
import { uniqueId } from 'lodash'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Button from 'src/shared/components/button'
import { interfaceActions } from '../../../../old-app/store/interfaceSlice'
import { dialogActions } from '../../../../old-app/store/dialogSlice'
import { showMessage } from '../../../../old-app/store/fuse/messageSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface ParametersRegulatoryDocument {
	id: Id
	name: string
	price: number | null
}

interface ProjectContractDirectoryData {
	category: { id: string }
	type_regulatory_document: string
	is_parameters_regulatory_document: boolean
	parameters_regulatory_document: ParametersRegulatoryDocument[]
	pricing: number | null
}

export const ProjectContractDirectoryCreate = () => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		category: yup.object().required(t('category_is_required')).nullable(),
		type_regulatory_document: yup.string().required(t('regulatory_document_type_is_required')),
		parameters_regulatory_document: yup.array().of(
			yup.object().shape({
				name: yup.string().required(t('title_is_required')).nullable(),
				price: yup.string().required(t('price_is_required')).nullable(),
			})
		),
		pricing: yup
			.string()
			.when('is_parameters_regulatory_document', {
				is: true,
				then: yup.string().required(t('price_is_required')).nullable(),
			})
			.nullable(),
	})

	const dispatch = useDispatch()
	const [createProjectContractDirectory] = useCreateProjectContractDirectoryMutation()
	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
	} = useForm<ProjectContractDirectoryData>({
		defaultValues: {
			pricing: null,
			parameters_regulatory_document: [],
			is_parameters_regulatory_document: true,
		},
		resolver: yupResolver(schema),
	})

	const parametersRegulatoryDocument = watch('parameters_regulatory_document')
	const isParameters = parametersRegulatoryDocument?.length !== 0

	useEffect(() => {
		setValue('is_parameters_regulatory_document', !isParameters)
	}, [isParameters])

	const createParameter = () => {
		setValue('parameters_regulatory_document', [
			...parametersRegulatoryDocument,
			{ id: uniqueId(), name: '', price: null },
		])
	}

	const removeParameter = (id: Id) => {
		setValue(
			'parameters_regulatory_document',
			parametersRegulatoryDocument.filter((p) => p.id !== id)
		)
	}

	const createContractDirectoryHandler = async (data: ProjectContractDirectoryData) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			await createProjectContractDirectory({
				category: data.category.id,
				name: data.type_regulatory_document,
				params:
					data?.parameters_regulatory_document?.length === 0
						? undefined
						: data.parameters_regulatory_document.map((parameter) => ({
								name: parameter.name,
								price: parameter.price ? +parameter.price : parameter.price,
						  })),
				price: isParameters ? undefined : data.pricing ? +data.pricing : undefined,
			})

			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.catalog'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		} catch (error) {
			dispatch(
				showMessage({
					message: t('error'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
			dispatch(dialogActions.setDialog(false))
			dispatch(interfaceActions.setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(createContractDirectoryHandler)} className="tt-w-[800px] tt-overflow-x-hidden">
			<DialogHeader back={undefined} backHandler={undefined} title={t('new.catalog')} />
			<DialogMain className={undefined}>
				<div className="tt-grid tt-grid-cols-6 tt-gap-8">
					<InputContainer label={t('regulatory_works_category')} className="tt-col-span-6" error={errors?.category}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectContractCategory
									placeholder={t('regulatory_works_category')}
									value={value}
									onChange={onChange}
								/>
							)}
							name="category"
							control={control}
						/>
					</InputContainer>
					<InputContainer
						label={t('regulatory_document_parameters')}
						className="tt-col-span-6"
						error={errors?.type_regulatory_document}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									onChange={onChange}
									error={!!errors.type_regulatory_document}
									hiddenLabel
									placeholder={t('regulatory_document_parameters')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="type_regulatory_document"
							control={control}
						/>
					</InputContainer>

					<Button
						type="button"
						variant="outlined"
						onClick={createParameter}
						className="tt-col-span-6 tt-flex tt-items-center"
					>
						<AddIcon className="tt-w-20 tt-h-20" />
						{t('add_parameter')}
					</Button>

					<Controller
						render={({ field: { value, onChange } }) => (
							<div className="tt-col-span-6">
								{value.map((v, i) => (
									<div className="tt-flex tt-items-center tt-gap-8 tt-mt-8">
										<InputContainer
											error={
												errors?.parameters_regulatory_document
													? errors?.parameters_regulatory_document[i]?.name
													: undefined
											}
											label={t('regulatory_document_parameters')}
											className="tt-w-full"
										>
											<TextField
												value={v.name}
												onChange={(event) => {
													onChange(
														value.map((vm) => {
															if (vm.id === v.id) {
																vm.name = event.target.value
															}
															return vm
														})
													)
												}}
												error={
													errors?.parameters_regulatory_document
														? !!errors?.parameters_regulatory_document[i]?.name
														: undefined
												}
												hiddenLabel
												placeholder={t('regulatory_document_parameters')}
												className="dialig-input"
												size="small"
											/>
										</InputContainer>
										<InputContainer
											error={
												errors?.parameters_regulatory_document
													? errors?.parameters_regulatory_document[i]?.price
													: undefined
											}
											label={t('price_rub')}
											className="tt-w-full"
										>
											<TextField
												value={Number(v.price).toLocaleString('ru')}
												onChange={(event) => {
													onChange(
														value.map((vm) => {
															if (vm.id === v.id) {
																vm.price = Number(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
															}
															return vm
														})
													)
												}}
												error={
													errors?.parameters_regulatory_document
														? !!errors?.parameters_regulatory_document[i]?.name
														: undefined
												}
												inputProps={{ maxLength: 11 }}
												hiddenLabel
												placeholder={t('price_rub')}
												className="dialig-input"
												size="small"
												InputProps={{
													startAdornment: <InputAdornment position="start">₽</InputAdornment>,
												}}
											/>
										</InputContainer>

										<IconButton className="!tt-h-32  !tt-w-32" onClick={() => removeParameter(v.id)}>
											<DeleteOutlinedIcon />
										</IconButton>
									</div>
								))}
							</div>
						)}
						name="parameters_regulatory_document"
						control={control}
					/>

					{!isParameters && (
						<InputContainer label={t('price_rub')} className="tt-col-span-6" error={errors?.pricing}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={Number(value).toLocaleString('ru')}
										onChange={(event) => {
											let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
											if (enteredValue?.length > 0 && enteredValue[0] === '0') {
												enteredValue = enteredValue.slice(1)
											}
											onChange(enteredValue)
										}}
										inputProps={{ maxLength: 11 }}
										error={!!errors.pricing}
										hiddenLabel
										placeholder={t('price_rub')}
										className="dialig-input"
										size="small"
										InputProps={{
											startAdornment: <InputAdornment position="start">₽</InputAdornment>,
										}}
									/>
								)}
								name="pricing"
								control={control}
							/>
						</InputContainer>
					)}
				</div>
			</DialogMain>
			<DialogFooter rightLabel={undefined} isDisabled={undefined} left={undefined} id={undefined} />
		</form>
	)
}
