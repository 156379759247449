import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL } from '../../shared/config'
import createHeaders from './createHeaders'
import { logout } from '../../@entities/session'
import { checkPublic } from 'src/shared/lib/check-public'

export const baseQuery = async (args, api, extraOptions) => {
	const isPublic = checkPublic()
	const res = fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: createHeaders,
	})

	const result = await res(args, api, extraOptions)

	if (result?.error?.status === 401 && !isPublic) {
		logout()
	}
	return result
}
