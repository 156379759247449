import { createContext } from 'react'
import { Task } from '../model'

// eslint-disable-next-line no-bitwise
const TaskContext = createContext<{ task: Task | null; baseUrl: String | null; page: string }>({
	task: null,
	baseUrl: null,
	page: '',
})
export default TaskContext
