import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import exportKPGHandler from '../../../utils/exportKPGHandler'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { TextField } from '@mui/material'
import ErrorText from '../../ui/ErrorText'
import DialogFooter from '../DialogFooter'
import { useQueryParams } from 'use-query-params'
import { AllMyTasksPageQueryParams } from '../../../main/my/AllMyTasksList'
import { serializeParams } from '../../../utils/serializeRequestParams'
import { getUserID } from 'src/old-app/store/userSlice'
import { getUserName } from '../../../../entities/user'
import { getID } from '../../../../shared/config/fields-ids'

const schema = yup.object().shape({
	start: yup.string().required('Введите начало работы'),
	end: yup.string().required('Введите завершение работы'),
})

const MyAllTasksTimeKPG = () => {
	const dispatch = useDispatch()
	const userID = useSelector(getUserID)
	const userName = useSelector(getUserName)
	// const project = useSelector(getProject)
	// const [isDisabled, setIsDisabled] = useState(false)

	// const [searchParams, setSearchParams] = useSearchParams()
	// const searchTask = searchParams.get('searchTask') ?? undefined
	// const slug = searchParams.get('slug') ?? undefined
	//
	// const deadlineStart = searchParams.get(`deadline_start`) ?? undefined
	// const deadlineEnd = searchParams.get(`deadline_end`) ?? undefined
	// const completedAtStart = searchParams.get(`completed_at_start`) ?? undefined
	// const completedAtEnd = searchParams.get(`completed_at_end`) ?? undefined
	// const createdAtStart = searchParams.get(`date_created_at_end`) ?? undefined
	// const createdAtEnd = searchParams.get(`date_created_at_start`) ?? undefined
	// const assignee = searchParams.get('assignee') ?? []
	// const status = searchParams.get('status') ?? []
	// const author = searchParams.get('author') ?? []
	// const priority = searchParams.get('priority') ?? []
	// const type = searchParams.get('type') ?? []
	//
	// const statusValue = Array.isArray(status) ? status : JSON.parse(status)
	// const assigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	// const priorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	// const authorValue = Array.isArray(author) ? author : JSON.parse(author)
	// const typeValue = Array.isArray(type) ? type : JSON.parse(type)
	//
	// const multipleParamsString = setParamsFilters({
	// 	type: typeValue,
	// 	author: authorValue,
	// 	priority: priorityValue,
	// 	statuses: statusValue,
	// 	assignee: assigneeValue,
	// })

	const [
		{
			searchTask,
			slug,
			created_at_sort,
			deadline_start,
			deadline_end,
			completedAtStart,
			completedAtEnd,
			createdAtStart,
			createdAtEnd,
			status,
			author,
			priority,
			type,
			tags,
			projects,
		},
	] = useQueryParams(AllMyTasksPageQueryParams)

	const {
		handleSubmit,
		control,
		formState: { errors },
		// watch,
		setError,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const exportFileHandler = (path, data) => {
		// const name = `${project?.name}`
		//
		// const params = new URLSearchParams(multipleParamsString)
		//
		// params.append('project_id', project.id)
		//
		// if (searchTask) {
		// 	params.append('title', searchTask)
		// }
		// if (slug) {
		// 	params.append('slug', slug)
		// }
		// if (deadlineStart) {
		// 	params.append('start_date_deadline', moment(deadlineStart).format())
		// }
		// if (deadlineEnd) {
		// 	params.append('end_date_deadline', moment(deadlineEnd).format())
		// }
		// if (completedAtStart) {
		// 	params.append('start_completed_at', moment(completedAtStart).format())
		// }
		// if (completedAtEnd) {
		// 	params.append('end_completed_at', moment(completedAtEnd).format())
		// }
		// if (createdAtStart) {
		// 	params.append('start_date_created_at', moment(createdAtStart).format())
		// }
		// if (createdAtEnd) {
		// 	params.append('end_date_created_at', moment(createdAtEnd).format())
		// }
		// params.set('end_track_date', moment(data.end).format())
		// params.set('start_track_date', moment(data.start).format())
		const params = serializeParams({
			params: {
				project_id: projects,
				created_at_sort,
				slug,
				title: searchTask,
				start_date_deadline: deadline_start,
				end_date_deadline: deadline_end,
				start_completed_at: completedAtStart,
				end_completed_at: completedAtEnd,
				end_date_created_at: createdAtEnd,
				start_date_created_at: createdAtStart,
				assignee_id: userID,
				end_track_date: moment(data.end).format(),
				start_track_date: moment(data.start).format(),
			},
			arraysParams: {
				project_status_id: status,
				parent_id: author,
				task_priority_id: priority,
				type_id: type,
				hashtag_id: tags,
			},
		})

		const url = `/${path}?${params}`
		exportKPGHandler({ url, params: new URLSearchParams(params), name: userName, dispatch })
	}

	const createProjectHandler = async (data) => {
		if (moment(data.stop).isBefore(data.start)) {
			return setError('end', { message: 'Конец не должен быть меньше времени от' })
		}
		exportFileHandler('report/time', data)
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="w-600">
			<DialogHeader title="Выгрузка времени" />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10 ">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-flex-col tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">Начало</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										error={!!errors.start}
										type="datetime-local"
										hiddenLabel
										className="dialig-input w-full"
										id={getID({
											place: 'modal',
											type: 'datetime',
											field: 'kpg',
											action: 'download',
											fieldPostfix: 'start',
										})}
										defaultValue=""
										size="small"
									/>
								)}
								name="start"
								control={control}
							/>
							<ErrorText errors={errors} field="start" />
						</div>
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">Конец</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										error={!!errors.end}
										type="datetime-local"
										hiddenLabel
										className="dialig-input w-full"
										id={getID({
											place: 'modal',
											type: 'datetime',
											field: 'kpg',
											action: 'download',
											fieldPostfix: 'end',
										})}
										defaultValue=""
										size="small"
									/>
								)}
								name="end"
								control={control}
							/>
							<ErrorText errors={errors} field="end" />
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter rightLabel="Выгрузить" />
		</form>
	)
}

export default MyAllTasksTimeKPG
