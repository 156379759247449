import React, { memo, useEffect, useState } from 'react'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetSearchUserQuery, useGetUsersByIdsQuery } from 'src/old-app/service/Users'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'
import { UserShow } from 'src/shared/components'

const UsersSelect = memo(({ value, allProjectsMode, onChange, ...props }) => {
	const [search, setSearch] = useState('')
	const debouncedSearch = useDebounce(search, 300)
	const { data: usersData, isSuccess } = useGetSearchUserQuery({ name: debouncedSearch })

	const usersByIdQuery = useGetUsersByIdsQuery(value.toString(), {
		skip: !allProjectsMode || !value.length,
		refetchOnMountOrArgChange: false,
	})

	useEffect(() => {
		usersByIdQuery.isSuccess && setCheckedUsers(usersByIdQuery.data.data)
	}, [usersByIdQuery.data])

	const [checkedUsers, setCheckedUsers] = useState(props.multiple ? [] : '')

	useEffect(() => {
		if (!allProjectsMode) return

		const allCheckedUsersHere =
			usersByIdQuery.isSuccess && value?.every((_id) => usersByIdQuery.data.data?.some(({ id }) => id === _id))

		if (allCheckedUsersHere) {
			setCheckedUsers(usersByIdQuery.data.data)
		} else {
			value.length && !allCheckedUsersHere && usersByIdQuery.refetch()
		}
	}, [value])

	const getUsers = usersData?.data ?? []
	const users = props.filterUsers ? props.filterUsers(getUsers) : getUsers

	function _onChange(item) {
		setCheckedUsers(item)
		onChange(item)
	}

	return (
		<>
			<MySelect
				menuItemRander={menuItemRander}
				labelRender={usersLabelRender}
				disabled={!isSuccess}
				items={users}
				isSearch
				setSearch={setSearch}
				search={search}
				value={allProjectsMode ? checkedUsers : value}
				onChange={_onChange}
				{...props}
			/>
		</>
	)
})

export default UsersSelect

const usersLabelRender = (value) => `${value?.last_name ?? ''} ${value?.first_name ?? ''} ${value?.middle_name ?? ''}`

const menuItemRander = (value) => <UserShow user={value} />
