import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getProject } from 'src/old-app/store/projectsSlice'
import DialogHeader from '../DialogHeader'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import DialogMain from '../DialogMain'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useUpdateProjectMutation } from '../../../service/Projects'
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { publicBoardUrl } from '../../../../shared/config'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from "@mui/material/Menu";

const ProjectPublic = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { t } = useTranslation()
	const project = useSelector(getProject)
	const dispatch = useDispatch()
	const [isCopy, setIsCopy] = useState(false)
	const projectId = project?.id
	const isPublic = project.is_public
	const url = `${window.location.host}/${publicBoardUrl.replace(':projectId', projectId)}`

	const [updateProject] = useUpdateProjectMutation()

	const setPublicProject = (is_public) => {
		updateProject({ id: projectId, body: { is_public, type_id: project?.type?.id } })
		handleClose()
	}

	const copyUrl = async () => {
		await navigator.clipboard.writeText(url)
		setTimeout(() => setIsCopy(false), 1000)
	}

	const onCloseDialog = () => {
		dispatch(dialogActions.setDialog(false))
	}

	return (
		<div className="tt-w-600">
			<DialogHeader title={t('public_board')} />
			<DialogMain>
				<div>
					<div>
						<span className="tt-font-bold tt-text-18 ">{t('public_access')}</span>
						<div className="tt-flex tt-items-center tt-gap-12 tt-py-12 tt-min-h-[90px]">
							<div
								className={clsx(
									'tt-h-36 tt-w-36 tt-min-w-36 tt-flex tt-items-center tt-justify-center tt-rounded-full',
									isPublic ? 'tt-bg-primary/30' : 'tt-bg-black/20'
								)}
							>
								{isPublic ? (
									<PublicOutlinedIcon className="tt-w-20 tt-h-20" />
								) : (
									<LockOutlinedIcon className="tt-w-20 tt-h-20" />
								)}
							</div>
							<div className="tt-flex tt-flex-col tt-gap-4 tt-h-[7rem] tt-justify-center">
								<div>
									<div>
										<Button
											className="!tt-px-3 !tt-py-0  !tt-max-h-auto !tt-h-20 !tt-min-h-auto tt-duration-150 tt-flex tt-items-center tt-gap-4 hover:tt-bg-gray-100 tt-rounded-md"
											id="basic-button"
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											{isPublic ? t('access_via_link'): t('restricted_access')}
											<ExpandMoreIcon className={["tt-h-20 tt-w-20 tt-duration-300", open ? "tt-rotate-180":""].join(" ")}/>
										</Button>
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem onClick={()=> setPublicProject(false)}>{t('restricted_access')}</MenuItem>
											<MenuItem onClick={()=> setPublicProject(true)}>{t('access_via_link')}</MenuItem>
										</Menu>
										{/*<Select*/}
										{/*	sx={{*/}
										{/*		'&.MuiInputBase-root': {*/}
										{/*			minHeight: 'auto',*/}
										{/*		},*/}
										{/*		'&.MuiInputBase-root .MuiSelect-select': {*/}
										{/*			minHeight: 'auto',*/}
										{/*			height: 'auto',*/}
										{/*			padding: '2px',*/}
										{/*		},*/}
										{/*		'&.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {*/}
										{/*			border: 0,*/}
										{/*		},*/}
										{/*	}}*/}
										{/*	className="tt-p-0 tt-border-none tt-fond-bold"*/}
										{/*	displayEmpty*/}
										{/*	IconComponent={ExpandMoreIcon}*/}
										{/*	value={String(isPublic)}*/}
										{/*	onChange={(event) => setPublicProject(event.target.value)}*/}
										{/*>*/}

										{/*</Select>*/}
									</div>
								</div>
								<p className="tt-text-gray-700 tt-p-2 tt-pr-128">
									{isPublic ? t('available_for_all') : t('available_for_allowed')}
								</p>
							</div>
						</div>
					</div>
					<div className="tt-w-full tt-flex tt-items-center tt-justify-between tt-mt-12">
						<Button
							disabled={!isPublic}
							variant="outlined"
							size="small"
							onClick={() => {
								setIsCopy(true)
								copyUrl()
							}}
						>
							<CopyAllOutlinedIcon className="tt-mr-4" />
							{t('copy_link')}
						</Button>
					</div>
					<Snackbar
						resumeHideDuration={1000}
						key="project-public-copy-url"
						open={isCopy}
						autoHideDuration={1000}
						onClose={() => setIsCopy(false)}
						message={t('link_copied')}
					/>
				</div>
			</DialogMain>
		</div>
	)
}

export default ProjectPublic
