import { InputBase } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetProjectsQuery } from 'src/old-app/service/Projects'
import { useGetSearchUserQuery } from 'src/old-app/service/Users'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'

const labelRender = (value) => value?.name

const ProjectsSelect = memo((props) => {
	const [search, setSearch] = useState('')
	const {
		data: projectsData,
		isLoading,
		refetch,
	} = useGetProjectsQuery(search ? { name: search, confirmation_status: 'APPROVED' } : {})
	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) {
			refetch()
		}
	}, [debouncedSearch])

	if (!projectsData?.data) return null
	const projects = projectsData?.data ?? []

	return (
		<>
			<MySelect
				label="Проект"
				menuItemRander={labelRender}
				labelRender={labelRender}
				items={projects}
				isSearch
				setSearch={setSearch}
				search={search}
				{...props}
			/>
		</>
	)
})

export default ProjectsSelect
