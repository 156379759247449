import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useCheckProjectMutation,
	useCreateFileProjectMutation,
	useCreateProjectHashtagLinkMutation,
	useCreateProjectMutation,
	useCreateUserProjectMutation,
} from 'src/old-app/service/Projects'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import getSlug from 'src/old-app/utils/getSlug'
import createSlug from 'slug'
import { getProject } from 'src/old-app/store/projectsSlice'
import { useCreateTeamProjectMutation } from 'src/old-app/service/Teams'
import { CreateProjectTemplateTasks } from 'src/entities/project'
import { InputContainer, UsersChoice } from '../../../../shared/components'
import CommandsChoice from '../../../../shared/components/commands-choice'
import ProjectTemplatesChoice from '../../../../shared/components/project-templates-choice'
import ProjectTagsChoice from '../../../../shared/components/project-tags-choice'
import ProjectTypes from '../../../../shared/components/project-types'
import { FileInput } from 'src/shared/components/file-input'
import { fileUpload } from '../../../service/File'
import { useTranslation } from 'react-i18next'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

const CreateProjectComponent = ({ isCreateChild, teamId }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		name: yup.string().required(t('project_title_is_required')),
		slug: yup.string().required(t('project_slug_is_required')),
		complete_at: yup.date().required(t('completion_date_is_required')).typeError(t('invalid_date')),

		curator: yup
			.object()
			.when('is_contract', {
				is: true,
				then: yup.object().required(t('dsm_is_required')).nullable(),
			})
			.nullable(),

		confirmation_project_manager: yup.array().when(['is_administration', 'is_contract'], {
			is: (is_administration, is_contract) => !is_contract && is_administration,
			then: yup.array().min(1, t('confirmative_users_are_required')),
		}),

		confirmation_ba: yup.array().when(['is_administration', 'is_contract'], {
			is: (is_administration, is_contract) => !is_contract && is_administration,
			then: yup.array().min(1, t('confirmative_users_are_required')),
		}),
		// type: yup.object().required('Выберите тип проекта').nullable(),
	})

	const dispatch = useDispatch()
	const curProject = useSelector(getProject)

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		watch,
	} = useForm({
		defaultValues: {
			users: [],
			hashtags: [],
			teams: [],
			team: null,

			tasks: [],
			confirmation_project_manager: [],
			confirmation_ba: [],
			slug: '',
			template: null,
			type: null,
			assignees: [],

			each_member_can_create_task: false,
			is_administration: false,

			curator: null,
			motivation: null,

			sanction_holiday: null,
			sanction_holiday_type: 'ruble',

			guaranteed_part: null,
			guaranteed_part_type: 'percent',

			sanction_per_day: 240,
			is_contract: false,

			contract_file: [],
			кpg_file: [],
		},
		resolver: yupResolver(schema),
	})

	const [createProject, result] = useCreateProjectMutation()
	const [createFileProjectMutation] = useCreateFileProjectMutation()
	const [checkProject] = useCheckProjectMutation()
	const [createTeamProject] = useCreateTeamProjectMutation()
	const [createProjectHashtagLink] = useCreateProjectHashtagLinkMutation()
	const [createUserrProject, userResult] = useCreateUserProjectMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.project'),

					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}
		if (userResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: userResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [result, userResult])

	const isValidation = (data) => {
		// const today = moment().format()
		// if (moment(data.complete_at).isBefore(today)) {
		// 	setError('complete_at', { message: 'Нельзя выбрать дату завершения проекта меньше сегодняшней' })
		// 	return true
		// }

		return false
	}

	const type = watch('type')
	const motivation = watch('motivation')
	const isContract = type?.name === 'contract'
	const isCBD = type?.name === 'cbd_project'
	const template = watch('template')
	const users = watch('users')
	const isTemplate = !!template?.id
	const isAdministration = watch('is_administration')

	useEffect(() => {
		setValue('is_contract', isContract)
	}, [isContract])

	useEffect(() => {
		if (isContract || isCBD) {
			setValue('is_administration', true)
		}
	}, [isContract, isCBD])

	useEffect(() => {
		if (isTemplate) {
			const tasks = template?.template?.Tasks ?? []

			const taskAsignees = tasks
				.map((item) => item?.assignee_id)
				.reduce((acc, item) => {
					if (acc.includes(item)) {
						return acc
					}
					return [...acc, item]
				}, [])

			const assignees = taskAsignees.map((assigneeId) => ({
				id: assigneeId,
				oldAssignee: template?.template?.Users?.find((u) => u?.user_id === assigneeId)?.user,
				newAssignee: template?.template?.Users?.find((u) => u?.user_id === assigneeId)?.user,
			}))

			setValue('name', template?.name)
			setValue('tasks', tasks)
			setValue('assignees', assignees)
			setValue('type', template?.template?.Type)
			setValue('team', template?.template?.Commands?.map((command) => command?.command)[0])
			// setValue('has_assimilation', template?.template?.HasAssimilation ?? false)
			// setValue('has_contract', template?.template?.HasContract ?? false)
			// setValue('has_development_plan', template?.template?.HasDevelopment_plan ?? false)
			setValue(
				'users',
				template?.template?.Users?.map((item) => item.user)
			)
			// setValue(
			// 	'teams',
			// 	template?.template?.Commands?.map((item) => item.command)
			// )
			setValue('each_member_can_create_task', template?.template?.EachMemberCanCreateTask ?? false)
		}
	}, [isTemplate, template])

	const createProjectHandler = async (data) => {
		try {
			const isValidate = isValidation(data)
			if (isValidate) return

			dispatch(interfaceActions.setIsLoading(true))
			setIsDisabled(true)

			const confirmationProjectManagerIds =
				data.confirmation_project_manager.length === 0 ? undefined : data.confirmation_project_manager.map((i) => i.id)
			const confirmationBaIds = data.confirmation_ba.length === 0 ? undefined : data.confirmation_ba.map((i) => i.id)

			let body = {
				curator_id: isContract ? data?.curator?.id : undefined,
				guaranteed_part: isContract ? +data.guaranteed_part : undefined,
				motivation: isContract ? +data.motivation : undefined,
				// sanction_holiday: isContract ? +data.sanction_holiday : undefined,
				sanction_per_day: isContract ? +data.sanction_per_day : undefined,

				name: data.name,
				command_id: teamId ? teamId : data?.team?.id ?? undefined,
				slug: data.slug,
				type_id: data?.type?.id,
				complete_at: dayjs(data?.complete_at).format(),
				parent_project_id: isCreateChild ? curProject.id : undefined,
				each_member_can_create_task: isContract ? false : data?.each_member_can_create_task,
				template_id: data?.template?.id,
				confirmation_ba: data?.is_administration ? confirmationBaIds : undefined,
				confirmation_project_manager: data?.is_administration ? confirmationProjectManagerIds : undefined,
				new_task_deadline: {},
				new_task_assignee: {},
				user_ids: data?.users?.map((item) => item?.id) ?? undefined,
			}

			if (isTemplate) {
				let new_task_assignee = data.assignees.reduce((acc, object) => {
					const field = object?.oldAssignee?.id
					acc[field] = object?.newAssignee?.id

					return acc
				}, {})

				let new_task_deadline = data?.tasks
					.filter((t) => t?.actual_deadline?.deadline)
					.reduce((acc, object) => {
						if (!object?.actual_deadline?.deadline) return acc
						const field = object?.id
						acc[field] = dayjs(object?.actual_deadline?.deadline).startOf('date').format()

						return acc
					}, {})

				body['new_task_assignee'] = JSON.parse(JSON.stringify(new_task_assignee ?? {}))
				body['new_task_deadline'] = JSON.parse(JSON.stringify(new_task_deadline ?? {}))
			}

			const checkProjectData = await checkProject({ slug: body.slug })

			if (checkProjectData.data.data) {
				dispatch(
					showMessage({
						message: t('project_code_engaged'),
						autoHideDuration: 2000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'warning',
					})
				)
				dispatch(interfaceActions.setIsLoading(false))
				setIsDisabled(false)
				return
			}

			const projectData = await createProject(body)
			const project = projectData?.data?.data ?? projectData?.data

			if (isContract) {
				const kpgFiles = data?.кpg_file ?? []
				const contractFiles = data?.contract_file ?? []

				await Promise.all([
					await contractFiles.map(async (file) => {
						const contractFile = await fileUpload(file?.data, 'file/', false, 'doc')
						const contractFileId = contractFile?.data?.id

						if (contractFileId) {
							await createFileProjectMutation({ file_id: contractFileId, project_id: project?.id, type: 'doc' })
						}
					}),
					await kpgFiles.map(async (file) => {
						const кpgFile = await fileUpload(file?.data, 'file/', false, 'doc')
						const кpgFileId = кpgFile?.data?.id

						if (кpgFileId) {
							await createFileProjectMutation({ file_id: кpgFileId, project_id: project?.id, type: 'kpg' })
						}
					}),
				])
			}

			await data.hashtags.map(async (hashtag) => {
				await createProjectHashtagLink({ id: project.id, hashtagId: hashtag.id })
			})

			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.project'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const titleChangeHandler = (event, onChange) => {
		const slug = getSlug(event.target.value)
		setValue('slug', createSlug(slug))
		onChange(event)
	}

	const slugChangeHandler = (event, onChange) => {
		const { value } = event.target
		onChange(createSlug(value))
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-[800px] tt-overflow-x-hidden">
			<DialogHeader title={t('new.project')} />
			<DialogMain>
				<div className="tt-grid tt-grid-cols-6 tt-gap-8">
					<InputContainer label={t('project_slug')} className="tt-col-span-1" error={errors?.slug}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									placeholder={t('project_slug')}
									onChange={(event) => slugChangeHandler(event, onChange)}
									error={!!errors.slug}
									hiddenLabel
									className="dialig-input"
									size="small"
								/>
							)}
							name="slug"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('title')} className="tt-col-span-5" error={errors?.name}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									onChange={(event) => titleChangeHandler(event, onChange)}
									error={!!errors.name}
									hiddenLabel
									placeholder={t('title')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="name"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('participants')} className="tt-col-span-3" error={errors?.users}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<UsersChoice placeholder={t('participants')} value={value} onChange={onChange} />
							)}
							name="users"
							control={control}
						/>
					</InputContainer>

					{!teamId && (
						<InputContainer label={t('team')} className="tt-col-span-3" error={errors?.team}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<CommandsChoice placeholder={t('team')} multiple={false} value={value} onChange={onChange} />
								)}
								name="team"
								control={control}
							/>
						</InputContainer>
					)}

					<InputContainer label={t('template')} className="tt-col-span-3" error={errors?.template}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectTemplatesChoice placeholder={t('template')} onChange={onChange} value={value} />
							)}
							name="template"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('project_type')} className="tt-col-span-3" error={errors?.type}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectTypes placeholder={t('project_type')} value={value} onChange={onChange} />
							)}
							name="type"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('planned_completion_date')} className="tt-col-span-3" error={errors?.complete_at}>
						<Controller
							render={({ field: { value, onChange } }) => <DatePickerInput value={value} onChange={onChange} />}
							name="complete_at"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('tags')} className="tt-col-span-3" error={errors?.hashtags}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectTagsChoice onChange={onChange} value={value} placeholder={t('tags')} />
							)}
							name="hashtags"
							control={control}
						/>
					</InputContainer>

					{isContract && (
						<div className="tt-col-span-6 tt-bg-gray-100/50 tt-border tt-border-gray-200/50 tt-p-12 tt-rounded-lg">
							<span className="tt-font-medium tt-text-gray-500">{t('project_type_contract')}</span>
							<div className="tt-grid tt-grid-cols-6 tt-gap-8 tt-mt-12">
								<InputContainer label={t('dsm')} className="tt-col-span-3" error={errors?.curator}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<UsersChoice placeholder={t('dsm')} multiple={false} value={value} onChange={onChange} />
										)}
										name="curator"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('dsm_motivation')} className="tt-col-span-3" error={errors?.motivation}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<TextField
												value={Number(value).toLocaleString('ru')}
												onChange={(event) => {
													let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
													if (enteredValue?.length > 0 && enteredValue[0] === '0') {
														enteredValue = enteredValue.slice(1)
													}
													onChange(enteredValue)
												}}
												inputProps={{ maxLength: 11 }}
												error={!!errors.motivation}
												hiddenLabel
												placeholder={t('rate')}
												className="dialig-input"
												size="small"
												InputProps={{
													startAdornment: <InputAdornment position="start">₽</InputAdornment>,
												}}
											/>
										)}
										name="motivation"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('contract_file')} className="tt-col-span-3" error={errors?.contract_file}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<FileInput multiple id="files_contract_file" onChange={onChange} value={value} />
										)}
										name="contract_file"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('cpg_file')} className="tt-col-span-3" error={errors?.кpg_file}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<FileInput multiple id="files_cpg_file" onChange={onChange} value={value} />
										)}
										name="кpg_file"
										control={control}
									/>
								</InputContainer>

								<InputContainer label={t('guaranteed_part')} className="tt-col-span-6" error={errors?.guaranteed_part}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<div>
												<TextField
													value={value}
													error={!!errors?.guaranteed_part}
													hiddenLabel
													placeholder={t('percent')}
													onChange={(event) => {
														if (+event.target.value > 100) return
														onChange(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
													}}
													inputProps={{ maxLength: 3 }}
													className="dialig-input tt-w-full"
													size="small"
													InputProps={{
														startAdornment: <InputAdornment position="start">%</InputAdornment>,
													}}
												/>
												{motivation && value && (
													<span className="tt-mt-4">
														{t('received')} {(+motivation * +value) / 100} ₽
													</span>
												)}
											</div>
										)}
										name="guaranteed_part"
										control={control}
									/>
								</InputContainer>
								<InputContainer
									label={t('sanction_per_day')}
									className="tt-col-span-6"
									error={errors?.sanction_per_day}
								>
									<Controller
										render={({ field: { value, onChange } }) => (
											<div className="tt-flex tt-gap-8 tt-w-full">
												<TextField
													value={value}
													onChange={(event) => {
														onChange(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
													}}
													disabled
													inputProps={{ maxLength: 9 }}
													error={!!errors?.sanction_per_day}
													hiddenLabel
													placeholder={t('sanction_value')}
													className="dialig-input tt-w-full"
													size="small"
													InputProps={{
														startAdornment: <InputAdornment position="start">₽</InputAdornment>,
													}}
												/>
											</div>
										)}
										name="sanction_per_day"
										control={control}
									/>
								</InputContainer>
								{/* <InputContainer
									label={t('non_sunction_period')}
									className="tt-col-span-6"
									error={errors?.sanction_holiday}
								>
									<Controller
										render={({ field: { value, onChange } }) => (
											<TextField
												value={value}
												onChange={(event) =>
													onChange(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
												}
												inputProps={{ maxLength: 9 }}
												error={!!errors.sanction_holiday}
												hiddenLabel
												placeholder={t('amount_of_days')}
												className="dialig-input"
												size="small"
											/>
										)}
										name="sanction_holiday"
										control={control}
									/>
								</InputContainer> */}
							</div>
						</div>
					)}
					{!isContract && (
						<Controller
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									className="tt-col-span-3 tt-z-0"
									id="each_member_can_create_task"
									control={<Checkbox checked={value} onChange={onChange} />}
									label={t('eack_participant_can_create_task')}
								/>
							)}
							name="each_member_can_create_task"
							control={control}
						/>
					)}
					<Controller
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								className="tt-col-span-3 tt-z-0"
								id="is_administration"
								disabled={isContract || isCBD}
								control={<Checkbox checked={value} onChange={onChange} />}
								label={t('administrated_project')}
							/>
						)}
						name="is_administration"
						control={control}
					/>
					{!isContract && isAdministration && (
						<div className="tt-col-span-6 tt-bg-gray-100/50 tt-border tt-border-gray-200/50 tt-p-12 tt-rounded-lg">
							<span className="tt-font-medium tt-text-gray-500">{t('administrated_project')}</span>
							<div className="tt-grid tt-grid-cols-6 tt-gap-8 tt-mt-12">
								<InputContainer
									label={t('roles.business_administrator_full')}
									className="tt-col-span-3"
									error={errors?.confirmation_ba}
								>
									<Controller
										render={({ field: { value, onChange } }) => (
											<UsersChoice
												placeholder={t('roles.business_administrator_full')}
												value={value}
												onChange={onChange}
											/>
										)}
										name="confirmation_ba"
										control={control}
									/>
								</InputContainer>
								<InputContainer
									label={t('roles.project_manager')}
									className="tt-col-span-3"
									error={errors?.confirmation_project_manager}
								>
									<Controller
										render={({ field: { value, onChange } }) => (
											<UsersChoice placeholder={t('roles.project_manager')} value={value} onChange={onChange} />
										)}
										name="confirmation_project_manager"
										control={control}
									/>
								</InputContainer>
							</div>
						</div>
					)}
					{isTemplate && <CreateProjectTemplateTasks users={users} control={control} template={template} />}
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} />
		</form>
	)
}

export default CreateProjectComponent
