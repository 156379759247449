import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import axios from 'axios'
import { BASE_URL, TOKEN } from 'src/shared/config'
import createHeaders from './createHeaders'
import { getToken } from 'src/@entities/session'

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: (headers) => {
		const token = localStorage.getItem(TOKEN)
		if (token) {
			headers.append('Authorization', `Bearer ${token?.replace(/"/g, '')}`)
		}
		headers.append('accept', 'application/json')
		//headers.append('Access-Control-Allow-Origin', '*');
		headers.append('Content-Type', 'multipart/form-data')

		return headers
	},
})

export const fileAPI = createApi({
	reducerPath: 'file-api',
	// tagTypes: ['File'],

	baseQuery,
	endpoints: (build) => ({
		createFile: build.mutation({
			query: (file) => {
				const formdata = new FormData()
				formdata.append('file', file)
				return {
					method: 'POST',
					url: '/file/',
					body: formdata,
				}
			},
			// invalidatesTags: ['File'],
			// providesTags: (result) => ['File'],
		}),
		getFiles: build.query({
			query: (id) => ({
				url: `/file/${id}`,
			}),
		}),
	}),
})

export const fileUpload = (file, url, toKPG, file_type) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	//headers.append('Access-Control-Allow-Origin', '*');
	headers.append('accept', 'application/json')

	const formdata = new FormData()
	formdata.append('file', file)
	formdata.append('file_type', file_type)

	const requestOptions = {
		method: 'POST',
		headers,
		body: formdata,
	}

	//запрос на отправку КПГ
	if (toKPG) {
		return fetch(`${BASE_URL}/${url ?? 'file/'}`, requestOptions)
	}

	//любой другой
	return fetch(`${BASE_URL}/${url ?? 'file/'}`, requestOptions).then((res) => res.json())
}

export const authorUpload = ({ file, userID }) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const formdata = new FormData()
	formdata.append('file', file)

	const requestOptions = {
		method: 'POST',
		headers,
		body: formdata,
	}

	return fetch(`${BASE_URL}/avatar/user/${userID}/avatar/`, requestOptions)
}

export const exportFile = async ({ url, params,baseUrl }) => {
	const token = getToken()
	const getUrl = `${baseUrl ?? BASE_URL}${url}`
	const type = 'xlsx'

	try {
		const res = await axios.get(getUrl, {
			responseType: 'arraybuffer',
			headers: {
				accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
			params,
		})
		return res
	} catch (error) {
		console.log(error)
	}
}

export const getOneFile = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/file/${id}`, requestOptions).then((res) => res.json())
}
export const { useCreateFileMutation, useGetFilesQuery } = fileAPI
