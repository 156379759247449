/* eslint-disable no-unneeded-ternary */
import React from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import { useSelector } from 'react-redux'
import { getUser, getUserRole } from 'src/old-app/store/userSlice'
import TeamsContent from './TeamsContent'
import TeamSideBarCheck from 'src/old-app/components/team/TeamSideBarCheck'
import { getID } from '../../../shared/config/fields-ids'
import { TeamsTable } from '../../../entities/team/components'
import { useTranslation } from 'react-i18next'

function Teams() {
	const { t } = useTranslation()
	const { projectId } = useParams()
	const { search } = useLocation()

	const user = useSelector(getUser)

	const backTo = '/team'

	return (
		<PageWithSideBar
			id={getID({ entity: 'teams' })}
			param="teamId"
			pageLink="teams"
			// pageLink={`teams/list/${projectId}${search}`}
			headerCreateComponent="create-team"
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			headerTitle={t('teams')}
			buttonLabel={t('create_new.team')}
			content={<TeamsTable />}
			rightSidebarContent={<TeamSideBarCheck backTo={backTo} />}
		/>
	)
}

export default Teams
