import { useGetProjectsQuery } from '../../../old-app/service/Projects'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { AllMyTasksPageQueryParams, customArrayParam, dateParam } from '../../../old-app/main/my/AllMyTasksList'
import { useGetProtocolsQuery } from '../../../old-app/service/Protocol'

const Params: any = {
	topic: StringParam,
	meeting_date_start: dateParam,
	meeting_date_end: dateParam,
	author: customArrayParam,
	order_sort: withDefault(StringParam, 'desc'),
	tags: customArrayParam,
}

const useProtocols = ({ params }: { params: any }) => {
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const {
		data: protocolsData,
		isLoading,
		isFetching,
	} = useGetProtocolsQuery({
		topic: queryParams.topic,
		created_at_sort: queryParams.order_sort,
		start_meeting_date_start: queryParams.meeting_date_start,
		end_meeting_date_start: queryParams.meeting_date_end,
		creator_ids: queryParams?.author?.join(','),
		hashtag_ids: queryParams?.tags?.join(','),
		...params,
	})

	const protocols = protocolsData?.data ?? []
	const meta = protocolsData?.meta ?? {}

	return { protocols, isLoading, meta, isFetching }
}
export default useProtocols
