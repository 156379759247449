import React from 'react'
import styles from './styles.module.scss'
import { useGetTask } from '../../../../entities/task'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectUserRole } from '../../../../old-app/store/projectsSlice'
import { dialogActions } from '../../../../old-app/store/dialogSlice'
import { Tooltip } from '@mui/material'
import { getID } from '../../../../shared/config/fields-ids'
import moment from 'moment/moment'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import SwipeLeftOutlinedIcon from '@mui/icons-material/SwipeLeftOutlined'
import { PathnameCell } from '../../../../shared/components'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import { useGetUser } from 'src/shared/hooks'
import { useProjectShow } from 'src/entities/project'

interface IProps {
	isShowStatus?: boolean
	task: any
	isShowStatusInText?: boolean
	placeholder?: string
	isEditDeadline?: boolean
}

const TaskDeadlineInfo: React.FC<IProps> = ({
	isEditDeadline = false,
	isShowStatus,
	isShowStatusInText,
	task,
	placeholder = 'Дедлайн не установлен',
}) => {
	const { t } = useTranslation()
	const currentUser = useGetUser()
	const projectShow = useProjectShow()

	const userIsTaskOwner = currentUser?.id === task?.assignee_id
	const isCommittee = projectShow?.isCommittee

	const statuses: any = {
		ON_CONFIRMATION: {
			label: t('statuses.on_confirmation'),
			className: 'tt-bg-yellow-600/10 tt-text-yellow-700 tt-border tt-border-yellow-600/20',
			textClassName: 'tt-text-yellow-700',
		},
		APPROVED: {
			label: t('statuses.approved'),
			className: 'tt-bg-green-600/10 tt-text-green-600',
		},
		REJECTED: {
			label: t('statuses.rejected'),
			className: 'tt-bg-red-600/10 tt-text-red-600',
		},
		CANCELLED: {
			label: t('statuses.cancaled'),
			className: 'tt-bg-blue-600/10 tt-text-blue-600',
		},
	}
	const deadlines = task?.deadlines ?? []
	const lastDeadline = deadlines[0]
	const deadline =
		lastDeadline?.confirmation_status === 'CANCELLED' || lastDeadline?.confirmation_status === 'REJECTED'
			? task?.deadline
			: lastDeadline
	const isUser = useSelector(getProjectUserRole)
	const isOverdue = task?.is_overdue

	const dispatch = useDispatch()

	const canEditDeadline = isUser ? task?.isMyTask : true

	const openDialog = () => {
		if (!canEditDeadline) return
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('edit-deadline'))
	}

	const status = statuses[deadline?.confirmation_status]

	return (
		<CommentedDisability
			optionDisabled={isEditDeadline && isCommittee && canEditDeadline && !userIsTaskOwner}
			disabilityComment={t('not_available_for_project_type')}
		>
			<Tooltip arrow title={isOverdue ? t('task_overdue') : false} placement="top-start">
				<div
					onClick={isEditDeadline ? openDialog : () => null}
					id={
						isEditDeadline ? getID({ prefix: '', type: 'button_open', field: 'deadline', entity: 'task' }) : undefined
					}
					className={[
						'tt-flex tt-gap-5 tt-items-center  tt-rounded-2xl  ',
						deadline ? '' : 'tt-text-gray-500',
						isOverdue && 'tt-text-red-400',
						isEditDeadline ? 'tt-cursor-pointer' : 'tt-cursor-default',
					].join(' ')}
				>
					{deadline ? (
						<div
							className={[
								'tt-flex tt-items-center tt-gap-10',
								isShowStatusInText && !isShowStatus && status?.textClassName,
							].join(' ')}
						>
							{moment(deadline?.deadline).format('LL')}
							{status && isShowStatus && (
								<div
									className={[
										'tt-text-center tt-inline-flex  tt-text-12 tt-font-medium tt-rounded-full tt-px-8 tt-py-2  tt-truncate',
										status?.className,
									].join(' ')}
								>
									{status?.label}
								</div>
							)}
						</div>
					) : (
						//placeholder
						t('deadline_not_set')
					)}
				</div>
			</Tooltip>
		</CommentedDisability>
	)

	// return <div className={styles['task-deadline-info']}>task-deadline-info</div>
}

export default TaskDeadlineInfo
