import React from 'react'
import { Button, Divider } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ChangeStatusButton from './ChangeStatusButton'
import AddFileButton from './AddFileButton'
import ChangeTaskPriority from './ChangeTaskPriority'
import { useSelector } from 'react-redux'
import { getUserRole } from 'src/old-app/store/userSlice'
import { getStatuses, getTask } from 'src/old-app/store/tasksSlice'
import getUser from 'src/old-app/utils/getUser'
import AddCompleteFileButton from './AddCompleteFileButton'
import TimeButton from './TimeButton'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { getID } from '../../../shared/config/fields-ids'
import TaskAcceptButton from '../../../entities/task/components/task-accept-button'
import { useTranslation } from 'react-i18next'
import { useProjectShow } from 'src/entities/project'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const ActionButtons = ({ setIsEdit, filesRefetch, id }) => {
	const { t } = useTranslation()
	// const navigate = useNavigate()
	// const dispatch = useDispatch()
	const task = useSelector(getTask)
	const projectShow = useProjectShow()
	const isUser = useSelector(getProjectUserRole)
	const statuses = useSelector(getStatuses)
	const statusId = task?.task_status?.project_status_id
	const isFinal = statuses?.find((item) => item?.id === statusId)?.is_final

	const isCommittee = projectShow?.isCommittee

	const goEdit = () => {
		setIsEdit(true)
	}

	// const openAddParams = () => {
	// 	dispatch(dialogActions.setDialog(true))
	// 	dispatch(dialogActions.setComponent('add-param'))
	// }

	const isMy = task?.isMyTask
	// console.log('isMy', isMy)
	const isEdit = isMy
	const isAddFiles = isMy
	const isTimeButton = isMy
	const isReportCompleteFile = isFinal ? false : task?.need_report ? isMy : false

	return (
		<>
			<div className="tt-flex tt-items-center tt-gap-16 tt-mb-12 tt-flex-wrap">
				{isEdit && !task?.project?.isEnd && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<Button
							id={getID({ prefix: id, type: 'button_edit' })}
							onClick={goEdit}
							disabled={task?.project?.isContract ? !task?.project?.isManageProject : false}
							startIcon={
								<svg
									className="tt-stroke-gray-700"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
										strokeWidth="2"
										stroke="#94A3B8"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							}
							className="tt-border-gray-200  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
							variant="outlined"
						>
							{t('change')}
						</Button>
					</CommentedDisability>
				)}

				{isAddFiles && !task?.project?.isEnd && <AddFileButton id={id} filesRefetch={filesRefetch} />}
				{isReportCompleteFile && !task?.project?.isEnd && <AddCompleteFileButton id={id} />}
				{isTimeButton && !task?.project?.isEnd && <TimeButton id={id} />}
			</div>
		</>
	)
}

export default ActionButtons
