import { TableCell } from '@mui/material'
import React, { FC, memo } from 'react'
import clsx from 'clsx'

type Props = {
	className?: string,
	label: any,
	onClick: () => any,
	sort?: 'desc' | 'asc' | boolean | string,
}

const HeadCell: FC<Props> = memo(({ className, label, onClick, sort }) => {
	return (
		<TableCell
			onClick={onClick}
			className={clsx('tt-px-16 tt-py-8  tt-text-gray-700 tt-font-semibold tt-border-y  tt-border-gray-200', className)}
		>
			<span className="tt-flex tt-items-center tt-gap-4">
				{label}
				<div className="tt-w-14">
					{sort && (
						<svg
							className={clsx(
								'tt-w-14  tt-fill-gray-600 tt-stroke-gray-600  tt-ml-5',
								sort === 'desc' ? 'tt-rotate-0' : 'tt-rotate-180'
							)}
							viewBox="0 0 24 24"
						>
							<path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
						</svg>
					)}
				</div>
			</span>
		</TableCell>
	)
})

HeadCell.displayName = 'HeadCell'
export default HeadCell
