import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'

interface IProps {
	children: ReactNode
}

const PathnameCell: React.FC<IProps> = ({ children }) => {
	const { pathname, search } = useLocation()
	return <Link to={pathname + search}>{children}</Link>
}

export default PathnameCell
