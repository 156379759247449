import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn: 'https://ee7dc7a9ebba60d4174a0f7ca98b826f@sentry.sddt.efko.ru/21',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

	denyUrls: ['localhost'],

	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
