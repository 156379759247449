import { getStatuses } from 'src/old-app/store/tasksSlice'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'
import { UserShow } from 'src/shared/components'

const menuItemRander = (value) => <UserShow user={value} />

const ProjectStatusSelect = memo(({ id, ...props }) => {
	const statuses = useSelector(getStatuses)
	const usersLabelRender = (value) => value?.status

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'status', type: 'select' })}
				menuItemRander={usersLabelRender}
				labelRender={usersLabelRender}
				items={statuses}
				{...props}
			/>
		</>
	)
})

export default ProjectStatusSelect
