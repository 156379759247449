import React from 'react'
import { MenuItem, Select } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getRequestStatus, getStatuses, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useApprovedTaskMutation, useUpdateTaskMutation } from 'src/old-app/service/Tasks'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getListsCards, scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { requestsActions } from 'src/old-app/store/requestSlice'
import { requestTypes } from 'src/old-app/constants/requests'
import { useLocation } from 'react-router-dom'
import { myActions } from 'src/old-app/store/mySlice'
import { getProjectAdminContractRole, getProjectCuratorRole, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import { getAdminProjectContractRole } from 'src/old-app/store/userSlice'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import { useProjectShow } from 'src/entities/project'

const ChangeStatusButton = ({ id, isContract }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const project = task?.project
	const projectShow = useProjectShow()
	const user = useGetUser()
	const isUser = useSelector(getProjectUserRole)
	const isAdminProjectContract = useSelector(getAdminProjectContractRole) || useSelector(getProjectAdminContractRole)
	const isCurator = useSelector(getProjectCuratorRole)
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const requestStatus = useSelector(getRequestStatus)
	const status = task?.task_status?.project_status
	const getCurStatuses = useSelector(getStatuses)
	const statuses = getCurStatuses.length === 0 ? [status] : getCurStatuses
	const statusId = task?.task_status?.project_status_id

	const isCommittee = projectShow?.isCommittee
	const userIsTaskOwner = user?.id === task?.assignee_id

	const [updateTask] = useUpdateTaskMutation()
	const [approvedTask] = useApprovedTaskMutation()

	const onChangeHandler = async (event, value) => {
		const newStatusId = value?.props?.value

		const newStatus = statuses.find((item) => item.id === newStatusId)
		const isRequestStatus = statuses?.find((item) => item?.id === statusId)?.is_request
		const isFinalStatus = statuses.find((item) => item.id === newStatusId)?.is_final

		if (
			newStatus?.is_request &&
			task?.contract_type &&
			task?.contract_type?.contract_type?.params?.length !== 0 &&
			isContract
		) {
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('task-finish'))
			return
		}

		if (requestStatus?.id === newStatusId && task?.need_report) {
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('task-finish'))
			return
		}

		if (isFinalStatus && project?.with_administration) {
			// dispatch(dialogActions.setDialog(true))
			// dispatch(dialogActions.setComponent('task-finish'))
			await approvedTask({
				task_id: task?.id,
				project_status_id: task?.task_status?.project_status_id,
			})
			dispatch(
				showMessage({
					message: t('task_confirmed'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}

		if (isRequestStatus && !isFinalStatus && project?.with_administration && task?.need_report) {
			// dispatch(dialogActions.setDialog(true))
			// dispatch(dialogActions.setComponent('task-finish'))
			dispatch(
				requestsActions.getRequest({
					type: requestTypes.TASK,
					backRoute: pathname,
					data: { whereStatusId: newStatusId, project_status_id: statusId, task_id: task.id },
				})
			)
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('cancel-request'))
			return
		}

		const data = {
			body: { project_status_id: newStatusId, task_priority_id: task?.priority?.id },
			id: task?.id,
		}
		await updateTask(data)

		dispatch(
			showMessage({
				message: t('status_is_updated'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
	}

	if (!task) return null

	const isFinal = statuses?.find((item) => item?.id === statusId)?.is_final
	const isRequest = statuses?.find((item) => item?.id === statusId)?.is_request
	const isProjectAdmin = project?.with_administration
	const isMyTask = !isUser || task?.assignee?.id === user?.id
	const isTaskDisabled = isRequest ? true : isFinal ? !!isUser : !isMyTask

	const disabled = task?.project?.isEnd
		? true
		: isContract
		? isAdminProjectContract | isCurator | isMyTask
			? false
			: true
		: isProjectAdmin
		? isTaskDisabled
		: !isMyTask
	const isEpic = task?.subtasks

	const isMy = user?.id === task?.project_id
	// const isFinalDisabled = isRequest ? false : true
	// const isRequestDisabled = !task?.subtask_completed

	const itemDislabled = (item) => {
		if (isRequest && project?.with_administration) {
			return true
		}
		if (isEpic && !task?.subtask_completed && (item?.is_final || item?.is_request)) {
			return true
		}
		if (item?.is_final && project?.with_administration) {
			return true
		}
		return false
	}

	return (
		<CommentedDisability
			optionDisabled={isCommittee && !userIsTaskOwner && !disabled}
			disabilityComment={t('not_available_for_project_type')}
		>
			<Select
				id={getID({ prefix: id, type: 'select', field: 'status', entity: 'task' })}
				disabled={disabled}
				onChange={onChangeHandler}
				sx={{ backgroundColor: statuses.find((item) => item.id === statusId)?.color ?? 'gray' }}
				IconComponent={KeyboardArrowDownIcon}
				className="task-card-select tt-w-160 tt-text-white tt-font-medium "
				value={statusId ?? 'null'}
			>
				{statuses.map((item, index) => (
					<MenuItem
						id={getID({ prefix: id, type: 'select_item', field: 'status', entity: 'task', index })}
						disabled={itemDislabled(item)}
						key={item.id}
						value={item.id}
					>
						{item?.status}
					</MenuItem>
				))}
				{!statusId && (
					<MenuItem
						id={getID({ prefix: id, type: 'select_item', field: 'status', entity: 'task', action: 'clear' })}
						disabled
						value="null"
					>
						{t('status_not_selected')}
					</MenuItem>
				)}
				{statuses.length === 0 && (
					<div className="tt-text-14 tt-text-gray-500 tt-text-center tt-w-full">{t('not_found')}</div>
				)}
			</Select>
		</CommentedDisability>
	)
}

export default ChangeStatusButton
