import { store } from 'src/app/store'
import React from 'react'
import { Provider } from 'react-redux'

interface IProps {
	children: React.ReactNode
}

const StoreProvider: React.FC<IProps> = (p) => {
	const { children } = p

	return <Provider store={store}>{children}</Provider>
}

export default StoreProvider
