import styles from './ProjectTemplateTasksList.module.css'
import ProjectTemplateTasksListItem from './ProjectTemplateTasksListItem'
import EmptyProjectTemplateTasksList from './EmptyProjectTemplateTasksList'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ProjectTemplateTasksList = ({ isCanEdit, tasksList, baseUrl, removeTaskHandler }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onCreate = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('task-create-template-epic'))
	}
	const onChoice = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('project-template-task-epics'))
	}

	return (
		<>
			<div className={styles.ProjectTemplateTasksList__header}>
				<div className={styles.ProjectTemplateTasksList__title}>{t('subtasks')}</div>
				{isCanEdit && (
					<div className={styles.ProjectTemplateTasksList__control_panel}>
						<Button
							id="add-template_sub_tasks-button_open"
							onClick={onChoice}
							type="button"
							className="tt-border-transparent tt-min-h-32 tt-h-32 tt-px-16 tt-z-10"
							variant="outlined"
						>
							{t('choose')}
						</Button>
						<Button
							id="create-template_sub_task-button_open"
							onClick={onCreate}
							startIcon={<AddIcon />}
							className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
						>
							{t('create')}
						</Button>
					</div>
				)}
			</div>
			{tasksList?.length > 0 ? (
				<div>
					{tasksList.map((tasksListItem) => {
						return (
							<ProjectTemplateTasksListItem
								isCanEdit={isCanEdit}
								key={tasksListItem?.id}
								task={tasksListItem}
								baseUrl={baseUrl}
								removeTaskHandler={removeTaskHandler}
							/>
						)
					})}
				</div>
			) : (
				<EmptyProjectTemplateTasksList />
			)}
		</>
	)
}

export default ProjectTemplateTasksList
