import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { TextField } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import download from 'src/old-app/utils/download'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { exportFile } from 'src/old-app/service/File'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getProject } from 'src/old-app/store/projectsSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import ProjectUsersSelect from '../../task/ProjectUsersSelect'
import DialogMain from '../DialogMain'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import getLocation from 'src/old-app/utils/getLocation'

const schema = yup.object().shape({
	user: yup.object().shape({
		id: yup.string().required('Выберите пользователя'),
	}),
})

const KPGProjectUser = () => {
	const dispatch = useDispatch()
	const project = useSelector(getProject)
	const [isDisabled, setIsDisabled] = useState(false)

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
	} = useForm({
		defaultValues: {
			user: {},
		},
		resolver: yupResolver(schema),
	})

	const user = watch('user')

	const isValidation = (data) => {
		// if (data?.start_date && data?.end_date) {
		// 	if (moment(data.start_date).isBefore(data.end_date)) {
		// 		setError('meeting_time_start', { message: 'Нельзя время начало выбрать больше времени завершени' })
		// 		return true
		// 	}
		// 	if (!moment(data.start_date).isBefore(data.end_date)) {
		// 		setError('meeting_time_start', { message: 'Нельзя время завершения выбрать меньше времени начало' })
		// 		return true
		// 	}
		// }

		return false
	}
	const createProjectHandler = async (data) => {
		const isValidate = isValidation(data)
		if (isValidate) return

		const name = `${project?.name}_${data.user?.first_name}_${data.user?.last_name}_${data?.user?.middle_name}`
		const endDate = moment(data?.end_date).format()

		const location = getLocation()
		const startDate = moment(data?.start_date).format()
		const params = {
			project_id: project.id,
			user_id: data.user.id,
			location,
			start_date: data?.start_date ? startDate : undefined,
			end_date: data?.end_date ? endDate : undefined,
		}
		const url = `/report/user`

		exportKPGHandler({ url, params, name, dispatch })
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader
				title={`Выгрузка КПГ для ${user?.first_name ? `${user?.first_name} ${user?.last_name}` : 'пользователя'}`}
			/>
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10">
					<span className="tt-text-14 tt-font-semibold tt-mb-4">Время</span>

					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-items-center tt-gap-8">
							<span className="tt-text-14 tt-mb-4">с</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										helperText={errors?.start_date?.message}
										error={!!errors.start_date}
										type="date"
										hiddenLabel
										className="dialig-input tt-w-full"
										id="filled-hidden-label-small"
										defaultValue=""
										size="small"
									/>
								)}
								name="start_date"
								control={control}
							/>
						</div>
						<div className="tt-flex tt-items-center tt-gap-8">
							<span className="tt-text-14  tt-mb-4">по</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										helperText={errors?.end_date?.message}
										error={!!errors.end_date}
										type="date"
										hiddenLabel
										className="dialig-input tt-w-full"
										id="filled-hidden-label-small"
										defaultValue=""
										size="small"
									/>
								)}
								name="end_date"
								control={control}
							/>
						</div>
					</div>
				</div>
				<Controller
					render={({ field: { value, onChange } }) => (
						<ProjectUsersSelect
							errors={errors}
							displayEmpty
							field="user"
							label="Пользователь"
							onChange={onChange}
							value={value}
						/>
					)}
					name="user"
					control={control}
				/>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} rightLabel="Выгрузить КПГ" />
		</form>
	)
}

export default KPGProjectUser
