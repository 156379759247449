import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { Checkbox, Autocomplete as MuiAutocomplete, TextField, createFilterOptions } from '@mui/material'
import _, { uniqueId } from 'lodash'
import { makeStyles } from '@mui/styles'
import { Portal } from '@mui/base'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

interface IProps {
	id?: string
	list: any[]
	value: any
	defaultValue?: any
	disabled?: boolean
	error?: any
	onChange: (newValue?: any) => void
	onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void
	multiple?: boolean
	className?: string
	placeholder: string

	optionLabel: (value: any) => any
	create?: (value: any) => any
	renderOptionLabel: (value: any) => any
	renderOptionActions?: (value: any) => any
	getOptionDisabled?: (value: any) => any
}

interface FilmOptionType {
	inputValue?: string
	hashtag: string
	newHashtag: string
	id: string
	isCreate?: boolean
}

const filter = createFilterOptions<FilmOptionType>()

const useStyles = makeStyles({
	paper: {
		minWidth: 200,
	},
})

const Autocomplete: React.FC<IProps> = (p) => {
	const {
		id = 'autocomplete',
		multiple = true,
		list = [],
		value,
		onChange,
		optionLabel,
		create,
		renderOptionLabel,
		renderOptionActions,
		placeholder,
		onInputChange,
		error,
		defaultValue,
		className,
		getOptionDisabled,
		disabled,
	} = p

	const { t } = useTranslation()

	const classes = useStyles()

	return (
		<div className={styles['autocomplete']}>
			<MuiAutocomplete
				disabled={disabled}
				defaultValue={defaultValue}
				multiple={multiple}
				size="small"
				id={id}
				getOptionDisabled={getOptionDisabled}
				options={list}
				disableCloseOnSelect={multiple}
				classes={{ paper: classes.paper }}
				onInputChange={onInputChange}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							label={optionLabel(option)}
							{...getTagProps({ index })}
							disabled={getOptionDisabled ? getOptionDisabled(option) : false}
						/>
					))
				}
				getOptionLabel={
					create
						? (option) => {
								// console.log('option',option)
								if (typeof option === 'string') {
									return option
								}
								if (option.inputValue) {
									return option.inputValue
								}
								return optionLabel(option)
						  }
						: optionLabel
				}
				isOptionEqualToValue={(option, value) => option?.id === value?.id}
				renderOption={(_props, option, state) => {
					const _p: any = { ..._props, className: `${_props.className} tt-flex-1` }
					return (
						<div className="tt-flex tt-items-center tt-gap-2">
							<li {..._p}>
								{!option?.isCreate && multiple && <Checkbox style={{ marginRight: 8 }} checked={state.selected} />}
								{renderOptionLabel(option)}
							</li>
							{renderOptionActions ? <div className="tt-px-8">{renderOptionActions(option)}</div> : null}
						</div>
					)
				}}
				onChange={async (event, newValue, reason, details) => {
					if (!create) {
						onChange(newValue)
					}

					if (typeof newValue === 'string') {
						onChange(newValue)
					} else if (newValue && details?.option?.isCreate && create) {
						const newCreateValue = await create(details?.option)

						if (multiple) {
							onChange(
								newValue.map((item: any) => {
									if (item?.id === details?.option?.id) {
										item = newCreateValue
									}
									return item
								})
							)
							return
						}
						onChange(newCreateValue)
					} else {
						onChange(newValue)
					}
				}}
				filterOptions={
					create
						? (options, params) => {
								const filtered = onInputChange ? options : filter(options, params)

								const { inputValue } = params
								// Suggest the creation of a new value

								const isExisting = options.some((option) => inputValue === renderOptionLabel(option))
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										id: uniqueId(),
										hashtag: `${t('create')} "${inputValue}"`,
										newHashtag: inputValue,
										isCreate: true,
									})
								}

								return filtered
						  }
						: onInputChange
						? (options) => options
						: undefined
				}
				value={value}
				className={['dialig-input', 'select-multiple tt-z-100', className].join(' ')}
				fullWidth
				renderInput={(params) => {
					return <TextField {...params} label="" placeholder={placeholder} />
				}}
			></MuiAutocomplete>
		</div>
	)
}

export default Autocomplete
