import React, { ReactNode } from 'react'
import { Dialog } from '@mui/material'
import DialogMain from 'src/old-app/components/dialogs/DialogMain'
import Button from '../button'
import { getID, IGetIdArgs } from '../../config/fields-ids'
import { useTranslation } from 'react-i18next'

interface IProps {
	children: ReactNode
	title: string
	text: string
	onSuccess: () => void
	id?: string
	disabled?: boolean
}

const ConfirmDelete: React.FC<IProps> = ({ children, onSuccess, title, text, id, disabled }) => {
	const { t } = useTranslation()
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const onSubmit = (event: any) => {
		handleClose()
		onSuccess()
	}

	const _commonIdParams: IGetIdArgs = { prefix: id, place: 'modal' }

	return (
		<>
			<div onClick={disabled ? () => null : handleClickOpen}>{children}</div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="tt-w-600">
					<div className="tt-px-32 tt-py-16 tt-mb-12 tt-flex tt-items-center tt-justify-between tt-border-b tt-sticky tt-top-0 tt-bg-white z-50">
						<div className="tt-w-40 tt-h-40"></div>
						<div className="tt-text-18 tt-font-semibold tt-pt-16">{title}</div>
						<div
							id={getID({ ..._commonIdParams, type: 'button_close' })}
							className="tt-cursor-pointer"
							onClick={handleClose}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14 14L26 26M14 26L26 14L14 26Z"
									stroke="#64748B"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					</div>
					<DialogMain className="">
						<p className="tt-text-14 tt-font-medium tt-text-gray-500">{text}</p>
					</DialogMain>
					<div className="tt-px-24 tt-py-16 tt-mt-1 tt-bg-gray-50 tt-flex tt-justify-end tt-border-t tt-sticky tt-bottom-0">
						<div className="tt-flex tt-gap-12 tt-items-center">
							<Button
								id={getID({ ..._commonIdParams, type: 'button_cancel' })}
								variant="outlined"
								onClick={handleClose}
							>
								{t('no')}
							</Button>
							<Button id={getID({ ..._commonIdParams, type: 'button_cancel' })} onClick={onSubmit}>
								{t('yes')}
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	)
}

export default ConfirmDelete
