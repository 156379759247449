import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Chip } from '@mui/material'
import { getProject, getUsers, projectsActions } from 'src/old-app/store/projectsSlice'
import { useGetOneProjectQuery, useGetUserProjectsQuery } from 'src/old-app/service/Projects'
import SideBarHeader from '../sidebar/SideBarHeader'
import { getAdminRole, getUserRole } from 'src/old-app/store/userSlice'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import { useGetTeamQuery, useGetTeamUsersQuery } from 'src/old-app/service/Teams'
import TeamSideBarLoading from './TeamSideBarLoading'
import FieldContent from '../shared/FieldContent'
import UserCellShow from '../tables/UserCellShow'
import UserCell from '../tables/UserCell'
import TeamEdit from './TeamEdit'
import TeamProjects from './TeamProjects'
import { UserShow } from 'src/shared/components'
import TeamStatus from '../../../entities/team/components/team-status'
import { useGetUser } from '../../../shared/hooks'
import TeamSettings from '../../../entities/team/components/team-settings'

const TeamSideBar = ({ backTo }) => {
	return (
		<div className="tt-h-full  tt-flex tt-flex-col">
			<SideBarHeader hideActions to={backTo} />
			<div className="tt-overflow-auto tt-flex-1 tt-px-24 tt-py-16 ">
				<TeamSettings isWrap hideFields={{ roles: true }} />
			</div>
		</div>
	)
}

export default TeamSideBar
