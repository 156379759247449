import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import EmptyPlaceholder from '../empty-placeholder'
import UnSkeleton from '../un-skeleton'
import { InView } from 'react-intersection-observer'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface IProps {
	list: any[]
	columns: any[]
	heads?: any[]
	link?: (id: Id) => string
	emptyPlaceholder?: string
	tableClassName?: string
	meta?: any
	isLoading?: any
	isFetching?: any
	loadMore?: () => void
	rowActive?: (id: Id) => boolean
	rowDisabled?: (listItem: any) => boolean
}

const UnTable: React.FC<IProps> = (p) => {
	const { t } = useTranslation()

	const {
		list,
		rowDisabled,
		columns,
		heads,
		link,
		emptyPlaceholder,
		isLoading,
		isFetching,
		meta,
		loadMore,
		rowActive,
		tableClassName,
	} = p

	const isEmpty = list?.length === 0
	const totalEntries = meta?.total_entries ?? 0
	const perPage = meta?.per_page ?? 0

	const needMore = totalEntries > perPage

	if (isLoading) return <UnSkeleton />

	return (
		<table className={clsx(styles['un-table'], tableClassName)}>
			<thead className="filters">
				<tr className={styles['un-table-head-tr']}>
					{heads?.map((head) => (
						<th className={[styles['un-table-cell'], head?.className].join(' ')}>{head.render()}</th>
					))}
				</tr>
			</thead>

			{isEmpty && <EmptyPlaceholder text={emptyPlaceholder ?? t('not_found')} />}

			{loadMore ? (
				<InView
					children={({ ref, inView, entry }) => (
						<tbody ref={ref} style={!inView && entry ? { height: entry?.boundingClientRect?.height } : {}}>
							{list.map((listItem) =>
								rowDisabled && rowDisabled(listItem) ? (
									<tr
										className={[
											styles['un-table-body-tr'],
											rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
											rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
											
										].join(' ')}
									>
										{columns.map((column) => (
											<td
												className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className].join(' ')}
											>
												{column.render(listItem)}
											</td>
										))}
									</tr>
								) : link ? (
									<Link to={link(listItem.id)}>
										<tr
											className={[
												styles['un-table-body-tr'],
												rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
												rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
												
											].join(' ')}
										>
											{listItem?.project_request_availability && (
												<div className="tt-flex tt-w-8 tt-h-8 tt-absolute tt-top-2 tt-left-2 tt-bg-primary-600 tt-rounded-full tt-animate-pulse" />
											)}
											{columns.map((column) => (
												<td
													className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className].join(' ')}
												>
													{column.render(listItem)}
												</td>
											))}
										</tr>
									</Link>
								) : (
									<tr
										className={[
											styles['un-table-body-tr'],
											rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
											rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
											
										].join(' ')}
									>
										{listItem?.project_request_availability && (
											<div className="tt-flex tt-w-8 tt-h-8 tt-absolute tt-top-2 tt-left-2 tt-bg-primary-600 tt-rounded-full tt-animate-pulse" />
										)}
										{columns.map((column) => (
											<td
												className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className].join(' ')}
											>
												{column.render(listItem)}
											</td>
										))}
									</tr>
								),
							)}
							{isFetching && <EmptyPlaceholder text={t('is_loading')} />}

							{needMore && (
								<InView
									delay={0}
									onChange={(inView) => {
										inView && loadMore()
									}}
								/>
							)}
						</tbody>
					)}
				/>
			) : (
				<tbody>
					{list.map((listItem) =>
						rowDisabled && rowDisabled(listItem) ? (
							<tr
								className={[
									styles['un-table-body-tr'],
									rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
									rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
									
								].join(' ')}
							>
								{columns.map((column) => (
									<td className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className].join(' ')}>
										{column.render(listItem)}
									</td>
								))}
							</tr>
						) : link ? (
							<Link to={link(listItem.id)}>
								<tr
									className={[
										styles['un-table-body-tr'],
										rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
										rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
										
									].join(' ')}
								>
									{columns.map((column) => (
										<td className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className].join(' ')}>
											{column.render(listItem)}
										</td>
									))}
								</tr>
							</Link>
						) : (
							<tr
								className={[
									styles['un-table-body-tr'],
									rowActive && rowActive(listItem.id) && styles['un-table-row-active'],
									rowDisabled && rowDisabled(listItem) && styles['un-table-row-disable'],
									"!tt-cursor-default"
								].join(' ')}
							>
								{columns.map((column) => (
									<td className={[styles['un-table-body-td'], styles['un-table-cell'], column?.className, "!tt-cursor-default"].join(' ')}>
										{column.render(listItem)}
									</td>
								))}
							</tr>
						),
					)}
				</tbody>
			)}
		</table>
	)
}

export default UnTable
