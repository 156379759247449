import React from 'react'
import { Link } from 'react-router-dom'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { useDispatch, useSelector } from 'react-redux'
import { getNotTasks } from 'src/old-app/store/tasksSlice'
import { getProjectTemplateData } from 'src/old-app/store/projectsSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useTranslation } from 'react-i18next'

const NotAssigneeTasks = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const projectTempalteData = useSelector(getProjectTemplateData)
	const tasks = useSelector(getNotTasks)

	const closeHandler = () => {
		dispatch(dialogActions.setDialog(false))
	}

	return (
		<div className="tt-w-600">
			<DialogHeader title={t('unable_to_save_template')} />
			<DialogMain>
				<div className="tt-mb-8 tt-font-medium">{t('tasks_dont_have_performer')}</div>
				<div className="tt-flex tt-flex-col tt-gap-6">
					{tasks.map((task) => (
						<Link onClick={closeHandler} to={`/projects-templates/${projectTempalteData.id}/${task.id}/${task.id}`}>
							<span className="tt-cursor-pointer tt-text-primary-500 hover:tt-text-primary-700">{task.title}</span>
						</Link>
					))}
				</div>
			</DialogMain>
		</div>
	)
}

export default NotAssigneeTasks
