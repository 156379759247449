import React, { useContext } from 'react'
import styles from './styles.module.scss'
import TaskAcceptButton from '../../../../entities/task/components/task-accept-button'
import { TaskContext } from '../../../../entities/task'
import { useGetUser } from '../../../../shared/hooks'
import { useTranslation } from 'react-i18next'

interface IProps {}

const TaskSideFooter: React.FC<IProps> = () => {
	const { t } = useTranslation()
	const user = useGetUser()

	const { task } = useContext(TaskContext)

	const isMyTask = user?.id === task?.assignee_id

	const isHide = !task?.incoming || !isMyTask

	if (isHide) return <></>
	return <div className={styles['task-side-footer']}>{<TaskAcceptButton label={t('accept_task')} />}</div>
}

export default TaskSideFooter
