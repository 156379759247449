import { MenuItem, Select } from '@mui/material'
import clsx from 'clsx'

const TaskStatusesChoice = ({
	value,
	taskStatuses,
	onChange,
	placeholder,
	disabled,
	className,
	checkStatusDisability,
}) => {
	return (
		<Select
			id={'task_status_select'}
			disabled={disabled}
			onChange={onChange}
			className={clsx('tt-w-full tt-h-40 tt-rounded-md', className)}
			placeholder={placeholder}
			value={value}
			renderValue={(selectedStatus) => <div className="tt-w-full tt-mt-[0.3rem]">{selectedStatus?.status}</div>}
		>
			{taskStatuses.map((status) => (
				<MenuItem
					id={`task_status_${status?.id}`}
					disabled={checkStatusDisability ? checkStatusDisability(status) : false}
					key={`task_status_${status?.id}`}
					value={status}
				>
					{status?.status}
				</MenuItem>
			))}
		</Select>
	)
}

export default TaskStatusesChoice
