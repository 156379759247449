import { lazy } from 'react'
import StartMyProject from './StartMyProject'
const Scrumboard = lazy(() => import('./scrumboard/Scrumboard'))
const Tasks = lazy(() => import('./Tasks'))
const Task = lazy(() => import('./Task'))
const SanctionProject = lazy(() => import('./SanctionProject'))

const TasksConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'projects/list/:projectId',
			element: <Tasks />,
			children: [
				{
					path: ':taskId',
					element: <Tasks />,
				},
			],
		},
		{
			path: 'projects/sanction/:projectId',
			element: <SanctionProject />,
			children: [
				{
					path: ':taskId',
					element: <SanctionProject />,
				},
			],
		},
		{
			path: 'task/:taskId',
			element: <Task />,
		},
		{
			path: 'projects/:projectId/start',
			element: <StartMyProject />,
		},
		{
			path: 'projects/board/:projectId',
			element: <Scrumboard />,
			children: [
				{
					path: ':taskId',
					element: <Scrumboard />,
				},
			],
		},
	],
}

export default TasksConfig

/**
 * Lazy load Example
 */
/*
import React from 'react';

const Example = lazy(() => import('./Example'));

const ExampleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'example',
      element: <Example />,
    },
  ],
};

export default ExampleConfig;
*/
