import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL, TOKEN_NOVU, NOVU_BACKEND_URL } from 'src/shared/config'
import createHeaders from './createHeaders'

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	// prepareHeaders: createHeaders,
})

export const notifAPI = createApi({
	reducerPath: 'notif-api',
	tagTypes: ['Notif'],
	baseQuery,
	endpoints: (build) => ({
		readNovu: build.mutation({
			query: ({ token }) => ({
				method: 'POST',
				url: `${NOVU_BACKEND_URL}/v1/widgets/messages/read`,
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': `application/json`,
				},
			}),
		}),
		getNotifs: build.query({
			query: (params) => ({
				url: '/notification/',
				params,
			}),
			providesTags: ['Notif'],
		}),
		deleteAllNotifs: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/notification/${id}/user`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Notif'],
		}),
		registerWebPush: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/notification/register-web-push',
				body: JSON.stringify(body),
			}),
		}),
		getOneNotif: build.query({
			query: (id) => ({
				url: `/notification/${id}`,
			}),
		}),
		deleteOneNotif: build.mutation({
			query: (id) => ({
				url: `/notification/${id}`,
				method: 'DELETE',
				body: JSON.stringify({}),
			}),
		}),
	}),
})

export const {
	useDeleteAllNotifsMutation,
	useReadNovuMutation,
	useGetNotifsQuery,
	useGetOneNotifQuery,
	useDeleteOneNotifMutation,
	useRegisterWebPushMutation,
} = notifAPI
