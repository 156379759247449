import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getProject, projectsActions } from 'src/old-app/store/projectsSlice'
import { useDeleteProjectUserMutation, useGetOneProjectQuery } from 'src/old-app/service/Projects'
import { useLocation } from 'react-router-dom'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useState, useEffect } from 'react'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const DeleteUserProject = () => {
	const { t } = useTranslation()
	const {
		handleSubmit,
		formState: { errors },
	} = useForm()
	const dispatch = useDispatch()
	const project = useSelector(getProject)
	const loc = useLocation()

	const [isDisabled, setIsDisabled] = useState(false)

	const [deleteUser, deleteResult] = useDeleteProjectUserMutation()

	const editUserProjectHandler = async () => {
		dispatch(interfaceActions.setIsLoading(true))
		setIsDisabled(true)

		await deleteUser({
			project_id: project.id,
			user_id: loc.pathname.split('/')[2],
		})

		// dispatch(projectsActions.deleteProjectUser(project?.id))

		dispatch(
			showMessage({
				message: t('user_removed_from_project'),
				autoHideDuration: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
		dispatch(dialogActions.setDialog(false))
	}

	useEffect(() => {
		if (deleteResult.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: deleteResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [deleteResult])

	return (
		<form onSubmit={handleSubmit(editUserProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('remove_user')} />
			<DialogMain>{t('remove_user_from_project', { value: project?.name })}</DialogMain>
			<DialogFooter id={getID({ entity: 'user', action: 'delete' })} rightLabel={t('delete')} isDisabled={isDisabled} />
		</form>
	)
}

export default DeleteUserProject
