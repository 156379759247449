import { AppDispatch } from 'src/app/store'
import { userActions } from 'src/entities/user'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

const actions = { ...userActions }

const useActions = () => {
	const dispatch = useDispatch<AppDispatch>()
	return bindActionCreators(actions, dispatch)
}

export default useActions
