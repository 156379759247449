import { IconButton, Popover } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import React, { useMemo, useState } from 'react'
import { useGetProjectParamsQuery, useGetUserProjectsQuery } from 'src/old-app/service/Projects'
import ParamValuesSelect from './ParamValuesSelect'
import { useSelector } from 'react-redux'
import { getUserID } from 'src/old-app/store/userSlice'
import { uniq } from 'lodash/array'
import { JsonParam, useQueryParam } from 'use-query-params'
import { LoadingButton } from '@mui/lab'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const AddFilter = ({ projectId, id }) => {
	const { t } = useTranslation()
	// const { search } = useLocation();
	// const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null)
	// const [searchParams] = useSearchParams();
	const [queryFields = {}, setFields] = useQueryParam('fields', JsonParam)

	const userID = useSelector(getUserID)

	const requestParams = {
		user_id: userID,
		per_page: 100,
	}

	if (projectId && projectId?.length) {
		requestParams.project_id = projectId
	}

	const projectsQuery = useGetUserProjectsQuery(requestParams, { init: !projectId })

	const stringProjectsIDS = useMemo(
		() =>
			projectsQuery.isSuccess ? projectsQuery.data?.data.map((project) => project.project_id)?.toString() : undefined,
		[projectsQuery.data]
	)

	const open = Boolean(anchorEl)
	// const task = useSelector(getTask)

	const projectsParamsQuery = useGetProjectParamsQuery(projectId || stringProjectsIDS, {
		init: projectId || projectsQuery.isSuccess,
	})

	const projectsParams = useMemo(
		() => (projectsParamsQuery.data?.data ? uniq(projectsParamsQuery.data?.data) : []),
		[projectsParamsQuery.data]
	)
	const isEmptyProjectParams = !Boolean(projectsParams.length)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const changeParams = (field, value) => {
		setFields((curr) => ({ ...curr, [field]: value.label }))
	}

	const removeParam = (field) => {
		setFields((curr) => {
			const copyCurr = { ...curr }
			delete copyCurr[field]
			// console.log({ curr, field, copyCurr });
			return copyCurr
		})
	}

	const _id = open ? 'simple-popover' : undefined

	return (
		<div>
			<LoadingButton
				id={getID({ prefix: id, action: 'filter', entity: 'project', field: 'params', type: 'button_open' })}
				startIcon={<AddIcon />}
				className="tt-text-primary tt-whitespace-nowrap tt-border tt-border-primary-600   tt-min-h-32 tt-h-32 tt-px-16"
				aria-describedby={_id}
				// disabled={!projectsParamsQuery.isSuccess}
				loadingPosition="start"
				variant="contained"
				loading={projectsParamsQuery.isLoading}
				onClick={handleClick}
			>
				{t('custom_fields_filter')}
			</LoadingButton>

			<Popover
				id={_id}
				open={open}
				container={document.getElementById('track-root')}
				anchorEl={anchorEl}
				onClose={handleClose}
				className={'tt-max-h-320'}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<div className="tt-px-16 tt-py-12 tt-flex tt-flex-col tt-gap-4">
					{isEmptyProjectParams
						? t('empty_custom_fields_list')
						: projectsParams.map((item, index) => {
								const value = queryFields[item]

								return (
									<div className="tt-flex tt-gap-14 tt-items-center  tt-justify-between">
										<span>{item}</span>

										<div className="tt-flex tt-items-center tt-gap-4">
											<div className="tt-w-20 tt-h-20">
												{value && (
													<IconButton className="tt-h-20 tt-w-20" onClick={() => removeParam(item)}>
														<CloseIcon className="tt-h-16 tt-w-16" />
													</IconButton>
												)}
											</div>
											<div className="min-w-[150px]">
												<ParamValuesSelect
													idSelect={getID({
														action: 'filter',
														entity: 'project',
														field: 'params',
														type: 'select',
														index,
													})}
													value={{ id: value, label: value }}
													id={requestParams?.project_id || stringProjectsIDS}
													onChange={(value) => changeParams(item, value)}
													param={item}
												/>
											</div>
										</div>
									</div>
								)
						  })}
				</div>
			</Popover>
		</div>
	)
}

export default AddFilter
