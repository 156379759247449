import { getStatuses, tasksActions } from 'src/old-app/store/tasksSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { useDeleteStatusTaskMutation } from 'src/old-app/service/Tasks'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { getProject } from 'src/old-app/store/projectsSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useProjectShow } from '../../../../entities/project'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const StatusesProject = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const statuses = useSelector(getStatuses)
	const projectShow = useProjectShow()
	const project = useSelector(getProject)

	const isCommittee = projectShow?.isCommittee

	const [deleteStatus] = useDeleteStatusTaskMutation()

	const deleteHandler = async (item) => {
		dispatch(interfaceActions.setIsLoading(true))
		await deleteStatus(item.id)
		dispatch(interfaceActions.setIsLoading(false))
		dispatch(scrumboardActions.deleteOneList(item.id))
		dispatch(tasksActions.deleteStatus(item.id))
		// dispatch(projectsActions.getPriority(item))
		// dispatch(dialogActions.setDialog(true))
		// dispatch(dialogActions.setComponent('edit-priority'))
	}

	const editHandler = (item) => {
		dispatch(tasksActions.getStatus(item))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('edit-status'))
	}

	const createHandler = (item) => {
		dispatch(tasksActions.getStatus(item))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('create-status'))
	}

	return (
		<div>
			{statuses.length === 0 ? (
				<div className="tt-text-gray-400 tt-mb-8">{t('empty_statuses_list')}</div>
			) : (
				<div className="tt-flex tt-flex-col tt-gap-4 tt-mb-8">
					{statuses.map((item, index) => {
						const disabled = project?.with_administration
							? item?.is_request || item?.is_final || item?.is_initial
							: false
						return (
							<div key={item.id} className="tt-flex tt-gap-8 group tt-items-center tt-truncate">
								<div className="tt-w-12 tt-h-12 tt-rounded-full" style={{ background: item.color }} />
								<span className="tt-font-medium tt-truncate tt-max-w-[200px]">{item.status}</span>
								{projectShow.isManageProject && !projectShow?.project?.isEnd && (
									<CommentedDisability
										optionDisabled={isCommittee}
										disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
									>
										<div className="tt-scale-100 group-hover:tt-scale-100  tt-duration-300 tt-flex tt-items-center tt-gap-4">
											<EditOutlinedIcon
												id={getID({
													place: 'modal',
													entity: 'project',
													action: 'edit',
													field: 'status',
													type: 'button_edit',
													index,
												})}
												onClick={() => editHandler(item)}
												className=" tt-text-gray-500 hover:tt-text-gray-800 tt-cursor-pointer tt-w-20 tt-h-20"
											/>

											{!disabled && (
												<ConfirmDelete
													text={t('delete_status_dialog.annotation', { value: item.status })}
													onSuccess={() => deleteHandler(item)}
													title={t('delete_status_dialog.header')}
												>
													<DeleteIcon
														id={getID({
															place: 'modal',
															entity: 'project',
															action: 'edit',
															field: 'status',
															type: 'button_delete',
															index,
														})}
														className="tt-text-gray-500 hover:tt-text-gray-800 tt-cursor-pointer tt-w-20 tt-h-20"
													/>
												</ConfirmDelete>
											)}
										</div>
									</CommentedDisability>
								)}
							</div>
						)
					})}
				</div>
			)}
			{projectShow.isManageProject && !projectShow?.project?.isEnd && (
				<CommentedDisability
					optionDisabled={isCommittee}
					disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
				>
					<button
						id={getID({
							place: 'modal',
							entity: 'project',
							action: 'edit',
							field: 'status',
							type: 'button_add',
						})}
						onClick={createHandler}
						className="tt-text-primary-500 hover:tt-text-primary-600 tt-duration-200 tt-mt-4 tt-flex tt-items-center tt-gap-6 tt-rounded-full  tt-h-28"
					>
						<AddIcon className="tt-w-20 tt-h-20" />
						{t('add_status')}
					</button>
				</CommentedDisability>
			)}
		</div>
	)
}

export default StatusesProject
