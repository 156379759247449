import React from 'react'
import { Avatar } from '@mui/material'

const CustomAvatar = ({ alt = '0', src, className, style, label }) => {
	return (
		<div style={style}>
			{src ? (
				<Avatar className={className} alt={alt} src={src} />
			) : (
				<div
					className={[
						className,
						'tt-flex tt-items-center tt-justify-center tt-text-bold tt-font-bold tt-bg-primary-50',
					].join(' ')}
				>
					{label ?? alt[0]}
				</div>
			)}
		</div>
	)
}

export default CustomAvatar
