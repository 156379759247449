import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import 'src/styles/markdown-editor.css'
import { getDialogIsOpen } from 'src/old-app/store/dialogSlice'
import { useSelector } from 'react-redux'
import { getID } from '../../../../shared/config/fields-ids'
// import '@uiw/react-markdown-preview/markdown.css'

const MD = ({ value, onChange, id }) => {
	const isOpenDialog = useSelector(getDialogIsOpen)
	// useEffect(() => {
	// 	if (value) {
	// 		const lm = document.querySelector('code.language-markdown')
	//
	// 		if (lm) {
	// 			// lm.style.background = 'none'
	// 			// lm.style.color = '#263238'
	// 		}
	// 	}
	// }, [value])

	// useEffect(() => {
	// 	if (value) {
	// 		const last = value[value.length - 1]
	// 		const isMention = last === '@'
	// 		if (isMention) {
	// 		}
	// 		// console.log('last', last)
	// 		// console.log('last', last)
	// 	}
	// }, [value])

	return (
		<div style={{ all: 'initial' }} data-color-mode="light">
			<MDEditor
				id={getID({ prefix: id, lib: 'md_editor' })}
				className={!isOpenDialog && 'md-left'}
				// commands={[...commands]}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default MD
