export type IGetIdArgs = {
	place?: 'modal' | 'sidebar' | 'tabs'
	entity?: keyof typeof Entities
	action?: keyof typeof Actions
	field?:
		| keyof typeof TaskFields
		| keyof typeof ProjectFields
		| keyof typeof ProtocolFields
		| keyof typeof ProjectTemplateFields
		| keyof typeof Filters
	fieldPostfix?: 'start' | 'end' | 'template' | 'show' | 'approve' | 'new'
	type?: keyof typeof Types
	index?: Number | String
	lib?: 'md_editor' | 'mui_autocomplete' | 'table_pagination' | 'mentions-input'
	prefix?: String | undefined
}

export const getID = ({ place, prefix, entity, action, field, fieldPostfix, type, lib, index }: IGetIdArgs): string => {
	// console.log({index});
	return [place, prefix, entity, action, field, fieldPostfix, type, lib, index]
		.filter((part) => part === 0 || part)
		.join('-')
}

const enum Actions {
	create,

	edit,
	delete,
	search,
	add,
	filter,
	sort,
	info,
	manage,
	confirm,
	clear,
	download,
	upload,
	input,
}

const enum Entities {
	task,
	incoming_task,
	project,
	child_project,
	project_template,
	protocol,
	dialog,
	hashtag,
	files,
	status,
	user,
	logged_user_tasks,
	priority,
	sanction,
}

const enum Filters {
	sort_direction,
	sub_tasks,
}

const enum TaskFields {
	time,
	comment,
	extra,
	report,
	title,
	type,
	level,
	color,
	template,
	priority,
	description,
	deadline,
	deadline_start,
	deadline_end,
	hashtags,
	hashtags_item,
	assignees,
	subscribers,
	params,
	params_item,
	params_item_field,
	params_item_value,
	result_representation,
	need_report,
	files,
	files_item,
	files_add,
	time_tracking_by_button,
	spent_time,
	spent_time_hours,
	confirmation,
	confirmatory_deadline,
	kpg,
}

const enum ProjectFields {
	user_role,
	template,
	tasks,
	name,
	slug,
	complete_at,
	users,
	users_item,
	teams,
	teams_item,
	each_member_can_create_task,
	is_administration,
	confirmation_ba,
	confirmation_project_manager,
	assignees_template,
	actual_deadline,
	with_administration,
	status,
}

const enum ProjectTemplateFields {
	status_name,
	status_type_is_initial,
	status_type_is_request,
	status_type_is_final,
	priority,
	priority_name,
	priority_level,
}

const enum ProtocolFields {
	topic,
	meeting_date,
	meeting_time_start,
	meeting_time_end,
	decisions_taken,
	decisions_taken_name,
	decisions_taken_responsible,
	decisions_taken_project,
	decisions_taken_task_priority,
	decisions_taken_completion,
	decisions_taken_completion_known,
	project,
	users,
	place,
}

const enum Types {
	lib,
	date,
	datetime,
	mention_input,
	select,
	select_item,
	select_search,
	row,
	select_autocomplete,
	text,
	link,
	link_icon,
	checkbox,
	input_file,
	file,
	time,
	color,
	radio,
	number,
	button,
	button_submit,
	button_cancel,
	button_delete,
	button_close,
	button_back,
	button_open,
	button_add,
	button_edit,
	button_create,
	button_toggle,
	button_tab,
}
