import { useMemo } from 'react'
import styles from './ProjectProtocolsTableHeaderRow.module.css'
import ProjectProtocolsTableHeaderCell from './ProjectProtocolsTableHeaderCell'
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import { useTranslation } from 'react-i18next'
import EllipsisTextLine from 'src/@shared/lib/EllipsisTextLine'

const ProjectProtocolsTableHeaderRow = ({ columnsSettings }) => {
	const { t } = useTranslation()
	const columnsComponents = useMemo(
		() => ({
			// hid: {
			// 	component: <NumbersOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />,
			// },
			topic: {
				component: (
					<div className="tt-flex tt-text-gray-600 tt-items-center">
						<ListAltOutlinedIcon className="tt-h-20 tt-w-20 tt-mr-4" />
						<EllipsisTextLine>{t('protocol_title')}</EllipsisTextLine>
					</div>
				),
			},
			committee_time: {
				component: (
					<div className="tt-flex tt-text-gray-600 tt-items-center">
						<EventOutlinedIcon className="tt-h-20 tt-w-20 tt-mr-4" />
						<EllipsisTextLine>{t('meeting_time')}</EllipsisTextLine>
					</div>
				),
			},
			protocol_created_at: {
				component: (
					<div className="tt-flex tt-text-gray-600 tt-items-center">
						<EventAvailableOutlinedIcon className="tt-h-20 tt-w-20 tt-mr-4" />
						<EllipsisTextLine>{t('creation_date')}</EllipsisTextLine>
					</div>
				),
			},
			author: {
				component: (
					<div className="tt-flex tt-text-gray-600 tt-items-center">
						<PermIdentityOutlinedIcon className="tt-h-20 tt-w-20 tt-mr-4" />
						<EllipsisTextLine>{t('responsible')}</EllipsisTextLine>
					</div>
				),
			},
		}),
		[columnsSettings]
	)

	return (
		<div className={styles.row_wrapper}>
			<div className={styles.row}>
				{Object.entries(columnsComponents).map((columnsComponent) => {
					const columnID = columnsComponent[0]
					return (
						<ProjectProtocolsTableHeaderCell
							key={columnID}
							canGrow={columnsSettings[columnID]?.canGrow}
							basicWidth={columnsSettings[columnID]?.basicWidth}
							component={columnsComponents[columnID]?.component}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ProjectProtocolsTableHeaderRow
