import React, { useMemo } from 'react'
import FiledRow from '../../../../shared/components/filed-row'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Button } from '@mui/material'
import { ConfirmDelete } from 'src/shared/components'
import { useDeleteProjectContractDirectoryMutation } from 'src/old-app/service/Projects'
import { useDispatch } from 'react-redux'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useNavigate } from 'react-router-dom'
import { formatter } from 'src/shared/lib/formatteer'
import { HeadItem } from 'src/@widgets/project/ui/project-contract-directory-table/project-contract-directory-table'
import UnTable from 'src/shared/components/un-table'
import { useTranslation } from 'react-i18next'

interface ContractTypeInfoProps {
	setIsEdit: (isEdit: boolean) => void
	contractType: any
	isManage: boolean
	closeSideBar: () => void
}

export const ContractTypeInfo: React.FC<ContractTypeInfoProps> = ({
	setIsEdit,
	contractType,
	closeSideBar,
	isManage,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [deleteProjectContractDirectory] = useDeleteProjectContractDirectoryMutation()

	const deleteContractType = async () => {
		dispatch(interfaceActions.setIsLoading(true))
		await deleteProjectContractDirectory(contractType.id)
		dispatch(interfaceActions.setIsLoading(false))
		closeSideBar()
	}

	const heads: any[] = useMemo(
		() => [
			{
				id: 1,
				label: t('parameter_title'),
				field: '1',
				className: 'tt-flex-1',
				render: (listItem: any) => <HeadItem className="tt-flex-1">{t('parameter_title')}</HeadItem>,
			},
			{
				id: 1,
				label: '1',
				field: '1',
				className: '',
				render: (listItem: any) => <HeadItem className="tt-min-w-208">{t('price_rub')}</HeadItem>,
			},
		],
		[]
	)

	const columns: any[] = useMemo(
		() => [
			{
				id: 1,
				label: t('title'),
				field: 'name',
				className: 'tt-flex-1',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap  tt-truncate tt-break-all tt-min-w-76  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.name}
					</p>
				),
			},
			{
				id: 2,
				label: t('price_rub'),
				field: 'pricing',
				className: '',
				render: (listItem: any) => (
					<p className=" tt-whitespace-pre-wrap tt-break-all  tt-min-w-208  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						<>₽ {Number(listItem?.price).toLocaleString('ru')}</>
					</p>
				),
			},
		],
		[]
	)
	return (
		<div className="tt-px-24 tt-py-16">
			<div className="tt-flex tt-items-center tt-gap-12">
				{isManage && (
					<>
						<Button
							onClick={() => setIsEdit(true)}
							startIcon={
								<svg
									className="tt-stroke-gray-700"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
										strokeWidth="2"
										stroke="#94A3B8"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							}
							className="tt-border-gray-200 tt-inline-flex  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
							variant="outlined"
						>
							{t('edit')}
						</Button>

						<ConfirmDelete
							text={t('delete_catalog_dialog.are_you_shure')}
							onSuccess={deleteContractType}
							title={t('delete_catalog_dialog.header')}
						>
							<Button
								startIcon={<DeleteOutlineOutlinedIcon className="tt-stroke-red-700 tt-h-20 tt-w-20" />}
								className="tt-border-red-200 tt-inline-flex tt-text-red tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
								variant="outlined"
							>
								{t('delete')}
							</Button>
						</ConfirmDelete>
					</>
				)}
			</div>
			<div className="tt-grid-cols-1 tt-grid tt-gap-16 tt-mt-24">
				<FiledRow label={t('category')} content={t(`contract_categories.${contractType?.category}`)} />
				<FiledRow label={t('type')} content={contractType?.name} />

				{!!contractType?.price && (
					<FiledRow
						label={t('price_rub')}
						content={
							<div className="tt-px-4 tt-inline tt-py-1 tt-rounded tt-bg-gray-100 tt-border-gray-200 tt-border tt-text-gray-600">
								{<>₽ {Number(contractType?.price).toLocaleString('ru')}</>}
							</div>
						}
					/>
				)}
				{contractType?.params.length !== 0 && (
					<UnTable
						meta={{}}
						rowDisabled={(listItem) => true}
						link={() => ''}
						isLoading={false}
						emptyPlaceholder=""
						heads={heads}
						list={contractType?.params}
						columns={columns}
					/>
				)}
			</div>
		</div>
	)
}
