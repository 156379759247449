import { IconButton, Popover } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import React from 'react'
import FilterDeadline from './FilterDeadline'
import AddFilter from './AddFilter'
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
// import { useState } from 'react'
import TaskSortRow from './TaskSortRow'
import { getID } from '../../../shared/config/fields-ids'

import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { useTranslation } from 'react-i18next'

const TaskMoreFilters = ({ prefix, projectId, allProjectsMode, hideTaskParamsFilter, isLabel, hideSkelet, id }) => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const _id = open ? 'simple-popover' : undefined
	return (
		<>
			<div
				className="tt-flex tt-gap-8 tt-items-center new-select tt-px-[7px] tt-py-4 tt-h-32 group  hover:tt-text-black tt-pr-[9px] tt-cursor-pointer tt-rounded-full tt-flex  hover:tt-border-gray-400 tt-border tt-border-transparent tt-items-center"
				aria-describedby={id}
				id={getID({ prefix: id, action: 'filter', field: 'extra', type: 'button_open' })}
				// variant='contained'
				onClick={handleClick}
			>
				<FilterListOutlinedIcon className="tt-w-22 tt-h-22 group-hover:tt-text-gray-700 tt-text-gray-500" />
				{isLabel && (
					<span className="tt-font-normal tt-whitespace-nowrap tt-text-gray-500 group-hover:tt-text-gray-700">
						{t('extra_filters_shorten')}
					</span>
				)}
			</div>

			<Popover
				id={_id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				container={document.getElementById('track-root')}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<div className="tt-p-16 tt-flex tt-flex-col tt-gap-20 filters">
					<div>
						<span className="tt-font-medium">{t('sort_by')}</span>
						<div className="tt-flex tt-flex-col tt-gap-8 tt-mt-10">
							{hideSkelet?.request_sort && (
								<TaskSortRow id={id} label={t('application_creation_date')} defaultValue="desc" field="request_sort" />
							)}
							{!hideSkelet?.order_sort && (
								<TaskSortRow id={id} label={t('creation_date')} defaultValue="desc" field="order_sort" />
							)}
							{hideSkelet?.created_at_sort && (
								<TaskSortRow id={id} label={t('creation_date')} defaultValue="desc" field="created_at_sort" />
							)}
							{!hideSkelet?.deadline_sort && <TaskSortRow id={id} label={t('deadline')} field="deadline_sort" />}
						</div>
					</div>
					<div>
						<span className="tt-font-medium">{t('other_filters')}</span>
						<div className="tt-flex tt-flex-col tt-gap-8 tt-mt-10">
							{hideSkelet?.complete_date && (
								<FilterDeadline
									id={getID({ prefix: id, action: 'filter', field: 'completed_at' })}
									label={t('completion_date')}
									field="completed_at"
									prefix={prefix}
								/>
							)}
							{!hideTaskParamsFilter && <AddFilter id={id} allProjectsMode={allProjectsMode} projectId={projectId} />}
						</div>
					</div>
				</div>
			</Popover>
		</>
	)
}

export default TaskMoreFilters
