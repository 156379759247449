import React, { useEffect, memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useGetOneProtocolQuery,
	useGetProtocolDecisionTaskQuery,
	useSendProtocolMutation,
} from 'src/old-app/service/Protocol'
import { useDispatch } from 'react-redux'
import SendIcon from '@mui/icons-material/Send'
import { Button } from '@mui/material'
import SideBarHeader from '../../sidebar/SideBarHeader'
import ProtocolInfo from './ProtocolInfo'
import ProtocolSideBarLoading from './ProtocolSideBarLoading'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useTranslation } from 'react-i18next'
import { ProtocolDownload } from 'src/features/protocol'

const ProtocolSideBar = memo(() => {
	const { t } = useTranslation()
	const { projectId, protocolId } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [sendProtocol] = useSendProtocolMutation()
	const { data: protocolData, error: protocolError, isFetching } = useGetOneProtocolQuery(protocolId)

	const backTo = projectId ? `/project/${projectId}/protocols` : '/protocol'

	const {
		data: protocolDecisionTaskData,
		isFetching: protocolDecisionTaskIsLoading,
		error: protocolDecisionTaskError,
	} = useGetProtocolDecisionTaskQuery({ protocol_id: protocolId })

	useEffect(() => {
		if (protocolError || protocolDecisionTaskError) {
			navigate('/404')
		}
	}, [protocolError, protocolDecisionTaskError])

	if (isFetching) return <ProtocolSideBarLoading />

	const sendHandler = (id) => {
		dispatch(interfaceActions.setIsLoading(true))
		sendProtocol(id).then((res) => {
			if (res) {
				dispatch(
					showMessage({
						message: t('successfully_sent.protocol'),
						autoHideDuratigon: 1000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'success',
					})
				)
				dispatch(interfaceActions.setIsLoading(false))
			}
		})
	}

	const protocol = protocolData?.data
	const protocolDecisionTask = protocolDecisionTaskData?.data ?? []

	return (
		<div className="tt-h-full  tt-flex tt-flex-col">
			<SideBarHeader
				hideActions
				left={
					<div className='tt-flex tt-items-center tt-gap-12'>
					<Button
						onClick={() => sendHandler(protocol?.id)}
						startIcon={<SendIcon className="tt-text-primary" />}
						className="tt-bg-primary/10 h-40 hover:tt-bg-primary-600/10 tt-text-primary  tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('send_by_email')}
						</Button>
						{protocol.committee_id &&<ProtocolDownload  name={protocol?.topic} protocolId={protocol?.id} />}
					</div>
				}
				to={backTo}
			/>
			<div className="tt-overflow-auto tt-flex-1 tt-px-24 tt-py-16 ">
				<ProtocolInfo protocolDecisionTask={protocolDecisionTask} protocol={protocol} />
			</div>
		</div>
	)
})

ProtocolSideBar.displayName = 'ProtocolSideBar'
export default ProtocolSideBar
