import AnalysisViolations from './AnalysisViolations'

const AnalysisViolationsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'analysis-violations',
			element: <AnalysisViolations />,
			children: [
				{
					path: ':taskId',
					element: <AnalysisViolations />,
				},
			],
		},
	],
}

export default AnalysisViolationsConfig
