import Teams from './Teams'

const TeamsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'teams',
			element: <Teams />,
			children: [
				{
					path: ':teamId',
					element: <Teams />,
				},
			],
		},
	],
}

export default TeamsConfig

/**
 * Lazy load Example
 */
/*
import React from 'react';

const Example = lazy(() => import('./Example'));

const ExampleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'example',
      element: <Example />,
    },
  ],
};

export default ExampleConfig;
*/
