import React from 'react'
import styles from './styles.module.scss'
import moment from 'moment'
import { CellEmpty } from 'src/shared/components'
import { Tooltip } from '@mui/material'

interface IProps {
	deadline: {
		deadline: string
	}
	isOverdue?: boolean
}

const TaskDeadline: React.FC<IProps> = (p) => {
	const { deadline, isOverdue } = p

	if (!deadline) return <CellEmpty />

	const date = moment(deadline.deadline)

	return (
		<Tooltip arrow title={isOverdue ? "Задача просрочена" : false} placement="top-start">
			<div className={styles['task-deadline']}>
				<span className={[styles['task-deadline-date'], isOverdue && styles['task-deadline-error']].join((' '))}>{date.isValid() ? date.format('ll') : "-"}</span>
			</div>
		</Tooltip>
	)
}

export default TaskDeadline
