import { useState, useRef, useLayoutEffect, createContext, useContext, useEffect } from 'react'

import styles from './VerticalListVirtualizer.module.css'

const VirtualisedListContext = createContext(null)

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Wraps ALL elements ////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const VirtualizedListWrapper = ({
	totalElements = 0,
	externalScrollableComponent,
	estimatedElementHeight = 100, //estimated element height on first mount
	unvirtualizedElementsLimit = 10, //start point for virtualization
	overflowElements = 3, //additional visible elements above & below visible elements
	onLastIsMounted = undefined, //triggering load more elements
	fixedElementIndex = undefined, //keep element alive while scrolling (use for drag-n-drop)
	autoScrollIsEnabled = false, //Automatic scrolling on top/bottom hover
	spacersSX = undefined, //styles for spacers
	children,
}) => {
	const elementsWrapperRef = useRef(null)
	const topSpacerHeight = useRef(0)
	const bottomSpacerHeight = useRef(0)
	const averagedElementHeight = useRef(estimatedElementHeight)
	const cachedElementsHeight = useRef([])
	const loadedElements = useRef(0)
	const [elementsVisibility, setElementsVisibility] = useState([])
	const keepAliveElement = useRef(fixedElementIndex)
	const [autoScrollValue, setAutoScrollValue] = useState(0)

	const rerenderListElements = () => {
		const scrollTop = externalScrollableComponent.current?.scrollTop
		const scrollHeight = externalScrollableComponent.current?.offsetHeight

		const inProgressElementsVisibility = new Array()
		inProgressElementsVisibility.length = loadedElements.current

		if (loadedElements.current <= unvirtualizedElementsLimit) {
			inProgressElementsVisibility.fill(true)
			topSpacerHeight.current = 0
			bottomSpacerHeight.current = 0
			setElementsVisibility(inProgressElementsVisibility)
			return
		}

		let calculatedFirstElementIndex = 0
		let currentHeight = 0
		for (let elementIndex = 0; elementIndex < loadedElements.current - 1; elementIndex++) {
			currentHeight += cachedElementsHeight.current[elementIndex]
			if (currentHeight > scrollTop) {
				calculatedFirstElementIndex = elementIndex
				break
			}
		}

		let calculatedLastElementIndex =
			calculatedFirstElementIndex + Math.ceil(scrollHeight / averagedElementHeight.current)

		const firstVisibleElementIndex = Math.max(0, calculatedFirstElementIndex - overflowElements)
		const lastVisibleElementIndex = Math.min(loadedElements.current - 1, calculatedLastElementIndex + overflowElements)

		for (let elementIndex = 0; elementIndex < loadedElements.current; elementIndex++) {
			inProgressElementsVisibility[elementIndex] =
				elementIndex >= firstVisibleElementIndex && elementIndex <= lastVisibleElementIndex
		}

		/////////////////// Calculating top & bottom spacers heights ////////////////////
		topSpacerHeight.current = 0
		bottomSpacerHeight.current = 0

		for (let elementIndex = 0; elementIndex < firstVisibleElementIndex; elementIndex++) {
			topSpacerHeight.current += cachedElementsHeight.current[elementIndex]
		}
		for (let elementIndex = lastVisibleElementIndex; elementIndex < loadedElements.current - 1; elementIndex++) {
			bottomSpacerHeight.current += cachedElementsHeight.current[elementIndex]
		}
		/////////////////////////////////////////////////////////////////////////////////

		if (keepAliveElement.current !== undefined) {
			inProgressElementsVisibility[keepAliveElement.current] = true
			if (keepAliveElement.current < firstVisibleElementIndex) {
				topSpacerHeight.current -= cachedElementsHeight.current[keepAliveElement.current]
			}
			if (keepAliveElement.current > lastVisibleElementIndex) {
				bottomSpacerHeight.current -= cachedElementsHeight.current[keepAliveElement.current]
			}
		}

		setElementsVisibility(inProgressElementsVisibility)
	}

	useLayoutEffect(() => {
		keepAliveElement.current = fixedElementIndex
	}, [fixedElementIndex])

	const onScrollHandler = () => {
		rerenderListElements()
	}

	const onResizeHandler = () => {
		rerenderListElements()
	}

	useLayoutEffect(() => {
		if (!externalScrollableComponent.current) return
		externalScrollableComponent.current.addEventListener('scroll', onScrollHandler)
		window.addEventListener('resize', onResizeHandler)

		rerenderListElements()
		return () => window.removeEventListener('resize', onResizeHandler)
	}, [externalScrollableComponent.current])

	useLayoutEffect(() => {
		if (!externalScrollableComponent.current?.offsetHeight) return
		rerenderListElements()
	}, [externalScrollableComponent.current?.offsetHeight])

	useEffect(() => {
		if (totalElements === loadedElements.current) return
		const inProgressCachedElementsHeight = new Array()
		inProgressCachedElementsHeight.length = totalElements

		if (totalElements < loadedElements.current) {
			for (let elementIndex = 0; elementIndex < totalElements; elementIndex++) {
				inProgressCachedElementsHeight[elementIndex] = cachedElementsHeight.current[elementIndex]
			}
		}
		if (totalElements > loadedElements.current) {
			for (let elementIndex = 0; elementIndex < totalElements; elementIndex++) {
				inProgressCachedElementsHeight[elementIndex] = cachedElementsHeight.current[elementIndex]
					? cachedElementsHeight.current[elementIndex]
					: averagedElementHeight.current
			}
		}

		loadedElements.current = totalElements
		cachedElementsHeight.current = inProgressCachedElementsHeight

		rerenderListElements()
	}, [totalElements])

	useEffect(() => {
		if (!autoScrollValue) return
		const autoScroller = setInterval(() => {
			externalScrollableComponent.current.scrollTop += autoScrollValue
		}, 20)
		return () => clearInterval(autoScroller)
	}, [autoScrollValue])

	return (
		<VirtualisedListContext.Provider
			value={{
				elementsVisibility,
				averagedElementHeight,
				cachedElementsHeight,
				loadedElements,
				onLastIsMounted,
			}}
		>
			<div ref={elementsWrapperRef} style={{ position: 'relative' }}>
				<div key={'vs_top_spacer'} style={{ height: `${topSpacerHeight.current}px`, ...spacersSX }} />
				{children}
				<div key={'vs_bottom_spacer'} style={{ height: `${bottomSpacerHeight.current}px`, ...spacersSX }} />
			</div>
			{autoScrollIsEnabled && (
				<>
					<div
						style={{ top: '0' }}
						className={styles.side_scroll_area}
						onMouseEnter={() => setAutoScrollValue(-10)}
						onMouseOver={() => setAutoScrollValue(-10)}
						onMouseLeave={() => setAutoScrollValue(0)}
						onMouseOut={() => setAutoScrollValue(0)}
						onMouseUp={() => setAutoScrollValue(0)}
					/>
					<div
						style={{ bottom: '0' }}
						className={styles.side_scroll_area}
						onMouseEnter={() => setAutoScrollValue(10)}
						onMouseOver={() => setAutoScrollValue(10)}
						onMouseLeave={() => setAutoScrollValue(0)}
						onMouseOut={() => setAutoScrollValue(0)}
						onMouseUp={() => setAutoScrollValue(0)}
					/>
				</>
			)}
		</VirtualisedListContext.Provider>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////// Wraps EACH element ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const VirtualizedListElement = ({ elementIndex, children }) => {
	const { elementsVisibility, averagedElementHeight, cachedElementsHeight, loadedElements, onLastIsMounted } =
		useContext(VirtualisedListContext)

	const elementRef = useRef(null)
	const hasBeenMountedBefore = useRef(false)
	const [isMounted, setIsMounted] = useState(false)

	useLayoutEffect(() => {
		setIsMounted(elementsVisibility[elementIndex])
	}, [elementsVisibility[elementIndex]])

	useLayoutEffect(() => {
		if (!isMounted) return

		const elementHeight = elementRef.current?.offsetHeight

		cachedElementsHeight.current[elementIndex] = elementHeight

		if (!hasBeenMountedBefore.current) {
			averagedElementHeight.current = averagedElementHeight.current
				? (averagedElementHeight.current + elementHeight) / 2
				: elementHeight
			if (onLastIsMounted && elementIndex === loadedElements.current - 1) {
				onLastIsMounted()
			}
		}

		hasBeenMountedBefore.current = true
	}, [isMounted])

	return <>{isMounted ? <div ref={elementRef}>{children}</div> : null}</>
}
