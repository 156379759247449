import React, { FC, useEffect } from 'react'
import Autocomplete from '../autocomplete'
import { useGetTaskTypesQuery } from '../../../old-app/service/Tasks'
import { useSelector } from 'react-redux'
import { getPrioritys } from 'src/old-app/store/projectsSlice'
import { useGetPriorityQuery } from '../../../old-app/service/Projects'

interface IProps {
	value: any
	defaultList?: any[]

	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	multiple?: boolean
	className?: string
	projectId: string
}

const TaskPriorityChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Приоритет', isIds, multiple = false, className, projectId, defaultList } = p

	const { data: priorityData } = useGetPriorityQuery({ project_id: projectId }, { skip: !!defaultList })

	const list: any[] = defaultList ? defaultList : priorityData?.data ?? []

	return (
		<Autocomplete
			multiple={multiple}
			placeholder={placeholder}
			className={className}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.name}
			renderOptionLabel={(option: any) => option?.name}
			list={list}
			// onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default TaskPriorityChoice
