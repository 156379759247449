import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isOpen: false,
	component: '',
}

const reducers = {
	setModal: (state, action) => {
		state.isOpen = action.payload
	},
	setComponent: (state, action) => {
		state.component = action.payload
	},
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers,
})

export const getModalIsOpen = (state) => state.modal.isOpen
export const getModalComponent = (state) => state.modal.component

export const modalReducer = modalSlice.reducer
export const modalActions = modalSlice.actions
