import { getStatuses, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import moment from 'moment'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import React from 'react'
import { useSelector } from 'react-redux'
import FieldContent from '../shared/FieldContent'
import GradedTimeStapm from '../shared/GradedTimeStamp'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { Button } from '@mui/material'
import { UserShow } from 'src/shared/components'
import { useTranslation } from 'react-i18next'

const TaskTimeTracker = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const statuses = useSelector(getStatuses)

	const spenTimeInputDisabled = task?.project?.isEnd ? false : task?.isMyTask

	const openDialogBoardCreate = (component) => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(component))
	}

	const editTimeSpentInput = (component, id) => {
		dispatch(tasksActions.getTimeSpentInputId(id))
		openDialogBoardCreate(component)
	}
	const editTimeSpentInputHours = (component, id) => {
		dispatch(tasksActions.getTimeSpentInputHoursId(id))
		openDialogBoardCreate(component)
	}
	const editTimeSpentButton = (component, id) => {
		dispatch(tasksActions.getTimeSpentButtonId(id))
		openDialogBoardCreate(component)
	}

	return (
		<div className="tt-flex tt-flex-col tt-gap-32">
			{task?.time_tracking_by_button?.length !== 0 && (
				<div
					className={['tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden '].join(
						' '
					)}
				>
					<div className="tt-text-14 tt-font-semibold tt-px-10 tt-py-5 tt-bg-gray-200 tt-text-center tt-text-black ">
						{t('by_timer')}
					</div>

					<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
						{task?.spent_time_by_button ? (
							<FieldContent
								className="tt-flex-1"
								content={<GradedTimeStapm timeStamp={task?.spent_time_by_button * 60} />}
								icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
								label={t('time_spent')}
							/>
						) : null}
						<div className="tt-mt-5 tt-flex  tt-flex-col">
							{task?.time_tracking_by_button?.map((item) => {
								return (
									<div className="tt-gap-12 tt-grid tt-grid-cols-2 tt-py-24 tt-border-b-2 tt-border-gray-300">
										{item?.start && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.start).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('start_time')}
											/>
										)}

										{item?.stop && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.stop).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('finish_time')}
											/>
										)}
										{item?.spent_time ? (
											<FieldContent
												className="tt-flex-1"
												content={<GradedTimeStapm timeStamp={item?.spent_time * 60} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('the_time')}
											/>
										) : null}
										{item?.user ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<UserShow user={item?.user} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('user')}
											/>
										) : null}

										{item?.stop && spenTimeInputDisabled && (
											<Button
												onClick={() => editTimeSpentButton('spent-time-button', item?.id)}
												startIcon={
													<svg
														className="tt-stroke-gray-700"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
															strokeWidth="2"
															stroke="#94A3B8"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												}
												className="tt-border-gray-200 tt-inline-flex tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
												variant="outlined"
											>
												{t('change')}
											</Button>
										)}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}
			{task?.time_tracking_by_input?.length !== 0 && (
				<div
					className={['tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden '].join(
						' '
					)}
				>
					<div className="tt-text-14 tt-font-semibold tt-px-10 tt-py-5 tt-bg-gray-200 tt-text-center tt-text-black ">
						{t('manually_entered')}
					</div>

					<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
						{task?.spent_time_by_input ? (
							<FieldContent
								className="tt-flex-1"
								content={<GradedTimeStapm timeStamp={task?.spent_time_by_input * 60} />}
								icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
								label={t('time_spent')}
							/>
						) : null}
						<div className="tt-mt-5 tt-flex  tt-flex-col">
							{task?.time_tracking_by_input?.map((item) => {
								return (
									<div className="tt-gap-12 tt-grid tt-grid-cols-2 tt-py-24 tt-border-b-2 tt-border-gray-300">
										{item?.start && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.start).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('start_time')}
											/>
										)}

										{item?.stop && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.stop).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('finish_time')}
											/>
										)}
										{item?.spent_time ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<GradedTimeStapm timeStamp={item?.spent_time * 60} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('the_time')}
											/>
										) : null}
										{item?.user ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<UserShow user={item?.user} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('user')}
											/>
										) : null}
										{spenTimeInputDisabled && (
											<Button
												onClick={() => editTimeSpentInput('spent-time-input', item?.id)}
												startIcon={
													<svg
														className="tt-stroke-gray-700"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
															strokeWidth="2"
															stroke="#94A3B8"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												}
												className="tt-border-gray-200 tt-inline-flex tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
												variant="outlined"
											>
												{t('change')}
											</Button>
										)}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}
			{task?.time_tracking_by_input_hours?.length !== 0 && (
				<div
					className={['tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden '].join(
						' '
					)}
				>
					<div className="tt-text-14 tt-font-semibold tt-px-10 tt-py-5 tt-bg-gray-200 tt-text-center tt-text-black ">
						{t('in_hours_manually_entered')}
					</div>

					<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
						{task?.spent_time_by_input_hours ? (
							<FieldContent
								className="flex-1"
								content={<GradedTimeStapm timeStamp={task?.spent_time_by_input_hours * 60} />}
								icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
								label={t('time_spent')}
							/>
						) : null}
						<div className="tt-mt-5 tt-flex  tt-flex-col">
							{task?.time_tracking_by_input_hours?.map((item) => {
								return (
									<div className="tt-gap-12 tt-grid tt-grid-cols-2 tt-py-24 tt-border-b-2 tt-border-gray-300">
										{item?.start && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.start).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('start_time')}
											/>
										)}

										{item?.stop && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.stop).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('finish_time')}
											/>
										)}
										{item?.spent_hours ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<GradedTimeStapm timeStamp={item?.spent_hours * 60} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('the_time')}
											/>
										) : null}
										{item?.user ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<UserShow user={item?.user} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('user')}
											/>
										) : null}
										{spenTimeInputDisabled && (
											<Button
												onClick={() => editTimeSpentInputHours('spent-time-input-hours', item?.id)}
												startIcon={
													<svg
														className="tt-stroke-gray-700"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
															strokeWidth="2"
															stroke="#94A3B8"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												}
												className="tt-border-gray-200 tt-inline-flex tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
												variant="outlined"
											>
												{t('change')}
											</Button>
										)}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}
			{task?.time_tracking_by_column?.length !== 0 && (
				<div
					className={['tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden '].join(
						' '
					)}
				>
					<div className="tt-text-14 tt-font-semibold tt-px-10 tt-py-5 tt-bg-gray-200 tt-text-center tt-text-black ">
						{t('in_each_status')}
					</div>

					<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
						{task?.spent_time_by_column ? (
							<FieldContent
								className="tt-flex-1"
								content={<GradedTimeStapm timeStamp={task?.spent_time_by_column * 60} />}
								icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
								label={t('time_spent')}
							/>
						) : null}
						<div className="tt-mt-5 tt-flex  tt-flex-col ">
							{task?.time_tracking_by_column?.map((item) => {
								return (
									<div className="tt-gap-12 tt-grid tt-grid-cols-2 tt-py-24 tt-border-b-2 tt-border-gray-300">
										{item?.status_id ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={
													<div
														style={{
															background: statuses?.find((status) => status?.id === item?.status_id)?.color,
														}}
														className="tt-rounded-full tt-px-6 tt-py-1 tt-text-12 tt-text-white"
													>
														{statuses?.find((status) => status?.id === item?.status_id)?.status}
													</div>
												}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('status')}
											/>
										) : null}
										{item?.start && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.start).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('start_time')}
											/>
										)}

										{item?.stop && (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={moment(item?.stop).format('LLL')}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('finish_time')}
											/>
										)}
										{item?.user ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<UserShow user={item?.user} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('user')}
											/>
										) : null}
										{item?.spent_time ? (
											<FieldContent
												className="tt-flex-1 tt-text-14"
												content={<GradedTimeStapm timeStamp={item?.spent_time * 60} />}
												icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
												label={t('the_time')}
											/>
										) : null}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default TaskTimeTracker
