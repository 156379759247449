import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import Profile from 'src/old-app/main/profile/Profile'
import Teams from 'src/old-app/main/teams/Teams'
import Protocol from 'src/old-app/main/protocol/Protocol'
import ProtocolCreate from 'src/old-app/main/protocol/ProtocolCreate'
import Task from 'src/old-app/main/tasks/Task'
import StartMyProject from 'src/old-app/main/tasks/StartMyProject'
import Projects from 'src/old-app/main/projects/Projects'
import AnalysisViolations from 'src/old-app/main/analysisViolations/AnalysisViolations'
import { ProjectTemplateListPage, ProjectTemplateShowPage } from 'src/pages/project'
import ProjectShow from '../../pages/project/project-show'
import TeamsRequest from '../../pages/team/teams-request'
import TeamShow from '../../pages/team/team-show'
import ProjectRequests from '../../pages/project/project-request'
import PrivatePage from '../../shared/components/private-page'
import { Error404Page } from '../../pages/errors'
import My from 'src/old-app/main/my/My'
import { PATHS } from 'src/shared/lib/navigation'
import { ProjectContractDirectoryPage } from 'src/@pages/project'
import ProjectBoardPublic from '../../pages/project/project-board-public'
import { publicBoardUrl, publicConst } from '../../shared/config'
import rolesType from 'src/old-app/constants/rolesType'

export const RoutesApp: React.FC = () => {
	const app = useRoutes([
		{
			path: '/my',
			element: <My />,
			children: [
				{
					path: ':projectId/:taskId',
					element: <My />,
				},
			],
		},
		{
			path: 'profile',
			element: <Profile />,
		},
		{
			path: 'team',
			element: <Teams />,
		},
		{
			path: 'teams/show/:teamId',
			element: <Teams />,
		},
		{
			path: 'team/:teamId',
			element: <TeamShow />,
			children: [
				{
					path: ':typeSlug',
					element: <TeamShow />,
					children: [
						{
							path: ':projectId/:taskId',
							element: <TeamShow />,
						},
						{
							path: ':projectId',
							element: <TeamShow />,
						},
					],
				},
			],
		},
		{
			path: 'protocol',
			element: <Protocol />,
			children: [
				{
					path: ':protocolId',
					element: <Protocol />,
					children: [
						{
							path: ':projectId/:taskId',
							element: <Protocol />,
						},
					],
				},
			],
		},
		{
			path: 'protocol/create',
			element: <ProtocolCreate />,
		},
		{
			path: 'task/:taskId',
			element: <Task />,
		},
		{
			path: 'projects/:projectId/start',
			element: <StartMyProject />,
		},
		{
			path: 'projects-templates',
			element: <ProjectTemplateListPage />,
		},
		{
			path: 'projects-templates/:templateID',
			element: <ProjectTemplateShowPage />,
		},
		{
			path: 'projects-templates/:templateID/:taskId',
			element: <ProjectTemplateShowPage />,
		},
		{
			path: 'project',
			element: <Projects />,
			children: [
				{
					path: 'show/:projectId',
					element: <Projects />,
				},
			],
		},
		{
			path: 'project-requests',
			element: (
				<PrivatePage roles={['ADMIN']}>
					<ProjectRequests />
				</PrivatePage>
			),
			children: [
				{
					path: 'show/:projectId',
					element: (
						<PrivatePage roles={['ADMIN']}>
							<ProjectRequests />
						</PrivatePage>
					),
				},
			],
		},
		{
			path: 'project/:projectId',
			element: <ProjectShow />,
			children: [
				{
					path: ':typeSlug',
					element: <ProjectShow />,
					children: [
						{
							path: ':taskId',
							element: <ProjectShow />,
						},
						{
							path: 'c/:contractTypeId',
							element: <ProjectShow />,
						},
						{
							path: 'p/:subProjectId',
							element: <ProjectShow />,
						},
						{
							path: 'pr/:protocolId',
							element: <ProjectShow />,
						},
					],
				},
			],
		},
		{
			path: 'team-requests',
			element: (
				<PrivatePage roles={['ADMIN']}>
					<TeamsRequest />
				</PrivatePage>
			),
			children: [
				{
					path: ':teamId',
					element: (
						<PrivatePage roles={['ADMIN']}>
							<TeamsRequest />
						</PrivatePage>
					),
				},
			],
		},
		{
			path: 'analysis-violations',
			element: (
				<PrivatePage roles={[rolesType.ADMIN]}>
					<AnalysisViolations />
				</PrivatePage>
			),
			children: [
				{
					path: ':projectId/:taskId',
					element: (
						<PrivatePage roles={['ADMIN']}>
							<AnalysisViolations />
						</PrivatePage>
					),
				},
			],
		},
		{
			path: '404',
			element: <Error404Page />,
		},
		{
			path: '/',
			element: <Navigate to="/my" />,
		},
		// {
		// 	path: '*',
		// 	element: <Navigate to="/404" />,
		// },
	])

	return app
}

export const PublicRoutesApp: React.FC = () => {
	const app = useRoutes([
		{
			path: publicBoardUrl,
			element: <ProjectBoardPublic />,
			children: [
				{
					path: ':taskId',
					element: <ProjectBoardPublic />,
				},
			],
		},
		{
			path: `${publicConst}/404`,
			element: <Error404Page />,
		},
		// {
		// 	path: '*',
		// 	element: <Navigate to="/404" />,
		// },
	])

	return app
}
