import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import TasksProvider from '../../../old-app/main/tasks/TasksProvider'
import TaskSideBarCheck from '../../../old-app/components/task/TaskSideBarCheck'
import TaskSideBar from '../../../old-app/components/task/TaskSideBar'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import {
	getProject,
	getProjectBARole,
	getProjectManagerRole,
	getProjectUserRole,
} from 'src/old-app/store/projectsSlice'
import { useSelector } from 'react-redux'
import { useGetUser } from 'src/shared/hooks'
import { ProjectContent, ProjectShowContext, useProjectShow } from 'src/entities/project'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import ProjectSideBarCheck from '../../../old-app/components/project/ProjectSideBarCheck'
import { ContractDirectorySideBar } from '../../../@widgets/project'
import ProtocolSideBar from 'src/old-app/components/protocol/side/ProtocolSideBar'
import ProtocolSideBarCheck from 'src/old-app/components/protocol/side/ProtocolSideBarCheck'

const ProjectShow = () => {
	const { projectId, typeSlug, subProjectId, taskId, contractTypeId, protocolId } = useParams()
	const { search } = useLocation()

	const projectShow = useProjectShow()
	const isAdminProjectContract = useSelector(getAdminProjectContractRole)
	const isAdmin = useSelector(getAdminRole)

	const page = `/project/${projectId}`
	const link = `${page}/${typeSlug}${search}`
	const pageLink = `${page}/${typeSlug}${search}`

	const param = contractTypeId ? 'contractTypeId' : subProjectId ? 'subProjectId' : protocolId ? 'protocolId' : 'taskId'

	return (
		<TasksProvider>
			<PageWithSideBar
				param={param}
				contentClassName={'!tt-pt-0 !tt-pr-0 !tt-pl-0 !tt-pb-0 !tt-h-full !tt-px-0'}
				pageLink={pageLink.slice(1, pageLink.length)}
				content={<ProjectContent page={page} />}
				rightSidebarContent={
					<>
						{taskId && (
							<TaskSideBarCheck>
								<TaskSideBar backTo={link} />
							</TaskSideBarCheck>
						)}
						{subProjectId && <ProjectSideBarCheck paramId={"subProjectId"} backTo={`${page}/${typeSlug}`} />}
						{contractTypeId && (
							<ContractDirectorySideBar
								isManage={isAdminProjectContract || isAdmin}
								paramId={param}
								backTo={`${page}/${typeSlug}`}
							/>
						)}
						{protocolId && (
							<ProtocolSideBarCheck>
								<ProtocolSideBar paramId={param} backTo={`${page}/protocols`} />
							</ProtocolSideBarCheck>
						)}
					</>
				}
			/>
		</TasksProvider>
	)
}

export default ProjectShow
