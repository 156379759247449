import React from 'react'
import styles from './styles.module.scss'

interface IProps {
	text: string
}

const EmptyPlaceholder: React.FC<IProps> = ({ text }) => {
	return <div className={styles['empty-placeholder']}>{text}</div>
}

export default EmptyPlaceholder
