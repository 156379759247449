const getMentionIds = (text) => {
	let ids = []
	let ids1 = []
	let ids2 = []
	for (let i = 0; i < text.length; i++) {
		const item = text[i]
		if (item === '(') {
			ids1.push(i + 1)
		}
	}
	for (let i = 0; i < text.length; i++) {
		const item = text[i]
		if (item === ')') {
			ids2.push(i)
		}
	}

	for (let i = 0; i < ids1.length; i++) {
		const item1 = ids1[i]
		const item2 = ids2[i]
		ids.push(text.slice(item1, item2))
	}
	return ids
}

export default getMentionIds
