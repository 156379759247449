import { createSlice } from '@reduxjs/toolkit'
import rolesType from '../constants/rolesType'

const initialState = {
	projects: [],
	projectsUsers: [],
	pUsers: [],
	project: null,
	templateData: null,
	tags: [],
	users: [],
	roles: [],
	prioritys: [],
	priority: null,
}

const reducers = {
	getProjects: (state, action) => {
		state.projects = action.payload
	},

	getProjectTags: (state, action) => {
		state.tags = action.payload
	},
	getPrioritys: (state, action) => {
		state.prioritys = action.payload
	},
	deletePriority: (state, action) => {
		state.prioritys = state.prioritys.filter((item) => item.id !== action.payload)
	},

	updatePriority: (state, action) => {
		state.prioritys = state.prioritys.map((item) => {
			if (item.id === action.payload.id) {
				item = action.payload
			}
			return item
		})
	},

	addPriority: (state, action) => {
		state.prioritys = [action.payload, ...state.prioritys]
	},
	getPriority: (state, action) => {
		state.priority = action.payload
	},
	getProjectsUsers: (state, action) => {
		state.projectsUsers = action.payload
	},
	getProjectRoles: (state, action) => {
		state.roles = action.payload
	},
	getPUsers: (state, action) => {
		state.pUsers = action.payload
	},
	getProject: (state, action) => {
		state.projects = [action.payload, ...state.projects]
	},
	getProjectUser: (state, action) => {
		state.projectsUsers = [action.payload, ...state.projectsUsers]
	},
	getOneProject: (state, action) => {
		state.project = action.payload
	},
	deleteProject: (state, action) => {
		state.projects = state.projects.filter((item) => item.id !== action.payload)
	},
	deleteProjectUser: (state, action) => {
		state.projectsUsers = state.projectsUsers.filter((item) => item.project_id !== action.payload)
	},
	getUsers: (state, action) => {
		state.users = action.payload
	},
	addUsers: (state, action) => {
		state.users = [...state.users, action.payload]
	},
	deleteUsers: (state, action) => {
		state.users = state.users.filter((item) => item.user_id !== action.payload)
	},
	updateProject: (state, action) => {
		state.project = { ...state.project, ...action.payload }
		state.projects = state.projects.map((item) => {
			if (item.id === action.payload.id) {
				item = { ...item, ...action.payload }
			}
			return item
		})
	},
	getProjectTemplateData: (state, action) => {
		state.templateData = action.payload
	},
	editProjectTemplateTask: (state, action) => {
		state.templateData.template.Tasks = state.templateData?.template?.Tasks?.map((task) => {
			if (task.id === action.payload.id) {
				task = { ...task, ...action.payload }
			}
			return task
		})
	},
	putProjectTemplateTasks: (state, action) => {
		state.templateData.template.Tasks = action.payload
	},
}

const projectsSlice = createSlice({
	name: 'post',
	initialState,
	reducers,
})

export const getProjects = (state) => state.projects.projects
export const getProjectsUsers = (state) => state.projects.projectsUsers
export const getPUsers = (state) => state.projects.pUsers
export const getProjectAdminRole = (state) => state.projects.roles.some((item) => item === rolesType.ADMIN)
export const getProjectManagerRole = (state) => state.projects.roles.some((item) => item === rolesType.PROJECT_MANAGER)
export const getProjectUserRole = (state) => state.projects.roles.some((item) => item === rolesType.USER)
export const getProjectAdminContractRole = (state) =>
state.projects.roles.some((item) => item === rolesType.ADMIN_PROJECT_CONTRACT)
export const getProjectBARole = (state) => state.projects.roles.some((item) => item === rolesType.BA)
export const getProjectCuratorRole = (state) => state.projects.roles.some((item) => item === rolesType.CURATOR)
export const getProject = (state) => state.projects.project
export const getUsers = (state) => state.projects.users
export const getProjectTemplateData = (state) => state.projects.templateData
export const getPrioritys = (state) => state.projects.prioritys
export const getProjectTags = (state) => state.projects.tags
export const getPriority = (state) => state.projects.priority

export const projectsReducer = projectsSlice.reducer
export const projectsActions = projectsSlice.actions
