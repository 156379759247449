/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Checkbox, FormControlLabel, TextareaAutosize, TextField } from '@mui/material'
import { getSubs, getTask, getTaskTypes, tasksActions } from 'src/old-app/store/tasksSlice'
import React, { memo, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	getProject,
	getProjectUserRole,
	getProjects,
	getProjectsUsers,
	projectsActions,
	getProjectTemplateData,
} from 'src/old-app/store/projectsSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useCreateTaskHashtagLinkMutation,
	useCreateTaskSubscriberMutation,
	useDeleteTaskHashtagMutation,
	useDeleteTaskSubscriberMutation,
	useGetTaskSubscriberQuery,
	useUpdateTaskMutation,
} from 'src/old-app/service/Tasks'
import ErrorText from '../ui/ErrorText'
import MySelect from '../ui/MySelect'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import AddIcon from '@mui/icons-material/Add'
import UserCell from '../tables/UserCell'
import UsersSelect from '../shared/UserSelect'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getOneUser, useGetOneUserQuery } from 'src/old-app/service/Users'
import MentionDescription from './MentionDescription'
import getMentionIds from 'src/old-app/utils/getMentionIds'
import ProjectUsersSelect from './ProjectUsersSelect'
import { getUserRole } from 'src/old-app/store/userSlice'
import getUser from 'src/old-app/utils/getUser'
import { myActions } from 'src/old-app/store/mySlice'
import MD from '../dialogs/task/MD'
import TaskHashtagSelect from '../shared/TaskHashtagSelect'
import TaskParams from '../dialogs/task/TaskParams'
import { Autocomplete, InputContainer } from 'src/shared/components'
import TaskTypesChoice from '../../../shared/components/task-types-choice'
import TaskTagsChoice from '../../../shared/components/task-tags-choice'
import { useTranslation } from 'react-i18next'

const typeLabelRender = (value) => {
	return value.name
}

const TaskHashtags = memo(({ value, onChange, setValue, deleteHashtags, task }) => {
	const [curHashtags, setCurHashtags] = useState([])

	const deleteHashtag = (item) => {
		setCurHashtags(curHashtags.filter(({ id }) => id !== item.id))
		setValue('deleteHashtags', [...deleteHashtags, item])
	}

	useEffect(() => {
		if (task.hashtags) {
			setCurHashtags(task.hashtags)
		}
	}, [task])

	if (!task.hashtags) return null

	const getHashtags = task.hashtags
	const filterHashtags = (arr) => arr.filter((item) => !getHashtags.find((hastag) => hastag?.id === item?.id))

	return (
		<div className="tt-w-full tt-flex tt-flex-col tt-gap-2">
			<TaskHashtagSelect
				filterHashtags={filterHashtags}
				multiple
				displayEmpty
				field="hastags"
				label="Теги"
				onChange={onChange}
				value={value}
			/>
			<div className="tt-flex tt-gap-4 tt-flex-wrap">
				{curHashtags.map((hashtag) => (
					<div
						key={hashtag.id}
						className="tt-h-32 tt-px-8 tt-py-6 tt-bg-gray-200 tt-rounded-full tt-inline-flex tt-items-center tt-gap-4 tt-pl-16"
					>
						<div className="tt-text-12 tt-font-medium">{hashtag.hashtag}</div>
						<div onClick={() => deleteHashtag(hashtag)} className="tt-cursor-pointer">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM7 9C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H7Z"
									fill="#64748B"
								/>
							</svg>
						</div>
					</div>
				))}
			</div>
		</div>
	)
})

const ProjectTemplateEditTask = ({ task, setIsEdit }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
		assignee: yup.object().required(t('performer_is_required')).nullable(),
		// description: yup.string().required('Введите описание проекта'),
		// subscribers: yup.array(),
		// // task_priority_id: yup.string().required('Вы должны выбрать task_priority_id'),
		// deadline: yup.string().required('Указажите дедлайн'),
		// time_spent: yup.string().required('Укажите оценку времени'),
	})

	const taskTypes = useSelector(getTaskTypes)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const projectTempalteData = useSelector(getProjectTemplateData)
	const params = useParams()
	const [isDisabled, setIsDisabled] = useState(false)
	const {
		setValue,
		handleSubmit,
		control,
		register,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			assignee: null,
			type: null,
			deleteHashtags: [],
			hashtags: [],
			subscribers: [],
			params: [],
			need_report: false,
		},
		resolver: yupResolver(schema),
	})

	const deleteHashtags = watch('deleteHashtags')
	const needReport = watch('need_report')

	const users = projectTempalteData?.template?.Users?.map((user) => user.user) ?? []

	const setValues = async () => {
		setValue('title', task.title)
		setValue('subscribers', task?.subscribers?.map((item) => item.id) ?? [])
		setValue('description', task?.description ?? '')
		setValue('hashtags', task?.hashtags ?? [])
		setValue('result_representation', task?.result_representation ?? '')

		setValue('assignee', task?.assignee)
		setValue('need_report', task?.need_report ?? false)
		setValue('type', task?.type ? task?.type : taskTypes?.find((t) => t?.id === task?.type_id))

		const paramsArr = []
		Object.entries(task.params).map(([label, value], index) => {
			paramsArr.push({ id: index + 1, order: index + 1, label, value })
		})
		setValue('params', paramsArr)
	}

	useEffect(() => {
		if (task) {
			setValues()
		}
	}, [task])

	const closeEdit = () => {
		setIsEdit(false)
	}

	const updateTaskHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		setIsDisabled(true)

		let hashtags = [...task?.hashtags]

		const newTags = data.hashtags ?? []
		const oldTags = task?.hashtags ?? []

		const createTags = newTags.filter(
			({ id: newUserId }) => !oldTags.find(({ id: oldUserId }) => oldUserId === newUserId)
		)
		const deleteTags = oldTags.filter(
			({ id: oldUserId }) => !newTags.find(({ id: newUserId }) => newUserId === oldUserId)
		)

		createTags.map((hashtag) => {
			hashtags.push(hashtag)
		})

		deleteTags?.map((hashtag) => {
			hashtags = hashtags.filter((item) => item.id !== hashtag.id)
		})

		const myReduceParams = data.params
			.filter((item) => item.label && item.value)
			.reduce((acc, object) => {
				const field = object.label
				acc[field] = object.value

				return acc
			}, {})

		const body = {
			title: data.title,
			description: data?.description,
			params: myReduceParams,
			hashtags,
			type_id: data?.type?.id,
			assignee_id: data?.assignee?.id ?? undefined,
			assignee: data?.assignee ?? undefined,
			type: data?.type,
			result_representation: data?.result_representation,
			id: task.id,
		}
		dispatch(projectsActions.editProjectTemplateTask(body))

		dispatch(
			showMessage({
				message: t('successfully_updated.task'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
		closeEdit()
	}

	return (
		<form onSubmit={handleSubmit(updateTaskHandler)}>
			<div className="tt-flex tt-gap-10 tt-mb-20 tt-flex-col">
				<div className="tt-flex tt-flex-col tt-w-full">
					<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>
					<TextField
						{...register('title')}
						error={!!errors.title}
						placeholder={t('title')}
						className="dialig-input"
						size="small"
					/>
					<ErrorText errors={errors} field="title" />
				</div>

				<InputContainer label={t('type')} error={errors?.type}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskTypesChoice placeholder={t('type')} value={value} onChange={onChange} />
						)}
						name="type"
						control={control}
					/>
				</InputContainer>

				<div className="tt-flex tt-flex-col tt-w-full">
					<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('description')}</span>

					<Controller
						render={({ field: { onChange, value } }) => <MD value={value} onChange={onChange} />}
						name="description"
						control={control}
					/>
					<ErrorText errors={errors} field="description" />
				</div>

				<div className="tt-flex tt-flex-col tt-w-full">
					<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('expected_results')}</span>

					<TextField
						multiline
						{...register('result_representation')}
						error={!!errors.result_representation}
						placeholder={t('expected_results')}
						className="dialig-input"
						size="small"
					/>
					<ErrorText errors={errors} field="result_representation" />
				</div>

				{/* <div className="flex w-full items-start justify-start">
					{project?.with_administration || (!project?.with_administration && task?.need_report) ? (
						<Controller
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									disabled={!!isUser}
									id="task-need_report"
									control={<Checkbox disabled={!!isUser} checked={value} onChange={onChange} />}
									label="Обязательный отчёт для выполнения задачи"
								/>
							)}
							name="need_report"
							control={control}
						/>
					) : null}
				</div> */}

				<InputContainer label={t('tags')} error={errors?.hashtags}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskTagsChoice hasCreate={false} projectId={task?.project?.id ?? task?.project_id} placeholder={t('tags')} value={value} onChange={onChange} />
						)}
						name="hashtags"
						control={control}
					/>
				</InputContainer>
				<InputContainer label={t('performer')} error={errors?.assignee}>
					<Controller
						render={({ field: { value, onChange } }) => (
							<Autocomplete
								multiple={false}
								placeholder={t('performer')}
								optionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
								renderOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
								list={users}
								value={value}
								onChange={onChange}
							/>
						)}
						name="assignee"
						control={control}
					/>
				</InputContainer>

				<Controller
					render={({ field: { value, onChange } }) => <TaskParams errors={errors} value={value} onChange={onChange} />}
					name="params"
					control={control}
				/>
			</div>
			<div className="tt-flex tt-gap-12 tt-items-center">
				<Button onClick={closeEdit} variant="outlined" className="tt-border-gray-200   tt-min-h-32 tt-h-32 tt-px-16">
					{t('cancel')}
				</Button>
				<Button
					type="submit"
					variant="contained"
					className="tt-bg-primary hover:tt-bg-primary-600  tt-text-white tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('save')}
				</Button>
			</div>
		</form>
	)
}

export default ProjectTemplateEditTask
