import React from 'react'
import styles from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { IconButton } from '@mui/material'

interface IProps {
	parentId: string
	taskId: string
	parentTitle?: string
}

const TaskToParent: React.FC<IProps> = ({ taskId, parentId, parentTitle }) => {
	const { pathname, search } = useLocation()

	const to = pathname.replace(taskId, parentId) + search

	return (
		<Link to={to}>
			<div className="tt-flex tt-items-center tt-gap-12">
				<IconButton>
					<ArrowUpwardIcon className="tt-w-20 tt-h-20 tt-text-gray-500" />
				</IconButton>
				<p style={{ color: '#5d5c69' }} className="tt-font-medium text-hyphens tt-line-clamp-2">
					{parentTitle}
				</p>
			</div>
		</Link>
	)
}

export default TaskToParent
