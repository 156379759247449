import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'src/app/store'
import { User } from 'src/@entities/session'

// TODO: попробовать вынести
export interface RootState {
	user: User
}
export const initialState: RootState = {
	user: {} as User,
}

const reducers = {
	setUser: (state: RootState, action: PayloadAction<User>) => {
		state.user = action.payload
	},
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers,
})

// TODO: попробовать вынести
export const getUser = (state: AppState) => state.user.user
export const getUserName = (state: AppState) => state.user?.user?.name

export const userReducer = userSlice.reducer
export const userActions = userSlice.actions
