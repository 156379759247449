import React from 'react'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import FieldContent from '../shared/FieldContent'
import UserCellShow from '../tables/UserCellShow'
import { AssignmentLateOutlined, TaskAltOutlined } from '@mui/icons-material'

//{
//     "Commands": [],
//     "Users": [
//         {
//             "project_id": "",
//             "user_id": "4b2ab86d-4a77-4a4a-a09c-cc84d9c63b92",
//             "user": {
//                 "id": "4b2ab86d-4a77-4a4a-a09c-cc84d9c63b92",
//                 "created_at": "2022-11-16T13:46:49Z",
//                 "updated_at": "2022-11-16T13:46:49Z",
//                 "deleted_at": null,
//                 "user_name": "",
//                 "keycloak_id": "4b2ab86d-4a77-4a4a-a09c-cc84d9c63b92",
//                 "first_name": "admin",
//                 "last_name": "admin",
//                 "middle_name": null,
//                 "position": null,
//                 "email": "admin1@efko.ru",
//                 "avatar": null,
//                 "avatar96": null,
//                 "avatar256": null
//             },
//             "project": {
//                 "id": "",
//                 "created_at": "0001-01-01T00:00:00Z",
//                 "updated_at": "0001-01-01T00:00:00Z",
//                 "deleted_at": null,
//                 "name": "",
//                 "project_tree": "",
//                 "complete_at": null,
//                 "is_approved": false,
//                 "comment": null,
//                 "actual_deadline": null,
//                 "owner_id": "",
//                 "owner": null,
//                 "slug": "",
//                 "parent_id": null,
//                 "child_projects": null,
//                 "hashtags": null,
//                 "need_time_tracking_by_button": false,
//                 "need_time_tracking_by_column": false,
//                 "need_time_tracking_by_input": false,
//                 "need_time_tracking_by_input_hours": false,
//                 "each_member_can_create_task": false,
//                 "confirmation_project_manager": null,
//                 "confirmation_ba": null,
//                 "with_administration": false
//             },
//             "role": "PROJECT_MANAGER",
//             "confirms": true
//         },
//         {
//             "project_id": "",
//             "user_id": "6552ef0d-7328-4261-a4b3-2a115dfdea0a",
//             "user": {
//                 "id": "6552ef0d-7328-4261-a4b3-2a115dfdea0a",
//                 "created_at": "2022-11-16T13:46:47Z",
//                 "updated_at": "2022-11-16T13:46:47Z",
//                 "deleted_at": null,
//                 "user_name": "",
//                 "keycloak_id": "6552ef0d-7328-4261-a4b3-2a115dfdea0a",
//                 "first_name": "Артём",
//                 "last_name": "Матвеев",
//                 "middle_name": null,
//                 "position": null,
//                 "email": "a.a.matveev@efko.ru",
//                 "avatar": null,
//                 "avatar96": null,
//                 "avatar256": null
//             },
//             "project": {
//                 "id": "",
//                 "created_at": "0001-01-01T00:00:00Z",
//                 "updated_at": "0001-01-01T00:00:00Z",
//                 "deleted_at": null,
//                 "name": "",
//                 "project_tree": "",
//                 "complete_at": null,
//                 "is_approved": false,
//                 "comment": null,
//                 "actual_deadline": null,
//                 "owner_id": "",
//                 "owner": null,
//                 "slug": "",
//                 "parent_id": null,
//                 "child_projects": null,
//                 "hashtags": null,
//                 "need_time_tracking_by_button": false,
//                 "need_time_tracking_by_column": false,
//                 "need_time_tracking_by_input": false,
//                 "need_time_tracking_by_input_hours": false,
//                 "each_member_can_create_task": false,
//                 "confirmation_project_manager": null,
//                 "confirmation_ba": null,
//                 "with_administration": false
//             },
//             "role": "USER",
//             "confirms": false
//         }
//     ],
//     "Priorities": [],
//     "Statuses": [
//         {
//             "id": "28d49fb7-febb-4b20-abb4-89bb6d0c00cb",
//             "created_at": "2023-01-18T09:48:03Z",
//             "updated_at": "2023-01-18T09:48:03Z",
//             "deleted_at": null,
//             "status": "К выполнению",
//             "project_id": "",
//             "order": 1,
//             "is_final": false,
//             "is_request": false,
//             "is_initial": true,
//             "color": "#757575"
//         },
//         {
//             "id": "3d92dd57-eb5f-4c6a-8675-fd57e9c09a30",
//             "created_at": "2023-01-18T09:48:03Z",
//             "updated_at": "2023-01-18T09:48:03Z",
//             "deleted_at": null,
//             "status": "В процессе",
//             "project_id": "",
//             "order": 2,
//             "is_final": false,
//             "is_request": false,
//             "is_initial": false,
//             "color": "#ff8c00"
//         },
//         {
//             "id": "cd356ff6-7ca8-4feb-8bea-1c950afbd6af",
//             "created_at": "2023-01-18T09:48:03Z",
//             "updated_at": "2023-01-18T09:48:03Z",
//             "deleted_at": null,
//             "status": "На проверке",
//             "project_id": "",
//             "order": 3,
//             "is_final": false,
//             "is_request": true,
//             "is_initial": false,
//             "color": "#ff0000"
//         },
//         {
//             "id": "27e8fadd-f6d7-4fe5-8002-a2919cbfd5fb",
//             "created_at": "2023-01-18T09:48:04Z",
//             "updated_at": "2023-01-18T09:48:04Z",
//             "deleted_at": null,
//             "status": "Выполнено",
//             "project_id": "",
//             "order": 4,
//             "is_final": true,
//             "is_request": false,
//             "is_initial": false,
//             "color": "#32cd32"
//         }
//     ],
//     "Tasks": [
//         {
//             "id": "3e182db5-ceae-4c08-82cd-4625fd41193a",
//             "created_at": "2023-01-18T09:48:05Z",
//             "updated_at": "2023-01-18T09:50:05Z",
//             "deleted_at": null,
//             "title": "123",
//             "author": "4b2ab86d-4a77-4a4a-a09c-cc84d9c63b92",
//             "author_view": null,
//             "description": "123",
//             "deadlines": null,
//             "type_id": "3f2c5a52-18a6-478e-bbb5-57271b25f91a",
//             "type": null,
//             "task_priority_id": null,
//             "priority": null,
//             "task_status": null,
//             "assignee_id": null,
//             "assignee": null,
//             "project_id": "1c2f52bc-28bd-47e7-a566-e99d7724ac70",
//             "protocol_id": null,
//             "order": 1,
//             "need_report": false,
//             "complete_file": null,
//             "slug": "e2223456-1",
//             "need_deadline_confirm": false,
//             "params": {},
//             "hashtags": [],
//             "time_tracking_by_button": null,
//             "spent_time_by_button": 0,
//             "time_tracking_by_column": null,
//             "time_tracking_by_input": null,
//             "spent_time_by_input": 0,
//             "time_tracking_by_input_hours": null,
//             "spent_time_by_input_hours": 0,
//             "epic": null,
//             "subscribers": null,
//             "result": "",
//             "actual_deadline_id": null,
//             "actual_deadline": null,
//             "updater_id": null,
//             "updater": null,
//             "subtask_completed": false,
//             "result_representation": "",
//             "hierarchy": "e2223456_1"
//         }
//     ],
//     "Types": null,
//     "NeedTimeTrackingByButton": false,
//     "NeedTimeTrackingByColumn": false,
//     "NeedTimeTrackingByInput": false,
//     "NeedTimeTrackingByInputHours": false,
//     "EachMemberCanCreateTask": false
// }

const ProjectTemplateInfo = ({ templateData }) => {
	const {
		Commands,
		Users,
		Priorities,
		Statuses,
		// Tasks
	} = templateData

	// console.log({ templateData });
	return (
		<div className="tt-w-full tt-flex tt-flex-col gap-24">
			<div className="tt-flex tt-items-start tt-flex-wrap tt-gap-32">
				<div className={'tt-flex tt-w-full tt-gap-48'}>
					<FieldContent
						// className={'flex-grow-1'}
						content={
							<div className="tt-flex tt-flex-col tt-gap-3">
								{Statuses?.map((item) => {
									// const disabled = project?.with_administration ? item?.is_request || item?.is_final || item?.is_initial : false
									// const disabled = item?.is_request || item?.is_final || item?.is_initial;
									return (
										<div key={item.order} className="tt-flex tt-gap-8 group tt-items-center tt-truncate">
											<div className="tt-w-12 tt-h-12 tt-rounded-full" style={{ background: item.color }} />
											<span className="tt-font-medium tt-truncate">{item.status}</span>
											{/*<div className='scale-0 group-hover:scale-100  duration-300 flex items-center gap-4'>*/}

											{/*<EditOutlinedIcon onClick={() => editHandler(item)}*/}
											{/*                  className=' text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20' />*/}

											{/*{!disabled &&*/}
											{/*<DeleteIcon onClick={() => deleteHandler(item)}*/}
											{/*            className='text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20' />}*/}

											{/*</div>*/}
										</div>
									)
								})}
							</div>
						}
						icon={<TaskAltOutlined className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
						label="Статусы"
					/>
					{/*<div>*/}
					{/*<span className='text-16 font-semibold'>Статусы</span>*/}
					{/*<div className='flex flex-col gap-4 mb-8 mt-10'>*/}

					{/*	{Statuses?.map((item) => {*/}
					{/*		// const disabled = project?.with_administration ? item?.is_request || item?.is_final || item?.is_initial : false*/}
					{/*		// const disabled = item?.is_request || item?.is_final || item?.is_initial;*/}
					{/*		return (*/}
					{/*			<div key={item.order} className='flex gap-8 group items-center truncate'>*/}
					{/*				<div className='w-12 h-12 rounded-full' style={{ background: item.color }} />*/}
					{/*				<span className='font-medium truncate'>{item.status}</span>*/}
					{/*				<div className='scale-0 group-hover:scale-100  duration-300 flex items-center gap-4'>*/}

					{/*					/!*<EditOutlinedIcon onClick={() => editHandler(item)}*!/*/}
					{/*					/!*                  className=' text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20' />*!/*/}

					{/*					/!*{!disabled &&*!/*/}
					{/*					/!*<DeleteIcon onClick={() => deleteHandler(item)}*!/*/}
					{/*					/!*            className='text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20' />}*!/*/}

					{/*				</div>*/}
					{/*			</div>*/}
					{/*		);*/}
					{/*	})}*/}
					{/*</div>*/}

					{/*<Button onClick={createHandler} startIcon={<AddIcon />}*/}
					{/*        className='bg-primary/10  text-primary rounded-full  min-h-32 h-32 px-16'>*/}
					{/*	Добавить статус*/}
					{/*</Button>*/}
					{/*</div>*/}

					<FieldContent
						className={'tt-flex-grow-1'}
						content={
							<div className="tt-flex tt-flex-col tt-gap-3">
								{Priorities?.map((item) => (
									<div key={item.level} className="tt-flex tt-gap-8 group tt-items-center ">
										<div className="tt-w-12 tt-h-12 tt-rounded-full " style={{ background: item.color }} />
										<span className="tt-font-medium tt-mr-4 tt-truncate">{item.name}</span>
										<span className="">уровень: {item.level}</span>

										{/*<div className="scale-0 group-hover:scale-100  duration-300 flex items-center gap-4">*/}
										{/*	<EditOutlinedIcon onClick={() => editHandler(item)} className=" text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20" />*/}
										{/*	<DeleteIcon onClick={() => deleteHandler(item)} className=" text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20" />*/}
										{/*</div>*/}
									</div>
								))}
							</div>
						}
						icon={<AssignmentLateOutlined className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
						label="Приоритеты"
					/>

					{/*<div>*/}
					{/*	<span className='text-16 font-semibold'>Приоритеты</span>*/}
					{/*	<div className='flex flex-col gap-4 mb-8 mt-10'>*/}
					{/*		{Priorities?.map((item) => (*/}
					{/*			<div key={item.level} className='flex gap-8 group items-center '>*/}
					{/*				<div className='w-12 h-12 rounded-full ' style={{ background: item.color }} />*/}
					{/*				<span className='font-medium mr-4 truncate'>{item.name}</span>*/}
					{/*				<span className=''>уровень: {item.level}</span>*/}

					{/*				/!*<div className="scale-0 group-hover:scale-100  duration-300 flex items-center gap-4">*!/*/}
					{/*				/!*	<EditOutlinedIcon onClick={() => editHandler(item)} className=" text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20" />*!/*/}
					{/*				/!*	<DeleteIcon onClick={() => deleteHandler(item)} className=" text-gray-500 hover:text-gray-800 cursor-pointer w-20 h-20" />*!/*/}
					{/*				/!*</div>*!/*/}
					{/*			</div>*/}
					{/*		))}*/}
					{/*	</div>*/}
					{/*	/!*<Button onClick={createHandler} startIcon={<AddIcon />} className="bg-primary/10  text-primary rounded-full  min-h-32 h-32 px-16">*!/*/}
					{/*	/!*	Добавить приоритет*!/*/}
					{/*	/!*</Button>*!/*/}
					{/*</div>*/}
				</div>

				{/*<FieldContent*/}
				{/*	content={*/}
				{/*		<Link to={`/projects/${project?.project?.id}/show`}>*/}
				{/*			<span>{project?.project?.name}</span>*/}
				{/*		</Link>*/}
				{/*	}*/}
				{/*	icon={<AccountTreeOutlinedIcon className="fill-gray-600 h-20 w-20"/>}*/}
				{/*	label="Проект"*/}
				{/*/>*/}
				{/*<FieldContent content={*/}
				{/*	<TaskSlug message='Код проект'*/}
				{/*	          isMultiline*/}
				{/*	          slug={project?.slug} />*/}
				{/*}*/}
				{/*              icon={<AccountTreeOutlinedIcon className='fill-gray-600 h-20 w-20' />} label='Код проекта' />*/}
				{/*<FieldContent content={moment(project?.complete_at).format('LL')}*/}
				{/*              icon={<EventOutlinedIcon className='fill-gray-600 w-20 h-20' />}*/}
				{/*              label='Плановая дата завершения' />*/}
				{/*<FieldContent content={project?.owner_id && <UserCell id={project?.owner_id} />}*/}
				{/*              icon={<EventOutlinedIcon className='fill-gray-600 w-20 h-20' />} label='Ответственный' />*/}
				{/*<FieldContent content={moment(project?.created_at).format('LL')}*/}
				{/*              icon={<CalendarMonthOutlinedIcon className='fill-gray-600 w-20 h-20' />}*/}
				{/*              label='Дата создания' />*/}
				{/*<FieldContent content={project?.actual_deadline && moment(project?.actual_deadline).format('LL')}*/}
				{/*              icon={<CalendarMonthOutlinedIcon className='fill-gray-600 w-20 h-20' />}*/}
				{/*              label='Фактический срок' />*/}
				{/*<FieldContent*/}
				{/*	contentClassName='w-full'*/}
				{/*	content={*/}
				{/*		<div className='flex gap-4 flex-wrap'>*/}
				{/*			{project?.hashtags?.map((item) => (*/}
				{/*				<Chip className='truncate' label={item.hashtag} />*/}
				{/*			))}*/}
				{/*		</div>*/}
				{/*	}*/}
				{/*	icon={<TagOutlinedIcon className='fill-gray-600 h-20 w-20' />}*/}
				{/*	label='Теги'*/}
				{/*/>*/}
				<FieldContent
					content={
						<div className="tt-flex tt-flex-col tt-gap-3">
							{Commands?.map((item) => (
								<div className={'tt-font-medium'} key={item.command.id}>
									{item.command.name}
								</div>
							))}
						</div>
					}
					icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
					label="Команды"
				/>
				<FieldContent
					content={
						<div className="tt-flex tt-flex-col tt-gap-3">
							{Users?.map((item) => (
								<div className="tt-flex tt-items-center tt-gap-8">
									<UserCellShow key={item.user_id} user={item.user} />
								</div>
							))}
						</div>
					}
					icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
					label="Участники"
				/>
				{/*{project.confirmation_project_manager && (*/}
				{/*	<FieldContent*/}
				{/*		content={*/}
				{/*			<div className='flex flex-col gap-3'>*/}
				{/*				{project.confirmation_project_manager?.map((item) => (*/}
				{/*					<div className='flex items-center gap-8'>*/}
				{/*						<UserCellShow key={item.user_id} user={item.user} />*/}
				{/*					</div>*/}
				{/*				))}*/}
				{/*			</div>*/}
				{/*		}*/}
				{/*		icon={<GroupOutlinedIcon className='fill-gray-600 w-20 h-20' />}*/}
				{/*		label='Подтверждающие руководители проекта'*/}
				{/*	/>*/}
				{/*)}*/}
				{/*{project.confirmation_ba && (*/}
				{/*	<FieldContent*/}
				{/*		content={*/}
				{/*			<div className='flex flex-col gap-3'>*/}
				{/*				{project.confirmation_ba?.map((item) => (*/}
				{/*					<div className='flex items-center gap-8'>*/}
				{/*						<UserCellShow key={item.user_id} user={item.user} />*/}
				{/*					</div>*/}
				{/*				))}*/}
				{/*			</div>*/}
				{/*		}*/}
				{/*		icon={<GroupOutlinedIcon className='fill-gray-600 w-20 h-20' />}*/}
				{/*		label='Подтверждающие БА'*/}
				{/*	/>*/}
				{/*)}*/}
			</div>
			{/* {project?.id && <ProjectMyTasks projectId={project.id} />} */}
			{/*{project?.child_projects && <ProjectChildProjects projects={project?.child_projects} />}*/}
		</div>
	)
}

// ProjectInfo.displayName = 'ProjectInfo'
export default ProjectTemplateInfo
