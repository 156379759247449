import styles from './ProjectProtocolsTableHeaderCell.module.css'

const ProjectProtocolsTableHeaderCell = ({ canGrow, basicWidth, component }) => {
	return (
		<div className={styles.cell_wrapper} style={{ flexGrow: canGrow ? '1' : '0', width: basicWidth }}>
			{component}
		</div>
	)
}

export default ProjectProtocolsTableHeaderCell
