const tasksStructure = (obj) => {
	if (!obj) return []
	var structure = {}
	var tasksMap = {}
	obj.forEach((element) => {
		tasksMap[`${element.id}`] = element
		if (element.parent_id !== null) {
			if (!structure[`${element.parent_id}`]) {
				structure[`${element.parent_id}`] = []
			}
			structure[`${element.parent_id}`]?.push(element.id)
		}
	})

	var resultTask = []
	function makeStructure(item) {
		if (structure[`${item.id}`]) {
			item['child'] = []
			structure[`${item.id}`].forEach((element) => {
				item['child'].push(makeStructure(tasksMap[element]))
			})
		}
		return item
	}

	obj
		.filter((element) => {
			return !element.parent_id
		})
		?.forEach((element) => {
			resultTask.push(makeStructure(element))
		})

	return resultTask
}
export default tasksStructure
