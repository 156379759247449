import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'
import AnalysisViolationsUsers from 'src/old-app/components/analysis/AnalysisViolationsUsers'
import ExportSanction from 'src/old-app/components/analysis/ExportSanction'
import TasksViolations from 'src/old-app/components/analysis/TasksViolations'
import TaskSideBar from 'src/old-app/components/task/TaskSideBar'
import TaskSideBarCheck from 'src/old-app/components/task/TaskSideBarCheck'
import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import TasksProvider from '../tasks/TasksProvider'
import { useTranslation } from 'react-i18next'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import { useSelector } from 'react-redux'

const AnalysisViolations = memo(() => {
	const { t } = useTranslation()

	const { search } = useLocation()

	return (
		<PageWithSideBar
			param="taskId"
			headerTopRight={<ExportSanction isButton />}
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			hideCreateButton
			pageLink={`/analysis-violations/${search}`}
			headerTitle={t('interrupted_tasks')}
			content={
				<div className="tt-overflow-auto tt-h-full">
					<TasksViolations isUrl allProjectsMode pageLink={'/analysis-violations'} />
				</div>
			}
			rightSidebarContent={
				<TaskSideBarCheck>
					<TaskSideBar backTo={`/analysis-violations${search}`} />
				</TaskSideBarCheck>
			}
		/>
	)
})

AnalysisViolations.displayName = 'AnalysisViolations'
export default AnalysisViolations
