import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useCreateTaskDeadlineMutation, useUpdateTaskMutation } from 'src/old-app/service/Tasks'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getTask } from 'src/old-app/store/tasksSlice'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import ErrorText from '../../ui/ErrorText'
import DialogFooter from '../DialogFooter'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

const AcceptTask = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		deadline: yup.date().required(t('deadline_is_required')).typeError(t('invalid_date')),
	})
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const user = useGetUser()
	const {
		setValue,
		handleSubmit,
		register,
		control,
		setError,
		formState: { errors },
	} = useForm({
		defaultValues: {
			deadline: undefined,
		},
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (task?.deadline?.deadline) {
			setValue('deadline', dayjs(task?.deadline?.deadline).format())
		}
	}, [task])

	const [createTaskDeadline, taskDeadlinesResult] = useCreateTaskDeadlineMutation()
	const [editTask, editTaskResult] = useUpdateTaskMutation()

	useEffect(() => {
		if (taskDeadlinesResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: taskDeadlinesResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (editTaskResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: editTaskResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [taskDeadlinesResult, editTaskResult])

	const isDeadline = !!task?.deadline?.deadline

	const acceptTaskHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))

			const taskDeadline = dayjs(data?.deadline).format()

			await editTask({
				id: task.id,
				body: {
					incoming: false,
					type_id: task?.type_id,
					project_status_id: task?.project_status_id,
					task_priority_id: task?.priority?.id,
				},
			})
			await createTaskDeadline({ task_id: task.id, deadline: taskDeadline })

			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(acceptTaskHandler)} className="tt-w-600">
			<DialogHeader title={t('setting_performer_deadline')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-text-14 tt-flex-col tt-w-full tt-bg-gray-100/50 tt-text-gray-600 tt-px-10 tt-border tt-py-6 tt-rounded-lg tt-border-gray-200/50">
						{isDeadline ? t('accept_deadline_or_set_yours') : t('set_deadline_to_accept_task')}
					</div>
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('deadline')}</span>
						<Controller
							render={({ field: { value, onChange } }) => <DatePickerInput value={value} onChange={onChange} />}
							name="deadline"
							control={control}
						/>

						<ErrorText errors={errors} field="deadline" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter rightLabel={t('accept_task')} />
		</form>
	)
}

export default AcceptTask
