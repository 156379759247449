import React, { FC, useState } from 'react'
import Autocomplete from '../autocomplete'
import useDebounce from 'src/shared/hooks/useDebounce'

import { useGetTeamHashtagQuery, useCreateTeamHashtagMutation } from 'src/old-app/service/Teams'

interface IProps {
	value: any

	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
}

const TeamTagsChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Теги', isIds } = p

	const [search, setSearch] = useState<string>('')
	const [createHashtag, result] = useCreateTeamHashtagMutation()
	const debounceSearch = useDebounce(search, 300)

	const { data: taskTagsData } = useGetTeamHashtagQuery({
		hashtag_filter: search,
		current_page: 1,
		per_page: 50,
	})

	const list: any[] = taskTagsData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	const createHandler = async (value: any) => {
		const tagData: any = await createHashtag({ hashtag: value.inputValue })
		const tag = tagData?.data
		return tag
	}

	return (
		<Autocomplete
			placeholder={placeholder}
			create={createHandler}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.hashtag}
			renderOptionLabel={(option: any) => option?.hashtag}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default TeamTagsChoice
