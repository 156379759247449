import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { InputBase } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { useDebounce } from '../../../hooks'

interface IProps {
	field?: string
	placeholder?: string
}

const TextFilter: React.FC<IProps> = (p) => {
	const { field = 'name', placeholder = 'Название' } = p

	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const value = searchParams.get(field) ?? ''
	const [search, setSearch] = useState(value)

	const searchDebounce = useDebounce(search, 500)

	const setParams = (newValue: string) => {
		const params = new URLSearchParams(location.search)

		params.set(field, newValue)
		if (newValue === '') {
			params.delete(field)
		}
		navigate({
			search: params.toString(),
		})
	}

	useEffect(() => {
		setParams(searchDebounce)
	}, [searchDebounce])

	return (
		<>
			<InputBase
				placeholder={placeholder}
				className="new-select select-border tt-w-full tt-rounded-full tt-border  tt-px-12 tt-flex-1"
				value={search}
				onChange={(event) => setSearch(event?.target?.value)}
			/>
		</>
	)
}

export default TextFilter
