import { Button, Chip, Popover } from '@mui/material'
import moment from 'moment/moment'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import { getID } from '../../../shared/config/fields-ids'
import DateRangePicker from '../../../shared/components/date-range-picker/DateRangePicker'
import { useTranslation } from 'react-i18next'

const FilterDeadline = ({ prefix, field, label, id }) => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const { search } = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const deadlineStart = searchParams.get(`${prefix}${field}_start`) ?? undefined
	const deadlineEnd = searchParams.get(`${prefix}${field}_end`) ?? undefined

	const [date, setDate] = useState([])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const _id = open ? 'simple-popover' : undefined

	const clearDeadlineFilter = () => {
		const params = new URLSearchParams(search)

		params.delete(`${prefix}${field}_start`)
		params.delete(`${prefix}${field}_end`)

		setDate([])
		navigate({
			search: params.toString(),
		})
	}

	const setDeadlineFilters = () => {
		const params = new URLSearchParams(search)
		// console.log({date, dd: moment(date[1]).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)});
		params.set(`${prefix}${field}_start`, moment(date[0]).toISOString())
		params.set(`${prefix}${field}_end`, moment(date[1]).toISOString())

		navigate({
			search: params.toString(),
		})
		handleClose()
	}

	useEffect(() => {
		if (deadlineStart && deadlineEnd) {
			setDate([new Date(deadlineStart), new Date(deadlineEnd)])
		}
	}, [deadlineStart, deadlineEnd])

	return (
		<div>
			{deadlineStart && deadlineEnd ? (
				<Chip
					onClick={handleClick}
					label={`${moment(deadlineStart).format('LL')} - ${moment(deadlineEnd).format('LL')}`}
					id={getID({ prefix: id, lib: 'chip' })}
					variant="outlined"
					sx={{ fontWeight: 500 }}
					onDelete={clearDeadlineFilter}
				/>
			) : (
				<div
					className={[
						'select-border tt-rounded-full tt-text-14 tt-min-h-32 tt-px-8 tt-h-32 tt-text-left hover:tt-border-black  tt-truncate  tt-max-h-32 new-select text-input tt-border tt-border-gray-300 tt-flex tt-items-center tt-justify-center',
						deadlineStart && deadlineEnd ? 'tt-px-0 ' : '',
					].join(' ')}
					c
					id={getID({ prefix: id, type: 'button_open' })}
					// variant='contained'
					onClick={handleClick}
				>
					<div className="tt-text-gray-400 tt-font-normal tt-w-full tt-px-4 tt-flex tt-items-center tt-gap-8 tt-justify-between tt-text-regular">
						{label} <CalendarMonthRoundedIcon className="tt-w-14 tt-h-14 tt-text-gray-500" />{' '}
					</div>
				</div>
			)}
			<Popover
				id={_id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					// horizontal: 'left',
				}}
			>
				<div className="tt-flex tt-flex-col tt-gap-16 tt-px-24 tt-py-16">
					<div>
						{/*<div className='text-16 font-semibold mb-8'>{label}</div>*/}
						<DateRangePicker dates={date} onChangeDate={setDate} />
						{/*<div className='flex gap-18 w-full'>*/}
						{/*	<div className='flex flex-col gap-4 w-full'>*/}
						{/*		<span className='text-14  mb-4'>От</span>*/}
						{/*		<TextField value={date?.start?.value}*/}
						{/*		           onChange={({ target }) => setDate({*/}
						{/*			           ...date,*/}
						{/*			           start: { ...date?.start, value: target?.value },*/}
						{/*		           })}*/}
						{/*		           error={date?.start?.error}*/}
						{/*		           type='date'*/}
						{/*		           hiddenLabel*/}
						{/*		           className='dialig-input w-full'*/}
						{/*		           id={getID({ prefix: id, fieldPostfix: 'start', type: 'date' })}*/}
						{/*		           defaultValue=''*/}
						{/*		           size='small' />*/}
						{/*		<ErrorText errors={{ start: date?.start?.error }} field='start' />*/}
						{/*	</div>*/}
						{/*	<div className='flex flex-col  gap-4 w-full'>*/}
						{/*		<span className='text-14 mb-4'>До</span>*/}
						{/*		<TextField value={date?.stop?.value}*/}
						{/*		           onChange={({ target }) => setDate({*/}
						{/*			           ...date,*/}
						{/*			           stop: { ...date?.stop, value: target?.value },*/}
						{/*		           })}*/}
						{/*		           error={date?.stop?.error}*/}
						{/*		           type='date'*/}
						{/*		           hiddenLabel*/}
						{/*		           className='dialig-input w-full'*/}
						{/*		           id={getID({ prefix: id, fieldPostfix: 'end', type: 'date' })}*/}
						{/*		           defaultValue=''*/}
						{/*		           size='small' />*/}
						{/*		<ErrorText errors={{ start: date?.stop?.error }} field='stop' />*/}
						{/*	</div>*/}
						{/*</div>*/}

						<Button
							onClick={setDeadlineFilters}
							disabled={date.length < 2}
							id={getID({ prefix: id, type: 'button_submit' })}
							className="tt-bg-primary disabled:tt-bg-gray-200 disabled:tt-gray-500 hover:tt-bg-primary-800 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
						>
							{t('choose')}
						</Button>
					</div>
				</div>
			</Popover>
		</div>
	)
}
export default FilterDeadline
