import React, { memo } from 'react'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import moment from 'moment'

const DateField = memo((p) => {
	const { date } = p

	return (
		<div className="tt-flex tt-gap-5 tt-items-center ">
			<CalendarMonthOutlinedIcon className="tt-h-20 tt-fill-gray-500" />
			{moment(date).format('LL')}
		</div>
	)
})

DateField.displayName = 'DateField'
export default DateField
