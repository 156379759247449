import React from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getProject } from 'src/old-app/store/projectsSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { UserShow } from '../../../../shared/components'
import { Link } from 'react-router-dom'
import TaskListItem from '../../../../old-app/main/tasks/TaskListItem'
import SubProjectRow from '../sub-project-row'
import EmptyPlaceholder from '../../../../shared/components/empty-placeholder'
import { useGetProjectsQuery } from '../../../../old-app/service/Projects'
import { ProjectRequestTable, ProjectsTable } from '../index'

interface IProps {
	curPage?: string
}

const ProjectSubprojectRequests: React.FC<IProps> = ({ curPage }) => {
	const project = useSelector(getProject)

	if (!project?.id) return null

	return <ProjectRequestTable curPage={curPage} params={{ parent_id: project?.id }} />
	// const dispatch = useDispatch()
	//
	// const openSubProjectCreate = () => {
	// 	dispatch(dialogActions.setDialog(true))
	// 	dispatch(dialogActions.setComponent('project-child-create'))
	// }
	//
	// const {data:projectsData} = useGetProjectsQuery({created_at_sort:'desc',parent_id:mainProject.id})
	//
	//
	// const projects:any[] = projectsData?.data ?? []
	//
	//
	//
	//
	// return(
	// 	<div className={styles['project-subproject']}>
	// 	<div className={[styles['subproject-list']].join(' ')}>
	// 		{projects.map((project:any,i:any)=>
	// 			<SubProjectRow
	// 				project={project}
	// 				index={i}
	// 				takeStatusesFromRedux
	// 				key={project.id}
	// 			/>
	// 		)}
	// 	</div>
	// 		{projects.length === 0 && <EmptyPlaceholder text='Подпроектов пока нет'/>}
	// 	</div>
	// )
}

export default ProjectSubprojectRequests
