import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { getAdminRole, getUserID } from 'src/old-app/store/userSlice'
import { useGetOneProjectsTemplateQuery } from '../../../entities/projects-templates/api/projects-templates-api'
import ProjectTemplateInfo from './ProjectTemplateInfo'
import SideBarHeader from '../sidebar/SideBarHeader'

function OneProjectTemplateSideBar() {
	// const dispatch = useDispatch()
	const navigate = useNavigate()
	// const [searchParams, setSearchParams] = useSearchParams();
	// const project = useSelector(getProject)
	// const users = useSelector(getUsers)
	const userID = useSelector(getUserID)
	const isAdmin = useSelector(getAdminRole)

	const { templateID } = useParams()
	// const { search } = useLocation();

	// const [isEditMode, setIsEditMode] = useState(false);
	// const user = useGetUser()

	// const isEdit = searchParams.get('isEdit');

	const templateQuery = useGetOneProjectsTemplateQuery(templateID, {
		refetchOnWindowFocus: true,
		refetchOnMountOrArgChange: 10,
	})

	const templateData = templateQuery.data?.data

	const canChange = useMemo(() => {
		// console.log({ templateQuery });
		return (templateQuery.isSuccess && isAdmin) || templateData?.author === userID
	}, [templateQuery.data])

	// const { data: projectUsers, error: projectUsersError } = useGetUserProjectsQuery(
	// 	{ project_id: projectId },
	// 	{ refetchOnMountOrArgChange: true }
	// )
	// const { data: projectTeams, error: projectTeamsError } = useGetTeamProjectsQuery(
	// 	{ project_id: projectId },
	// 	{ refetchOnMountOrArgChange: true }
	// )

	useEffect(() => {
		if (templateQuery.error) {
			navigate('/projects-templates')
		}

		// if (projectUsers && templateQuery.data && !templateQuery.isLoading) {
		// 	dispatch(projectsActions.getOneProject(null))
		// 	const me = projectUsers?.data?.find((item) => item?.user_id === user?.id)
		// 	const showRoles = templateQuery.data?.data?.owner?.id === user?.id || isAdmin || me?.role !== 'USER'
		// 	const isAdminShow = templateQuery.data?.data?.owner?.id === user?.id || isAdmin
		//
		// 	dispatch(projectsActions.getUsers(projectUsers.data))
		// 	dispatch(projectsActions.getProjectsUsers(projectUsers.data))
		// 	dispatch(
		// 		projectsActions.getOneProject({
		// 			...templateQuery.data.data,
		// 			isRule: showRoles,
		// 			isAdminRule: isAdminShow,
		// 		})
		// 	)
		// } else {
		// 	dispatch(projectsActions.getUsers([]))
		// 	dispatch(projectsActions.getProjectsUsers([]))
		// 	dispatch(projectsActions.getOneProject(null))
		// }
	}, [templateQuery.error])

	// useEffect(() => {
	// 	if (projectUsers?.data?.length > 0 && project) {
	// 	}
	// }, [project, projectUsers])

	// const setEdit = (state) => {
	// 	const params = new URLSearchParams(search);
	//
	// 	if (state) {
	// 		params.set('isEdit', true);
	// 	} else {
	// 		params.set('isEdit', '');
	// 	}
	//
	// 	navigate({
	// 		search: params.toString(),
	// 	});
	// };

	// const teams = projectTeams?.data ?? []
	// console.log({templateQuery});

	return (
		<div className="tt-px-24 tt-py-16">
			<SideBarHeader
				// left={
				// 	<>
				// 		{project?.is_approved && (
				// 			<Link to={`/projects/list/${projectId}`}>
				// 				<span>Перейти к задачам</span>
				// 			</Link>
				// 		)}
				// 	</>
				// }
				hideActions
				to="/projects-templates"
			/>
			{/*{isEditMode*/}
			{/*	?*/}
			{/*	<EditProject setEdit={setIsEditMode} />*/}
			{/*	: (*/}

			<div className="tt-py-16 tt-w-full tt-flex tt-flex-col tt-gap-24">
				<div className="tt-flex tt-flex-col tt-gap-4">
					{/*<div className='flex w-full justify-end'>*/}
					{/*	{canChange && (*/}
					{/*		<Button*/}
					{/*			onClick={() => setIsEditMode(true)}*/}
					{/*			startIcon={*/}
					{/*				// <ModeEditOutlineOutlined className={'text-gray-700'}/>*/}
					{/*				<svg className='stroke-gray-700' width='20' height='20' viewBox='0 0 20 20' fill='none'*/}
					{/*				     xmlns='http://www.w3.org/2000/svg'>*/}
					{/*					<path*/}
					{/*						d='M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z'*/}
					{/*						strokeWidth='2'*/}
					{/*						stroke='#94A3B8'*/}
					{/*						strokeLinecap='round'*/}
					{/*						strokeLinejoin='round'*/}
					{/*					/>*/}
					{/*				</svg>*/}
					{/*			}*/}
					{/*			className='border-gray-200 inline-flex text-12 min-h-32 h-32 px-16'*/}
					{/*			variant='outlined'*/}
					{/*		>*/}
					{/*			Изменить*/}
					{/*		</Button>*/}
					{/*	)}*/}
					{/*</div>*/}

					<div className="tt-flex tt-flex-col tt-gap-8">
						{/*{project.with_administration && (*/}
						{/*	<div>*/}
						{/*		<div className="px-6 py-2 inline-flex text-sm rounded-full bg-black text-white">*/}
						{/*			Проект с администрированием*/}
						{/*		</div>{' '}*/}
						{/*	</div>*/}
						{/*)}*/}

						<h2 className="tt-text-3xl tt-font-semibold tt-leading-8">{templateData?.name}</h2>
					</div>
				</div>
				{templateQuery.isFetching ? (
					<div className="tt-w-full tt-flex-1 tt-items-center  tt-flex tt-justify-center tt-min-h-full tt-p-24">
						<CircularProgress className="tt-text-primary-600" />
					</div>
				) : (
					<ProjectTemplateInfo templateData={templateData?.template} />
				)}
				{/*{project?.isRule && <ProjectRoles />}*/}
			</div>
			{/*	)*/}
			{/*}*/}
		</div>
	)
}

// ProjectSideBar.displayName = 'OneProjectTemplateSideBar'
export default OneProjectTemplateSideBar
