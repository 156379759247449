/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { Checkbox, Chip, Divider, InputBase, ListSubheader, MenuItem, Select } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import ErrorText from './ErrorText'
import clsx from 'clsx'
import { getID } from '../../../shared/config/fields-ids'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MySelect = ({
	multiple,
	errors,
	value,
	onChange,
	field,
	items,
	defaultValue,
	displayEmpty,
	label,
	labelRender,
	isShowCheckbox,
	errorField,
	isSearch,
	search,
	setSearch,
	disabled,
	menuItemRander,
	hideLabel,
	addComponent,
	selectClassName,
	wrapperClassName,
	hideError,
	idSelect,
	withSubHeadersMode,
}) => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = (event) => {
		//console.log('event',event)
		event.stopPropagation()
		setOpen(true)
	}

	const onChangeHandler = (val) => {
		if (multiple) {
			const isCheck = value.find((item) => item?.id === val?.id)
			const values = isCheck ? value.filter((item) => item?.id !== val?.id) : [...value, val]
			return onChange(values)
		}
		onChange(val)
		return handleClose()
	}

	const SelectRenderValue = useCallback(
		(selected) => {
			if (multiple ? selected?.length === 0 : !selected?.id) {
				return <span className="tt-text-gray-400">{label}</span>
			}

			return (
				<div className="tt-flex tt-truncate">
					{multiple ? <RenderValue values={selected} labelRender={labelRender} /> : labelRender(selected)}{' '}
					<div className="tt-grow tt-flex tt-justify-end tt-pl-3 tt-w-[22px] tt-min-w-[22px]"></div>
				</div>
			)
		},
		[label]
	)

	const isEmptyItems = items ? (withSubHeadersMode ? Object.keys(items)?.length === 0 : items?.length === 0) : !items

	let subItemsInitIndex = 0

	return (
		<div className={['tt-flex tt-flex-col tt-w-full tt-relative', wrapperClassName].join(' ')}>
			{!hideLabel && <div className="tt-text-14 tt-font-semibold tt-mb-4">{label}</div>}
			{multiple && value && value.length > 0 && (
				<button
					className="tt-absolute tt-top-[3px] tt-right-[25px] tt-z-10 tt-bg-gray-50 tt-px-2"
					type="button"
					onClick={() => {
						onChange([])
					}}
				>
					<CancelIcon className="tt-w-[21px] tt-h-[21px] hover:tt-text-gray-500 tt-text-gray-400" />
				</button>
			)}
			<Select
				{...{
					multiple,
					value,
					open,
					displayEmpty,
					defaultValue,
					disabled,
				}}
				onClose={handleClose}
				onOpen={handleOpen}
				error={errors ? !!errors[field] : undefined}
				className={['dialig-input', selectClassName, multiple && 'select-multiple'].join(' ')}
				size="small"
				id={idSelect}
				renderValue={SelectRenderValue}
			>
				{isSearch && (
					<div>
						<div className="tt-flex tt-items-center">
							<SearchInput value={search} onChange={setSearch} />

							{/*не удалять! Решает проблему ввода первой буквы следующего элемента после инпута*/}
							<InvisibleLetter />

							{addComponent}
						</div>
						<Divider />
					</div>
				)}

				<div className="tt-max-h-320 tt-overflow-y-auto overflow-x-hidden">
					{isEmptyItems ? (
						<div className="tt-text-12 tt-text-gray-500 tt-w-full tt-text-center tt-p-8">{t('not_found')}</div>
					) : withSubHeadersMode ? (
						Object.entries(items)?.map(([subHeader, subItems]) => {
							return (
								<React.Fragment key={subHeader}>
									<ListSubheader key={subHeader} className={'tt-text-gray-400 tt-border-t-1 tt-leading-8'}>
										{subHeader}
									</ListSubheader>

									{subItems?.map((subItem) => (
										<MyMenuItem
											index={subItemsInitIndex++}
											isShowCheckbox={isShowCheckbox}
											multiple={multiple}
											labelRender={menuItemRander ?? labelRender}
											key={subItem?.id}
											item={subItem}
											value={value}
											className={'pl-32'}
											onChangeHandler={onChangeHandler}
										/>
									))}
								</React.Fragment>
							)
						})
					) : (
						items?.map((item, ind) => (
							<MyMenuItem
								index={ind}
								isShowCheckbox={isShowCheckbox}
								multiple={multiple}
								labelRender={menuItemRander ?? labelRender}
								key={item?.id}
								item={item}
								value={value}
								onChangeHandler={onChangeHandler}
							/>
						))
					)}
				</div>
			</Select>
			{!hideError && <ErrorText errors={errors} errorField={errorField} field={field} />}
		</div>
	)
}

export default MySelect

const RenderValue = ({ values, labelRender }) => {
	return (
		<div className="filter-wrap tt-flex tt-flex-wrap tt-gap-5">
			{values.map((val) => (
				<Chip key={val?.id} label={labelRender(val)} />
			))}
		</div>
	)
}

const SearchInput = ({ value, onChange }) => {
	const { t } = useTranslation()
	const onChangeHandler = (event) => {
		const val = event.target.value
		onChange(val)
	}
	return (
		<div className="tt-flex tt-items-center tt-h-40 tt-rounded-full tt-shadow-none tt-bg-transparent tt-px-24 tt-gap-10 tt-w-full">
			<SearchIcon className="tt-fill-gray-600" />
			<InputBase
				id={getID({ type: 'select_search' })}
				value={value}
				onChange={onChangeHandler}
				className="tt-w-full"
				placeholder={t('search')}
			/>
		</div>
	)
}

const MyMenuItem = ({ className, multiple, value, item, onChangeHandler, labelRender, isShowCheckbox, id, index }) => {
	const isActive = multiple ? value.find((v) => v?.id === item?.id) : value?.id === item?.id

	return (
		<MenuItem
			id={id || getID({ index: index, type: 'select_item' })}
			className={clsx({ 'tt-bg-gray-100': isActive }, className)}
			onClick={() => {
				onChangeHandler(item)
			}}
			key={item?.id}
		>
			{isShowCheckbox && <Checkbox checked={!!isActive} />}

			{labelRender(item)}
		</MenuItem>
	)
}

MyMenuItem.propTypes = {
	index: PropTypes.number.isRequired,
}

function InvisibleLetter() {
	return <i style={{ fontSize: 0 }}>{'®'}</i>
}
