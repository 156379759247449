import React from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import TasksProvider from '../../../old-app/main/tasks/TasksProvider'
import TaskSideBarCheck from '../../../old-app/components/task/TaskSideBarCheck'
import TaskSideBar from '../../../old-app/components/task/TaskSideBar'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import {
	getProject,
	getProjectBARole,
	getProjectManagerRole,
	getProjectUserRole,
} from 'src/old-app/store/projectsSlice'
import { useSelector } from 'react-redux'
import { useGetUser } from 'src/shared/hooks'
import { ProjectContent, ProjectShowContext, ProjectToParent, useProjectShow } from 'src/entities/project'
import { getAdminRole } from 'src/old-app/store/userSlice'
import ProjectSideBarCheck from '../../../old-app/components/project/ProjectSideBarCheck'
import ScrumboardList from '../../../old-app/main/tasks/scrumboard/ScrumboardList'
import { Box, Chip, Tab, Tabs } from '@mui/material'
import { publicBoardUrl } from '../../../shared/config'
import { useTranslation } from 'react-i18next'
import LanguageToggler from 'src/features/translate/components/LanguageToggler'

const ProjectBoardPublic = () => {
	const { projectId, typeSlug, subProjectId, taskId } = useParams()
	const { search } = useLocation()

	const page = `/${publicBoardUrl.replace(':projectId', projectId)}`
	const link = `${page}${search}`
	const pageLink = `${page}${search}`
	const param = 'taskId'

	return (
		<TasksProvider isPublic defaultProjectContext={{ isEnd: true }}>
			<PageWithSideBar
				param={param}
				contentClassName={'!tt-pt-0 !tt-pr-0 !tt-pl-0 !tt-pb-0 !tt-h-full !tt-px-0'}
				pageLink={pageLink.slice(1, pageLink.length)}
				content={<ProjectBoardPublicContent />}
				rightSidebarContent={
					<>
						{taskId && (
							<TaskSideBarCheck>
								<TaskSideBar defaultTaskContext={{ isEnd: true }} backTo={link} />
							</TaskSideBarCheck>
						)}
					</>
				}
			/>
		</TasksProvider>
	)
}

export const ProjectBoardPublicContent = () => {
	const { t } = useTranslation()
	const { project } = useProjectShow()

	return (
		<div className="tt-w-full tt-flex tt-flex-col tt-h-full">
			<div className="tt-sticky tt-px-16 tt-border-b tt-border-gray-200 tt-top-0 tt-pt-24 tt-bg-white z-[999]">
				<div className="tt-flex tt-justify-between tt-min-h-[42px] tt-gap-16 tt-pb-10">
					<div className="tt-flex tt-gap-8 tt-flex-col">
						<div className="tt-flex tt-gap-8 tt-items-center">
							<div className="tt-flex tt-items-end tt-gap-12">
								<div className="my-truncate tt-text-2xl tt-font-semibold  tt-flex-1 tt-max-w-512  tt-w-full">
									{project?.name}{' '}
								</div>
								{project?.type && (
									<Chip
										sx={{ height: 24 }}
										className="tt-truncate tt-inline-flex tt-font-medium tt-text-12"
										label={t(`types.${project?.type?.name}`)}
									/>
								)}
								<LanguageToggler />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				id="project-content"
				className={['tt-px-16 tt-py-16 tt-overflow-auto tt-h-full table-scroll overflow-hidden'].join(' ')}
			>
				<ScrumboardList />
			</div>
		</div>
	)
}
export default ProjectBoardPublic
