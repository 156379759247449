import FuseUtils from 'src/@fuse/utils'
import FuseLoading from 'src/@fuse/core/FuseLoading'
import { Navigate } from 'react-router-dom'
import settingsConfig from 'src/old-app/configs/settingsConfig'
import ProjectsConfig from '../main/projects/ProjectsConfig'
import TasksConfig from '../main/tasks/TasksConfig'
import ProtocolConfig from '../main/protocol/ProtocolConfig'
import MyConfig from '../main/my/MyConfig'
import ProfileConfig from '../main/profile/ProfileConfig'
import AnalysisViolationsConfig from '../main/analysisViolations/AnalysisViolationsConfig'
import TeamsConfig from '../main/teams/TeamsConfig'

const routeConfigs = [
	AnalysisViolationsConfig,
	ProjectsConfig,
	TasksConfig,
	ProtocolConfig,
	MyConfig,
	TeamsConfig,
	ProfileConfig,
]

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: '/',
		element: <Navigate to="/my" />,
	},
	{
		path: 'loading',
		element: <FuseLoading />,
	},

	{
		path: '*',
		element: <Navigate to="/" />,
	},
]

export default routes
