import React, { useEffect, useState } from 'react'
import SideBarHeader from '../../../../old-app/components/sidebar/SideBarHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetProjectContractDirectoryGetByIdQuery } from '../../../../old-app/service/Projects'
import { ContractTypeInfo } from 'src/@entities/project'
import { ContractTypeEdit } from 'src/@features/project'
import { LoadPage } from 'src/shared/components'

interface ContractDirectorySideBarProps {
	backTo: string
	paramId: string
	isManage: boolean
}

export const ContractDirectorySideBar: React.FC<ContractDirectorySideBarProps> = ({
	backTo,
	paramId = 'contractTypeId',
	isManage,
}) => {
	const routeParams = useParams()
	const navigate = useNavigate()

	const { data, isLoading, isError } = useGetProjectContractDirectoryGetByIdQuery(routeParams[paramId])
	const [isEdit, setIsEdit] = useState(false)

	const closeSideBar = () => {
		navigate(backTo)
	}

	useEffect(() => {
		setIsEdit(false)
	}, [routeParams[paramId]])

	useEffect(() => {
		if (isError) {
			closeSideBar()
		}
	}, [isError])

	if (isLoading) return <LoadPage />

	const contractType = data?.data

	return (
		<div className="tt-h-full tt-flex tt-flex-col">
			<SideBarHeader left={<></>} hideActions to={backTo} />
			<div className="tt-h-full tt-overflow-auto tt-tt-flex-1">
				{isEdit ? (
					<ContractTypeEdit setIsEdit={setIsEdit} contractType={contractType} />
				) : (
					<ContractTypeInfo isManage={isManage} closeSideBar={closeSideBar} contractType={contractType} setIsEdit={setIsEdit} />
				)}
			</div>
		</div>
	)
}
