import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	getProjectBARole,
	getProjectManagerRole,
	getProjectUserRole,
	projectsActions,
} from 'src/old-app/store/projectsSlice'
import { useGetOneProjectQuery, useGetUserProjectsQuery } from 'src/old-app/service/Projects'
import ProjectSideBarLoading from './ProjectSideBarLoading'
import SideBarHeader from '../sidebar/SideBarHeader'
import { getAdminRole, getUserRole } from 'src/old-app/store/userSlice'
import { useGetUser } from 'src/shared/hooks'
import { ProjectShowContext } from '../../../entities/project'
import rolesType from '../../constants/rolesType'
import { ProjectSettings } from '../../../pages/project'

const ProjectSideBar = ({ backTo, paramId = 'projectId' }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const isUser = useSelector(getProjectUserRole)
	const isManager = useSelector(getProjectManagerRole)
	const isBA = useSelector(getProjectBARole)
	const isAdmin = useSelector(getAdminRole)
	const user = useGetUser()
	const params = useParams()
	const projectId = params[paramId]

	const {
		data: projectData,
		isError: projectIsError,
		isFetching: projectIsFetching,
	} = useGetOneProjectQuery({ id: projectId })

	const {
		data: projectUsers,
		isError: usersIsError,
		isFetching: usersIsFetching,
	} = useGetUserProjectsQuery({ project_id: projectId, per_page: 100 })

	useEffect(() => {
		if (projectIsError || usersIsError) {
			return navigate(backTo)
		}
	}, [usersIsError, projectIsError])

	useEffect(() => {
		if (projectUsers?.data) {
			const me = projectUsers?.data.find((u) => u.user.id === user.id)
			dispatch(projectsActions.getProjectRoles([isAdmin ? rolesType.ADMIN : me?.role ?? rolesType.USER]))
		}
	}, [projectUsers])

	if (projectIsFetching || usersIsFetching) return <ProjectSideBarLoading />

	const users = projectUsers?.data ?? []
	const me = users?.find((u) => u?.user?.id === user?.id)
	const project = {
		...projectData?.data,
		users,
		child_projects_completed: projectData?.data?.child_projects_completed,
		isEnd: projectData?.data?.confirmation_status === 'COMPLETED',
		me,
	}

	const projectClosingUsersIDs = project?.closing_users?.map((closingUser) => {
		return closingUser?.user_id
	})

	const unclosableProject =
		projectData?.data?.confirmation_status === 'REJECTED' ||
		projectData?.data?.confirmation_status === 'ON_CONFIRMATION'

	const canCloseProject = !unclosableProject && (projectClosingUsersIDs.includes(user?.id) || isAdmin)

	const isMyProject = project?.id === user?.id
	const isOwnerProject = project?.owner?.id === user?.id
	const isCreateTask = isUser ? project?.each_member_can_create_task : true
	const isShowCreateTaskButton = project?.isEnd ? false : isMyProject || isCreateTask
	const isManageProject = isMyProject || isAdmin || isOwnerProject || isManager || isBA
	const isEditProject = isMyProject || isAdmin || isOwnerProject || isManager || isBA
	const isCommittee = project?.type?.name === 'committee'

	return (
		<ProjectShowContext.Provider
			value={{
				isShowCreateTaskButton,
				isManageProject,
				isEditProject,
				project,
				isMyProject,
				canCloseProject,
				isCommittee,
			}}
		>
			<div className="tt-h-full  tt-flex tt-flex-col">
				<SideBarHeader to={backTo} />

				<div className="tt-overflow-auto tt-h-full ">
					<ProjectSettings projectId={project?.id} isSideBar hideFields={{ status: true, priority: true, roles: true }} isWrap />
				</div>
			</div>
		</ProjectShowContext.Provider>
	)
}

export default ProjectSideBar
