import moment from 'moment'
import React from 'react'
import UserCellShow from '../../components/tables/UserCellShow'

const TaskText = ({ item }) => {
	return (
		<div>
			<div>Задача была отправлена на подтверждение {moment(item?.created_at).format('LL')}</div>
			<div className="flex gap-5 mt-3">
				Исполнитель: <UserCellShow user={item?.task?.assignee} />
			</div>
		</div>
	)
}

export default TaskText
