import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const DateTimePickerInput = ({ value, onChange, sx }) => {
	const dateTimeValue = value && dayjs(value).isValid() ? dayjs(value).$d : undefined

	function onDateChangeHandler(value) {
		onChange(value ? dayjs(value).format() : undefined)
	}

	return (
		<DateTimePicker
			className="dialig-input"
			ampm={false}
			slotProps={{
				textField: { size: 'small' },
				openPickerIcon: { style: { height: '1.8rem', width: '1.8rem' } },
			}}
			sx={sx}
			value={dateTimeValue}
			onChange={onDateChangeHandler}
		/>
	)
}

export default DateTimePickerInput
