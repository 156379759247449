import React, { useEffect, useLayoutEffect, useState } from 'react'
import styles from './styles.module.scss'
import { getProjectTemplateData, getProjectUserRole, projectsActions } from 'src/old-app/store/projectsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { TaskContext, TaskDeadline } from 'src/entities/task'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Chip, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Markdown from 'src/old-app/components/ui/Markdown'
import TaskSlug from 'src/old-app/components/task/TaskSlug'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import { UserShow } from 'src/shared/components'
import addAlpha from 'src/old-app/utils/addAlpha'
import TaskType from 'src/old-app/components/task/TaskType'
import FieldContent from 'src/old-app/components/shared/FieldContent'
import moment from 'moment'
import Button from 'src/shared/components/button'
import ProjectTemplateEditTask from 'src/old-app/components/task/ProjectTemplateEditTask'
import { getID } from '../../../../shared/config/fields-ids'
import { useGetUser } from '../../../../shared/hooks'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import Epic from '../../../../old-app/components/task/Epic'
import ProjectTemplateTasksList from '../ProjectTemplateTasksList'
import { useTranslation } from 'react-i18next'

const TaskRow = ({ label, content }) => {
	return (
		<div className="tt-flex tt-gap-12 tt-items-center">
			<div className="tt-min-w-128 tt-w-128 tt-text-gray-600">{label}:</div>
			<div>{content}</div>
		</div>
	)
}

const _commonId = getID({ place: 'sidebar', entity: 'project_template', prefix: 'task' })

const ProjectTemplateTask = ({ isCanEdit }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { taskId, templateID } = useParams()
	const [isEdit, setIsEdit] = useState(false)
	const projectTempalteData = useSelector(getProjectTemplateData)
	const user = useGetUser()
	const [task, setTask] = useState({})
	const [subTasks, setSubTasks] = useState([])

	const isUser = useSelector(getProjectUserRole)

	useEffect(() => {
		const task = projectTempalteData?.template?.Tasks?.find((item) => item?.id === taskId)
		if (!task) {
			navigate(`/projects-templates/${templateID}`)
			return
		} else {
			setTask(task)
		}
	}, [taskId, templateID, projectTempalteData])

	const changeTaskStatusHandler = ({ target: { value } }) => {
		const status = projectTempalteData?.template?.Statuses?.find((item) => item.id === value)
		dispatch(
			projectsActions.editProjectTemplateTask({
				...task,
				actual_status_id: status.id,
				task_status: { project_status: status, ...status },
			})
		)
	}

	useLayoutEffect(() => {
		const isMyTask = task?.assignee?.id === user?.id || task?.author?.id === user?.id || !isUser
		const subtasks = projectTempalteData?.template?.Tasks?.filter((item) => item?.parent_id === task.id)
		setSubTasks(subtasks)
		dispatch(tasksActions.getTask({ ...task, isMyTask, subtasks }))
	}, [task, projectTempalteData?.template?.Tasks])

	const changeTaskPriorityHandler = ({ target: { value } }) => {
		const priority = projectTempalteData?.template?.Priorities?.find((item) => item.id === value)
		dispatch(
			projectsActions.editProjectTemplateTask({
				...task,
				task_priority_id: priority.id,
				priority,
			})
		)
	}

	const removeTaskHandler = (taskId) => {
		const tasks = projectTempalteData?.template?.Tasks?.map((taskItem) => {
			if (taskItem.id === taskId) {
				return { ...taskItem, parent_id: null }
			}
			return taskItem
		})
		dispatch(projectsActions.putProjectTemplateTasks(tasks))
	}

	const backTo = `/projects-templates/${templateID}`
	const page = backTo.indexOf('?') === -1 ? backTo : backTo.slice(0, backTo.indexOf('?'))

	return (
		<div className={styles['project-template-task']}>
			<TaskContext.Provider value={{ task, page: page, baseUrl: backTo }}>
				<div className="tt-px-24 tt-py-16">
					<div className="tt-flex tt-sticky tt-z-20 tt-top-0 tt-bg-white tt-items-center tt-justify-between tt-border-b-1 tt-w-full tt-pb-16">
						<div />
						<div className="tt-flex tt-items-center tt-gap-8">
							<Link className="tt-w-32 tt-h-32" to={`/projects-templates/${templateID}`}>
								<CloseIcon />
							</Link>
						</div>
					</div>
					<div className=" tt-py-16">
						{isEdit ? (
							<div className="tt-py-24">
								<ProjectTemplateEditTask id={_commonId} task={task} setIsEdit={setIsEdit} />
							</div>
						) : (
							<div className="tt-py-24">
								<div>
									{isCanEdit && (
										<Button
											id={getID({
												prefix: _commonId,
												action: 'edit',
												type: 'button_toggle',
											})}
											onClick={() => setIsEdit(true)}
											startIcon={
												<svg
													className="tt-stroke-gray-700"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
														strokeWidth="2"
														stroke="#94A3B8"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											}
											className="tt-border-gray-200  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
											variant="outlined"
										>
											{t('change')}
										</Button>
									)}
									<div className="tt-flex tt-gap-8 tt-mt-24">
										<h3 className="tt-font-bold tt-mb-12 text-hyphens">{task?.title}</h3>
									</div>
									<div className="tt-flex tt-flex-col tt-gap-12">
										{task?.task_status && (
											<TaskRow
												label={t('status')}
												content={
													<Select
														id={getID({
															prefix: _commonId,
															field: 'status',
															type: 'select',
														})}
														disabled={!isCanEdit}
														onChange={changeTaskStatusHandler}
														IconComponent={null}
														className="task-card-select tt-w-160 tt-text-14 tt-font-medium "
														value={task?.task_status?.project_status?.id ?? 'null'}
														sx={{
															backgroundColor: addAlpha(task?.task_status?.project_status?.color ?? '#1E293B', 0.1),
															color: task?.task_status?.project_status?.color ?? '#1E293B',
														}}
													>
														{projectTempalteData?.template?.Statuses?.map((item, index) => (
															<MenuItem
																key={item.id}
																id={getID({
																	prefix: _commonId,
																	field: 'status',
																	type: 'select_item',
																	index,
																})}
																value={item.id}
																sx={{ color: item?.color }}
															>
																{item?.status}
															</MenuItem>
														))}
														{!task?.task_status && (
															<MenuItem disabled value="null">
																{t('not_selected')}
															</MenuItem>
														)}
													</Select>
												}
											/>
										)}

										<TaskRow
											label={t('priority')}
											content={
												<Select
													id={getID({
														prefix: _commonId,
														field: 'priority',
														type: 'select',
													})}
													disabled={!isCanEdit}
													onChange={changeTaskPriorityHandler}
													IconComponent={null}
													className="task-card-select tt-w-160 tt-text-14 tt-font-medium "
													value={task?.priority?.id ?? 'null'}
													sx={{
														backgroundColor: addAlpha(task?.priority?.color ?? '#1E293B', 0.1),
														color: task?.priority?.color ?? '#1E293B',
													}}
												>
													{projectTempalteData?.template?.Priorities?.map((item, index) => (
														<MenuItem
															id={getID({
																prefix: _commonId,
																field: 'priority',
																type: 'select_item',
																index,
															})}
															key={item.id}
															value={item.id}
															sx={{ color: item?.color }}
														>
															{item?.name}
														</MenuItem>
													))}
													{!task?.priority && (
														<MenuItem disabled value="null">
															{t('not_selected')}
														</MenuItem>
													)}
												</Select>
											}
										/>

										{task?.slug && (
											<TaskRow label={t('project_slug')} content={<TaskSlug isMultiline slug={task?.slug} />} />
										)}
										{task?.assignee && <TaskRow label={t('performer')} content={<UserShow user={task?.assignee} />} />}

										{task?.deadline && (
											<TaskRow label={t('deadline')} content={<TaskDeadline deadline={task?.deadline} />} />
										)}
										{task?.description ? (
											<div className="tt-bg-gray-100 tt-rounded-lg tt-p-16 tt-mt-6">
												<Markdown value={task?.description} />
											</div>
										) : null}
									</div>
									{/* <GridFiles /> */}
									<div className="tt-flex tt-flex-col  tt-gap-16 tt-mt-20">
										<FieldContent
											content={<TaskType taskType={task?.type} />}
											icon={<AccountTreeOutlinedIcon className="tt-text-gray-700 tt-w-20 tt-h-20" />}
											label={t('type')}
										/>

										<FieldContent
											content={<p className="tt-break-all">{task?.result_representation}</p>}
											icon={<AccountTreeOutlinedIcon className="tt-text-gray-700 tt-w-20 tt-h-20" />}
											label={t('expected_results')}
										/>

										<FieldContent
											className="tt-flex-1"
											content={moment(task?.created_at).format('LLL')}
											icon={<CalendarMonthOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
											label={t('creation_date')}
										/>

										<FieldContent
											className="tt-flex-1"
											content={
												<div className="tt-flex tt-gap-4 tt-flex-wrap">
													{task?.hashtags?.map((item) => (
														<Chip className="tt-truncate" label={item.hashtag} />
													))}
												</div>
											}
											icon={<TagOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
											label={t('tags')}
										/>

										<FieldContent
											className="tt-flex-1"
											content={moment(task?.updated_at).format('LLL')}
											icon={<EventOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
											label={t('updation_date')}
										/>

										{Object.entries(task?.params ?? {}).map(([field, value]) => {
											return (
												<FieldContent
													className="tt-flex-1"
													content={value}
													icon={<DataObjectOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
													label={field}
												/>
											)
										})}
									</div>
								</div>
							</div>
						)}
					</div>
					<ProjectTemplateTasksList
						isCanEdit={isCanEdit}
						tasksList={subTasks}
						baseUrl={`/projects-templates/${templateID}`}
						removeTaskHandler={removeTaskHandler}
					/>
				</div>
			</TaskContext.Provider>
		</div>
	)
}

export default ProjectTemplateTask
