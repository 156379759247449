import React from 'react'

const ProjectStatus = ({ project }) => {
	return (
		<div className="tt-flex tt-flex-col tt-gap-2">
			{project?.comment && (
				<div>
					<div className="tt-text-white  tt-inline-flex tt-text-12 tt-font-medium  tt-bg-gray-100 tt-rounded-full tt-px-8 tt-py-2 tt-bg-red-600">
						Отклонен
					</div>
				</div>
			)}
			{!project?.is_approved && !project?.comment && (
				<div>
					<div className="tt-text-white tt-inline-flex tt-text-12  tt-font-medium  tt-bg-gray-100 tt-rounded-full tt-px-8 tt-py-2 tt-bg-orange-600">
						На подтверждении
					</div>
				</div>
			)}
			{project?.is_approved && !project?.commen && (
				<div>
					<div className="tt-text-white tt-inline-flex tt-text-12  tt-font-medium  tt-bg-gray-100 tt-rounded-full tt-px-8 tt-py-2 tt-bg-green-600">
						Подтвержден
					</div>
				</div>
			)}
		</div>
	)
}

export default ProjectStatus
