/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
import { yupResolver } from '@hookform/resolvers/yup'
import { TextareaAutosize } from '@mui/base'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { dialogActions, getDialogState } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import * as yup from 'yup'
import { useRejectTeamMutation } from '../../../service/Teams'
import ErrorText from '../../ui/ErrorText'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { useTranslation } from 'react-i18next'

const schema = yup.object().shape({
	comment: yup.string().required('Укажите комментарий'),
})

const RejectTeam = () => {
	const {} = useParams()
	const navigate = useNavigate()
	const { search, pathname } = useLocation()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [rejectTeam] = useRejectTeamMutation()
	const dialogState = useSelector(getDialogState)

	const {
		setValue,
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const closeHandler = (message) => {
		dispatch(
			showMessage({
				message,
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(dialogActions.setDialog(false))
		dispatch(interfaceActions.setIsLoading(false))
		// if (request?.backRoute) {
		// 	return navigate(`${request?.backRoute}${search}`)
		// }
		// navigate(`${pathname}${search}`)
	}

	const rejectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		await rejectTeam({
			command_id: dialogState?.teamId,
			...data,
		})
		closeHandler(t('rejected'))
	}
	return (
		<form onSubmit={handleSubmit(rejectHandler)} className="tt-w-600">
			<DialogHeader title={t('cancel_with_comment')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('comment')}</span>
						<TextareaAutosize
							minRows={3}
							maxRows={10}
							{...register('comment')}
							error={!!errors.comment}
							placeholder={t('comment')}
							size="small"
							aria-label="minimum height"
							className="tt-border tt-border-gray-300 tt-p-12  tt-rounded-lg"
						/>
						<ErrorText errors={errors} field="comment" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter rightLabel={t('send')} />
		</form>
	)
}

export default RejectTeam
