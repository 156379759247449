import React, { useEffect } from 'react'
import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
	useDeleteOneProjectsTemplateMutation,
	useGetOneProjectsTemplateQuery,
	useUpdateOneProjectsTemplateMutation,
} from 'src/entities/projects-templates/api/projects-templates-api'
import { getProjectTemplateData, projectsActions } from 'src/old-app/store/projectsSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
	ProjectTemplateInfo,
	ProjectTemplateTask,
	ProjectTemplateTasks,
} from 'src/entities/projects-templates/components'
import { ConfirmDelete, LoadPage, UserShow } from 'src/shared/components'
import Button from 'src/shared/components/button'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useGetUser } from 'src/shared/hooks'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import rolesType from 'src/old-app/constants/rolesType'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { getID } from '../../../shared/config/fields-ids'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

const commonID = getID({ entity: 'project_template' })

const ProjectTemplateShowPage = () => {
	const { t } = useTranslation()

	const { templateID, taskId } = useParams()
	const user = useGetUser()
	const isAdmin = useSelector(getAdminRole)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const projectTempalteData = useSelector(getProjectTemplateData)
	const templateQuery = useGetOneProjectsTemplateQuery(templateID, {
		refetchOnMountOrArgChange: true,
	})

	const isCanEdit = isAdmin || projectTempalteData?.author_view?.id === user?.id

	const [updateOneProjectsTemplate, result] = useUpdateOneProjectsTemplateMutation()
	const [deleteOneProjectsTemplate, deleteResult] = useDeleteOneProjectsTemplateMutation()

	useEffect(() => {
		if (result?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [result])

	useEffect(() => {
		if (deleteResult?.isSuccess) {
			dispatch(interfaceActions?.setIsLoading(false))
			navigate('/projects-templates')
		}
		if (deleteResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [deleteResult])

	useEffect(() => {
		if (templateQuery?.data?.data && !templateQuery?.isFetching) {
			const projectTemplate = templateQuery?.data?.data

			dispatch(projectsActions.getProjectTemplateData(projectTemplate))

			dispatch(projectsActions.getPrioritys(projectTemplate?.template?.Priorities))
			dispatch(tasksActions.getStatuses(projectTemplate?.template?.Statuses))

			dispatch(projectsActions.getProjectsUsers(projectTemplate?.template?.Users))
			dispatch(projectsActions.getProjectRoles([rolesType.PROJECT_MANAGER, rolesType.ADMIN]))
		} else {
			dispatch(projectsActions.getProjectTemplateData(null))
		}
	}, [templateQuery, templateQuery?.isFetching])

	useEffect(() => {
		if (templateQuery?.isError) {
			navigate('/projects-templates')
		}
	}, [templateQuery])

	if (templateQuery?.isLoading) {
		return (
			<div className="tt-flex tt-bg-white tt-w-full tt-h-full tt-items-center tt-justify-center">
				<CircularProgress className="tt-text-primary" />
			</div>
		)
	}

	const deleteHandler = async () => {
		dispatch(interfaceActions?.setIsLoading(true))
		await deleteOneProjectsTemplate(projectTempalteData?.id)
		dispatch(interfaceActions?.setIsLoading(false))
	}

	const saveHandler = async () => {
		const notAssigneeTasks = projectTempalteData?.template?.Tasks?.filter((task) => {
			if (!task?.assignee_id || !task?.assignee) {
				return task
			}
		})

		if (notAssigneeTasks.length > 0) {
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('not-assignee-tasks'))
			dispatch(tasksActions.getNotTasks(notAssigneeTasks))
			return
		}

		dispatch(interfaceActions.setIsLoading(true))
		try {
			const priorities = projectTempalteData?.template?.Priorities?.map((item) => ({
				...item,
				level: +item.level,
			}))
			const tasks = projectTempalteData?.template?.Tasks?.map((item) => ({
				...item,
				task_status: null,
			}))

			const bodyData = {
				id: templateID,
				body: {
					...projectTempalteData,
					template: { ...projectTempalteData.template, Tasks: tasks, Priorities: priorities },
				},
			}
			navigate(`/projects-templates/${projectTempalteData?.id}`)
			await updateOneProjectsTemplate(bodyData)

			dispatch(interfaceActions.setIsLoading(false))
			dispatch(
				showMessage({
					message: t('successfully_updated.template'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		} catch (error) {
			console.log('error', error)
			dispatch(interfaceActions.setIsLoading(false))
		}
	}

	const createTaskHandler = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('task-create-template'))
	}

	return (
		<PageWithSideBar
			param="taskId"
			id={commonID}
			pageLink="projects-templates"
			headerCreateComponent={true}
			headerTitle={
				<div className="tt-flex tt-flex-col tt-gap-4 tt-w-full">
					<p className="tt-text-black my-truncate">{projectTempalteData?.name}</p>
					<span className="tt-text-12 tt-text-gray-500 tt-mt-4">
						{moment(projectTempalteData?.created_at).format('LLLL')}
					</span>
					<span className="ttt-ext-14">
						<UserShow user={projectTempalteData?.author_view} />
					</span>
				</div>
			}
			headerTopRight={
				<div className="tt-flex tt-gap-8 tt-items-center">
					{isCanEdit && (
						<>
							<ConfirmDelete
								id={commonID}
								text={t('delete_template_dialog.are_you_shure')}
								onSuccess={deleteHandler}
								title={t('delete_template_dialog.header')}
							>
								<Button id={getID({ prefix: commonID, action: 'delete', type: 'button_open' })} variant={'outlined'}>
									{t('delete_the.template')}
								</Button>
							</ConfirmDelete>
							<Button
								id={getID({ prefix: commonID, action: 'create', entity: 'task', type: 'button_open' })}
								onClick={createTaskHandler}
								variant={'outlined'}
							>
								{t('create_new.task')}
							</Button>
							<Button id={getID({ prefix: commonID, type: 'button_submit' })} onClick={saveHandler}>
								{t('save_the.template')}
							</Button>
						</>
					)}
				</div>
			}
			hideCreateButton={true}
			content={
				<div className="tt-flex tt-flex-col tt-gap-24">
					<ProjectTemplateInfo isCanEdit={isCanEdit} />
					{projectTempalteData && <ProjectTemplateTasks />}
				</div>
			}
			rightSidebarContent={<div>{taskId && projectTempalteData && <ProjectTemplateTask isCanEdit={isCanEdit} />}</div>}
		/>
	)
}

export default ProjectTemplateShowPage
