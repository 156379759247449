import { Chip, Tooltip } from '@mui/material'
import React, { memo } from 'react'
import { CellEmpty } from '../../../shared/components'

const multilineProps = {
	classes: { label: 'tt-whitespace-pre-wrap tt-break-all' },
	style: { height: 'auto', padding: '.25em' },
}

const TaskSlug = memo(
	({ slug, className = '', isMultiline, ...props }) => {
		if (!slug) return <CellEmpty />

		const _multilineProps = isMultiline ? multilineProps : {}

		return (
			<Tooltip enterDelay={500} arrow title={slug} placement="top-start">
				<Chip
					label={slug}
					className={`tt-bg-gray-200 tt-text-gray-700 tt-font-medium tt-truncate ${className}`}
					size="small"
					{..._multilineProps}
					{...props}
				/>
			</Tooltip>
		)
	},
	(prev, curr) => {
		// console.log({ prev, curr });
		return prev.slug === curr.slug
	}
)

TaskSlug.displayName = 'TaskSlug'
export default TaskSlug
