import { lazy } from 'react'
const Projects = lazy(() => import('./Projects'))
const ProjectProjects = lazy(() => import('./ProjectProjects'))

const ProjectsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'projects',
			element: <Projects />,
			children: [
				{
					path: ':projectId',
					element: <Projects />,
				},
			],
		},
		{
			path: 'project/:parentId',
			element: <ProjectProjects />,
			children: [
				{
					path: ':projectId',
					element: <Projects />,
				},
			],
		},
	],
}

export default ProjectsConfig
