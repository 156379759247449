import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	tasks: { type: null, items: [], count: 0 },
	projects: { type: null, items: [], count: 0 },
	tasksDeadlines: { type: null, items: [], count: 0 },
	request: null,
}

const reducers = {
	getTasks: (state, action) => {
		state.tasks = action.payload
	},
	getRequest: (state, action) => {
		state.request = action.payload
	},
	getProjects: (state, action) => {
		state.projects = action.payload
	},
	getTasksDeadlines: (state, action) => {
		state.tasksDeadlines = action.payload
	},
	deleteProject: (state, action) => {
		state.projects = {
			...state.projects,
			count: state.projects.count - 1,
			items: state.projects.items.filter((item) => item.id !== action.payload),
		}
	},
	deleteTask: (state, action) => {
		state.tasks = {
			...state.tasks,
			count: state.tasks.count - 1,
			items: state.tasks.items.filter((item) => item.task_id !== action.payload),
		}
	},
	deleteTasksDeadlines: (state, action) => {
		state.tasksDeadlines = {
			...state.tasksDeadlines,
			count: state.tasksDeadlines.count - 1,
			items: state.tasksDeadlines.items.filter((item) => item?.created_at !== action.payload?.created_at),
		}
	},
}

const requestsSlice = createSlice({
	name: 'requests',
	initialState,
	reducers,
})

export const getTasks = (state) => state.requests.tasks
export const getRequest = (state) => state.requests.request
export const getTasksDeadlines = (state) => state.requests.tasksDeadlines
export const getProjects = (state) => state.requests.projects

export const requestsReducer = requestsSlice.reducer
export const requestsActions = requestsSlice.actions
