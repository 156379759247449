import { useState } from 'react'
import { KKUser } from './types'
import { parseJwt, uuid } from 'src/shared/lib/utils'
import { KEYCLOAK_URL } from 'src/shared/config'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

interface AuthTrue {
	token: string
	state: string
	session_state: string
	token_type: string
	expires_in: string
	authenticated: true
}

interface AuthFalse {
	authenticated: false
}

type Auth = AuthFalse | AuthTrue

export const localStorageAuthKey = 'auth'
export const localStorageAuthTokenKey = 'auth-token'

const authConfig = {
	KEYCLOAK_URL_REAM: 'ucp',
	KEYCLOAK_URL: KEYCLOAK_URL,
	KEYCLOAK_URL_CLIENT_ID: 'base',
}

const getRedirectUrl = () => window.location.origin + window.location.pathname

const getAuth = () => {
	const auth = localStorage.getItem(localStorageAuthKey)
	if (auth) {
		return JSON.parse(auth)
	}
	return { authenticated: false }
}

const clearAllCookies = () => {
	const cookies = document.cookie.split(';')

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf('=')
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
	}
}

export const getToken = (): string => {
	const auth = getAuth()
	if (!auth.authenticated) return 'null-token'
	return auth.token
}

export const getUser = () => {
	const auth = getAuth()
	if (!auth.authenticated) return null
	return parseJwt<KKUser>(auth.token)
}

const clearStorage = () => {
	localStorage.clear()
	sessionStorage.clear()
	clearAllCookies()
}

export const logout = () => {
	clearStorage()

	window.location.href = createLogoutURL()
}

const saveRedirectUri = (redirectUri: string, key: string) => {
	localStorage.setItem(key, redirectUri)
}

const createLoginURL = () => {
	const state = uuid()
	const responseMode = 'fragment'
	const responseType = 'token'
	const scope = 'openid'
	const nonce = uuid()
	const redirectUri = getRedirectUrl()
	const url = `${authConfig.KEYCLOAK_URL}/realms/${authConfig.KEYCLOAK_URL_REAM}/protocol/openid-connect/auth?client_id=${authConfig.KEYCLOAK_URL_CLIENT_ID}&redirect_uri=${redirectUri}&state=${state}&response_mode=${responseMode}&response_type=${responseType}&scope=${scope}&nonce=${nonce}`

	saveRedirectUri(redirectUri, state)

	return url
}

const createLogoutURL = () => {
	const redirectUri = getRedirectUrl()
	const url = `${authConfig.KEYCLOAK_URL}/realms/${authConfig.KEYCLOAK_URL_REAM}/protocol/openid-connect/logout?client_id=${authConfig.KEYCLOAK_URL_CLIENT_ID}&post_logout_redirect_uri=${redirectUri}`

	return url
}

export const useSession = () => {
	const [auth, setAuth] = useState<Auth>(getAuth())
	const navigate = useNavigate()

	const checkTokenExp = (token: string) => {
		const tokenExp = parseJwt<KKUser>(token).exp
		const exp = new Date(+tokenExp * 1000)

		return moment(exp).isBefore(new Date())
	}

	const checkAuth = () => {
		if (auth.authenticated && window.location.hash.includes('state')) {
			clearUrl(auth)
		}

		if (!auth.authenticated) {
			return false
		}

		if (checkTokenExp(auth.token)) {
			logout()
			return false
		}

		return true
	}

	const clearUrl = (auth: AuthTrue) => {
		const url = getRedirectUri(auth.state)
		if (!url) return

		const pathname = new URL(url)?.pathname
		navigate(pathname ?? '/')
	}

	const getRedirectUri = (key: string): string | null => {
		const redirectUri = localStorage.getItem(key)
		if (!redirectUri) return null
		return redirectUri
	}

	const saveAuth = (auth: AuthTrue) => {
		window.localStorage.setItem(localStorageAuthKey, JSON.stringify(auth))
		window.localStorage.setItem(localStorageAuthTokenKey, auth.token)
	}

	const getState = () => {
		const searchParams = new URLSearchParams(window.location.hash.substring(1))
		const token = searchParams.get('access_token')
		const state = searchParams.get('state')
		const session_state = searchParams.get('session_state')
		const token_type = searchParams.get('token_type')
		const expires_in = searchParams.get('expires_in')

		if (token && session_state && token_type && expires_in && state) {
			const auth: AuthTrue = {
				authenticated: true,
				token,
				state,
				session_state,
				token_type,
				expires_in,
			}
			setAuth(auth)
			saveAuth(auth)
			clearUrl(auth)
		}
	}

	const login = () => {
		if (auth.authenticated) return
		if (window.location.hash.includes('state')) {
			getState()
			return
		}

		window.location.href = createLoginURL()
	}

	const init = () => {
		if (checkAuth()) return
		login()
	}

	return { auth, init }
}
