import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const PageHeader = memo(
	({
		createComponent,
		headerTopRight,
		headerTopRightLeft,
		// hideButton,
		hideCreateButton,
		// hideSearch,
		leftTitleComponent,
		onCreate,
		// showCreateButton,
		title,
		id,
		buttonLabel,
	}) => {
		const { t } = useTranslation()
		const dispath = useDispatch()

		const openDialogBoardCreate = () => {
			dispath(dialogActions.setDialog(true))
			dispath(dialogActions.setComponent(createComponent))
		}

		return (
			<div className="tt-w-full">
				<div className="tt-mb-16 tt-flex  tt-justify-between">
					<div className="tt-flex tt-gap-12 ">
						<div className="tt-text-2xl tt-font-semibold  tt-flex-1 tt-max-w-512  tt-w-full">{title}</div>
						{leftTitleComponent}
					</div>

					<div className="tt-flex tt-gap-8 tt-min-h-32">
						{headerTopRightLeft}
						{!hideCreateButton && (
							<Button
								id={getID({ prefix: id, type: 'button_open', action: 'create' })}
								onClick={onCreate ?? openDialogBoardCreate}
								startIcon={<AddIcon />}
								className="tt-bg-primary  hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
							>
								{buttonLabel ?? t('create')}
							</Button>
						)}
						{headerTopRight}
					</div>
				</div>

				{/* <div className="mb-16 mt-6 flex items-center justify-between">
				<div className="flex gap-12 items-center">
					<h2 className="text-3xl font-semibold">{title}</h2>
					{leftTitleComponent}
				</div>

				{headerTopRight}
			</div>

			{!hideButton && (
				<div className="flex items-center w-full gap-10">
					<Paper component="form" className="border flex items-center h-40 rounded-full shadow-none bg-transparent px-12 gap-10 w-full">
						<SearchIcon className="fill-gray-600" />
						<InputBase className="w-full" placeholder="Поиск" />
					</Paper>
					{!hideCreateButton && (
						<Button
							onClick={onCreate ?? openDialogBoardCreate}
							variant="contained"
							startIcon={<AddIcon />}
							className="bg-primary h-40 hover:bg-primary-600 px-24 text-white"
						>
							Создать
						</Button>
					)}
				</div>
			)} */}
			</div>
		)
	}
)

PageHeader.displayName = 'PageHeader'
export default PageHeader
