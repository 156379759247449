import { Box, Tab, TablePagination, Tabs } from '@mui/material'
import { getProject } from 'src/old-app/store/projectsSlice'
import { getTasksDeadlines, requestsActions, getTasks } from 'src/old-app/store/requestSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import RequestsList from 'src/old-app/main/requests/RequestsList'
import { useGetTasksApproveQuery, useGetTasksDeadlineApproveQuery } from 'src/old-app/service/Tasks'

import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'

import DialogMain from '../DialogMain'
import { requestType } from 'src/entities/task'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			className="tt-p-0 tt-m-0 tt-pt-16 "
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const ProjectConfirmation = () => {
	const { t } = useTranslation()
	const [value, setValue] = React.useState(0)

	const dispatch = useDispatch()
	const project = useSelector(getProject)
	const { search } = useLocation()
	const navigate = useNavigate()
	const isAdmin = useSelector(getAdminRole)
	const [searchParams, setSearchParams] = useSearchParams()
	const page = searchParams.get('page') ?? 0
	const rowsPerPage = searchParams.get('rowsPerPage') ?? 5
	const projectId = project?.id

	const handleChange = (event, newValue) => {
		setValue(newValue)
		// navigate({
		// 	search: null,
		// })
	}

	const {
		data: requestTasks,
		isLoading: taskIsLoading,
		refetch: reqTasksRefetch,
	} = useGetTasksApproveQuery(
		{
			current_page: +page + 1,
			per_page: rowsPerPage,
			created_at_sort: 'desc',
			project_id: projectId,
		},
		{ refetchOnMountOrArgChange: true }
	)

	const {
		data: requestTasksDeadlines,
		isLoading: taskDeadlineIsLoading,
		refetch: reqTasksDeadlinesRefetch,
	} = useGetTasksDeadlineApproveQuery(
		{
			current_page: +page + 1,
			per_page: rowsPerPage,
			created_at_sort: 'desc',
			project_id: projectId,
		},
		{ refetchOnMountOrArgChange: true }
	)

	const totalEntries = useMemo(
		() => [requestTasks?.meta?.total_entries ?? 0, requestTasksDeadlines?.meta?.total_entries ?? 0],
		[requestTasks, requestTasksDeadlines]
	)

	// useEffect(() => {
	// 	if (requestTasks) {
	// 		dispatch(
	// 			requestsActions.getTasks({ type: requestType.TASK_STATUS, items: requestTasks?.data ?? [] })
	// 		)
	// 	}
	// 	if (requestTasksDeadlines) {
	// 		dispatch(
	// 			requestsActions.getTasksDeadlines({
	// 				type: requestType.TASKS_DEADLINES,
	// 				items: requestTasksDeadlines?.data ?? [],
	// 				count: requestTasksDeadlines?.data?.length ?? 0,
	// 			})
	// 		)
	// 	}
	// }, [requestTasks, requestTasksDeadlines])

	const switchTabsHandler = () => {
		switch (value) {
			case 0:
				reqTasksRefetch()
				break

			case 1:
				reqTasksDeadlinesRefetch()
				break

			default:
				break
		}
	}

	useEffect(() => {
		switchTabsHandler()
	}, [value])

	const handleChangePage = (event, newPage) => {
		const params = new URLSearchParams(search)
		params.set('page', newPage)
		params.set('rowsPerPage', rowsPerPage)
		navigate({
			search: params.toString(),
		})
		switchTabsHandler()
	}

	const handleChangeRowsPerPage = (event) => {
		const params = new URLSearchParams(search)
		params.set('page', page)
		params.set('rowsPerPage', parseInt(event.target.value, 10))
		navigate({
			search: params.toString(),
		})
		switchTabsHandler()
	}

	if (taskIsLoading && taskDeadlineIsLoading) return null

	return (
		<div className="tt-overflow-hidden  tt-w-full tt-flex tt-flex-col">
			<DialogHeader title={t('confirmation')} />
			<DialogMain>
				<div className="tt-w-full tt-h-full">
					<div className="tt-w-full tt-mx-auto tt-h-full">
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
								<Tab
									sx={{ minHeight: 'auto', whiteSpace: 'nowrap' }}
									iconPosition="end"
									icon={<span className="tab-badge  tt-bg-primary">{totalEntries[0]}</span>}
									label={t('tasks_completion_confirmation')}
									{...a11yProps(0)}
								/>

								<Tab
									sx={{ minHeight: 'auto', whiteSpace: 'nowrap' }}
									iconPosition="end"
									icon={<span className="tab-badge  tt-bg-primary">{totalEntries[1]}</span>}
									label={t('task_deadline_change')}
									{...a11yProps(1)}
								/>
							</Tabs>
						</Box>

						<TabPanel value={value} index={0}>
							<RequestsList type={requestType.TASK_STATUS} disabledLink isProject list={requestTasks?.data ?? []} />
						</TabPanel>

						<TabPanel value={value} index={1}>
							<RequestsList
								type={requestType.TASKS_DEADLINE}
								disabledLink
								isProject
								list={requestTasksDeadlines?.data ?? []}
							/>
						</TabPanel>

						<TablePagination
							component="div"
							count={totalEntries[value]}
							page={+page}
							className="custom-table-pagitiona"
							labelRowsPerPage={t('rows_per_page')}
							showFirstButton
							showLastButton
							rowsPerPageOptions={[3, 5, 10, 15, 20, 50]}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
				</div>
			</DialogMain>
			{/* <DialogFooter rightLabel="Сохранить" /> */}
		</div>
	)
}

export default ProjectConfirmation
