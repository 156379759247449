import React, { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import TasksProvider from '../../../old-app/main/tasks/TasksProvider'
import TaskSideBarCheck from '../../../old-app/components/task/TaskSideBarCheck'
import TaskSideBar from '../../../old-app/components/task/TaskSideBar'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import {
	getProject,
	getProjectBARole,
	getProjectManagerRole,
	getProjectUserRole,
} from 'src/old-app/store/projectsSlice'
import { useSelector } from 'react-redux'
import { useGetUser } from 'src/shared/hooks'
import { ProjectContent, ProjectShowContext } from 'src/entities/project'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { TeamShowContext } from '../../../entities/team'

import TeamContent from '../../../features/team/components/team-content'
import TeamProvider from '../../../entities/team/components/team-provider'
import ProtocolSideBarCheck from '../../../old-app/components/protocol/side/ProtocolSideBarCheck'
import ProtocolSideBar from '../../../old-app/components/protocol/side/ProtocolSideBar'
import ProjectSideBar from '../../../old-app/components/project/ProjectSideBar'
import ProjectSideBarCheck from '../../../old-app/components/project/ProjectSideBarCheck'

const TeamShow = () => {
	const { typeSlug, teamId, taskId, projectId } = useParams()
	const { search } = useLocation()
	const page = `/team/${teamId}`
	const link = `${page}/${typeSlug}${search}`
	const backTo = `${page}/${typeSlug}`

	const paramId = projectId && !taskId ? 'projectId' : 'taskId'

	return (
		<TeamProvider>
			<PageWithSideBar
				param={paramId}
				contentClassName={'!tt-pt-0 !tt-pr-0 !tt-pl-0 !tt-pb-0 !tt-h-full !tt-px-0'}
				pageLink={`${page}/${typeSlug}${search}`}
				content={<TeamContent page={page} />}
				rightSidebarContent={
					<>
						{taskId && (
							<TasksProvider>
								<TaskSideBar backTo={link} />
							</TasksProvider>
						)}
						{projectId && !taskId && <ProjectSideBarCheck backTo={backTo} />}
					</>
				}
			/>
		</TeamProvider>
	)
}

export default TeamShow
