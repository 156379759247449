import React, { memo } from 'react'
import { dialogActions, getDialogComponent, getDialogIsOpen, getDialogState } from 'src/old-app/store/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '@mui/material'
import CreateTask from './task/CreateTask'
import EditDeadline from './task/EditDeadline'
import CreateProject from './project/CreateProject'
import CancelNotif from './notif/CancelNotif'
import CancelRequest from './request/CancelRequest'
import AddTaskReport from './add-report/AddTaskReport'
import AddUserProject from './users-management/AddUserProject'
import DeleteUserProject from './users-management/DeleteUserProject'
import EditUserProject from './users-management/EditUserProject'
import KPGProjectUser from './project/KPGProjectUser'
import CreateStatus from './status/CreateStatus'
import EditStatus from './status/EditStatus'
import SettingProject from './project/SettingProject'
import CreatePriority from './priority/CreatePriority'
import EditPriority from './priority/EditPriority'
import ExportAllMyTasksKPG from './my/ExportAllUserTasksKPG'
import CreateTeam from './team/CreateTeam'
import SpentTimeInput from './task/SpentTimeInput'
import SpentTimeButton from './task/SpentTimeButton'
import AddParam from './task/AddParam'
import Epics from './task/Epics'
import SpentTimeInputHours from './task/SpentTimeInputHours'
import AcceptTask from './task/AcceptTask'
import TimeKPG from './task/TimeKPG'
import CreateProjectTemplate from './project/CreateProjectTemplate'
import ProjectConfirmation from './project/ProjectConfirmation'
import CreateTaskTemplate from './project/CreateTaskTemplate'
import MyAllTasksTimeKPG from './my/MyAllTasksTimeKPG'
import AllKPG from './my/AllKPG'
import CreateTaskForTemplate from './task/CreateTaskForTemplate'
import NotAssigneeTasks from './project-template/NotAssigneeTasks'
import { UCP_APP_NAME } from 'src/shared/config'
import RejectTeam from './team/RejectTeam'
import RejectProject from '../project/RejectProject'
import { useParams } from 'react-router-dom'
import ProjectPublic from './project/project-public'
import { TaskContractType } from './task/TaskContractType'
import { ProjectContractDirectoryCreate } from 'src/@widgets/project'
import { TaskFinish } from 'src/@widgets/task'
import { TaskDeadlineConfirmation } from 'src/@features/task'

const Dialogs = memo(() => {
	const dispatch = useDispatch()

	const isOpenDialog = useSelector(getDialogIsOpen)
	const dialogComponent = useSelector(getDialogComponent)
	const dialogState = useSelector(getDialogState)

	const onCloseDialog = () => {
		dispatch(dialogActions.setDialog(false))
	}

	return (
		<Dialog
			maxWidth={'1500rem'}
			container={document.getElementById('track-root')}
			onClose={onCloseDialog}
			open={isOpenDialog}
		>
			{dialogComponent === 'task-create' && <CreateTask />}
			{dialogComponent === 'task-create-template' && <CreateTaskForTemplate />}
			{dialogComponent === 'task-create-template-epic' && <CreateTaskForTemplate isEpic />}
			{dialogComponent === 'task-create-epic' && <CreateTask isEpic />}
			{dialogComponent === 'my-task-create' && <CreateTask isMy />}
			{dialogComponent === 'edit-deadline' && <EditDeadline />}
			{dialogComponent === 'project-create' && <CreateProject />}
			{dialogComponent === 'project-team-create' && <CreateProject teamId={dialogState?.teamId} />}
			{dialogComponent === 'create-team' && <CreateTeam />}
			{dialogComponent === 'project-child-create' && <CreateProject isCreateChild />}
			{dialogComponent === 'cancel-notif' && <CancelNotif />}
			{dialogComponent === 'cancel-request' && <CancelRequest />}
			{dialogComponent === 'team-reject' && <RejectTeam />}
			{dialogComponent === 'add-task-report' && <AddTaskReport />}
			{dialogComponent === 'project-reject' && <RejectProject />}
			{dialogComponent === 'add-user-project' && <AddUserProject />}
			{dialogComponent === 'delete-user-project' && <DeleteUserProject />}
			{dialogComponent === 'not-assignee-tasks' && <NotAssigneeTasks />}
			{dialogComponent === 'edit-user-project' && <EditUserProject />}
			{dialogComponent === 'project-public' && <ProjectPublic />}
			{dialogComponent === 'kpg-project-user' && <KPGProjectUser />}
			{dialogComponent === 'my-kpg' && <AllKPG />}
			{dialogComponent === 'my-time-kpg' && <MyAllTasksTimeKPG />}
			{dialogComponent === 'time-kpg' && <TimeKPG />}
			{dialogComponent === 'create-project-contract-directory' && <ProjectContractDirectoryCreate />}
			{dialogComponent === 'create-status' && <CreateStatus />}
			{dialogComponent === 'create-priority' && <CreatePriority />}
			{dialogComponent === 'edit-priority' && <EditPriority />}
			{dialogComponent === 'edit-status' && <EditStatus />}
			{dialogComponent === 'accept-task' && <AcceptTask />}
			{dialogComponent === 'setting-project' && <SettingProject />}
			{dialogComponent === 'create-template-project' && <CreateProjectTemplate />}
			{dialogComponent === 'create-template-task' && <CreateTaskTemplate />}
			{dialogComponent === 'spent-time-input' && <SpentTimeInput />}
			{dialogComponent === 'spent-time-input-hours' && <SpentTimeInputHours />}
			{dialogComponent === 'spent-time-button' && <SpentTimeButton />}
			{dialogComponent === 'task-deadline-confirmation' && <TaskDeadlineConfirmation />}
			{dialogComponent === 'add-param' && <AddParam />}
			{dialogComponent === 'epics' && <Epics />}
			{dialogComponent === 'project-template-task-epics' && <Epics isProjectTemplateTask />}
			{dialogComponent === 'project-сonfirmation' && <ProjectConfirmation />}

			{/* {dialogComponent === 'task-finish' && <TaskContractType />} */}
			{/* {dialogComponent === 'task-finish' && <AddTaskReport />} */}
			{dialogComponent === 'task-finish' && <TaskFinish />}
		</Dialog>
	)
})

Dialogs.displayName = 'Dialogs'
export default Dialogs
