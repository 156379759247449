/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
import React from 'react'
import DialogFooter from '../dialogs/DialogFooter'
import DialogHeader from '../dialogs/DialogHeader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { TextareaAutosize } from '@mui/base'
import { useSelector } from 'react-redux'
import { getNotif, notifsActions } from 'src/old-app/store/notifSlice'
import { useDeclineProjectMutation, useRejectProjectMutation } from 'src/old-app/service/Projects'
import { notifTypes } from 'src/old-app/constants/notifications'
import { useDispatch } from 'react-redux'
import { dialogActions, getDialogState } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useDeleteOneNotifMutation } from 'src/old-app/service/Notif'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import {
	useCancelTaskDeadlineMutation,
	useCancelTaskMutation,
	useDeclineTaskDeadlineMutation,
	useDeclineTaskMutation,
	useUpdateTaskMutation,
} from 'src/old-app/service/Tasks'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getRequest, requestsActions } from 'src/old-app/store/requestSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { requestType } from 'src/entities/task'
import DialogMain from '../dialogs/DialogMain'
import ErrorText from '../ui/ErrorText'
import { useTranslation } from 'react-i18next'

const RejectProject = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		comment: yup.string().required(t('comment_is_required')),
	})
	const {} = useParams()
	const navigate = useNavigate()
	const { search, pathname } = useLocation()
	const dispatch = useDispatch()
	const [rejectProject] = useRejectProjectMutation()
	const dialogState = useSelector(getDialogState)

	const {
		setValue,
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const closeHandler = (message) => {
		dispatch(
			showMessage({
				message,
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(dialogActions.setDialog(false))
		dispatch(interfaceActions.setIsLoading(false))
		navigate(`${pathname}${search}`)
	}

	const rejectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		await rejectProject({
			project_id: dialogState?.projectId,
			...data,
		})
		closeHandler(t('rejected'))
	}
	return (
		<form onSubmit={handleSubmit(rejectHandler)} className="tt-w-600">
			<DialogHeader title={t('cancel_with_comment')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('comment')}</span>
						<TextareaAutosize
							minRows={3}
							maxRows={10}
							{...register('comment')}
							error={!!errors.comment}
							placeholder={t('comment')}
							size="small"
							aria-label="minimum height"
							className="tt-border tt-border-gray-300 tt-p-12 tt-rounded-lg"
						/>
						<ErrorText errors={errors} field="comment" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter rightLabel={t('send')} />
		</form>
	)
}

export default RejectProject
