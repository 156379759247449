import { Button, InputBase, Popover } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useDebounce from 'src/old-app/hooks/useDebounce'
import AddIcon from '@mui/icons-material/Add'
import MySelect from '../ui/MySelect'
import { useDispatch } from 'react-redux'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useCreateTaskHashtagMutation, useGetTaskHashtagQuery } from 'src/old-app/service/Tasks'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import { checkPublic } from 'src/shared/lib/check-public'
import { useProjectShow } from 'src/entities/project'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const labelRender = (value) => value?.hashtag

const TaskHashtagSelect = ({ useInnerValue, value, onChange, idAddComponent, projectId,hideAdd, projectIds, id, ...props }) => {
	const { t } = useTranslation()
	const [search, setSearch] = useState('')
	const isPublic = checkPublic()

	const projectIdsArr = [...projectIds, projectId].join(",")

	const {
		data: hashtagsData,
		refetch,
	} = useGetTaskHashtagQuery({
		hashtag: search,
		current_page: 1,
		per_page: 250,
		project_id: projectIdsArr
	})

	const allHashtagsQuery = useGetTaskHashtagQuery({
		hashtag: "",
		current_page: 1,
		per_page: 250,
		project_id: projectIdsArr
	})
	
	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) refetch()
	}, [debouncedSearch])

	const [selected, setSelected] = useState(props.multiple ? [] : '')
	const isSelectedEmpty = Boolean(selected?.length)

	useEffect(() => {
		if (!isSelectedEmpty && allHashtagsQuery.isSuccess)
			setSelected(value.map((tag) => allHashtagsQuery.data?.data.find((_tag) => _tag.id === tag)))
	}, [value, allHashtagsQuery.isSuccess])

	function _onChange(item) {
		setSelected(item)
		onChange?.(item)
	}

	const getHashtags = hashtagsData?.data ?? []
	const hashtags = props.filterHashtags ? props.filterHashtags(getHashtags) : getHashtags
	return (
		<>
			<MySelect
				label="HashtagSelect"
				idSelect={getID({ prefix: id, field: 'hashtags', type: 'select' })}
				// idSelect={getFieldID({})}
				// disabled={!isSuccess}
				addComponent={!isPublic && !hideAdd && <AddComponent projectId={projectId} id={idAddComponent} refetch={refetch} />}
				addLabel={t('add')}
				menuItemRander={labelRender}
				labelRender={labelRender}
				items={hashtags}
				isSearch
				setSearch={setSearch}
				search={search}
				value={selected}
				onChange={_onChange}
				{...props}
			/>
		</>
	)
}

const _addComponentIDS = {
	openPopover: getID({ entity: 'hashtag', action: 'create', type: 'button_open' }),
	tagName: getID({ entity: 'hashtag', action: 'create', type: 'text' }),
	submit: getID({ entity: 'hashtag', action: 'create', type: 'button_submit' }),
}

function AddComponent({ refetch,projectId }) {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const [hashtag, setHashtag] = useState('')
	const projectShow = useProjectShow()

	const isCommittee = projectShow?.isCommittee

	const open = Boolean(anchorEl)
	const popoverId = open ? 'simple-popover' : undefined

	const dispatch = useDispatch()

	const [createHashtag, result] = useCreateTaskHashtagMutation()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const createHashtagHandler = async () => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const hashtagData = await createHashtag({ hashtag,project_id: projectId })

			if (!hashtagData?.data) return null
			setHashtag('')
			handleClose()
			refetch()
			dispatch(interfaceActions?.setIsLoading(false))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<div>
			<CommentedDisability
				optionDisabled={isCommittee}
				disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
			>
				<Button
					aria-describedby={popoverId}
					onClick={handleClick}
					id={_addComponentIDS.openPopover}
					startIcon={<AddIcon className={'tt-text-gray-500'} />}
					className="tt-border-gray-200 tt-mr-16"
					variant="outlined"
					size={'small'}
					// type={'button'}
				>
					{t('create')}
				</Button>
			</CommentedDisability>

			<Popover
				id={popoverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<div className="tt-flex tt-items-center tt-gap-6 tt-px-16 tt-py-6">
					<InputBase
						value={hashtag}
						id={_addComponentIDS.tagName}
						onChange={(event) => setHashtag(event.target.value)}
						className="w-full"
						placeholder={t('title')}
					/>

					<Button
						onClick={createHashtagHandler}
						id={_addComponentIDS.submit}
						startIcon={<AddIcon />}
						className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white tt-w-160  tt-text-sm tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('add')}
					</Button>
				</div>
			</Popover>
		</div>
	)
}

export default TaskHashtagSelect
