/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useCheckProjectMutation,
	useCreateProjectHashtagLinkMutation,
	useCreateProjectMutation,
	useCreateProjectTemplateMutation,
	useCreateUserProjectMutation,
} from 'src/old-app/service/Projects'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import ErrorText from '../../ui/ErrorText'
import UsersSelect from '../../shared/UserSelect'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import moment from 'moment'
import getSlug from 'src/old-app/utils/getSlug'
import ProjectHashtagSelect from '../../shared/ProjectHashtagSelect'
import { getProject } from 'src/old-app/store/projectsSlice'
import { useCreateTeamsMutation, useCreateTeamUsersMutation } from 'src/old-app/service/Teams'
import { useTranslation } from 'react-i18next'

const CreateProjectTemplate = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		name: yup.string().required(t('template_title_is_required')),
	})
	const dispatch = useDispatch()
	const project = useSelector(getProject)

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		setError,
	} = useForm({
		defaultValues: {
			users: [],
			hashtags: [],
			name: '',
		},
		resolver: yupResolver(schema),
	})

	const [createProjectTemplate, result] = useCreateProjectTemplateMutation()

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))

			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					// message: 'Проект создан',
					message: t('successfully_created.team'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}
	}, [result])

	const createHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))

			const body = {
				name: data.name,
			}

			await createProjectTemplate({ id: project?.id, body })

			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.template'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(createHandler)} className="tt-w-600">
			<DialogHeader title={t('new.template')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									onChange={onChange}
									error={!!errors.name}
									hiddenLabel
									placeholder={t('title')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="name"
							control={control}
						/>

						<ErrorText errors={errors} field="name" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter />
		</form>
	)
}

export default CreateProjectTemplate
