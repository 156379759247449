import CardContent from '@mui/material/CardContent'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetTasksQuery } from 'src/old-app/service/Tasks'
import { Skeleton } from '@mui/material'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import BoardAddCard from '../board-card/BoardAddCard'
import BoardCard from '../board-card/BoardCard'
import BoardListHeader from './BoardListHeader'
import { getTask } from 'src/old-app/store/tasksSlice'
import { TASK_UPDATE } from 'src/shared/config'
import { getIsAutoUpdate } from 'src/old-app/store/interfaceSlice'
import { setParamsFilters } from '../../TaskList'
import { getProject, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import queryString from 'query-string'
import { useProjectShow } from '../../../../../entities/project'
import { VirtualizedListElement, VirtualizedListWrapper } from 'src/@shared/lib/VerticalListVirtualizer'
import {
	DragNDropContext,
	ColumnDragNDropWrapper,
	EmptyColumnPlaceholder,
} from 'src/old-app/main/tasks/scrumboard/components/ScrumboardDragNDrop'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import { useTranslation } from 'react-i18next'

const skeletonArray = [randomInteger(50, 100), randomInteger(50, 100), randomInteger(75, 125)]

function randomInteger(min, max) {
	// получить случайное число от (min-0.5) до (max+0.5)
	const rand = min - 0.5 + Math.random() * (max - min + 1)
	return Math.round(rand)
}

const defaultCardsPerColumn = 20

function BoardColumn(props) {
	const { list, listId, columnIndex } = props
	const dispatch = useDispatch()
	const isUser = useSelector(getProjectUserRole)
	const project = useSelector(getProject)
	const isAdmin = useSelector(getAdminRole)
	const { projectId } = useParams()
	const projectShow = useProjectShow()
	const [searchParams] = useSearchParams()
	const isAutoUpdate = useSelector(getIsAutoUpdate)
	const parsedSearch = queryString.parse(searchParams.toString())
	const contentScrollEl = useRef(null)
	const totalCards = useRef(0)
	const checkSumm = useRef('')
	const columnGrabArea = useRef(null)

	const { draggableCard, draggableColumn } = useContext(DragNDropContext)

	const {
		searchTask,
		slug,
		deadline_sort: deadlineSort,
		deadline_start: deadlineStart,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		fields = JSON.stringify({}),
	} = parsedSearch

	const [assigneeValue, priorityValue, authorValue, typeValue, tagsValue, fieldsValue] = [
		assignee,
		priority,
		author,
		type,
		tags,
		fields,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		priority: priorityValue,
		assignee: assigneeValue,
		tags: tagsValue,
	})

	const [cardsPerColumn, setCardsPerColumn] = useState(defaultCardsPerColumn)

	function loadMoreCards() {
		if (cardsPerColumn < totalCards.current) {
			setCardsPerColumn(cardsPerColumn + defaultCardsPerColumn)
		}
	}

	const isCurrentlyDragging = Boolean(draggableCard) || Boolean(draggableColumn)

	const {
		data: tasks,
		isLoading,
		isError,
	} = useGetTasksQuery(
		{
			url: multipleParamsString,
			params: {
				project_id: projectId,
				project_status_id: listId,
				order_sort: 'asc',
				current_page: 1,
				per_page: cardsPerColumn,
				deadline_sort: deadlineSort ?? undefined,
				title: searchTask,
				slug,
				start_date_deadline: deadlineStart,
				end_date_deadline: deadlineEnd,
				start_completed_at: completedAtStart,
				end_completed_at: completedAtEnd,
				end_date_created_at: createdAtEnd,
				start_date_created_at: createdAtStart,
				for_board: true,
				need_files: true,
				need_complete_files: true,
				...fieldsValue,
			},
		},
		{
			pollingInterval: isAutoUpdate && !isCurrentlyDragging ? TASK_UPDATE : false,
		}
	)

	useEffect(() => {
		if (!tasks?.data) return
		///////////////////////// Кэширование отключено, т.к. нет проверки на фронте, осталась ли карточка в столбце куда ее перекинули. Возможно, кэширование в стор придется перенести
		// let inProgressCheckSumm = ''
		// for (let i = 0; i < tasks?.data?.length; i++) {
		// 	inProgressCheckSumm += tasks?.data[i]?.id
		// 	inProgressCheckSumm += tasks?.data[i]?.updated_at
		// }
		// const clearedCheckSumm = inProgressCheckSumm.replace(/[^A-Za-z0-9]/g, '')
		// if (checkSumm.current !== clearedCheckSumm) {
		// 	checkSumm.current = clearedCheckSumm
		// 	totalCards.current = tasks?.meta?.total_entries
		// 	dispatch(scrumboardActions.createListCard({ list: { ...list, id: listId }, cards: tasks.data }))
		// }
		totalCards.current = tasks?.meta?.total_entries
		dispatch(scrumboardActions.createListCard({ list: { ...list, id: listId }, cards: tasks.data }))
	}, [tasks, listId])

	if (isLoading)
		return (
			<div className="tt-min-w-320 tt-w-320 tt-overflow-x-hidden tt-max-h-full tt-p-12 tt-flex tt-flex-col tt-rounded-xl tt-border tt-bg-[#edf0f5]">
				<CardContent
					className="tt-flex tt-flex-col tt-flex-auto tt-h-full tt-min-h-0 tt-w-full tt-p-0 tt-overflow-auto"
					ref={contentScrollEl}
				>
					{skeletonArray.map((item, index) => (
						<Skeleton
							key={index}
							className="tt-flex tt-flex-col tt-w-full tt-h-full tt-min-h-1 tt-mb-12"
							sx={{ bgcolor: 'white' }}
							height={item}
						/>
					))}
				</CardContent>
			</div>
		)

	const { cards = [] } = list

	const isShowCreateTaskButton = projectShow?.isShowCreateTaskButton
		? isUser
			? project?.each_member_can_create_task
			: true
		: false

	const canAddCard = !projectShow?.project?.isEnd && isShowCreateTaskButton && !list?.is_request && !list?.is_final

	const columnHandlingParams = {
		columnID: listId,
		columnOrder: list?.order,
		statusIsInitial: list?.is_initial,
		statusIsRequest: list?.is_request,
		statusIsFinal: list?.is_final,
		userIsGlobalAdmin: isAdmin,
		userIsUser: isUser,
		projectIsClosed: project?.isEnd,
		projectIsAdministrated: project?.with_administration,
		projectIsCommittee: projectShow?.isCommittee,
	}

	return (
		<ColumnDragNDropWrapper
			grabArea={columnGrabArea}
			columnID={listId}
			columnIndex={columnIndex}
			columnHandlingParams={columnHandlingParams}
		>
			<div className="tt-flex tt-flex-col tt-relative tt-w-320 tt-overflow-x-hidden tt-max-h-full tt-rounded-xl tt-border tt-bg-[#edf0f5] tt-pb-12">
				<div ref={columnGrabArea}>
					<BoardListHeader
						list={list}
						listId={listId}
						length={cards.length}
						totalCards={totalCards.current}
						className="tt-border-b-1"
					/>
				</div>
				{cards?.length > 0 ? (
					<div
						id="card_content"
						className="tt-flex tt-flex-col tt-flex-auto tt-h-full tt-min-h-0 tt-w-full tt-p-2 tt-overflow-auto"
						ref={contentScrollEl}
					>
						<VirtualizedListWrapper
							totalElements={cards?.length}
							unvirtualizedElementsLimit={3}
							overflowElements={2}
							estimatedElementHeight={200}
							externalScrollableComponent={contentScrollEl}
							fixedElementIndex={draggableCard?.columnID === listId ? draggableCard?.cardIndex : undefined}
							onLastIsMounted={loadMoreCards}
							autoScrollIsEnabled={Boolean(draggableCard)}
							spacersSX={{ backgroundColor: 'white', opacity: '0.5', margin: '0 1.2rem' }}
						>
							<div className="tt-flex tt-flex-col tt-w-full tt-h-full tt-px-12">
								{cards?.map((card, index) => (
									<VirtualizedListElement key={card?.id} elementID={card?.id} elementIndex={index}>
										<BoardCard card={card} index={index} columnHandlingParams={columnHandlingParams} />
									</VirtualizedListElement>
								))}
							</div>
						</VirtualizedListWrapper>
					</div>
				) : (
					<EmptyColumnPlaceholder
						columnID={listId}
						columnIndex={columnIndex}
						columnHandlingParams={columnHandlingParams}
					/>
				)}
				{canAddCard && <BoardAddCard listId={listId} className="tt-p-12 tt-pb-0 tt-overflow-y-auto tt-shrink-0" />}
			</div>
		</ColumnDragNDropWrapper>
	)
}

export default BoardColumn
