import { getProject } from 'src/old-app/store/projectsSlice'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetProjectParamsParamQuery } from 'src/old-app/service/Projects'
import MySelect from '../ui/MySelect'

const usersLabelRender = (value) => {
	return value?.label
}

const ParamValuesSelect = (props) => {
	const project = useSelector(getProject)
	const [search, setSearch] = useState('')
	// console.log({ props });
	// const [users, setUsers] = useState([])
	const {
		data: paramsData,
		// isLoading,
		refetch,
	} = useGetProjectParamsParamQuery({
		param: props.param,
		id: props?.id ?? project?.id,
	})

	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) refetch()
	}, [debouncedSearch])

	const params =
		paramsData?.data
			?.filter((item) => item != null)
			.map((item) => {
				// console.log('item', item)
				if (item) {
					return { id: item, label: item }
				}
			}) ?? []

	// console.log('params', params)

	return (
		<>
			<MySelect
				menuItemRander={usersLabelRender}
				labelRender={usersLabelRender}
				items={params}
				// isSearch
				setSearch={setSearch}
				selectClassName="new-select tt-border-gray-100"
				wrapperClassName="tt-w-full"
				hideError
				hideLabel
				search={search}
				{...props}
			/>
		</>
	)
}

export default ParamValuesSelect
