import React, { useState } from 'react'
import { PathnameCell } from '../../../../shared/components'
import Button from '@mui/material/Button'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import MenuItem from '@mui/material/MenuItem'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { alpha, styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import { useConfirmTeamMutation } from '../../../../old-app/service/Teams'
import { useDispatch } from 'react-redux'
import { dialogActions } from '../../../../old-app/store/dialogSlice'
import { interfaceActions } from '../../../../old-app/store/interfaceSlice'
import { useTranslation } from 'react-i18next'

const TeamRequestButton = ({ teamId }) => {
	const { t } = useTranslation()

	const [confirmTeam] = useConfirmTeamMutation()

	const dispatch = useDispatch()

	const confirmHandler = async () => {
		dispatch(interfaceActions.setIsLoading(true))
		try {
			await confirmTeam({ command_id: teamId })
			dispatch(interfaceActions.setIsLoading(false))
		} catch (error) {
			console.error(error)
			dispatch(interfaceActions.setIsLoading(false))
		}
	}
	const rejectHandler = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('team-reject'))
		dispatch(dialogActions.setDialogState({ teamId }))
	}

	// @ts-ignore
	return (
		<PathnameCell>
			<div className="tt-w-[140px] tt-flex tt-items-center tt-gap-4 row-hover tt-cursor-default">
				<div className="tt-w-[140px] tt-flex tt-items-center tt-gap-4 row-hover-first">
					<div
						onClick={confirmHandler}
						className="tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-green-600/10 tt-border tt-border-green-600/20 "
					>
						<DoneOutlinedIcon className="tt-text-green-500 tt-storke-2 tt-text-20" />
					</div>
					<div
						onClick={rejectHandler}
						className="tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-red-600/10 tt-border tt-border-red-600/20 "
					>
						<CloseOutlinedIcon className="tt-text-red-500 tt-storke-2 tt-text-20" />
					</div>
				</div>

				<Button className=" tt-bg-primary-transparent tt-px-[8px] tt-h-[23px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px] row-hover-second">
					<TouchAppOutlinedIcon className="tt-w-22 tt-h-22" />
					{t('confirmation_decision')}
				</Button>

				{/*<Button*/}
				{/*    id="demo-customized-button"*/}
				{/*    aria-describedby={idCP}*/}
				{/*    aria-controls={open ? 'demo-customized-menu' : undefined}*/}
				{/*    aria-haspopup="true"*/}
				{/*    aria-expanded={open ? 'true' : undefined}*/}
				{/*    disableElevation*/}
				{/*    onClick={handleClick}*/}
				{/*    className="tt-bg-primary-transparent tt-px-[8px] tt-h-[23px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px]"*/}
				{/*>*/}
				{/*    <TouchAppOutlinedIcon className="tt-w-22 tt-h-22" />*/}
				{/*    Решение*/}
				{/*</Button>*/}
				{/*<StyledMenu*/}
				{/*    id="demo-customized-menu"*/}
				{/*    MenuListProps={{*/}
				{/*        'aria-labelledby': 'demo-customized-button',*/}
				{/*    }}*/}
				{/*    anchorEl={anchorEl}*/}
				{/*    open={open}*/}
				{/*    onClose={handleClose}*/}
				{/*>*/}
				{/*    <MenuItem onClick={confirmHandler} disableRipple>*/}
				{/*        <DoneOutlinedIcon />*/}
				{/*        Подтвердить*/}
				{/*    </MenuItem>*/}
				{/*    <MenuItem onClick={rejectHandler} disableRipple>*/}
				{/*        <CloseOutlinedIcon />*/}
				{/*        Отклонить*/}
				{/*    </MenuItem>*/}
				{/*</StyledMenu>*/}
			</div>
		</PathnameCell>
	)
}

export default TeamRequestButton
