import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	protocols: [],
	places: [],
	protocol: null,
	protocolUsers: [],
	protocolAssignee: [],
	protocolTask: [],
}

const reducers = {
	getProtocols: (state, action) => {
		state.protocols = action.payload
	},
	getProtocolAssignee: (state, action) => {
		state.protocolAssignee = action.payload
	},
	getProtocolTask: (state, action) => {
		state.protocolTask = action.payload
	},
	getProtocolUsers: (state, action) => {
		state.protocolUsers = action.payload
	},
	getProtocol: (state, action) => {
		state.protocol = action.payload
	},
	getPlaces: (state, action) => {
		state.places = action.payload
	},
}

const protocolSlice = createSlice({
	name: 'protocol',
	initialState,
	reducers,
})

export const gerProtocols = (state) => state.protocol.protocols
export const gerOneProtocol = (state) => state.protocol.protocol
export const getProtocolUsers = (state) => state.protocol.protocolUsers
export const getProtocolAssignee = (state) => state.protocol.protocolAssignee
export const getProtocolTask = (state) => state.protocol.protocolTask
export const getPlaces = (state) => state.protocol.places

export const protocolReducer = protocolSlice.reducer
export const protocolActions = protocolSlice.actions
