import { useResumeProjectMutation, useCompleteProjectMutation } from '../../../old-app/service/Projects'

const useProjectEnd = (projectId: string) => {
	const [resumeProject] = useResumeProjectMutation()
	const [completeProject] = useCompleteProjectMutation()

	const endProjectHandler = async () => {
		await completeProject(projectId)
	}
	const resumeProjectHandler = async () => {
		await resumeProject(projectId)
	}

	return { endProject: endProjectHandler, resumeProject: resumeProjectHandler }
}
export default useProjectEnd
