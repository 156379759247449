import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import TeamSideBarCheck from '../../../old-app/components/team/TeamSideBarCheck'
import { ProjectRequestTable } from 'src/entities/project'
import ProjectSideBar from '../../../old-app/components/project/ProjectSideBar'
import ProjectSideBarCheck from '../../../old-app/components/project/ProjectSideBarCheck'
import { useTranslation } from 'react-i18next'

interface IProps {}

const ProjectRequests: React.FC<IProps> = () => {
	const { t } = useTranslation()
	const { projectId } = useParams()
	const { search } = useLocation()

	const page = `/project-requests/${projectId}`
	const backTo = `/project-requests`

	return (
		<PageWithSideBar
			param="projectId"
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			pageLink={`${page}/${search}`}
			buttonLabel={undefined}
			content={<ProjectRequestTable />}
			rightSidebarContent={<ProjectSideBarCheck backTo={backTo} paramId={undefined} />}
			// rightSidebarContent={<ProjectSideBar isHideSideBar={undefined} />}
			headerTitle={t('projects_confirmation')}
			headerCreateComponent={undefined}
			headerOnCreate={undefined}
			headerTopRight={undefined}
			headerTopRightLeft={undefined}
			hideCreateButton
			id={undefined}
			leftTitleComponent={undefined}
			showCreateButton={undefined}
		/>
	)
}

export default ProjectRequests
