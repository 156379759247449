import { Button } from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import React, { useContext } from 'react'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { TaskContext } from '../../contexts'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { PathnameCell } from '../../../../shared/components'
import clsx from 'clsx'

interface IProps {
	label?: string
	className?: string
}

const TaskAcceptButton: React.FC<IProps> = ({ label, className }) => {
	const dispatch = useDispatch()
	const { task }: { task: any } = useContext(TaskContext)

	if (!task?.incoming) return null

	const openAcceptTask = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(tasksActions.getTask(task))
		dispatch(dialogActions.setComponent('accept-task'))
	}
	return (
		<PathnameCell>
			<Button
				onClick={openAcceptTask}
				className={clsx(
					'tt-whitespace-nowrap tt-bg-primary-transparent tt-h-20 tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-20',
					className
				)}
			>
				<CheckCircleIcon className="tt-w-20 tt-h-20" />
				{label}
			</Button>
		</PathnameCell>
	)
}

export default TaskAcceptButton
