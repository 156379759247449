/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import Button from 'src/shared/components/button'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateProjectContractDirectoryMutation } from 'src/old-app/service/Projects'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ProjectContractCategory } from '../project-contract-category/project-contract-category'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { uniqueId } from 'lodash'
import { InputContainer } from '../../../../shared/components'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useTranslation } from 'react-i18next'

interface ParametersRegulatoryDocument {
	id?: Id
	uuid?: Id
	name: string
	price: number | null
}

interface ProjectContractDirectoryData {
	category: { id: string }
	type_regulatory_document: string
	is_parameters_regulatory_document: boolean
	parameters_regulatory_document: ParametersRegulatoryDocument[]
	pricing: number | null
}

interface ContractTypeEditProps {
	setIsEdit: (isEdit: boolean) => void
	contractType: any
}

export const ContractTypeEdit: React.FC<ContractTypeEditProps> = ({ setIsEdit, contractType }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		category: yup.object().required(t('category_is_required')).nullable(),
		type_regulatory_document: yup.string().required(t('regulatory_document_type_is_required')),
		parameters_regulatory_document: yup.array().of(
			yup.object().shape({
				name: yup.string().required(t('title_is_required')).nullable(),
				price: yup.string().required(t('price_is_required')).nullable(),
			})
		),
		pricing: yup
			.string()
			.when('is_parameters_regulatory_document', {
				is: true,
				then: yup.string().required(t('price_is_required')).nullable(),
			})
			.nullable(),
	})

	const dispatch = useDispatch()
	const [updateProjectContractDirectory] = useUpdateProjectContractDirectoryMutation()

	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
	} = useForm<ProjectContractDirectoryData>({
		defaultValues: {
			pricing: null,
			parameters_regulatory_document: [],
			is_parameters_regulatory_document: true,
		},
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (contractType) {
			setValue('category', { id: contractType.category })
			setValue('type_regulatory_document', contractType.name)
			setValue('pricing', contractType.price)
			setValue(
				'parameters_regulatory_document',
				contractType.params.map((param: any) => ({ ...param, uuid: param.id }))
			)
		}
	}, [contractType])

	const parametersRegulatoryDocument = watch('parameters_regulatory_document')
	const isParameters = parametersRegulatoryDocument?.length !== 0

	useEffect(() => {
		setValue('is_parameters_regulatory_document', !isParameters)
	}, [isParameters])

	const createParameter = () => {
		setValue('parameters_regulatory_document', [
			...parametersRegulatoryDocument,
			{
				uuid: uniqueId(),
				name: '',
				price: null,
			},
		])
	}

	const removeParameter = (id: Id) => {
		setValue(
			'parameters_regulatory_document',
			parametersRegulatoryDocument.filter((p) => p.id !== id)
		)
	}

	const updateContractDirectoryHandler = async (data: ProjectContractDirectoryData) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const body = {
				body: {
					category: data.category.id,
					name: data.type_regulatory_document,
					params:
						data?.parameters_regulatory_document?.length === 0
							? undefined
							: data.parameters_regulatory_document.map((parameter) => ({
									id: parameter?.id ? parameter?.id : undefined,
									name: parameter.name,
									price: parameter.price ? +parameter.price : parameter.price,
							  })),
					price: data?.parameters_regulatory_document?.length !== 0 ? 0 : data.pricing ? +data.pricing : 0,
				},
				id: contractType.id,
			}

			await updateProjectContractDirectory(body)
			dispatch(interfaceActions.setIsLoading(false))
			setIsEdit(false)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<form className="tt-min-h-full tt-flex tt-flex-col" onSubmit={handleSubmit(updateContractDirectoryHandler)}>
			<div className="tt-min-h-full tt-flex-1 tt-grow">
				<div className="tt-grid tt-py-16 tt-px-24 tt-grid-cols-6 tt-gap-8">
					<InputContainer label={t('regulatory_works_category')} className="tt-col-span-6" error={errors?.category}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectContractCategory
									placeholder={t('regulatory_works_category')}
									value={value}
									onChange={onChange}
								/>
							)}
							name="category"
							control={control}
						/>
					</InputContainer>
					<InputContainer
						label={t('regulatory_document_type')}
						className="tt-col-span-6"
						error={errors?.type_regulatory_document}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									onChange={onChange}
									error={!!errors.type_regulatory_document}
									hiddenLabel
									placeholder={t('regulatory_document_type')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="type_regulatory_document"
							control={control}
						/>
					</InputContainer>

					<Button
						type="button"
						variant="outlined"
						onClick={createParameter}
						className="tt-col-span-6 tt-flex tt-items-center"
					>
						<AddIcon className="tt-w-20 tt-h-20" />
						{t('add_parameter')}
					</Button>
					<Controller
						render={({ field: { value, onChange } }) => (
							<div className="tt-col-span-6">
								{value.map((v, i) => (
									<div className="tt-flex tt-items-center tt-gap-8 tt-mt-8">
										<InputContainer
											labelClassName="tt-whitespace-nowrap"
											error={
												errors?.parameters_regulatory_document
													? errors?.parameters_regulatory_document[i]?.name
													: undefined
											}
											label={t('regulatory_document_parameters')}
											className="tt-w-full"
										>
											<TextField
												value={v.name}
												onChange={(event) => {
													onChange(
														value.map((vm) => {
															if (vm.uuid === v.uuid) {
																vm.name = event.target.value
															}
															return vm
														})
													)
												}}
												error={
													errors?.parameters_regulatory_document
														? !!errors?.parameters_regulatory_document[i]?.name
														: undefined
												}
												hiddenLabel
												placeholder={t('regulatory_document_parameters')}
												className="dialig-input"
												size="small"
											/>
										</InputContainer>
										<InputContainer
											error={
												errors?.parameters_regulatory_document
													? errors?.parameters_regulatory_document[i]?.price
													: undefined
											}
											label={t('price_rub')}
											className="tt-w-full"
										>
											<TextField
												value={Number(v.price).toLocaleString('ru')}
												onChange={(event) => {
													onChange(
														value.map((vm) => {
															if (vm.uuid === v.uuid) {
																let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
																if (enteredValue?.length > 0 && enteredValue[0] === '0') {
																	enteredValue = enteredValue.slice(1)
																}
																vm.price = Number(enteredValue)
															}
															return vm
														})
													)
												}}
												error={
													errors?.parameters_regulatory_document
														? !!errors?.parameters_regulatory_document[i]?.name
														: undefined
												}
												inputProps={{ maxLength: 11 }}
												hiddenLabel
												placeholder={t('price_rub')}
												className="dialig-input"
												size="small"
												InputProps={{
													startAdornment: <InputAdornment position="start">₽</InputAdornment>,
												}}
											/>
										</InputContainer>

										<IconButton className="!tt-h-32 !tt-w-32" onClick={() => v.uuid && removeParameter(v.uuid)}>
											<DeleteOutlinedIcon />
										</IconButton>
									</div>
								))}
							</div>
						)}
						name="parameters_regulatory_document"
						control={control}
					/>

					{!isParameters && (
						<InputContainer label={t('price_rub')} className="tt-col-span-6" error={errors?.pricing}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={Number(value).toLocaleString('ru')}
										error={!!errors.pricing}
										hiddenLabel
										onChange={(event) => {
											let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
											if (enteredValue?.length > 0 && enteredValue[0] === '0') {
												enteredValue = enteredValue.slice(1)
											}
											onChange(enteredValue)
										}}
										inputProps={{ maxLength: 11 }}
										placeholder={t('price_rub')}
										className="dialig-input"
										size="small"
										InputProps={{
											startAdornment: <InputAdornment position="start">₽</InputAdornment>,
										}}
									/>
								)}
								name="pricing"
								control={control}
							/>
						</InputContainer>
					)}
				</div>
			</div>

			<div className="tt-left-0  tt-w-full tt-sticky tt-z-20 tt-bottom-0">
				<div className="tt-w-full tt-max-w-5xl tt-px-24 tt-mx-auto tt-flex tt-gap-12 tt-items-center  tt-py-24 tt-border-t tt-border-gray-200 tt-bg-gray-50">
					<Button
						type="button"
						onClick={() => setIsEdit(false)}
						variant="outlined"
						className="tt-border-gray-200 tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('cancel')}
					</Button>
					<Button
						type="submit"
						variant="contained"
						className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('save')}
					</Button>
				</div>
			</div>
		</form>
	)
}
