import { TableCell } from '@mui/material'
import React, { FC, memo } from 'react'
import clsx from 'clsx'
import { TableCellProps } from '@mui/material/TableCell/TableCell'

const BodyCell: React.FunctionComponent<TableCellProps> = memo(({ className, ...props }) => {
	return (
		<TableCell className={clsx(['tt-px-16 tt-py-8 tt-border-b tt-border-gray-200', className])} {...props}>
			{props.children}
		</TableCell>
	)
})

BodyCell.displayName = 'BodyCell'
export default BodyCell
