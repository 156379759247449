import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { exportFile } from 'src/old-app/service/File'
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import download from 'src/old-app/utils/download'
import { Button } from 'src/shared/components'
import { COMMITTEES_API_URL } from 'src/shared/config';

interface ProtocolDownloadProps {
  protocolId: string,
  name: string,
}

export const ProtocolDownload: React.FC<ProtocolDownloadProps> = ({ protocolId, name }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const downloadProtocol = async () => {
    try {
      dispatch(interfaceActions.setIsLoading(true));
      const type = 'pdf';

      const exportData: any = await exportFile({ baseUrl: COMMITTEES_API_URL, url: `/ProtocolEvent/sap/${protocolId}`, params: {} });

      const contentType = exportData.headers['content-type'];
      const fileName = `${name}.${type}`;

      download(exportData.data, contentType, fileName);
      dispatch(interfaceActions?.setIsLoading(false));

    } catch (error) {
      dispatch(interfaceActions?.setIsLoading(false));
    }
  }

  return (
    <Button
      className="tt-bg-primary/10 h-40 hover:tt-bg-primary-600/10 tt-text-primary  tt-min-h-32 tt-h-32 tt-px-16"
      variant="text"
      onClick={downloadProtocol}
    >
      <DownloadingOutlinedIcon className="tt-text-primary" />
      {t(`download_protocol`)}
    </Button>
  )
}