import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getTask, getTimeSpentInputHoursId } from 'src/old-app/store/tasksSlice'
import { useCreateTimeTrackInputHoursMutation, useUpdateTimeTrackInputHoursMutation } from 'src/old-app/service/Tasks'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import ErrorText from '../../ui/ErrorText'
import moment from 'moment/moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function withDotHours(value) {
	return value.replace(',', '.')
}

const SpentTimeInputHours = () => {
	const { t } = useTranslation()
	const errMessage = t('time_spent_in_hours')
	function humanizeHours(value) {
		const numberValue = value.replace(',', '.')
		const duration = moment.duration(numberValue, 'hours')
		const hours = Math.floor(duration.asHours())
		const minutes = duration.subtract(hours, 'hours').minutes()
		const hoursWithText = hours ? `${hours} ${t('time.hours')}` : ''
		const minutesWithText = minutes ? ` ${minutes} ${t('time.minutes')}` : ''
		const humanizedTime = (hoursWithText + minutesWithText).trim()
		return humanizedTime
	}
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const timeSpentInputHoursId = useSelector(getTimeSpentInputHoursId)

	const [createTimeTrackInputHours] = useCreateTimeTrackInputHoursMutation()
	const [updateTimeTrackInputHours] = useUpdateTimeTrackInputHoursMutation()

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	})

	const createProjectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		const parsedHours = +withDotHours(data.hours)

		if (!timeSpentInputHoursId) {
			await createTimeTrackInputHours({
				task_id: task.id,
				spent_hours: parsedHours,
			})
		} else {
			await updateTimeTrackInputHours({
				id: timeSpentInputHoursId,
				body: {
					spent_hours: parsedHours,
				},
			})
		}

		dispatch(dialogActions.setDialog(false))
		dispatch(
			showMessage({
				message: t('spent_time_indicated'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
	}

	const [humanizedHours, setHumanizedHours] = useState(null)

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('time_spent')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10 ">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-relative tt-flex tt-flex-col tt-gap-4 tt-w-full">
							<span className="tt-text-14  tt-mb-4">{t('hours')}</span>
							<Controller
								rules={{
									required: errMessage,
									validate: (value) => +withDotHours(value) > 0 || errMessage,
								}}
								render={({ field: { value, onChange } }) => {
									return (
										<TextField
											value={value ?? ''}
											// onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
											onChange={(e) => {
												const match = e.target.value.match(/\d*[,.]?\d*/)
												const isValidValue = match.input === match[0]
												if (isValidValue) {
													setHumanizedHours(humanizeHours(e.target.value))
													onChange(e)
												}
											}}
											error={!!errors.hours}
											hiddenLabel
											placeholder={t('enter_amount_time_spent')}
											className="dialog-input tt-w-full"
											id="filled-hidden-label-small"
											size="small"
										/>
									)
								}}
								name="hours"
								control={control}
							/>
							<p className="tt-absolute -tt-bottom-6">{humanizedHours}</p>
							<ErrorText errors={errors} field="hours" />
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={false} rightLabel={t('save')} />
		</form>
	)
}

export default SpentTimeInputHours
