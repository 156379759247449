import { useTranslation } from 'react-i18next'
import styles from './LanguageToggler.module.css'
import { Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getAppLanguage } from 'src/old-app/store/interfaceSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

const LanguageToggler = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [toggleTitle, setToggleTitle] = useState(null)

	const appLanguage = useSelector(getAppLanguage)

	const languageToggle = () => {
		if (appLanguage == 'ru') {
			dispatch(interfaceActions.setAppLanguage('en'))
		} else {
			dispatch(interfaceActions.setAppLanguage('ru'))
		}
	}

	useEffect(() => {
		if (appLanguage) {
			setToggleTitle(appLanguage[0].toUpperCase() + appLanguage.slice(1))
		}
	}, [appLanguage])

	return (
		<Tooltip title={t('language_toggle')}>
			<div
				onClick={() => {
					languageToggle()
				}}
				className={styles.LanguageToggler}
			>
				<Typography className="tt-select-none">{toggleTitle}</Typography>
			</div>
		</Tooltip>
	)
}

export default LanguageToggler
