import React, { memo, useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import TaskRow from './TaskRow'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { CircularProgress } from '@mui/material'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useGetTasksQuery } from 'src/old-app/service/Tasks'
import { useSelector } from 'react-redux'
import { getIsOpenEpic } from 'src/old-app/store/interfaceSlice'
import { setParamsFilters } from './TaskList'
import moment from 'moment/moment'
import { getProject } from 'src/old-app/store/projectsSlice'
import queryString from 'query-string'
import { JsonParam, withDefault } from 'use-query-params'
import { getID } from '../../../shared/config/fields-ids'

// const colors = ['bg-primary-500', 'bg-orange-500']

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	'&:not(:last-child)': {
		borderBottom: 0,
		width: '100%',
	},
	'&:before': {
		display: 'none',
		width: '100%',
	},

	width: '100%',
	background: 'none',
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	padding: 0,
	minHeight: 0,
	width: '100%',
	'& .MuiAccordionSummary-expandIconWrapper': {
		display: 'none',
		width: '100%',
	},
	'& .MuiAccordionSummary-content': {
		margin: 0,
		width: '100%',
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: 0,
	border: 'none',
	width: '100%',
	position: 'relative',
}))

const ToggleAccordion = ({ open, setOpen, id, disabled }) => {
	// const { pathname, search } = useLocation();
	return (
		// <Link
		// 	className="relative h-20 w-20 z-50 flex items-center justify-center"
		// 	to={pathname + search}
		// >
		<button
			disabled={disabled}
			id={id}
			onClick={(e) => {
				e.preventDefault()
				setOpen(!open)
			}}
			className="tt-h-20 tt-w-20  tt-text-gray-500 hover:tt-text-primary disabled:tt-text-gray-400 disabled-hover:tt-text-gray-400  tt-flex tt-items-center tt-justify-center"
		>
			<ArrowDropDownCircleOutlinedIcon
				className={['tt-duration-500 tt-h-20 tt-w-20', open ? 'tt-rotate-180' : ''].join(' ')}
			/>
		</button>
		// {/*</Link>*/}
	)
}
// const SubTasks = (props) => {
// 	return (
// 		<>
// 			<div style={{ left: 22 }} className="w-2 py-8 h-full absolute top-0 z-10">
// 				<div className="w-fill rounded-lg h-full bg-primary-400" />
// 			</div>
// 			{props?.task?.subtasks?.map((t) => (
// 				<TaskListItem activeClassName="bg-white" takeStatusesFromRedux key={t.id} data={t} />
// 			))}
// 		</>
// 	)
// }

const SubTaskProvider = (props) => {
	// const dispatch = useDispatch()
	const project = useSelector(getProject)
	const { search } = useLocation()
	const [searchParams] = useSearchParams()

	// const searchTask = searchParams.get('searchTask') ?? undefined
	// const slug = searchParams.get('slug') ?? undefined
	// const createdSort = searchParams.get(`created_at_sort`) ?? 'desc'
	// const deadlineStart = searchParams.get(`deadline_start`) ?? undefined
	// const deadlineEnd = searchParams.get(`deadline_end`) ?? undefined
	// const completedAtStart = searchParams.get(`completed_at_start`) ?? undefined
	// const completedAtEnd = searchParams.get(`completed_at_end`) ?? undefined
	// const createdAtStart = searchParams.get(`date_created_at_end`) ?? undefined
	// const createdAtEnd = searchParams.get(`date_created_at_start`) ?? undefined

	const [paramsSearch, setParamsSearch] = useState({})

	//rybakov refactor
	// const assignee = searchParams.get('assignee') ?? props?.assigneId ? [props?.assigneId] : []
	// const assignee = searchParams.get('assignee') ||
	// props?.assigneId ? [props?.assigneId] : []
	//
	// const status = searchParams.get('status') ?? []
	// const author = searchParams.get('author') ?? []
	// const priority = searchParams.get('priority') ?? []
	// const type = searchParams.get('type') ?? []
	//
	// console.log(searchParams.get('assignee'), assignee)
	// console.log(searchParams.toString())

	const parsedSearch = queryString.parse(searchParams.toString())
	const {
		searchTask,
		slug,
		order_sort: createdSort = 'desc',
		deadline_sort: deadlineSort,
		deadline_start: deadlineStart,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = props?.assigneId ? [props?.assigneId] : [],
		status = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		fields = JSON.stringify({}),
	} = parsedSearch

	// console.log('fields',fields)
	//----

	// rybakov refactor
	// const statusValue = Array.isArray(status) ? status : JSON.parse(status)
	// const assigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	// const priorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	// const authorValue = Array.isArray(author) ? author : JSON.parse(author)
	// const typeValue = Array.isArray(type) ? type : JSON.parse(type)

	const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue, fieldsValue] = [
		status,
		assignee,
		priority,
		author,
		type,
		tags,
		fields,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))
	//----

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		author: authorValue,
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
		tags: tagsValue,
	})

	const { data, isLoading } = useGetTasksQuery(
		{
			url: multipleParamsString,
			params: {
				project_id: props.task?.project_id,
				created_at_sort: createdSort,
				current_page: 1,
				parent_id: props.task.id,
				per_page: 1000,
				deadline_sort: deadlineSort ?? undefined,
				slug,
				title: searchTask,
				// start_date_deadline: deadlineStart ? moment(deadlineStart).format() : undefined,
				start_date_deadline: deadlineStart,
				// end_date_deadline: deadlineStart ? moment(deadlineEnd + 'T23:59:59').format() : undefined,
				end_date_deadline: deadlineEnd,

				// start_completed_at: completedAtStart ? moment(completedAtStart).format() : undefined,
				start_completed_at: completedAtStart,
				// end_completed_at: completedAtEnd ? moment(completedAtEnd + 'T23:59:59').format() : undefined,
				end_completed_at: completedAtEnd,

				// end_date_created_at: createdAtEnd ? moment(createdAtEnd + 'T23:59:59').format() : undefined,
				end_date_created_at: createdAtEnd,
				// start_date_created_at: createdAtStart ? moment(createdAtStart).format() : undefined,
				start_date_created_at: createdAtStart,
				...fieldsValue,
	
			},
		},
		{ refetchOnMountOrArgChange: true }
	)

	// const task = useMemo(() =>
	// 	props.onlyMyTasks
	// 		?
	// 		:
	// 	, [data])

	// useEffect(() => {
	// 	if (data) {
	// 		dispatch(tasksActions.setSubTasks({ taskId: props?.task?.id, subtasks: data?.data, parentId: props.task.id }))
	// 	}
	// }, [data, isLoading])

	if (isLoading) return <CircularProgress className="tt-text-primary-600" />

	return (
		<>
			{data?.data?.map((t, i) => (
				<>
					<div style={{ left: 5 }} className="tt-w-2 tt-py-8 tt-h-full tt-absolute tt-top-0 tt-z-20">
						<div className={`tt-w-fill tt-h-full  tt-bg-gray-300`} />
					</div>
					<tr className="tt-relative tt-w-full">
						<div style={{ left: 5 }} className="tt-w-2 tt-py-8 tt-absolute tt-top-0 tt-z-20">
							<div className={`tt-w-fill task-branch   tt-bg-gray-300`} />
						</div>
						<TaskListItem
							{...props}
							accordionSx={{ paddingLeft: '15px' }}
							index={`${props?.index}-${i}`}
							activeClassName="tt-bg-white"
							takeStatusesFromRedux
							key={t.id}
							data={t}
						/>
					</tr>
				</>
			))}
		</>
	)
}

const TaskListItem = memo((props) => {
	const isOpenEpic = useSelector(getIsOpenEpic)
	const isHideLeft = !!props?.hideSkelet?.left
	const [open, setOpen] = useState(false)

	const id = `panel${props.data.id}`
	const panelContentId = `panel${props.data.id}d-content`
	const panelHeaderId = `panel${props.data.id}d-header`

	const isEpic = props?.isAccordionHandler ? props?.isAccordionHandler(props) : props?.data?.has_subtask

	useEffect(() => {
		if (isEpic) {
			setOpen(isOpenEpic)
		}
	}, [isOpenEpic])

	return (
		<table className="tt-w-full">
			<Accordion style={props.accordionSx} expanded={props?.isTasksIncoming ? false : open} key={id}>
				<AccordionSummary aria-controls={panelContentId} id={panelHeaderId}>
					<TaskRow
						{...props}
						className={open ? 'task-accordion-details' : ''}
						left={
							!isEpic ? (
								<div className="tt-w-20" />
							) : (
								<ToggleAccordion
									disabled={props?.hideSkelet?.leftDisabled}
									id={getID({
										prefix: id,
										field: 'sub_tasks',
										fieldPostfix: 'show',
										type: 'button_toggle',
										index: props?.index,
									})}
									open={open}
									setOpen={setOpen}
								/>
							)
						}
					/>
				</AccordionSummary>
				{!isHideLeft && (
					<>
						{isEpic && (
							<AccordionDetails>
								<table className="tt-w-full tt-flex-1">
									{open ? (
										<>
											{props?.childrenRender ? (
												props?.childrenRender({ task: props?.data, props: props })
											) : (
												<SubTaskProvider {...props} task={props?.data} />
											)}
										</>
									) : null}
								</table>
							</AccordionDetails>
						)}
					</>
				)}
			</Accordion>
		</table>
	)
})

TaskListItem.displayName = 'TaskListItem'
export default TaskListItem
