import React from 'react'
import styles from './styles.module.scss'

interface IProps {}

const CellEmpty: React.FC<IProps> = () => {
	return <span className={styles['cell-empty']}>-</span>
}

export default CellEmpty
