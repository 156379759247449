import moment from 'moment'
import React from 'react'
import UserCellShow from '../../components/tables/UserCellShow'
import { useTranslation } from 'react-i18next'

const TasksDeadlineText = ({ item }) => {
	const { t } = useTranslation()
	return (
		<div>
			<div>
				{t('new.deadline')} {moment(item?.deadline?.deadline).format('LL')}
			</div>
			<div className="flex gap-5 mt-3">
				{t('by_user')}
				<UserCellShow user={item?.deadline?.task?.assignee} />
			</div>
		</div>
	)
}

export default TasksDeadlineText
