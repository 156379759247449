import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL } from 'src/shared/config'
import { serializeParams } from '../utils/serializeRequestParams'
import { getToken } from 'src/@entities/session'
import { baseQuery } from './base-query'

export const projectsAPI = createApi({
	reducerPath: 'projects-api',
	tagTypes: ['Project', 'ProjectUser', 'Project-Approve', 'ProjectsPriority', 'ProjectContractDirectory'],
	baseQuery,
	endpoints: (build) => ({
		getOneProject: build.query({
			query: ({ id, params }) => ({
				url: `/project/${id}`,
				params,
			}),
			providesTags: ['Project'],
		}),

		getPriority: build.query({
			query: (params) => {
				return {
					url: '/project-priority/',
					params,
				}
			},
			providesTags: ['ProjectsPriority'],
		}),
		getPriorityR: build.query({
			query: ({ params, arraysParams }) => {
				// console.log({ params, arraysParams });
				return {
					url: '/project-priority/',
					params: serializeParams({ params, arraysParams }),
				}
			},
			providesTags: ['ProjectsPriority'],
		}),

		confirmProject: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/project/confirm`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project', 'Projects'],
		}),
		rejectProject: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/project/reject`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project', 'Projects'],
		}),
		getProjectParamsParam: build.query({
			query: ({ id, param }) => ({
				url: `/project/params/${id}/${param}`,
			}),
		}),
		getProjectParams: build.query({
			query: (id) => ({
				url: `/project/params/${id}`,
			}),
		}),
		getOnePriority: build.query({
			query: (id) => ({
				url: `/project-priority/${id}`,
			}),
		}),
		deletePriorityTask: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/project-priority/${id}/`,
				body: JSON.stringify({}),
			}),
		}),
		updatePriorityTask: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/project-priority/${id}`,
				body: JSON.stringify(body),
			}),
		}),

		createPriorityTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/project-priority`,
				body: JSON.stringify(body),
			}),
		}),

		createProjectHashtag: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/project-hashtag`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project-Create'],
		}),
		createFileProject: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/file-project/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: [],
		}),
		deleteFileProject: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/file-project/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: [],
		}),
		getProjectHashtag: build.query({
			query: (params) => ({
				url: `/project-hashtag/`,
				params,
			}),
			providesTags: ['Project-Tags'],
		}),
		createProjectHashtagLink: build.mutation({
			query: ({ id, hashtagId }) => ({
				method: 'POST',
				url: `/project-hashtag/project/${id}/hashtag/${hashtagId}/link`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Project'],
		}),

		deleteProjectHashtag: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/project-hashtag/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project'],
		}),
		getProjects: build.query({
			query: (params) => ({
				url: '/project/',
				params,
			}),
			providesTags: (result) => [
				...result.data?.map(({ id }) => ({ type: 'Project', id })),
				{ type: 'Project', id: 'List' },
			],
		}),
		createProjectStatus: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project-status/',
				body: JSON.stringify(body),
			}),
		}),
		checkProject: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project/slug/exist',
				body: JSON.stringify(body),
			}),
		}),
		getProjectsApprove: build.query({
			query: (params) => ({
				url: '/project/approve',
				params,
			}),
			providesTags: ['Project-Approve'],
		}),
		approvedProject: build.mutation({
			query: (id) => ({
				method: 'PUT',
				url: `/project/${id}/approve`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Project-Approve'],
		}),
		completeProject: build.mutation({
			query: (id) => ({
				method: 'PUT',
				url: `/project/complete`,
				body: JSON.stringify({
					project_id: id,
				}),
			}),
			invalidatesTags: ['Project'],
		}),
		resumeProject: build.mutation({
			query: (id) => ({
				method: 'PUT',
				url: `/project/resume`,
				body: JSON.stringify({
					project_id: id,
				}),
			}),
			invalidatesTags: ['Project'],
		}),
		declineProject: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/project/${id}/decline`,
				body: JSON.stringify(body),
			}),
		}),
		updateProject: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/project/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project'],
		}),

		getUserProjects: build.query({
			query: (params = {}) => ({
				url: `/project-user/`,
				params: { per_page: 100, ...params },
			}),
			providesTags: ['ProjectUser'],
		}),

		createProject: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project'],
		}),

		createUserProject: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project-user/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['ProjectUser'],
		}),

		updateUserProject: build.mutation({
			query: (user) => ({
				method: 'PUT',
				url: `/project-user/${user.project_id}`,
				body: JSON.stringify(user),
			}),
			invalidatesTags: ['ProjectUser', 'Project'],
		}),

		deleteProjectUser: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/project-user/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['ProjectUser'],
		}),

		deleteProject: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/project/${id}`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Project'],
		}),

		createProjectTemplate: build.mutation({
			query: ({ id, body }) => ({
				method: 'POST',
				body: JSON.stringify(body),
				url: `/project-template/${id}/project`,
			}),
			providesTags: ['ProjectTemplate'],
		}),
		getProjectTemplates: build.query({
			query: (params) => ({
				url: `/project-template/`,
				params,
			}),
			providesTags: ['ProjectTemplates'],
		}),
		getProjectTypes: build.query({
			query: (params) => ({
				url: `/project-type/`,
				params,
			}),
			// providesTags: ['ProjectTemplates'],
		}),
		getProjectContractDirectory: build.query({
			query: (params) => ({
				url: `/contract-dictionary/`,
				params,
			}),
			providesTags: ['ProjectContractDirectory'],
		}),
		getProjectContractDirectoryGetById: build.query({
			query: (id) => ({
				url: `/contract-dictionary/${id}`,
			}),
			providesTags: ['ProjectContractDirectoryGetById'],
		}),
		createProjectContractDirectory: build.mutation({
			query: (body) => ({
				method: 'POST',
				body: JSON.stringify(body),
				url: `/contract-dictionary`,
			}),
			invalidatesTags: ['ProjectContractDirectory'],
		}),
		deleteProjectContractDirectory: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				body: JSON.stringify({}),
				url: `/contract-dictionary/${id}`,
			}),
			invalidatesTags: ['ProjectContractDirectory'],
		}),
		updateProjectContractDirectory: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				body: JSON.stringify(body),
				url: `/contract-dictionary/${id}`,
			}),
			invalidatesTags: ['ProjectContractDirectoryGetById', 'ProjectContractDirectory'],
		}),

		getProjectTemplate: build.query({
			query: (id) => ({
				url: `/project-template/${id}`,
			}),
			providesTags: ['ProjectTemplate'],
		}),

		getOnConfirmationTeamProjectsCount: build.query({
			query: (params) => ({
				url: `/project/requests/count`,
				params,
			}),
			providesTags: ['Project', 'Projects'],
		}),
	}),
})

export const getOneProject = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/project/${id}`, requestOptions).then((res) => res.json())
}

export const {
	useDeleteProjectContractDirectoryMutation,
	useUpdateProjectContractDirectoryMutation,
	useGetProjectContractDirectoryGetByIdQuery,
	useGetProjectTypesQuery,
	useGetProjectTemplatesQuery,
	useConfirmProjectMutation,
	useRejectProjectMutation,
	useGetProjectTemplateQuery,
	useCreateProjectTemplateMutation,
	useDeleteProjectMutation,
	useGetUserProjectsQuery,
	useGetProjectParamsParamQuery,
	useCreateUserProjectMutation,
	useCreateProjectMutation,
	useGetProjectsQuery,
	useGetOneProjectQuery,
	useUpdateProjectMutation,
	useDeleteProjectUserMutation,
	useApprovedProjectMutation,
	useGetProjectsApproveQuery,
	useDeclineProjectMutation,
	useCheckProjectMutation,
	useCreateProjectStatusMutation,
	useGetPriorityQuery,
	useGetPriorityRQuery,
	useCreatePriorityTaskMutation,
	useUpdatePriorityTaskMutation,
	useDeletePriorityTaskMutation,
	useUpdateUserProjectMutation,
	useCreateProjectHashtagMutation,
	useCreateProjectHashtagLinkMutation,
	useGetProjectHashtagQuery,
	useDeleteProjectHashtagMutation,
	useGetProjectParamsQuery,
	useCompleteProjectMutation,
	useResumeProjectMutation,
	useGetOnConfirmationTeamProjectsCountQuery,
	useGetProjectContractDirectoryQuery,
	useCreateProjectContractDirectoryMutation,
	useCreateFileProjectMutation,
	useDeleteFileProjectMutation,
} = projectsAPI
