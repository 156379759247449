import { createContext } from 'react'

interface IProps {
	isShowCreateTaskButton: boolean
	isManageProject: boolean
	canCloseProject?: boolean
	project?: any
	isMyProject?: boolean
	isContract?: boolean
	isCommittee?: boolean
	isPublic?: boolean
	isEditProject?: boolean
}
const ProjectShowContext = createContext<IProps>({
	isShowCreateTaskButton: false,
	isManageProject: false,
	isMyProject: false,
	canCloseProject: false,
	isContract: false,
	isCommittee: false,
	isPublic: false,
	isEditProject: false,
	project: null,
})
export default ProjectShowContext
