import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { TaskСonfirmatory, requestType } from 'src/entities/task'
import { useSelector } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import { getTask } from 'src/old-app/store/tasksSlice'
import moment from 'moment'
import { getID } from '../../../../shared/config/fields-ids'

interface IProps {
	id?: string
}

function TabPanel(props: any) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`task-conf-deadlinepanel-${index}`}
			aria-labelledby={`task-conf-deadline-${index}`}
			{...other}
			className="tt-w-full"
		>
			{children}
		</div>
	)
}

function a11yProps(index: any) {
	return {
		'aria-controls': `task-conf-deadlinepanel-${index}`,
	}
}

const TaskConfirmatoryDeadline: React.FC<IProps> = ({ id }) => {
	const task = useSelector(getTask)

	const [value, setValue] = React.useState(0)

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue)
	}

	return (
		<div className={styles['task-confirmatory-deadline']}>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider' }}
			>
				{task.deadlines?.map((item: any, index: any) => (
					<Tab
						label={moment(item.deadline).format('LL')}
						id={getID({ prefix: id, field: 'confirmatory_deadline', type: 'button_tab', index })}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>

			{task?.deadlines?.map((item: any, index: any) => {
				const isShowCancel = item?.confirmation_status === 'ON_CONFIRMATION'

				return (
					<TabPanel value={value} index={index}>
						<TaskСonfirmatory
							id={id}
							requestId={item.id}
							isShowCancel={isShowCancel}
							type={requestType.TASKS_DEADLINE}
							confirmations={item?.confirmations ?? []}
							comment={item.comment ?? ""}
						/>
					</TabPanel>
				)
			})}
		</div>
	)
}

export default TaskConfirmatoryDeadline
