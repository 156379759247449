import React, { useRef, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Button, Menu, MenuItem, Tooltip } from '@mui/material'
import { fileUpload } from 'src/old-app/service/File'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getProject, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { setParamsFilters } from 'src/old-app/main/tasks/TaskList'
import queryString from 'query-string'
import { getID } from '../../../shared/config/fields-ids'
import ExportSanction from '../analysis/ExportSanction'
import { useProjectShow } from '../../../entities/project'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const _commonID = getID({ field: 'kpg' })

const ExportKPG = () => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const isUser = useSelector(getProjectUserRole)
	const project = useSelector(getProject)
	const dispatch = useDispatch()
	const fileRef = useRef(null)
	const projectShow = useProjectShow()
	const isCommittee = projectShow?.isCommittee

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const [searchParams, setSearchParams] = useSearchParams()

	const parsedSearch = queryString.parse(searchParams.toString())
	const {
		searchTask,
		slug,
		order_sort: createdSort = 'desc',
		deadline_start: deadlineStart,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = [],
		status = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		...tasksParams
	} = parsedSearch

	const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue] = [
		status,
		assignee,
		priority,
		author,
		type,
		tags,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		author: authorValue,
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
		tags: tagsValue,
		initParams: tasksParams,
	})

	const exportFileHandler = (path) => {
		const name = `${project.name}`

		const params = new URLSearchParams(multipleParamsString)

		params.append('project_id', project.id)
		params.append('created_at_sort', createdSort)

		params.delete('fields')

		const fields = JSON.parse(parsedSearch?.fields ?? '{}')

		if (searchTask) {
			params.append('title', searchTask)
		}
		if (slug) {
			params.append('slug', slug)
		}
		if (deadlineStart) {
			params.append('start_date_deadline', moment(deadlineStart).format())
		}
		if (deadlineEnd) {
			params.append('end_date_deadline', moment(deadlineEnd).format())
		}
		if (completedAtStart) {
			params.append('start_completed_at', moment(completedAtStart).format())
		}
		if (completedAtEnd) {
			params.append('end_completed_at', moment(completedAtEnd).format())
		}
		if (createdAtStart) {
			params.append('start_date_created_at', moment(createdAtStart).format())
		}
		if (createdAtEnd) {
			params.append('end_date_created_at', moment(createdAtEnd).format())
		}

		Object.entries(fields).map(([key, value]) => {
			params.append(key, value)
		})

		const url = `/${path}/?${params.toString()}`
		exportKPGHandler({ url, params, name, dispatch })
	}

	const exportKPG = () => {
		exportFileHandler('report')
		handleClose()
	}

	const exportTime = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('time-kpg'))
		// exportFileHandler('report/time')
		// handleClose()
	}

	const uploadTasksKpg = () => {
		fileRef.current?.focus()
		fileRef.current?.click()
	}

	const onChangeFilesHandler = async (event) => {
		handleClose()

		const file = event.target.files[0]

		if (file) {
			dispatch(interfaceActions?.setIsLoading(true))

			dispatch(
				showMessage({
					message: t('cpg_upload.sending_file'),
					autoHideDuration: null,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'info',
				})
			)

			const toKPG = true //отдает только fetch(), без json()

			const data = await fileUpload(file, 'report/task/upload', toKPG)

			//успешная отправка
			if (data.status >= 200 && data.status < 300) {
				dispatch(
					showMessage({
						message: t('cpg_upload.sent_successfully'),
						autoHideDuration: null,
						variant: 'info',
					})
				)
			}

			try {
				if (data.status >= 400) {
					dispatch(
						showMessage({
							message: t('cpg_upload.error'),
							autoHideDuration: null,
							variant: 'error',
						})
					)
				} else {
					dispatch(
						showMessage({
							message: t('cpg_upload.success'),
							autoHideDuration: null,
							variant: 'success',
						})
					)
				}
			} catch (e) {
				dispatch(
					showMessage({
						message: t('cpg_upload.unknown_error'),
						autoHideDuration: null,
						variant: 'error',
					})
				)
			}

			dispatch(interfaceActions?.setIsLoading(false))
		}
	}

	return (
		<>
			<Tooltip arrow title={t('cpg_actions')} placement="top-start">
				<Button
					id={getID({ field: 'kpg', type: 'button_open' })}
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					startIcon={<UploadFileIcon />}
					className="tt-text-primary  tt-border tt-border-primary-600 tt-text-[0] md:tt-text-14  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('download_or_upload_cpg')}
				</Button>
			</Tooltip>
			<Menu
				// id={getID({ action: 'manage', field: 'kpg', type: 'select' })}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem id={getID({ prefix: _commonID, action: 'download', type: 'button_open' })} onClick={exportKPG}>
					{t('download_cpg')}
				</MenuItem>

				{/*{!isUser &&
				<MenuItem id={getID({prefix: _commonID, action: 'download', field: 'time', type: 'button_open' })}
				          onClick={exportTime}>
					Выгрузить Время
				</MenuItem>}*/}

				{projectShow?.isManageProject && !projectShow?.project?.isEnd && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<MenuItem
							id={getID({ prefix: _commonID, action: 'upload', field: 'tasks', type: 'button_open' })}
							onClick={uploadTasksKpg}
						>
							{t('upload_cpg')}
						</MenuItem>
					</CommentedDisability>
				)}
				{projectShow?.isManageProject && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<ExportSanction
							className="hover:tt-bg-gray-100 tt-text-14 tt-text-left tt-w-full tt-px-16 tt-py-6 tt-leading-6 tt-cursor-pointer tt-select-none"
							projectId={project?.id}
						/>
					</CommentedDisability>
				)}
			</Menu>

			<input
				id={getID({ prefix: _commonID, action: 'upload', field: 'tasks', type: 'input_file' })}
				accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				onChange={onChangeFilesHandler}
				ref={fileRef}
				hidden
				type="file"
			/>
		</>
	)
}

export default ExportKPG
