import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, TextField } from '@mui/material'
import ErrorText from '../../ui/ErrorText'
import { getID } from '../../../../shared/config/fields-ids'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const TaskParams = ({ value, onChange, errors, id, className }) => {
	const { t } = useTranslation()
	const addParams = () => {
		const last = value[value.length - 1]

		const index = last ? last.order + 1 : 1

		const arr = [...value, { id: index, order: index, label: ``, value: `` }]
		onChange(arr)
	}

	const onChangeLabel = (val, id) => {
		const arr = value.map((item) => {
			if (item.id === id) {
				item.label = val
			}
			return item
		})

		onChange(arr)
	}

	const onChangeValue = (val, id) => {
		const arr = value.map((item) => {
			if (item.id === id) {
				item.value = val
			}
			return item
		})

		onChange(arr)
	}

	const deleteParam = (id) => {
		const arr = value.filter((item) => item.id !== id)
		onChange(arr)
	}
	return (
		<div className={clsx('tt-flex tt-flex-col tt-w-full tt-gap-8', className)}>
			<div className="tt-gap-16 tt-flex tt-flex-col">
				{value.map((item, i) => (
					<div key={item.id} className="tt-flex tt-items-end tt-gap-16">
						<div className="tt-flex tt-gap-8 tt-w-full">
							<div className="tt-flex tt-flex-col tt-gap-8 tt-w-full">
								<TextField
									id={getID({
										prefix: id,
										field: 'params_item_field',
										type: 'text',
										index: i,
									})}
									value={item.label}
									onChange={(event) => onChangeLabel(event.target.value, item.id)}
									placeholder={t('enter_title')}
									className="dialig-input tt-w-full"
									size="small"
								/>
								<ErrorText
									errors={errors}
									errorField={errors?.params && errors?.params[i] ? errors?.params[i]?.label?.message : null}
								/>
							</div>
							<div className="tt-flex tt-flex-col tt-gap-8 tt-w-full">
								<TextField
									value={item.value}
									id={getID({
										prefix: id,
										field: 'params_item_value',
										type: 'text',
										index: i,
									})}
									onChange={(event) => onChangeValue(event.target.value, item.id)}
									placeholder={t('enter_value')}
									className="dialig-input tt-w-full"
									size="small"
								/>
								<ErrorText
									errors={errors}
									errorField={errors?.params && errors?.params[i] ? errors?.params[i]?.value?.message : null}
								/>
								{/* <TextField value={item.value} onChange={(event) => onChangeValue(event.target.value, item.id)} placeholder="Введите значение" className="dialig-input w-full" size="small" /> */}
							</div>
							{/* <div className="h-10">
									{errors?.params && errors?.params[i] && (
										<p className="text-12 text-red-600 pl-16">
											{errors?.params[i]?.label?.message}
											{errors?.params[i]?.label?.message && ', '}
											{errors?.params[i]?.value?.message}
										</p>
									)}
								</div> */}
						</div>
						<button
							id={getID({
								prefix: id,
								field: 'params_item',
								type: 'button_delete',
								index: i,
							})}
							onClick={() => deleteParam(item.id)}
							className="tt-h-42 tt-w-42 tt-mb-10 tt-flex tt-items-center tt-justify-center tt-cursor-pointer "
						>
							<svg
								className="stroke-gray-700"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M3.33331 5.83333H16.6666M15.8333 5.83333L15.1108 15.9517C15.0809 16.3722 14.8927 16.7657 14.5842 17.053C14.2758 17.3403 13.8699 17.5 13.4483 17.5H6.55165C6.1301 17.5 5.7242 17.3403 5.41572 17.053C5.10723 16.7657 4.91908 16.3722 4.88915 15.9517L4.16665 5.83333H15.8333ZM12.5 5.83333V3.33333C12.5 3.11232 12.4122 2.90036 12.2559 2.74408C12.0996 2.5878 11.8877 2.5 11.6666 2.5H8.33331C8.1123 2.5 7.90034 2.5878 7.74406 2.74408C7.58778 2.90036 7.49998 3.11232 7.49998 3.33333V5.83333H12.5Z"
									stroke="#94A3B8"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</div>
				))}
			</div>
			<Button
				id={getID({
					prefix: id,
					field: 'params',
					type: 'button_add',
				})}
				onClick={addParams}
				startIcon={<AddIcon />}
				className="tt-border-gray-200  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
				variant="outlined"
			>
				{t('add_field')}
			</Button>
		</div>
	)
}

TaskParams.propTypes = {
	id: PropTypes.string.isRequired,
}

export default TaskParams
