import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL } from 'src/shared/config'
import { serializeParams } from '../utils/serializeRequestParams'
import { getToken } from 'src/@entities/session'
import { baseQuery } from './base-query'

export const tasksAPI = createApi({
	reducerPath: 'tasks-api',
	tagTypes: ['Task-Status', 'User-Task-Deadline', 'Project-Status', 'Task', 'Tasks', 'Sanctions'],
	baseQuery,
	endpoints: (build) => ({
		getWorkspaceMy: build.query({
			query: (params) => ({
				url: `/workspace/my`,
				params,
			}),
			providesTags: ['WorkspaceMy', 'Tasks'],
		}),
		getTaskIncomingCount: build.query({
			query: (params) => ({
				url: `/task/incoming/count`,
				params,
			}),
			providesTags: ['Tasks', 'Task'],
		}),
		getTasks: build.query({
			query: ({ params, url }) => ({
				url: url ? `/task/?${url}` : '/task/',
				params,
			}),
			providesTags: ['Tasks'],
		}),
		//todo новый запрос с автосериализацией
		getTasksR: build.query({
			query: ({ params, arraysParams }) => ({
				url: '/task/',
				params: serializeParams({ params, arraysParams }),
				// paramsSerializer: serializeParams,
				// paramsSerializer: args => {console.log(args);},
			}),
			providesTags: ['TasksR'],
		}),
		getOneTask: build.query({
			query: (id) => ({
				url: `/task/${id}`,
			}),
			providesTags() {
				return [{ type: 'Task', id: arguments[2] }]
			},
		}),
		createTaskHashtag: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/task-hashtag/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		createCompleteFile: build.mutation({
			query: (body) => {
				return {
					method: 'POST',
					url: '/task-complete-file/',
					body: JSON.stringify(body),
				}
			},
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		deleteTaskHashtag: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/task-hashtag/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR', 'Sanctions'],
		}),
		deleteCompleteFile: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/task-complete-file/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		createTimeTrackButton: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/time-tracking-by-button/${body.task_id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task'],
		}),
		createTimeTrackInput: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/time-tracking-by-input/${body.task_id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task'],
		}),
		createTimeTrackInputHours: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/time-tracking-by-input-hours/${body.task_id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task'],
		}),
		updateTimeTrackInputHours: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/time-tracking-by-input-hours/${id}`,
				body: JSON.stringify(body),
			}),

			invalidatesTags: ['Sanctions', 'Task'],
		}),
		updateTimeTrackButton: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/time-tracking-by-button/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task'],
		}),
		updateTimeTrackInput: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/time-tracking-by-input/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task'],
		}),
		getTimeTrackButton: build.query({
			query: () => ({
				url: `/time-tracking-by-button/`,
			}),
		}),

		getTaskHashtag: build.query({
			query: (params) => ({
				url: `/task-hashtag/`,
				params,
			}),
		}),
		createTaskHashtagLink: build.mutation({
			query: ({ id, hashtagId }) => ({
				method: 'POST',
				url: `/task-hashtag/task/${id}/hashtag/${hashtagId}/link`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		getStatus: build.query({
			query: (params) => ({
				url: '/project-status/',
				params,
			}),
		}),
		getStatusR: build.query({
			query: ({ params, arraysParams }) => ({
				url: '/project-status/',
				params: serializeParams({ params, arraysParams }),
			}),
		}),
		getOneStatus: build.query({
			query: (id) => ({
				url: `/project-status/${id}`,
			}),
		}),
		deleteStatusTask: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/project-status/${id}/`,
				body: JSON.stringify({}),
			}),
			// invalidatesTags: ['Sanctions,'Project-Status'],
		}),
		updateStatusTask: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/project-status/${id}`,
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Sanctions,'Project-Status'],
		}),
		updateStatusOrderTask: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/project-status/${id}`,
				body: JSON.stringify(body),
			}),
		}),

		createStatusTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/project-status`,
				body: JSON.stringify(body),
			}),
			// invalidatesTags: ['Sanctions,'Project-Status'],
		}),
		createTaskEdpic: build.mutation({
			query: ({ id, epic }) => ({
				method: 'POST',
				url: `/task/${id}/epic/${epic}/add`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		deleteTaskEpic: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/task/${id}/epic/delete`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		addTaskEpic: build.mutation({
			query: ({ id, epic }) => ({
				method: 'PUT',
				url: `/task/${epic}/epic/${id}/add`,
				body: JSON.stringify({
					task_id: epic,
					epic_id: id,
				}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		getPriorities: build.query({
			query: (params) => ({
				url: '/task-priority/',
				params,
			}),
		}),
		getHistory: build.query({
			query: (id) => ({
				url: `/task/${id}/history`,
			}),
			providesTags: ['Task', 'TasksR'],
		}),
		getTaskDeadline: build.query({
			query: (params) => ({
				url: '/user-task-deadline/',
				params,
			}),
		}),
		getTasksDeadlineApprove: build.query({
			query: ({ params, url }) => ({
				url: url ? `/user-task-deadline/approve/?${url}` : '/user-task-deadline/approve',
				params,
			}),

			providesTags: ['Task', 'Tasks', 'User-Task-Deadline', 'TasksR'],
		}),
		declineTaskDeadline: build.mutation({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/user-task-deadline/${id}/decline`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'User-Task-Deadline', 'TasksR'],
		}),
		approvedTaskDeadline: build.mutation({
			query: (id) => ({
				method: 'PUT',
				url: `/user-task-deadline/${id}/approve`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'User-Task-Deadline', 'TasksR'],
		}),
		cancelTaskDeadline: build.mutation({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/user-task-deadline/${id}/cancel`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'User-Task-Deadline', 'TasksR'],
		}),

		getTasksApprove: build.query({
			query: ({ params, url }) => ({
				url: url ? `/task-status/approve?${url}` : '/task-status/approve',
				params,
			}),
			providesTags: ['Task', 'Tasks', 'Task-Status', 'TasksR'],
		}),
		approvedTask: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/task-status/approve`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'Task-Status', 'TasksR'],
		}),
		cancelTask: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/task-status/cancel`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'Task-Status', 'TasksR'],
		}),
		declineTask: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/task-status/decline`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'Task-Status', 'TasksR'],
		}),

		createTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/task/',
				body: JSON.stringify(body),
			}),

			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),

		createTaskSubscriber: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/task-subscriber/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),

		deleteTaskSubscriber: build.mutation({
			query: (params) => ({
				method: 'DELETE',
				url: `/task-subscriber/`,
				body: JSON.stringify({}),
				params,
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR', 'Sanctions'],
		}),

		getTaskSubscriber: build.query({
			query: (params) => ({
				url: '/task-subscriber/',
				params,
			}),
		}),

		getTaskTypes: build.query({
			query: () => ({
				url: '/task-type/',
			}),
		}),

		updateTask: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/task/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags() {
				return ['Task', 'Tasks', 'TasksR']
			},
		}),
		deletePriority: build.mutation({
			query: (id) => ({
				method: 'PUT',
				url: `/task/${id}/priority`,
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),

		deleteTask: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/task/${id}`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR', 'Sanctions'],
			// onQueryStarted: revalidateSanctions,
		}),

		createHastagTask: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project-task/',
				body: JSON.stringify(body),
			}),
		}),
		createTaskDeadline: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/user-task-deadline/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		updateTaskDeadline: build.mutation({
			query: (data) => ({
				method: 'PUT',
				url: `/user-task-deadline/${data.id}`,
				body: JSON.stringify(data.body),
			}),
		}),
		getStatusTask: build.query({
			query: (params) => ({
				url: '/task-status/',
				params,
			}),
		}),

		updateTaskStatus: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/task-status/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Sanctions', 'Task', 'Tasks', 'TasksR'],
		}),
		getTaskTemplate: build.query({
			query: (params) => ({
				url: `/task-template/`,
				params,
			}),
			invalidatesTags: ['TaskTemplates'],
		}),
		createTaskTemplate: build.mutation({
			query: (body) => ({
				method: 'POST',
				body: JSON.stringify(body),
				url: `/task-template/`,
			}),
			providesTags: ['TaskTemplates'],
		}),
		deleteTaskTemplate: build.mutation({
			query: (taskId) => ({
				method: 'DELETE',
				body: JSON.stringify({}),
				url: `/task-template/${taskId}`,
			}),
			providesTags: ['TaskTemplates'],
		}),

		getSanctions: build.query({
			query: ({ params, url }) => ({
				url: url ? `/sanction/?${url}` : '/sanction/',
				params,
			}),
			providesTags: (result) => [
				...result.data?.map(({ task_id }) => ({ type: 'Sanctions', id: task_id })),
				{ type: 'Sanctions', id: 'List' },
			],
			// invalidatesTags: ['Sanctions,'Sanctions'],
		}),

		createSanction: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/sanction/',
				body: JSON.stringify(body),
			}),

			invalidatesTags: ['Sanctions', 'Sanctions'],
		}),

		clearTaskContractTypeParams: build.mutation({
			query: ({ taskID, body }) => ({
				method: 'PUT',
				url: `/task/${taskID}/dictionary/replace/`,
				body: JSON.stringify(body),
			}),
		}),
	}),
})

export const getOneTask = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/task/${id}`, requestOptions).then((res) => res.json())
}
export const getOneTaskCompleteFile = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/task-complete-file/?task_id=${id}`, requestOptions).then((res) => res.json())
}

export const getTasks = (params) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/task/${params}`, requestOptions).then((res) => res.json())
}

export const getMoreTasks = (params) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/tasks/${params}`, requestOptions).then((res) => res.json())
}

export const {
	useCreateTimeTrackButtonMutation,
	useDeleteStatusTaskMutation,
	useCreateStatusTaskMutation,
	useCreateTaskSubscriberMutation,
	useGetTaskTypesQuery,
	useCreateTaskMutation,
	useCreateHastagTaskMutation,
	useGetTasksQuery,
	useGetTasksRQuery,
	useGetOneTaskQuery,
	useDeleteTaskMutation,
	useUpdateTaskMutation,
	useGetTaskSubscriberQuery,
	useGetPrioritiesQuery,
	useGetStatusQuery,
	useGetStatusRQuery,
	useGetTaskDeadlineQuery,
	useCreateTaskDeadlineMutation,
	useGetTaskIncomingCountQuery,
	useUpdateTaskDeadlineMutation,
	useCancelTaskDeadlineMutation,
	useCancelTaskMutation,
	useDeleteTaskSubscriberMutation,
	useGetOneStatusQuery,
	useApprovedTaskMutation,
	useGetTasksApproveQuery,
	useApprovedTaskDeadlineMutation,
	useGetTasksDeadlineApproveQuery,
	useGetHistoryQuery,
	useDeclineTaskMutation,
	useDeclineTaskDeadlineMutation,
	useGetStatusTaskQuery,
	useUpdateStatusTaskMutation,
	useUpdateStatusOrderTaskMutation,
	useCreateTaskHashtagLinkMutation,
	useCreateTaskHashtagMutation,
	useGetTaskHashtagQuery,
	useAddTaskEpicMutation,
	useUpdateTimeTrackButtonMutation,
	useGetTimeTrackButtonQuery,
	useDeletePriorityMutation,
	useCreateTimeTrackInputMutation,
	useUpdateTimeTrackInputMutation,
	useDeleteTaskHashtagMutation,
	useDeleteTaskEpicMutation,
	useCreateTimeTrackInputHoursMutation,
	useUpdateTimeTrackInputHoursMutation,
	useGetWorkspaceMyQuery,
	useCreateTaskTemplateMutation,
	useGetTaskTemplateQuery,
	useDeleteCompleteFileMutation,
	//для упрощения ревалидации тасок с санкц.
	useGetSanctionsQuery,
	useCreateSanctionMutation,
	useCreateCompleteFileMutation,
	useDeleteTaskTemplateMutation,
	useClearTaskContractTypeParamsMutation,
} = tasksAPI
