import React from 'react'
import '../styles/app-components.css'
import '../styles/app-utilities.css'
import '../styles/app-base.scss'
import '../styles/markdown.scss'
import withProviders from 'src/app/providers'
import FuseTheme from 'src/@fuse/core/FuseTheme'
import { SnackbarProvider } from 'notistack'
import { useSelector } from 'react-redux'
import { selectMainTheme } from 'src/old-app/store/fuse/settingsSlice'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { parse, stringify } from 'query-string'
import FuseMessage from 'src/@fuse/core/FuseMessage'
import Aside from 'src/old-app/components/layout/aside/Aside'
import Dialogs from 'src/old-app/components/dialogs/Dialogs'
import IsLoading from '../old-app/components/layout/IsLoading'
import MediaViewer from 'src/old-app/components/layout/MediaViewer/MediaViewer'
import { RoutesApp, PublicRoutesApp } from './routes'
import { checkPublic } from 'src/shared/lib/check-public'

// TODO: Full refactor

const queryParamOptions = {
	searchStringToObject: parse,
	objectToSearchString: stringify,
	includeAllParams: true,
}

const App = () => {
	const langDirection = 'ltr'
	const mainTheme = useSelector(selectMainTheme)
	// const user = useGetUser()

	const isPublic = checkPublic()

	return (
		<div
			id="track-root"
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			<React.StrictMode>
				<FuseTheme theme={mainTheme} direction={langDirection}>
					<QueryParamProvider adapter={ReactRouter6Adapter} options={queryParamOptions}>
						<SnackbarProvider
							maxSnack={5}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							classes={{
								containerRoot: 'tt-bottom-0 tt-right-0 tt-mb-52 md:tt-mb-68 tt-mr-8 lg:tt-mr-80 tt-z-99',
							}}
						>
							<IsLoading />
							<MediaViewer />

							<div className="tt-w-full tt-flex tt-relative tt-h-screen">
								{!isPublic && <Aside />}
								{isPublic ? (
									<PublicRoutesApp />
								) : (
									<div className="tt-ml-50  tt-w-full  tt-min-h-screen">
										<RoutesApp />
									</div>
								)}
							</div>
							<Dialogs />
							<div className="tt-z-full tt-relative">
								<FuseMessage />
							</div>
						</SnackbarProvider>
					</QueryParamProvider>
				</FuseTheme>
			</React.StrictMode>
		</div>
	)
}

export default withProviders(App)
