import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useDispatch } from 'react-redux'

import { requestsActions } from 'src/old-app/store/requestSlice'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useApprovedTaskDeadlineMutation, useApprovedTaskMutation } from 'src/old-app/service/Tasks'
import { useApprovedProjectMutation } from 'src/old-app/service/Projects'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { requestType } from 'src/entities/task'
import { useTranslation } from 'react-i18next'

const RequestApproved = ({ label, type, id, item }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { requestId } = useParams()
	const [approvedTask, taskResult] = useApprovedTaskMutation()
	const [approvedProject, projectResult] = useApprovedProjectMutation()
	const [approvedTaskDeadline] = useApprovedTaskDeadlineMutation()

	useEffect(() => {
		if (taskResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: taskResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (projectResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: projectResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [taskResult, projectResult])

	const approvedHandler = async () => {
		dispatch(interfaceActions.setIsLoading(true))

		if (requestType.TASK_STATUS === type) {
			await approvedTask({ task_id: id })

			dispatch(
				showMessage({
					message: t('task_confirmed'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}
		if (requestType.TASKS_DEADLINE === type) {
			await approvedTaskDeadline(item.deadline_id)

			dispatch(
				showMessage({
					message: t('postponement_confirmed'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}

		dispatch(interfaceActions.setIsLoading(false))
	}
	return (
		<div>
			<Button
				onClick={approvedHandler}
				className="tt-bg-primary-transparent tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary tt-min-h-20 tt-h-20"
			>
				<CheckCircleIcon className="tt-w-20 tt-h-20" />
				{label}
			</Button>
		</div>
	)
}

export default RequestApproved
