import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { TextField } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import download from 'src/old-app/utils/download'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { exportFile } from 'src/old-app/service/File'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getProject } from 'src/old-app/store/projectsSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import ProjectUsersSelect from '../../task/ProjectUsersSelect'
import DialogMain from '../DialogMain'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import getLocation from 'src/old-app/utils/getLocation'
import { getTask, getTasks, getTimeSpentButtonId, getTimeSpentInputId } from 'src/old-app/store/tasksSlice'
import {
	useCreateTimeTrackButtonMutation,
	useCreateTimeTrackInputMutation,
	useUpdateTimeTrackButtonMutation,
	useUpdateTimeTrackInputMutation,
} from 'src/old-app/service/Tasks'
import { useTranslation } from 'react-i18next'

function calcMinutes(start, finish) {
	return (new Date(finish).getTime() - new Date(start).getTime()) / 60000
}

const AddParam = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		field: yup.string().required(t('field_title_is_required')),
		value: yup.string().required(t('value_is_required')),
	})
	const dispatch = useDispatch()
	const task = useSelector(getTask)

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
		setValue,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const createProjectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))

		dispatch(dialogActions.setDialog(false))
		dispatch(interfaceActions.setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader title="Кастомное поле" />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('field')}</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										helperText={errors?.field?.message}
										error={!!errors.field}
										hiddenLabel
										placeholder={t('field_title_e_g')}
										className="dialig-input tt-w-full"
										id="filled-hidden-label-small"
										defaultValue=""
										size="small"
									/>
								)}
								name="field"
								control={control}
							/>
						</div>
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('value')}</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										helperText={errors?.value?.message}
										error={!!errors.value}
										hiddenLabel
										placeholder={t('value_e_g')}
										className="dialig-input tt-w-full"
										id="filled-hidden-label-small"
										defaultValue=""
										size="small"
									/>
								)}
								name="value"
								control={control}
							/>
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={false} rightLabel={t('save')} />
		</form>
	)
}

export default AddParam
