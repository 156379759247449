import React from 'react'
import styles from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { IconButton, Tooltip } from '@mui/material'

interface IProps {
	parentId: string
	projectId: string
	title: string
}

const ProjectToParent: React.FC<IProps> = ({ projectId, parentId, title }) => {
	const { pathname, search } = useLocation()
	const to = pathname.replace(projectId, parentId) + search
	return (
		<Tooltip enterDelay={500} arrow title={title} placement="top-start">
			<Link to={to}>
				<IconButton className="tt-w-[30px] tt-h-[30px]">
					<ArrowUpwardIcon className="tt-w-20 tt-h-20 tt-text-gray-500" />
				</IconButton>
			</Link>
		</Tooltip>
	)
}

export default ProjectToParent
