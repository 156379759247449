import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { BASE_URL } from 'src/shared/config'
import { getToken } from 'src/@entities/session'
import { baseQuery } from './base-query'

export const teamsAPI = createApi({
	reducerPath: 'teams-api',
	tagTypes: ['Team', 'Teams', 'TeamUser', 'TeamProject'],
	baseQuery,

	endpoints: (build) => ({
		getTeams: build.query({
			query: (params) => ({
				url: '/command/',
				params,
			}),
			providesTags: ['Teams'],
		}),
		getTeam: build.query({
			query: (id) => ({
				url: `/command/${id}`,
			}),
			providesTags: ['Team'],
		}),
		getTeamProjects: build.query({
			query: (params = {}) => ({
				url: `/project-command/`,
				params: { per_page: 100, ...params },
			}),
			providesTags: ['TeamProject'],
		}),
		getTeamUsers: build.query({
			query: (params) => ({
				url: '/command-user/',
				params,
			}),
			providesTags: ['TeamUser'],
		}),
		confirmTeam: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/command/confirm`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Team', 'Teams'],
		}),
		rejectTeam: build.mutation({
			query: (body) => ({
				method: 'PUT',
				url: `/command/reject`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Team', 'Teams'],
		}),
		updateTeam: build.mutation({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/command/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Team', 'Teams'],
		}),
		deleteTeamProject: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/project-command`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['TeamProject', 'Team'],
		}),
		createTeams: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/command/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Teams'],
		}),
		createTeamProject: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/project-command/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['TeamProject', 'Team'],
		}),

		createTeamUsers: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/command-user/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['TeamUser', 'Teams', 'Team'],
		}),
		deleteTeamUsers: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: '/command-user/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Teams', 'Team', 'TeamUser'],
		}),
		updateTeamUser: build.mutation({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/command-user/${id}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Teams', 'Team', 'TeamUser'],
		}),
		createTeamHashtag: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/command-hashtag/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Team', 'Teams'],
		}),
		deleteTeamHashtag: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: `/command-hashtag/`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Team', 'Teams'],
		}),
		getTeamHashtag: build.query({
			query: (params) => ({
				url: `/command-hashtag/`,
				params,
			}),
			providesTags: ['Team'],
		}),

		createTeamHashtagLink: build.mutation({
			query: ({ id, hashtagId }) => ({
				method: 'POST',
				url: `/command-hashtag/command/${id}/hashtag/${hashtagId}/link`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Teams', 'Team'],
		}),
		getOnConfirmationTeamsCount: build.query({
			query: (params) => ({
				url: `/command/requests/count`,
				params,
			}),
			providesTags: ['Teams', 'Team'],
		}),
	}),
})

export const getMoreTeamUsers = (id) => {
	const token = getToken()
	const headers = new Headers()
	headers.append('Authorization', `Bearer ${token}`)
	headers.append('accept', 'application/json')

	const requestOptions = {
		method: 'GET',
		headers,
	}

	return fetch(`${BASE_URL}/command-user/?per_page=100&current_page=1&command_id=${id}`, requestOptions).then((res) =>
		res.json()
	)
}

export const {
	useDeleteTeamUsersMutation,
	useGetTeamsQuery,
	useGetTeamQuery,
	useUpdateTeamMutation,
	useCreateTeamsMutation,
	useCreateTeamUsersMutation,
	useGetTeamUsersQuery,
	useCreateTeamProjectMutation,
	useGetTeamProjectsQuery,
	useUpdateTeamUserMutation,
	useDeleteTeamProjectMutation,
	useGetTeamHashtagQuery,
	useCreateTeamHashtagMutation,
	useDeleteTeamHashtagMutation,
	useCreateTeamHashtagLinkMutation,
	useConfirmTeamMutation,
	useRejectTeamMutation,
	useGetOnConfirmationTeamsCountQuery,
} = teamsAPI
