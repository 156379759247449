import { getTaskTypes } from 'src/old-app/store/tasksSlice'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import MySelect from '../ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const TaskTypeSelect = memo(({ id, ...props }) => {
	const { t } = useTranslation()
	const types = useSelector(getTaskTypes)

	const usersLabelRender = (value) => t(`types.${value?.name}`)

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'type', type: 'select' })}
				menuItemRander={usersLabelRender}
				labelRender={usersLabelRender}
				items={types}
				{...props}
			/>
		</>
	)
})

export default TaskTypeSelect
