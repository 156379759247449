import React, { useState } from 'react'
import styles from './styles.module.scss'
import FiledRow from '../../../../shared/components/filed-row'
import { useTeamShow } from '../../hooks'
import moment from 'moment'
import { UserShow } from '../../../../shared/components'
import { Chip } from '@mui/material'
import ProjectStatus from '../../../project/components/project-status'
import ConfirmsTimeline from '../../../../shared/components/confirms-timeline'
import { useTranslation } from 'react-i18next'

interface IProps {
	isWrap?: boolean
}

const TeamInfo: React.FC<IProps> = ({ isWrap }) => {
	const { t } = useTranslation()
	const { team } = useTeamShow()

	return (
		<div className={styles['team-info']}>
			<div>
				<div className={[styles['team-fields'], isWrap && styles['team-fields-wrap']].join(' ')}>
					<FiledRow label={t('team_title')} content={team?.name} />
					{team?.comment && (
						<FiledRow
							label={t('rejection_comment')}
							content={
								<div style={{ overflowWrap: 'anywhere' }}>
									<p className="tt-text-gray-600 tt">{team?.comment}</p>
								</div>
							}
						/>
					)}

					<FiledRow label={t('status')} content={<ProjectStatus status={team?.confirmation_status} />} />
					<FiledRow
						label={t('responsible')}
						content={<UserShow isShowEmail isShowMiddleName user={team?.author_view} />}
					/>
					<FiledRow label={t('creation_date')} content={moment(team?.created_at).format('LL')} />

					<FiledRow
						label={t('tags')}
						content={
							<div className="tt-grid tt-grid-cols-2 tt-gap-4">
								{team?.hashtags?.map((hashtag: any) => (
									<div>
										<Chip
											key={hashtag.id}
											sx={{ height: 24 }}
											className="tt-truncate tt-inline-flex tt-text-12"
											label={hashtag.hashtag}
										/>
									</div>
								))}
								{team?.hashtags?.length === 0 && <div className="tt-text-gray-400">-</div>}
							</div>
						}
					/>

					{team?.confirms?.length !== 0 && (
						<FiledRow label={t('confirmations_history')} content={<ConfirmsTimeline confirmations={team.confirms} />} />
					)}
				</div>
			</div>
		</div>
	)
}

export default TeamInfo
