import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getStatuses, getSubs, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { useGetOneUserQuery } from 'src/old-app/service/Users'
import TaskDetailsLabel from './TaskDetailsLabel'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FieldContent from '../shared/FieldContent'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import TaskDeadlines from './TaskDeadlines'
import TaskProtocol from './TaskProtocol'
import { getUserRole } from 'src/old-app/store/userSlice'
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import { Link, useLocation } from 'react-router-dom'
import { Chip } from '@mui/material'
import GradedTimeStapm from '../shared/GradedTimeStamp'
import UserCellShow from '../tables/UserCellShow'
import { useGetUser } from 'src/shared/hooks'
import { TaskTotalTime } from 'src/entities/task'
import { UserShow } from '../../../shared/components'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import { useProjectShow } from 'src/entities/project'

const User = ({ id }) => {
	const { data: user } = useGetOneUserQuery(id)
	return `${user?.data?.first_name} ${user?.data?.last_name}`
}

const CompleteDate = ({ completeDate, deadline }) => {
	const c = moment(completeDate).format(moment.HTML5_FMT.DATE)
	const d = moment(deadline).format(moment.HTML5_FMT.DATE)
	const isActual = c === d ? true : moment(c).isBefore(d)

	return <span className={[isActual ? 'text-green' : 'text-red'].join(' ')}>{moment(completeDate).format('LL')}</span>
}

const TaskDetails = ({ offEditDeadline, id }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	// const subs = useSelector(getSubs)
	const user = useGetUser()
	const statuses = useSelector(getStatuses)
	const projectShow = useProjectShow()

	const dispath = useDispatch()
	const openDialogBoardCreate = (component) => {
		dispath(tasksActions.getTimeSpentInputId(null))
		dispath(tasksActions.getTimeSpentInputHoursId(null))
		dispath(dialogActions.setDialog(true))
		dispath(dialogActions.setComponent(component))
	}

	const project = task?.project

	const status = statuses?.find((p) => p?.id === task?.task_status?.project_status_id)
	const isFinal = status?.is_final

	const { search, pathname } = useLocation()
	const baseEpicPath = project?.id ? pathname.split(project?.id)[0] : ''
	const epicPath = `${baseEpicPath}${project?.id}/${task?.epic?.id}${search}`

	const isMy = task.isMyTask

	return (
		<div>
			<div className="tt-flex tt-flex-col tt-gap-16 tt-mt-20">
				{/* <TaskDetailsLabel label="Проект" textClassName="text-primary" text={project?.name} /> */}
				{task?.epic && (
					<FieldContent
						content={
							<Link to={epicPath}>
								<span>{task?.epic?.title}</span>
							</Link>
						}
						icon={<TaskAltIcon className="tt-text-gray-700 tt-w-20 tt-h-20" />}
						label={t('task')}
					/>
				)}
				<FieldContent
					content={
						<>
							{projectShow?.isPublic ? (
								<span>{project?.name}</span>
							) : (
								<Link to={`/project/${project.id}/list`}>
									<span>{project?.name}</span>
								</Link>
							)}
						</>
					}
					icon={<AccountTreeOutlinedIcon className="tt-text-gray-700 tt-w-20 tt-h-20" />}
					label={t('project')}
				/>
				<FieldContent
					className="tt-flex-1"
					content={
						<div className="tt-flex tt-gap-4 tt-flex-col">
							{task?.subscribers?.map((user) => (
								<UserCellShow user={user} />
							))}
						</div>
					}
					icon={<TagOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					label={t('observers')}
				/>
				<FieldContent
					content={<p className="tt-break-all">{task?.result_representation}</p>}
					icon={<AccountTreeOutlinedIcon className="tt-text-gray-700 tt-w-20 tt-h-20" />}
					label={t('expected_results')}
				/>
				<FieldContent
					className="tt-flex-1"
					content={moment(task?.created_at).format('LLL')}
					icon={<CalendarMonthOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					label={t('creation_date')}
				/>
				<FieldContent
					className="tt-flex-1"
					content={
						<div className="tt-flex tt-gap-4 tt-flex-wrap">
							{task?.hashtags?.map((item) => (
								<Chip className="tt-truncate" label={item.hashtag} />
							))}
						</div>
					}
					icon={<TagOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					label={t('tags')}
				/>
				<FieldContent
					className="tt-flex-1"
					content={moment(task?.updated_at).format('LLL')}
					icon={<EventOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					label={t('updation_date')}
				/>
				{isFinal && task?.complete_date && (
					<FieldContent
						className="tt-flex-1"
						content={<CompleteDate completeDate={task?.complete_date} deadline={task?.deadline?.deadline} />}
						icon={<EventOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
						label={t('actual_completion_date')}
					/>
				)}
				<FieldContent
					label={t('deadline_history')}
					contentClassName={'tt-w-full'}
					icon={<TimelapseOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					content={<TaskDeadlines deadlines={task?.deadlines ?? []} />}
				/>
				{task?.author_view && (
					<FieldContent
						content={<UserShow user={task?.author_view} />}
						icon={<PersonOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
						label={t('author')}
					/>
				)}
				{task?.protocol && (
					<FieldContent
						content={
							<>
								{projectShow?.isPublic ? (
									<span>{task?.protocol?.topic}</span>
								) : (
									<Link to={`/protocol/${task.protocol.id}`}>
										<span>{task?.protocol?.topic}</span>
									</Link>
								)}
							</>
						}
						icon={<PersonOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
						label={t('protocol')}
					/>
				)}
				{task?.spent_time_by_button ? (
					<FieldContent
						className="tt-flex-1"
						content={<GradedTimeStapm timeStamp={task?.spent_time_by_button * 60} />}
						icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
						label={t('time_spent_by_timer')}
					/>
				) : null}
				{task?.spent_time_by_column ? (
					<FieldContent
						className="tt-flex-1"
						content={<GradedTimeStapm timeStamp={task?.spent_time_by_column * 60} />}
						icon={<AccessTimeOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
						label={t('time_spent_in_each_status')}
					/>
				) : null}
				<TaskDetailsLabel
					id={getID({ prefix: id, field: 'spent_time', type: 'button_open' })}
					isEdit={isMy && !task?.project?.isEnd}
					onClick={() => (task?.project?.isEnd ? null : isMy ? openDialogBoardCreate('spent-time-input') : null)}
					className="tt-flex-1"
					label={t('time_spent_manually_entered')}
					text={<GradedTimeStapm timeStamp={task?.spent_time_by_input * 60} />}
				/>
				<TaskDetailsLabel
					id={getID({ prefix: id, field: 'spent_time_hours', type: 'button_open' })}
					isEdit={isMy & !task?.project?.isEnd}
					onClick={() => (task?.project?.isEnd ? null : isMy ? openDialogBoardCreate('spent-time-input-hours') : null)}
					className="tt-flex-1"
					label={t('time_spent_in_hours_manually_entered')}
					text={<GradedTimeStapm timeStamp={task?.spent_time_by_input_hours * 60} />}
				/>
				<FieldContent
					className="tt-flex-1"
					content={<GradedTimeStapm timeStamp={task?.total_time * 60} />}
					icon={<TimelapseOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
					label={t('time_spent_summirized')}
				/>
				{Object.entries(task?.params ?? {}).map(([field, value], index) => {
					return (
						<FieldContent
							className="tt-flex-1"
							content={value}
							icon={<DataObjectOutlinedIcon className="tt-text-gray-600 tt-w-20 tt-h-20" />}
							label={field}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default TaskDetails
