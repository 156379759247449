import React, { useMemo, useState } from 'react'
import { CircularProgress } from '@mui/material'
import TaskViolationsDecision from './TaskViolationsDecision'
import { useSearchParams } from 'react-router-dom'
import EmptyPlaceholder from '../../../shared/components/empty-placeholder'
import TaskListItem from 'src/old-app/main/tasks/TaskListItem'
import TaskFilters from 'src/old-app/main/tasks/TaskFilters'
import { PathnameCell } from 'src/shared/components'
import queryString from 'query-string'
import { setParamsFilters } from '../../main/tasks/TaskList'
import List from '@mui/material/List'
import { InView } from 'react-intersection-observer'
import { useGetSanctionsQuery } from '../../service/Tasks'
import { useTranslation } from 'react-i18next'
import {  getAdminRole } from 'src/old-app/store/userSlice'
import { useSelector } from 'react-redux'

const TasksViolationsOnePageList = ({
	projectId,
	page,
	setPageCount,
	pagesCount,
	isUrl,
	multipleParamsString,
	params,
	isManage
}) => {
	const { t } = useTranslation()
	const { data: sanctionsData, isSuccess } = useGetSanctionsQuery({
		url: multipleParamsString,
		params: { ...params, current_page: page },
	})



	const sanctions = sanctionsData?.data ?? []
	const totalPages = sanctionsData?.meta?.total_pages
	const needMorePages = pagesCount < totalPages

	const viewTaskViolationsDecision = isManage

	return (
		<>
			{!isSuccess && <CircularProgress color={'secondary'} sx={{ m: '10px auto', display: 'block' }} size={20} />}

			{isSuccess && sanctions.length === 0 && <EmptyPlaceholder text={t('empty_tasks_list')} />}

			<InView
				children={({ ref, inView, entry }) => (
					<li ref={ref} style={!inView && entry ? { height: entry.boundingClientRect.height } : {}}>
						{inView && (
							<>
								{sanctions?.map((sanction) => (
									<TaskListItem
										url={
											isUrl
												? ({ projectId, taskId, location }) => ({
														...location,
														pathname: `${page ?? ''}${projectId}/${taskId}`,
												  })
												: undefined
										}
										hideSkelet={{ left: true, tags: true, project: !!projectId, priority: true, author: true }}
										right={
											viewTaskViolationsDecision ? 
											<PathnameCell>
												<div className="tt-flex tt-gap-8 tt-items-center tt-w-[200px] tt-px-[7px]">
													<TaskViolationsDecision sanction={sanction} taskId={sanction?.task_id} />
												</div>
												</PathnameCell>
												: <div className="tt-flex tt-gap-8 tt-items-center tt-w-[40px]"></div>
										}
										className="hover:tt-bg-primary/10"
										activeClassName="tt-bg-primary/10 hover:tt-bg-primary/10"
										accordionSx={{ paddingBottom: '8px' }}
										takeStatusesFromRedux
										projectId={projectId}
										key={sanction?.id}
										data={{
											...sanction?.task,
											complete_date: sanction?.closed,
										}}
									/>
								))}

								{needMorePages && page === pagesCount && (
									<InView
										delay={50}
										skip={pagesCount >= totalPages}
										onChange={(inView) => {
											inView && setPageCount((curr) => (curr < sanctionsData?.meta?.total_pages ? curr + 1 : curr))
										}}
									/>
								)}
							</>
						)}
					</li>
				)}
			/>
		</>
	)
}

const TasksViolations = (props) => {
	const { projectId, allProjectsMode } = props
	const [pagesCount, setPagesCount] = useState(1)
	const [searchParams] = useSearchParams()
	const isAdmin = useSelector(getAdminRole)

	const isManage = isAdmin || props.isManage
	const stringifySearchParams = searchParams.toString()

	const { multipleParamsString, params } = useMemo(() => {
		setPagesCount(1)

		const parsedSearch = queryString.parse(searchParams.toString())

		const {
			searchTask,
			slug,
			projects,
			order_sort: createdSort = 'desc',
			deadline_start: deadlineStart,
			deadline_end: deadlineEnd,
			deadline_sort: deadlineSort,
			completed_at_start: completedAtStart,
			completed_at_end: completedAtEnd,
			date_created_at_end: createdAtStart,
			date_created_at_start: createdAtEnd,
			assignee = [],
			status = [],
			author = [],
			priority = [],
			type = [],
			tags = [],
			fields = JSON.stringify({}),
		} = parsedSearch

		const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue, fieldsValue] = [
			status,
			assignee,
			priority,
			author,
			type,
			tags,
			fields,
		].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

		const multipleParamsString = setParamsFilters({
			type: typeValue,
			author: [],
			priority: priorityValue,
			statuses: statusValue,
			assignee: assigneeValue,
			tags: tagsValue,
		})

		const params = {
			project_id: projectId ?? projects,
			created_at_sort: createdSort,
			deadline_sort: deadlineSort ?? undefined,
			per_page: 50,
			only_epic: false,
			slug,
			author: authorValue?.length === 0 ? undefined : authorValue.join(','),
			title: searchTask,
			start_date_deadline: deadlineStart,
			end_date_deadline: deadlineEnd,
			start_completed_at: completedAtStart,
			end_completed_at: completedAtEnd,
			start_date_created_at: createdAtStart,
			end_date_created_at: createdAtEnd,
			...fieldsValue,
		}

		return { multipleParamsString, params }
	}, [stringifySearchParams])

	return (
		<div className="tt-flex tt-flex-col tt-w-full">
			<table className="tt-w-full tt-overflow-hidden">
				<TaskFilters
					allProjectsMode={allProjectsMode}
					skeletClassName={{ right: isManage ? 'tt-w-[200px]': "" }}
					isRightFilterLabel={isManage}
					hideSkelet={{ left: true, tags: true, author: true, project: !!projectId, slug: !projectId, priority: true }}
				/>
				<List className="tt-pt-0">
					{Array(pagesCount)
						.fill(null)
						.map((_, _page) => (
							<TasksViolationsOnePageList
								{...props}
								key={_page}
								page={_page + 1}
								params={params}
								pagesCount={pagesCount}
								setPageCount={setPagesCount}
								multipleParamsString={multipleParamsString}
								isManage={isManage}
							/>
						))}
				</List>
			</table>
		</div>
	)
}

export default TasksViolations
