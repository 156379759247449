const getSlug = (title) => {
	return title
		.split(' ')
		.map(function (item) {
			return item[0]
		})
		.join('')
		.toUpperCase()
}

export default getSlug
