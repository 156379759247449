import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface IProps {
	label: string
	labelClassName?: string
	className?: string
	content: ReactNode
}

const FiledRow: React.FC<IProps> = ({ content, label, labelClassName, className }) => {
	return (
		<div className={clsx(styles['filed-row'], className)}>
			<div className={[styles['filed-label'], labelClassName].join(' ')}>{label}:</div>
			<div className={styles['filed-content']}>{content}</div>
		</div>
	)
}

export default FiledRow
