import React from 'react'
import { useTranslation } from 'react-i18next'

const MONTH = 'month',
	YEAR = 'year'

export function ViewToggle({ view, setView }) {
	const { t } = useTranslation()
	const views = [
		[MONTH, t('date_signature.day')],
		[`${MONTH}-weeks`, t('date_signature.week')],
		[YEAR, t('date_signature.month')],
	]
	return (
		<div className={'react-calendar__viewToggle-container'}>
			{views.map(([_view, label]) => {
				const active = _view === view
				return (
					<button
						disabled={active}
						key={_view}
						className={`react-calendar__viewToggle-button ${active ? '--active' : ''}`}
						onClick={() => {
							setView(_view)
						}}
					>
						{label}
					</button>
				)
			})}
		</div>
	)
}
