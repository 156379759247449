import { useTranslation } from 'react-i18next'

const GradedTimeStapm = ({ timeStamp }) => {
	const { t } = useTranslation()

	if (!timeStamp || timeStamp === 0) {
		return null
	}

	const d = Math.floor(timeStamp / (3600 * 24))
	const h = Math.floor((timeStamp % (3600 * 24)) / 3600)
	const m = Math.floor((timeStamp % 3600) / 60)
	const s = Math.floor(timeStamp % 60)

	const dDisplay = d > 0 ? `${d} ${t('time.days')} ` : ''
	const hDisplay = h > 0 ? `${h} ${t('time.hours')} ` : ''
	const mDisplay = m > 0 ? `${m} ${t('time.minutes')} ` : ''
	const sDisplay = s > 0 ? `${s} ${t('time.seconds')} ` : ''

	return <span>{dDisplay + hDisplay + mDisplay + sDisplay}</span>
}

export default GradedTimeStapm
