import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import download from 'src/old-app/utils/download'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { exportFile } from 'src/old-app/service/File'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getProject } from 'src/old-app/store/projectsSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import ProjectUsersSelect from '../../task/ProjectUsersSelect'
import DialogMain from '../DialogMain'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import getUser from 'src/old-app/utils/getUser'
import { useGetUser } from 'src/shared/hooks'
import { getUserName } from 'src/entities/user'
import { serializeParams } from 'src/old-app/utils/serializeRequestParams'
import { useQueryParams } from 'use-query-params'
import { AllMyTasksPageQueryParams } from 'src/old-app/main/my/AllMyTasksList'
import { getUserID } from 'src/old-app/store/userSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const schema = yup.object().shape({})

const AllKPG = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useGetUser()
	const userName = useSelector(getUserName)
	const project = useSelector(getProject)
	const userID = useSelector(getUserID)
	const [isDisabled, setIsDisabled] = useState(false)
	const [searchQueryParams] = useQueryParams(AllMyTasksPageQueryParams)

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
	} = useForm({
		defaultValues: {
			on_one_page: true,
		},
		resolver: yupResolver(schema),
	})

	const exportFileHandler = (path, data) => {
		const params = serializeParams({
			params: {
				project_id: searchQueryParams.projects,
				order_sort: searchQueryParams.order_sort,
				slug: searchQueryParams.slug,
				on_one_page: !data?.on_one_page,
				title: searchQueryParams.searchTask,
				start_date_deadline: searchQueryParams.deadline_start,
				end_date_deadline: searchQueryParams.deadline_end,
				start_completed_at: searchQueryParams.completedAtStart,
				end_completed_at: searchQueryParams.completedAtEnd,
				end_date_created_at: searchQueryParams.createdAtEnd,
				start_date_created_at: searchQueryParams.createdAtStart,
				author: searchQueryParams?.author?.join(','),
				assignee_id: userID,
				...searchQueryParams.fields,
			},
			arraysParams: {
				project_status_id: searchQueryParams.status,
				// parent_id: searchQueryParams.author,
				task_priority_id: searchQueryParams.priority,
				type_id: searchQueryParams.type,
				hashtag_id: searchQueryParams.tags,
			},
		})

		const url = `/${path}/?${params}`
		exportKPGHandler({ url, params: new URLSearchParams(params), name: userName, dispatch })
	}

	const createProjectHandler = async (data) => {
		exportFileHandler('report', data)
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('download_cpg_header')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mt-10">
					<Controller
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								id={getID({ place: 'modal', type: 'checkbox', prefix: 'on_one_page' })}
								className="-mt-6"
								control={<Checkbox checked={value} onChange={onChange} />}
								label={t('divide_projects_into_pages')}
							/>
						)}
						name="on_one_page"
						control={control}
					/>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} rightLabel={t('download_cpg')} />
		</form>
	)
}

export default AllKPG
