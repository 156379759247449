import { useGetProjectsQuery } from '../../../old-app/service/Projects'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { AllMyTasksPageQueryParams, customArrayParam, dateParam } from '../../../old-app/main/my/AllMyTasksList'
import { useGetProtocolsQuery } from '../../../old-app/service/Protocol'
import { useGetListProjectsTemplatesQuery } from '../api/projects-templates-api'

const Params: any = {
	name: StringParam,
	order_sort: withDefault(StringParam, 'desc'),
	author: customArrayParam,
}

const useProjectTemplates = ({ params }: { params: any }) => {
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const { data: projectTemplatesData, isLoading } = useGetListProjectsTemplatesQuery({
		name: queryParams.name,
		created_at_sort: queryParams.order_sort,
		author_id: queryParams?.author?.join(','),
		...params,
	})

	const projectTemplates = projectTemplatesData?.data ?? []
	const meta = projectTemplatesData?.meta ?? {}

	return { projectTemplates, isLoading, meta }
}
export default useProjectTemplates
