import React from 'react'

const FieldContent = ({ icon, label, content, className, contentClassName }) => {
	return (
		<div className={['tt-flex tt-items-start tt-flex-col tt-gap-6', className].join(' ')}>
			<div className={['tt-flex tt-gap-6 tt-items-center', contentClassName].join(' ')}>
				{icon}
				<span className={['tt-text-14 tt-text-gray-700 tt-whitespace-nowrap', contentClassName].join(' ')}>
					{label}
				</span>
			</div>
			<div className={contentClassName}>{content}</div>
		</div>
	)
}

export default FieldContent
