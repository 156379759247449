import React from 'react'
import styles from './styles.module.scss'
import Avatar from '../avatar'
import TableCellEmpty from '../table/table-cell-empty'
import { getAcronym } from 'src/@shared/lib'

interface IProps {
	user: any
	isHideName?: boolean
	isShowEmail?: boolean
	isShowMiddleName?: boolean
}

const UserShow: React.FC<IProps> = ({ user, isHideName, isShowEmail, isShowMiddleName }) => {
	if (!user) return <TableCellEmpty />

	const name = `${user?.last_name} ${user?.first_name} ${isShowMiddleName ? user?.middle_name ?? '' : ''}`
	const noname = getAcronym(name)

	return (
		<div className={styles['user-show']}>
			<Avatar src={user?.avatar256} alt={noname} />
			<div className="tt-flex tt-flex-col">
				{!isHideName && <span className={styles['user-name']}>{name}</span>}
				{isShowEmail && user?.email && <span className={styles['user-email']}>{user?.email}</span>}
			</div>
		</div>
	)
}

export default UserShow
