import { getUser } from 'src/@entities/session'
import { getRoles } from '../../old-app/store/userSlice'
import { useSelector } from 'react-redux'

const useHasRole = (roles?: string[]) => {
	const userRoles = useSelector(getRoles)

	const hasRoleHandler = (roles: string[]) => roles.filter((role) => userRoles?.includes(role)).length > 0

	const isHasRole = userRoles?.length === 0 ? true : hasRoleHandler(roles ?? [])
	const hasRole = hasRoleHandler

	return { isHasRole, hasRole }
}

export default useHasRole
