import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

const accessMiddleware: Middleware = () => (next) => (action) => {
	if (isRejectedWithValue(action) && (action.payload.status === 404 || action.payload.status === 403)) {
		return (location.href = '/404')
	}

	return next(action)
}

export default accessMiddleware
