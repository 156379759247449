import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import Divider from '@mui/material/Divider'
import ArchiveIcon from '@mui/icons-material/Archive'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import AddTaskIcon from '@mui/icons-material/AddTask'
import PercentIcon from '@mui/icons-material/Percent'
import CheckIcon from '@mui/icons-material/Check'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import Popover from '@mui/material/Popover'
import { Chip, IconButton, Input } from '@mui/material'
import { useCreateSanctionMutation } from 'src/old-app/service/Tasks'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import solutionType from 'src/old-app/constants/solutionType'
import { useEffect } from 'react'
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined'
import { useState } from 'react'
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble'
import { useTranslation } from 'react-i18next'

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}))

const SanctionSum = ({ sum, className }) => {
	return (
		<span
			className={[
				'tt-inline-flex tt-items-center tt-border-gray-200 tt-border tt-text-12 tt-px-6 tt-py-1.5 tt-rounded-lg tt-bg-gray-100 tt-text-gray-500 tt-font-medium',
				className,
			].join(' ')}
		>
			<CurrencyRubleIcon style={{ marginRight: 4 }} className="tt-h-16 tt-w-16   tt-text-gray-500" />

			{sum}
		</span>
	)
}

const getSumInPercent = (sum, percentage) => {
	const totalSum = Math.floor(+sum * (+percentage / 100))
	return Math.floor(sum * (percentage / 100))
}

export default function TaskViolationsDecision({ taskId, sanction }) {
	const { t } = useTranslation()
	const { future_sanction, solution } = sanction

	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)
	const [percent, setPercent] = useState(null)
	const [anchorElCP, setAnchorElCP] = useState(null)
	const open = Boolean(anchorEl)

	const [createSanction, result] = useCreateSanctionMutation()

	useEffect(() => {
		if (result?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			setAnchorEl(null)
			setAnchorElCP(null)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [result])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClickCP = (event) => {
		setAnchorElCP(event.currentTarget)
	}

	const handleCloseCP = () => {
		setAnchorElCP(null)
	}

	const createSanctionHandler = async ({ solution, percentage = 0 }) => {
		try {
			dispatch(interfaceActions?.setIsLoading(true))
			const body = {
				percentage,
				solution,
				task_id: taskId,
			}

			await createSanction(body)
			setAnchorEl(null)
			setAnchorElCP(null)
			dispatch(interfaceActions?.setIsLoading(false))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setAnchorEl(null)
			setAnchorEl(null)
			setAnchorElCP(null)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const choocePercentHandler = () => {
		handleCloseCP()
		handleClose()
	}

	const openCP = Boolean(anchorElCP)
	const idCP = openCP ? 'simple-popover' : undefined

	const color = () => {
		if (solutionType.ACCEPT === solution?.solution) return 'tt-text-red-600'
		if (solutionType.SOLUTION_DECLINE === solution?.solution) return 'tt-text-green-600'
		if (solutionType.ACCEPT_PERCENT === solution?.solution) return 'tt-text-orange-600'
		return 'text-gray-500'
	}

	return (
		<div>
			{solution ? (
				<button
					id="demo-customized-button"
					aria-describedby={idCP}
					aria-controls={open ? 'demo-customized-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					disableElevation
					onClick={handleClick}
					className={['tt-text-left tt-font-medium tt-text-13 tt-cursor-pointer tt-pl-[7px]', color()].join(' ')}
				>
					{solutionType.ACCEPT === solution?.solution && (
						<span className="tt-flex tt-gap-2 tt-items-center tt-flex-wrap">
							{`${t('accrued')} - `}
							<SanctionSum sum={sanction.sanction} />
						</span>
					)}
					{solutionType.SOLUTION_DECLINE === solution?.solution && (
						<span className="tt-flex tt-gap-2 tt-items-center tt-flex-wrap">
							{`${t('non_accrued')} - `}
							<SanctionSum sum={sanction.sanction} />
						</span>
					)}
					{solutionType.ACCEPT_PERCENT === solution?.solution && (
						<span lassName="tt-flex tt-items-center tt-gap-2  tt-flex-wrap">
							{`${t('accrued')} ${solution?.percentage}% - `}
							<SanctionSum sum={getSumInPercent(+sanction.sanction, +solution.percentage)} />
						</span>
					)}
				</button>
			) : (
				<div className="tt-flex tt-gap-4 tt-items-center tt-flex-wrap">
					<Button
						id="demo-customized-button"
						aria-describedby={idCP}
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						disableElevation
						onClick={handleClick}
						className="tt-bg-primary-transparent tt-pr-[9px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px]"
					>
						<TouchAppOutlinedIcon className="tt-w-22 tt-h-22" />
						{t('sanction_decision')}
					</Button>
					<SanctionSum sum={sanction?.sanction} />
				</div>
			)}
			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={() => createSanctionHandler({ solution: solutionType.ACCEPT })} disableRipple>
					<CheckIcon />
					{t('apply_sanction')}
					<SanctionSum className="tt-ml-[12px]" sum={sanction?.sanction} />
				</MenuItem>

				<MenuItem onClick={() => createSanctionHandler({ solution: solutionType.SOLUTION_DECLINE })} disableRipple>
					<CloseOutlinedIcon />
					{t('deny_sanction')}
				</MenuItem>

				<MenuItem onClick={handleClickCP} disableRipple>
					<PercentIcon />
					{t('apply_sanction_percent')}
				</MenuItem>
			</StyledMenu>

			<Popover
				id={idCP}
				open={openCP}
				anchorEl={anchorElCP}
				onClose={handleCloseCP}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<div className="tt-p-16 tt-px-32 tt-pt-32 tt-flex tt-flex-col tt-gap-6 tt-w-256">
					<div>
						<SanctionSum sum={getSumInPercent(sanction?.sanction, percent)} />
					</div>
					<Input
						placeholder={0}
						type="number"
						value={percent}
						onChange={(event) =>
							+event.target.value <= 100 && +event.target.value >= 0
								? setPercent(Math.floor(+event.target.value))
								: null
						}
					/>
					{percent ? (
						<Button
							onClick={() => createSanctionHandler({ solution: solutionType.ACCEPT_PERCENT, percentage: percent })}
							className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
						>
							{t('apply')}
						</Button>
					) : null}
				</div>
			</Popover>
		</div>
	)
}
