import { useGetTeamsQuery } from '../../../old-app/service/Teams'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { customArrayParam, dateParam } from '../../../old-app/main/my/AllMyTasksList'

const Params: any = {
	slug: StringParam,
	name: StringParam,
	completed_at_start: dateParam,
	completed_at_end: dateParam,
	order_sort: withDefault(StringParam, 'desc'),
	author: customArrayParam,
	tags: customArrayParam,
}

const useTeamRequests = ({ params }: { params: any }) => {
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const {
		data: teamsData,
		isLoading,
		isFetching,
	} = useGetTeamsQuery({
		slug: queryParams.slug,
		name: queryParams.name,
		confirmation_status: 'ON_CONFIRMATION',
		created_at_sort: queryParams.order_sort,
		start_completed_at: queryParams.completed_at_start,
		end_completed_at: queryParams.completed_at_end?.replace('T00:00:00', 'T23:59:59'),
		authors: queryParams?.author?.join(','),
		hashtag_ids: queryParams?.tags?.join(','),
		...params,
	})

	const teams = teamsData?.data ?? []
	const meta = teamsData?.meta ?? {}

	return { teams, isLoading, meta, isFetching }
}
export default useTeamRequests
