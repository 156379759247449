import React, { useState } from 'react'
import TaskContext from '../../../task/contexts/task-context'
import { requestType } from '../../../task'
import { PathnameCell } from '../../../../shared/components'
import Button from '@mui/material/Button'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import MenuItem from '@mui/material/MenuItem'
import TaskApproved from '../../../task/components/task-approved'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import TaskDecline from '../../../task/components/task-decline'
import { alpha, styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import { useConfirmProjectMutation } from '../../../../old-app/service/Projects'
import { useDispatch } from 'react-redux'
import { dialogActions } from '../../../../old-app/store/dialogSlice'
import { interfaceActions } from '../../../../old-app/store/interfaceSlice'
import { useTranslation } from 'react-i18next'

const ProjectRequestButton = ({ projectId }) => {
	const { t } = useTranslation()

	const [confirmProject] = useConfirmProjectMutation()
	const dispatch = useDispatch()

	const approveHandler = async () => {
		dispatch(interfaceActions.setIsLoading(true))
		try {
			await confirmProject({ project_id: projectId })
			dispatch(interfaceActions.setIsLoading(false))
		} catch (error) {
			console.error(error)
			dispatch(interfaceActions.setIsLoading(false))
		}
	}
	const declineHandler = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('project-reject'))
		dispatch(dialogActions.setDialogState({ projectId }))
	}

	// @ts-ignore
	return (
		<PathnameCell>
			<div className="tt-w-[140px] tt-flex tt-items-center tt-gap-4 row-hover tt-cursor-default">
				<div className="tt-w-[140px] tt-flex tt-items-center tt-gap-4 row-hover-first">
					<div
						onClick={approveHandler}
						className="tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-green-600/10 tt-border tt-border-green-600/20 "
					>
						<DoneOutlinedIcon className="tt-text-green-500 tt-storke-2 tt-text-20" />
					</div>
					<div
						onClick={declineHandler}
						className="tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-red-600/10 tt-border tt-border-red-600/20 "
					>
						<CloseOutlinedIcon className="tt-text-red-500 tt-storke-2 tt-text-20" />
					</div>
				</div>

				<Button className=" tt-bg-primary-transparent tt-px-[8px] tt-h-[23px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px] row-hover-second">
					<TouchAppOutlinedIcon className="tt-w-22 tt-h-22" />
					{t('confirmation_decision')}
				</Button>
			</div>
		</PathnameCell>
	)
}

export default ProjectRequestButton
