import { useSelector } from 'react-redux'
import { getUserID } from 'src/old-app/store/userSlice'
import { useGetUserProjectsQuery } from '../../service/Projects'
import { useGetStatusRQuery } from '../../service/Tasks'
import React, { useMemo } from 'react'
import MySelect from '../../components/ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'

function groupByProjectName(statuses, projects) {
	return statuses?.reduce(
		(acc, oneStatus) => {
			const { id, status } = oneStatus
			const projectName = projects.find(({ project_id }) => project_id === oneStatus.project_id).project?.name

			if ([projectName] in acc.byProjectName) {
				acc.byProjectName[projectName].push({ id, status })
			} else {
				acc.byProjectName[projectName] = [{ id, status }]
			}
			return acc
		},
		{ byProjectName: {} }
	)
}

export function UserProjectsStatusesSelect({ value, requestProjectID, id, ...props }) {
	const userID = useSelector(getUserID)

	const statusSelectRender = (value) => value?.status

	const requestParams = {
		user_id: userID,
		per_page: 100,
	}

	if (requestProjectID && requestProjectID?.length) {
		requestParams.project_id = requestProjectID
	}

	const projectsQuery = useGetUserProjectsQuery(requestParams, { skip: !userID })

	const projectIds = projectsQuery?.data?.data.map((project) => project.project_id) ?? []

	const statusesQuery = useGetStatusRQuery(
		{
			params: { per_page: 250, project_id: projectIds.join(','), order_sort: 'asc' },
			arraysParams: {
				// project_id: projectsQuery.data?.data.map(project => project.project_id)
			},
		},
		{
			skip: !projectsQuery.isSuccess,
		}
	)

	const foundByIdsStatuses = useMemo(() => {
		if (projectsQuery?.isFetching || statusesQuery?.isFetching) return props.multiple ? [] : ''

		return props.multiple
			? value?.map((valueID) => statusesQuery?.data?.data.find(({ id }) => id === valueID))
			: statusesQuery?.data?.data.find(({ status }) => status === value)
	}, [value, statusesQuery?.data])

	const preparedItems = useMemo(() => {
		if (!projectsQuery?.isSuccess || !projectsQuery?.isSuccess) return

		return groupByProjectName(statusesQuery?.data?.data, projectsQuery?.data?.data)
	}, [statusesQuery?.data])

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'status', type: 'select' })}
				menuItemRander={statusSelectRender}
				labelRender={statusSelectRender}
				items={preparedItems?.byProjectName}
				value={foundByIdsStatuses}
				withSubHeadersMode
				{...props}
			/>
		</>
	)
}
