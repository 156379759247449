import React, { useEffect, useRef } from 'react'
import { Button } from '@mui/material'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { useDispatch, useSelector } from 'react-redux'
import { fileUpload } from 'src/old-app/service/File'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'

import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { useGetUser } from 'src/shared/hooks'
import { getID } from '../../../shared/config/fields-ids'
import { useCreateCompleteFileMutation } from 'src/old-app/service/Tasks'
import { checkFileSize } from 'src/shared/lib/checkFileSize'
import { useTranslation } from 'react-i18next'

const AddCompleteFileButton = ({ id }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const user = useGetUser()
	const [createTaskCompleteFile, taskCompleteFileResult] = useCreateCompleteFileMutation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (taskCompleteFileResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: taskCompleteFileResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [taskCompleteFileResult])

	const fileInputRef = useRef()

	const inputFileClick = () => {
		fileInputRef.current.click()
	}

	const resetFileInput = () => {
		// 👇️ reset input value
		fileInputRef.current.value = null
	}

	const onChangeFilesHandler = async (event) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const files = [...event.target.files]

			for (const file of files) {
				if (!checkFileSize(file.size)) {
					dispatch(interfaceActions.setIsLoading(false))
					dispatch(
						showMessage({
							message: t('maximum_file_size'),
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'top-right',
							},
							variant: 'warning',
						})
					)
					return
				}
			}

			const getCompleteFiles = []
			await Promise.all(
				files.map(async (item) => {
					const fileData = await fileUpload(item)

					if (!fileData?.data) return
					getCompleteFiles.push(fileData?.data)
					const fileId = fileData?.data?.id
					const taskId = task?.id

					await createTaskCompleteFile({
						author_id: user.id,
						file_id: fileId,
						task_id: taskId,
					})
				})
			)
			// const scrumBoardData = {
			// 	listId: task?.task_status?.project_status_id,
			// 	cardId: task.id,
			// 	data: { complete_file: [...task.complete_file, ...getCompleteFiles] },
			// };

			// dispatch(scrumboardActions.updateOneListCard(scrumBoardData));
			// dispatch(tasksActions.updateTask({ id: task.id, complete_file: getCompleteFiles }));

			resetFileInput()

			dispatch(
				showMessage({
					message: files?.length === 1 ? t('file_uploaded_successfully') : t('files_uploaded_successfully'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
			setTimeout(() => {
				dispatch(interfaceActions.setIsLoading(false))
			}, 1000)
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))

			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<div>
			<input
				id={getID({ prefix: id, type: 'input_file', action: 'confirm', field: 'files' })}
				hidden
				ref={fileInputRef}
				multiple
				type="file"
				onChange={(event) => onChangeFilesHandler(event)}
			/>
			<Button
				id={getID({ prefix: id, type: 'button_add', action: 'confirm', field: 'files' })}
				onClick={inputFileClick}
				type="button"
				startIcon={
					<svg
						className="tt-stroke-gray-700"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.6434 5.83333L7.1551 11.3217C6.99591 11.4754 6.86894 11.6593 6.7816 11.8627C6.69425 12.066 6.64827 12.2847 6.64635 12.506C6.64442 12.7273 6.68659 12.9468 6.77039 13.1516C6.8542 13.3564 6.97795 13.5425 7.13444 13.699C7.29093 13.8555 7.47701 13.9792 7.68184 14.063C7.88667 14.1468 8.10613 14.189 8.32743 14.1871C8.54873 14.1852 8.76743 14.1392 8.97077 14.0518C9.17411 13.9645 9.35802 13.8375 9.51176 13.6783L14.8568 8.18999C15.464 7.56132 15.7999 6.71931 15.7923 5.84532C15.7847 4.97133 15.4342 4.13529 14.8162 3.51727C14.1981 2.89924 13.3621 2.54868 12.4881 2.54108C11.6141 2.53349 10.7721 2.86947 10.1434 3.47666L4.7976 8.96416C3.85984 9.90192 3.33301 11.1738 3.33301 12.5C3.33301 13.8262 3.85984 15.0981 4.7976 16.0358C5.73536 16.9736 7.00724 17.5004 8.33343 17.5004C9.65963 17.5004 10.9315 16.9736 11.8693 16.0358L17.0834 10.8333"
							stroke="#94A3B8"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				}
				className="tt-border-gray-200  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16 tt-z-10"
				variant="outlined"
			>
				{t('upload_task_completion_report')}
			</Button>
		</div>
	)
}

export default AddCompleteFileButton
