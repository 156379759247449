import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Skeleton } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getProjectTemplateData, projectsActions } from 'src/old-app/store/projectsSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { useAddTaskEpicMutation, useGetTasksQuery } from 'src/old-app/service/Tasks'
import { setParamsFilters } from 'src/old-app/main/tasks/TaskList'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskFilters from '../../../main/tasks/TaskFilters'
import TaskRow from 'src/old-app/main/tasks/TaskRow'
import { useTranslation } from 'react-i18next'

// function calcMinutes(start, finish) {
// 	return (new Date(finish).getTime() - new Date(start).getTime()) / 60000;
// }

const filter_prefix = 'epic-'
const initialPerPage = 100

const Epics = ({ isProjectTemplateTask }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const epic = useSelector(getTask)
	// const isAutoUpdate = useSelector(getIsAutoUpdate)
	const projectTempalteData = useSelector(getProjectTemplateData)
	// const navigate = useNavigate()
	// const getTasksData = useSelector(getTasks)
	// const isUser = useSelector(getUserRole)
	// const project = useSelector(getProject)
	const params = useParams()
	const projectId = params?.projectId
	// const [total, setTotal] = useState(0)
	// const { search, pathname } = useLocation();
	// const [fetchLoading, setFetchLoading] = useState(false)
	// const [isFetchLoading, setIsFetchLoading] = useState(false)
	// const [fetching, setFetching] = useState(false)
	const [perPage, serPerPage] = useState(initialPerPage)
	const [total, setTotal] = useState(0)
	const [fetchLoading, setFetchLoading] = useState(false)
	const [fetching, setFetching] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()

	// const taskTypes = useSelector(getTaskTypes)

	const deadlineStart = searchParams.get(`${filter_prefix}deadline_start`) ?? undefined
	const deadlineEnd = searchParams.get(`${filter_prefix}deadline_end`) ?? undefined
	const completedAtStart = searchParams.get(`${filter_prefix}completed_at_start`) ?? undefined
	const completedAtEnd = searchParams.get(`${filter_prefix}completed_at_end`) ?? undefined
	const assignee = searchParams.get(`${filter_prefix}assignee`) ?? []
	const status = searchParams.get(`${filter_prefix}status`) ?? []
	const author = searchParams.get(`${filter_prefix}author`) ?? []
	const searchTask = searchParams.get(`${filter_prefix}searchTask`) ?? undefined
	const slug = searchParams.get(`${filter_prefix}slug`) ?? undefined
	const priority = searchParams.get(`${filter_prefix}priority`) ?? []
	const type = searchParams.get(`${filter_prefix}type`) ?? []
	const [paramsSearch, setParamsSearch] = useState({})

	const statusValue = Array.isArray(status) ? status : JSON.parse(status)
	const assigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	const priorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	const authorValue = Array.isArray(author) ? author : JSON.parse(author)
	const typeValue = Array.isArray(type) ? type : JSON.parse(type)

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		author: authorValue,
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
	})

	const [selectedEpicIds, setSelectedEpicIds] = useState([])

	const {
		data: tasks,
		isLoading,
		refetch,
	} = useGetTasksQuery(
		{
			url: multipleParamsString,
			params: {
				project_id: epic?.project_id,
				created_at_sort: 'desc',
				current_page: 1,
				per_page: perPage,
				slug,
				only_available_to_put: true,
				epic_id_for_putting: epic?.id,
				// start_date_deadline: deadlineStart ? moment(deadlineStart).format() : undefined,
				start_date_deadline: deadlineStart,
				// end_date_deadline: deadlineStart ? moment(deadlineEnd).format() : undefined,
				end_date_deadline: deadlineEnd,
				// start_completed_at: completedAtStart ? moment(completedAtStart).format() : undefined,
				start_completed_at: completedAtStart,
				// end_completed_at: completedAtEnd ? moment(completedAtEnd + 'T23:59:59').format() : undefined,
				end_completed_at: completedAtEnd,
				title: searchTask,
				...paramsSearch,
			},
		},
		{ refetchOnMountOrArgChange: true, skip: isProjectTemplateTask }
	)

	const [addTaskEpic] = useAddTaskEpicMutation()

	const {
		handleSubmit,
		// control,
		// formState: { errors },
		// watch,
		// setError,
		// setValue,
	} = useForm({
		defaultValues: {},
	})

	const templateTasks = projectTempalteData?.template?.Tasks ?? []

	const curTasks = isProjectTemplateTask ? templateTasks : tasks?.data ?? []

	const createHandler = async () => {
		if (isProjectTemplateTask) {
			let newTasks = JSON.parse(JSON.stringify(projectTempalteData?.template?.Tasks))
			selectedEpicIds.map((selectedEpic) => {
				newTasks = newTasks.map((taskItem) => {
					if (taskItem.id === selectedEpic.id) {
						return { ...taskItem, parent_id: epic.id }
					}
					return taskItem
				})
			})

			dispatch(
				projectsActions.putProjectTemplateTasks(
					newTasks.map((newTaskItem) => {
						if (newTaskItem.id === epic.id) {
							return {
								...newTaskItem,
								type_id: '9ff9911d-d33e-4909-96b5-4730b2db1623',
								type: {
									id: '9ff9911d-d33e-4909-96b5-4730b2db1623',
									created_at: '2022-12-08T16:45:32Z',
									updated_at: '2022-12-08T16:45:32Z',
									deleted_at: null,
									name: 'Эпик',
									color: '#5249e5',
								},
							}
						}
						return newTaskItem
					})
				)
			)
			return dispatch(dialogActions.setDialog(false))
		}
		dispatch(interfaceActions.setIsLoading(true))

		selectedEpicIds.map(async (_epic) => {
			addTaskEpic({ id: epic.id, epic: _epic.id })
		})

		// selectedEpicIds.map((s) => {
		// 	dispatch(tasksActions.addSubTasks({ taskId: task?.id, task: { ...s } }))
		// 	dispatch(tasksActions.deleteTask(s?.id))
		// })

		dispatch(dialogActions.setDialog(false))
		dispatch(interfaceActions.setIsLoading(false))
	}

	const selectedEpicHandler = (task, checked) => {
		if (epic?.id === task?.id) return

		// console.log({ task, checked });
		// if (selectedEpicIds.find((item) => item.id === task.id)) {

		setSelectedEpicIds((currSelected) =>
			checked ? [...selectedEpicIds, task] : currSelected.filter((item) => item.id !== task.id)
		)
	}

	const refetchList = async () => {
		if (total === curTasks.length) return null
		if (fetchLoading) return null

		setFetchLoading(true)
		serPerPage((prev) => prev + initialPerPage)
		refetch()
	}

	useEffect(() => {
		if (fetching) {
			refetchList()
		}
	}, [fetching])

	useEffect(() => {
		if (tasks?.data) {
			setTotal(tasks?.meta?.total_entries)
			setFetchLoading(false)
			setFetching(false)
		}
	}, [tasks, isLoading])

	const scrollHandler = (e) => {
		const scrollHeight = e.target.scrollHeight
		const scrollTop = e.target.scrollTop
		const innerHeight = window.innerHeight
		const left = scrollHeight - 5
		const right = scrollTop + innerHeight

		if (left < right) {
			setFetching(true)
		}
	}

	useEffect(() => {
		const b = document?.querySelector('.dialigs-epics-lists-class')
		b?.addEventListener('scroll', scrollHandler)
		return function () {
			b?.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	if (!epic) return null

	return (
		<form
			id="dialigs-epics-lists"
			onSubmit={handleSubmit(createHandler)}
			className="tt-overflow-hidden tt-flex tt-flex-col"
		>
			<DialogHeader title={t('tasks')} />
			<DialogMain className="dialigs-epics-lists-class">
				{isLoading ? (
					<div className="tt-w-full tt-flex-col tt-flex tt-gap-4 tt-border tt-border-gray-200 tt-rounded-lg tt-overflow-hidden">
						{Array(5)
							.fill()
							.map((item, index) => (
								<Skeleton key={index} className="tt-w-full" sx={{ bgcolor: 'gray.50' }} height={44} />
							))}
					</div>
				) : (
					<>
						<table className="w-full">
							{!isProjectTemplateTask && (
								<TaskFilters
									hideSkelet={{
										right: true,
										tags: true,
										author: true,
										project: true,
										deadline: true,
										priority: true,
									}}
									left={<div className="tt-w-20 tt-min-w-20"></div>}
									prefix={filter_prefix}
								/>
							)}
							{curTasks?.map((item) => (
								<TaskRow
									linkDisabled
									left={
										<label className="tt-h-20 tt-w-20 tt-text-gray-500 hover:tt-text-primary tt-flex tt-items-center tt-justify-center">
											<Checkbox
												disabled={item?.id === epic?.id}
												checked={selectedEpicIds.some((select) => select.id === item.id)}
												onChange={(ev) => {
													selectedEpicHandler(item, ev.target.checked)
												}}
											/>
										</label>
									}
									hideSkelet={{
										right: true,
										author: true,
										tags: true,
										deadline: true,
										priority: true,
										project: true,
									}}
									activeClassName="tt-bg-white"
									takeStatusesFromRedux
									projectId={projectId}
									key={item.id}
									data={item}
								/>
							))}
						</table>
						{/* <div className="flex flex-col mb-10 mt-10">
							{tasks?.data?.map((item) => (
								<>
									<div className="flex relative pl-48">
										<div className="absolute left-0 top-0">
											<Checkbox disabled={item?.id === task?.id} checked={Boolean(selectedEpicIds.find((se) => se === item.id))} onChange={() => selectedEpicHandler(item.id)} />
										</div>
										<TaskRow linkDisabled activeClassName="bg-white" takeStatusesFromRedux projectId={projectId} key={item.id} className="flex-1 w-full" data={item} />
									</div>
								</>
							))}
						</div> */}
					</>
				)}
			</DialogMain>
			<DialogFooter rightLabel={t('save')} />
		</form>
	)
}

export default Epics
