import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	meetings: [],
	meeting: null,
}

const reducers = {
	getMeetings: (state, action) => {
		state.meetings = action.payload
	},
	getOneMeeting: (state, action) => {
		state.meeting = action.payload
	},

	addMeeting: (state, action) => {
		state.meetings = [action.payload, ...state.meetings]
	},
}

const meetingsSlice = createSlice({
	name: 'meetings',
	initialState,
	reducers,
})

export const getMeetings = (state) => state.meetings.meetings
export const getOneMeeting = (state) => state.meetings.meeting

export const meetingsReducer = meetingsSlice.reducer
export const meetingsActions = meetingsSlice.actions
