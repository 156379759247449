import { getPrioritys } from 'src/old-app/store/projectsSlice'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import MySelect from '../ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'

const usersLabelRender = (value) => value?.name

const ProjectPrioritySelect = memo(({ id, ...props }) => {
	const prioritys = useSelector(getPrioritys)

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'priority', type: 'select' })}
				menuItemRander={usersLabelRender}
				labelRender={usersLabelRender}
				items={prioritys}
				{...props}
			/>
		</>
	)
})

export default ProjectPrioritySelect
