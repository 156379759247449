const { dialogActions } = require('src/old-app/store/dialogSlice')
const { showMessage } = require('src/old-app/store/fuse/messageSlice')
const { interfaceActions } = require('src/old-app/store/interfaceSlice')
const { exportFile } = require('../service/File')
const { default: download } = require('./download')

const exportKPGHandler = async ({ url, params, name, dispatch }) => {
	try {
		dispatch(interfaceActions.setIsLoading(true))
		const type = 'xlsx'
		const myParams = { ...params }

		const exportData = await exportFile({ url, params: myParams })

		const contentType = exportData.headers['content-type']
		const fileName = `${name}.${type}`

		download(exportData.data, contentType, fileName)
		dispatch(interfaceActions?.setIsLoading(false))
		dispatch(dialogActions.setDialog(false))
	} catch (error) {
		dispatch(interfaceActions?.setIsLoading(false))

		dispatch(
			showMessage({
				message: error?.messsage ?? 'Ошибка',
				autoHideDuration: 500,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'error',
			})
		)
	}
}
export default exportKPGHandler
