import { useEffect, useLayoutEffect, useState } from 'react'

import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-AU'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import 'moment/locale/ru'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getUser } from 'src/old-app/store/userSlice'
import { useDispatch } from 'react-redux'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAppLanguage } from 'src/old-app/store/interfaceSlice'
import { useUpdateUserLanguageMutation } from 'src/old-app/service/Users'
import { LoadPage } from 'src/shared/components'
import { setFuseAppLanguage } from 'src/old-app/store/fuse/settingsSlice'

const i18nInitSettings = {
	debug: false,
	fallbackLng: ['en', 'ru'],
	detection: {
		order: ['queryString', 'cookie'],
		cache: ['cookie'],
	},
	interpolation: {
		escapeValue: false,
	},
}

const TranslationProvider = ({ children }) => {
	const [i18nInitialised, seti18nInitialised] = useState(false)
	const user = useSelector(getUser)
	const appLanguage = useSelector(getAppLanguage)

	const dispatch = useDispatch()
	const [updateUserLanguage] = useUpdateUserLanguageMutation()

	useEffect(() => {
		if (i18nInitialised) return
		i18n
			.use(Backend)
			.use(LanguageDetector)
			.use(initReactI18next)
			.init(i18nInitSettings, (error) => {
				if (!error) {
					seti18nInitialised(true)
				}
			})
	}, [i18nInitialised])

	useEffect(() => {
		if (!user?.language) {
			let localLanguage = ''
			const browserLanguage = window.navigator.language.toLowerCase()
			if (browserLanguage.includes('ru')) {
				localLanguage = 'ru'
			} else {
				localLanguage = 'en'
			}
			dispatch(interfaceActions.setAppLanguage(localLanguage))
		} else {
			dispatch(interfaceActions.setAppLanguage(user?.language?.toLowerCase()))
		}
	}, [user])

	useLayoutEffect(() => {
		if (!i18nInitialised || !appLanguage) return
		i18n.changeLanguage(appLanguage)
		moment.locale(appLanguage)
		if (user) {
			updateUserLanguage(appLanguage.toUpperCase())
		}
		dispatch(setFuseAppLanguage(appLanguage))
	}, [appLanguage, i18nInitialised])

	return i18nInitialised ? (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLanguage === 'ru' ? ru : en}>
			{children}
		</LocalizationProvider>
	) : (
		<LoadPage />
	)
}

export default TranslationProvider
