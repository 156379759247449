import React, { useMemo } from 'react'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'

import SharedTable, { SharedTableRows } from '../../../shared/components/table/SharedTable'
import DateField from '../../../old-app/components/shared/table/DateField'
import useNewSortTable from '../../../old-app/hooks/useNewSortTable'
import { StringParam } from 'use-query-params'
import UserShow from '../../../shared/components/user-show'
import { MyInputFind } from '../../../shared/components/input/MyInputFind'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import OneProjectTemplateSideBar from '../../../old-app/components/project/OneProjectTemplateSideBar'
import clsx from 'clsx'
import { useGetListProjectsTemplatesQuery } from 'src/entities/projects-templates/api/projects-templates-api'
import { getID } from '../../../shared/config/fields-ids'
import { ProjectTemplatesTable } from '../../../entities/projects-templates/components'
import { useTranslation } from 'react-i18next'

const commonID = getID({ entity: 'project_template' })

const ProjectTemplateListPage = () => {
	const { t } = useTranslation()

	const { templateID } = useParams()
	// const { getQuery, setQuery, query } = useNewSortTable({
	// 	extraParams: { name: StringParam },
	// });

	// const { data: listTemplates, ...listProjectsTemplatesQuery } = useGetListProjectsTemplatesQuery(
	// 	{ name: query.name, created_at_sort: 'desc', ...getQuery },
	// 	{ refetchOnWindowFocus: true, refetchOnMountOrArgChange: 10 },
	// );

	// function onFindChange({ target: { value } }) {
	// 	setQuery({ name: value, page: 1 }, 'replaceIn');
	// }

	const { search } = useLocation()
	const navigate = useNavigate()

	// const { rowClassName, rowOnClick } = useMemo(
	// 	() => ({
	// 		rowClassName: (template) => clsx('cursor-pointer truncate', { 'bg-white': template.id === templateID }),
	// 		rowOnClick: (template) => {
	// 			navigate({
	// 				pathname: `/projects-templates${template.id === templateID ? '' : `/${template.id}`}`,
	// 				search,
	// 			});
	// 		},
	// 	}),
	// 	[templateID, search],
	// );

	return (
		<PageWithSideBar
			id={commonID}
			param="templateID"
			pageLink="projects-templates"
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			// headerCreateComponent="project-create"
			headerTitle={t('project_templates')}
			hideCreateButton
			content={<ProjectTemplatesTable />}
			rightSidebarContent={
				<div className="tt-w-full tt-flex-1 tt-flex tt-flex-col">{templateID && <OneProjectTemplateSideBar />}</div>
			}
		/>
	)
}

export default ProjectTemplateListPage
