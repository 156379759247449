import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import DateCell from '../tables/DateCell'
import { getID } from '../../../shared/config/fields-ids'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'
import EmptyPlaceholder from 'src/shared/components/empty-placeholder'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Tooltip } from '@mui/material'

const BodyTableCell = ({ children, className, align = 'left', sx, onClick, id }) => {
	return (
		<TableCell id={id} onClick={onClick} sx={sx} className={['tt-py-8 tt-px-16', className].join(' ')} align={align}>
			{children}
		</TableCell>
	)
}

function FilesTable({ files, hideFileDelete, deleteFile, id, hideEmptyPlaceholder = false }) {
	const { t } = useTranslation()
	// const dispatch = useDispatch()
	// const task = useSelector(getTask)

	if (files?.length === 0) {
		if (hideEmptyPlaceholder) {
			return null
		} else {
			return <EmptyPlaceholder text={t('empty_files_list')} />
		}
	}

	return (
		<TableContainer>
			<Table>
				<TableBody>
					{files.map((row, index) => (
						<TableRow key={row?.id} className="border-b-my">
							<BodyTableCell className="tt-text-primary tt-max-w-128 tt-w-full tt-truncate">
								<a id={getID({ prefix: id, field: 'files_item', type: 'link', index })} href={row?.link}>
									{row?.original_name}
								</a>
							</BodyTableCell>

							<BodyTableCell className="tt-whitespace-nowrap tt-w-224">
								<DateCell date={row?.created_at} />
							</BodyTableCell>

							<BodyTableCell className="tt-w-56">
								<a id={getID({ prefix: id, type: 'link_icon', field: 'files_item', index })} href={row?.link}>
									<Tooltip title={t('download_file')} placement="top">
										<div className="tt-flex tt-h-28 tt-w-28 tt-rounded-full tt-bg-white tt-text-gray-700 tt-items-center tt-justify-center tt-cursor-pointer hover:tt-bg-gray-100 tt-transition-colors">
											<FileDownloadOutlinedIcon fontSize="small" />
										</div>
									</Tooltip>
								</a>
							</BodyTableCell>
							{!hideFileDelete && (
								<>
									<ConfirmDelete
										text={t('delete_file_dialog.are_you_shure')}
										onSuccess={() => deleteFile(row?.id)}
										title={t('delete_file_dialog.header')}
									>
										<BodyTableCell
											id={getID({ prefix: id, type: 'button_delete', field: 'files_item', index })}
											className="tt-w-56"
										>
											<Tooltip title={t('delete')} placement="top">
												<div className="tt-flex tt-h-28 tt-w-28 tt-rounded-full tt-bg-white tt-text-gray-700 tt-items-center tt-justify-center tt-cursor-pointer hover:tt-bg-gray-100 tt-transition-colors">
													<DeleteOutlinedIcon fontSize="small" />
												</div>
											</Tooltip>
										</BodyTableCell>
									</ConfirmDelete>
								</>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default FilesTable
