import React, { useEffect } from 'react'
import DialogFooter from 'src/old-app/components/dialogs/DialogFooter'
import DialogHeader from 'src/old-app/components/dialogs/DialogHeader'
import DialogMain from 'src/old-app/components/dialogs/DialogMain'
import { TaskContext, TaskСonfirmatoryDeadline } from 'src/entities/task'
import { useSelector } from 'react-redux'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import TaskDeadlines from 'src/old-app/components/task/TaskDeadlines'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useDispatch } from 'react-redux'
import { useGetOneTaskQuery } from 'src/old-app/service/Tasks'
import { useGetUser } from 'src/shared/hooks'
import { getProjectUserRole } from 'src/old-app/store/projectsSlice'

export const TaskDeadlineConfirmation = () => {
	const taskSelect = useSelector(getTask)
	const user = useGetUser()
	const isUser = useSelector(getProjectUserRole)

	const {
		data: taskData,
		isLoading,
		isFetching,
	} = useGetOneTaskQuery(taskSelect.id, {
		skip: !taskSelect.id,
	})

	const dispatch = useDispatch()

	const onSubmit = (event: any) => {
		event.preventDefault()
		dispatch(dialogActions.setDialog(false))
	}

	useEffect(() => {
		if (!isLoading && taskData?.data) {
			const isMyTask = taskData?.data?.assignee?.id === user?.id || taskData?.data?.author?.id === user?.id || !isUser

			const tasks = taskData?.data

			dispatch(
				tasksActions.getTask({
					...tasks,
					isMyTask,
					project: { ...tasks?.project, isEnd: tasks?.project?.confirmation_status === 'COMPLETED' },
				})
			)
		}
	}, [isLoading, taskData])

	const task = taskSelect

	return (
		<TaskContext.Provider value={{ task, baseUrl: '', page: '' }}>
			<form onSubmit={onSubmit} className="tt-w-[800px] tt-h-full  tt-relative tt-overflow-x-hidden">
				<DialogHeader title="Примите решение по переносу дедлайна" back={undefined} backHandler={undefined} />
				<DialogMain className={undefined}>
					<div className="tt-pb-12 tt-border-b tt-text-14 tt-border-gray-200 tt-flex tt-flex-col tt-mb-12">
						<div className="tt-font-bold tt-pb-8">История дедлайна</div>
						<TaskDeadlines deadlines={task?.deadlines ?? []} />
					</div>
					<TaskСonfirmatoryDeadline />
				</DialogMain>
				<DialogFooter isDisabled={undefined} left={undefined} id={undefined} rightLabel="Готово" />
			</form>
		</TaskContext.Provider>
	)
}
