import { protocolActions } from 'src/old-app/store/protocolSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { usersActions } from 'src/old-app/store/userSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetProtocolsPlaceQuery } from '../service/Protocol'
import { useGetTaskTypesQuery } from '../service/Tasks'
import { useGetOneUserQuery } from '../service/Users'
import { useGetUser } from 'src/shared/hooks'
import { LoadPage } from 'src/shared/components'

const DataProviders = ({ children, skips }) => {
	const dispatch = useDispatch()
	const user = useGetUser()

	const { data: taskTypes } = useGetTaskTypesQuery({}, { skip: skips?.task_types })
	const { data: protocalPlaces } = useGetProtocolsPlaceQuery({}, { skip: skips?.protocol_place })
	const { data: userData } = useGetOneUserQuery(user?.id, { skip: skips?.user || !user?.id })

	useEffect(() => {
		if (taskTypes) {
			dispatch(tasksActions.getTaskTypes(taskTypes.data))
		}
		if (protocalPlaces) {
			dispatch(protocolActions.getPlaces(protocalPlaces.data))
		}

		if (userData?.data) {
			dispatch(usersActions.getUser(userData.data))
		}
	}, [taskTypes, protocalPlaces, userData])

	return !userData?.data && !skips?.user ? <LoadPage /> : children
}

export default DataProviders
