import React, { useMemo, useState } from 'react'

import TextFilter from '../../../../shared/components/filters/text-filter'
import UserFilter from '../../../../shared/components/filters/user-filter'
import UnTable from '../../../../shared/components/un-table'
import { CellEmpty, UserShow } from '../../../../shared/components'
import { Chip, Tooltip } from '@mui/material'
import { useProjectRequests } from 'src/entities/project'
import TagsView from '../../../../old-app/components/ui/TagsView'
import ProjectTagsFilter from '../../../../shared/components/filters/project-tags-filter'
import moment from 'moment/moment'
import DateFilter from '../../../../shared/components/filters/date-filter'
import TaskMoreFilters from '../../../../old-app/components/task/TaskMoreFilters'
import ProjectRequestButton from '../project-request-button'
import { useLocation } from 'react-router-dom'
import ProjectTypesFilter from 'src/shared/components/filters/project-type-filter'
import { useTranslation } from 'react-i18next'

interface IProps {
	params?: any
	curPage?: string
}

const defaultPerPage = 100

const ProjectRequestsTable: React.FC<IProps> = ({ params, curPage }) => {
	const { t } = useTranslation()

	const columns = useMemo(() => {
		return [
			{
				id: 1,
				label: 'Код проекта',
				field: 'slug',
				className: 'tt-w-64 tt-min-w-64',
				render: (listItem: any) => (
					<Chip
						label={listItem?.slug}
						className={`tt-bg-gray-200 tt-text-gray-700 tt-font-medium tt-truncate `}
						size="small"
					/>
				),
			},
			{
				id: 2,
				label: 'Название',
				field: 'name',
				className: 'tt-flex-1 tt-min-w-64',
				render: (listItem: any) => (
					<Tooltip enterDelay={500} arrow title={listItem?.name} placement="top-start">
						<p className="my-truncate-one whitespace-pre-wrap break-all flex-1 min-w-76  text-14 text-left font-medium text-black">
							{listItem?.name}
						</p>
					</Tooltip>
				),
			},
			{
				id: 3,
				label: 'Отвественный',
				field: 'author',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => <>{listItem?.owner && <UserShow user={listItem?.owner} />}</>,
			},
			{
				id: 4,
				label: 'Теги',
				field: 'tags',
				className: 'tt-max-w-160 tt-min-w-160 tt-w-160',
				render: (listItem: any) => (
					<>
						<TagsView isHidePlaceholder={false} tags={listItem?.hashtags ?? []} />
					</>
				),
			},
			{
				id: 5,
				label: 'Дата завершения',
				field: 'complete_at',
				className: 'tt-max-w-160 tt-min-w-160 tt-w-160',
				render: (listItem: any) => (
					<>
						<span className="text-14 font-medium text-black">{moment(listItem?.complete_at).format('ll')}</span>
					</>
				),
			},
			{
				id: 8,
				label: 'Статус',
				field: 'confirmation_status',
				className: 'tt-max-w-120 tt-min-w-120 tt-w-120',
				render: (listItem: any) => (
					<>
						{listItem?.type ? (
							<Chip
								sx={{ height: 24 }}
								className="tt-truncate tt-font-medium tt-inline-flex tt-text-12"
								label={t(`types.${listItem?.type?.name}`)}
							/>
						) : (
							<CellEmpty />
						)}
					</>
				),
			},
			{
				id: 5,
				label: '',
				field: '',
				className: ' tt-max-w-[162px] tt-min-w-[162px] tt-w-[162px]',
				render: (listItem: any) => (
					<>
						<ProjectRequestButton projectId={listItem?.id} />
					</>
				),
			},
		]
	}, [params, curPage])

	const filters = [
		{
			id: 1,
			render: (listItem: any) => (
				<>
					<TextFilter placeholder={t('slug')} field="slug" />
				</>
			),
		},
		{
			id: 2,
			render: (listItem: any) => (
				<>
					<TextFilter placeholder={t('title')} />
				</>
			),
		},
		{
			id: 3,
			render: (listItem: any) => (
				<>
					<UserFilter isAll placeholder={t('author')} field="author" />
				</>
			),
		},
		{
			id: 4,
			render: (listItem: any) => (
				<>
					<ProjectTagsFilter placeholder={t('tags')} />
				</>
			),
		},
		{
			id: 5,
			render: (listItem: any) => (
				<>
					<DateFilter placeholder={t('completion_date')} field="complete_at" />
				</>
			),
		},
		{
			id: 7,
			render: (listItem: any) => (
				<>
					<ProjectTypesFilter placeholder={t('type')} />
				</>
			),
		},
		{
			id: 5,
			render: (listItem: any) => (
				<>
					<TaskMoreFilters
						prefix={''}
						hideTaskParamsFilter
						hideSkelet={{ deadline_sort: true }}
						isLabel
						allProjectsMode={true}
						projectId={undefined}
						id={undefined}
					/>
				</>
			),
		},
	]

	const heads = columns.map((column, index) => {
		return { ...column, render: filters[index]?.render }
	})

	const { search } = useLocation()
	const [perPage, setPerPage] = useState(defaultPerPage)
	const { projects, isLoading, meta, isFetching } = useProjectRequests({
		params: { per_page: perPage, page: 1, ...params },
	})
	const page = '/project-requests'

	const link = (id: Id) => (curPage ? `${curPage}/${id}${search}` : `${page}/show/${id}${search}`)

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	return (
		<div className="tt-flex tt-flex-col tt-overflow-auto table-scroll">
			<UnTable
				isFetching={isFetching}
				loadMore={loadMore}
				meta={meta}
				isLoading={isLoading}
				emptyPlaceholder={t('empty_projects_confirmation_list')}
				link={link}
				heads={heads}
				list={projects}
				columns={columns}
			/>
		</div>
	)
}

export default ProjectRequestsTable
