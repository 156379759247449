import React, { memo } from 'react'
import { useGetProtocolAssigneeQuery } from 'src/old-app/service/Protocol'
import UserCell from '../../tables/UserCell'

const ProtocolAssignee = memo(({ taskId }) => {
	const { data } = useGetProtocolAssigneeQuery({ task_id: taskId })
	return (
		<div className="tt-flex tt-flex-col tt-gap-4">
			{data?.data?.map((item, index) => (
				<UserCell className="tt-italic tt-text-gray-800" key={item.id} id={item.user_id} />
			))}
		</div>
	)
})

ProtocolAssignee.displayName = 'ProtocolAssignee'
export default ProtocolAssignee
