import { InputBase } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetProjectsQuery } from 'src/old-app/service/Projects'
import { useGetSearchUserQuery } from 'src/old-app/service/Users'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'

const labelRender = (value) => value?.name

const RoleSelect = memo((props) => {
	const [search, setSearch] = useState('')
	// const { data: projectsData, isLoading, refetch } = useGetProjectsQuery({ name: search, current_page: 1, per_page: 999 })
	// const debouncedSearch = useDebounce(search, 2000)

	// useEffect(() => {
	// 	if (debouncedSearch) {
	// 		refetch()
	// 	}
	// }, [debouncedSearch])

	// if (!projectsData?.data) return null
	// const projects = projectsData?.data ?? []

	return (
		<>
			<MySelect
				label="Роль"
				menuItemRander={labelRender}
				labelRender={labelRender}
				items={[
					{ id: '232', name: 'ADMIN' },
					{ id: '32323', name: 'BA' },
					{ id: '123', name: 'USER' },
				]}
				isSearch
				setSearch={setSearch}
				search={search}
				{...props}
			/>
		</>
	)
})

export default RoleSelect
