// {param: [1,2]} => 'param=1&param=2'
export const serializeParams = ({ params = {}, arraysParams = {} }) => {
	const definedParams = Object.entries(params).filter(([, value]) => value)
	const _params = new URLSearchParams(definedParams)

	Object.entries(arraysParams).forEach(([param, values]) => {
		values?.forEach((value) => {
			_params.append(param, value)
		})
	})

	return _params.toString()
}
