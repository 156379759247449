/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useCheckProjectMutation,
	useCreateProjectHashtagLinkMutation,
	useCreateProjectMutation,
	useCreateUserProjectMutation,
} from 'src/old-app/service/Projects'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import ErrorText from '../../ui/ErrorText'
import UsersSelect from '../../shared/UserSelect'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import moment from 'moment'
import getSlug from 'src/old-app/utils/getSlug'
import ProjectHashtagSelect from '../../shared/ProjectHashtagSelect'
import { getProject } from 'src/old-app/store/projectsSlice'
import {
	useCreateTeamHashtagLinkMutation,
	useCreateTeamsMutation,
	useCreateTeamUsersMutation,
} from 'src/old-app/service/Teams'
import { defaultTableRow as user, InputContainer, UsersChoice } from '../../../../shared/components'
import TeamTagsChoice from '../../../../shared/components/team-tags-choice'
import { useGetUser } from '../../../../shared/hooks'
import { useTranslation } from 'react-i18next'

const CreateTeam = () => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		name: yup.string().required(t('team_title_is_required')),
	})

	const dispatch = useDispatch()
	const isAdmin = useSelector(getAdminRole)
	const curUser = useGetUser()

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		setError,
	} = useForm({
		defaultValues: {
			users: [],
			hashtags: [],
			name: '',
		},
		resolver: yupResolver(schema),
	})

	const [createTeam, result] = useCreateTeamsMutation()
	// const [checkProject] = useCheckProjectMutation()
	const [createTeamHashtagLink] = useCreateTeamHashtagLinkMutation()
	const [createTeamsUser, userResult] = useCreateTeamUsersMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					// message: 'Проект создан',
					message: t('successfully_created.team'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		}
		if (userResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: userResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [result, userResult])

	const isValidation = (data) => {
		// const today = moment().format()
		// if (moment(data.complete_at).isBefore(today)) {
		// 	setError('complete_at', { message: 'Нельзя выбрать дату завершения проекта меньше сегодняшней' })
		// 	return true
		// }

		return false
	}

	const createTeamsHandler = async (data) => {
		try {
			const isValidate = isValidation(data)
			if (isValidate) return

			const teamUsers = data.users.map((teamUser) => {
				return teamUser?.id
			})

			dispatch(interfaceActions.setIsLoading(true))
			setIsDisabled(true)
			const body = {
				name: data.name,
				//admin: curUser?.id,
				command_users: teamUsers,
				// slug: data.slug,
			}

			// const checkProjectData = await checkProject({ slug: body.slug })

			// if (checkProjectData.data.data) {
			// 	dispatch(
			// 		showMessage({
			// 			message: 'Проект с таким названием уже существует',
			// 			autoHideDuration: 2000,
			// 			anchorOrigin: {
			// 				vertical: 'top',
			// 				horizontal: 'top-right',
			// 			},
			// 			variant: 'warning',
			// 		})
			// 	)
			// 	dispatch(interfaceActions.setIsLoading(false))
			// 	setIsDisabled(false)
			// 	return
			// }

			const teamData = await createTeam(body)
			const team = teamData?.data?.data ?? teamData?.data

			data.hashtags.forEach(async (hashtag) => {
				await createTeamHashtagLink({ id: team.id, hashtagId: hashtag.id })
			})

			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.team'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const titleChangeHandler = (event, onChange) => {
		const slug = getSlug(event.target.value)
		setValue('slug', slug)
		onChange(event)
	}

	const slugChangeHandler = (event, onChange) => {
		const { value } = event.target
		onChange(value.trim(' '))
	}

	return (
		<form onSubmit={handleSubmit(createTeamsHandler)} className="tt-w-600">
			<DialogHeader title={t('new.team')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									onChange={(event) => titleChangeHandler(event, onChange)}
									error={!!errors.name}
									hiddenLabel
									placeholder={t('title')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="name"
							control={control}
						/>

						<ErrorText errors={errors} field="name" />
					</div>

					<InputContainer label={t('participants')} error={errors?.users}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<UsersChoice placeholder={t('participants')} value={value} onChange={onChange} />
							)}
							name="users"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('tags')} error={errors?.hashtags}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TeamTagsChoice placeholder={t('tags')} value={value} onChange={onChange} />
							)}
							name="hashtags"
							control={control}
						/>
					</InputContainer>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} />
		</form>
	)
}

export default CreateTeam
