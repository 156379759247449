import React from 'react'
import styles from './styles.module.scss'

interface IProps {
	src?: string | null
	alt: string
}

const Avatar: React.FC<IProps> = (p) => {
	const { src, alt } = p

	return (
		<div className={styles['avatar']}>
			{src ? (
				<img className={styles['avatar-img']} alt={alt} src={src} draggable={false} />
			) : (
				<div className={styles['avatar-placeholder']}>{alt}</div>
			)}
		</div>
	)
}

export default Avatar
