import { Controller, useForm } from 'react-hook-form'

import { darken, lighten } from '@mui/material/styles'

import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import _ from 'src/@lodash'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import { useCreateStatusTaskMutation } from 'src/old-app/service/Tasks'
import { useParams } from 'react-router-dom'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import getColor from 'src/old-app/utils/getColor'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
import { useProjectShow } from 'src/entities/project'
// import { newList } from '../../store/listsSlice';

const defaultValues = {
	title: '',
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	title: yup.string().required('You must enter a title'),
})

function BoardAddList({ disabled }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const projectShow = useProjectShow()
	const { projectId } = useParams()
	const [createStatusTask] = useCreateStatusTaskMutation()
	const [formOpen, setFormOpen] = useState(false)
	const { reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	})

	const isCommittee = projectShow?.isCommittee

	useEffect(() => {
		if (!formOpen) {
			reset(defaultValues)
		}
	}, [formOpen, reset])

	function handleOpenForm(ev) {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('create-status'))
	}

	function handleCloseForm() {
		setFormOpen(false)
	}

	const onSubmit = async (data) => {
		const color = getColor()
		createStatusTask({ project_id: projectId, status: data.title, color }).then((createData) => {
			if (createData.data) {
				dispatch(tasksActions.addStatuses(createData.data.data))
				dispatch(scrumboardActions.createOneList({ id: createData.data.data.id, data: createData.data.data }))
				handleCloseForm()
			}
		})
	}

	return (
		<div>
			<div className="tt-w-256 sm:tt-w-320 bg-tt-gray-100 shadow-none tt-max-h-full tt-flex tt-flex-col tt-rounded-xl tt-border">
				<CommentedDisability
					optionDisabled={isCommittee}
					disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
				>
					<Button
						disabled={disabled}
						onClick={handleOpenForm}
						classes={{
							root: 'tt-font-medium tt-w-full tt-rounded-lg tt-p-24 tt-justify-start',
						}}
						startIcon={<FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>}
						sx={{ color: 'text.secondary' }}
					>
						{t('add_column')}
					</Button>
				</CommentedDisability>
			</div>
		</div>
	)
}

export default BoardAddList
