import React from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Button } from '@mui/material'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useDispatch } from 'react-redux'
import { notifsActions } from 'src/old-app/store/notifSlice'
import { requestsActions } from 'src/old-app/store/requestSlice'

const RequestCancel = ({ label, request, type, id }) => {
	const dispatch = useDispatch()

	const cancelHandler = () => {
		dispatch(requestsActions.getRequest({ type, id }))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('cancel-request'))
	}
	return (
		<div>
			<Button
				onClick={cancelHandler}
				className="tt-bg-primary-transparent tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-red tt-min-h-20 tt-h-20"
			>
				<CancelOutlinedIcon className="tt-w-20 tt-h-20" />
				{label}
			</Button>
		</div>
	)
}

export default RequestCancel
