import { lazy } from 'react'
const My = lazy(() => import('./My'))

const MyConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'my',
			element: <My />,
			children: [
				{
					path: ':projectId/:taskId',
					element: <My />,
				},
			],
		},
	],
}

export default MyConfig
