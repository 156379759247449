import styles from './Gallery.module.css'
import { useState, useRef, useEffect } from 'react'
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined'
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined'
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined'
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined'
import { CircularProgress, Typography } from '@mui/material'

const Gallery = ({ source, itemToShow }) => {
	const totalItems = source.length

	const videoPlayerRef = useRef(null)

	const [isLoading, setIsLoading] = useState(true)
	const [isMuted, setIsMuted] = useState(true)
	const [isPlaying, setIsPlaying] = useState(false)
	const [currentItem, setCurrentItem] = useState(itemToShow)
	const [videoPlayingProblems, setVideoPlayingProblems] = useState(false)

	const [awaitingCount, setAwaitingCount] = useState(0)

	const { mediaType, url, title } = source[currentItem]

	useEffect(() => {
		document.addEventListener('keydown', handleKeyboardActions)

		return () => {
			document.removeEventListener('keydown', handleKeyboardActions)
		}
	})

	useEffect(() => {
		if (mediaType == 'video' && !videoPlayingProblems) {
			if (isPlaying) {
				videoPlayerRef.current.play()
			} else {
				videoPlayerRef.current.pause()
			}
		}
	}, [isPlaying])

	useEffect(() => {
		if (awaitingCount > 10) {
			setVideoPlayingProblems(true)
			setIsPlaying(false)
		}
	}, [awaitingCount])

	const handleKeyboardActions = (event) => {
		const { keyCode } = event
		if (keyCode == 37) {
			showPreviousItem()
		}
		if (keyCode == 39) {
			showNextItem()
		}
	}

	const showNextItem = () => {
		if (totalItems < 2) return
		setIsLoading(true)
		if (currentItem < totalItems - 1) {
			setCurrentItem((prev) => (prev += 1))
		} else {
			setCurrentItem(0)
		}
		setIsPlaying(false)
		setVideoPlayingProblems(false)
		setAwaitingCount(0)
	}

	const showPreviousItem = () => {
		if (totalItems < 2) return
		setIsLoading(true)
		if (currentItem > 0) {
			setCurrentItem((prev) => (prev -= 1))
		} else {
			setCurrentItem(totalItems - 1)
		}
		setIsPlaying(false)
		setVideoPlayingProblems(false)
		setAwaitingCount(0)
	}

	const playSource = () => {
		setIsLoading(false)
		setVideoPlayingProblems(false)
		setIsPlaying(true)
	}

	const handleVideoError = () => {
		setIsLoading(false)
		setVideoPlayingProblems(true)
		setIsPlaying(false)
	}

	return (
		<div className={styles.Gallery} onClick={(event) => event.stopPropagation()}>
			<div className={styles.Gallery__itemTitle}>
				<Typography className="tt-text-white">{title}</Typography>
			</div>
			{isLoading && (
				<div style={{ position: 'absolute' }}>
					<CircularProgress className="tt-text-white" />
				</div>
			)}
			{mediaType == 'image' && (
				<img
					style={{ visibility: `${isLoading ? 'hidden' : 'visible'}` }}
					src={url}
					data
					onLoad={() => setIsLoading(false)}
				/>
			)}
			{mediaType == 'video' && !videoPlayingProblems && (
				<video
					style={{ visibility: `${isLoading ? 'hidden' : 'visible'}` }}
					ref={videoPlayerRef}
					onCanPlayThrough={() => playSource()}
					onWaiting={() => {
						setAwaitingCount(awaitingCount + 1)
					}}
					onError={() => handleVideoError()}
					muted={isMuted}
					loop
					disablePictureInPicture
					src={url}
				/>
			)}

			{mediaType == 'video' && videoPlayingProblems && (
				<Typography className="tt-text-white">
					Возникли проблемы при воспроизведении видео. Пожалуйста,{' '}
					<a href={url} className="tt-text-blue-400">
						скачайте
					</a>{' '}
					его и откройте на своем устройстве.
				</Typography>
			)}

			<div className={styles.Gallery__controlPanel}>
				{mediaType == 'video' && !videoPlayingProblems && (
					<button className={styles.Gallery__controlButton} onClick={() => setIsPlaying(!isPlaying)}>
						{isPlaying ? <PauseOutlinedIcon /> : <PlayArrowOutlinedIcon />}
					</button>
				)}
				{totalItems > 1 && (
					<button className={styles.Gallery__controlButton} onClick={() => showPreviousItem()}>
						<NavigateBeforeOutlinedIcon />
					</button>
				)}
				{totalItems > 1 && (
					<div className={styles.Gallery__itemIndex}>
						<Typography className="tt-text-white">{`${currentItem + 1}/${totalItems}`}</Typography>
					</div>
				)}
				<a href={url}>
					<button className={styles.Gallery__controlButton}>
						<DownloadOutlinedIcon />
					</button>
				</a>
				{totalItems > 1 && (
					<button className={styles.Gallery__controlButton} onClick={() => showNextItem()}>
						<NavigateNextOutlinedIcon />
					</button>
				)}
				{mediaType == 'video' && !videoPlayingProblems && (
					<button className={styles.Gallery__controlButton} onClick={() => setIsMuted(!isMuted)}>
						{isMuted ? <VolumeOffOutlinedIcon /> : <VolumeMuteOutlinedIcon />}
					</button>
				)}
			</div>
		</div>
	)
}

export default Gallery
