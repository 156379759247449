import React from 'react'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import moment from 'moment'

const DateCell = ({ date, className, icon }) => {
	return (
		<div className={['tt-flex tt-gap-5 tt-items-center', className].join(' ')}>
			{icon ? icon : <CalendarMonthOutlinedIcon className="tt-h-20 tt-fill-gray-500" />}

			{moment(date).format('LL')}
		</div>
	)
}

export default DateCell
