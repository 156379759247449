import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import ProtocolSideBar from './ProtocolSideBar'

const ProtocolSideBarCheck = memo(({ children }) => {
	const { protocolId } = useParams()
	return <div className="tt-w-full tt-flex-1 tt-flex tt-flex-col "> {protocolId && children}</div>
})

ProtocolSideBarCheck.displayName = 'ProtocolSideBarCheck'
export default ProtocolSideBarCheck
