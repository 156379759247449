import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const meetingAPI = createApi({
	reducerPath: 'meeting-api',
	tagTypes: ['Meeting'],
	baseQuery,

	endpoints: (build) => ({
		getMeetings: build.query({
			query: (params) => ({
				url: '/meeting/',
				params,
			}),
			providesTags: ['Meeting'],
		}),
		createMeeting: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/meeting/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Project'],
		}),
	}),
})

export const { useGetMeetingsQuery, useCreateMeetingMutation } = meetingAPI
