import {
	NotificationBell,
	NovuProvider,
	PopoverNotificationCenter,
	ScreensEnum,
	useNotifications,
} from '@novu/notification-center'
import { NOVU_BACKEND_URL, NOVU_IDENTIFIER, NOVU_SOCKET_URL, TOKEN_NOVU } from 'src/shared/config'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined'
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { styled } from '@mui/material/styles'
import { useGetUser } from 'src/shared/hooks'
import { useNavigate } from 'react-router-dom'
import { novuStyles, novuStylesFull } from './novu-styles'
import { NovuCustomItem } from './NovuCustomItem'
import { IconButton, Tooltip } from '@mui/material'
import Badge from '@mui/material/Badge'
import { useEffect, useState } from 'react'
import { useReadNovuMutation } from '../../../service/Notif'
import { useTranslation } from 'react-i18next'

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: -22,
		top: 13,
		background: '#0f766e',
		color: '#fff',
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}))

function Novu() {
	const user = useGetUser()
	const subscriberId = user?.id
	const navigate = useNavigate()
	const [isFull, setIsFull] = useState(false)
	const [showUserPreferences, setShowUserPreferences] = useState(false)

	const onNotificationClick = (message) => {
		const url = new URL(message?.cta?.data?.url)?.pathname
		const navigateUrl = url[0] === '/' && url[1] === '/' ? url.slice(1, -1) : url

		if (isFull) {
			window.open(navigateUrl, '_blank')
			return
		}
		navigate(navigateUrl)
	}

	return (
		<NovuProvider
			backendUrl={NOVU_BACKEND_URL}
			socketUrl={NOVU_SOCKET_URL}
			i18n={'ru'}
			styles={isFull ? novuStylesFull : novuStyles}
			subscriberId={subscriberId}
			applicationIdentifier={NOVU_IDENTIFIER}
		>
			<PopoverNotificationCenter
				onNotificationClick={onNotificationClick}
				position="left-start"
				// offset={}
				emptyState={<Empty />}
				listItem={renderItem}
				colorScheme="light"
				header={(p) => {
					return (
						<NovuHeader
							showUserPreferences={showUserPreferences}
							setScreen={p.setScreen}
							setShowUserPreferences={setShowUserPreferences}
							isFull={isFull}
							setIsFull={setIsFull}
						/>
					)
				}}
			>
				{({ unseenCount }) => (
					<div className="tt-w-full tt-flex tt-items-center tt-justify-center">
						<NotificationBell unseenCount={unseenCount} />
					</div>
				)}
			</PopoverNotificationCenter>
		</NovuProvider>
	)
}

const Empty = () => {
	return (
		<div className="tt-w-full tt-h-full tt-flex tt-items-center tt-justify-center tt-text-center tt-p-24">
			<span className="tt-text-gray-500 tt-font-medium">Список уведомлений пуст</span>
		</div>
	)
}

function renderItem(message, onActionButtonClick, onNotificationClick) {
	return (
		<NovuCustomItem
			message={message}
			onActionButtonClick={onActionButtonClick}
			onNotificationClick={onNotificationClick}
		/>
	)
}

export default Novu

const NovuHeader = ({ setIsFull, isFull, setShowUserPreferences, setScreen, showUserPreferences }) => {
	const { t } = useTranslation()
	const n = useNotifications()

	const [readNovu] = useReadNovuMutation()

	const read = () => {
		readNovu({ token: localStorage.getItem(TOKEN_NOVU) })
		n?.markAllNotificationsAsRead()
	}

	const openFullScreen = () => {
		setIsFull((prev) => !prev)
	}

	const openSetting = (open) => {
		setShowUserPreferences(open)
	}

	useEffect(() => {
		if (showUserPreferences) {
			setScreen(ScreensEnum.SETTINGS)
		} else {
			setScreen(ScreensEnum.NOTIFICATIONS)
		}
	}, [showUserPreferences])

	useEffect(() => {
		const root = document.getElementById('track-root')
		if (isFull) {
			root.classList.add('tt-overflow-hidden')
		} else {
			root.classList.remove('tt-overflow-hidden')
		}
	}, [isFull])

	return (
		<div
			className={`tt-rounded-t-[7px] tt-bg-white tt-border-b border-gray-200  tt-border-gray-2   tt-flex tt-items-center tt-justify-between tt-gap-20 ${
				isFull ? 'tt-py-18 tt-px-20' : 'tt-py-12 tt-px-20'
			}`}
		>
			<div className="tt-flex tt-items-center tt-gap-12">
				{showUserPreferences && (
					<IconButton
						id="novu-setting-button-back"
						className="tt-w-25 tt-p-0 tt-h-25"
						onClick={() => openSetting(false)}
						size="small"
					>
						<ArrowBackIcon className="tt-w-20 tt-h-20" />
					</IconButton>
				)}

				<StyledBadge badgeContent={showUserPreferences ? 0 : n.unseenCount}>
					<h3 className="tt-font-bold tt-text-xl">{showUserPreferences ? t('settings') : t('notifications')}</h3>
				</StyledBadge>
			</div>

			<div className="tt-flex tt-items-center tt-gap-10">
				<Tooltip title={isFull ? t('compact_view') : t('full_screen_view')}>
					<IconButton className="tt-w-25 tt-p-0 tt-h-25" id="novu-read-button" onClick={openFullScreen} size="small">
						{isFull ? (
							<FullscreenExitOutlinedIcon className="tt-w-20 tt-h-20" />
						) : (
							<FullscreenOutlinedIcon className="tt-w-20 tt-h-20" />
						)}
					</IconButton>
				</Tooltip>
				{!showUserPreferences && (
					<Tooltip title={t('mark_all_as_readen')}>
						<IconButton className="tt-w-25 tt-p-0 tt-h-25" id="novu-read-button" onClick={read} size="small">
							<MarkEmailReadOutlinedIcon className="tt-w-20 tt-h-20" />
						</IconButton>
					</Tooltip>
				)}
				{!showUserPreferences && (
					<Tooltip title={t('settings')}>
						<IconButton
							id="novu-setting-button"
							className="tt-w-25 tt-p-0 tt-h-25"
							onClick={() => openSetting(true)}
							size="small"
						>
							<SettingsOutlinedIcon className="tt-w-20 tt-h-20" />
						</IconButton>
					</Tooltip>
				)}

				{showUserPreferences && (
					<Tooltip title={t('notifications')}>
						<IconButton
							id="novu-notif-button"
							className="tt-w-25 tt-p-0 tt-h-25"
							onClick={() => openSetting(false)}
							size="small"
						>
							<NotificationsOutlinedIcon className="tt-w-20 tt-h-20" />
						</IconButton>
					</Tooltip>
				)}
			</div>
		</div>
	)
}
