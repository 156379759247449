import { getTask } from 'src/old-app/store/tasksSlice'
import { useSelector } from 'react-redux'
import { useGetCommentsQuery } from 'src/old-app/service/Comment'
import AddComment from './AddComment'
import Comment from './Comment'
import { getID } from '../../../shared/config/fields-ids'
import { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Box, CircularProgress, Divider } from '@mui/material'
import List from '@mui/material/List'
import { CommentCard } from 'src/@entities/task'
import EmptyPlaceholder from 'src/shared/components/empty-placeholder'
import { TASK_UPDATE } from 'src/shared/config'
import { useTranslation } from 'react-i18next'

const CommentsOnePageList = ({ page, setPageCount, pagesCount, id }) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const [inView, setInView] = useState(false)

	const commentsQuery = useGetCommentsQuery(
		{
			params: { current_page: page, per_page: 25 },
			id: task.id,
		},
		{
			pollingInterval: inView ? TASK_UPDATE : undefined,
		}
	)

	if (commentsQuery.isLoading)
		return (
			<Box textAlign={'center'}>
				<CircularProgress sx={{ mx: 'auto' }} size={20} color="secondary" />
			</Box>
		)

	if (commentsQuery?.data?.data?.length === 0 ?? !commentsQuery.isLoading)
		return <EmptyPlaceholder text={t('empty_comments_list')} />

	const totalPages = commentsQuery.data?.meta.total_pages
	const isLastPage = page === totalPages
	const needMorePages = pagesCount < totalPages

	const _id = getID({ prefix: id, field: 'comment' })

	return (
		<InView
			children={({ ref }) => (
				<li ref={ref}>
					{commentsQuery.data?.data.map((item, index) => (
						<CommentCard key={item.id} comment={item} />
					))}

					{needMorePages && (
						<InView
							delay={50}
							skip={pagesCount >= totalPages}
							onChange={(inView) => {
								if (inView && needMorePages) {
									setPageCount(pagesCount + 1)
								}
							}}
						/>
					)}

					{isLastPage && <Divider className={'mt-8'} />}
				</li>
			)}
			onChange={(inView) => {
				setInView(inView)
			}}
		/>
	)
}

const Comments = ({ id }) => {
	const [pagesCount, setPagesCount] = useState(1)
	const task = useSelector(getTask)

	const _id = getID({ prefix: id, field: 'comment' })

	return (
		<div className="tt-w-full">
			{!task?.project?.isEnd && <AddComment id={_id} />}

			<List className="tt-mt-16">
				{Array(pagesCount)
					.fill(null)
					.map((_, _page) => (
						<CommentsOnePageList
							id={`${_id}-${_page}`}
							key={_page}
							page={_page + 1}
							pagesCount={pagesCount}
							setPageCount={setPagesCount}
						/>
					))}
			</List>
		</div>
	)
}

export default Comments
