import { useSelector } from 'react-redux'
import { getUserID } from 'src/old-app/store/userSlice'
import { useGetPriorityRQuery, useGetUserProjectsQuery } from '../../service/Projects'
import React, { useMemo } from 'react'
import MySelect from '../../components/ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'

function groupByProjectName(prioritys, projects) {
	return prioritys?.reduce(
		(acc, onePriority) => {
			const { id, name } = onePriority
			const projectName = projects.find(({ project_id }) => project_id === onePriority.projectID)?.project?.name
			if (!projectName) return acc

			if ([projectName] in acc.byProjectName) {
				acc.byProjectName[projectName].push({ id, name })
			} else {
				acc.byProjectName[projectName] = [{ id, name }]
			}
			return acc
		},
		{ byProjectName: {} }
	)
}

export function UserProjectsPrioritiesSelect({ value, requestProjectID, id, ...props }) {
	const userID = useSelector(getUserID)

	const requestParams = {
		user_id: userID,
		per_page: 100,
	}

	if (requestProjectID && requestProjectID?.length) {
		requestParams.project_id = requestProjectID
	}

	const projectsQuery = useGetUserProjectsQuery(requestParams)

	const projectIds = projectsQuery?.data?.data.map((project) => project.project_id) ?? []

	const prioQuery = useGetPriorityRQuery(
		{
			params: { per_page: 250, project_id: projectIds.join(',') },
			arraysParams: {
				// project_id: projectIds,
			},
		},
		{
			skip: !projectsQuery.isSuccess,
			refetchOnMountOrArgChange: true,
		}
	)

	const foundByIdsPrios = useMemo(() => {
		if (!projectsQuery.isSuccess || !prioQuery.isSuccess) return props.multiple ? [] : ''

		return props.multiple
			? value?.map((valueID) => prioQuery.data?.data.find(({ id }) => id === valueID))
			: prioQuery.data.data.find(({ status }) => status === value)
	}, [value, prioQuery.data])

	const preparedItems = useMemo(() => {
		if (!projectsQuery.isSuccess || !prioQuery.isSuccess) return
		return groupByProjectName(prioQuery.data.data, projectsQuery.data.data)
	}, [prioQuery.data, prioQuery.isSuccess])

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'priority', type: 'select' })}
				menuItemRander={prioLabelRender}
				labelRender={prioLabelRender}
				items={preparedItems?.byProjectName}
				value={foundByIdsPrios}
				withSubHeadersMode
				{...props}
			/>
		</>
	)
}

const prioLabelRender = (value) => value?.name
