import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import TeamSideBar from './TeamSideBar'
import TeamProvider from '../../../entities/team/components/team-provider'

const TeamSideBarCheck = ({ backTo }) => {
	const { teamId } = useParams()

	return (
		<div className="tt-w-full">
			{teamId && (
				<TeamProvider>
					<TeamSideBar backTo={backTo} />
				</TeamProvider>
			)}
		</div>
	)
}

export default TeamSideBarCheck
