import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { useGetOneTaskQuery, useGetStatusQuery } from 'src/old-app/service/Tasks'
//import { useGetTaskFilesQuery } from 'src/old-app/service/TasksFile'
import { getProject, getProjectAdminContractRole, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import ActionButtons from './ActionButtons'
import EditTasks from './EditTask'
import TaskContent from './TaskContent'
import TaskHeader from './TaskHeader'
import TaskMain from './TaskMain'
import { getOneFile } from 'src/old-app/service/File'
import { CircularProgress } from '@mui/material'
import { getIsAutoUpdate } from 'src/old-app/store/interfaceSlice'
import { TASK_UPDATE } from 'src/shared/config'
import { useGetUser } from 'src/shared/hooks'
import { TaskContext, TaskToParent } from 'src/entities/task'
import { TaskSideFooter } from '../../../features/task'
import { getID } from '../../../shared/config/fields-ids'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'

function filterMySubtasks(task, userID) {
	const filteredSubTasks = task.subtasks?.filter((subTask) => subTask.assignee.id === userID)
	return { ...task, subtasks: filteredSubTasks }
}

const GetProjectStatuses = ({ projectId }) => {
	const dispatch = useDispatch()
	const { data: statuses } = useGetStatusQuery({ project_id: projectId, order_sort: 'asc', per_page: 100 }, {})

	useEffect(() => {
		if (statuses) {
			dispatch(tasksActions.getStatuses(statuses?.data))
		}
	}, [statuses])

	return null
}

const TaskSideBar = ({ backTo, hideActionButtons, hideHeader, onlyMyTasks, id, defaultTaskContext }) => {
	const navigate = useNavigate()
	const task = useSelector(getTask)
	const project = useSelector(getProject)
	const isAutoUpdate = useSelector(getIsAutoUpdate)
	const routeParams = useParams()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(tasksActions.getTask(null))
	}, [])

	const [isEdit, setIsEdit] = useState(false)
	const user = useGetUser()
	const isUser = useSelector(getProjectUserRole)
	const isAdminRole = useSelector(getAdminRole)
	const isProjectAdminContractRole =
		useSelector(getProjectAdminContractRole) || useSelector(getAdminProjectContractRole)

	const {
		data: taskData,
		isLoading: taskIsLoading,
		error: taskError,
		refetch: taskRefetch,
	} = useGetOneTaskQuery(routeParams.taskId, {
		refetchOnMountOrArgChange: true,
		pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
		skip: isEdit,
	})

	// const { data: filesData, refetch: filesRefetch } = useGetTaskFilesQuery(
	// 	{ task_id: routeParams.taskId },
	// 	{ refetchOnMountOrArgChange: true }
	// )

	useEffect(() => {
		setIsEdit(false)
	}, [routeParams.taskId])

	// useEffect(() => {
	// 	const getFilesHandler = async () => {
	// 		const items = []

	// 		await Promise.all(
	// 			filesData?.data.map(async (item) => {
	// 				const file = await getOneFile(item.file_id)
	// 				if (!file?.data) return
	// 				items.push(file?.data)
	// 			})
	// 		)
	// 		dispatch(tasksActions.getFiles(items))
	// 	}

	// 	if (filesData?.data) getFilesHandler()
	// 	return () => {
	// 		dispatch(tasksActions.getFiles([]))
	// 	}
	// }, [filesData])

	useEffect(() => {
		if (taskError) {
			navigate(backTo)
		}

		if (taskData?.data) {
			const isMyTask = defaultTaskContext?.isEnd
				? false
				: project?.isContract
				? taskData?.data?.assignee?.id === user?.id ||
				  taskData?.data?.author?.id === user?.id ||
				  isAdminRole ||
				  isProjectAdminContractRole
				: isUser
				? taskData?.data?.assignee?.id === user?.id || taskData?.data?.author?.id === user?.id
				: true

			const tasks = onlyMyTasks ? filterMySubtasks(taskData.data, user.id) : taskData.data
			dispatch(
				tasksActions.getTask({
					...tasks,
					isMyTask,
					project: {
						...tasks?.project,
						...project,
						isEnd: defaultTaskContext?.isEnd || tasks?.project?.confirmation_status === 'COMPLETED',
					},
				})
			)
		}
	}, [taskData, taskError, isUser, project, isProjectAdminContractRole, isAdminRole, isProjectAdminContractRole])

	if (!task || taskIsLoading)
		return (
			<div className="tt-w-full tt-flex-1 tt-flex tt-items-center tt-justify-center tt-min-h-full tt-p-24">
				<CircularProgress className="tt-text-primary-600" />
			</div>
		)

	const offEditDeadline = task?.assignee_id === user?.id ? false : isUser

	const page = backTo.indexOf('?') === -1 ? backTo : backTo.slice(0, backTo.indexOf('?'))
	const _taskSidebarId = getID({ prefix: id, place: 'sidebar' })

	return (
		<TaskContext.Provider value={{ task, baseUrl: backTo, page }}>
			<div className="tt-h-full  tt-flex tt-flex-col">
				{!hideHeader && (
					<TaskHeader
						id={_taskSidebarId}
						hideActionMenu={defaultTaskContext?.isEnd}
						left={
							task?.parent_id && (
								<TaskToParent parentTitle={task?.epic?.title} taskId={task?.id} parentId={task?.parent_id} />
							)
						}
						backTo={backTo}
					/>
				)}

				<div className="tt-overflow-auto tt-flex-1 tt-px-24 tt-py-16 ">
					{task?.project_id && (
						<>
							<GetProjectStatuses projectId={task?.project_id} />
						</>
					)}

					{isEdit ? (
						<>
							<EditTasks setIsEdit={setIsEdit} taskRefetch={taskRefetch} />
						</>
					) : (
						<div className="tt-h-full">
							{!hideActionButtons && <ActionButtons id={_taskSidebarId} setIsEdit={setIsEdit} />}
							<div className={[!hideActionButtons && 'tt-pt-24'].join(' ')}>
								<TaskContent id={_taskSidebarId} />
							</div>
							<div className="tt-py-24">
								<TaskMain id={_taskSidebarId} offEditDeadline={offEditDeadline} />
							</div>
						</div>
					)}
				</div>
				{!task?.project?.isEnd && <TaskSideFooter />}
				{/* {isEdit && <div className="tt-bg-white tt-sticky tt-h-12 tt-w-full">11</div>} */}
			</div>
		</TaskContext.Provider>
	)
}

export default TaskSideBar
