import React from 'react'

import RequestItem from './RequestItem'
import RequestItemId from './RequestItemId'
import RequestsFilters from './RequestsFilters'
import { requestType } from 'src/entities/task'
import { useTranslation } from 'react-i18next'

const RequestsList = ({ list, isProject, disabledLink, type }) => {
	const { t } = useTranslation()
	return (
		<>
			{!isProject && <RequestsFilters />}
			<div className="w-full flex-1">
				<div className="tt-w-full">
					<div className="flex flex-col gap-12 p-6 pb-24">
						{list.map((item) => {
							if (type === requestType.TASKS_DEADLINE) {
								return (
									<RequestItemId
										disabledLink={disabledLink}
										type={type}
										item={item}
										id={item.task_id}
										key={item.task_id}
									/>
								)
							}
							if (type === requestType.TASK_STATUS) {
								return (
									<RequestItemId
										disabledLink={disabledLink}
										type={type}
										item={item}
										id={item.task_id}
										key={item.task_id}
									/>
								)
							}
							// if (list.type === requestTypes.PROJECT) {
							// 	return <RequestItem disabledLink={disabledLink} type={list.type} item={item} id={item.id} key={item.id} />
							// }
						})}
						{list.length === 0 && (
							<div className="bg-gray-100 border border-gray-200/50 text-gray-500 rounded p-12 text-center">
								{t('empty_tasks_on_confirmation_list')}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default RequestsList

// REQ_CREATE_PROJECT = 'REQ_CREATE_PROJECT'; // подтверждение заявок на ввод новых проектов,
// REQ_REQUESTS_DEDUCTION_SANCTIONS = 'REQ_REQUESTS_DEDUCTION_SANCTIONS'; // запросов на неначисление санкций,
// REQ_CHANGING_USER_RIGHTS_STATUS_THEIR_PROCESSING = 'REQ_CHANGING_USER_RIGHTS_STATUS_THEIR_PROCESSING'; // запросов на изменение прав пользователя и статус их обработки,
// REQ_CONFIRMATION_TASK_COMPLETION_REPORT = 'REQ_CONFIRMATION_TASK_COMPLETION_REPORT'; // запросов на подтверждение отчета о выполнении задачи,
// REQ_CHANGING_DEADLINES_COMPLETING_TASK = 'REQ_CHANGING_DEADLINES_COMPLETING_TASK'; // подтверждение изменение сроков выполнения задачи,
// REQ_POSTPONEMENT_OF_DEADLINE = 'REQ_POSTPONEMENT_OF_DEADLINE'; // подтверждение переноса срока выполнения,
// ADDING_ACCOUNTING_DOCUMENTS = 'REQ_ADDING_ACCOUNTING_DOCUMENTS'; // подтверждение Добавление отчетных документов с возможностью отправки их на проверку и подтверждение ДСМ проекта / ответственному за учет задач по Протоколу совещания,
