import React, { ComponentProps, ElementType, memo, ReactNode } from 'react'
import styles from './styles.module.scss'

type ButtonOwnProps<E extends ElementType = ElementType> = {
	children: ReactNode
	as?: E
	// color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
	size?: 'small' | 'medium' | 'large'
	variant?: 'text' | 'outlined' | 'contained'
	startIcon?: any
	endIcon?: any
}

type ButtonProps<E extends ElementType> = ButtonOwnProps<E> & Omit<ComponentProps<E>, keyof ButtonOwnProps>

const defaultElement = 'button'

function Button<E extends ElementType = typeof defaultElement>(p: ButtonProps<E>): JSX.Element {
	const { children, size = 'medium', variant = 'contained', as, ...otherProps } = p

	const TagName = as || defaultElement

	const classNames = [styles.button, styles[variant], styles[size], p.className, p.disabled && styles.disabled].join(' ')

	return (
		<TagName {...otherProps} className={classNames}>
			{children}
		</TagName>
	)
}

export default memo(Button)
