import React, {useMemo} from 'react'
import {User} from "src/@entities/session";
import {
  HeadItem
} from "src/@widgets/project/ui/project-contract-directory-table/project-contract-directory-table";
import {useTranslation} from "react-i18next";
import {UserShow} from "src/shared/components";
import moment from "moment/moment";
import UnTable from "../../../../shared/components/un-table";

interface CuratorHistory {
  id: string
  created_at: string,
  project_id: string,
  updater_id:string,
  updater: User,
  curator_id: string,
  curator: User,
  motivation: number,
  work_percent: number
  accrued_part: number
}

interface ProjectCuratorsHistoryProps {
  curatorsHistory: CuratorHistory[]
}

export const ProjectCuratorsHistory: React.FC<ProjectCuratorsHistoryProps> = ({ curatorsHistory }) => {
  const { t } = useTranslation()

  const columns: any[] = useMemo(
      () => [
        {
          id: 1,
          label: t('curator'),
          field: 'curator',
          className: 'tt-flex-1 tt-min-w-64 tt-pl-2',
          render: (listItem: CuratorHistory) => <><UserShow user={listItem.curator ?? '-'}/></>,
        },
        {
          id: 2,
          label: t('amounted_paid'),
          field: 'amounted_paid',
          className: 'tt-max-w-256 tt-min-w-256 tt-w-256',
          render: (listItem: CuratorHistory) => <span className='tt-pl-4'>₽ {listItem?.accrued_part ? listItem?.accrued_part?.toLocaleString('ru') : '-'}</span>,
        },
        {
          id: 3,
          label: t('percentage_completion'),
          field: 'percentage_completion',
          className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
          render: (listItem: CuratorHistory) => <span className='tt-pl-4'>{listItem.work_percent ?? '-'} %</span>,
        },
        {
          id: 4,
          label: t('date_withdrawal_from_project'),
          field: 'created_at',
          className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
          render: (listItem: CuratorHistory) => <span className='tt-pl-4'>{listItem.created_at ? moment(listItem.created_at).format('LL') : "-"}</span>,
        },
      ],
      []
  )

  const heads: any[] = useMemo(
      () => [
        {
          id: 1,
          label: t('curator'),
          field: 'curator',
          className: 'tt-flex-1 tt-min-w-64',
          render: () => <HeadItem className="tt-flex-1 tt-min-w-64">{t('curator')}</HeadItem>,
        },
        {
          id: 2,
          label: t('amounted_paid'),
          field: 'amounted_paid',
          className: 'tt-max-w-256 tt-min-w-256 tt-w-256',
          render: () => <HeadItem className="tt-max-w-256 tt-min-w-256 tt-w-256" >{t('amounted_paid')}</HeadItem>,
        },
        {
          id: 3,
          label: t('percentage_completion'),
          field: 'percentage_completion',
          className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
          render: () => <HeadItem className="tt-max-w-192 tt-min-w-192 tt-w-192">{t('percentage_completion')}</HeadItem>,
        },
        {
          id: 4,
          label: t('date_withdrawal_from_project'),
          field: 'created_at',
          className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
          render: () => <HeadItem className="tt-max-w-192 tt-min-w-192 tt-w-192" >{t('date_withdrawal_from_project')}</HeadItem>,
        },
      ],
      []
  )

  return (
      <UnTable
          meta={{}}
          isLoading={false}
          heads={heads}
          list={curatorsHistory}
          columns={columns}
      />
  )
}
