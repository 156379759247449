import React, { useContext } from 'react'
import styles from './styles.module.scss'
import { useApprovedTaskDeadlineMutation, useApprovedTaskMutation } from 'src/old-app/service/Tasks'
import { Button, Tooltip } from '@mui/material'
import { requestType } from '../../types'
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { TaskContext } from '../../contexts'
import { userActions } from 'src/entities/user'
import { getUserID } from 'src/old-app/store/userSlice'
import { useSelector } from 'react-redux'
import { getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { useTranslation } from 'react-i18next'

interface IProps {
	id: string
	type: requestType
	className?: string
	isHideLabel?: boolean
	disabled?: boolean
}

const TaskApproved: React.FC<IProps> = (p) => {
	const { type, id, className, isHideLabel, disabled } = p

	const { task }: any = useContext(TaskContext)
	const { t } = useTranslation()
	const userID = useSelector(getUserID)
	const isProjectUserRole = useSelector(getProjectUserRole)
	const dispatch = useDispatch()

	const [approvedTask] = useApprovedTaskMutation()
	const [approvedTaskDeadline] = useApprovedTaskDeadlineMutation()

	const deadline = task?.deadlines && task?.deadlines?.length ? task?.deadlines[0] : null
	const youDeadlineConfirmation = deadline?.confirmations?.find((confirmation: any) => confirmation?.user_id === userID)
	const isApproveTaskDeadline = youDeadlineConfirmation?.confirm === 'ON_CONFIRMATION'

	const approvedHander = async () => {
		switch (type) {
			case requestType.TASK_STATUS:
				if (isApproveTaskDeadline) {
					const isMyTask = task?.assignee?.id === userID || task?.author?.id === userID || !isProjectUserRole

					dispatch(
						tasksActions.getTask({
							...task,
							isMyTask,
							project: { ...task?.project, isEnd: task?.project?.confirmation_status === 'COMPLETED' },
						})
					)
					dispatch(dialogActions.setComponent('task-deadline-confirmation'))
					dispatch(dialogActions.setDialog(true))
					return
				}
				await approvedTask({ task_id: id })
				break
			case requestType.TASKS_DEADLINE:
				await approvedTaskDeadline(id)
				break

			default:
				break
		}
	}
	return (
		<Tooltip
			title={
				disabled &&
				'Задача не может быть принята, пока администратор контрактов не утвердит или отклонит запрашиваемый дедлайн'
			}
		>
			<Button
				onClick={!disabled ? approvedHander : () => null}
				className={[
					'tt-bg-green-transparent tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-green-transparent tt-text-green tt-min-h-20 tt-h-20 tt-cursor-pointer',
					disabled && 'tt-cursor-not-allowed',
				].join(' ')}
			>
				<DoneOutlinedIcon className="tt-w-20 tt-h-20" />
				{isHideLabel ? '' : t('approve')}
			</Button>
		</Tooltip>
	)
}

export default TaskApproved
