import { InputBase } from '@mui/material'
import { getProject } from 'src/old-app/store/projectsSlice'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from 'src/old-app/hooks/useDebounce'
import { useGetUserProjectsQuery } from 'src/old-app/service/Projects'
import { useGetSearchUserQuery } from 'src/old-app/service/Users'
import CustomAvatar from '../ui/CustomAvatar'
import MySelect from '../ui/MySelect'
import { getID } from '../../../shared/config/fields-ids'
import { UserShow } from 'src/shared/components'

export const usersLabelRender = (value) => `${value?.last_name} ${value?.first_name}  ${value?.middle_name ?? ''}`
export const menuItemRander = (value) => <UserShow user={value} />

const ProjectUsersSelect = memo(({ id, ...props }) => {
	const project = useSelector(getProject)
	const [search, setSearch] = useState('')
	// const [users, setUsers] = useState([])
	const {
		data: usersData,
		isLoading,
		refetch,
	} = useGetUserProjectsQuery({
		name: search,
		current_page: 1,
		per_page: 50,
		project_id: props?.id ?? project?.id,
	})

	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) {
			refetch()
		}
	}, [debouncedSearch])

	const users = props?.users ?? usersData?.data?.map((item) => item?.user) ?? []

	return (
		<>
			<MySelect
				idSelect={getID({ prefix: id, field: 'assignees', type: 'select' })}
				menuItemRander={menuItemRander}
				labelRender={usersLabelRender}
				items={users}
				isSearch
				setSearch={setSearch}
				search={search}
				{...props}
			/>
		</>
	)
})

export default ProjectUsersSelect
