import { Chip } from '@mui/material'
import { getProjectsUsers } from 'src/old-app/store/projectsSlice'
import { getStatuses } from 'src/old-app/store/tasksSlice'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ProjectsSelect from 'src/old-app/components/shared/ProjectsSelect'
import ProjectStatusSelect from 'src/old-app/components/task/ProjectStatusSelect'
import ProjectUsersSelect from 'src/old-app/components/task/ProjectUsersSelect'
import TaskMySwitch from 'src/old-app/components/task/TaskMySwitch'
import { useGetOneProjectQuery } from 'src/old-app/service/Projects'

const Project = ({ projectId, handleAssigneDelete }) => {
	const { data: projectData } = useGetOneProjectQuery({ id: projectId })
	if (!projectData?.data) return null

	const project = projectData?.data

	return <Chip label={project?.name} selectClassName="new-select" variant="outlined" onDelete={handleAssigneDelete} />
}

const RequestsFilters = ({ hideStatus }) => {
	const navigate = useNavigate()
	const { search } = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const projectId = searchParams.get('project_id') ?? undefined

	const projectChange = (value) => {
		const params = new URLSearchParams()
		if (value) {
			params.append('project_id', value?.id)
		}

		navigate({
			search: params.toString(),
		})
	}

	const handleAssigneDelete = () => {
		const params = new URLSearchParams(search)
		params.delete('project_id')
		navigate({
			search: params.toString(),
		})
	}

	return (
		<div className="flex gap-8 items-center px-4 pb-6">
			<div className="flex items-center">
				{!projectId ? (
					<ProjectsSelect
						selectClassName="new-select"
						wrapperClassName="w-auto"
						hideError
						hideLabel
						displayEmpty
						field="project"
						label="Проект"
						value={{}}
						onChange={projectChange}
					/>
				) : (
					<Project projectId={projectId} handleAssigneDelete={handleAssigneDelete} />
				)}
			</div>
		</div>
	)
}

export default RequestsFilters
