import React, { FC, useState } from 'react'
import Autocomplete from '../autocomplete'
import useDebounce from 'src/shared/hooks/useDebounce'
import { useGetProjectTypesQuery } from '../../../old-app/service/Projects'
import ProjectTemplatesChoice from '../project-templates-choice'
import { useTranslation } from 'react-i18next'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	disabled?: boolean
	multiple?: boolean
}

const ProjectTypes: FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { value, onChange, placeholder = 'Тип проекта', isIds, multiple = false, disabled } = p

	const { data: projectTypesData } = useGetProjectTypesQuery({})

	const list: any[] = projectTypesData?.data ?? []

	return (
		<Autocomplete
			disabled={disabled}
			multiple={multiple}
			placeholder={placeholder}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => t(`types.${option?.name}`)}
			renderOptionLabel={(option: any) => t(`types.${option?.name}`)}
			list={list}
			value={value}
			onChange={onChange}
		/>
	)
}

export default ProjectTypes
