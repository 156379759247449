import React from 'react'
import styles from './styles.module.scss'

import { useCancelTaskMutation, useCancelTaskDeadlineMutation } from '../../../old-app/service/Tasks'
import { requestType } from '../../../entities/task'
import { Button } from '@mui/material'
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined'
import { requestsActions } from 'src/old-app/store/requestSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface IProps {
	className?: string
	label?: string
	id: string
	type: string
}

const CancelRequest: React.FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { type, id, className, label } = p
	const dispatch = useDispatch()

	const approvedHander = async () => {
		const newType =
			type === requestType.TASK_STATUS ? requestType.USER_CANCEL_TASK_STATUS : requestType.USER_CANCEL_TASKS_DEADLINE
		dispatch(requestsActions.getRequest({ type: newType, id }))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('cancel-request'))
	}
	return (
		<Button
			onClick={approvedHander}
			className="tt-bg-primary-transparent tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary tt-min-h-20 tt-h-20"
		>
			<CancelScheduleSendOutlinedIcon className="tt-w-20 tt-h-20" />
			{label ?? t('cancel')}
		</Button>
	)
}

export default CancelRequest
