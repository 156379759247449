/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { dialogActions, getDialogBackComponent } from 'src/old-app/store/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getID } from '../../../shared/config/fields-ids'

const DialogHeader = ({ title, back, backHandler }) => {
	const dispatch = useDispatch()
	const backComponent = useSelector(getDialogBackComponent)

	const onCloseDialog = () => {
		dispatch(dialogActions.setDialog(false))
	}

	const onBackDialig = () => {
		dispatch(dialogActions.setComponent(backComponent))
	}

	return (
		<div className="tt-px-32 tt-py-16 tt-mb-12 tt-flex tt-items-center tt-justify-between tt-border-b tt-sticky tt-top-0 tt-bg-white tt-z-50">
			<div className="tt-w-40 tt-h-40">
				{back && (
					<button
						type="button"
						id={getID({ type: 'button_back', place: 'modal' })}
						onClick={backHandler ?? onBackDialig}
					>
						<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M23 27L16 20L23 13"
								stroke="#64748B"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				)}
			</div>
			<div className="tt-text-18 tt-font-semibold tt-pt-16">{title}</div>
			<button type="button" id={getID({ type: 'button_close', place: 'modal' })} onClick={onCloseDialog}>
				<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14 14L26 26M14 26L26 14L14 26Z"
						stroke="#64748B"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	)
}

export default DialogHeader
