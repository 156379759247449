/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import { Controller, useForm } from 'react-hook-form'
import DialogMain from '../DialogMain'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useCreatePriorityTaskMutation } from 'src/old-app/service/Projects'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import ErrorText from '../../ui/ErrorText'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getAdminRole } from 'src/old-app/store/userSlice'
import { getProject, projectsActions } from 'src/old-app/store/projectsSlice'
import getColor from 'src/old-app/utils/getColor'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const CreatePriority = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		name: yup.string().required(t('title_is_required')),
		level: yup.string().required(t('level_is_required')),
	})
	const dispatch = useDispatch()

	const project = useSelector(getProject)

	const isAdmin = useSelector(getAdminRole)

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		setError,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const [createPriorityTask, result] = useCreatePriorityTaskMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (result.isError) {
			dispatch(interfaceActions.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: result?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (result.isSuccess) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))
		}
	}, [result])

	useEffect(() => {
		setValue('color', getColor())
	}, [])

	const isValidation = (data) => {
		// const today = moment().format()
		// if (moment(data.complete_at).isBefore(today)) {
		// 	setError('complete_at', { message: 'Нельзя выбрать дату завершения проекта меньше сегодняшней' })
		// 	return true
		// }

		return false
	}

	const createStatusHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const createData = await createPriorityTask({
				name: data.name,
				level: Number(data.level),
				color: data.color,
				project_id: project.id,
			})
			dispatch(projectsActions.addPriority(createData.data.data))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(createStatusHandler)} className="tt-w-600">
			<DialogHeader title={t('new.priority')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('title')}</span>

						<Controller
							name="name"
							control={control}
							render={({ field: { value, onChange } }) => (
								<TextField
									value={value}
									id={getID({
										place: 'modal',
										entity: 'priority',
										action: 'create',
										field: 'name',
										type: 'text',
									})}
									onChange={onChange}
									error={!!errors.name}
									hiddenLabel
									placeholder={t('title')}
									className="dialig-input w-full"
									size="small"
								/>
							)}
						/>

						<ErrorText errors={errors} field="name" />
					</div>
					<div className="tt-flex tt-flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('level')}</span>
						<div className="tt-flex tt-gap-12">
							<Controller
								name="level"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										id={getID({
											place: 'modal',
											entity: 'priority',
											action: 'create',
											field: 'level',
											type: 'number',
										})}
										onChange={onChange}
										error={!!errors.name}
										hiddenLabel
										type="number"
										placeholder={t('level')}
										className="dialig-input tt-w-full"
										size="small"
									/>
								)}
							/>

							<Controller
								name="color"
								control={control}
								render={({ field: { value, onChange } }) => (
									<input
										value={value}
										id={getID({
											place: 'modal',
											entity: 'priority',
											action: 'create',
											field: 'color',
											type: 'color',
										})}
										onChange={onChange}
										placeholder={t('color')}
										className="dialig-input"
										size="small"
										type="color"
									/>
								)}
							/>
						</div>

						<ErrorText errors={errors} field="level" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter
				id={getID({
					place: 'modal',
					entity: 'priority',
					action: 'create',
				})}
				isDisabled={isDisabled}
			/>
		</form>
	)
}

export default CreatePriority
