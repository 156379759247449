import React, { useState } from 'react'
import moment from 'moment/moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Mention, MentionsInput } from 'react-mentions'
import clsx from 'clsx'
import { CommentDeleteButton, CommentUploadFileButton } from 'src/@features/task'
import { Avatar } from 'src/shared/components'
import styles from './comment-card.module.scss'
import { Button, IconButton, Tooltip } from '@mui/material'
import AddComment, { mentionDefaultStyle } from 'src/old-app/components/task/AddComment'
import {
	useDeleteCommentFileMutation,
	useGetCommentsQuery,
	useUpdateCommentMutation,
} from 'src/old-app/service/Comment'
import { useSelector } from 'react-redux'
import { getTask } from 'src/old-app/store/tasksSlice'
import GridFiles from 'src/old-app/components/task/GridFiles'
import { useGetUser } from 'src/shared/hooks'
import { getID } from 'src/shared/config/fields-ids'
import { getPUsers } from 'src/old-app/store/projectsSlice'
import { getAcronym } from 'src/@shared/lib'
import MDEditor from '@uiw/react-md-editor'
import { useTranslation } from 'react-i18next'

export const CommentCard = (p) => {
	const { t } = useTranslation()

	const { comment } = p

	const task = useSelector(getTask)
	const curUser = useGetUser()
	const [isAnswer, setIsAnswer] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [newText, setNewText] = useState(comment.text)
	const [updateComment] = useUpdateCommentMutation()
	const [deleteCommentFile] = useDeleteCommentFileMutation()

	const pUsers = useSelector(getPUsers)
	const users = pUsers.map((item) => ({
		id: item.id,
		display: `${item?.last_name} ${item?.first_name}`,
	}))

	const { data: childCommentsData } = useGetCommentsQuery({
		params: { parent_comment_id: comment.id, per_page: 999 },
		id: task.id,
	})

	const editCommentFn = async (event) => {
		try {
			event.preventDefault()
			setIsEdit(false)
			await updateComment({ taskId: task.id, commentId: comment.id, body: { text: newText } })
		} catch (error) {
			console.log(error)
		}
	}

	const deleteFileFn = async (id) => {
		try {
			await deleteCommentFile({ comment_id: comment.id, file_id: id })
		} catch (error) {}
	}

	const childComments = childCommentsData?.data ?? []
	const isManageComment = comment.user_id === curUser?.id

	const isSave = Boolean(comment?.files?.length > 0 || newText)

	const name = `${comment.user?.last_name} ${comment.user?.first_name}`
	const alt = getAcronym(name)

	const cancelIsEdit = () => {
		setIsEdit(false)
		setNewText(comment.text)
	}

	return (
		<div className={styles['comment']}>
			<div className={styles['comment-card-container']}>
				<div className={styles['comment-card-avatar']}>
					<Avatar alt={alt} src={comment.user.avatar256} />
				</div>

				<div className={styles['comment-card']}>
					<div className={styles['comment-card-header']}>
						<div className={styles['comment-card-info']}>
							<span className={styles['comment-card-info-name']}>
								{comment.user.first_name} {comment.user.last_name}
							</span>
							&bull;
							<span className={styles['comment-card-info-date']}>{moment(comment.created_at).fromNow()}</span>
						</div>
						{!task?.project?.isEnd && (
							<div className={styles['comment-card-header-actions']}>
								<Tooltip title={isAnswer ? t('cancel_comments') : t('reply_to_comment')}>
									<IconButton
										id={getID({ prefix: comment.id, action: 'manage', type: 'button_open' })}
										onClick={() => setIsAnswer(!isAnswer)}
										size="small"
									>
										<SmsOutlinedIcon fontSize="inherit" />
									</IconButton>
								</Tooltip>
								{isManageComment && (
									<>
										<Tooltip title={isEdit ? t('cancel_editing') : t('edit')}>
											<IconButton
												id={getID({ prefix: comment.id, action: 'edit', type: 'button_edit' })}
												onClick={() => setIsEdit(!isEdit)}
												size="small"
											>
												<EditOutlinedIcon fontSize="inherit" />
											</IconButton>
										</Tooltip>
										<CommentUploadFileButton taskId={task.id} commentId={comment.id} />
										<CommentDeleteButton taskId={task.id} commentId={comment.id} />
									</>
								)}
							</div>
						)}
					</div>

					{isEdit ? (
						<form
							style={{ width: 'calc(100% - 36px)' }}
							onSubmit={editCommentFn}
							className="tt-flex tt-gap-12 tt-max-w-full"
						>
							<MentionsInput
								id={getID({ prefix: comment.id, action: 'input', type: 'mention_input' })}
								onChange={(event) => setNewText(event.target.value)}
								value={newText}
								style={mentionDefaultStyle}
								className="tt-w-full"
								placeholder={t('comment')}
								a11ySuggestionsListLabel="Suggested mentions"
							>
								<Mention
									markup="@[__display__](__id__)"
									trigger="@"
									data={users}
									renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
										<div className={`user ${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>
									)}
									style={{ backgroundColor: '#CDCBF8' }}
								/>
							</MentionsInput>

							<div className="tt-flex tt-flex-col tt-gap-4">
								<Button
									disabled={!isSave}
									id={getID({ prefix: comment.id, action: 'edit', type: 'button_toggle' })}
									type="submit"
									className="disabled:tt-bg-gray-300 disabled:tt-text-gray-800 tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-24"
								>
									{t('save')}
								</Button>
								<Button
									size="small"
									onClick={cancelIsEdit}
									id={getID({ prefix: comment.id, action: 'cancel', type: 'button_toggle' })}
									type="button"
									className=" tt-bg-transparent  tt-min-h-32 tt-h-32 hover:tt-bg-gray-300 tt-text-gray-500 hover:tt-text-black tt-w-full tt-text-center tt-py-2 tt-px-2"
								>
									{t('cancel')}
								</Button>
							</div>
						</form>
					) : (
						<div className="tt-container" data-color-mode="light">
							<MDEditor.Markdown
								className={clsx(styles['comment-text'], 'text-hyphens')}
								components={{
									a: ({ children }) => <span className="tt-text-primary">{children}</span>,
								}}
								source={comment.text}
							/>
						</div>
					)}

					<div className={styles['comment-card-footer']}>
						{comment?.files && (
							<GridFiles
								id=""
								deleteFileFn={isManageComment ? deleteFileFn : null}
								files={comment?.files}
								toCommentCard={true}
							/>
						)}
					</div>
				</div>
			</div>
			{isAnswer && (
				<div className={styles['comment-card-container']}>
					<div className={styles['comment-card-avatar']}></div>
					<AddComment
						style={{ width: 'calc(100% - 36px)' }}
						id={`add-comment-${comment.id}`}
						placeholder={t('reply')}
						onSubmit={() => setIsAnswer(false)}
						setIsAnswer={setIsAnswer}
						commentId={comment.id}
					/>
				</div>
			)}
			{childComments.length !== 0 && (
				<div className={styles['comment-card-container']}>
					<div className={styles['comment-card-avatar']}></div>
					<div style={{ width: 'calc(100% - 36px)' }} className={styles['comment-child-comments']}>
						{childComments.map((comment) => (
							<div key={comment.id} className="tt-flex tt-w-full ">
								<div className="comment__branches">
									<div className="comment__branch" />
								</div>
								<CommentCard comment={comment} />
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}
