import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import { useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { setParamsFilters } from '../../main/tasks/TaskList'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { customArrayParam, dateParam } from '../../main/my/AllMyTasksList'
import { useTranslation } from 'react-i18next'

export const AllMyTasksPageQueryParams = {
	searchTask: StringParam,
	slug: StringParam,
	projects: StringParam,
	order_sort: withDefault(StringParam, 'desc'),
	deadline_start: dateParam,
	deadline_end: dateParam,
	completed_at_start: dateParam,
	completed_at_end: dateParam,
	createdAtStart: dateParam,
	createdAtEnd: dateParam,
	status: customArrayParam,
	author: customArrayParam,
	assignee: customArrayParam,
	priority: customArrayParam,
	type: customArrayParam,
	tags: customArrayParam,
	fields: withDefault(JsonParam, {}),
	// 'дом': StringParam
}

const ExportSanction = ({ projectId, isButton, className }) => {
	const { t } = useTranslation()

	const [anchorEl, setAnchorEl] = useState(null)

	const dispatch = useDispatch()

	const [searchParams, setSearchParams] = useSearchParams()

	const [paramsSearch, setParamsSearch] = useState({})

	const [queryParams] = useQueryParams(AllMyTasksPageQueryParams, { includeAllParams: true })

	const multipleParamsString = setParamsFilters({
		type: queryParams.type,
		priority: queryParams.priority,
		statuses: queryParams.status,
		assignee: queryParams.assignee,
		tags: queryParams.tags,
	})

	const exportFileHandler = (path) => {
		const name = `Санкции`

		const params = {
			project_id: projectId ? projectId : queryParams.projects,
			created_at_sort: queryParams.order_sort,
			slug: queryParams.slug,
			title: queryParams.searchTask,
			start_date_deadline: queryParams.deadline_start,
			// end_date_deadline: queryParams.deadline_end?.replace('T00:00:00', 'T23:59:59'),
			end_date_deadline: queryParams.deadline_end,
			start_completed_at: queryParams.completed_at_start,
			// end_completed_at: queryParams.completed_at_end?.replace('T00:00:00', 'T23:59:59'),
			end_completed_at: queryParams.completed_at_end,
			// end_date_created_at: queryParams.createdAtEnd,
			// start_date_created_at: queryParams.createdAtStart,
			author: queryParams?.author?.join(','),
			...queryParams.fields,
		}

		const url = `/${path}?${multipleParamsString}`
		exportKPGHandler({ url, params, name, dispatch })
	}

	return (
		<>
			{isButton ? (
				<Button
					onClick={() => exportFileHandler('report/sanction')}
					id="basic-button"
					startIcon={<UploadFileIcon />}
					className="tt-text-primary tt-text-14 tt-whitespace-nowrap hover:tt-bg-transparent tt-border tt-border-primary-600  md:tt-text-14  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('download_sanctions')}
				</Button>
			) : (
				<div className={className} onClick={() => exportFileHandler('report/sanction')}>
					{t('download_sanctions')}
				</div>
			)}
		</>
	)
}

export default ExportSanction
