import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const commentAPI = createApi({
	reducerPath: 'comment-api',
	tagTypes: ['Comment'],
	baseQuery,
	endpoints: (build) => ({
		getComments: build.query({
			query: ({ id, params }) => ({
				url: `/task/${id}/comment`,
				params,
			}),

			providesTags() {
				const { id, params } = arguments[2]
				return [
					{
						type: 'Comment',
						id: `${id}${params.current_page ? `_page-${params.current_page}` : ''}`,
					},
				]
			},
		}),
		fileComment: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: '/file-comment/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Comment'],
		}),
		deleteCommentFile: build.mutation({
			query: (body) => ({
				method: 'DELETE',
				url: '/file-comment/',
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Comment'],
		}),
		createComment: build.mutation({
			query: ({ body, id }) => ({
				method: 'POST',
				url: `/task/${id}/comment`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Comment'],
		}),
		updateComment: build.mutation({
			query: ({ body, taskId, commentId }) => ({
				method: 'PUT',
				url: `/comment/${commentId}`,
				body: JSON.stringify(body),
			}),
			invalidatesTags: ['Comment'],
		}),
		deleteComment: build.mutation({
			query: ({ body, taskId, commentId }) => ({
				method: 'DELETE',
				url: `/comment/${commentId}`,
				body: JSON.stringify({}),
			}),
			invalidatesTags: ['Comment'],
		}),
	}),
})

export const {
	useGetCommentsQuery,
	useCreateCommentMutation,
	useUpdateCommentMutation,
	useDeleteCommentMutation,
	useFileCommentMutation,
	useDeleteCommentFileMutation,
} = commentAPI
