import { createSlice } from '@reduxjs/toolkit'
import rolesType from '../constants/rolesType'

const initialState = {
	users: [],
	user: null,
	roles: [],
	projectRoles: [],
}

const reducers = {
	getUsers: (state, action) => {
		state.users = action.payload
	},
	getUser: (state, action) => {
		state.user = action.payload
	},
	getRoles: (state, action) => {
		state.roles = action.payload
	},
	getProjectRoles: (state, action) => {
		state.projectRoles = action.payload
	},
}

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers,
})

export const getUsers = (state) => state.users.users
export const getUser = (state) => state.users.user
export const getRoles = (state) => state.users.roles
export const getUserID = (state) => state.users.user?.id
export const getAdminRole = (state) => state.users?.roles.some((item) => item === rolesType.ADMIN)
export const getAdminProjectContractRole = (state) =>
	state.users?.roles.some((item) => item === rolesType.ADMIN_PROJECT_CONTRACT)
export const getUserRole = (state) => state.users?.roles.some((item) => item === rolesType?.USER)
export const getBARole = (state) => state.users.roles.some((item) => item === rolesType.BA)

export const usersReducer = usersSlice.reducer
export const usersActions = usersSlice.actions
