import { menuItemRander, usersLabelRender } from 'src/old-app/components/task/ProjectUsersSelect'
import MySelect from 'src/old-app/components/ui/MySelect'
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined'
import { Controller } from 'react-hook-form'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'

const CreateProjectTemplateTasks = ({ template, control, users, id }) => {
	const { t } = useTranslation()

	return (
		<div className="tt-col-span-6">
			<div>
				<Controller
					render={({ field: { value, onChange } }) => (
						<>
							{value.map((item, index) => {
								const changeAssigneeHandler = (user) => {
									onChange(
										value.map((v) => {
											if (v.id === item.id) {
												v.newAssignee = user
											}
											return v
										})
									)
								}

								return (
									<div className="tt-flex tt-items-center tt-gap-16">
										<div className="tt-flex tt-flex-col tt-w-full">
											<MySelect
												idSelect={getID({
													prefix: id,
													field: 'assignees_template',
													type: 'select',
													index,
												})}
												items={users}
												menuItemRander={menuItemRander}
												labelRender={usersLabelRender}
												displayEmpty
												field="old_asignee"
												// idSelect={`project-template-old-assignee-${index}`}
												disabled
												label={t('performer')}
												// onChange={onChange}
												value={item.oldAssignee}
											/>
										</div>
										<DoubleArrowOutlinedIcon className="tt-mt-8 tt-text-gray-600" />
										<div className="tt-flex tt-flex-col tt-w-full">
											<MySelect
												idSelect={getID({
													prefix: id,
													field: 'assignees',
													type: 'select',
													index,
												})}
												items={users}
												menuItemRander={menuItemRander}
												labelRender={usersLabelRender}
												displayEmpty
												field="new_assignee"
												label={t('performer')}
												onChange={changeAssigneeHandler}
												value={item.newAssignee}
											/>
										</div>
									</div>
								)
							})}
						</>
					)}
					name="assignees"
					control={control}
				/>
			</div>
			<Controller
				render={({ field: { value, onChange } }) => {
					const changeDeadline = (dateValue, id) => {
						onChange(
							value.map((item) => {
								if (item.id === id) {
									item = { ...item, actual_deadline: { deadline: dateValue } }
								}
								return item
							})
						)
					}
					return (
						<div className="tt-overflow-auto tt-mt-12">
							{value?.map((item, index) => (
								<div className="tt-w-full group tt-flex tt-gap-12 tt-justify-between tt-items-center tt-bg-white tt-relative tt-px-12 tt-py-4 hover:tt-bg-gray-50 tt-rounded-lg tt-border   tt-cursor-pointer">
									<p className="tt-truncate">{item?.title}</p>
									<DatePickerInput
										sx={{ width: '16rem', flexShrink: '0' }}
										value={item?.actual_deadline?.deadline}
										onChange={(value) => changeDeadline(value, item.id)}
									/>
								</div>
							))}
						</div>
					)
				}}
				name="tasks"
				control={control}
			/>
		</div>
	)
}

export default CreateProjectTemplateTasks
