import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { TASK_UPDATE } from 'src/shared/config'
import { Skeleton } from '@mui/material'
import TaskFilters from '../tasks/TaskFilters'
import TaskListItem from '../tasks/TaskListItem'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useGetTasksRQuery } from '../../service/Tasks'
import { getTasks, tasksActions } from 'src/old-app/store/tasksSlice'
import { getIsAutoUpdate } from 'src/old-app/store/interfaceSlice'
import { getUserID } from 'src/old-app/store/userSlice'
import EmptyPlaceholder from '../../../shared/components/empty-placeholder'
import { useGetUser } from '../../../shared/hooks'
import { PathnameCell } from '../../../shared/components'
import TaskAcceptButton from '../../../entities/task/components/task-accept-button'
import { useTranslation } from 'react-i18next'

export const dateParam = {
	decode(strValue) {
		return strValue && moment(strValue).format()
	},
}

export const customArrayParam = {
	decode(strValue) {
		return strValue && JSON.parse(strValue)
	},
}

const pageHideSkelet = { assignee: true, project: false, complete_date: true, order_sort: true, created_at_sort: true }
const initialPerPage = 100
export const AllMyTasksPageQueryParams = {
	searchTask: StringParam,
	slug: StringParam,
	projects: StringParam,
	tasks_incoming: StringParam,
	created_at_sort: withDefault(StringParam, 'desc'),
	order_sort: withDefault(StringParam, 'desc'),
	deadline_sort: StringParam,
	deadline_start: dateParam,
	deadline_end: dateParam,
	completed_at_start: dateParam,
	completed_at_end: dateParam,
	createdAtStart: dateParam,
	createdAtEnd: dateParam,
	status: customArrayParam,
	author: customArrayParam,
	priority: customArrayParam,
	assignee: customArrayParam,
	type: customArrayParam,
	tags: customArrayParam,
	fields: withDefault(JsonParam, {}),
	// 'дом': StringParam
}

export function AllMyTasksList({
	id,
	taskParams,
	right,
	hideSkelet,
	isRightFilterLabel,
	page,
	hideAssigneId,
	scroll,
	statusSlot,
	isMyWorkspace,
	isTeamWorkspace
}) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isAutoUpdate = useSelector(getIsAutoUpdate)
	const getTasksData = useSelector(getTasks)
	const user = useGetUser()
	const userID = user?.id
	const [perPage, serPerPage] = useState(initialPerPage)
	const [total, setTotal] = useState(0)
	const [fetchLoading, setFetchLoading] = useState(false)
	const [fetching, setFetching] = useState(false)

	const [queryParams] = useQueryParams(AllMyTasksPageQueryParams, { includeAllParams: true })

	const isTasksIncoming = queryParams?.tasks_incoming === 'true'

	const {
		data: tasks,
		isLoading,
		isFetching,
		refetch,
	} = useGetTasksRQuery(
		{
			params: {
				project_id: queryParams.projects,
				created_at_sort: queryParams?.order_sort,
				deadline_sort: queryParams?.deadline_sort ?? undefined,
				current_page: 1,
				per_page: perPage,
				slug: queryParams.slug,
				title: queryParams.searchTask,
				start_date_deadline: queryParams.deadline_start,
				end_date_deadline: queryParams.deadline_end,
				start_completed_at: queryParams.completed_at_start,
				end_completed_at: queryParams.completed_at_end,
				only_epic: true,
				incoming: queryParams?.tasks_incoming === 'true' ? true : undefined,
				author: queryParams?.author?.join(','),
				...queryParams.fields,
				...taskParams,
				project_ids: undefined,
			},
			arraysParams: {
				project_status_id: queryParams.status,
				assignee_id: hideAssigneId ? queryParams.assignee : userID ? [userID] : [],
				task_priority_id: queryParams.priority,
				type_id: queryParams.type,
				hashtag_id: queryParams.tags,
			},
		},
		{
			pollingInterval: isAutoUpdate ? TASK_UPDATE : false,
		}
	)

	const refetchList = async () => {
		if (total === getTasksData.length) return null
		if (fetchLoading) return null

		setFetchLoading(true)

		serPerPage((prev) => prev + initialPerPage)
		refetch()
	}

	useEffect(() => {
		if (fetching) {
			refetchList()
		}
	}, [fetching])

	useEffect(() => {
		if (tasks?.data) {
			dispatch(tasksActions.getTasks(tasks.data))
			setTotal(tasks?.meta?.total_entries)
			setFetchLoading(false)
			setFetching(false)
		}
	}, [tasks, isLoading])

	const scrollHandler = (e) => {
		const scrollHeight = e.target.scrollHeight
		const scrollTop = e.target.scrollTop
		const innerHeight = window.innerHeight

		const left = scrollHeight
		const right = scrollTop + innerHeight

		if (left < right) {
			setFetching(true)
		}
	}

	const scrollView = document?.querySelector(scroll ? scroll : '.table-scroll')

	useEffect(() => {
		if (!scrollView) return
		scrollView?.addEventListener('scroll', scrollHandler)
		return function () {
			scrollView?.removeEventListener('scroll', scrollHandler)
		}
	}, [scrollView])

	if (isLoading)
		return (
			<div className="tt-w-full tt-flex-col tt-flex tt-gap-4 tt-border tt-border-gray-200 tt-rounded-lg tt-overflow-hidden">
				{Array(5)
					.fill()
					.map((_, ind) => (
						<Skeleton key={ind} className="tt-w-full" sx={{ bgcolor: 'white' }} height={44} />
					))}
			</div>
		)

	return (
		<div className="flex flex-col tt-overflow-auto table-scroll">
			<div className="tt-w-full">
				<table className="tt-w-full tt-overflow-hidden">
					<TaskFilters
						id={id}
						isRightFilterLabel={isRightFilterLabel}
						allProjectsMode
						hideSkelet={hideSkelet ?? pageHideSkelet}
						isMyWorkspace={isMyWorkspace}
						isTeamWorkspace={isTeamWorkspace}
						projectIds={taskParams?.project_ids ?? []}
					/>

					{getTasksData?.map((item, index) => (
						<TaskListItem
							index={index}
							id={id}
							statusSlot={statusSlot}
							assigneId={hideAssigneId ? undefined : userID}
							right={right ?? <div className={'tt-w-[36px]'} />}
							// url={`${item.project?.id || ind}/`}
							url={({ projectId, taskId, location }) => ({
								...location,
								pathname: `${page ?? ''}${projectId}/${taskId}`,
							})}
							isTasksIncoming={isTasksIncoming}
							className="hover:tt-bg-primary/10"
							activeClassName="tt-bg-primary/10 hover:tt-bg-primary/10"
							accordionSx={{ paddingBottom: '8px' }}
							takeStatusesFromRedux
							hideSkelet={hideSkelet ?? pageHideSkelet}
							key={item.id}
							data={item}
						/>
					))}
					{!isLoading && tasks?.meta?.total_entries === 0 && <EmptyPlaceholder text={t('empty_tasks_list')} />}

					{fetchLoading && <EmptyPlaceholder text={t('loading_tasks')} />}
				</table>
			</div>
		</div>
	)
}
