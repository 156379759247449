import { Button, InputBase, Popover } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useDebounce from 'src/old-app/hooks/useDebounce'
import AddIcon from '@mui/icons-material/Add'
import MySelect from '../ui/MySelect'
import { useDispatch } from 'react-redux'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { getID } from '../../../shared/config/fields-ids'
import { useCreateProjectHashtagMutation, useGetProjectHashtagQuery } from '../../service/Projects'
import { useTranslation } from 'react-i18next'

const labelRender = (value) => value?.hashtag

const ProjectHashtagSelect = ({ useInnerValue, value, onChange, idAddComponent, id, ...props }) => {
	const { t } = useTranslation()
	const [search, setSearch] = useState('')

	const {
		data: hashtagsData,
		refetch,
		isSuccess,
	} = useGetProjectHashtagQuery({
		hashtag: search,
		current_page: 1,
		per_page: 250,
	})

	const allHashtagsQuery = useGetProjectHashtagQuery({
		current_page: 1,
		per_page: 250,
	})

	const debouncedSearch = useDebounce(search, 2000)

	useEffect(() => {
		if (debouncedSearch) refetch()
	}, [debouncedSearch])

	const [selected, setSelected] = useState(props.multiple ? [] : '')
	const isSelectedEmpty = Boolean(selected?.length)

	useEffect(() => {
		if (!isSelectedEmpty && allHashtagsQuery.isSuccess)
			setSelected(value.map((tag) => allHashtagsQuery.data?.data.find((_tag) => _tag.id === tag)))
	}, [value, allHashtagsQuery.isSuccess])

	function _onChange(item) {
		setSelected(item)
		onChange?.(item)
	}

	const getHashtags = hashtagsData?.data ?? []
	const hashtags = props.filterHashtags ? props.filterHashtags(getHashtags) : getHashtags
	return (
		<>
			<MySelect
				label="HashtagSelect"
				idSelect={getID({ prefix: id, field: 'hashtags', type: 'select' })}
				// idSelect={getFieldID({})}
				// disabled={!isSuccess}
				addComponent={<AddComponent id={idAddComponent} refetch={refetch} />}
				addLabel={t('add')}
				menuItemRander={labelRender}
				labelRender={labelRender}
				items={hashtags}
				isSearch
				setSearch={setSearch}
				search={search}
				value={selected}
				onChange={_onChange}
				{...props}
			/>
		</>
	)
}

export default ProjectHashtagSelect

// const schema = yup.object().shape({
// 	hashtag: yup.string().required('hashtag'),
// });

const _addComponentIDS = {
	openPopover: getID({ entity: 'hashtag', action: 'create', type: 'button_open' }),
	tagName: getID({ entity: 'hashtag', action: 'create', type: 'text' }),
	submit: getID({ entity: 'hashtag', action: 'create', type: 'button_submit' }),
}

function AddComponent({ refetch }) {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const [hashtag, setHashtag] = useState('')

	const open = Boolean(anchorEl)
	const popoverId = open ? 'simple-popover' : undefined

	const dispatch = useDispatch()

	const [createHashtag, result] = useCreateProjectHashtagMutation()

	// useEffect(() => {
	// 	if (result?.isError) {
	// 		dispatch(interfaceActions?.setIsLoading(false));
	//
	// 		dispatch(
	// 			showMessage({
	// 				message: result?.error?.data?.error ?? 'Ошибка',
	// 				autoHideDuration: 2000,
	// 				anchorOrigin: {
	// 					vertical: 'top',
	// 					horizontal: 'top-right',
	// 				},
	// 				variant: 'error',
	// 			}),
	// 		);
	// 	}
	// }, [result]);

	// const form = useForm({
	// 	defaultValues: {},
	// 	resolver: yupResolver(schema),
	// });

	const handleClick = (event) => {
		// console.log('handleClick');
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const createHashtagHandler = async () => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			const hashtagData = await createHashtag({ hashtag })

			if (!hashtagData?.data) return null
			setHashtag('')
			handleClose()
			refetch()
			dispatch(interfaceActions?.setIsLoading(false))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<div>
			<Button
				aria-describedby={popoverId}
				onClick={handleClick}
				id={_addComponentIDS.openPopover}
				// startIcon={
				// 	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
				// 		<path d='M10 5V10M10 10V15M10 10H15M10 10H5' stroke='#94A3B8' strokeWidth='2' strokeLinecap='round'
				// 		      strokeLinejoin='round' />
				// 	</svg>
				// }
				startIcon={<AddIcon className={'tt-text-gray-500'} />}
				// className='border-gray-200  text-12 min-h-32 h-32 px-16 mr-16'
				className="tt-border-gray-200 mr-16"
				variant="outlined"
				size={'small'}
				// type={'button'}
			>
				{t('create')}
				{/*ADD*/}
			</Button>

			<Popover
				id={popoverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<div className="tt-flex tt-items-center tt-gap-6 tt-px-16 tt-py-6">
					<InputBase
						value={hashtag}
						id={_addComponentIDS.tagName}
						onChange={(event) => setHashtag(event.target.value)}
						className="tt-w-full"
						placeholder={t('title')}
					/>

					<Button
						onClick={createHashtagHandler}
						id={_addComponentIDS.submit}
						startIcon={<AddIcon />}
						className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white tt-w-160  tt-text-sm tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('add')}
					</Button>
				</div>
			</Popover>
		</div>
	)
}
