import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
export function PickedDateInputs({ date }) {
	const { t } = useTranslation()
	const inputs = [t('from'), t('till')]
	return (
		<div className={'react-calendar__selected-dates__wrap'}>
			{inputs.map((label, index) => (
				<label key={label} className={'react-calendar__selected-dates__input-wrap'}>
					{label}
					<input value={date[index] ? dayjs(date[index]).format('DD-MM-YYYY') : ''} type="text" readOnly />
				</label>
			))}
		</div>
	)
}
