import styles from './ProjectProtocolsTableDummyRow.module.css'
import ProjectProtocolsTableDummyCell from './ProjectProtocolsTableDummyCell'

const ProjectProtocolsTableDummyRow = ({ columnsSettings }) => {
	return (
		<div className={styles.row_wrapper}>
			<div className={styles.row}>
				{Object.entries(columnsSettings).map((columnSettings, index) => {
					const columnID = columnSettings[0]
					return (
						<ProjectProtocolsTableDummyCell
							key={columnID}
							canGrow={columnsSettings[columnID]?.canGrow}
							basicWidth={columnsSettings[columnID]?.basicWidth}
							animationDelay={250 * index}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ProjectProtocolsTableDummyRow
