import React, { useEffect, useMemo } from 'react'
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import {
	useCreateProjectHashtagLinkMutation,
	useCreateUserProjectMutation,
	useDeleteProjectHashtagMutation,
	useDeleteProjectUserMutation,
	useUpdateProjectMutation,
	useDeleteFileProjectMutation,
	useCreateFileProjectMutation,
} from 'src/old-app/service/Projects'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useGetTeamProjectsQuery } from 'src/old-app/service/Teams'
import { InputContainer, UsersChoice } from '../../../shared/components'
import ProjectTagsChoice from '../../../shared/components/project-tags-choice'
import CommandsChoice from '../../../shared/components/commands-choice'
import { useProjectShow } from '../../../entities/project'
import ProjectTypes from '../../../shared/components/project-types'
import { FileInput } from 'src/shared/components/file-input'
import { fileUpload } from '../../service/File'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Files from '../task/Files'

const EditProject = ({ setEdit, isSideBar }) => {
	const { t } = useTranslation()

	const schema = yup.object().shape({
		old_percentage_completion: yup
			.number()
			.when('is_change_curator', {
				is: true,
				then: yup.number().required(t('percentage_completion_is_required')).nullable(),
			})
			.nullable(),

		old_motivation: yup
			.number()
			.when('is_change_curator', {
				is: true,
				then: yup.number().required(t('motivation_is_required')).nullable(),
			})
			.nullable(),

		old_guaranteed_part: yup
			.number()
			.when('is_change_curator', {
				is: true,
				then: yup.number().required(t('guaranteed_part_is_required')).nullable(),
			})
			.nullable(),

		name: yup.string().required(t('project_title_is_required')),

		old_curator: yup
			.object()
			.when('is_change_curator', {
				is: true,
				then: yup.object().required(t('curator_is_required')).nullable(),
			})
			.nullable(),

		curator: yup
			.object()
			.when('is_contract', {
				is: true,
				then: yup.object().required(t('dsm_is_required')).nullable(),
			})
			.nullable(),

		motivation: yup
			.number()
			.when('is_contract', {
				is: true,
				then: yup.number().required(t('motivation_is_required')).nullable(),
			})
			.nullable(),

		guaranteed_part: yup
			.number()
			.when('is_contract', {
				is: true,
				then: yup.number().required(t('guaranteed_part_is_required')).nullable(),
			})
			.nullable(),
		// type: yup.object().required('Выберите тип проекта').nullable(),
	})

	const dispatch = useDispatch()

	const projectShow = useProjectShow()
	const project = projectShow?.project
	const users = project?.users ?? []
	const [updateProject, projectResult] = useUpdateProjectMutation()
	const [deleteProjectUser] = useDeleteProjectUserMutation()
	const [createUserrProject] = useCreateUserProjectMutation()
	const [deleteHashtag] = useDeleteProjectHashtagMutation()
	const [createFileProjectMutation] = useCreateFileProjectMutation()
	const [deleteFileProjectMutation] = useDeleteFileProjectMutation()
	const [createProjectHashtagLink] = useCreateProjectHashtagLinkMutation()


	const { data: projectTeams } = useGetTeamProjectsQuery({ project_id: project.id, per_page: 999 })

	const {
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		control,
		formState: { errors },
		watch,
		getValues,
	} = useForm({
		defaultValues: {
			users: [],
			teams: [],
			team: null,
			type: null,
			author: null,
			hashtags: [],
			deleteUsers: [],
			deleteTeams: [],
			deleteHashtags: [],

			each_member_can_create_task: false,
			with_administration: false,

			curator: null,
			motivation: null,

			sanction_per_day: null,
			sanction_per_day_type: 'ruble',

			guaranteed_part: null,
			guaranteed_part_type: 'percent',

			period_is_not_subject_authorization: null,

			is_contract: false,
			contract_file: [],
			кpg_file: [],

			is_change_curator: false,
			old_curator: null,
			old_motivation: null,
			old_guaranteed_part: null,
			old_amount_paid: null,
			old_percentage_completion: null
		},
		resolver: yupResolver(schema),
	})

	const motivation = watch('motivation')
	const oldMotivation = watch("old_motivation")
	const oldGuaranteedPart = watch("old_guaranteed_part")
	const type = watch('type')
	const isChangeCurator = watch('is_change_curator')
	const isContract = type?.name === 'contract'
	const isCBD = type?.name === 'cbd_project'


	useEffect(() => {
		if (isChangeCurator) {
			const old_guaranteed_part = getValues("old_guaranteed_part")
			setValue('old_amount_paid', (+oldMotivation * +old_guaranteed_part) / 100)
		}
	}, [isChangeCurator, oldMotivation])
	
	useEffect(() => {
		if (isChangeCurator) {
			setValue('old_amount_paid', (+oldMotivation * +oldGuaranteedPart) / 100)
		}
	},[oldGuaranteedPart, oldMotivation, isChangeCurator])

	useEffect(() => {
		setValue('is_contract', isContract)
		setValue('with_administration', isContract || isCBD)
	}, [isContract, isCBD])

	useEffect(() => {
		const errorsValues = Object.entries(errors)

    if (errorsValues?.length > 0) {
			const error = errorsValues[0]
			const errorKey = error[0]
			
			if (errorKey) {
				const el = document.getElementById(`${errorKey}-edit-project`);

				if (el) {
					el?.scrollIntoView({ behavior: 'smooth' })
				}
			}
		
    }
	}, [errors])

	useEffect(() => {
		if (projectResult.isError) {
			dispatch(interfaceActions.setIsLoading(false))

			dispatch(
				showMessage({
					message: projectResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}, [projectResult])

	const teams = useMemo(() => projectTeams?.data?.map((item) => item?.command) ?? [], [projectTeams])

	const projectContractKpgFiles = project?.contract_kpg?.map(file => ({ name: file?.file?.original_name, data: file, id: file?.file_id }))  ?? []
	const projectContractDocFiles = project?.contract_doc?.map(file => ({ name: file?.file?.original_name, data: file, id: file?.file_id }))  ?? []

	useEffect(() => {
		if (project) {
			setValue('name', project.name)
			setValue(
				'users',
				users.map((user) => user.user)
			)
			// setValue('teams', teams);
			setValue('team', project?.command)
			setValue('author', project?.owner)
			setValue('type', project?.type)
			setValue('hashtags', project?.hashtags ?? [])
			setValue('each_member_can_create_task', project?.each_member_can_create_task)
			setValue('with_administration', project?.with_administration)

			setValue('curator', project?.curator)

			setValue('motivation', project?.motivation)
			setValue('guaranteed_part', project?.guaranteed_part)
			// setValue('sanction_holiday', project?.sanction_holiday)
			setValue('sanction_per_day', project?.sanction_per_day)

			setValue('old_curator', project?.curator)
			setValue('old_motivation', project?.motivation)
			setValue('old_guaranteed_part', project?.guaranteed_part)
		
			setValue('кpg_file',projectContractKpgFiles)
			setValue('contract_file',projectContractDocFiles)
		}
	}, [project, users, teams])

	const closeEdit = () => {
		dispatch(interfaceActions.setIsLoading(false))
		setEdit(false)
	}

	const updateProjectHandler = async (data) => {
		// if(isContract && isChangeCurator && data.old_guaranteed_part > data.old_final_motivation) {
		// 	setError("old_final_motivation", {message: t('final_motivation_lees_guaranteed_part')})

		// 	return
		// } else {
		// 	clearErrors("old_final_motivation")
		// }


		dispatch(interfaceActions.setIsLoading(true))

		const projectId = project.id

		let contractFileId
		let кpgFileId

		if (isContract) {
			const newKpgFiles = data?.кpg_file ?? []
			const oldKpgFiles = projectContractKpgFiles

			const createKpgFiles = newKpgFiles.filter((newKpgFile) => !oldKpgFiles.find((oldKpgFile) => newKpgFile?.id === oldKpgFile?.id))
			const deleteKpgFiles = oldKpgFiles.filter((oldKpgFile) => !newKpgFiles.find((newKpgFile) => newKpgFile?.id === oldKpgFile?.id))

			await Promise.all(
				createKpgFiles.map(async (file) => {
					const createdFile = await fileUpload(file?.data, 'file/', false, 'doc')
					const createdFileId = createdFile?.data?.id
					await createFileProjectMutation({
						file_id: createdFileId, project_id: projectId, type: "kpg"
					})
				})
			)
	
			await Promise.all(
				deleteKpgFiles.map(async (file) => {
					await deleteFileProjectMutation({
						file_id: file?.id, project_id: projectId
					})
				})
			)


			const newContractFiles = data?.contract_file ?? []
			const oldContractFiles = projectContractDocFiles

			const createContractFiles = newContractFiles.filter((newKpgFile) => !oldContractFiles.find((oldKpgFile) => newKpgFile?.id === oldKpgFile?.id))
			const deleteContractFiles = oldContractFiles.filter((oldKpgFile) => !newContractFiles.find((newKpgFile) => newKpgFile?.id === oldKpgFile?.id))
			
			await Promise.all(
				createContractFiles.map(async (file) => {
					const createdFile = await fileUpload(file?.data, 'file/', false, 'doc')
					const createdFileId = createdFile?.data?.id

					await createFileProjectMutation({
						file_id: createdFileId, project_id: projectId, type: "doc"
					})
			
				})
			)
	
			await Promise.all(
				deleteContractFiles.map(async (file) => {
					await deleteFileProjectMutation({
						file_id: file?.id, project_id: projectId
					})
				})
			)
		}

		const body = {
			body: {
				contract_doc: isContract ? contractFileId : undefined,
				contract_kpg: isContract ? кpgFileId : undefined,
				curator_id: isContract ? data?.old_curator?.id === data?.curator?.id ?  undefined : data?.curator?.id : undefined,
				guaranteed_part: isContract ? +data.guaranteed_part : undefined,
				motivation: isContract ? +data.motivation : undefined,
				// sanction_holiday: isContract ? +data.sanction_holiday : undefined,
				sanction_per_day: isContract ? +data.sanction_per_day : undefined,

				name: data.name,
				command_id: data?.team?.id ?? null,
				type_id: data?.type?.id ?? null,
				owner_id: data?.author?.id ?? null,
				each_member_can_create_task: isContract ? false : data?.each_member_can_create_task,
				need_confirmation_for_create: data.need_confirmation_for_create,
				with_administration: data.with_administration,
				// has_assimilation: data.has_assimilation,
				// has_contract: data.has_contract,
				// has_development_plan: data.has_development_plan,

				work_percent: data?.is_change_curator ? data?.old_percentage_completion : undefined,
				accrued_part: data?.is_change_curator ? data?.old_amount_paid : undefined,
			},
			id: projectId,
		}

		await updateProject(body)

		const newUsers = data.users.map((item) => item.id) ?? []
		const oldUsers = users.map((item) => item?.user_id) ?? []

		const createUsers = newUsers.filter((newUserId) => !oldUsers.find((oldUserId) => oldUserId === newUserId))
		const deleteUsers = oldUsers.filter((oldUserId) => !newUsers.find((newUserId) => newUserId === oldUserId))

		await Promise.all(
			createUsers.map(async (userId) => {
				await createUserrProject({
					project_id: projectId,
					user_id: userId,
				})
			})
		)

		await Promise.all(
			deleteUsers.map(async (userId) => {
				await deleteProjectUser({
					project_id: projectId,
					user_id: userId,
				})
			})
		)

		// const newTeams = data.teams.map(item => item.id) ?? [];
		// const oldTeams = teams.map((item) => item?.id) ?? [];
		//
		// const createTeams = newTeams.filter(
		//     (newUserId) => !oldTeams.find((oldUserId) => oldUserId === newUserId),
		// );
		// const deleteTeams = oldTeams.filter(
		//     (oldUserId) => !newTeams.find((newUserId) => newUserId === oldUserId),
		// );
		//
		//
		// await Promise.all(
		//     createTeams.map(async (teamId) => {
		//         await createTeamProject({
		//             project_id: projectId,
		//             command_id: teamId,
		//         });
		//     }),
		// );
		//
		//
		// await Promise.all(
		//     deleteTeams.map(async (teamId) => {
		//         await deleteTeamProject({
		//             project_id: projectId,
		//             command_id: teamId,
		//         });
		//     }),
		// );

		const newTags = data.hashtags.map((item) => item.id) ?? []
		const oldTags = project?.hashtags.map((item) => item?.id) ?? []

		const createTags = newTags.filter((newUserId) => !oldTags.find((oldUserId) => oldUserId === newUserId))
		const deleteTags = oldTags.filter((oldUserId) => !newTags.find((newUserId) => newUserId === oldUserId))

		await Promise.all(
			createTags.map(async (hashtagID) => {
				await createProjectHashtagLink({ id: project.id, hashtagId: hashtagID })
			})
		)

		await Promise.all(
			deleteTags.map(async (hashtagId) => {
				await deleteHashtag({ project_id: project.id, hashtag_id: hashtagId })
			})
		)

		const newKpgFilesId = data?.kpgFiles ?? []
		const oldKpgFilesId = project?.contract_kpg ?? []
		

		// dispatch(projectsActions.updateProject({...body.body, id: projectId}));

		dispatch(
			showMessage({
				message: t('successfully_updated.project'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		closeEdit()
	}

	if (!project) return null

	return (
		<form className="tt-h-full" onSubmit={handleSubmit(updateProjectHandler)}>
			<div className="tt-h-full">
				<div className={clsx(isSideBar && 'tt-px-24', 'tt-mb-20 tt-grid tt-grid-cols-6 tt-gap-8 tt-py-16 ')}>
					<InputContainer label={t('title')} className="tt-col-span-6" error={errors?.name}>
						<Controller
							render={({ field: { value, onChange,name } }) => (
								<TextField
									id={`${name}-edit-project`}
									value={value}
									onChange={onChange}
									error={!!errors.name}
									hiddenLabel
									placeholder={t('title')}
									className="dialig-input"
									size="small"
								/>
							)}
							name="name"
							control={control}
						/>
					</InputContainer>

					{project?.id && (
						<InputContainer label={t('participants')} className="tt-col-span-3" error={errors?.users}>
							<Controller
								render={({ field: { value, onChange, name } }) => (
									<UsersChoice
										id={`${name}-edit-project`}
										placeholder={t('participants')} value={value} onChange={onChange} />
								)}
								name="users"
								control={control}
							/>
						</InputContainer>
					)}

					<InputContainer className="tt-col-span-3" label={t('team')} error={errors?.team}>
						<Controller
							render={({ field: { value, onChange, name } }) => (
								<CommandsChoice
								id={`${name}-edit-project`}
								 placeholder={t('team')} multiple={false} value={value} onChange={onChange} />
							)}
							name="team"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('project_type')} className="tt-col-span-3" error={errors?.type}>
						<Controller
							render={({ field: { value, onChange, name } }) => (
								<ProjectTypes
									id={`${name}-edit-project`}
									placeholder={t('project_type')} disabled value={value} onChange={onChange} />
							)}
							name="type"
							control={control}
						/>
					</InputContainer>

					<InputContainer className="tt-col-span-3" label={t('author')} error={errors?.author}>
						<Controller
							render={({ field: { value, onChange, name } }) => (
								<UsersChoice
									id={`${name}-edit-project`}
									placeholder={t('author')} value={value} multiple={false} onChange={onChange} />
							)}
							name="author"
							control={control}
						/>
					</InputContainer>

					{project?.id && (
						<InputContainer className="tt-col-span-6" label={t('tags')} error={errors?.hashtags}>
							<Controller
								render={({ field: { value, onChange, id } }) => (
									<ProjectTagsChoice
										id={`${name}-edit-project`}
										placeholder={t('tags')} onChange={onChange} value={value} />
								)}
								name={t('hashtags')}
								control={control}
							/>
						</InputContainer>
					)}

					{projectShow?.isContract && (
						<div className="tt-col-span-6 tt-z-10 tt-bg-gray-100/50 tt-border tt-border-gray-200/50 tt-p-12 tt-rounded-lg">
							<span className="tt-font-medium tt-text-gray-500">{t('project_type_contract')}</span>
							<div className="tt-grid tt-grid-cols-6 tt-gap-8 tt-mt-12">
								<InputContainer label={t('dsm')} className="tt-col-span-3" error={errors?.curator}>
									<Controller
										render={({ field: { value, onChange, name} }) => (
											<UsersChoice
												id={`${name}-edit-project`}
												placeholder={t('dsm')}
												multiple={false}
												value={value}
												onChange={(value) => {
													onChange(value)

													if(value && value?.id !== project?.curator?.id) {
														setValue("is_change_curator", true)
													} else {
														setValue("is_change_curator", false)
													}
											}} />
										)}
										name="curator"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('dsm_motivation')} className="tt-col-span-3" error={errors?.motivation}>
									<Controller
										render={({ field: { value, onChange, id } }) => (
											<TextField
											id={`${name}-edit-project`}
												value={Number(value).toLocaleString('ru')}
												onChange={(event) =>
													{
														let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
														if (enteredValue?.length > 0 && enteredValue[0] === '0') {
																enteredValue = enteredValue.slice(1)
														}
														onChange(enteredValue ? enteredValue : null)
													}
												}
												inputProps={{ maxLength: 11 }}
												error={!!errors.motivation}
												hiddenLabel
												placeholder={t('rate')}
												className="dialig-input"
												size="small"
												InputProps={{
													startAdornment: <InputAdornment position="start">₽</InputAdornment>,
												}}
											/>
										)}
										name="motivation"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('contract_file')} className="tt-col-span-3" error={errors?.contract_file}>
									<Controller
										render={({ field: { value, onChange, name } }) =>
											<FileInput
												id={`${name}-edit-project`}					
												onChange={onChange}
												value={value}
											/>
										}
										name="contract_file"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('cpg_file')} className="tt-col-span-3" error={errors?.кpg_file}>
									<Controller
										render={({ field: { value, onChange, name } }) =>
											<FileInput
												id={`${name}-edit-project`}					
												onChange={onChange}
												value={value}
											/>
										}
										name="кpg_file"
										control={control}
									/>
								</InputContainer>

								<InputContainer label={t('guaranteed_part')} className="tt-col-span-6" error={errors?.guaranteed_part}>
									<Controller
										render={({ field: { value, onChange, name } }) => (
											<div>
												<TextField
														id={`${name}-edit-project`}
													value={value}
													onChange={(event) => {
														if (+event.target.value > 100) return

														const val = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
														onChange(val ? val : null)
													}}
													inputProps={{ maxLength: 3 }}
													error={!!errors?.guaranteed_part}
													hiddenLabel
													placeholder={t('percent')}
													className="dialig-input tt-w-full"
													size="small"
													InputProps={{
														startAdornment: <InputAdornment position="start">%</InputAdornment>,
													}}
												/>
												{motivation && value && (
													<span className="tt-mt-4">
														{t('received')}  {((+motivation * +value) / 100).toLocaleString('ru')} ₽
													</span>
												)}
											</div>
										)}
										name="guaranteed_part"
										control={control}
									/>
								</InputContainer>
								<InputContainer
									label={t('sanction_per_day')}
									className="tt-col-span-6"
									error={errors?.sanction_per_day}
								>
									<Controller
										render={({ field: { value, onChange, name } }) => (
											<div className="tt-flex tt-gap-8 tt-w-full">
												<TextField
														id={`${name}-edit-project`}
													value={value}
													onChange={(event) =>
														onChange(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
													}
													disabled
													inputProps={{ maxLength: 9 }}
													error={!!errors?.sanction_per_day}
													hiddenLabel
													placeholder={t('sanction_value')}
													className="dialig-input tt-w-full"
													size="small"
													InputProps={{
														startAdornment: <InputAdornment position="start">₽</InputAdornment>,
													}}
												/>
											</div>
										)}
										name="sanction_per_day"
										control={control}
									/>
								</InputContainer>
								{/* <InputContainer
									label={t('non_sunction_period')}
									className="tt-col-span-6"
									error={errors?.sanction_holiday}
								>
									<Controller
										render={({ field: { value, onChange } }) => (
											<TextField
												value={value}
												onChange={(event) =>
													onChange(event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
												}
												inputProps={{ maxLength: 9 }}
												error={!!errors.sanction_holiday}
												hiddenLabel
												placeholder={t('amount_of_days')}
												className="dialig-input"
												size="small"
											/>
										)}
										name="sanction_holiday"
										control={control}
									/>
								</InputContainer> */}
							</div>
							{isChangeCurator &&
								<div className="tt-pt-12 tt-border-t tt-border-gray-300 tt-grid tt-grid-cols-6 tt-gap-8 tt-mt-10">
									<div className="tt-col-span-6 tt-text-gray-500">
										{t('settings_previous_dsm_project')}
									</div>
									<InputContainer label={t('dsm')} className="tt-col-span-3" error={errors?.old_curator}>
										<Controller
											render={({ field: { value, onChange, name } }) => (
												<UsersChoice 	id={`${name}-edit-project`} disabled placeholder={t('dsm')} multiple={false} value={value} onChange={onChange} />
											)}
											name="old_curator"
											control={control}
										/>
									</InputContainer>
									<InputContainer label={t('dsm_motivation')} className="tt-col-span-3" error={errors?.old_motivation}>
										<Controller
											render={({ field: { value, onChange, name } }) => (
												<TextField
													disabled
													id={`${name}-edit-project`}
													value={Number(value).toLocaleString('ru')}
													onChange={(event) =>
													{
															let enteredValue = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
															if (enteredValue?.length > 0 && enteredValue[0] === '0') {
																	enteredValue = enteredValue.slice(1)
															}
															onChange(enteredValue ? enteredValue : null)
													}
													}
													inputProps={{ maxLength: 11 }}
													error={!!errors.old_motivation}
													hiddenLabel
													placeholder={t('rate')}
													className="dialig-input"
													size="small"
													InputProps={{
														startAdornment: <InputAdornment position="start">₽</InputAdornment>,
													}}
												/>
											)}
											name="old_motivation"
											control={control}
										/>
									</InputContainer>
									<InputContainer label={t('guaranteed_part')} className="tt-col-span-3" error={errors?.old_guaranteed_part}>
										<Controller
											render={({ field: { value, onChange, name } }) => (
												<div>
													<TextField
														id={`${name}-edit-project`}
														value={value}
														onChange={(event) => {
															if (+event.target.value > 100) return

															const val = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
															onChange(val ? val : null)
														}}
														inputProps={{ maxLength: 3 }}
														error={!!errors?.old_guaranteed_part}
														hiddenLabel
														placeholder={t('percent')}
														className="dialig-input tt-w-full"
														size="small"
														InputProps={{
															startAdornment: <InputAdornment position="start">%</InputAdornment>,
														}}
													/>
												</div>
											)}
											name="old_guaranteed_part"
											control={control}
										/>
									</InputContainer>
									<InputContainer label={t('amount_paid')} className="tt-col-span-3" error={errors?.old_amount_paid}>
										<Controller
											render={({ field: { value, onChange, name } }) => (
												<div>
													<TextField
														disabled
														id={`${name}-edit-project`}
														value={Number(value).toLocaleString('ru')}
														onChange={(event) => {
															if (+event.target.value > 100) return
															const val = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
															onChange(val ? val : null)
														}}
														error={!!errors?.old_amount_paid}
														hiddenLabel
														placeholder={t('percent')}
														className="dialig-input tt-w-full"
														size="small"
														InputProps={{
															startAdornment: <InputAdornment position="start">₽</InputAdornment>,
														}}
													/>
											
												</div>
											)}
											name="old_amount_paid"
											control={control}
										/>
									</InputContainer>
										<InputContainer label={t('percentage_completion')} className="tt-col-span-6" error={errors?.old_percentage_completion}>
											<Controller
												render={({ field: { value, onChange, name } }) => (
													<div>
														<TextField
															id={`${name}-edit-project`}
															value={value}
															onChange={(event) => {
																if (+event.target.value > 100) return
	
																const val = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
																onChange(val ? val : null)
															}}
															inputProps={{ maxLength: 3 }}
															error={!!errors?.old_percentage_completion}
															hiddenLabel
															placeholder={t('percent')}
															className="dialig-input tt-w-full"
															size="small"
															InputProps={{
																startAdornment: <InputAdornment position="start">%</InputAdornment>,
															}}
														/>
													</div>
												)}
												name="old_percentage_completion"
												control={control}
											/>
										</InputContainer>
								</div>
							}
						</div>
					)}

					{!isContract && (
						<Controller
							render={({ field: { value, onChange, name } }) => (
								<FormControlLabel
									className="tt-col-span-6 tt-z-10"
									id="each_member_can_create_task"
									control={<Checkbox checked={value} onChange={onChange} />}
									label={t('eack_participant_can_create_task')}
								/>
							)}
							name="each_member_can_create_task"
							control={control}
						/>
					)}

					<Controller
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								disabled={isContract || isCBD}
								className="tt-col-span-6 tt-z-10"
								id="with_administration"
								control={<Checkbox checked={value} onChange={onChange} />}
								label={t('administrated_project')}
							/>
						)}
						name="with_administration"
						control={control}
					/>
				</div>
			</div>
			<div className={clsx('tt-w-full tt-sticky tt-z-20  tt-bottom-0 ', !isSideBar && 'tt-translate-y-20')}>
				<div
					className={clsx(
						isSideBar && 'tt-px-24',
						'tt-w-full tt-max-w-5xl tt-mx-auto tt-flex tt-gap-12 tt-items-center  tt-py-24 tt-border-t tt-border-gray-200 tt-bg-gray-50'
					)}
				>
					<Button
						type="button"
						onClick={closeEdit}
						variant="outlined"
						className="tt-border-gray-200 tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('cancel')}
					</Button>
					<Button
						type="submit"
						variant="contained"
						className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('save')}
					</Button>
				</div>
			</div>
		</form>
	)
}

EditProject.displayName = 'EditProject'

export default EditProject
