import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const rolesAPI = createApi({
	reducerPath: 'roles-api',
	tagTypes: ['Role'],
	baseQuery,
	endpoints: (build) => ({
		getRoles: build.query({
			query: () => ({
				url: '/role/',
			}),
		}),
		getOneRoleName: build.query({
			query: (name) => ({
				url: `/role/${name}`,
			}),
		}),
		deleteRole: build.mutation({
			query: (name) => ({
				method: 'DELETE',
				url: `/role/${name}`,
				body: JSON.stringify({}),
			}),
		}),
		addUserToRole: build.mutation({
			query: (name, user) => ({
				method: 'POST',
				url: `/role/${name}/add-user/${user}`,
			}),
		}),
		addRuleToUser: build.mutation({
			query: (body) => ({
				method: 'POST',
				url: `/rule/`,
				body: JSON.stringify(body),
			}),
		}),
		addRuleToRole: build.mutation({
			query: (body, name) => ({
				method: 'POST',
				url: `/rule/${name}`,
				body: JSON.stringify(body),
			}),
		}),
	}),
})

export const {
	useGetRolesQuery,
	useGetOneRoleNameQuery,
	useDeleteRoleMutation,
	useAddUserToRoleMutation,
	useAddRuleToUserMutation,
	useAddRuleToRoleMutation,
} = rolesAPI
