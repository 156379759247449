import React from 'react'
import styles from './styles.module.scss'
import {ProjectCuratorsHistory} from 'src/@features/project'
import FiledRow from '../../../../shared/components/filed-row'
import { getProjectCuratorRole } from 'src/old-app/store/projectsSlice'
import { useSelector } from 'react-redux'
import { UserShow } from 'src/shared/components'
import moment from 'moment/moment'
import { Chip } from '@mui/material'
import { ProjectSubprojects, useProjectShow } from 'src/entities/project'
import StatusesProject from '../../../../old-app/components/dialogs/project/StatusesProject'
import PriorityProject from '../../../../old-app/components/dialogs/project/PriorityProject'
import ProjectStatus from '../project-status'
import ConfirmsTimeline from '../../../../shared/components/confirms-timeline'
import { useTranslation } from 'react-i18next'
import { formatter } from 'src/shared/lib/formatteer'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx'

interface IProps {
	isWrap?: boolean
	hideFields?: any
}

const ProjectInfo: React.FC<IProps> = ({ isWrap, hideFields }) => {
	const { t } = useTranslation()
	const projectShow = useProjectShow()
	const isCurator = useSelector(getProjectCuratorRole)
	const project = projectShow?.project

	const completeDate = moment(project?.complete_at)

	return (
		<div className={styles['project-info']}>
			<div>
				<div className={[styles['project-fields'], isWrap && styles['project-fields-wrap']].join(' ')}>
					<FiledRow label={t('project_title')} content={project?.name} />
					{project?.comment && (
						<FiledRow
							label={t('rejection_comment')}
							content={<p className="tt-text-gray-600 tt-w-full tt-break-all">{project?.comment}</p>}
						/>
					)}
					<FiledRow label={t('administrated_project')} content={project?.with_administration ? t('yes') : t('no')} />
					<FiledRow label={t('status')} content={<ProjectStatus status={project?.confirmation_status} />} />
					<FiledRow
						label={t('project_type')}
						content={
							project?.type?.name ? (
								<Chip
									sx={{ height: 24 }}
									className="tt-truncate tt-font-medium tt-inline-flex tt-text-12"
									label={t(`types.${project?.type?.name}`)}
								/>
							) : (
								<div className="tt-text-gray-400">-</div>
							)
						}
					/>
					<FiledRow label={t('project_slug')} content={project?.slug} />
					<FiledRow
						label={t('tags')}
						content={
							<div className="tt-grid tt-grid-cols-2 tt-gap-4">
								{project?.hashtags?.map((hashtag: any) => (
									<Chip
										key={hashtag.id}
										sx={{ height: 24 }}
										className="tt-truncate tt-inline-flex tt-text-12"
										label={hashtag.hashtag}
									/>
								))}
								{project?.hashtags?.length === 0 && <div className="tt-text-gray-400">-</div>}
							</div>
						}
					/>
					<FiledRow
						label={t('participant_can_create_tasks')}
						content={project?.each_member_can_create_task ? t('yes') : t('no')}
					/>
					<FiledRow
						label={t('team')}
						content={
							<div className="tt-grid tt-grid-cols-2 tt-gap-x-20 tt-gap-y-4">
								{project?.command && (
									<Chip
										key={project?.command?.id}
										sx={{ height: 24 }}
										className="tt-truncate tt-text-12"
										label={project?.command?.name}
									/>
								)}

								{!project?.command && <div className="tt-text-gray-400">-</div>}
							</div>
						}
					/>
					<FiledRow
						label={t('planned_completion_date')}
						content={completeDate.isValid() ? completeDate.format('LL') : t('no_project_completion_date')}
					/>
					{/*<FiledRow label="Участники" content={*/}
					{/*	<div className='grid grid-cols-2 gap-x-20 gap-y-4'>*/}
					{/*		{users?.map((user) => (*/}
					{/*			<UserShow key={user.id} user={user}/>*/}
					{/*		))}*/}
					{/*	</div>*/}
					{/*}/>*/}
					<FiledRow label={t('creation_date')} content={moment(project?.created_at).format('LL')} />
					<FiledRow label={t('responsible')} content={<UserShow user={project?.owner} />} />
					{project?.actual_deadline && (
						<FiledRow label={t('actual_completion_date')} content={moment(project?.actual_deadline).format('LL')} />
					)}
					{!hideFields?.priority && <FiledRow label={t('project_priorities')} content={<PriorityProject />} />}
					{!hideFields?.status && <FiledRow label={t('project_statuses')} content={<StatusesProject />} />}
					{project?.confirms?.length !== 0 && (
						<FiledRow
							label={t('confirmations_history')}
							content={<ConfirmsTimeline confirmations={project.confirms} />}
						/>
					)}
					<FiledRow
						label={t('subprojects_completed')}
						content={project?.child_projects_completed ? t('yes') : t('no')}
					/>
				</div>
				{projectShow?.isContract && (projectShow.isManageProject || isCurator) && (
					<>
						<Accordion style={{boxShadow:"none"}} defaultExpanded className="tt-col-span-6 !tt-p-0  tt-border tt-mt-12 tt-bg-gray-100/50 tt-border tt-border-gray-200/50 tt-p-12 tt-rounded-lg">
							<AccordionSummary 	style={{minHeight: "10px", maxHeight: "40px" }} expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content" id="panel1-header">
								<span className="tt-font-medium tt-text-gray-500">{t('project_type_contract')}</span>
							</AccordionSummary>
							<AccordionDetails className="tt-border-t tt-border-gray-200">
								<div className="tt-grid tt-grid-cols-6 tt-gap-8 tt-mt-12">
									<FiledRow
										className="tt-col-span-6 tt-truncate"
										label={t('dsm')}
										content={<UserShow user={project?.curator}/>}
									/>
									<FiledRow
										className="tt-col-span-3"
										label={t('dsm_motivation')}
										content={<div>₽ {project?.motivation.toLocaleString('ru') ?? 0}</div>}
									/>

									<FiledRow
										className="tt-col-span-3"
										label={t('sanction_per_day')}
										content={<div>₽ {formatter(project?.sanction_per_day ?? 0)}</div>}
									/>

									<FiledRow
										className="tt-col-span-3"
										label={t('guaranteed_part')}
										content={
											<div>
												{project?.guaranteed_part ?? 0} %{' '}
												{project?.guaranteed_part && project?.motivation && (
													<>(₽ {((project?.motivation * project?.guaranteed_part) / 100).toLocaleString('ru')})</>
												)}
											</div>
										}
									/>
									{/* <FiledRow
								className="tt-col-span-3"
								label={t('non_sunction_period')}
								content={<div>{formatter(project?.sanction_holiday ?? 0)}</div>}
							/> */}
									<FiledRow
										className={clsx("tt-col-span-6", (!project?.contract_doc || project?.contract_doc?.length < 0) ? "": "tt-flex-col tt-gap-8")}
										label={t('contract_file')}
										content={
											<div>
													<div className="tt-flex tt-gap-6 tt-flex-wrap">
														{project?.contract_doc?.map(({ file }: any) =>
																<a href={file?.link} className='tt-truncate'>
																	<div
																		className="tt-text-12 tt-truncate tt-font-medium  tt-px-8 tt-py-6 tt-bg-gray-200 tt-rounded-md tt-flex tt-items-center tt-gap-4">
																		{file?.original_name ?? t('no_attached_file')}
																	</div>
																</a>
														)}
														{(!project?.contract_doc || project?.contract_doc?.length < 0) &&  <span className="tt-text-gray-500 tt-text-14">{t('no_attached_file')}</span>}
													</div>
											</div>
										}
									/>
									<FiledRow
										className={clsx("tt-col-span-6", (!project?.contract_kpg || project?.contract_kpg?.length < 0) ? "": "tt-flex-col tt-gap-8")}
										label={t('cpg_file')}
										content={
											<div>
												<div className="tt-flex tt-gap-6 tt-flex-wrap">
													{project?.contract_kpg?.map(({ file }: any) =>
														<a href={file?.link} className='tt-truncate'>
															<div
																	className="tt-text-12 tt-truncate tt-font-medium  tt-px-8 tt-py-6 tt-bg-gray-200 tt-rounded-md tt-flex tt-items-center tt-gap-4">
																	{file?.original_name ?? t('no_attached_file')}
																</div>
														</a>
													)}
													{(!project?.contract_kpg || project?.contract_kpg?.length < 0) && <span className="tt-text-gray-500 tt-text-14">{t('no_attached_file')}</span>}
												</div>
											</div>
										}
									/>
								</div>
							</AccordionDetails>
						</Accordion>
						<Accordion style={{boxShadow:"none"}} defaultExpanded={false} className="tt-col-span-6 !tt-p-0  tt-border tt-mt-12 tt-bg-gray-100/50 tt-border tt-border-gray-200/50 tt-p-12 tt-rounded-lg">
							<AccordionSummary
								style={{minHeight: "10px", maxHeight: "40px" }}
								expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content" id="panel1-header">
								<span className="tt-font-medium  tt-text-gray-500">{t('curators_history')}</span>
							</AccordionSummary>
							<AccordionDetails className="tt-border-t tt-border-gray-200">
								<ProjectCuratorsHistory curatorsHistory={project?.project_log ?? []}/>
							</AccordionDetails>
						</Accordion>
					</>
				)}
			</div>
		</div>
	)
}

export default ProjectInfo
