import { getPriorities, getStatuses, tasksActions } from 'src/old-app/store/tasksSlice'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import AddIcon from '@mui/icons-material/Add'
import { getPrioritys, getProjects, projectsActions } from 'src/old-app/store/projectsSlice'
import { useDeletePriorityTaskMutation, useGetPriorityQuery } from 'src/old-app/service/Projects'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getID } from '../../../../shared/config/fields-ids'
import { useProjectShow } from '../../../../entities/project'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'

const PriorityProject = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const priorities = useSelector(getPrioritys)
	const projectShow = useProjectShow()
	// const project = useSelector(getProjects);
	const [deletePriority] = useDeletePriorityTaskMutation()

	const editHandler = (item) => {
		dispatch(projectsActions.getPriority(item))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('edit-priority'))
	}
	const deleteHandler = async (item) => {
		dispatch(interfaceActions.setIsLoading(true))
		await deletePriority(item.id)
		dispatch(projectsActions.deletePriority(item.id))
		dispatch(interfaceActions.setIsLoading(false))
		// dispatch(projectsActions.getPriority(item))
		// dispatch(dialogActions.setDialog(true))
		// dispatch(dialogActions.setComponent('edit-priority'))
	}

	const createHandler = (item) => {
		dispatch(tasksActions.getStatus(item))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('create-priority'))
	}

	return (
		<div>
			{priorities.length === 0 ? (
				<div className="tt-text-gray-400 tt-mb-8">{t('empty_priorities_list')}</div>
			) : (
				<div className="tt-flex tt-flex-col tt-gap-4 group/item tt-mb-8">
					{priorities.map((item, index) => (
						<div key={item?.id} className="tt-flex tt-gap-8  tt-items-center ">
							<div className="tt-w-12 tt-h-12 tt-rounded-full" style={{ background: item.color }} />
							<div className="tt-flex tt-flex-col">
								<span className="tt-font-medium tt-truncate tt-max-w-[200px]">{item.name}</span>
								<span className="tt-text-gray-500 tt-font-regular tt-text-12">
									{`${t('level').toLowerCase()}: ${item.level}`}
								</span>
							</div>
							{projectShow?.isManageProject && !projectShow?.project?.isEnd && (
								<div className="tt-scale-100 group-hover/item:tt-scale-100  tt-duration-300 tt-flex tt-items-center tt-gap-4">
									<EditOutlinedIcon
										id={getID({
											place: 'modal',
											entity: 'project',
											action: 'edit',
											field: 'priority',
											type: 'button_edit',
											index,
										})}
										onClick={() => editHandler(item)}
										className="tt-text-gray-500 hover:tt-text-gray-800 tt-cursor-pointer tt-w-20 tt-h-20"
									/>
									<ConfirmDelete
										text={t('delete_priority_dialog.are_you_shure')}
										onSuccess={() => deleteHandler(item)}
										title={t('delete_priority_dialog.header')}
									>
										<DeleteIcon
											id={getID({
												place: 'modal',
												entity: 'project',
												action: 'edit',
												field: 'priority',
												type: 'button_delete',
												index,
											})}
											className=" tt-text-gray-500 hover:tt-text-gray-800 tt-cursor-pointer tt-w-20 tt-h-20"
										/>
									</ConfirmDelete>
								</div>
							)}
						</div>
					))}
				</div>
			)}
			{projectShow?.isManageProject && !projectShow?.project?.isEnd && (
				<button
					onClick={createHandler}
					className="tt-text-primary-500 hover:tt-text-primary-600 tt-duration-200 tt-mt-4 tt-flex tt-items-center tt-gap-6 tt-rounded-full  tt-h-28"
				>
					<AddIcon
						id={getID({
							place: 'modal',
							entity: 'project',
							action: 'edit',
							field: 'priority',
							type: 'button_add',
						})}
						className="tt-w-20 tt-h-20"
					/>
					{t('add_priority')}
				</button>
			)}
		</div>
	)
}

export default PriorityProject
