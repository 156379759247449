export const BASE_URL = process.env.REACT_APP_API_URL
export const COMMITTEES_API_URL = process.env.REACT_APP_COMMITTEES_API_URL
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL
export const NOVU_IDENTIFIER = process.env.REACT_APP_NOVU_IDENTIFIER
export const NOVU_SOCKET_URL = process.env.REACT_APP_NOVU_SOCKET_URL
export const NOVU_BACKEND_URL = process.env.REACT_APP_NOVU_BACKEND_URL
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

export const VAPID_PUBLIC = ''
export const TOKEN = 'ucpToken'
export const TASK_UPDATE = 10000
export const AUTH_TYPE = 'AUTH_TYPE'

export const IS_UCP = false
export const UCP_APP_NAME = 'track'
export const TOKEN_NOVU = 'widget_user_auth_token'

export const publicConst = 'public-page'
export const publicBoardUrl = `${publicConst}/project/:projectId/board`