import React from 'react'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import PriorityProject from './PriorityProject'
import StatusesProject from './StatusesProject'
import { useTranslation } from 'react-i18next'

const SettingProject = () => {
	const { t } = useTranslation()
	// const project = useSelector(getProject)
	return (
		<div>
			<div className="tt-w-600 tt-relative">
				<div>
					<DialogHeader title={t('project_settings')} />
					<DialogMain>
						<div className="tt-mb-16 tt-flex tt-flex-wrap tt-gap-24">
							<div className="tt-flex-1 tt-w-full">
								<StatusesProject />
							</div>
							<div className="tt-flex-1 tt-w-full">
								<PriorityProject />
							</div>
						</div>
					</DialogMain>
					{/* <DialogFooter /> */}
				</div>
			</div>
		</div>
	)
}

export default SettingProject
