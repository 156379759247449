import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import { useDeleteTaskMutation } from 'src/old-app/service/Tasks'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { getProject, getProjectCuratorRole, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getID } from '../../../shared/config/fields-ids'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'
import { getUserRole } from 'src/old-app/store/userSlice'
import { useProjectShow } from 'src/entities/project'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

function ActionsMenu(props) {
	const { t } = useTranslation()
	const { taskId, backTo, id } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const task = useSelector(getTask)
	const projectShow = useProjectShow()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [deleteTask] = useDeleteTaskMutation()
	const isUser = useSelector(getUserRole)
	const isCurator = useSelector(getProjectCuratorRole)

	const isCommittee = projectShow?.isCommittee

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	function handleRemoveTask() {
		handleClose()
		deleteTask(task?.id)
		dispatch(tasksActions.deleteTask(task?.id))
		dispatch(scrumboardActions.deleeteOneListCard({ cardId: task.id, listId: task.project_status_id }))
		navigate(backTo)
	}

	const openDialog = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('create-template-task'))
		handleClose()
	}

	return (
		<div>
			<IconButton
				id={getID({ prefix: id, type: 'button_open' })}
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{task?.isMyTask && !task?.project?.isEnd && (
					<CommentedDisability
						optionDisabled={isCommittee}
						disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
					>
						<ConfirmDelete
							text={t('delete_task_dialog.are_you_shure')}
							onSuccess={handleRemoveTask}
							title={t('delete_task_dialog.header')}
						>
							<MenuItem
								disabled={task?.project?.isContract ? (isCurator ? true : isUser ? task?.isMyTask : false) : false}
								id={getID({ prefix: id, entity: 'task', type: 'button_delete' })}
							>
								<ListItemIcon className="tt-min-w-40">
									<FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
								</ListItemIcon>
								<ListItemText primary={t('delete_the.task')} />
							</MenuItem>
						</ConfirmDelete>
					</CommentedDisability>
				)}
				<CommentedDisability
					optionDisabled={isCommittee}
					disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
				>
					<MenuItem
						disabled={task?.project?.isContract ? (isCurator ? true : isUser ? true : false) : false}
						id={getID({ prefix: id, entity: 'task', fieldPostfix: 'template', type: 'button_create' })}
						onClick={openDialog}
					>
						<ListItemIcon className="tt-min-w-40">
							<FuseSvgIcon>heroicons-outline:template</FuseSvgIcon>
						</ListItemIcon>
						<ListItemText primary={t('save_task_as_template')} />
					</MenuItem>
				</CommentedDisability>
			</Menu>
		</div>
	)
}

export default ActionsMenu
