import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { dialogReducer } from 'src/old-app/store/dialogSlice'
import { interfaceReducer } from 'src/old-app/store/interfaceSlice'
import { meetingsReducer } from 'src/old-app/store/meetingsSlice'
import { modalReducer } from 'src/old-app/store/modalSlice'
// import { myReducer } from 'src/old-app/store/mySlice'
import { notifsReducer } from 'src/old-app/store/notifSlice'
import { projectsReducer } from 'src/old-app/store/projectsSlice'
import { protocolReducer } from 'src/old-app/store/protocolSlice'
import { requestsReducer } from 'src/old-app/store/requestSlice'
import { scrumboardReducer } from 'src/old-app/store/scrumboardSlice'
import { tasksReducer } from 'src/old-app/store/tasksSlice'
import { usersReducer } from 'src/old-app/store/userSlice'
import { userReducer } from 'src/entities/user'
import { authAPI } from 'src/old-app/service/Auth'
import { commentAPI } from 'src/old-app/service/Comment'
import { fileAPI } from 'src/old-app/service/File'
import { meetingAPI } from 'src/old-app/service/Meeting'
import { notifAPI } from 'src/old-app/service/Notif'
import { projectsAPI } from 'src/old-app/service/Projects'
import { protocolAPI } from 'src/old-app/service/Protocol'
import { rolesAPI } from 'src/old-app/service/Role'
// import { sanctionAPI } from 'src/old-app/service/Sanction'
import { taskCompleteFileAPI } from 'src/old-app/service/TaskCompleteFile'
import { tasksAPI } from 'src/old-app/service/Tasks'
import { taskFileAPI } from 'src/old-app/service/TasksFile'
import { teamsAPI } from 'src/old-app/service/Teams'
import { usersAPI } from 'src/old-app/service/Users'
import fuse from 'src/old-app/store/fuse'
import { projectsTemplatesAPI } from '../../entities/projects-templates/api/projects-templates-api'
import { accessMiddleware } from '../../shared/middlewares'

export const setupStore = () => {
	const middlewares = [
		taskFileAPI.middleware,
		projectsAPI.middleware,
		usersAPI.middleware,
		tasksAPI.middleware,
		fileAPI.middleware,
		protocolAPI.middleware,
		notifAPI.middleware,
		meetingAPI.middleware,
		taskCompleteFileAPI.middleware,
		// sanctionAPI.middleware,
		commentAPI.middleware,
		teamsAPI.middleware,
		projectsTemplatesAPI.middleware,
		accessMiddleware,
	]

	return configureStore({
		reducer: {
			user: userReducer,
			fuse,
			projects: projectsReducer,
			scrumboard: scrumboardReducer,
			modal: modalReducer,
			dialog: dialogReducer,
			protocol: protocolReducer,
			users: usersReducer,
			tasks: tasksReducer,
			notifs: notifsReducer,
			requests: requestsReducer,
			meetings: meetingsReducer,
			interface: interfaceReducer,
			// my: myReducer,
			[projectsAPI.reducerPath]: projectsAPI.reducer,
			[notifAPI.reducerPath]: notifAPI.reducer,
			[tasksAPI.reducerPath]: tasksAPI.reducer,
			[usersAPI.reducerPath]: usersAPI.reducer,
			[fileAPI.reducerPath]: fileAPI.reducer,
			// [sanctionAPI.reducerPath]: sanctionAPI.reducer,
			[protocolAPI.reducerPath]: protocolAPI.reducer,
			[taskFileAPI.reducerPath]: taskFileAPI.reducer,
			[meetingAPI.reducerPath]: meetingAPI.reducer,
			[taskCompleteFileAPI.reducerPath]: taskCompleteFileAPI.reducer,
			[rolesAPI.reducerPath]: rolesAPI.reducer,
			[authAPI.reducerPath]: authAPI.reducer,
			[commentAPI.reducerPath]: commentAPI.reducer,
			[teamsAPI.reducerPath]: teamsAPI.reducer,
			[projectsTemplatesAPI.reducerPath]: projectsTemplatesAPI.reducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				immutableCheck: false,
				serializableCheck: false,
			}).concat(middlewares),
		// devTools: process.env.NODE_ENV === 'development',
		devTools: true,
	})
}

export const store = setupStore()
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>
