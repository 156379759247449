import { ITableRow, ITableState } from './types'
import { uniqueId } from 'lodash'

export const defaultTableState: ITableState = {
	pagination: {
		current_page: 0,
		per_page: 0,
		total_entries: 0,
	},
	search: '',
	sorts: [],
}

export const defaultTableRow: ITableRow = {
	id: uniqueId(),
	list: [
		{
			id: uniqueId(),
			label: 'Id',
			field: 'id',
			render: () => null,
		},
	],
}
