import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProtocolSideBarCheck from 'src/old-app/components/protocol/side/ProtocolSideBarCheck'
import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import ProtocolSideBar from 'src/old-app/components/protocol/side/ProtocolSideBar'
import TaskSideBar from 'src/old-app/components/task/TaskSideBar'
import TasksProvider from '../tasks/TasksProvider'
import ProtocolsTable from '../../../entities/protocol/components/protocols-table'
import { useTranslation } from 'react-i18next'

const Protocol = () => {
	const { t } = useTranslation()
	const { taskId } = useParams()
	const navigate = useNavigate()

	const onCreateHandler = () => {
		navigate('create')
	}

	return (
		<PageWithSideBar
			param="protocolId"
			pageLink="protocol"
			headerOnCreate={onCreateHandler}
			headerTitle={t('protocols')}
			buttonLabel={t('create_new.protocol')}
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			content={
				<ProtocolsTable />
				// <div>
				// 	<ProtocolFilters />
				// 	<ProtocolTable />
				// </div>
			}
			rightSidebarContent={
				<ProtocolSideBarCheck>
					{taskId ? (
						<TasksProvider>
							<TaskSideBar backTo="/protocol" />
						</TasksProvider>
					) : (
						<ProtocolSideBar />
					)}
				</ProtocolSideBarCheck>
			}
		/>
	)
}

export default Protocol
