import React, { ChangeEventHandler } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePagination } from '@mui/material'
import clsx from 'clsx'
import HeadCell from '../../../old-app/components/shared/table/HeadCell'
import BodyCell from '../../../old-app/components/shared/table/BodyCell'
import useNewSortTable from '../../../old-app/hooks/useNewSortTable'
import { getID } from '../../config/fields-ids'

export type SharedTableRows = {
	sortLabel?: string
	isSort?: boolean
	isLinkDisabled?: boolean
	id?: string
	label: string | number
	className?: string
	content: (arg: object, index: number) => any
}

type Props = {
	items: { id?: string }[]
	rowId: string
	tableRows: SharedTableRows[]
	totalEntries: number
	rowsPerPageOptions?: number[]
	rowClassName?: ((arg: object) => string) | string
	rowOnClick?: (arg: object, row: object) => void
}

type HandleChangeRowsPerPage = ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
type HandleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

function getToggledSortDirection(direction: string): string {
	return direction === 'asc' ? 'desc' : 'asc'
}

function SharedTable(props: Props): JSX.Element {
	const { query, setQuery } = useNewSortTable()

	// const { search } = useLocation()

	const getSortName = (name: string) => {
		const isCurrField = name === query.field
		const _sort = isCurrField ? getToggledSortDirection(query.sort) : query.sort
		setQuery({ field: name, sort: _sort })
	}

	const handleChangePage: HandleChangePage = (_, newPage) => {
		setQuery({ page: newPage + 1 })
	}

	const handleChangeRowsPerPage: HandleChangeRowsPerPage = (event) => {
		setQuery({
			per_page: parseInt(event.target.value, 10),
			page: 1,
		})
	}

	return (
		<div className="overflow-x-auto overflow-y-hidden h-full flex flex-col w-full">
			<TableContainer className="rounded-lg border-x flex-1 border-gray-200 w-full">
				<Table>
					<TableHead className="bg-white truncate">
						<TableRow>
							{props.tableRows?.map(({ id, isSort, label, sortLabel }, index) => {
								return (
									<HeadCell
										className={clsx({ 'cursor-pointer': isSort })}
										onClick={() => isSort && sortLabel && getSortName(sortLabel)}
										key={id || index}
										sort={sortLabel && query.field === sortLabel && query.sort}
										label={label}
									/>
								)
							})}
						</TableRow>
					</TableHead>

					<TableBody>
						{props.items.map((item, index) => {
							return (
								<TableRow
									id={getID({ prefix: props.rowId, type: 'row', index })}
									key={item?.id || index}
									className={clsx(
										'hover:bg-gray-100/50',
										typeof props.rowClassName === 'function' ? props.rowClassName(item) : props.rowClassName
									)}
								>
									{props?.tableRows.map((tableRow, cellIndex) => (
										<BodyCell
											onClick={() => (props?.rowOnClick ? props?.rowOnClick(item, tableRow) : null)}
											key={tableRow?.id || cellIndex}
											className={tableRow?.className}
										>
											{tableRow?.content(item, index)}
										</BodyCell>
									))}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				id={getID({ lib: 'table_pagination' })}
				component="div"
				className="custom-table-pagitiona"
				labelRowsPerPage="Проектов на странице:"
				showFirstButton
				showLastButton
				count={props.totalEntries}
				page={query.page - 1}
				rowsPerPageOptions={props.rowsPerPageOptions ?? [5, 10, 15, 20, 50]}
				onPageChange={handleChangePage}
				rowsPerPage={query.per_page}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</div>
	)
}

SharedTable.displayName = 'SharedTable'
export default SharedTable
