import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import clsx from 'clsx'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import FusePageSimpleSidebarContent from './FusePageSimpleSidebarContent'

const FusePageSimpleSidebar = forwardRef((props, ref) => {
	const { open, position, variant, rootRef } = props

	const [isOpen, setIsOpen] = useState(open)

	useImperativeHandle(ref, () => ({
		toggleSidebar: handleToggleDrawer,
	}))

	const handleToggleDrawer = useCallback((val) => {
		setIsOpen(val)
	}, [])

	useEffect(() => {
		handleToggleDrawer(open)
	}, [handleToggleDrawer, open])

	return (
		<>
			{/*<Hidden lgUp={variant === 'permanent'}>*/}
			{/*  <SwipeableDrawer*/}
			{/*    variant="temporary"*/}
			{/*    anchor={position}*/}
			{/*    open={isOpen}*/}
			{/*    onOpen={(ev) => {}}*/}
			{/*    onClose={() => props?.onClose()}*/}
			{/*    disableSwipeToOpen*/}
			{/*    classes={{*/}
			{/*      root: clsx('FusePageSimple-sidebarWrapper', variant),*/}
			{/*      paper: clsx(*/}
			{/*        'FusePageSimple-sidebar ',*/}
			{/*        variant,*/}
			{/*        position === 'left' ? 'FusePageSimple-leftSidebar' : 'FusePageSimple-rightSidebar'*/}
			{/*      ),*/}
			{/*    }}*/}
			{/*    ModalProps={{*/}
			{/*      keepMounted: true, // Better open performance on mobile.*/}
			{/*    }}*/}
			{/*    // container={rootRef.current}*/}
			{/*    BackdropProps={{*/}
			{/*      classes: {*/}
			{/*        root: 'FusePageSimple-backdrop',*/}
			{/*      },*/}
			{/*    }}*/}
			{/*    style={{ position: 'absolute' }}*/}
			{/*    container={document.getElementById('track-root')}*/}
			{/*  >*/}
			{/*    <FusePageSimpleSidebarContent {...props} />*/}
			{/*  </SwipeableDrawer>*/}
			{/*</Hidden>*/}

			{variant === 'permanent' && (
				// <Hidden lgDown>
				<Drawer
					variant="permanent"
					anchor={position}
					className={clsx(
						'FusePageSimple-sidebarWrapper',
						variant,
						isOpen ? 'opened' : 'closed',
						position === 'left' ? 'FusePageSimple-leftSidebar' : 'FusePageSimple-rightSidebar',
						'tt-right-0'
					)}
					open={isOpen}
					onClose={props?.onClose}
					classes={{
						paper: clsx('FusePageSimple-sidebar tt-border-0', variant),
					}}
				>
					<FusePageSimpleSidebarContent {...props} />
				</Drawer>
				// </Hidden>
			)}
		</>
	)
})

FusePageSimpleSidebar.defaultProps = {
	open: true,
}

export default FusePageSimpleSidebar
