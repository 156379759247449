const rolesType = {
	ADMIN: 'ADMIN',
	ADMIN_PROJECT_CONTRACT: 'ADMIN_PROJECT_CONTRACT',
	CURATOR: 'CURATOR',
	USER: 'USER',
	BA: 'BA',
	DSM: 'DSM',
	PROJECT_MANAGER: 'PROJECT_MANAGER',
}

export default rolesType
