export const notifTypes = {
	CREATE_PROTOCOL: 'CREATE_PROTOCOL', // Уведомления о добавлении Протокола совещания;
	CREATE_PROJECT: 'CREATE_PROJECT', // Уведомления о добавлении Проекта;
	VIOLATIONS_BY_THOSE_RESPONSIBLE: 'VIOLATIONS_BY_THOSE_RESPONSIBLE', // Уведомления о выявленных нарушениях ответственными за выполнение задач.
	CONFIRMATION_OF_THE_REPORT: 'CONFIRMATION_OF_THE_REPORT', // Уведомления о запросах на подтверждение отчета о выполнении задачи;
	ACCRUAL_OF_SANCTIONS: 'ACCRUAL_OF_SANCTIONS', // Уведомления о начислении санкций за невыполнение задач по проекту или Протоколу совещания.
	POSTPONE_THE_DEADLINE: 'POSTPONE_THE_DEADLINE', // Уведомления о необходимости переноса срока и написания причин отклонений (при отсутствии отчетных документов по задаче на 1 р.д., следующий за отчетным периодом);
	USER_MENTIONS: 'USER_MENTIONS', // Упоминания пользователя в карточке задачи
	APPOINTMENT_RESPONSIBLE: 'APPOINTMENT_RESPONSIBLE', // Уведомления о назначении ответственным по задачам проекта.
	PUT_DOWN_DEADLINES: 'PUT_DOWN_DEADLINES', // Уведомления о необходимости проставить сроки для задачи, по которой пользователь является ответственным;
	DEADLINE_FOR_COMPLETING_TASKS: 'DEADLINE_FOR_COMPLETING_TASKS', // Уведомления о наступлении срока выполнения задач ответственным (необходимости загрузки документа по конкретной задаче);
	CHANGING_THE_TASK_STATUS: 'CHANGING_THE_TASK_STATUS',
	CONFIRMED_TASK_STATUS_IS_FINAL: 'CONFIRMED_TASK_STATUS_IS_FINAL',
	CONFIRM_CREATE_PROJECT: 'CONFIRM_CREATE_PROJECT',
	UPDATING_TASK_DEADLINE: 'UPDATING_TASK_DEADLINE',
	DECLINE_PROJECT: 'DECLINE_NEW_PROJECT',
	DECLINED_TASK_STATUS_IS_FINAL: 'DECLINED_TASK_STATUS_IS_FINAL',
	CONFIRMED_TASK_DEADLINE: 'CONFIRMED_TASK_DEADLINE',
	DECLINE_TASK_DEADLINE: 'DECLINE_TASK_DEADLINE',
	REFERENCED_IN_THE_TASK: 'REFERENCED_IN_THE_TASK',
	YOU_IS_RESPONSIBLE: 'YOU_IS_RESPONSIBLE',
	DEADLINE_FOR_TASK_SETED: 'DEADLINE_FOR_TASK_SETED',
	NEW_COMMAND_MEMBER: 'NEW_COMMAND_MEMBER',
	YOU_ADDED_TO_COMMAND: 'YOU_ADDED_TO_COMMAND',
	REMOVE_COMMAND_MEMBER: 'REMOVE_COMMAND_MEMBER',
}
