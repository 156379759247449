import { type PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Session, User } from './types'

interface SessionSliceState {
	isAuthorized: boolean
	token?: string
	user?: User
}

const initialState: SessionSliceState = {
	isAuthorized: false,
}

export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		clearSession: (state) => {
			state.token = undefined
			state.user = undefined
			state.isAuthorized = false
		},
		loginSession: (state, action: PayloadAction<Session>) => {
			state.token = action.payload.token
			state.user = action.payload.user
			state.isAuthorized = true
		},
	},
})

export const sessionSelectors = {
	user: (state: RootState) => state.session.user,
	userId: (state: RootState) => state.session.user?.id,
	isAuthorized: (state: RootState) => state.session.isAuthorized,
}
export const sessionActions = sessionSlice.actions
