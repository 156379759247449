import React, { useState } from 'react'
import styles from './styles.module.scss'
import { InputBase } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { getID } from '../../../config/fields-ids'
import FilterDeadline from '../../../../old-app/components/task/FilterDeadline'

interface IProps {
	field?: string
	placeholder?: string
}

const DateFilter: React.FC<IProps> = (p) => {
	const { field = 'date', placeholder = 'Дата' } = p

	return (
		<>
			<FilterDeadline
				id={getID({ prefix: '', field: 'deadline' })}
				// idStart={getID({prefix: _commonID, field: 'deadline_start' })}
				// idStart={getID({prefix: _commonID, field: 'deadline_end', fieldPostfix: '' })}
				label={placeholder}
				field={field}
				prefix={''}
			/>
		</>
	)
}

export default DateFilter
