import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { useLocation } from 'react-router-dom'
import RoleSelect from '../../shared/RoleSelect'
import { getProject } from 'src/old-app/store/projectsSlice'
import { useTranslation } from 'react-i18next'

const EditUserProject = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isDisabled, setIsDisabled] = useState(false)

	const project = useSelector(getProject)
	const loc = useLocation()

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			roles: [],
		},
	})

	const editHandler = (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		setIsDisabled(true)

		dispatch(interfaceActions.setIsLoading(false))
		dispatch(dialogActions.setDialog(false))
	}
	return (
		<form onSubmit={handleSubmit(editHandler)} className="tt-w-600">
			<DialogHeader title="EditUserProject" />
			<DialogMain>
				<Controller
					render={({ field: { value, onChange } }) => (
						<RoleSelect
							multiple
							errors={errors}
							displayEmpty
							field="roles"
							label={t('role')}
							onChange={onChange}
							value={value}
						/>
					)}
					name="roles"
					control={control}
				/>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} rightLabel={t('change')} />
		</form>
	)
}

export default EditUserProject
