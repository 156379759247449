import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CommentedDisability = ({ optionDisabled = false, disabilityComment = '', children }) => {
	const { t } = useTranslation()

	if (!optionDisabled) return <>{children}</>

	const tooltipTitle = disabilityComment ? disabilityComment : t('option_not_available')

	//это охренеть какие костыли, но вот так -_-
	return (
		<>
			<div
				style={{
					display: 'flex',
					width: 'fit-content',
					opacity: '0.4',
					position: 'relative',
					userSelect: 'none',
					overflow: 'hidden',
					tabIndex: '-1',
				}}
			>
				{children}
				<Tooltip placement="top" arrow disableInteractive title={tooltipTitle}>
					<div
						style={{
							position: 'absolute',
							top: '0',
							left: '0',
							width: '100%',
							height: '100%',
							cursor: 'not-allowed',
							zIndex: '999',
						}}
						onClick={(event) => {
							event.preventDefault()
							event.stopPropagation()
						}}
						onMouseDown={(event) => event.stopPropagation()}
						onMouseUp={(event) => event.stopPropagation()}
						onMouseEnter={(event) => event.stopPropagation()}
					/>
				</Tooltip>
			</div>
		</>
	)
}

export default CommentedDisability
