import React from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined'
import SwipeLeftOutlinedIcon from '@mui/icons-material/SwipeLeftOutlined'
import EmptyPlaceholder from '../../../../shared/components/empty-placeholder'
import { CellEmpty } from '../../../../shared/components'
import { useTranslation } from 'react-i18next'

interface IProps {
	status: string
}

const ProjectStatus: React.FC<IProps> = ({ status }) => {
	const { t } = useTranslation()
	const statuses: any = {
		ON_CONFIRMATION: {
			label: t('statuses.on_confirmation'),
			className: 'tt-bg-gray-600/10 tt-text-gray-600',
			icon: <AccessTimeOutlinedIcon />,
		},
		APPROVED: {
			label: t('statuses.approved'),
			className: 'tt-bg-green-600/10 tt-text-green-600',
			icon: <CheckOutlinedIcon />,
		},
		REJECTED: {
			label: t('statuses.rejected'),
			className: 'tt-bg-red-600/10 tt-text-red-600',
			icon: <SwipeLeftOutlinedIcon />,
		},
		COMPLETED: {
			label: t('statuses.completed'),
			className: 'tt-bg-blue-600/10 tt-text-blue-600',
			icon: <SwipeLeftOutlinedIcon />,
		},
	}
	if (!status) return <CellEmpty />

	const curStatus = statuses[status]
	return (
		<div
			className={[
				'tt-text-center tt-inline-flex  tt-text-12 tt-font-medium tt-rounded-full tt-px-8 tt-py-2  tt-truncate',
				curStatus?.className,
			].join(' ')}
		>
			{curStatus?.label}
		</div>
	)
}

export default ProjectStatus
