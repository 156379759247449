import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getProject } from 'src/old-app/store/projectsSlice'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import exportKPGHandler from 'src/old-app/utils/exportKPGHandler'
import ErrorText from '../../ui/ErrorText'
import { useSearchParams } from 'react-router-dom'
import { setParamsFilters } from 'src/old-app/main/tasks/TaskList'
import queryString from 'query-string'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const TimeKPG = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		start: yup.string().required(t('working_start_time_is_required')),
		end: yup.string().required(t('working_finish_time_is_required')),
	})
	const dispatch = useDispatch()
	const project = useSelector(getProject)
	const [isDisabled, setIsDisabled] = useState(false)

	const [searchParams, setSearchParams] = useSearchParams()

	const parsedSearch = queryString.parse(searchParams.toString())
	const {
		searchTask,
		slug,
		order_sort: createdSort = 'desc',
		deadline_start: deadlineStart,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = [],
		status = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		...tasksParams
	} = parsedSearch

	// const searchTask = searchParams.get('searchTask') ?? undefined
	// const slug = searchParams.get('slug') ?? undefined
	//
	// const deadlineStart = searchParams.get(`deadline_start`) ?? undefined
	// const deadlineEnd = searchParams.get(`deadline_end`) ?? undefined
	// const completedAtStart = searchParams.get(`completed_at_start`) ?? undefined
	// const completedAtEnd = searchParams.get(`completed_at_end`) ?? undefined
	// const createdAtStart = searchParams.get(`date_created_at_end`) ?? undefined
	// const createdAtEnd = searchParams.get(`date_created_at_start`) ?? undefined
	// const assignee = searchParams.get('assignee') ?? []
	// const status = searchParams.get('status') ?? []
	// const author = searchParams.get('author') ?? []
	// const priority = searchParams.get('priority') ?? []
	// const type = searchParams.get('type') ?? []
	//
	// const statusValue = Array.isArray(status) ? status : JSON.parse(status)
	// const assigneeValue = Array.isArray(assignee) ? assignee : JSON.parse(assignee)
	// const priorityValue = Array.isArray(priority) ? priority : JSON.parse(priority)
	// const authorValue = Array.isArray(author) ? author : JSON.parse(author)
	// const typeValue = Array.isArray(type) ? type : JSON.parse(type)
	//
	// const multipleParamsString = setParamsFilters({
	// 	type: typeValue,
	// 	author: authorValue,
	// 	priority: priorityValue,
	// 	statuses: statusValue,
	// 	assignee: assigneeValue,
	// })

	const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue] = [
		status,
		assignee,
		priority,
		author,
		type,
		tags,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		author: authorValue,
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
		tags: tagsValue,
		initParams: tasksParams,
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const exportFileHandler = (path, data) => {
		const name = `${project?.name}`

		const params = new URLSearchParams(multipleParamsString)

		params.append('project_id', project.id)
		params.append('order_sort', createdSort)

		if (searchTask) {
			params.append('title', searchTask)
		}
		if (slug) {
			params.append('slug', slug)
		}
		if (deadlineStart) {
			params.append('start_date_deadline', moment(deadlineStart).format())
		}
		if (deadlineEnd) {
			params.append('end_date_deadline', moment(deadlineEnd).format())
		}
		if (completedAtStart) {
			params.append('start_completed_at', moment(completedAtStart).format())
		}
		if (completedAtEnd) {
			params.append('end_completed_at', moment(completedAtEnd).format())
		}
		if (createdAtStart) {
			params.append('start_date_created_at', moment(createdAtStart).format())
		}
		if (createdAtEnd) {
			params.append('end_date_created_at', moment(createdAtEnd).format())
		}
		params.set('end_track_date', moment(data.end).format())
		params.set('start_track_date', moment(data.start).format())

		const url = `/${path}/?${params.toString()}`
		exportKPGHandler({ url, params, name, dispatch })
	}

	const createProjectHandler = async (data) => {
		if (moment(data.stop).isBefore(data.start)) {
			return setError('end', { message: 'Конец не должен быть меньше времени от' })
		}
		exportFileHandler('report/time', data)
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="w-600">
			<DialogHeader title="Выгрузка времени" />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10 ">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-flex-col tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('start_time')}</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										error={!!errors.start}
										type="datetime-local"
										hiddenLabel
										className="dialig-input tt-w-full"
										id={getID({
											place: 'modal',
											type: 'datetime',
											field: 'kpg',
											action: 'download',
											fieldPostfix: 'start',
										})}
										defaultValue=""
										size="small"
									/>
								)}
								name="start"
								control={control}
							/>
							<ErrorText errors={errors} field="start" />
						</div>
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('finish_time')}</span>
							<Controller
								render={({ field: { value, onChange } }) => (
									<TextField
										value={value}
										onChange={onChange}
										error={!!errors.end}
										type="datetime-local"
										hiddenLabel
										className="dialig-input tt-w-full"
										id={getID({
											place: 'modal',
											type: 'datetime',
											field: 'kpg',
											action: 'download',
											fieldPostfix: 'end',
										})}
										defaultValue=""
										size="small"
									/>
								)}
								name="end"
								control={control}
							/>
							<ErrorText errors={errors} field="end" />
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} rightLabel={t('download')} />
		</form>
	)
}

export default TimeKPG
