import React, { useMemo, useState } from 'react'
import styles from './styles.module.scss'
import TextFilter from '../../../../shared/components/filters/text-filter'
import UserFilter from '../../../../shared/components/filters/user-filter'
import UnTable from '../../../../shared/components/un-table'
import { PathnameCell, UserShow } from '../../../../shared/components'
import { Chip } from '@mui/material'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { useProjects } from 'src/entities/project'
import TagsView from '../../../../old-app/components/ui/TagsView'
import ProjectTagsFilter from '../../../../shared/components/filters/project-tags-filter'
import moment from 'moment/moment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTeams } from '../../model'
import TeamTagsFilter from '../../../../shared/components/filters/team-tags-filter'
import TaskMoreFilters from '../../../../old-app/components/task/TaskMoreFilters'
import TeamStatus from '../team-status'
import TeamStatusesFilter from '../../../../shared/components/filters/team-statuses-filter'
import { useTranslation } from 'react-i18next'

interface IProps {}

const ShowTeam = ({ id }: { id: string }) => {
	const { search } = useLocation()
	return (
		<Link to={`/teams/show/${id}${search}`}>
			<div className="tt-w-full tt-bg-gray-010 tt-rounded-xl tt-border tt-duration-200 tt-text-gray-500 hover:tt-text-primary tt-border-gray-200 hover:tt-bg-primary/10 hover:tt-border-primary/20  tt-h-full  group tt-flex tt-items-center tt-justify-center">
				<MenuOpenIcon className="tt-w-20 tt-h-20  " />
			</div>
		</Link>
	)
}

const defaultPerPage = 100

const TeamsTable: React.FC<IProps> = () => {
	const { t } = useTranslation()

	const columns = useMemo(
		() => [
			{
				id: 2,
				label: 'Название',
				field: 'name',
				className: 'tt-flex-1',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap tt-break-all tt-flex-1 tt-min-w-76  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.name}
					</p>
				),
			},
			{
				id: 3,
				label: 'Отвественный',
				field: 'author',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => <>{listItem?.author_view && <UserShow user={listItem?.author_view} />}</>,
			},
			{
				id: 4,
				label: 'Теги',
				field: 'tags',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => (
					<>
						<TagsView isHidePlaceholder={false} tags={listItem?.hashtags ?? []} />
					</>
				),
			},
			{
				id: 4,
				label: 'Статус',
				field: 'confirmation_status',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => (
					<>
						<TeamStatus status={listItem?.confirmation_status} />
					</>
				),
			},
			{
				id: 5,
				label: '',
				field: '',
				className: 'tt-max-w-[44px] tt-min-w-[44px] tt-w-[44px]',
				render: (listItem: any) => (
					<>
						<ShowTeam id={listItem.id} />
					</>
				),
			},
		],
		[]
	)

	const filters = useMemo(
		() => [
			{
				id: 2,
				render: (listItem: any) => (
					<>
						<TextFilter placeholder={t('title')} />
					</>
				),
			},
			{
				id: 3,
				render: (listItem: any) => (
					<>
						<UserFilter isAll placeholder={t('author')} field="author" />
					</>
				),
			},
			{
				id: 4,
				render: (listItem: any) => (
					<>
						<TeamTagsFilter placeholder={t('tags')} />
					</>
				),
			},
			{
				id: 4,
				render: (listItem: any) => (
					<>
						<TeamStatusesFilter placeholder={t('status')} />
					</>
				),
			},
			{
				id: 5,
				render: (listItem: any) => (
					<>
						<TaskMoreFilters
							prefix={''}
							hideTaskParamsFilter
							hideSkelet={{ deadline_sort: true }}
							isLabel={undefined}
							allProjectsMode={true}
							projectId={undefined}
							id={undefined}
						/>
					</>
				),
			},
		],
		[]
	)

	const heads = columns.map((column, index) => {
		return { ...column, render: filters[index]?.render }
	})

	const { search } = useLocation()
	const { teamId } = useParams()
	const [perPage, setPerPage] = useState(defaultPerPage)
	const { teams, isLoading, meta, isFetching } = useTeams({
		params: { per_page: perPage, page: 1, return_project_req_availability: true },
	})
	const page = '/team'

	const link = (id: Id) => `${page}/${id}`

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	const rowActive = (id: Id) => {
		return id === teamId
	}

	const isRowDisabled = (listItem: any) => listItem?.confirmation_status !== 'APPROVED'

	return (
		<div className="tt-flex tt-flex-col tt-overflow-auto table-scroll">
			<UnTable
				isFetching={isFetching}
				rowDisabled={isRowDisabled}
				rowActive={rowActive}
				loadMore={loadMore}
				meta={meta}
				isLoading={isLoading}
				emptyPlaceholder={t('empty_teams_list')}
				link={link}
				heads={heads}
				list={teams}
				columns={columns}
			/>
		</div>
	)
}

export default TeamsTable
