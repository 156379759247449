import React from 'react'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from '@mui/material'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		border: 0,
		'&.Mui-disabled': {
			border: 0,
		},
		'&.Mui-selected': {
			backgroundColor: '#fff',
		},
		'&.Mui-selected:hover': {
			backgroundColor: '#fff',
		},

		'&:not(:first-of-type)': {
			borderRadius: 44,
		},
		'&:first-of-type': {
			borderRadius: 44,
		},
	},
}))

const Tabs = ({ items, value, setValue, paramsKey, labelClassName, toggleButtonStyle, paperClassName }) => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const onChangeValue = (event, newWalue) => {
		if (!newWalue) return
		// const taskTabParams = searchParams.get('task_tab')
		// const params = { task_tab: taskTabParams, [paramsKey]: newWalue }
		setValue(newWalue)
		// navigate({
		// 	search: `${createSearchParams(params)}`,
		// })
	}

	return (
		<Paper elevation={0} className={['tt-bg-gray-200 tt-rounded-full  tt-p-2', paperClassName].join(' ')}>
			<StyledToggleButtonGroup
				className="flex"
				value={value}
				exclusive
				onChange={onChangeValue}
				aria-label="text alignment"
			>
				{items.map((item) => (
					<ToggleButton
						key={item.slug + item.id}
						sx={{ height: 32, ...toggleButtonStyle, width: `${100 / items?.length}%` }}
						value={item.slug}
						aria-label={item.slug}
					>
						<Tooltip arrow title={item.label} placement="top-start">
							<span className={['tt-normal-case tt-truncate', labelClassName].join(' ')}>{item.label}</span>
						</Tooltip>
					</ToggleButton>
				))}
			</StyledToggleButtonGroup>
		</Paper>
	)
}

export default Tabs
