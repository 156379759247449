import React, { createContext, useState, useContext, useMemo } from 'react'
import styles from './styles.module.scss'
import TextFilter from '../../../../shared/components/filters/text-filter'
import UserFilter from '../../../../shared/components/filters/user-filter'
import UnTable from '../../../../shared/components/un-table'
import { CellEmpty, UserShow } from '../../../../shared/components'
import { Chip, Tooltip } from '@mui/material'
import { useProjects } from 'src/entities/project'
import TagsView from '../../../../old-app/components/ui/TagsView'
import ProjectTagsFilter from '../../../../shared/components/filters/project-tags-filter'
import moment from 'moment/moment'
import DateFilter from '../../../../shared/components/filters/date-filter'
import TaskMoreFilters from '../../../../old-app/components/task/TaskMoreFilters'
import ProjectStatus from '../project-status'
import ProjectStatusesFilter from '../../../../shared/components/filters/project-statuses-filter'
import { Link, useLocation } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import ProjectTypesFilter from '../../../../shared/components/filters/project-type-filter'
import { useTranslation } from 'react-i18next'

interface IProps {
	params?: any
	curPage?: any
}

const Context = createContext<{ page?: string }>({ page: '/project/show' })

const ShowProject = ({ id }: { id: string }) => {
	const { search } = useLocation()

	const { page = '/project/show' } = useContext(Context)

	return (
		<Link to={`${page}/${id}${search}`}>
			<div className="tt-w-full tt-bg-gray-010 tt-rounded-xl tt-border tt-duration-200 tt-text-gray-500 hover:tt-text-primary tt-border-gray-200 hover:tt-bg-primary/10 hover:tt-border-primary/20  tt-h-full  group tt-flex tt-items-center tt-justify-center">
				<MenuOpenIcon className="tt-w-20 tt-h-20  " />
			</div>
		</Link>
	)
}
const defaultPerPage = 100

const ProjectsTable: React.FC<IProps> = ({ params, curPage }) => {
	const { t } = useTranslation()

	const columns = useMemo(
		() => [
			{
				id: 1,
				label: 'Код проекта',
				field: 'slug',
				className: 'tt-w-64 tt-min-w-64',
				render: (listItem: any) => (
					<Chip
						label={listItem?.slug}
						className={`tt-bg-gray-200 tt-text-gray-700 tt-font-medium tt-truncate `}
						size="small"
					/>
				),
			},
			{
				id: 2,
				label: 'Название',
				field: 'name',
				className: 'tt-flex-1 tt-min-w-64',
				render: (listItem: any) => (
					<Tooltip enterDelay={500} arrow title={listItem?.name} placement="top-start">
						<p className="my-truncate-one whitespace-pre-wrap break-all flex-1 min-w-76  text-14 text-left font-medium text-black">
							{listItem?.name}
						</p>
					</Tooltip>
				),
			},
			{
				id: 3,
				label: 'Отвественный',
				field: 'author',
				className: 'tt-max-w-192 tt-min-w-192 tt-w-192',
				render: (listItem: any) => <>{listItem?.owner && <UserShow user={listItem?.owner} />}</>,
			},
			{
				id: 4,
				label: 'Теги',
				field: 'tags',
				className: 'tt-max-w-160 tt-min-w-160 tt-w-160',
				render: (listItem: any) => (
					<>
						<TagsView isHidePlaceholder={false} tags={listItem?.hashtags ?? []} />
					</>
				),
			},
			{
				id: 5,
				label: 'Дата завершения',
				field: 'complete_at',
				className: 'tt-max-w-160 tt-min-w-160 tt-w-160',
				render: (listItem: any) => {
					const date = moment(listItem?.complete_at)
					return (
						<>
							<span className="text-14 font-medium text-black">
								{date.isValid() ? date.format('ll') : t('no_completion_date')}
							</span>
						</>
					)
				},
			},
			{
				id: 7,
				label: 'Статус',
				field: 'confirmation_status',
				className: 'tt-max-w-160 tt-min-w-160 tt-w-160',
				render: (listItem: any) => (
					<>
						<ProjectStatus status={listItem?.confirmation_status} />
					</>
				),
			},
			{
				id: 8,
				label: 'Статус',
				field: 'confirmation_status',
				className: 'tt-max-w-120 tt-min-w-120 tt-w-120',
				render: (listItem: any) => (
					<>
						{listItem?.type ? (
							<Chip
								sx={{ height: 24 }}
								className="tt-truncate tt-font-medium tt-inline-flex tt-text-12"
								label={t(`types.${listItem?.type?.name}`)}
							/>
						) : (
							<CellEmpty />
						)}
					</>
				),
			},
			{
				id: 5,
				label: '',
				field: '',
				className: 'tt-max-w-[44px] tt-min-w-[44px] tt-w-[44px]',
				render: (listItem: any) => (
					<>
						<ShowProject id={listItem?.id} />
					</>
				),
			},
		],
		[]
	)

	const filters = useMemo(
		() => [
			{
				id: 1,
				render: (listItem: any) => (
					<>
						<TextFilter placeholder={t('slug')} field="slug" />
					</>
				),
			},
			{
				id: 2,
				render: (listItem: any) => (
					<>
						<TextFilter placeholder={t('title')} />
					</>
				),
			},
			{
				id: 3,
				render: (listItem: any) => (
					<>
						<UserFilter isAll placeholder={t('author')} field="author" />
					</>
				),
			},
			{
				id: 4,
				render: (listItem: any) => (
					<>
						<ProjectTagsFilter placeholder={t('tags')} />
					</>
				),
			},
			{
				id: 5,
				render: (listItem: any) => (
					<>
						<DateFilter placeholder={t('completion_date')} field="complete_at" />
					</>
				),
			},
			{
				id: 7,
				render: (listItem: any) => (
					<>
						<ProjectStatusesFilter placeholder={t('status')} />
					</>
				),
			},
			{
				id: 7,
				render: (listItem: any) => (
					<>
						<ProjectTypesFilter placeholder={t('type')} />
					</>
				),
			},
			{
				id: 6,
				render: (listItem: any) => (
					<>
						<TaskMoreFilters
							prefix={''}
							hideTaskParamsFilter
							hideSkelet={{ deadline_sort: true }}
							isLabel={undefined}
							allProjectsMode={true}
							projectId={undefined}
							id={undefined}
						/>
					</>
				),
			},
		],
		[]
	)

	const heads = columns.map((column, index) => {
		return { ...column, render: filters[index]?.render }
	})

	const [perPage, setPerPage] = useState(defaultPerPage)
	const { projects, isLoading, meta, isFetching } = useProjects({ params: { per_page: perPage, page: 1, ...params } })
	const page = '/project'

	const link = (id: Id) => `${page}/${id}/list`

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	const isRowDisabled = (listItem: any) =>
		listItem?.confirmation_status === 'REJECTED' || listItem?.confirmation_status === 'ON_CONFIRMATION'

	return (
		<Context.Provider value={{ page: curPage }}>
			<div className="tt-flex tt-flex-col tt-overflow-auto table-scroll">
				<UnTable
					isFetching={isFetching}
					rowDisabled={isRowDisabled}
					loadMore={loadMore}
					meta={meta}
					isLoading={isLoading}
					emptyPlaceholder={t('empty_projects_list')}
					link={link}
					heads={heads}
					list={projects}
					columns={columns}
				/>
			</div>
		</Context.Provider>
	)
}

export default ProjectsTable
