import React from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getProject } from 'src/old-app/store/projectsSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { UserShow } from '../../../../shared/components'
import { Link, useLocation } from 'react-router-dom'
import TaskListItem from '../../../../old-app/main/tasks/TaskListItem'

import EmptyPlaceholder from '../../../../shared/components/empty-placeholder'
import { useGetProjectsQuery } from '../../../../old-app/service/Projects'
import { useTeamShow } from '../../hooks'
import SubProjectRow from '../../../project/components/sub-project-row'
import { useGetTeamProjectsQuery } from '../../../../old-app/service/Teams'
import { ProjectsTable } from '../../../project'

interface IProps {
	curPage?: string
}

const TeamProjects: React.FC<IProps> = ({ curPage }) => {
	const { team } = useTeamShow()

	return <ProjectsTable curPage={curPage} params={{ command_id: team?.id }} />
}

export default TeamProjects
