import { formatter } from 'src/shared/lib/formatteer'

export const getContractDirectoryParamsPrice = (params: { id: string; name: string; price: number }[]) => {
	if (params?.length === 0) {
		return `₽ 0`
	}
	const prices = params?.map((param) => param?.price) ?? []
	const minPrice = Math.min(...prices)
	const maxPrice = Math.max(...prices)

	if (minPrice === maxPrice) {
		return `₽ ${Number(minPrice).toLocaleString('ru')}`
	}

	return `₽ ${Number(minPrice).toLocaleString('ru')} - ${Number(maxPrice).toLocaleString('ru')}`
}
