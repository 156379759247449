import React, { memo, useEffect, useLayoutEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import TaskRow from './TaskRow'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsOpenEpic } from 'src/old-app/store/interfaceSlice'

const colors = ['tt-bg-primary-500', 'tt-bg-orange-500']

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	'&:not(:last-child)': {
		borderBottom: 0,
		width: '100%',
	},
	'&:before': {
		display: 'none',
		width: '100%',
	},

	width: '100%',
	background: 'none',
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	padding: 0,
	minHeight: 0,
	width: '100%',
	'& .MuiAccordionSummary-expandIconWrapper': {
		display: 'none',
		width: '100%',
	},
	'& .MuiAccordionSummary-content': {
		margin: 0,
		width: '100%',
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: 0,
	border: 'none',
	width: '100%',
	position: 'relative',
}))

const ToggleAccordion = ({ open, setOpen }) => {
	const { pathname, search } = useLocation()
	return (
		<Link
			className="tt-relative tt-h-20 tt-w-20 tt-z-50 tt-flex tt-items-center tt-justify-center"
			to={pathname + search}
		>
			<div
				onClick={() => setOpen(!open)}
				className="tt-h-20 tt-w-20 tt-absolute tt-text-gray-500 hover:tt-text-primary tt-flex tt-items-center tt-justify-center"
			>
				<ArrowDropDownCircleOutlinedIcon
					className={['tt-duration-500 tt-h-full tt-w-full', open ? 'tt-rotate-180' : ''].join(' ')}
				/>
			</div>
		</Link>
	)
}

const TaskListItemWithSubTasks = memo((props) => {
	const isOpenEpic = useSelector(getIsOpenEpic)
	const [open, setOpen] = useState(true)

	const id = `panel${props.data.id}`
	const panelContentId = `panel${props.data.id}d-content`
	const panelHeaderId = `panel${props.data.id}d-header`

	const isEpic = props?.isAccordionHandler ? props?.isAccordionHandler(props) : props?.data?.type?.name === "epic" || props?.data?.type?.name === "Эпик" 

	useLayoutEffect(() => {
		if (isEpic) {
			setOpen(isOpenEpic)
		}
	}, [isOpenEpic, isEpic])

	return (
		<table className="tt-w-full">
			<Accordion style={props.accordionSx} expanded={open} key={id}>
				<AccordionSummary aria-controls={panelContentId} id={panelHeaderId}>
					<TaskRow
						{...props}
						className={open ? 'task-accordion-details' : ''}
						left={
							!isEpic ? (
								<div className="tt-h-20 tt-ml-5 tt-min-w-20 tt-w-20  tt-text-gray-500 hover:tt-text-primary tt-flex tt-items-center tt-justify-center" />
							) : (
								<ToggleAccordion open={open} setOpen={setOpen} />
							)
						}
					/>
				</AccordionSummary>
				{isEpic && props?.data?.child?.length > 0 && (
					<AccordionDetails>
						<table className="tt-w-full tt-flex-1">
							{open ? (
								<>
									<>
										{props?.data?.child?.map((t, i) => (
											<>
												<div style={{ left: 22 }} className="tt-w-2 tt-py-8 tt-h-full tt-absolute tt-top-0 tt-z-20">
													<div className={`tt-w-fill tt-h-full tt-bg-gray-300`} />
												</div>
												<tr className="tt-relative tt-w-full">
													<div style={{ left: 22 }} className="tt-w-2 tt-py-8 tt-absolute tt-top-0 tt-z-20">
														<div className={`tt-w-fill task-branch   tt-bg-gray-300`} />
													</div>
													<TaskListItemWithSubTasks
														{...props}
														accordionSx={{ paddingLeft: '45px' }}
														index={`${props?.index}-${i}`}
														activeClassName="tt-bg-white"
														takeStatusesFromRedux
														key={t.id}
														data={t}
													/>
												</tr>
											</>
										))}
									</>
								</>
							) : null}
						</table>
					</AccordionDetails>
				)}
			</Accordion>
		</table>
	)
})

export default TaskListItemWithSubTasks
