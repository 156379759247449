import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { ProjectInfo, useProjectShow } from 'src/entities/project'
import ProjectRoles from '../../../old-app/components/project/ProjectRoles'
import EditProject from '../../../old-app/components/project/EditProject'
import { Button } from '@mui/material'
import { getID } from '../../../shared/config/fields-ids'
import { useParams } from 'react-router-dom'
import { ProjectEnd } from '../../../features/project'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

interface IProps {
	isWrap?: boolean
	isSideBar?: boolean
	hideFields?: any
	projectId: string
}

const ProjectSettings: React.FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { isWrap, hideFields, isSideBar, projectId } = p

	const [isEdit, setIsEdit] = useState(false)
	const projectShow = useProjectShow()

	const isCommittee = projectShow?.isCommittee

	useEffect(() => {
		setIsEdit(false)
	}, [projectId])

	return (
		<div className={clsx(isSideBar && 'tt-h-full', !isEdit && isSideBar && 'tt-p-24', styles['project-settings'])}>
			{isEdit ? (
				<EditProject isSideBar={isSideBar} setEdit={setIsEdit} />
			) : (
				<>
					<div className={styles['project-block']}>
						<div className="tt-flex tt-items-center tt-gap-12 tt-mb-12">
							{projectShow?.isManageProject && !projectShow?.project?.isEnd && (
								<CommentedDisability
									optionDisabled={isCommittee}
									disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
								>
									<Button
										onClick={() => setIsEdit(true)}
										startIcon={
											<svg
												className="tt-stroke-gray-700"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
													strokeWidth="2"
													stroke="#94A3B8"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										}
										className="tt-border-gray-200 tt-inline-flex  tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
										variant="outlined"
									>
										{t('edit_project')}
									</Button>
								</CommentedDisability>
							)}
							{projectId && projectShow?.canCloseProject && (
								<ProjectEnd
									isSubProject={Boolean(projectShow?.project?.parent_id)}
									projectId={projectId}
									childProjectsCompleted={projectShow?.project?.child_projects_completed}
									curStatus={projectShow?.project?.confirmation_status}
								/>
							)}
						</div>
						<ProjectInfo hideFields={hideFields} isWrap={isWrap} />
					</div>

					<div className={styles['project-block']}>
						{!hideFields?.roles && (
							<ProjectRoles
								id={getID({ entity: 'project' })}
								isDisabled={projectShow?.project?.isEnd ? true : projectShow?.isContract ? true : !projectShow.isEditProject}
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default ProjectSettings
