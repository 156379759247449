import { useEffect, useState } from 'react'
import styles from './LocalTemporaryChangesController.module.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import { showMessage } from '../../../../old-app/store/fuse/messageSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const LocalTemporaryChangesNode = ({ nodeTitle, nodeContent, nodeKey }) => {
	return (
		<div key={nodeKey} className={styles.changes_node}>
			<div className={styles.changes_node__title}>{nodeTitle}:</div>
			<div className={styles.changes_node__content}>{nodeContent}</div>
		</div>
	)
}

const LocalTemporaryChangesController = ({
	localStorageKey = null,
	observableFields = [],
	setFieldValue,
	getFieldValue,
	updatePeriod = 5000,
}) => {
	const { t } = useTranslation()
	const [showRestoreChangesToolbar, setShowRestoreChangesToolbar] = useState(false)
	const [unsavedChanges, setUnsavedChanges] = useState(null)
	const [canWriteLocalChanges, setCanWriteLocalChanges] = useState(false)
	const [expandPreview, setExpandPreview] = useState(false)

	const dispatch = useDispatch()

	if (!observableFields?.length) return

	const checkLocalTemporaryChanges = async () => {
		const changesData = window.localStorage.getItem(localStorageKey)
		if (!changesData) {
			setCanWriteLocalChanges(true)
			return
		}
		const parsedChangesData = await JSON.parse(changesData)
		if (!Object.keys(parsedChangesData)?.length) {
			setCanWriteLocalChanges(true)
			return
		}

		const currentFieldsValues = {}

		for (const observableField of observableFields) {
			const fieldValue = getFieldValue(observableField?.fieldName)
			if (fieldValue) {
				currentFieldsValues[observableField?.fieldName] = fieldValue
			}
		}

		const affectedFieldsChanges = {}

		Object.keys(parsedChangesData).forEach((fieldName) => {
			if (!(parsedChangesData[fieldName] === currentFieldsValues[fieldName])) {
				affectedFieldsChanges[fieldName] = parsedChangesData[fieldName]
			}
		})

		if (!Object.keys(affectedFieldsChanges)?.length) {
			setCanWriteLocalChanges(true)
			return
		}

		setUnsavedChanges(affectedFieldsChanges)
		setShowRestoreChangesToolbar(true)
	}

	const storeLocalTemporaryChanges = async () => {
		const temporaryUnsavedChanges = {}
		for (const observableField of observableFields) {
			const fieldValue = getFieldValue(observableField?.fieldName)
			if (fieldValue) {
				temporaryUnsavedChanges[observableField?.fieldName] = fieldValue
			}
		}
		const localStorageTemporaryData = JSON.stringify(temporaryUnsavedChanges)
		window.localStorage.setItem(localStorageKey, localStorageTemporaryData)
	}

	const restoreLocalTemporaryChanges = () => {
		Object.keys(unsavedChanges).forEach((fieldName) => {
			setFieldValue(fieldName, unsavedChanges[fieldName])
		})

		dispatch(
			showMessage({
				message: t('changes_restored_message'),
				autoHideDuration: 2000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				variant: 'success',
			})
		)
		setShowRestoreChangesToolbar(false)
		setCanWriteLocalChanges(true)
	}

	const clearLocalTemporaryChanges = () => {
		window.localStorage.removeItem(localStorageKey)
		setShowRestoreChangesToolbar(false)
		setUnsavedChanges(null)
		setCanWriteLocalChanges(true)
	}

	useEffect(() => {
		if (localStorageKey) {
			checkLocalTemporaryChanges()
		}
	}, [localStorageKey])

	useEffect(() => {
		if (canWriteLocalChanges) {
			const saveTemporaryChanges = setInterval(() => {
				storeLocalTemporaryChanges()
			}, updatePeriod)

			return () => {
				clearInterval(saveTemporaryChanges)
			}
		}
	}, [canWriteLocalChanges])

	return (
		<>
			{showRestoreChangesToolbar && (
				<div
					key={localStorageKey}
					className={
						!expandPreview ? styles.main_container : [styles.main_container, styles.main_container__expanded].join(' ')
					}
				>
					<div className={styles.toolbar}>
						<div className={styles.warning_icon}>
							<InfoOutlinedIcon fontSize="medium" />
						</div>
						<p>{t('unsaved_changes_found')}</p>
						<div className="tt-flex tt-gap-8 tt-ml-auto">
							<button
								className={[styles.control_button, styles.expand_button].join(' ')}
								onClick={(event) => {
									event.preventDefault()
									setExpandPreview(!expandPreview)
								}}
							>
								{expandPreview ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
							</button>
							<button
								className={[styles.control_button, styles.restore_button].join(' ')}
								onClick={(event) => {
									event.preventDefault()
									restoreLocalTemporaryChanges()
								}}
							>
								{t('restore')}
							</button>
							<button
								className={[styles.control_button, styles.clear_button].join(' ')}
								onClick={(event) => {
									event.preventDefault()
									clearLocalTemporaryChanges()
								}}
							>
								{t('clear_all')}
							</button>
						</div>
					</div>

					{expandPreview && (
						<div className={styles.changes_preview_container}>
							{observableFields.map((observableField) => {
								return (
									<>
										{unsavedChanges[observableField?.fieldName] && (
											<LocalTemporaryChangesNode
												key={observableField?.fieldName}
												nodeKey={observableField?.fieldName}
												nodeTitle={observableField?.fieldTitle}
												nodeContent={unsavedChanges[observableField?.fieldName]}
											/>
										)}
									</>
								)
							})}
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default LocalTemporaryChangesController
