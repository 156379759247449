import styles from './ProjectProtocolsTableEmptyRow.module.css'
import EllipsisTextLine from 'src/@shared/lib/EllipsisTextLine'

const ProjectProtocolsTableEmptyRow = ({ emptyPlaceholder = '' }) => {
	return (
		<div className={styles.row_wrapper}>
			<div className={styles.row}>
				<EllipsisTextLine>{emptyPlaceholder}</EllipsisTextLine>
			</div>
		</div>
	)
}

export default ProjectProtocolsTableEmptyRow
