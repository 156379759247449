import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProject } from '../../store/projectsSlice'
import { useTranslation } from 'react-i18next'
import { useProjectShow } from 'src/entities/project'

const TaskDeadlines = ({ deadlines }) => {
	const { t } = useTranslation()

	const projectShow = useProjectShow()

	const isCommittee = projectShow?.isCommittee

	const statuses = {
		ON_CONFIRMATION: {
			label: t('statuses.on_confirmation'),
			className: 'tt-bg-yellow-600/10 tt-text-yellow-700 tt-border tt-border-yellow-600/20',
			textClassName: 'tt-text-yellow-700',
		},
		APPROVED: {
			label: t('statuses.approved'),
			className: 'tt-bg-green-600/10 tt-text-green-600',
		},
		REJECTED: {
			label: t('statuses.rejected'),
			className: 'tt-bg-red-600/10 tt-text-red-600',
		},
		CANCELLED: {
			label: t('statuses.canceled'),
			className: 'tt-bg-blue-600/10 tt-text-blue-600',
		},
	}
	const project = useSelector(getProject)

	return (
		<div className="tt-flex tt-flex-col tt-gap-8 tt-w-full">
			{deadlines?.map((item, index) => {
				const status = statuses[item?.confirmation_status]
				const deadlineComment = item?.comment

				return (
					<div key={item?.id} className="tt-flex tt-flex-col tt-items-start tt-w-full">
						<div className="tt-flex tt-gap-8 tt-w-full">
							<span
								className={[index === 0 ? 'no-underline' : 'tt-line-through tt-text-red-400'].join(' ')}
								key={item?.deadline}
							>
								{moment(item?.deadline).format('LL')}
							</span>
							{status && project?.with_administration && (
								<div
									className={[
										'tt-text-center tt-inline-flex tt-text-12 tt-font-medium tt-rounded-full tt-px-8 tt-py-2  tt-truncate',
										status?.className,
									].join(' ')}
								>
									{status?.label}
								</div>
							)}
						</div>
						{isCommittee && deadlineComment && (
							<div className="tt-relative tt-max-w-full tt-mt-8 tt-px-8">
								<div className="tt-relative tt-max-w-full tt-min-w-44 tt-p-8 tt-bg-white tt-rounded-md tt-border-1 tt-border-dashed tt-border-gray-400">
									<div className="tt-w-11 tt-h-11 tt-bg-white tt-absolute -tt-top-6 tt-left-16 tt-rotate-45 tt-border-l-1 tt-border-t-1 tt-border-dashed tt-border-gray-400" />
									<span className=" tt-text-gray-600 tt-break-words">{deadlineComment}</span>
								</div>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}
export default TaskDeadlines
