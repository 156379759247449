import React, { FC, useState } from 'react'
import Autocomplete from '../autocomplete'
import useDebounce from 'src/shared/hooks/useDebounce'
import { useGetTeamsQuery } from '../../../old-app/service/Teams'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	multiple?: boolean
}

const CommandsChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Команды', isIds, multiple } = p

	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)

	const { data: сommandsData } = useGetTeamsQuery({ name: debounceSearch, confirmation_status: 'APPROVED' })

	const list: any[] = сommandsData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	return (
		<Autocomplete
			placeholder={placeholder}
			multiple={multiple}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.name}
			renderOptionLabel={(option: any) => option?.name}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default CommandsChoice
