import React, { FC, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetTeamQuery, useGetTeamUsersQuery } from '../../../../old-app/service/Teams'
import { getAdminRole } from '../../../../old-app/store/userSlice'
import { useGetUser } from '../../../../shared/hooks'
import { TeamShowContext } from '../../contexts'

interface IProps {
	children: ReactNode
}

export const ETeamRole = {
	ADMIN: 'ADMIN',
	CONFIRM_USER: 'CONFIRMING_USER',
	USER: 'USER',
}

const TeamProvider: FC<IProps> = ({ children }) => {
	const { teamId } = useParams()
	const navigate = useNavigate()
	const isAdmin = useSelector(getAdminRole)
	const user = useGetUser()
	const { data: teamData, isLoading: isTeamLoading, isError } = useGetTeamQuery(teamId)
	const { data: usersData, isLoading: isTeamUsersLoading } = useGetTeamUsersQuery({
		command_id: teamId,
		per_page: 9999,
	})

	useEffect(() => {
		if (isError) {
			navigate('/404')
		}
	}, [isError])

	if (isTeamLoading || isTeamUsersLoading) return <div></div>

	const team = teamData?.data
	const users = usersData?.data ?? []

	const me = users?.find((teamUser: any) => teamUser?.user?.id === user?.id)

	const curUserRole = isAdmin ? ETeamRole.ADMIN : me?.role

	const isManageTeam = isAdmin || me?.role === ETeamRole.ADMIN || teamData?.data?.author_view?.id === user?.id

	return (
		<TeamShowContext.Provider value={{ team: { ...team, users }, isManageTeam, curUserRole }}>
			{children}
		</TeamShowContext.Provider>
	)
}

export default TeamProvider
