import { useGetProjectsQuery } from '../../../old-app/service/Projects'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { AllMyTasksPageQueryParams, customArrayParam, dateParam } from '../../../old-app/main/my/AllMyTasksList'

const Params: any = {
	slug: StringParam,
	name: StringParam,
	complete_at_start: dateParam,
	complete_at_end: dateParam,
	order_sort: withDefault(StringParam, 'desc'),
	author: customArrayParam,
	tags: customArrayParam,
	type: customArrayParam,
}

const useProjectRequests = ({ params }: { params: any }) => {
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const {
		data: projectsData,
		isLoading,
		isFetching,
	} = useGetProjectsQuery({
		slug: queryParams.slug,
		created_at_sort: queryParams.order_sort,
		name: queryParams.name,
		start_date_complete_at: queryParams.complete_at_start,
		end_date_complete_at: queryParams.complete_at_end,
		owner_ids: queryParams?.author?.join(','),
		confirmation_status: 'ON_CONFIRMATION',
		hashtag_ids: queryParams?.tags?.join(','),
		type_id: queryParams?.type?.join(','),
		...params,
	})

	const projects = projectsData?.data ?? []
	const meta = projectsData?.meta ?? {}

	return { projects, isLoading, meta, isFetching }
}
export default useProjectRequests
