import { Popover } from '@mui/material'
import React from 'react'
import { getContractDirectoryParamsPrice } from '../../lib/get-contract-directory-params-price'
import { formatter } from 'src/shared/lib/formatteer'
import { num_word } from 'src/shared/lib/num_word'
import { useTranslation } from 'react-i18next'

interface ContractTypeParamsProps {
	params: { id: string; name: string; price: number }[]
}

export const ContractTypeParams: React.FC<ContractTypeParamsProps> = ({ params }) => {
	const { t } = useTranslation()
	const views = 2
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handlePopoverOpen = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	return (
		<div>
			<div
				className="tt-flex tt-gap-4"
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<div className="tt-bg-gray-100 tt-border tt-rounded-lg tt-border-gray-200 tt-px-8 tt-py-2 tt-text-gray-500 tt-flex tt-items-center tt-gap-6 tt-text-center">
					<span>{t('parameters', { count: params.length })}</span>
				</div>
			</div>

			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<div className="tt-col-span-6 tt-text-14 tt-z-10 tt-bg-gray-100/50 tt-flex tt-divide-y tt-divide-gray-200 tt-flex-col tt-border tt-border-gray-200 tt-rounded-lg">
					{params.map((param: any) => (
						<div className="tt-flex tt-items-center tt-gap-16 tt-px-8 tt-py-4" key={param.id}>
							<div className="tt-flex-1">{param.name}</div>
							<div className="tt-px-4 tt-inline tt-py-1 tt-rounded tt-bg-gray-100 tt-border-gray-200 tt-border tt-text-gray-600">
								{<>₽ {formatter(param?.price)}</>}
							</div>
						</div>
					))}
				</div>
			</Popover>
		</div>
	)
}
