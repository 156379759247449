import React, { useRef } from 'react'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import CustomAvatar from 'src/old-app/components/ui/CustomAvatar'
import { useDispatch } from 'react-redux'
import { getOneUser } from 'src/old-app/service/Users'
import { usersActions } from 'src/old-app/store/userSlice'
import { authorUpload } from 'src/old-app/service/File'
import { useGetUser } from 'src/shared/hooks'
import { logout } from 'src/@entities/session'
import LanguageToggler from 'src/features/translate/components/LanguageToggler'
import { useTranslation } from 'react-i18next'

const ProfileHeader = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useGetUser()

	const inputFileRef = useRef()

	const changeAvatarHandler = async (event) => {
		const file = event.target.files[0]
		const fileData = await authorUpload({ file, userID: user.id })
		const userData = await getOneUser(user.id)
		dispatch(usersActions.getUser(userData.data))
	}

	return (
		<div className="tt-flex tt-flex-col">
			<LanguageToggler />
			<div className="tt-h-160 tt-bg-primary tt-w-full" />

			<div className="tt-flex tt-flex-0 tt-flex-row tt-items-center tt-max-w-5xl tt-w-full tt-mx-auto tt-px-16 tt-h-72">
				<div className="-tt-mt-96 lg:-tt-mt-88 tt-rounded-full tt-flex tt-flex-col tt-items-center">
					<motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1 } }}>
						<CustomAvatar
							className="tt-w-128 tt-text-32 tt-h-128 tt-border-4 tt-rounded-full"
							src={user?.avatar}
							alt={user?.name}
						/>
					</motion.div>

					<input onChange={changeAvatarHandler} hidden ref={inputFileRef} type="file" />
					{/* <Button
						onClick={() => inputFileRef.current.click()}
						type="button"
						className="bg-gray-200 -mt-18 hover:bg-gray-300 text-gray-900  min-h-32 h-32 px-16"
					>
						Обновить
					</Button> */}
				</div>

				<div className="tt-flex tt-flex-1 tt-flex-col tt-items-center lg:tt-items-start tt-mt-16 lg:tt-mt-0 lg:tt-ml-32">
					<Typography className="tt-text-lg tt-font-bold tt-leading-none">{user?.name}</Typography>
					<Typography color="text.secondary">{user?.email}</Typography>
				</div>

				<div className="flex flex-1 justify-end my-16 lg:my-0">
					<Button
						onClick={logout}
						startIcon={<LogoutIcon />}
						className=" tt-bg-transparent hover:tt-bg-transparent  tt-whitespace-nowrap tt-text-black   tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('logout')}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ProfileHeader
