import React from 'react'
import styles from './styles.module.scss'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { requestsActions } from 'src/old-app/store/requestSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { requestType } from '../../types'
import SwipeLeftOutlinedIcon from '@mui/icons-material/SwipeLeftOutlined'
import { getID } from '../../../../shared/config/fields-ids'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'

interface IProps {
	id: string
	type: requestType
	className?: string
	isHideLabel?: false
}

const TaskDecline: React.FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { type, id, className, isHideLabel } = p
	const dispatch = useDispatch()

	const declineHander = async () => {
		dispatch(requestsActions.getRequest({ type, id }))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('cancel-request'))
	}
	return (
		<Button
			onClick={declineHander}
			className="tt-bg-red-transparent tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-red-transparent tt-text-red tt-min-h-20 tt-h-20"
		>
			<CloseOutlinedIcon className="tt-w-20 tt-h-20" />
			{isHideLabel ? '' : t('reject')}
		</Button>
	)
}

export default TaskDecline
