import React, { Fragment, memo } from 'react'
import moment from 'moment'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FieldContent from '../../shared/FieldContent'
import GetPlace from './GetPlace'
import GetProtocolUsers from './GetProtocolUsers'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import DecisionShow from './DecisionShow'
import ProtocolTaskShow from './ProtocolTaskShow'
import UserCell from '../../tables/UserCell'
import { Chip, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPlaces } from 'src/old-app/store/protocolSlice'
import { useTranslation } from 'react-i18next'
import { useProjectShow } from 'src/entities/project'

const ProtocolInfo = memo(({ protocol, protocolDecisionTask, titleClassName, decisionsClassName }) => {
	const { t } = useTranslation()
	const projectShow = useProjectShow()
	const places = useSelector(getPlaces)

	const isCommittee = projectShow?.isCommittee

	return (
		<div className="tt-flex tt-flex-col tt-gap-16 tt-w-full">
			<h2 className={['tt-text-3xl tt-font-semibold tt-leading-7', titleClassName].join(' ')}>{protocol?.topic}</h2>
			<div className="tt-flex tt-gap-24 tt-flex-wrap tt-mt-16">
				<div className="tt-flex-1">
					<FieldContent
						content={<GetProtocolUsers classNameList="tt-flex tt-flex-col tt-gap-4" protocolId={protocol?.id} />}
						icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />}
						label={t('participants')}
					/>
				</div>

				{protocol?.project && (
					<div className="tt-flex-1">
						<FieldContent
							content={
								isCommittee ? (
									<span>{protocol?.project?.name}</span>
								) : (
									<Link to={`/project/${protocol?.project?.id}/list`}>
										<span>{protocol?.project?.name}</span>
									</Link>
								)
							}
							icon={<AccountTreeOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />}
							label={t('project')}
						/>
					</div>
				)}
				<div className="tt-flex-1">
					<FieldContent
						content={places?.find((item) => item?.id === protocol?.place)?.Place}
						icon={<BusinessCenterOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
						label={t('meeting_location')}
					/>
				</div>
				<div className="tt-flex-1">
					<FieldContent
						content={moment(protocol?.created_at).format('LLL')}
						icon={<CalendarMonthOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
						label={t('creation_date')}
					/>
				</div>
				<div className="tt-flex-1">
					<FieldContent
						content={<UserCell id={protocol?.creator_id} />}
						icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />}
						label={t('responsible')}
					/>
				</div>
				<div className="tt-flex-1 tt-w-full">
					<FieldContent
						contentClassName="tt-w-full"
						content={
							<div className="tt-flex tt-gap-4 tt-flex-wrap">
								{protocol?.hashtags?.map((item) => (
									<Chip className="truncate" label={item.hashtag} />
								))}
							</div>
						}
						icon={<TagOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />}
						label={t('meeting_tags')}
					/>
				</div>

				<div className="tt-flex-1">
					<FieldContent
						content={
							<div>
								{moment(protocol?.meeting_date_start).format('LL')}
								<span className="tt-pl-8">
									{moment(protocol?.meeting_date_start).format('LT')}-{moment(protocol?.meeting_date_end).format('LT')}
								</span>
							</div>
						}
						icon={<AccessTimeOutlinedIcon className="tt-fill-gray-600 tt-h-20 tt-w-20" />}
						label={t('meeting_time')}
					/>
				</div>
			</div>
			{protocolDecisionTask?.length !== 0 && (
				<div
					className={[
						'tt-w-full tt-flex tt-flex-col tt-gap-5 tt-bg-gray-100 tt-rounded-lg tt-overflow-hidden ',
						decisionsClassName,
					].join(' ')}
				>
					<div className="tt-text-14 tt-font-semibold tt-px-10 tt-py-5 tt-bg-gray-200 tt-text-center tt-text-black ">
						{t('decisions_and_tasks_made')}
					</div>
					<div className="tt-flex tt-flex-col tt-gap-16 tt-p-20">
						{protocolDecisionTask?.map((item, index) => (
							<Fragment key={item.id}>
								{item?.decision && <DecisionShow index={index + 1} decision={item?.decision} />}
								{item?.task && <ProtocolTaskShow index={index + 1} task={item?.task} />}
							</Fragment>
						))}
					</div>
				</div>
			)}
		</div>
	)
})
ProtocolInfo.displayName = 'ProtocolInfo'
export default ProtocolInfo
