const STYLES = {
	FONT_FAMILY: {
		BASE: {
			fontFamily:
				"'Inter var', 'Roboto', '-apple-system', 'BlinkMacSystemFont', '\"Segoe UI\"', 'Roboto', '\"Helvetica Neue\"', 'Arial', '\"Noto Sans\"', 'sans-serif', '\"Apple Color Emoji\"', '\"Segoe UI Emoji\"', '\"Segoe UI Symbol\"', '\"Noto Color Emoji\"'",
		},
		INHERIT: {
			fontFamily: 'inherit',
		},
	},
	COLOR: {
		INHERIT: { color: 'inherit' },
		PRIMARY: { color: '#0f766e' },
		DARK: { color: '#22292F' },
		SECONDARY: { color: 'rgb(107, 114, 128)' },
	},
	BACKGROUND: {
		PRIMARY: { background: '#0f766e' },
	},
}

export const gNovuStyles = {
	layout: {
		root: {
			background: '#f9fafb !important',
		},
	},
}

export const novuStyles = {
	layout: {
		root: {
			...STYLES.COLOR.DARK,
			// ...STYLES.FONT_FAMILY.INHERIT,
			'& *': {
				...STYLES.FONT_FAMILY.BASE,
			},
			'& .MuiBadge-badge': {
				background: '#0f766e',
			},
			padding: '2px 0px',
			border: '1px solid #E0E0E0 !important',
			...gNovuStyles.layout.root,
		},
	},
	header: {
		root: {
			...STYLES.COLOR.INHERIT,
			'& .nc-unseen-badge': {
				...STYLES.BACKGROUND.PRIMARY,
				width: 'unset',
				padding: '.5em .75em',
				margin: 'unset',
			},
		},
		title: { ...STYLES.COLOR.INHERIT, fontSize: '1.25em', fontWeight: '600' },
		markAsRead: { ...STYLES.COLOR.SECONDARY, fontSize: '.75em', fontWeight: '600' },
		cog: { ...STYLES.COLOR.INHERIT },
		backButton: { ...STYLES.COLOR.SECONDARY },
	},
	notifications: {
		listItem: {
			unread: {
				'&:before': STYLES.BACKGROUND.PRIMARY,
			},
		},
	},
	accordion: {
		content: { ...STYLES.COLOR.INHERIT },
		control: { ...STYLES.COLOR.INHERIT },
	},
	switch: {
		input: {
			'&:checked ~ label': STYLES.BACKGROUND.PRIMARY,
		},
	},
	footer: {
		root: {
			display: 'none',
		},
	},
}
export const novuStylesFull = {
	layout: {
		root: {
			...STYLES.COLOR.DARK,
			// ...STYLES.FONT_FAMILY.INHERIT,
			'& *': {
				...STYLES.FONT_FAMILY.BASE,
			},
			width: 'calc(100vw - 60px)',
			height: '100vh',
			padding: 0,
			marginLeft: '-3px',
			borderRadius: '0px !important',
			'& .mantine-ScrollArea-root': {
				width: 'calc(100vw - 60px) !important',
				height: 'calc(100vh - 40px) !important',
			},
			'& .MuiBadge-badge': {
				background: '#0f766e',
			},
			...gNovuStyles.layout.root,
		},
	},
	header: {
		root: {
			height: '50px',
			...STYLES.COLOR.INHERIT,
			'& .nc-unseen-badge': {
				...STYLES.BACKGROUND.PRIMARY,
				width: 'unset',
				padding: '.5em .75em',
				margin: 'unset',
			},
		},
		title: { ...STYLES.COLOR.INHERIT, fontSize: '1.25em', fontWeight: '600' },
		markAsRead: { ...STYLES.COLOR.SECONDARY, fontSize: '.75em', fontWeight: '600' },
		cog: { ...STYLES.COLOR.INHERIT },
		backButton: { ...STYLES.COLOR.SECONDARY },
	},
	notifications: {
		root: {
			width: 'calc(100vw - 60px)',
			height: 'calc(100vh - 40px)',

			'.infinite-scroll-component': {
				width: 'calc(100vw - 60px)',
				height: 'calc(100vh - 40px) !important',
				maxWidth: '900px',
				margin: '0 auto',
			},
		},

		listItem: {
			unread: {
				'&:before': STYLES.BACKGROUND.PRIMARY,
			},
		},
	},
	preferences: {
		root: {
			width: 'calc(100vw - 60px)',
			height: 'calc(100vh - 40px)',
			maxWidth: '900px',
			margin: '0 auto',
			'.mantine-1avyp1d': {
				paddingBottom: '60px',
			},
			'.infinite-scroll-component': {
				width: 'calc(100vw - 60px)',
				height: 'calc(100vh - 40px) !important',
				maxWidth: '900px',
				margin: '0 auto',
			},
		},

		item: {
			unread: {
				'&:before': STYLES.BACKGROUND.PRIMARY,
			},
		},
	},
	accordion: {
		content: { ...STYLES.COLOR.INHERIT },
		control: { ...STYLES.COLOR.INHERIT },
	},
	switch: {
		input: {
			'&:checked ~ label': STYLES.BACKGROUND.PRIMARY,
		},
	},
	footer: {
		root: {
			display: 'none',
		},
	},
}
