import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import { Badge } from '@mui/material'
import classnames from 'classnames'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: 8,
		top: 8,
		width: 16,
		height: 16,
		maxWidth: 16,
		minWidth: 16,
		fontSize: 11,
		lineHeight: 11,
	},
}))

interface IProps {
	icon: any
	label: string
	badge?: number
}

const TableMenuItem: React.FC<IProps> = (p) => {
	const { icon, label, badge } = p

	return (
		<StyledBadge badgeContent={badge} color="success">
			<div className={classnames(styles['table-menu-item'], badge && styles['table-menu-item-active'])}>
				<div className={styles['menu-icon']}>{icon}</div>
				<span className={styles['menu-label']}>{label}</span>
			</div>
		</StyledBadge>
	)
}

export default TableMenuItem
