import { Link, useNavigate } from 'react-router-dom'
import styles from './ProjectTemplateTasksListItem.module.css'
import { PathnameCell, UserShow } from 'src/shared/components'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Tooltip } from '@mui/material'

const ProjectTemplateTasksListItem = ({ isCanEdit, task, baseUrl, removeTaskHandler }) => {
	const navigate = useNavigate()

	const linkTo = `${baseUrl}/${task?.id}`

	return (
		<Link to={linkTo} className={styles.ProjectTemplateTasksListItem}>
			<div className={styles.ProjectTemplateTasksListItem__slug}>
				<Tooltip title={task?.slug} placement="top">
					<span>{task?.slug}</span>
				</Tooltip>
			</div>
			<div className={styles.ProjectTemplateTasksListItem__title}>
				<Tooltip title={task?.title} placement="top">
					<span>{task?.title}</span>
				</Tooltip>
			</div>
			<div className={styles.ProjectTemplateTasksListItem__spacer} />
			<div className={styles.ProjectTemplateTasksListItem__user_show}>
				<UserShow user={task?.assignee} />
			</div>
			<div
				className={styles.ProjectTemplateTasksListItem__status}
				style={{ backgroundColor: task?.task_status?.color }}
			>
				<Tooltip title={task?.task_status?.status} placement="top">
					<span>{task?.task_status?.status}</span>
				</Tooltip>
			</div>
			{isCanEdit && (
				<PathnameCell>
					<button
						className={styles.ProjectTemplateTasksListItem__delete_btn}
						onClick={(event) => {
							// event.stopPropagation()
							removeTaskHandler(task?.id)
						}}
					>
						<HighlightOffOutlinedIcon />
					</button>
				</PathnameCell>
			)}
		</Link>
	)
}

export default ProjectTemplateTasksListItem
