import React, { FC, useEffect } from 'react'
import Autocomplete from '../autocomplete'
import { useGetTaskTypesQuery } from '../../../old-app/service/Tasks'
import { useTranslation } from 'react-i18next'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	multiple?: boolean
	className?: string
}

const TaskTypesChoice: FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { value, onChange, placeholder = 'Тип', isIds, multiple = false, className } = p

	// const [search, setSearch] = useState<string>('');
	// const debounceSearch = useDebounce(search,300)
	const { data: taskTypesData } = useGetTaskTypesQuery(
		{} // {name:debounceSearch}
	)

	const list: any[] = taskTypesData?.data ?? []

	// const onInputChange = (event: React.SyntheticEvent, value: string, reason: string)=>{
	//     setSearch(value)
	// }

	return (
		<Autocomplete
			multiple={multiple}
			placeholder={placeholder}
			className={className}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => t(`types.${option?.name}`)}
			renderOptionLabel={(option: any) => t(`types.${option?.name}`)}
			list={list}
			// onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default TaskTypesChoice
