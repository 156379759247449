import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TaskCompleteFileView = ({ id, link, originalName, removeTaskCompleteFile }) => {
	const { t } = useTranslation()
	return (
		<div className="tt-flex tt-shrink-0 tt-w-full tt-h-40 tt-px-8 tt-rounded-lg tt-border tt-border-solid tt-border-gray-200 hover:tt-border-gray-400 tt-transition-colors tt-items-center tt-justify-start tt-text-14">
			<span className="tt-w-full tt-overflow-hidden tt-text-ellipsis tt-whitespace-nowrap">{originalName}</span>
			<div className="tt-flex tt-items-center tt-gap-8 tt-ml-auto tt-h-full tt-text-gray-700">
				<Tooltip title={t('download_file')} placement="top" disableInteractive>
					<a
						className="tt-flex tt-h-28 tt-w-28 tt-rounded-full tt-bg-white tt-items-center tt-justify-center tt-cursor-pointer hover:tt-bg-gray-100 tt-transition-colors"
						href={link}
					>
						<FileDownloadOutlinedIcon fontSize="small" />
					</a>
				</Tooltip>
				<Tooltip title={t('delete')} placement="top" disableInteractive>
					<div
						onClick={() => {
							removeTaskCompleteFile(id)
						}}
						className="tt-flex tt-h-28 tt-w-28 tt-rounded-full tt-bg-white tt-items-center tt-justify-center tt-cursor-pointer hover:tt-bg-gray-100 tt-transition-colors"
					>
						<DeleteOutlinedIcon fontSize="small" />
					</div>
				</Tooltip>
			</div>
		</div>
	)
}

export default TaskCompleteFileView
