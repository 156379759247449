import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import { getTask } from 'src/old-app/store/tasksSlice'
import AddIcon from '@mui/icons-material/Add'
import React, { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { useDeleteTaskEpicMutation } from 'src/old-app/service/Tasks'
import TaskRow from 'src/old-app/main/tasks/TaskRow'
import { TaskContext } from '../../../entities/task'
import { chunk } from 'lodash/array'
import { ExpandMore } from '@mui/icons-material'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import { useTypedSelector } from '../../../shared/hooks'
import EmptyPlaceholder from '../../../shared/components/empty-placeholder'
import { getID } from '../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'
import { getProject, getProjectAdminContractRole, getProjectUserRole } from 'src/old-app/store/projectsSlice'
import { useProjectShow } from 'src/entities/project'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'

const Epic = ({ id, limit, hideHead, isCanEdit, removeTaskHandler, onCreateComponent, onChoiceComponent }) => {
	const { t } = useTranslation()
	const { projectId } = useParams()
	const mainTask = useSelector(getTask)
	const isAdmin = useTypedSelector(getAdminRole)
	const projectShow = useProjectShow()
	const project = useSelector(getProject)
	const isUser = useTypedSelector(getProjectUserRole)
	const isAdminProjectContract = useSelector(getAdminProjectContractRole) || useSelector(getProjectAdminContractRole)

	const _canEpicEdit = project?.isContract ? isAdminProjectContract || isAdmin : mainTask?.isMyTask || isAdmin

	const { page } = useContext(TaskContext)

	const isCommittee = projectShow?.isCommittee

	const dispatch = useDispatch()
	const [deleteEpicTask] = useDeleteTaskEpicMutation()

	const onCreate = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(onCreateComponent ?? 'task-create-epic'))
	}
	const onChoice = () => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(onChoiceComponent ?? 'epics'))
	}

	const removeTask = async (item) => {
		removeTaskHandler ? removeTaskHandler(item) : await deleteEpicTask(item.id)
	}

	const [expanded, setExpanded] = React.useState(false)

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	const [subtasks, accordionSubTasks] = useMemo(() => {
		if (!mainTask?.subtasks?.length) {
			return [null, null]
		}
		if (!limit && mainTask?.subtasks?.length > 0) {
			return [mainTask?.subtasks, null]
		}
		if (limit && mainTask?.subtasks?.length <= limit) {
			return [mainTask?.subtasks, null]
		} else {
			return [mainTask?.subtasks?.slice(0, limit), mainTask?.subtasks?.slice(limit)]
		}
	}, [mainTask])

	const getCommonProps = (task, index) => ({
		right: (isCanEdit || _canEpicEdit) && (
			<CommentedDisability
				optionDisabled={isCommittee}
				disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
			>
				<TaskRemove
					id={getID({ prefix: id, field: 'sub_tasks', type: 'button_delete', index })}
					remove={() => {
						removeTask(task)
					}}
				/>
			</CommentedDisability>
		),
		hideSkelet: {
			left: true,
			type: true,
			tags: true,
			author: true,
			complete_date: true,
			deadline: true,
			priority: true,
			project: true,
			right: mainTask?.project?.isEnd,
		},
		activeClassName: 'bg-white',
		takeStatusesFromRedux: true,
		projectId: projectId,
		key: task.id,
		data: task,
		url: ({ projectId: _projectId, taskId, location }) => ({
			...location,
			pathname: `${page}/${page.includes(_projectId) ? '' : _projectId + '/'}${taskId}`,
		}),
	})
	let subtaskIndex = 0

	return (
		<div>
			<div className="tt-w-full">
				{!hideHead && (
					<div className="tt-flex tt-items-center tt-justify-between tt-mb-12">
						<div className="tt-text-16 tt-font-semibold">{t('subtasks')}</div>

						{getIsResolvedAddingSubtasks(mainTask) && !mainTask?.project?.isEnd && (
							<div className="tt-flex tt-items-center tt-h-32 tt-gap-8">
								<CommentedDisability
									optionDisabled={isCommittee}
									disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
								>
									<Button
										id={getID({ prefix: id, field: 'sub_tasks', action: 'add', type: 'button_open' })}
										onClick={onChoice}
										type="button"
										className="tt-border-transparent tt-min-h-32 tt-h-32 tt-px-16 tt-z-10"
										variant="outlined"
									>
										{t('choose')}
									</Button>
								</CommentedDisability>
								<CommentedDisability
									optionDisabled={isCommittee}
									disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
								>
									<Button
										id={getID({ prefix: id, field: 'sub_tasks', action: 'create', type: 'button_open' })}
										onClick={onCreate}
										startIcon={<AddIcon />}
										className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
									>
										{t('create')}
									</Button>
								</CommentedDisability>
							</div>
						)}
					</div>
				)}

				<div className="tt-overflow-hidden tt-rounded-lg tt-flex tt-flex-col">
					<table className="tt-w-full">
						{subtasks?.map((item, index) => {
							subtaskIndex = index
							return <TaskRow {...getCommonProps(item, index)} />
						})}

						{limit && accordionSubTasks?.length && (
							<Accordion
								expanded={expanded === 'panel1'}
								disableGutters
								sx={{ display: 'flex', flexDirection: 'column', '&:before': { content: 'none' } }}
								onChange={handleChange('panel1')}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									sx={(theme) => ({ fontWeight: 600, order: '1', color: theme.palette.secondary.main })}
									aria-controls="panel1bh-content"
									id={getID({ prefix: id, field: 'sub_tasks', fieldPostfix: 'show', type: 'button_toggle' })}
								>
									{Boolean(!expanded) && `+${mainTask?.subtasks?.length - limit}`}
								</AccordionSummary>

								<AccordionDetails sx={{ padding: 0 }}>
									{accordionSubTasks?.map((item) => (
										<TaskRow {...getCommonProps(item, ++subtaskIndex)} />
									))}
								</AccordionDetails>
							</Accordion>
						)}
					</table>

					{!mainTask?.subtasks || mainTask?.subtasks.length === 0 ? (
						<EmptyPlaceholder text={t('empty_subtasks_list')} />
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Epic

function getIsResolvedAddingSubtasks(epic) {
	if (!epic) return false

	// template
	if (!epic?.project) {
		return epic?.isMyTask
	}

	const { with_administration } = epic.project

	if (with_administration === false) {
		return epic?.isMyTask
	}

	if (with_administration === true) {
		return (
			!epic?.task_status?.project_status?.is_final && !epic?.task_status?.project_status?.is_request && epic?.isMyTask
		)
	}
}

function TaskRemove({ remove }) {
	const { pathname, search } = useLocation()
	return (
		<Link to={pathname + search}>
			<div
				onClick={remove}
				className="tt-h-20 tt-w-20  tt-text-gray-500 hover:tt-text-primary tt-flex tt-items-center tt-justify-center"
			>
				<HighlightOffOutlinedIcon className={['tt-duration-500 tt-h-full tt-w-full'].join(' ')} />
			</div>
		</Link>
	)
}
