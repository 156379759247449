/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unreachable */
import React, { memo } from 'react'
import { notifTypes } from 'src/old-app/constants/notifications'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDeleteOneNotifMutation } from 'src/old-app/service/Notif'
import { useDispatch } from 'react-redux'
import { notifsActions } from 'src/old-app/store/notifSlice'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'

import ConfirmedTaskText from 'src/old-app/components/notifications/ConfirmedTaskText'
import ChangeStatusTaskText from 'src/old-app/components/notifications/ChangeStatusTaskText'
import ConformationTaskText from 'src/old-app/components/notifications/ConformationTaskText'
import moment from 'moment'
import { requestTypes } from 'src/old-app/constants/requests'
import TaskText from './TaskText'
import ProjectText from './ProjectText'
import RequestApproved from '../../components/requests/RequestApproved'
import RequestCancel from 'src/old-app/components/requests/RequestCancel'
import { useTranslation } from 'react-i18next'

const RequestItem = memo(({ item, type, id, disabledLink }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { requestId } = useParams()
	const { search } = useLocation()

	const goInfo = () => {
		if (disabledLink) return
		navigate(`/requests/${id}/${type}${search}`)
	}

	return (
		<div
			className={[
				'tt-rounded-lg tt-border group tt-p-12  tt-flex tt-flex-wrap tt-gap-32 tt-relative',
				requestId === id ? 'tt-bg-white tt-border-gray-200' : 'tt-bg-gray-100',
			].join(' ')}
		>
			<div className="tt-flex tt-flex-col tt-gap-12 tt-w-full">
				<div onClick={goInfo} className="tt-flex tt-gap-16 tt-flex-1 tt-cursor-pointer">
					<div className="tt-flex tt-flex-col tt-gap-2 tt-flex-1">
						<div className="tt-text-16 font-medium ">
							{requestTypes.TASK === type && item?.task?.title}
							{requestTypes.PROJECT === type && item.name}
						</div>
						<div className="tt-text-gray-600">
							{requestTypes.TASK === type && <TaskText item={item} />}
							{requestTypes.PROJECT === type && <ProjectText item={item} />}
						</div>
					</div>
					<span className="tt-text-gray-500">{moment(item?.created_at).format('LLL')}</span>
				</div>
				<div>
					{requestTypes.PROJECT === type && (
						<div className="tt-flex tt-items-center tt-gap-12">
							<RequestApproved item={item} id={id} type={type} label={t('approve_creation')} />
							<RequestCancel type={type} label={t('reject')} request={item} />
						</div>
					)}
					{requestTypes.TASK === type && (
						<>
							<div className="tt-flex tt-items-center tt-gap-12">
								<RequestApproved item={item} id={id} type={type} label={t('approve_changes')} />
								<RequestCancel type={type} label={t('reject')} request={item} />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
})

RequestItem.displayName = 'RequestItem'
export default RequestItem
