import React, { FC, useState } from 'react'
import Autocomplete from '../autocomplete'
import useDebounce from 'src/shared/hooks/useDebounce'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useDeleteTaskTemplateMutation, useGetTaskTemplateQuery } from '../../../old-app/service/Tasks'
import { IconButton } from '@mui/material'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	multiple?: boolean
	project_id?: boolean
}

const TaskTemplatesChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Шаблон', isIds, multiple = false, project_id = undefined } = p

	const [deleteTemplateTask] = useDeleteTaskTemplateMutation()

	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)

	const { data: taskTemplatesData, refetch } = useGetTaskTemplateQuery({ project_id: project_id })

	const list: any[] = taskTemplatesData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	const deleteTemplateTaskFn = async (taskId: string) => {
		try {
			await deleteTemplateTask(taskId)
			refetch()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Autocomplete
			multiple={multiple}
			placeholder={placeholder}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.name}
			renderOptionLabel={(option: any) => option?.name}
			renderOptionActions={(option: any) => (
				<>
					<IconButton onClick={() => deleteTemplateTaskFn(option?.id)} className="tt-w-26 tt-h-26">
						<DeleteOutlineOutlinedIcon />
					</IconButton>
				</>
			)}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default TaskTemplatesChoice
