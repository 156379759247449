import { Button } from '@mui/material'
import { getAdminRole, getUser, getUserRole } from 'src/old-app/store/userSlice'
import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AddChildProject from 'src/old-app/components/project/AddChildProject'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import ProjectFilters from 'src/old-app/components/project/ProjectFilters'
import ProjectSideBarCheck from 'src/old-app/components/project/ProjectSideBarCheck'

import PageWithSideBar from 'src/old-app/containers/PageWithSideBar'
import LinkMyProject from '../my/LinkMyProject'
import { useDispatch } from 'react-redux'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { getID } from '../../../shared/config/fields-ids'
import { ProjectsTable } from '../../../entities/project'
import { useTranslation } from 'react-i18next'

const Projects = memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(tasksActions.getTasks([]))
	}, [])

	return (
		<PageWithSideBar
			id={getID({ entity: 'project' })}
			param="projectId"
			pageLink="project"
			headerCreateComponent="project-create"
			headerTitle={t('projects')}
			buttonLabel={t('create_new.project')}
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			// headerTopRightLeft={
			// 	<div className="flex items-center gap-2">
			// 		<LinkMyProject />
			// 	</div>
			// }
			content={
				<ProjectsTable params={undefined} />
				// <div>
				// 	<ProjectFilters />
				// 	<ProjectsTable />
				// </div>
			}
			rightSidebarContent={<ProjectSideBarCheck backTo="/project" />}
		/>
	)
})

Projects.displayName = 'Projects'
export default Projects
