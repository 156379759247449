import React, { memo } from 'react'
import { useGetProtocolUsersQuery } from 'src/old-app/service/Protocol'
import UserCell from '../../tables/UserCell'

const GetProtocolUsers = memo(({ protocolId, classNameList, classNameItem }) => {
	const { data } = useGetProtocolUsersQuery({ protocol_id: protocolId })

	return (
		<div className={classNameList}>
			{data?.data?.map((item, index) => (
				<UserCell className={classNameItem} key={item.id} id={item.user_id} />
			))}
		</div>
	)
})

GetProtocolUsers.displayName = 'GetProtocolUsers'
export default GetProtocolUsers
