import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import createHeaders from '../../../old-app/service/createHeaders'
import { BASE_URL } from '../../../shared/config'
import { User } from '../../user'
import { IResponseWithPagination } from '../../../shared/models/api'

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: createHeaders,
})

// interface IUser {
// 	avatar: NullishString;
// 	avatar256: NullishString;
// 	avatar96: NullishString;
// 	created_at: string;
// 	deleted_at: {
// 		time: string;
// 		valid: boolean;
// 	} | null;
// 	email: string;
// 	first_name: string;
// 	id: string;
// 	keycloak_id: string;
// 	last_name: string;
// 	middle_name: NullishString;
// 	position: NullishString;
// 	updated_at: string;
// 	user_name: string;
// }

interface IProjectsTemplate {
	author: string
	author_view: User
	created_at: string
	deleted_at: { time: string; valid: boolean } | null
	id: string
	name: string
	template: object
	updated_at: string
}

type IResponseProjectsTemplate = IResponseWithPagination<IProjectsTemplate>

enum Tags {
	Template = 'ProjectsTemplate',
	List = 'LIST',
}

export const projectsTemplatesAPI = createApi({
	reducerPath: 'projects-templates-api',
	tagTypes: [Tags.Template],
	baseQuery,

	endpoints: (build) => ({
		getListProjectsTemplates: build.query<IResponseProjectsTemplate, {}>({
			query: (params) => ({
				url: '/project-template/',
				params,
			}),
			providesTags: () =>
				// result
				// 	? [
				// 			...result.data.map(({ id }) => ({
				// 				type: Tags.Template as const,
				// 				id,
				// 			})),
				// 			{ type: Tags.Template, id: Tags.List },
				// 	  ]
				// 	:
				[{ type: Tags.Template, id: Tags.List }],
		}),

		getOneProjectsTemplate: build.query({
			query: (id) => ({
				url: `/project-template/${id}`,
			}),
			providesTags: (res) => [{ type: Tags.Template, id: res?.data.id }],
		}),

		createOneProjectsTemplate: build.mutation({
			query: ({ id, body }) => ({
				method: 'POST',
				url: `/project-template/${id}/project`,
				body,
			}),
			invalidatesTags: [{ type: Tags.Template, id: Tags.List }],
		}),

		deleteOneProjectsTemplate: build.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/project-template/${id}`,
				body: {},
			}),
			invalidatesTags: [Tags.Template],
		}),

		updateOneProjectsTemplate: build.mutation({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/project-template/${id}`,
				body,
			}),
			invalidatesTags: [Tags.Template],
		}),
	}),
})

export const {
	useGetListProjectsTemplatesQuery,
	useCreateOneProjectsTemplateMutation,
	useDeleteOneProjectsTemplateMutation,
	useGetOneProjectsTemplateQuery,
	useUpdateOneProjectsTemplateMutation,
} = projectsTemplatesAPI
