import * as React from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined'
import styles from './task-incoming-toggle.module.scss'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type HandleChange = (event: React.MouseEvent<HTMLElement>, value: any) => void
const key = 'tasks_incoming'

export const TaskIncomingToggle = () => {
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const { search } = useLocation()
	const navigate = useNavigate()

	const alignment = searchParams.get(key) ?? 'false'

	const handleChange: HandleChange = (event, value) => {
		const params = new URLSearchParams(search)

		if (value) {
			params.set(key, value)
		} else {
			params.set(key, 'false')
		}

		navigate({
			search: params.toString(),
		})
	}

	return (
		<ToggleButtonGroup
			color="primary"
			value={alignment}
			exclusive
			onChange={handleChange}
			className={styles['task-incoming-toggle']}
		>
			<Tooltip
				classes={{ tooltip: 'tt-max-w-[600px]' }}
				title={alignment === 'false' ? t('my_tasks_toggle.show_incoming') : t('my_tasks_toggle.show_all')}
			>
				<ToggleButton
					id="task-incoming-toggle"
					className={alignment === 'true' ? styles['task-incoming-toggle-selected'] : ''}
					value={'true'}
				>
					<MarkChatReadOutlinedIcon />
					{/*{alignment==="true" ? <ChatBubbleOutlineOutlinedIcon/>: <MarkChatReadOutlinedIcon/> }*/}
				</ToggleButton>
			</Tooltip>
		</ToggleButtonGroup>
	)
}
