import { Badge, Card, CardContent, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'

export const NovuCustomItem = ({
	message,
	// onActionButtonClick,
	onNotificationClick,
}) => {
	return (
		<InView
			children={({ ref, inView, entry }) => {
				const height = entry?.boundingClientRect.height
				return (
					<Card
						ref={ref}
						style={!inView ? { height: height > 1 ? height : 100 } : undefined}
						onClick={inView ? onNotificationClick : undefined}
						className={'tt-list-none tt-my-4 tt-shadow-sm tt-border tt-cursor-pointer'}
					>
						{inView && (
							<CardContent style={{ padding: '1em' }}>
								{!message.seen && <Badge sx={{ float: 'right', padding: '1em' }} variant={'dot'} color="error" />}

								<Typography
									variant="h3"
									gutterBottom
									sx={{
										fontSize: '1em',
										fontWeight: 'bold',
										color: message.read ? 'text.disabled' : 'unset',
									}}
								>
									{message.content}
								</Typography>

								<Tooltip placement={'top-start'} title={moment(message.createdAt).format('LLLL')}>
									<Typography
										component={'span'}
										fontSize={'small'}
										fontWeight={300}
										color={`text.${message.read ? 'disabled' : 'secondary'}`}
									>
										{moment(message.createdAt).fromNow()}
									</Typography>
								</Tooltip>

								<Typography fontSize={'small'} color={`text.${message.read ? 'disabled' : 'secondary'}`}>
									<Names type={message.templateIdentifier} payload={message.payload} />
								</Typography>
							</CardContent>
						)}
					</Card>
				)
			}}
		/>
	)
}

function Names({ payload, type }) {
	const { t } = useTranslation()

	const byFields = {
		Comment: type === 'referenced-in-the-comment' ? t('task_comment') : t('rejection_comment'),
		ProjectName: t('project'),
		TaskTitle: t('task'),
		CommandName: t('team'),
		Name: type === 'project-resumed' ? t('initiator') : t('title'),
	}

	return Object.entries(byFields).map(
		([field, label]) =>
			payload[field] && (
				<Typography component={'span'} className="tt-flex tt-flex-wrap" key={field} fontSize={'small'} noWrap>
					<span className="tt-mt-8">{label}:</span>
					<strong className="tt-whitespace-pre-wrap my-truncate tt-break-words tt-w-full">{payload[field]}</strong>
				</Typography>
			)
	)
}
