import React from 'react'
import CustomAvatar from '../ui/CustomAvatar'

const UserCellShow = ({ user, className, hideAvatar }) => {
	return (
		<div className={['tt-flex tt-gap-4 tt-items-center tt-text-black tt-truncate'].join(' ')}>
			{!hideAvatar && (
				<CustomAvatar
					className="tt-w-20 tt-text-12  tt-text-gray-900 tt-font-normal tt-h-20 tt-flex tt-items-center tt-justify-center tt-rounded-full tt-mr-5"
					alt={user?.first_name ?? 'avatar'}
					src={user?.avatar256}
				/>
			)}
			<span className="tt-text-gray-900 tt-truncate  tt-font-medium">
				{user?.last_name} {user?.first_name}
			</span>
		</div>
	)
}

export default UserCellShow
