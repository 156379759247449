import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { StyledEngineProvider } from '@mui/material/styles'
import DataProviders from 'src/old-app/providers/DataProviders'
import StoreProvider from '../store/store-provider'
import AppContext from 'src/old-app/AppContext'
import routes from 'src/old-app/configs/routesConfig'
import { IS_UCP, UCP_APP_NAME } from 'src/shared/config'
import { AppAuth } from '../app-auth'
import TranslationProvider from 'src/features/translate/TranslationProvider'
import { checkPublic } from 'src/shared/lib/check-public'

// TODO: Refactor

const WithProviders = (Component: any) => () => {
	const basename = IS_UCP ? UCP_APP_NAME : undefined

	const isPublic = checkPublic()

	return (
		<BrowserRouter basename={basename}>
			<CookiesProvider>
				<StoreProvider>
					<TranslationProvider>
						<AppContext.Provider
							value={{
								routes,
							}}
						>
							<StyledEngineProvider injectFirst>
								{isPublic ? (
									<DataProviders skips={{ user: true, protocol_place: true }}>
										<Component />
									</DataProviders>
								) : (
									<AppAuth>
										<DataProviders skips={{}}>
											<Component />
										</DataProviders>
									</AppAuth>
								)}
							</StyledEngineProvider>
						</AppContext.Provider>
					</TranslationProvider>
				</StoreProvider>
			</CookiesProvider>
		</BrowserRouter>
	)
}

export default WithProviders
