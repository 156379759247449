import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ProjectContent, ProjectShowContext } from '../../../entities/project'
import PageWithSideBar from '../../../old-app/containers/PageWithSideBar'
import TeamSideBarCheck from '../../../old-app/components/team/TeamSideBarCheck'
import { TeamRequestsContent } from '../../../features/team'
import { useTranslation } from 'react-i18next'

interface IProps {}

const TeamsRequest: React.FC<IProps> = () => {
	const { t } = useTranslation()

	const { teamId } = useParams()
	const { search } = useLocation()

	const page = `/team-requests/${teamId}`
	const backTo = `/team-requests/`

	return (
		<PageWithSideBar
			param="teamId"
			contentClassName="!tt-h-full !tt-pb-8"
			className="!tt-pb-0"
			pageLink={`${page}/${search}`}
			buttonLabel={undefined}
			content={<TeamRequestsContent />}
			rightSidebarContent={<TeamSideBarCheck backTo={backTo} />}
			headerTitle={t('teams_confirmation')}
			headerCreateComponent={undefined}
			headerOnCreate={undefined}
			headerTopRight={undefined}
			headerTopRightLeft={undefined}
			hideCreateButton
			id={undefined}
			leftTitleComponent={undefined}
			showCreateButton={undefined}
		/>
	)
}

export default TeamsRequest
