import React from 'react'
import styles from './styles.module.scss'

interface IProps {}

const TableCellEmpty: React.FC<IProps> = () => {
	return <div className={styles['table-cell-empty']}>-</div>
}

export default TableCellEmpty
