import { getToken } from 'src/@entities/session'
import { checkPublic } from 'src/shared/lib/check-public'
import { uuid } from 'src/shared/lib/utils'

const createHeaders = (headers, isFile) => {
	const token = getToken()
	const isPublic = checkPublic()

	if (token && !isPublic) {
		headers.append('Authorization', `Bearer ${token}`)
	}
	// headers.append('Access-Control-Allow-Origin', '*');
	headers.append('Content-Type', 'application/json')
	headers.append('X-Request-ID', uuid())

	return headers
}
export default createHeaders
