import { v4 as uuidV4 } from 'uuid'

export const uuid = () => uuidV4()

export const parseJwt = <T>(token: string): T => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)
	return JSON.parse(jsonPayload) as T
}
