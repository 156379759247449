import React, { FC, useState, useEffect } from 'react'
import Autocomplete from '../autocomplete'
import { useGetUsersByIdsQuery } from '../../../old-app/service/Users'
import useDebounce from 'src/shared/hooks/useDebounce'
import { useGetProjectsQuery } from '../../../old-app/service/Projects'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	isIds?: boolean
	multiple?: boolean
}

const ProjectsChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Проект', isIds, multiple = false } = p

	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)
	const { data: projectsData } = useGetProjectsQuery({
		name: debounceSearch,
		confirmation_status: 'APPROVED',
		for_sap_protocol: true,
	})

	const list: any[] = projectsData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	return (
		<Autocomplete
			multiple={multiple}
			placeholder={placeholder}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => option?.name}
			renderOptionLabel={(option: any) => option?.name}
			list={list}
			onInputChange={onInputChange}
			value={value}
			//defaultValue={defaultValue}
			onChange={onChange}
		/>
	)
}

export default ProjectsChoice
