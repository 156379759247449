import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import { Navigate } from 'react-router-dom'
import useHasRole from '../../hooks/useHasRole'

interface IProps {
	children: ReactNode
	roles: string[]
}

const PrivatePage: React.FC<IProps> = ({ children, roles }) => {
	const { isHasRole } = useHasRole(roles)

	if (!isHasRole) return <Navigate to="/404" />

	return <>{children}</>
}

export default PrivatePage
