import React, { FC, useState, useEffect } from 'react'
import Autocomplete from '../autocomplete'
import { useGetSearchUserQuery, useGetUsersByIdsQuery } from '../../../old-app/service/Users'
import useDebounce from 'src/shared/hooks/useDebounce'
import { UserShow } from 'src/shared/components'

interface IProps {
	value: any
	placeholder?: string
	onChange: (value: any) => void
	error?: any
	isIds?: boolean
	disabled?: boolean
	getOptionDisabled?: (value: any) => any
	multiple?: boolean
}

const UsersChoice: FC<IProps> = (p) => {
	const { value, onChange, placeholder = 'Участники', isIds, error, getOptionDisabled, multiple = true, disabled = false } = p

	const [search, setSearch] = useState<string>('')
	const debounceSearch = useDebounce(search, 300)
	const { data: usersData } = useGetSearchUserQuery({ name: debounceSearch })

	const usersByIdQuery = useGetUsersByIdsQuery(value?.toString(), {
		skip: !value?.length || !isIds,
	})

	useEffect(() => {
		if (usersByIdQuery.isSuccess && isIds) {
			const users = usersByIdQuery?.data?.data ?? []
			const newUsers = value.map((val: any) => users.find((user: any) => user.id == val))
			onChange(newUsers)
		}
	}, [usersByIdQuery?.data])

	const list: any[] = usersData?.data ?? []

	const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
		setSearch(value)
	}

	return (
		<Autocomplete
			disabled={disabled}
			multiple={multiple}
			placeholder={placeholder}
			error={error}
			getOptionDisabled={getOptionDisabled}
			// TODO: Вынести в отдельную функцию
			optionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
			renderOptionLabel={(option: any) => <UserShow isShowEmail isShowMiddleName user={option} />}
			list={list}
			onInputChange={onInputChange}
			value={value}
			onChange={onChange}
		/>
	)
}

export default UsersChoice
