import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getRequestStatus, getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import { Controller, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useUpdateTaskMutation } from 'src/old-app/service/Tasks'
import { fileUpload } from 'src/old-app/service/File'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { useCreateTaskCompleteFileMutation } from 'src/old-app/service/TaskCompleteFile'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import getUser from 'src/old-app/utils/getUser'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { getListsCards, scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import Files from '../task/Files'
import ErrorText from '../../ui/ErrorText'
import { useGetUser } from 'src/shared/hooks'
import { checkFileSize } from 'src/shared/lib/checkFileSize'
import { useTranslation } from 'react-i18next'

const AddTaskReport = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		completeFiles: yup.array().min(1, t('completion_report_is_required')),
	})
	const user = useGetUser()
	const task = useSelector(getTask)
	const requestStatus = useSelector(getRequestStatus)
	const taskStatusId = task?.task_status?.project_status_id
	const boardTasks = useSelector((state) => getListsCards(state, taskStatusId))
	const dispatch = useDispatch()

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			completeFiles: [],
		},
		resolver: yupResolver(schema),
	})

	const [drag, setDrag] = useState(false)
	const [updateTask, taskResult] = useUpdateTaskMutation()
	const [createTaskCompleteFile, taskCompleteFileResult] = useCreateTaskCompleteFileMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		if (taskResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: taskResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (taskCompleteFileResult?.isError) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: taskCompleteFileResult?.error?.data?.error ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					variant: 'error',
				})
			)
		}
	}, [taskResult, taskCompleteFileResult])

	const addReportHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			setIsDisabled(true)

			const taskId = task?.id
			const { completeFiles } = data
			const completeFilesData = []

			for (const file of completeFiles) {
				if (!checkFileSize(file.size)) {
					dispatch(interfaceActions.setIsLoading(false))
					setIsDisabled(false)
					dispatch(
						showMessage({
							message: t('maximum_file_size'),

							anchorOrigin: {
								vertical: 'top',
								horizontal: 'top-right',
							},
							variant: 'warning',
						})
					)
					return
				}
			}

			await Promise.all(
				completeFiles.map(async (item) => {
					const fileData = await fileUpload(item)
					if (!fileData.data) return
					completeFilesData.push(fileData.data)
					const fileId = fileData?.data?.id
					await createTaskCompleteFile({
						author_id: user.id,
						file_id: fileId,
						task_id: taskId,
					})
				})
			)

			await updateTask({
				body: {
					project_status_id: requestStatus.id,
					task_priority_id: task?.priority?.id,
				},
				id: taskId,
			})

			let index = 0
			boardTasks?.filter((item, i) => {
				if (item.id === task.id) {
					index = i
				}
			})

			const result = {
				source: {
					droppableId: taskStatusId,
					index,
				},
				destination: {
					droppableId: requestStatus.id,
					index: 0,
				},
			}
			dispatch(
				scrumboardActions.updateOneListCard({
					listId: taskStatusId,
					cardId: task.id,
					data: {
						task_status: { ...task.task_status, project_status_id: requestStatus.id },
						complete_file: completeFilesData,
					},
				})
			)
			dispatch(scrumboardActions.reorderList(result))
			dispatch(
				tasksActions.updateTask({
					task_status: { ...task.task_status, project_status_id: requestStatus.id },
					complete_file: completeFilesData,
					id: taskId,
				})
			)

			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('report_added'),
					autoHideDuration: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
			dispatch(interfaceActions.setIsLoading(false))
		} catch (error) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const dragStartHandler = (e) => {
		e.preventDefault()
		setDrag(true)
	}
	const dragLeaveHandler = (e) => {
		e.preventDefault()
		setDrag(false)
	}
	const dropHandler = (e) => {
		e.preventDefault()
		const newfiles = [...e.dataTransfer.files]
		const oldFiles = getValues('completeFiles')
		const files = [...oldFiles, ...newfiles]

		setValue('completeFiles', files)
		setDrag(false)
	}

	return (
		<form
			onSubmit={handleSubmit(addReportHandler)}
			className="w-600"
			onDragStart={dragStartHandler}
			onDragLeave={dragLeaveHandler}
			onDragOver={dragStartHandler}
			onDrop={dropHandler}
		>
			<div>
				<DialogHeader title={t('add_report_for_task')} />
				<DialogMain>
					<Controller
						render={({ field: { value, onChange } }) => <Files onChange={onChange} value={value} label={t('files')} />}
						name="completeFiles"
						control={control}
					/>
					<ErrorText errors={errors} field="completeFiles" />
				</DialogMain>
				<DialogFooter rightLabel={t('send')} isDisabled={isDisabled} />
			</div>
		</form>
	)
}

export default AddTaskReport
