import { useGetProjectsQuery } from '../../../old-app/service/Projects'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { AllMyTasksPageQueryParams, customArrayParam, dateParam } from '../../../old-app/main/my/AllMyTasksList'
import { useGetTeamsQuery } from '../../../old-app/service/Teams'

const Params: any = {
	slug: StringParam,
	name: StringParam,
	completed_at_start: dateParam,
	completed_at_end: dateParam,
	order_sort: withDefault(StringParam, 'desc'),
	author: customArrayParam,
	tags: customArrayParam,
	confirmation_status: customArrayParam,
}

const useTeams = ({ params }: { params: any }) => {
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const {
		data: teamsData,
		isLoading,
		isFetching,
	} = useGetTeamsQuery(
		{
			slug: queryParams.slug,
			name: queryParams.name,
			created_at_sort: queryParams.order_sort,
			start_completed_at: queryParams.completed_at_start,
			end_completed_at: queryParams.completed_at_end,
			authors: queryParams?.author?.join(','),
			confirmation_status: queryParams?.confirmation_status?.join(','),
			hashtag_ids: queryParams?.tags?.join(','),
			...params,
		},
		{ refetchOnMountOrArgChange: true }
	)

	const teams = teamsData?.data ?? []
	const meta = teamsData?.meta ?? {}

	return { teams, isLoading, meta, isFetching }
}
export default useTeams
