import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	tasks: [],
	notTasks: [],
	task: null,
	timeSpentInputId: null,
	timeSpentInputHoursId: null,
	timeSpentButtonId: null,
	taskTypes: [],
	files: [],
	completeFiles: [],
	priorities: [],
	statuses: [],
	status: null,
	deadlines: [],
	subs: [],
	comments: [],
}

const reducers = {
	getTasks: (state, action) => {
		state.tasks = action.payload
	},
	getNotTasks: (state, action) => {
		state.notTasks = action.payload
	},
	getTimeSpentInputId: (state, action) => {
		state.timeSpentInputId = action.payload
	},
	getTimeSpentInputHoursId: (state, action) => {
		state.timeSpentInputHoursId = action.payload
	},
	getTimeSpentButtonId: (state, action) => {
		state.timeSpentButtonId = action.payload
	},
	getAddTasks: (state, action) => {
		state.tasks = [...state.tasks, ...action.payload]
	},
	getComments: (state, action) => {
		state.comments = action.payload
	},
	getDeadlines: (state, action) => {
		state.deadlines = action.payload
	},
	getSubs: (state, action) => {
		state.subs = action.payload
	},
	addSubs: (state, action) => {
		const isSub = state.subs.find((item) => item.user_id === action.payload.user_id)
		if (!isSub) {
			state.subs = [...state.subs, action.payload]
		}
	},
	deleteSubs: (state, action) => {
		state.subs = state.subs.filter((item) => item.user_id !== action.payload)
	},

	getPriorities: (state, action) => {
		state.priorities = action.payload
	},
	getStatuses: (state, action) => {
		state.statuses = action.payload
	},
	deleteStatus: (state, action) => {
		state.statuses = state.statuses.filter((item) => item.id !== action.payload)
	},
	updateStatus: (state, action) => {
		state.statuses = state.statuses.map((item) => {
			if (item.id === action.payload.id) {
				item = action.payload
			}
			return item
		})
	},

	getStatus: (state, action) => {
		state.status = action.payload
	},
	addStatus: (state, action) => {
		state.statuses = [action.payload, ...state.statuses]
	},
	addDeadlines: (state, action) => {
		state.task = [...state.deadlines, action.payload]
	},

	deleteStatuses: (state, action) => {
		state.statuses = state.statuses.filter((item) => item.id !== action.payload)
	},

	getFiles: (state, action) => {
		state.files = action.payload
	},

	getTask: (state, action) => {
		state.task = action.payload
	},
	addTask: (state, action) => {
		state.tasks = [action.payload, ...state.tasks]
	},
	addFile: (state, action) => {
		state.files = [...state.files, action.payload]
	},

	getCompleteFiles: (state, action) => {
		state.completeFiles = action.payload
	},
	updateTask: (state, action) => {
		state.task = { ...state.task, ...action.payload }
		// state.tasks = state.tasks.map((item) => {
		// 	if (item.id === action.payload.id) {
		// 		item = { ...item, ...action.payload }
		// 	}
		// 	return item
		// })
	},
	setSubTasks: (state, action) => {
		// state.task = { ...state.task, ...action.payload, subtasks: action.payload.subtasks }
		state.tasks = state.tasks.map((item) => {
			if (item.id === action.payload.taskId) {
				item = { ...item, subtasks: action.payload.subtasks }
			}
			return item
		})
	},
	addSubTasks: (state, action) => {
		// state.task = { ...state.task, ...action.payload, subtasks: action.payload.subtasks }
		state.tasks = state.tasks.map((item) => {
			if (item.id === action.payload.taskId) {
				item = {
					...item,
					subtasks: item?.subtasks ? [action.payload.task, ...item?.subtasks] : [action.payload.task],
					type: state.taskTypes.find((t) => t.name === 'Эпик'),
				}
			}
			return item
		})
	},
	deleteSubTasks: (state, action) => {
		// state.task = { ...state.task, ...action.payload, subtasks: action.payload.subtasks }
		state.tasks = state.tasks.map((item) => {
			if (item.id === action.payload.taskId) {
				const subtasks = item?.subtasks ? item.subtasks.filter((ts) => ts.id !== action.payload.subTaskId) : []

				item = { ...item, subtasks }
			}
			return item
		})
	},
	updateDeadline: (state, action) => {
		state.task.deadlines = [action.payload, ...state.task.deadlines]
		state.tasks = state.tasks.map((item) => {
			if (item.id === action.payload.task_id) {
				if (item?.deadlines) {
					item.deadlines = [action.payload, ...item.deadlines]
				}
			}
			return item
		})
	},

	deleteFile: (state, action) => {
		state.files = state.files.filter((item) => item.id !== action.payload)
	},
	deleteTask: (state, action) => {
		state.tasks = state.tasks.filter((item) => item.id !== action.payload)
	},
	getTaskTypes: (state, action) => {
		state.taskTypes = action.payload
	},
}

const tasksSlice = createSlice({
	name: 'tasks',
	initialState,
	reducers,
})

export const getTasks = (state) => state.tasks.tasks
export const getDeadlines = (state) => state.tasks.deadlines
export const getTask = (state) => state.tasks.task
export const getNotTasks = (state) => state.tasks.notTasks
export const getSubs = (state) => state.tasks.subs
export const getTaskTypes = (state) => state.tasks.taskTypes
export const getFiles = (state) => state.tasks.files

export const getPriorities = (state) => state.tasks.priorities
export const getStatuses = (state) => state.tasks.statuses
export const getStatus = (state) => state.tasks.status
export const getTimeSpentInputId = (state) => state.tasks.timeSpentInputId
export const getTimeSpentInputHoursId = (state) => state.tasks.timeSpentInputHoursId
export const getTimeSpentButtonId = (state) => state.tasks.timeSpentButtonId
export const getComments = (state) => state.tasks.comments
export const getRequestStatus = (state) => state.tasks.statuses.find((item) => item?.is_request)

export const tasksReducer = tasksSlice.reducer
export const tasksActions = tasksSlice.actions
