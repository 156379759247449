import { getTask } from 'src/old-app/store/tasksSlice'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Comments from './Comments'
//import CompleteFiles from './CompleteFiles'
import Epic from './Epic'
import Files from './Files'
import HistoryChanges from './HistoryChanges'
import TaskInfo from './TaskInfo'
import TaskTimeTracker from './TaskTimeTracker'
import { getProject, getProjectCuratorRole, getProjectUserRole } from 'src/old-app/store/projectsSlice'

import { Box, Tab, Tabs } from '@mui/material'
import { TaskAdministration, TaskContext } from 'src/entities/task'
import { getID } from '../../../shared/config/fields-ids'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUserRole } from 'src/old-app/store/userSlice'
import { useProjectShow } from 'src/entities/project'

const paramsKey = 'task_tab'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`task-mainpanel-${index}`}
			aria-labelledby={`task-main-${index}`}
			{...other}
			className="tt-py-16 tt-w-full"
		>
			{children}
		</div>
	)
}

function a11yProps(index) {
	return {
		// id: `task-main-${index}`,
		'aria-controls': `task-mainpanel-${index}`,
	}
}

const TaskMain = ({ hideFilesSort, hideFileDelete, id }) => {
	const { t } = useTranslation()
	const projectShow = useProjectShow()
	const task = useSelector(getTask)
	const project = useSelector(getProject)
	const isUser = useSelector(getProjectUserRole)
	const isCurator = useSelector(getProjectCuratorRole)

	const isCommittee = projectShow?.isCommittee

	const files = task?.task_files || []

	const _idTabs = getID({ prefix: id, place: 'tabs' })

	const items = useMemo(() => {
		const is = [
			{ id: 1, label: t('overview'), slug: 'review', children: <TaskInfo id={_idTabs} />, isShow: true },
			{
				id: 2,
				label: t('administrating'),
				slug: 'administration',
				children: <TaskAdministration id={_idTabs} />,
				isDisabled: isCommittee,
				isShow: project?.with_administration,
			},
			{
				id: 3,
				label: t('files'),
				slug: 'files',
				children: <Files id={_idTabs} hideFilesSort={hideFilesSort} hideFileDelete={hideFileDelete} files={files} />,
				isShow: true,
			},
			{
				id: 4,
				label: t('comments'),
				slug: 'comment',
				children: <Comments id={_idTabs} />,
				isDisabled: false,
				isShow: true,
			},
			{
				id: 5,
				label: t('time_tracking'),
				slug: 'time_tracker',
				children: <TaskTimeTracker id={_idTabs} />,
				isDisabled: false,
				isShow: true,
			},
			{
				id: 6,
				label: t('tasks'),
				slug: 'epic',
				children: (
					<Epic hideHead={task?.project?.isContract ? (isCurator || isUser ? true : false) : false} id={_idTabs} />
				),
				isDisabled: isCommittee,
				isShow: true,
			},
			{
				id: 7,
				label: t('history'),
				slug: 'history_changes',
				children: <HistoryChanges id={_idTabs} />,
				isDisabled: false,
				isShow: true,
			},
		]
		return is?.filter((item) => item.isShow)
	}, [project, task, isUser])

	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className="tt-w-full">
			<Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{items?.map((item, index) => (
						<Tab
							id={getID({ prefix: [_idTabs, item.slug].join('-'), type: 'button_tab' })}
							label={item.label}
							{...a11yProps(index)}
							disabled={item?.isDisabled}
						/>
					))}
				</Tabs>
			</Box>
			{items?.map((item, index) => (
				<TabPanel value={value} index={index}>
					{item.children}
				</TabPanel>
			))}
		</div>
	)
}

export default TaskMain
