/* eslint-disable prefer-const */
import { Skeleton } from '@mui/material'
import { getProject, getProjectCuratorRole, getProjectUserRole, projectsActions } from 'src/old-app/store/projectsSlice'
import { getLists, scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { getTask, tasksActions } from 'src/old-app/store/tasksSlice'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	useApprovedTaskMutation,
	useGetStatusQuery,
	useUpdateStatusOrderTaskMutation,
	useUpdateTaskMutation,
} from 'src/old-app/service/Tasks'
import BoardAddList from './board-list/BoardAddList'
import BoardColumn from './board-list/BoardColumn'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { requestsActions } from 'src/old-app/store/requestSlice'
import { requestTypes } from 'src/old-app/constants/requests'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import TaskFilters from '../TaskFilters'
import { useProjectShow } from '../../../../entities/project'
import { useTranslation } from 'react-i18next'
import { GlobalDragNDropWrapper } from './components/ScrumboardDragNDrop'

const ScrumboardList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lists = useSelector(getLists)
	const project = useSelector(getProject)
	const projectId = project?.id
	const [approvedTask] = useApprovedTaskMutation()
	const { pathname } = useLocation()
	const isUser = useSelector(getProjectUserRole)
	const isCurator = useSelector(getProjectCuratorRole)
	const [updateTask] = useUpdateTaskMutation()
	const projectShow = useProjectShow()
	const isContract = project?.type?.name === 'contract'

	const statusesQuery = useGetStatusQuery({ project_id: projectId, order_sort: 'asc', per_page: 100 }, { skip: !projectId })

	const [updateStatusTask] = useUpdateStatusOrderTaskMutation()
	const [confirmationListId, setConfirmationListId] = useState(null)
	const [finalListId, setFinalListId] = useState(null)

	const draggableTask = useSelector(getTask)

	const isShowAddList = !isUser

	useEffect(() => {
		if (lists) {
			const isRequest = Object.entries(lists)?.find(([listId, list]) => list?.is_request === true)
			const isFinal = Object.entries(lists)?.find(([listId, list]) => list?.is_final === true)
			if (isRequest) {
				setConfirmationListId(isRequest[0])
			}
			if (isFinal) {
				setFinalListId(isFinal[0])
			}
		}
	}, [lists])

	const onDragStart = (data) => {
		//if (data.type === 'list') return
		const droppableColumn = Object.entries(lists).find(([listId, list]) => listId === data.source.droppableId)
		const task = droppableColumn[1].cards.find((card) => card.id === data.draggableId)
		dispatch(tasksActions.updateTask(task))
	}

	const changeTask = async ({ source, destination, result }) => {
		const placeTask = lists[destination?.droppableId]?.cards[destination?.index]

		if (source?.droppableId !== destination?.droppableId) {
			dispatch(
				scrumboardActions.updateOneListCard({
					listId: source.droppableId,
					cardId: draggableTask.id,
					data: {
						task_status: {
							...draggableTask.task_status,
							project_status_id: destination.droppableId,
						},
					},
				})
			)
			dispatch(scrumboardActions.reorderList(result))

			const data = {
				body: {
					project_status_id: destination?.droppableId,
					order: placeTask?.order ?? 0,
					task_priority_id: draggableTask?.priority?.id,
				},
				id: result?.draggableId,
			}
			await updateTask(data)
		} else {
			dispatch(scrumboardActions.reorderCard(result))

			const data = {
				body: {
					order: placeTask?.order ?? 0,
					task_priority_id: draggableTask?.priority?.id,
					project_status_id: draggableTask?.project_status_id,
				},
				id: result?.draggableId,
			}
			await updateTask(data)
		}
	}

	const onDragEnd = async (result) => {
		const { source, destination } = result

		if (result.type === 'list') {
			const dI = result.destination.index
			const sI = result.source.index
			const ddid = Object.entries(lists).filter((item, index) => index === dI)[0][0]
			const sdid = Object.entries(lists).filter((item, index) => index === sI)[0][0]
			const newResult = {
				...result,
				destination: {
					...result.destination,
					droppableId: ddid,
				},
				source: {
					...result.source,
					droppableId: sdid,
				},
			}
			dispatch(scrumboardActions.reorderStatus(newResult))
			await updateStatusTask({ id: sdid, body: { order: dI + 1 } })
			return
		}
		const isRequestStatus = statusesQuery.data.data.find((item) => item.id === source.droppableId)?.is_request
		const isFinalStatus = statusesQuery.data.data.find((item) => item.id === destination.droppableId)?.is_final

		if (isFinalStatus && source?.droppableId !== destination?.droppableId) {
			if (project?.with_administration) {
				await approvedTask({
					task_id: draggableTask?.id,
					project_status_id: draggableTask?.task_status?.project_status_id,
				})
				dispatch(
					showMessage({
						message: t('task_confirmed'),
						autoHideDuratigon: 1000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'success',
					})
				)
			}
			changeTask({ source, destination, result })
			return
		}

		if (
			isRequestStatus &&
			source?.droppableId !== destination?.droppableId &&
			project?.with_administration &&
			draggableTask?.need_report
		) {
			dispatch(
				requestsActions.getRequest({
					type: requestTypes.TASK,
					backRoute: pathname,
					data: {
						whereStatusId: destination.droppableId,
						project_status_id: source.droppableId,
						task_id: draggableTask.id,
					},
				})
			)
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('cancel-request'))
			return
		}

		if (!result?.destination) return
		if (result?.destination.droppableId === finalListId && draggableTask.need_report) return
		if (
			result?.destination.droppableId === confirmationListId &&
			draggableTask?.contract_type &&
			draggableTask?.contract_type?.contract_type?.params?.length !== 0 &&
			isContract
		) {
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('task-finish'))
			return
		}

		if (result?.destination.droppableId === confirmationListId && draggableTask.need_report) {
			dispatch(dialogActions.setDialog(true))
			dispatch(dialogActions.setComponent('task-finish'))
			return
		}

		changeTask({ source, destination, result })
	}

	function onCardDrag(dragData) {
		const data = {
			type: 'DEFAULT',
			draggableId: dragData?.initialValue.cardID,
			source: {
				index: dragData?.initialValue.cardIndex,
				droppableId: dragData?.initialValue.columnID,
			},
		}
		onDragStart(data)
	}

	function onCardDrop(dropData) {
		const result = {
			type: 'DEFAULT',
			destination: {
				index: dropData?.targetValue.cardIndex,
				droppableId: dropData?.targetValue.columnID,
			},
			draggableId: dropData?.initialValue.cardID,
			source: {
				index: dropData?.initialValue.cardIndex,
				droppableId: dropData?.initialValue.columnID,
			},
		}
		onDragEnd(result)
	}

	function onColumnDrop(dropData) {
		const result = {
			type: 'list',
			destination: {
				index: dropData?.targetValue.columnIndex,
				droppableId: dropData?.targetValue.columnID,
			},
			source: {
				index: dropData?.initialValue.columnIndex,
				droppableId: dropData?.initialValue.columnID,
			},
		}
		onDragEnd(result)
	}

	useEffect(() => {
		if (statusesQuery.data?.data) {
			if (!lists) {
				let listsData = {}
				statusesQuery.data.data.forEach((item) => {
					listsData[item?.id] = { ...item, cards: [] }
				})
				dispatch(scrumboardActions.createList(listsData))
			} else {
				dispatch(scrumboardActions.syncListWithStatuses(statusesQuery.data.data))
			}
		}
	}, [statusesQuery.data])

	if (!statusesQuery.isSuccess || !lists)
		return (
			<div className="tt-w-full tt-flex tt-gap-4 tt-flex-1 tt-overflow-x-auto tt-overflow-y-hidden tt-h-full ">
				<Skeleton
					className="tt-w-256 sm:tt-w-320 tt-max-h-full tt-flex tt-flex-col tt-rounded-xl tt-border"
					sx={{ bgcolor: 'grey.200' }}
					height={400}
				/>
				<Skeleton
					className="tt-w-256 sm:tt-w-320 tt-max-h-full tt-flex tt-flex-col tt-rounded-xl tt-border"
					sx={{ bgcolor: 'grey.200' }}
					height={200}
				/>
				<Skeleton
					className="tt-w-256 sm:tt-w-320 tt-max-h-full tt-flex tt-flex-col tt-rounded-xl tt-border"
					sx={{ bgcolor: 'grey.200' }}
					height={300}
				/>
			</div>
		)

	return (
		<section id="scrumboard-body" className="tt-flex tt-h-full tt-flex-col">
			<section id="board_filters" className="tt-px-16">
				<TaskFilters
					hideSkelet={{
						status: true,
						left: true,
						author: true,
						project: true,
						complete_date: true,
						order_sort: true,
					}}
				/>
			</section>
			<div className="tt-h-full tt-w-full tt-overflow-x-auto">
				<GlobalDragNDropWrapper onDrag={onCardDrag} onCardDrop={onCardDrop} onColumnDrop={onColumnDrop}>
					{lists && !statusesQuery.isFetching && (
						<div id="scrumboard_list" className="tt-flex tt-w-full tt-max-h-full tt-h-full tt-gap-16 tt-pb-8 tt-px-16">
							{Object.entries(lists).map(([listId, list], index) => (
								<BoardColumn key={listId} listId={listId} list={list} columnIndex={index} />
							))}
							{isShowAddList && !projectShow?.project?.isEnd && <BoardAddList />}
						</div>
					)}
				</GlobalDragNDropWrapper>
			</div>
		</section>
	)
}

export default ScrumboardList
