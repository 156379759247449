import { useGetProtocolsQuery } from 'src/old-app/service/Protocol'
import ProjectProtocolsTableRow from './ProjectProtocolsTableRow'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProjectProtocolsTableHeaderRow from './ProjectProtocolsTableHeaderRow'
import { VirtualizedListElement, VirtualizedListWrapper } from 'src/@shared/lib/VerticalListVirtualizer'
import ProjectProtocolsTableDummyRow from './ProjectProtocolsTableDummyRow'
import ProjectProtocolsTableEmptyRow from './ProjectProtocolsTableEmptyRow'
import { useTranslation } from 'react-i18next'

const defaultProtocolsPerPage = 50

const tableColumnsSettings = {
	// hid: {
	// 	basicWidth: '3.5rem',
	// 	canGrow: false,
	// },
	topic: {
		basicWidth: '30rem',
		canGrow: true,
	},
	committee_time: {
		basicWidth: '22rem',
		canGrow: false,
	},
	protocol_created_at: {
		basicWidth: '16rem',
		canGrow: false,
	},
	author: {
		basicWidth: '22rem',
		canGrow: false,
	},
}

const ProjectProtocolsTable = () => {
	const { t } = useTranslation()
	const { projectId, protocolId } = useParams()
	const scrollContainerRef = useRef(null)
	const totalCommitteeProtocols = useRef(0)
	const [protocolsPerPage, setProtocolsPerPage] = useState(defaultProtocolsPerPage)

	const protocolRequestParams = {
		committee_id: projectId,
		per_page: protocolsPerPage,
		current_page: 1,
		created_at_sort: 'DESC',
	}

	function loadMoreProtocols() {
		if (protocolsPerPage < totalCommitteeProtocols.current) {
			setProtocolsPerPage(protocolsPerPage + defaultProtocolsPerPage)
		}
	}

	const { data: committeeProtocolsRawData, isFetching, isError } = useGetProtocolsQuery(protocolRequestParams)

	const committeeProtocolsData = committeeProtocolsRawData?.data

	useEffect(() => {
		if (!committeeProtocolsRawData) return
		totalCommitteeProtocols.current = committeeProtocolsRawData?.meta?.total_entries
	}, [committeeProtocolsRawData])

	return (
		<section ref={scrollContainerRef} className="tt-w-full tt-h-full tt-px-16 tt-pt-8 tt-overflow-auto">
			<ProjectProtocolsTableHeaderRow key={'table_header'} columnsSettings={tableColumnsSettings} />
			<VirtualizedListWrapper
				totalElements={committeeProtocolsData?.length}
				unvirtualizedElementsLimit={20}
				overflowElements={5}
				estimatedElementHeight={44}
				externalScrollableComponent={scrollContainerRef}
				onLastIsMounted={loadMoreProtocols}
			>
				{committeeProtocolsData?.length &&
					committeeProtocolsData.map((protocolData, index) => {
						return (
							<VirtualizedListElement key={protocolData?.id} elementID={protocolData?.id} elementIndex={index}>
								<ProjectProtocolsTableRow
									columnsSettings={tableColumnsSettings}
									protocolData={protocolData}
									isChoosen={protocolId === protocolData?.id}
								/>
							</VirtualizedListElement>
						)
					})}
				{isFetching && <ProjectProtocolsTableDummyRow columnsSettings={tableColumnsSettings} />}
				{!isFetching && !committeeProtocolsData?.length && (
					<ProjectProtocolsTableEmptyRow emptyPlaceholder={t('empty_available_protocols_list')} />
				)}
			</VirtualizedListWrapper>
		</section>
	)
}

export default ProjectProtocolsTable
