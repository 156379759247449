import { useDispatch, useSelector } from 'react-redux'
import {
	getIsMediaViewerOpen,
	getMediaSource,
	getCurrentMediaItem,
	interfaceActions,
} from 'src/old-app/store/interfaceSlice'
import Gallery from './Gallery'
import styles from './MediaViewer.module.css'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const MediaViewer = () => {
	const dispatch = useDispatch()

	const handleClose = () => {
		dispatch(interfaceActions?.setIsMediaViewerOpen(false))
	}

	const viewerIsOpen = useSelector(getIsMediaViewerOpen)
	const mediaSource = useSelector(getMediaSource)
	const currentItem = useSelector(getCurrentMediaItem)

	return (
		<>
			{viewerIsOpen && (
				<div className={styles.MediaViewer}>
					<div className={styles.MediaViewer__background} onClick={handleClose}>
						<Gallery source={mediaSource} itemToShow={currentItem} />
					</div>
					<button className={styles.MediaViewer__closeButton} onClick={handleClose}>
						<CloseOutlinedIcon />
					</button>
				</div>
			)}
		</>
	)
}

export default MediaViewer
