import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './base-query'

export const taskCompleteFileAPI = createApi({
	reducerPath: 'task-complete-file-api',
	tagTypes: ['Complete-file'],

	baseQuery,
	endpoints: (build) => ({
		createTaskCompleteFile: build.mutation({
			query: (body) => {
				return {
					method: 'POST',
					url: '/task-complete-file/',
					body: JSON.stringify(body),
				}
			},
			invalidatesTags: ['Complete-file'],
			providesTags: (result) => ['Complete-file'],
		}),
		getTaskCompleteFiles: build.query({
			query: (params) => ({
				url: '/task-complete-file/',
				params,
			}),
		}),
	}),
})

export const { useCreateTaskCompleteFileMutation, useGetTaskCompleteFilesQuery } = taskCompleteFileAPI
