import { IconButton, Tooltip } from '@mui/material'
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined'
import React, { useRef } from 'react'
import { useFileCommentMutation } from 'src/old-app/service/Comment'
import { fileUpload } from 'src/old-app/service/File'
import { getID } from 'src/shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

interface CommentUploadFileButtonProps {
	commentId: Id
	taskId: Id
}

export const CommentUploadFileButton: FC<CommentUploadFileButtonProps> = (p) => {
	const { t } = useTranslation()
	const { commentId, taskId } = p

	const [fileComment] = useFileCommentMutation()

	const fileRef = useRef<any>(null)

	const clearFiles = () => {
		fileRef.current.value = null
	}

	const onChangeFilesHandler = async (event: any) => {
		const curFiles = [...event.target.files]

		const fileIds: any[] = []

		await Promise.all(
			curFiles.map(async (item) => {
				const fileData = await fileUpload(item)
				if (!fileData?.data) return
				const fileId = fileData?.data?.id
				fileIds.push(fileId)
			})
		)

		if (fileIds?.length !== 0) {
			await fileComment({
				file_id: fileIds,
				comment_id: commentId,
			})
		}

		clearFiles()
	}

	return (
		<>
			<Tooltip title={t('upload_file')}>
				<IconButton
					id={getID({ prefix: commentId, action: 'upload', type: 'button_add' })}
					onClick={() => fileRef.current?.click()}
					size="small"
				>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.6434 5.83333L7.1551 11.3217C6.99591 11.4754 6.86894 11.6593 6.7816 11.8627C6.69425 12.066 6.64827 12.2847 6.64635 12.506C6.64442 12.7273 6.68659 12.9468 6.77039 13.1516C6.8542 13.3564 6.97795 13.5425 7.13444 13.699C7.29093 13.8555 7.47701 13.9792 7.68184 14.063C7.88667 14.1468 8.10613 14.189 8.32743 14.1871C8.54873 14.1852 8.76743 14.1392 8.97077 14.0518C9.17411 13.9645 9.35802 13.8375 9.51176 13.6783L14.8568 8.18999C15.464 7.56132 15.7999 6.71931 15.7923 5.84532C15.7847 4.97133 15.4342 4.13529 14.8162 3.51727C14.1981 2.89924 13.3621 2.54868 12.4881 2.54108C11.6141 2.53349 10.7721 2.86947 10.1434 3.47666L4.7976 8.96416C3.85984 9.90192 3.33301 11.1738 3.33301 12.5C3.33301 13.8262 3.85984 15.0981 4.7976 16.0358C5.73536 16.9736 7.00724 17.5004 8.33343 17.5004C9.65963 17.5004 10.9315 16.9736 11.8693 16.0358L17.0834 10.8333"
							strokeWidth="1"
							stroke="#757575"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</IconButton>
			</Tooltip>
			<input onChange={onChangeFilesHandler} multiple ref={fileRef} hidden type="file" />
		</>
	)
}
