import { CircularProgress } from '@mui/material'
import { getIsLoading } from 'src/old-app/store/interfaceSlice'
import React from 'react'
import { useSelector } from 'react-redux'

const IsLoading = () => {
	const isLoading = useSelector(getIsLoading)
	if (!isLoading) return null
	return (
		<div
			className={[
				'tt-h-screen tt-w-full tt-z-[99999999999] tt-flex tt-items-center tt-bg-black/20 tt-fixed tt-top-0 tt-left-0  tt-justify-center tt-duration-300',
			].join(' ')}
		>
			<CircularProgress className="tt-text-primary-600" />
		</div>
	)
}

export default IsLoading
