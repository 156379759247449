import { getTask } from 'src/old-app/store/tasksSlice'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useGetHistoryQuery } from 'src/old-app/service/Tasks'
import { useGetOneUserQuery } from 'src/old-app/service/Users'
import moment from 'moment'
import DiffMatchPatch from 'diff-match-patch'
import List from '@mui/material/List'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import Avatar from 'src/shared/components/avatar'
import { getAcronym } from 'src/@shared/lib'
import { useTranslation } from 'react-i18next'
import EmptyPlaceholder from 'src/shared/components/empty-placeholder'
import { UserShow } from 'src/shared/components'

// Поля, отображаемые в истории изменений задачи
const viewableHistoryFields = [
	'deadline', //Дэдлайн задачи
	'description', //Описание задачи
	'project_status_name', //Статус задачи
	'project_task_priority_name', //Приоритет задачи
	'result_representation', //Образ результата
	'task_assignee_user_id', //ID исполнителя
	'title', //Название задачи
	'type_name', //Тип задачи
]

function prepareHistoryList(rawDifferenceList, rawHistoryList) {
	if (!rawDifferenceList || !rawDifferenceList?.length) return []

	const extendedDifferenceList = []

	for (let i = 0; i < rawDifferenceList?.length; i++) {
		// Включить после исправления бага с лишними записями в истории на бэке
		//if (!rawHistoryList[i]?.updater_user_id) continue

		const rawDifferenceListItem = rawDifferenceList[i]
		if (!rawDifferenceListItem?.length) continue
		const modifiedDifferenceListItem = []
		for (const rawDifferenceRecord of rawDifferenceListItem) {
			if (viewableHistoryFields.includes(rawDifferenceRecord?.affected_field[0]?.name)) {
				modifiedDifferenceListItem.push({
					name: rawDifferenceRecord?.affected_field[0]?.name,
					value: rawDifferenceRecord?.affected_field[0]?.value,
					oldValue: rawDifferenceRecord?.affected_field[0]?.old_value,
				})
			}
		}

		const differenceRecord = {
			operationTime: rawHistoryList[i]?.operation_time,
			updaterFirstName: rawHistoryList[i]?.updater_first_name,
			updaterLastName: rawHistoryList[i]?.updater_last_name,
			updaterAvatar: rawHistoryList[i]?.updater_avatar,
			changesList: modifiedDifferenceListItem,
		}

		if (modifiedDifferenceListItem?.length) {
			extendedDifferenceList.push(differenceRecord)
		}
	}
	return extendedDifferenceList
}

const dmp = new DiffMatchPatch()

const HistoryItemHead = ({ updaterName, updaterAvatar, updatedTime }) => {
	return (
		<div className="tt-flex tt-items-center">
			<Avatar src={updaterAvatar} alt={getAcronym(updaterName)} />
			<Typography variant={'h3'} fontSize={'inherit'} fontWeight={500} className="tt-ml-5">
				{updaterName}
				<span className={'tt-ml-12 tt-text-14 tt-text-gray-500'}>{moment(updatedTime).fromNow()}</span>
			</Typography>
		</div>
	)
}

const TextDifferenceHighlighter = ({ firstItem, secondItem }) => {
	const diff = dmp.diff_main(firstItem?.toString() || '', secondItem?.toString() || '')
	dmp.diff_cleanupSemantic(diff)

	return (
		<span className="my-text-overflow-ellipsis">
			{diff.map((item, index, _arr) => (
				<span
					key={`${item[1]}_${index}`}
					className={clsx({
						'tt-bg-red-600/10 tt-text-red-600 tt-rounded-lg': item[0] === -1,
						'tt-bg-green-600/10 tt-text-green-600 tt-rounded-lg': item[0] === 1,
						'tt-line-through': item[0] === -1 && _arr.length === 1,
					})}
				>
					{item[1]}
				</span>
			))}
		</span>
	)
}

const EssenceHighlighter = ({ oldValue, value, isDateFormat, isTranslatable, translatePrefix }) => {
	const { t } = useTranslation()

	if (isDateFormat) {
		;[oldValue, value] = [oldValue, value].map((dateValue) => dateValue && moment(dateValue).format('LL'))
	}

	if (isTranslatable) {
		;[oldValue, value] = [oldValue, value].map((translatableValue) =>
			t(`${translatePrefix ? translatePrefix + '.' + translatableValue : translatableValue}`)
		)
	}

	return (
		<span className="my-text-overflow-ellipsis">
			{oldValue && (
				<span className={`tt-px-5 tt-bg-red-600/10 tt-text-red-600 tt-rounded-lg ${!value && 'tt-line-through'}`}>
					{oldValue}
				</span>
			)}
			{oldValue && value && <KeyboardArrowRightOutlinedIcon className="tt-text-sm tt-text-gray-600" />}
			{value && <span className="tt-px-5 tt-bg-green-600/10 tt-text-green-600 tt-rounded-lg">{value}</span>}
		</span>
	)
}

const UserHighlighter = ({ oldValue, value }) => {
	const oldUserData = oldValue ? useGetOneUserQuery(oldValue) : null
	const newUserData = value ? useGetOneUserQuery(value) : null

	const oldUserName = oldUserData
		? `${oldUserData?.data?.data?.last_name ? oldUserData?.data?.data?.last_name : ''} ${
				oldUserData?.data?.data?.first_name ? oldUserData?.data?.data?.first_name : ''
		  }`
		: ''

	const newUserName = newUserData
		? `${newUserData?.data?.data?.last_name ? newUserData?.data?.data?.last_name : ''} ${
				newUserData?.data?.data?.first_name ? newUserData?.data?.data?.first_name : ''
		  }`
		: ''

	return (
		<div className="tt-flex tt-items-center">
			{oldValue && (
				<div className="tt-flex tt-items-center tt-bg-red-600/10 tt-rounded-full tt-p-1 tt-pr-8">
					<Avatar src={oldUserData?.data?.data?.avatar256} alt={getAcronym(oldUserName)} />
					<span className="tt-pl-4 tt-pb-2 tt-text-[#1e293b]">{oldUserName}</span>
				</div>
			)}
			{oldValue && value && <KeyboardArrowRightOutlinedIcon className="tt-text-sm tt-text-gray-600" />}
			{value && (
				<div className="tt-flex tt-items-center tt-bg-green-600/10 tt-rounded-full tt-p-1 tt-pr-8">
					<Avatar src={newUserData?.data?.data?.avatar256} alt={getAcronym(newUserName)} />
					<span className="tt-pl-4 tt-pb-2 tt-text-[#1e293b]">{newUserName}</span>
				</div>
			)}
		</div>
	)
}

const HistoryRecord = ({ name, oldValue, value }) => {
	switch (name) {
		case 'description':
			return <TextDifferenceHighlighter firstItem={oldValue} secondItem={value} />
		case 'result_representation':
			return <TextDifferenceHighlighter firstItem={oldValue} secondItem={value} />
		case 'task_assignee_user_id':
			return <UserHighlighter oldValue={oldValue} value={value} />
		case 'deadline':
			return <EssenceHighlighter oldValue={oldValue} value={value} isDateFormat />
		case 'type_name':
			return <EssenceHighlighter oldValue={oldValue} value={value} isTranslatable translatePrefix="types" />
		default:
			return <EssenceHighlighter oldValue={oldValue} value={value} />
	}
}

const HistoryChanges = () => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const { data: taskChangesData, isSuccess } = useGetHistoryQuery(task?.id)

	const preparedHistoryList = useMemo(() => {
		return isSuccess && taskChangesData?.data
			? prepareHistoryList(taskChangesData?.data?.difference, taskChangesData?.data?.history)
			: []
	}, [taskChangesData?.data])

	if (preparedHistoryList?.length === 0) return <EmptyPlaceholder text={t('empty_task_history')} />

	return (
		<List>
			{preparedHistoryList?.map(
				({ updaterFirstName, updaterLastName, updaterAvatar, operationTime, changesList }, index) => {
					return (
						<li className={'tt-flex tt-gap-4 tt-mt-8'} key={index}>
							<HistoryOutlinedIcon className={'tt-text-gray-400 tt-mr-4 tt-relative'} />

							<div>
								<HistoryItemHead
									updaterName={`${updaterLastName} ${updaterFirstName}`}
									updaterAvatar={updaterAvatar}
									updatedTime={operationTime}
								/>

								<List>
									{changesList.map(({ name, oldValue, value }) => {
										const historyItemTitle = t(`history_changes.${name}`)

										return (
											<li className={'tt-py-2'} key={JSON.stringify(name)}>
												<span className={'tt-text-gray-600'}>{`${historyItemTitle}: `}</span>
												<HistoryRecord name={name} oldValue={oldValue} value={value} />
											</li>
										)
									})}
								</List>
							</div>
						</li>
					)
				}
			)}
		</List>
	)
}

export default HistoryChanges
