import React, { memo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import TaskSlug from 'src/old-app/components/task/TaskSlug'
import TaskPriorityShow from 'src/old-app/components/task/TaskPriorityShow'
import TaskType from 'src/old-app/components/task/TaskType'
import UserCellShow from 'src/old-app/components/tables/UserCellShow'
import TaskSkelet from './TaskSkelet'
import TagsView from 'src/old-app/components/ui/TagsView'
import { TaskContext, TaskDeadline } from 'src/entities/task'
import { UserShow } from '../../../shared/components'
import { getID } from '../../../shared/config/fields-ids'
import { Tooltip } from '@mui/material'
import addAlpha from '../../utils/addAlpha'
import TaskDeadlineInfo from '../../../features/task/components/task-deadline-info'
import { useTranslation } from 'react-i18next'

export const ViewStatus = ({ status }) => {
	const { t } = useTranslation()
	const currentTaskStatus = status?.project_status

	if (!currentTaskStatus?.id) return <div className="tt-text-center">-</div>

	return (
		<div
			// style={{ color: value?.color ?? '#000000', backgroundColor: addAlpha(value?.color ?? '#000000', 0.1) }}
			style={{ backgroundColor: currentTaskStatus?.color }}
			className="tt-w-full tt-text-center  tt-text-white tt-text-12 tt-font-medium  tt-rounded-full tt-px-8 tt-py-2 tt-truncate"
		>
			{currentTaskStatus?.status}
		</div>
	)
}

const TaskRow = memo((props) => {
	const {
		data,
		// projectId,
		// takeStatusesFromRedux,
		hideSkelet,
		url = '',
		className,
		activeClassName,
		linkDisabled,
		// project,
		// isHideTags,
		isHideSlug,
		isHideType,
		isHidePriority,
		left,
		// isHideDeadline,
		right,
		rightRender,
		id = 'task',
		statusSlot,
	} = props

	const {
		is_overdue,
		id: taskID,
		task_status,
		priority,
		deadline,
		hashtags,
		title,
		slug,
		assignee,
		author_view,
		complete_date,
		type,
		// actual_status,
		project: projectData,
	} = data

	const location = useLocation()
	const urlParams = useParams()

	// const linkTo = `${url}${data?.id}${search}`

	function getPath() {
		return typeof url === 'function'
			? url({ taskId: data?.id, projectId: projectData?.id, location, urlParams })
			: `${url}${data?.id}${location?.search}`
	}

	const WrapperComponent = linkDisabled ? 'div' : Link

	const projectName = projectData?.name ?? ''
	return (
		<TaskContext.Provider value={{ task: data }}>
			<div
				className={[
					'tt-w-full tt-group tt-flex  tt-relative  tt-rounded-lg  tt-border tt-cursor-pointer',
					className,
					// urlParams?.taskId === taskID && activeClassName ? activeClassName : 'tt-bg-gray-100',
					urlParams?.taskId === taskID
						? ' hover:tt-bg-gray-200/50  tt-border-gray-200 hover:tt-border-gray-200  tt-bg-gray-200/50'
						: 'tt-bg-gray-100/70 hover:tt-border-gray-200  hover:tt-bg-gray-200/50',
				].join(' ')}
			>
				<WrapperComponent
					id={getID({ prefix: id, type: 'row', index: props?.index })}
					to={linkDisabled ? undefined : getPath()}
					className="tt-px-6 tt-py-4 tt-gap-6 tt-flex tt-items-center tt-w-full"
				>
					<TaskSkelet
						hideSkelet={hideSkelet}
						left={left}
						// right={right}
						complete_date={
							<TaskDeadline isOverdue={false} deadline={complete_date ? { deadline: complete_date } : undefined} />
						}
						status={
							statusSlot ? (
								statusSlot(data)
							) : (
								<ViewStatus
									// takeStatusesFromRedux={takeStatusesFromRedux}
									// projectId={projectId}
									status={task_status}
								/>
							)
						}
						priority={
							!isHidePriority && (
								<>
									{priority ? (
										<TaskPriorityShow priority={priority} />
									) : (
										<span className="tt-text-14 tt-text-gray-500">-</span>
									)}
								</>
							)
						}
						deadline={<TaskDeadlineInfo isShowStatusInText task={data} placeholder="-" />}
						tags={<TagsView tags={hashtags ?? []} />}
						type={!isHideType && <TaskType taskType={type} />}
						text={
							<Tooltip enterDelay={500} arrow title={title} placement="top-start">
								<p className="my-truncate-one tt-whitespace-pre-wrap tt-break-all">{title}</p>
							</Tooltip>
						}
						slug={!isHideSlug && <TaskSlug slug={slug} />}
						assignee={<div className="tt-flex tt-items-center">{assignee && <UserShow user={assignee} />}</div>}
						author={<div className="tt-flex tt-items-center">{author_view && <UserShow user={author_view} />}</div>}
						project={
							<Tooltip enterDelay={500} arrow title={projectName} placement="top-start">
								<div>{projectName}</div>
							</Tooltip>
						}
						// TODO /R поменял для восстановления кнопок удаления, не разбирался долго
						// right={<TaskStates />}

						right={
							<div className={'tt-flex tt-gap-6 tt-items-center'}>
								{/*<TaskStates />*/}
								{right}
								{rightRender && rightRender(data, props?.index)}
							</div>
						}
					/>
				</WrapperComponent>
			</div>
		</TaskContext.Provider>
	)
})

TaskRow.displayName = 'TaskRow'
export default TaskRow
