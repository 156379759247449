import React from 'react'

const TaskSkelet = ({
	type,
	slug,
	left,
	text,
	assignee,
	right,
	tags,
	status,
	priority,
	deadline,
	project,
	complete_date = true,
	author,
	hideSkelet,
	skeletClassName,
}) => {
	return (
		<>
			{!hideSkelet?.left && <th>{left}</th>}
			{!hideSkelet?.type && (
				<th className="tt-max-w-68 tt-w-68 tt-truncate tt-text-14 tt-text-left tt-font-medium">{type}</th>
			)}

			{!hideSkelet?.slug && (
				<th className="!tt-max-w-68 !tt-w-68 !tt-min-68 tt-truncate tt-text-14 tt-text-left tt-font-medium">{slug}</th>
			)}
			<th style={{ minWidth: 198 }} className="tt-flex-1 tt-text-14   tt-text-left tt-font-medium tt-text-black">
				{text}
			</th>
			{!hideSkelet?.author && <th className="tt-w-160 tt-truncate tt-text-14  tt-font-medium">{author}</th>}
			{!hideSkelet?.assignee && <th className="tt-w-160 tt-truncate tt-text-14  tt-font-medium">{assignee}</th>}

			{!hideSkelet?.project && (
				<th className="tt-w-112 tt-text-14 tt-text-left tt-truncate tt-font-medium">{project}</th>
			)}

			{!hideSkelet?.tags && <th className="tt-w-120 tt-truncate tt-text-left">{tags}</th>}

			{!hideSkelet?.deadline && (
				<th className="tt-truncate tt-text-14 tt-text-left tt-w-128 tt-font-medium tt-text-gray-500">{deadline}</th>
			)}
			{!hideSkelet?.complete_date && (
				<th className="tt-truncate tt-text-14 tt-text-left tt-w-[17rem] tt-font-medium tt-text-gray-500">
					{complete_date}
				</th>
			)}
			{!hideSkelet?.priority && (
				<th className="tt-max-w-112 tt-truncate tt-text-left tt-text-14 tt-w-136 tt-font-medium">{priority}</th>
			)}
			{!hideSkelet?.status && (
				<th className="tt-max-w-96  tt-text-14 tt-text-left tt-w-120 tt-font-medium">{status}</th>
			)}
			{!hideSkelet?.right && <th className={[skeletClassName?.right].join(' ')}>{right}</th>}
		</>
	)
}

export default TaskSkelet
