import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useTeamShow } from '../../hooks'
import Button from '@mui/material/Button'
import TeamInfo from '../team-info'
import TeamEdit from '../../../../old-app/components/team/TeamEdit'
import TeamRoles from '../team-roles'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
	hideFields?: any
	isWrap?: boolean
}

const TeamSettings: React.FC<IProps> = (p) => {
	const { t } = useTranslation()
	const { hideFields, isWrap } = p
	const { teamId } = useParams()
	const [isEdit, setIsEdit] = useState(false)
	const { team, isManageTeam } = useTeamShow()

	useEffect(() => {
		setIsEdit(false)
	}, [teamId])

	return (
		<div className={styles['team-settings']}>
			{isEdit ? (
				<TeamEdit setEdit={setIsEdit} />
			) : (
				<>
					<div className={styles['team-block']}>
						{isManageTeam && (
							<div>
								<Button
									onClick={() => setIsEdit(true)}
									startIcon={
										<svg
											className="tt-stroke-gray-700"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.6933 4.36003L15.64 7.3067M13.9433 3.11003C14.3341 2.71928 14.8641 2.49976 15.4167 2.49976C15.9693 2.49976 16.4992 2.71928 16.89 3.11003C17.2808 3.50078 17.5003 4.03076 17.5003 4.58336C17.5003 5.13597 17.2808 5.66595 16.89 6.0567L5.41667 17.53H2.5V14.5534L13.9433 3.11003Z"
												strokeWidth="2"
												stroke="#94A3B8"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									}
									className="tt-border-gray-200 tt-inline-flex tt-mb-12 tt-text-12 tt-min-h-32 tt-h-32 tt-px-16"
									variant="outlined"
								>
									{t('edit_team')}
								</Button>
							</div>
						)}
						<TeamInfo isWrap={isWrap} />
					</div>
					<div className={styles['project-block']}>
						<TeamRoles isDisabled={!isManageTeam || hideFields?.roles} />
					</div>
				</>
			)}
		</div>
	)
}

export default TeamSettings
