import { Button } from '@mui/material'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { getID } from '../../../../shared/config/fields-ids'
import { useTranslation } from 'react-i18next'

const ProtocolCreateHeader = memo(({ isDisabled, id }) => {
	const { t } = useTranslation()
	return (
		<div className="tt-flex tt-sticky tt-z-10 tt-pt-24 tt-top-0 tt-bg-gray-50 tt-items-center tt-justify-between tt-w-full  ">
			<h2 className="tt-text-2xl tt-font-semibold">{t('new.protocol')}</h2>
			<div className="tt-flex tt-items-centet tt-gap-8">
				<Link to="/protocol" id={getID({ prefix: id, type: 'link', entity: 'protocol' })}>
					<Button
						variant="outlined"
						disabled={isDisabled}
						type="button"
						className="tt-border-gray-100 tt-text-black  tt-min-h-32 tt-h-32 tt-px-16"
					>
						{t('cancel')}
					</Button>
				</Link>

				<Button
					id={getID({ prefix: id, type: 'button_submit' })}
					disabled={isDisabled}
					type="submit"
					startIcon={<AddIcon />}
					className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
				>
					{t('save')}
				</Button>
			</div>
		</div>
	)
})

export default ProtocolCreateHeader
