import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const DatePickerInput = ({ value, onChange, sx }) => {
	const dateValue = value && dayjs(value).isValid() ? dayjs(value).$d : undefined

	function onDateChangeHandler(value) {
		onChange(value ? dayjs(value).startOf('date').format() : undefined)
	}

	return (
		<DatePicker
			className="dialig-input"
			slotProps={{
				textField: { size: 'small' },
				openPickerIcon: { style: { height: '1.8rem', width: '1.8rem' } },
			}}
			sx={sx}
			value={dateValue}
			onChange={onDateChangeHandler}
		/>
	)
}

export default DatePickerInput
