import { Button, IconButton } from '@mui/material'
import { getTask } from 'src/old-app/store/tasksSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCreateCommentMutation, useFileCommentMutation } from 'src/old-app/service/Comment'
import { fileUpload } from 'src/old-app/service/File'
import { Mention, MentionsInput } from 'react-mentions'
import { getPUsers } from 'src/old-app/store/projectsSlice'
import getMentionIds from 'src/old-app/utils/getMentionIds'
import { File } from '../dialogs/task/Files'
import { getID } from '../../../shared/config/fields-ids'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { checkFileSize } from 'src/shared/lib/checkFileSize'
import { useParams } from 'react-router-dom'
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined'
import { width } from '@mui/system'
import { useTranslation } from 'react-i18next'

export const mentionDefaultStyle = {
	width: 'calc(100% - 180px)',
	control: {
		backgroundColor: '#fff',
		fontSize: 14,
		fontWeight: 'normal',
	},

	'&multiLine': {
		control: {
			minHeight: 63,
		},
		highlighter: {
			padding: '8px 12px',
			border: '1px solid transparent',
		},
		input: {
			padding: '8px 12px',
			borderRadius: '1.6rem',
			borderWidth: 1,
		},
	},

	'&singleLine': {
		display: 'inline-block',
		width: 180,

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},
		input: {
			padding: 1,
			border: '2px inset',
		},
	},

	suggestions: {
		list: {
			borderRadius: '1.6rem',
			backgroundColor: 'white',
			borderWidth: 1,
			fontSize: 14,
			overflow: 'auto',
			maxHeight: 128,
		},
		item: {
			padding: '5px 15px',
			borderRadius: '1.6rem',
			borderBottomWidth: 1,
			'&focused': {
				backgroundColor: '#CDCBF8',
			},
		},
	},
}

const AddComment = ({
	commentId,
	onSubmit,
	setIsAnswer,
	placeholder = 'Написать комментарий..',
	id,
	style = { width: '100%' },
}) => {
	const { t } = useTranslation()
	const task = useSelector(getTask)
	const dispatch = useDispatch()
	const routeParams = useParams()

	const fileRef = useRef(null)
	const [text, setText] = useState('')
	const [files, setFiles] = useState([])

	const pUsers = useSelector(getPUsers)
	const users = pUsers.map((item) => ({
		id: item.id,
		display: `${item?.last_name} ${item?.first_name}`,
	}))

	const [createComment] = useCreateCommentMutation()
	const [fileComment] = useFileCommentMutation()

	useEffect(() => {
		setFiles([])
		setText('')
	}, [routeParams.taskId])

	const createCommentHandler = async (event) => {
		event.preventDefault()

		for (const file of files) {
			if (!checkFileSize(file.size)) {
				dispatch(
					showMessage({
						message: t('maximum_file_size'),

						anchorOrigin: {
							vertical: 'top',
							horizontal: 'top-right',
						},
						variant: 'warning',
					})
				)
				return
			}
		}

		if (!text && files.length === 0) return
		setText('')
		if (onSubmit) {
			onSubmit()
		}

		const createData = await createComment({
			id: task.id,
			body: {
				parent_comment_id: commentId,
				text,
				mentions_user_ids: getMentionIds(text),
			},
		})

		const curFiles = files
		const id = createData?.data?.data?.id
		setFiles([])

		const fileIds = []
		await Promise.all(
			curFiles.map(async (item) => {
				const fileData = await fileUpload(item)
				if (!fileData?.data) return
				const fileId = fileData?.data?.id
				fileIds.push(fileId)
			})
		)

		if (fileIds?.length !== 0) {
			await fileComment({
				file_id: fileIds,
				comment_id: id,
			})
		}
	}

	const onChangeFilesHandler = async (event) => {
		setFiles([...files, ...event.target.files])
	}

	const onRemoveHandler = (index) => {
		setFiles((currFiles) => currFiles.filter((_, _index) => _index !== index))
	}

	const _idParams = { prefix: id, field: 'comment' }

	const isSave = files.length !== 0 || text

	return (
		<div style={style} className="tt-flex tt-flex-col tt-gap-2">
			<form onSubmit={createCommentHandler} className="tt-flex tt-gap-12 tt-w-full">
				<MentionsInput
					id={getID({ ..._idParams, lib: 'mentions-input' })}
					onChange={(event) => setText(event.target.value)}
					value={text}
					style={mentionDefaultStyle}
					// className="p-8 px-12  w-full  resize-none border h-auto rounded-2xl"
					placeholder={t('use_to_mention_user')}
					a11ySuggestionsListLabel="Suggested mentions"
				>
					<Mention
						markup="@[__display__](__id__)"
						trigger="@"
						data={users}
						renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
							<div className={`user ${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>
						)}
						style={{ backgroundColor: '#CDCBF8' }}
					/>
				</MentionsInput>

				<div className="tt-flex tt-gap-4">
					<IconButton
						className="tt-cursor-pointer tt-inline-flex tt-h-36 tt-w-36"
						onClick={() => fileRef.current?.click()}
						as="dev"
					>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M12.6434 5.83333L7.1551 11.3217C6.99591 11.4754 6.86894 11.6593 6.7816 11.8627C6.69425 12.066 6.64827 12.2847 6.64635 12.506C6.64442 12.7273 6.68659 12.9468 6.77039 13.1516C6.8542 13.3564 6.97795 13.5425 7.13444 13.699C7.29093 13.8555 7.47701 13.9792 7.68184 14.063C7.88667 14.1468 8.10613 14.189 8.32743 14.1871C8.54873 14.1852 8.76743 14.1392 8.97077 14.0518C9.17411 13.9645 9.35802 13.8375 9.51176 13.6783L14.8568 8.18999C15.464 7.56132 15.7999 6.71931 15.7923 5.84532C15.7847 4.97133 15.4342 4.13529 14.8162 3.51727C14.1981 2.89924 13.3621 2.54868 12.4881 2.54108C11.6141 2.53349 10.7721 2.86947 10.1434 3.47666L4.7976 8.96416C3.85984 9.90192 3.33301 11.1738 3.33301 12.5C3.33301 13.8262 3.85984 15.0981 4.7976 16.0358C5.73536 16.9736 7.00724 17.5004 8.33343 17.5004C9.65963 17.5004 10.9315 16.9736 11.8693 16.0358L17.0834 10.8333"
								strokeWidth="1"
								stroke="#757575"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</IconButton>

					<input
						id={getID({ ..._idParams, type: 'input_file' })}
						key={files.length}
						onChange={onChangeFilesHandler}
						multiple
						ref={fileRef}
						hidden
						type="file"
					/>

					<div className="tt-flex tt-flex-col tt-gap-4">
						<Button
							disabled={!isSave}
							id={getID({ ..._idParams, type: 'button_submit' })}
							type="submit"
							className="disabled:tt-bg-gray-300 disabled:tt-text-gray-800 tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-24"
						>
							{t('send')}
						</Button>
						{setIsAnswer && (
							<Button
								size="small"
								onClick={() => setIsAnswer(false)}
								id={getID({ ..._idParams, action: 'cancel', type: 'button_toggle' })}
								type="button"
								className=" tt-bg-transparent  tt-min-h-32 tt-h-32 hover:tt-bg-gray-300 tt-text-gray-500 hover:tt-text-black tt-w-full tt-text-center tt-py-2 tt-px-2"
							>
								{t('cancel')}
							</Button>
						)}
					</div>
				</div>
			</form>
			<div className="tt-mt-8 tt-flex tt-flex-wrap tt-gap-4">
				{files.map((item, index) => (
					<File
						id={getID({ ..._idParams, field: 'files', type: 'button_delete', index })}
						onRemove={() => onRemoveHandler(index)}
						key={index}
						item={item}
					/>
				))}
			</div>
		</div>
	)
}

export default AddComment
