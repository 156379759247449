import {
	getProjectAdminContractRole,
	getProjectBARole,
	getProjectManagerRole,
	getProjectUserRole,
	projectsActions,
} from 'src/old-app/store/projectsSlice'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import rolesType from '../../constants/rolesType'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useGetOneProjectQuery,
	useGetPriorityQuery,
	useGetProjectParamsQuery,
	useGetUserProjectsQuery,
} from 'src/old-app/service/Projects'
import { useGetStatusQuery } from 'src/old-app/service/Tasks'
import { useGetUser } from 'src/shared/hooks'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import { CircularProgress, Skeleton } from '@mui/material'
import { ProjectShowContext } from 'src/entities/project'
import { publicConst } from 'src/shared/config'

const ProjectSkeleton = () => {
	return (
		<div className="tt-w-full tt-flex tt-flex-col tt-h-full">
			<div
				style={{ height: 113 }}
				className="tt-sticky tt-px-16 tt-border-b  tt-border-gray-200 tt-top-0 tt-pt-24 tt-bg-white z-[999]"
			>
				<div className="tt-flex tt-justify-between tt-min-h-[42px] tt-gap-16 tt-pb-10">
					<div className="tt-flex tt-gap-8 tt-flex-col">
						<div className="tt-flex tt-gap-8 tt-items-center">
							<div className="tt-flex tt-items-end tt-gap-12">
								<Skeleton variant="text" width={310} />
							</div>
						</div>
					</div>
					<div>
						<div className="tt-flex tt-items-center tt-gap-6">
							<Skeleton variant="rounded" width={120} />
							<Skeleton variant="rounded" width={180} />
							<Skeleton variant="rounded" width={30} />
						</div>
					</div>
				</div>
				<div className="tt-flex tt-items-center tt-gap-12 tt-pb-8">
					<Skeleton variant="rounded" width={180} />
					<Skeleton variant="rounded" width={180} />
					<Skeleton variant="rounded" width={180} />
					<Skeleton variant="rounded" width={180} />
				</div>
			</div>
			<div className="tt-px-16 tt-py-16 tt-overflow-auto tt-h-full table-scroll">
				<div className="tt-w-full tt-group tt-px-6 tt-py-4 tt-gap-6  tt-items-center tt-mb-8  tt-relative tt-h-[36px]  tt-rounded-lg ">
					<Skeleton height={24} variant="rounded" />
				</div>

				{[...Array(6).keys()].map((_, index) => (
					<div
						key={index}
						className="tt-w-full tt-group tt-px-6 tt-py-4 tt-gap-6 tt-mb-8 tt-items-center   tt-relative tt-h-[36px]  tt-rounded-lg  tt-border  tt-bg-gray-100/70 "
					>
						<Skeleton height={24} variant="rounded" />
					</div>
				))}
			</div>
		</div>
	)
}
const TaskSkeleton = () => {
	return (
		<div className="tt-w-full tt-flex-1 tt-flex tt-items-center tt-justify-center tt-min-h-full tt-p-24">
			<CircularProgress className="tt-text-primary-600" />
		</div>
	)
}
const TasksProvider = ({
	children,
	skeletonType = 'project',
	defaultProjectContext,
	forMyTask = false,
	isPublic = false,
}) => {
	const dispatch = useDispatch()
	const user = useGetUser()
	const isAdmin = useSelector(getAdminRole)
	const navigate = useNavigate()
	const params = useParams()
	const isUser = useSelector(getProjectUserRole)
	const isManager = useSelector(getProjectManagerRole)
	const isProjectAdminContractRole = useSelector(getProjectAdminContractRole)
	const isAdminProjectContractRole = useSelector(getAdminProjectContractRole)
	const isBA = useSelector(getProjectBARole)
	const [canCloseProject, setCanCloseProject] = useState(false)

	const projectRequestParams = forMyTask ? { for_my_task: true } : {}

	const {
		data: projectData,
		isLoading: projectIsLoading,
		error,
		isError,
	} = useGetOneProjectQuery({ id: params?.projectId, params: projectRequestParams })
	const projectId = projectData?.data?.id

	const { data: projectParamsData, isLoading: projectParamsLoading } = useGetProjectParamsQuery(projectId, {
		refetchOnMountOrArgChange: true,
		skip: !projectId,
	})
	const { data: statuses, isLoading: projectStatusLoading } = useGetStatusQuery(
		{ project_id: projectId, order_sort: 'asc', per_page: 100 },
		{
			skip: !projectId,
		}
	)
	const { data: priority, isLoading: projectPriorityLoading } = useGetPriorityQuery(
		{ project_id: projectId, level_sort: 'asc', per_page: 100 },
		{
			skip: !projectId,
		}
	)
	const { data: projectUsers, isLoading: projectUsersLoading } = useGetUserProjectsQuery(
		{
			current_page: 1,
			per_page: 100,
			project_id: projectId,
		},
		{
			skip: !projectId,
		}
	)

	const project = {
		...projectData?.data,
		projectParams: projectParamsData?.data,
		users: projectUsers?.data ?? [],
		child_projects_completed: projectData?.data?.child_projects_completed,
		isEnd: projectData?.data?.confirmation_status === 'COMPLETED',
	}
	const isContract = project?.type?.name === 'contract'
	const isCommittee = project?.type?.name === 'committee'

	const isMyProject = project?.id === user?.id
	const isOwnerProject = project?.owner?.id === user?.id

	const isManageProject = isContract
		? isAdmin || isAdminProjectContractRole || isProjectAdminContractRole
		: isMyProject || isAdmin || isOwnerProject || isManager || isBA
	const isEditProject = isContract
		? isAdmin || isAdminProjectContractRole || isProjectAdminContractRole
		: isMyProject || isAdmin || isOwnerProject || isManager || isBA
	const isCreateTask = isContract
		? isAdmin || isAdminProjectContractRole || isProjectAdminContractRole
		: isUser
		? project?.each_member_can_create_task
		: true
	const isShowCreateTaskButton = defaultProjectContext?.isEnd
		? false
		: project?.isEnd
		? false
		: isMyProject || isCreateTask
	useEffect(() => {
		if (projectData?.data) {
			const projectClosingUsersIDs = project?.closing_users?.map((closingUser) => {
				return closingUser?.user_id
			})
			setCanCloseProject(projectClosingUsersIDs.includes(user?.id) || isAdmin)
		}
	}, [projectData])

	useEffect(() => {
		if (isError) {
			if (isPublic) {
				return navigate(`/${publicConst}/404`)
			}
			navigate('/404')
		}
	}, [isError])

	useEffect(() => {
		if (projectData?.data && !projectParamsLoading && projectUsers?.data) {
			if (
				projectData?.data?.confirmation_status === 'REJECTED' ||
				projectData?.data?.confirmation_status === 'ON_CONFIRMATION'
			) {
				if (isPublic) {
					return navigate(`/${publicConst}/404`)
				}

				navigate('/404')
				return
			}
			// const teams = projectTeams?.data ?? []
			const me = projectUsers?.data.find((u) => u?.user?.id === user?.id)

			dispatch(
				projectsActions.getOneProject({
					...projectData.data,
					me,
					projectParams: projectParamsData?.data,
					users: projectUsers?.data ?? [],
					child_projects_completed: projectData?.data?.child_projects_completed,
					isEnd: defaultProjectContext?.isEnd || projectData?.data?.confirmation_status === 'COMPLETED',
					isShowCreateTaskButton,
					isManageProject,
					canCloseProject,
					isContract: projectData?.data?.type?.name === 'contract',
					project,
					isMyProject,
					isEditProject,
				})
			)
			return
		}
	}, [
		projectData,
		projectIsLoading,
		projectParamsLoading,
		projectUsers,
		isShowCreateTaskButton,
		isManageProject,
		canCloseProject,
		isContract,
		project,
		isMyProject,
		isEditProject,
	])

	useEffect(() => {
		if (priority?.data) {
			dispatch(projectsActions.getPrioritys(priority.data))
		}
		if (projectUsers?.data) {
			const me = projectUsers?.data.find((u) => u.user.id === user.id)

			dispatch(projectsActions.getProjectsUsers(projectUsers?.data))
			dispatch(projectsActions.getProjectRoles([isAdmin ? rolesType.ADMIN : me?.role ?? rolesType.USER]))
			dispatch(projectsActions.getPUsers(projectUsers?.data?.map((item) => item?.user)))
		}
		if (statuses?.data) {
			dispatch(tasksActions.getStatuses(statuses.data))
		}
	}, [priority, projectUsers, statuses])

	const isLoading =
		!projectData ||
		projectIsLoading ||
		projectParamsLoading ||
		projectStatusLoading ||
		projectUsersLoading ||
		projectPriorityLoading

	if (isLoading) return <>{skeletonType === 'project' ? <ProjectSkeleton /> : <TaskSkeleton />}</>

	return (
		<ProjectShowContext.Provider
			value={{
				isShowCreateTaskButton,
				isManageProject,
				isContract,
				isCommittee,
				project,
				isPublic,
				isMyProject,
				canCloseProject,
				isEditProject,
			}}
		>
			{children}
		</ProjectShowContext.Provider>
	)
}

export default TasksProvider
