import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

// type BaseParams = {
// 	field: string;
// 	sort: 'asc' | 'desc';
// 	page: number;
// 	per_page: number;
// };

const _baseParams = {
	page: withDefault(NumberParam, 1),
	per_page: withDefault(NumberParam, 15),
	field: StringParam,
	sort: withDefault(StringParam, 'asc'),
}

const useNewSortTable = ({ extraParams }: { extraParams?: object } = {}) => {
	if (extraParams) {
		Object.assign(_baseParams, extraParams)
	}

	const [query, setQuery] = useQueryParams(_baseParams)
	const { field, sort, page, per_page } = query

	const _pagesQuery = {
		current_page: page,
		per_page,
	}

	const getQuery = field ? { [field]: sort, ..._pagesQuery } : _pagesQuery

	return { getQuery, query, setQuery }
}
export default useNewSortTable
