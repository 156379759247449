import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface IProps {
	children: ReactNode
	error?: any
	label?: string
	className?: string
	labelClassName?: string
}

const InputContainer: React.FC<IProps> = (p) => {
	const { children, error, label, className, labelClassName } = p

	return (
		<div className={clsx(styles['input-container'], className)}>
			{label && <span className={clsx(styles['input-label'], labelClassName)}>{label}</span>}
			{children}
			<div className={styles['input-error']}>{error?.message}</div>
		</div>
	)
}

export default InputContainer
