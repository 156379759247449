import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetProjectContractDirectoryQuery } from 'src/old-app/service/Projects'
import UnTable from 'src/shared/components/un-table'
import { ContractTypeParams, getContractDirectoryParamsPrice } from '../../../../@entities/project'
import clsx from 'clsx'
import { formatter } from 'src/shared/lib/formatteer'
import TextFilter from '../../../../shared/components/filters/text-filter'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useTranslation } from 'react-i18next'

interface ProjectContractDirectoryTableProps {}

export const HeadItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
	return (
		<div
			className={clsx(
				'my-truncate-one tt-whitespace-pre-wrap tt-break-all tt-min-w-128 tt-text-14 tt-text-left tt-font-medium tt-text-gray-500',
				className
			)}
		>
			{' '}
			{children}
		</div>
	)
}

const Params: any = {
	name: StringParam,
}

export const ProjectContractDirectoryTable: React.FC<ProjectContractDirectoryTableProps> = (p) => {
	const {} = p
	const { t } = useTranslation()
	const [queryParams] = useQueryParams(Params, { includeAllParams: true })

	const columns: any[] = useMemo(
		() => [
			{
				id: 1,
				label: t('regulatory_works_category'),
				field: 'category',
				className: 'tt-truncate',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap tt-break-all tt-w-256  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{t(`contract_categories.${listItem?.category}`)}
					</p>
				),
			},
			{
				id: 2,
				label: t('regulatory_document_type'),
				field: 'type_regulatory_document',
				className: 'tt-flex-1',
				render: (listItem: any) => (
					<p className="my-truncate-one tt-whitespace-pre-wrap  tt-truncate tt-break-all tt-min-w-76  tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.name}
					</p>
				),
			},
			{
				id: 3,
				label: t('regulatory_document_parameters'),
				field: 'parameters_regulatory_document',
				className: '',
				render: (listItem: any) => (
					<div className="tt-min-w-320">
						{listItem?.params?.length !== 0 && <ContractTypeParams params={listItem.params} />}
					</div>
				),
			},
			{
				id: 4,
				label: t('price_rub'),
				field: 'pricing',
				className: '',
				render: (listItem: any) => (
					<p className=" tt-whitespace-pre-wrap tt-break-all !tt-min-w-208 tt-text-14 tt-text-left tt-font-medium tt-text-black">
						{listItem?.price && listItem?.params?.length === 0 ? (
							<>₽ {formatter(listItem?.price)}</>
						) : (
							<>{getContractDirectoryParamsPrice(listItem.params as any[])}</>
						)}
					</p>
				),
			},
		],
		[]
	)

	const heads: any[] = useMemo(
		() => [
			{
				id: 1,
				label: t('regulatory_works_category'),
				field: 'category',
				className: 'tt-truncate',
				render: () => <HeadItem className="tt-w-256">{t('regulatory_works_category')}</HeadItem>,
			},
			{
				id: 2,
				label: t('regulatory_document_type'),
				field: 'type_regulatory_document',
				className: 'tt-flex-1',
				render: () => <TextFilter placeholder={t('regulatory_document_type')} />,
			},
			{
				id: 3,
				label: t('regulatory_document_parameters'),
				field: 'parameters_regulatory_document',
				className: '',
				render: () => <HeadItem className="!tt-min-w-320">{t('regulatory_document_parameters')}</HeadItem>,
			},
			{
				id: 4,
				label: t('price_rub'),
				field: 'pricing',
				className: '',
				render: () => <HeadItem className="!tt-min-w-208">{t('price_rub')}</HeadItem>,
			},
		],
		[]
	)

	const defaultPerPage = 50
	const { search } = useLocation()
	const [perPage, setPerPage] = useState(defaultPerPage)
	const { data, isLoading } = useGetProjectContractDirectoryQuery({
		per_page: perPage,
		page: 1,
		created_at_sort: 'desc',
		name: queryParams?.name,
	})
	const link = (id: Id) => `c/${id}${search}`
	const list = data?.data ?? []
	const meta = data?.meta

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	return (
		<div>
			<UnTable
				tableClassName="!tt-min-w-[1100px]"
				loadMore={loadMore}
				meta={meta}
				isLoading={isLoading}
				emptyPlaceholder={t('empty_catalog')}
				link={link}
				heads={heads}
				list={list}
				columns={columns}
			/>
		</div>
	)
}
