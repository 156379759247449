import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { TextareaAutosize } from '@mui/base'
import { useLocation, useNavigate } from 'react-router-dom'
import { getNotif, notifsActions } from 'src/old-app/store/notifSlice'
import { useDeclineProjectMutation } from 'src/old-app/service/Projects'
import { notifTypes } from 'src/old-app/constants/notifications'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { useDeleteOneNotifMutation } from 'src/old-app/service/Notif'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { useDeclineTaskDeadlineMutation, useDeclineTaskMutation } from 'src/old-app/service/Tasks'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import ErrorText from '../../ui/ErrorText'
import { useTranslation } from 'react-i18next'

const CancelNotif = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		comment: yup.string().required(t('comment_is_required')),
	})
	const navigate = useNavigate()
	const { search } = useLocation()
	const dispatch = useDispatch()
	const notif = useSelector(getNotif)

	const [declineProject] = useDeclineProjectMutation()
	const [declineTaskDeadline] = useDeclineTaskDeadlineMutation()
	const [declineTask] = useDeclineTaskMutation()
	const [deleteNotif, deleteResult] = useDeleteOneNotifMutation()

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const deleteApprovedNotifHandler = async (id, message) => {
		try {
			await deleteNotif(id)
			dispatch(
				showMessage({
					message,
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)

			dispatch(notifsActions.deleteNotif(id))
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(dialogActions.setDialog(false))

			navigate('/notifications', { search })
		} catch (error) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const cancelHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			switch (notif.type) {
				case notifTypes.CONFIRMATION_OF_THE_REPORT:
					await declineTask({ id: notif.task_id, body: data })
					deleteApprovedNotifHandler(notif.id, t('rejected'))
					break
				case notifTypes.CREATE_PROJECT:
					await declineProject({ id: notif.project_id, body: data })
					deleteApprovedNotifHandler(notif.id, t('rejected'))
					break
				case notifTypes.UPDATING_TASK_DEADLINE:
					await declineTaskDeadline({ ...data, task_id: notif.task_id })
					deleteApprovedNotifHandler(notif.id, t('rejected'))
					break

				default:
					dispatch(interfaceActions.setIsLoading(false))
					break
			}
		} catch (error) {
			dispatch(interfaceActions.setIsLoading(false))
			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(cancelHandler)} className="tt-w-600">
			<DialogHeader title={t('cancel_with_comment')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-gap-16">
					<div className="tt-flex flex-col tt-w-full">
						<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('comment')}</span>
						<TextareaAutosize
							minRows={3}
							maxRows={10}
							{...register('comment')}
							error={!!errors.comment}
							placeholder={t('comment')}
							size="small"
							aria-label="minimum height"
							className="tt-border tt-border-gray-300 tt-p-12  tt-rounded-lg"
						/>
						<ErrorText errors={errors} field="comment" />
					</div>
				</div>
			</DialogMain>
			<DialogFooter rightLabel={t('send')} />
		</form>
	)
}

export default CancelNotif
