import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Badge, Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { TeamProjects } from '../../../../entities/team/components'
import TeamProjectRequests from '../../../../entities/team/components/team-project-requests'
import { ETeamRole } from '../../../../entities/team/components/team-provider'
import TeamSettings from '../../../../entities/team/components/team-settings'
import { useTeamShow } from '../../../../entities/team/hooks'
import { AllMyTasksList } from '../../../../old-app/main/my/AllMyTasksList'
import { useGetUser } from '../../../../shared/hooks'
import { Button } from '../../../../shared/components'
import { useGetOnConfirmationTeamProjectsCountQuery } from 'src/old-app/service/Projects'
import { useTranslation } from 'react-i18next'
import { useProjects } from 'src/entities/project'

interface IProps {
	page: string
}

const defaultTab = 'tasks'

const TeamContent: React.FC<IProps> = ({ page }) => {
	const { t } = useTranslation()
	const { typeSlug, taskId } = useParams()
	const { search } = useLocation()
	const navigate = useNavigate()
	const user = useGetUser()
	const teamShow = useTeamShow()
	const { team } = teamShow
	const dispatch = useDispatch()

	//запрос счетчика проектов на подтверждение
	const { data: onConfirmationTeamProjectsCountData } = useGetOnConfirmationTeamProjectsCountQuery(
		{ command_id: team.id },
		{
			pollingInterval: 10000,
		}
	)

	const { projects } = useProjects({ params: { per_page: 500, page: 1, command_id: team?.id }})

	const onConfirmationTeamProjectsCount = onConfirmationTeamProjectsCountData?.data ?? 0
	const viewOnConfirmationTeamProjectsCount =
		onConfirmationTeamProjectsCount > 99 ? '+99' : onConfirmationTeamProjectsCount

	const [value, setValue] = React.useState(typeSlug ?? defaultTab)

	const currentPage = `${page}/${value}`

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue)
		const baseTo = `${page}/${newValue}`

		let to
		if (newValue === 'list' || newValue === 'board') {
			to = taskId ? baseTo + `/${taskId}` + search : baseTo + search
		} else {
			to = taskId ? baseTo + `/${taskId}` : baseTo
		}
		navigate(to)
	}

	const tabs: any = useMemo(() => {
		const items: any[] = [
			{
				id: 1,
				label: t('tasks'),
				icon: <TaskAltIcon className="tt-w-[22px] tt-h-[22px]" />,
				slug: 'tasks',
				children: (
					<AllMyTasksList
						isTeamWorkspace={true}
						isMyWorkspace={false}
						statusSlot={undefined}
						scroll="#project-content"
						hideAssigneId
						id={undefined}
						taskParams={{ command_ids: team?.id, project_ids: projects?.map((project:any) => project.id) ?? [] }}
						right={undefined}
						hideSkelet={{ author: true, assignee: false, complete_date: true }}
						isRightFilterLabel={undefined}
						page={undefined}
					/>
				),
				isShow: true,
			},
			{
				id: 2,
				label: t('projects'),
				icon: <AccountTreeOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				slug: 'projects',
				children: <TeamProjects curPage={`${page}/${typeSlug}`} />,
				isShow: true,
				buttonComponent: {
					label: t('create_new.project'),
					component: 'project-team-create',
					isShow: true,
				},
			},
			{
				id: 3,
				label: (
					<>
						<span>{t('projects_confirmation')}</span>
						<Badge
							classes={{ badge: 'tt-bg-primary tt-text-white' }}
							badgeContent={viewOnConfirmationTeamProjectsCount}
							className="tt-absolute tt-left-10 tt-top-10 tt-text-center"
						/>
					</>
				),
				icon: <RuleOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				slug: 'project-requests',
				children: <TeamProjectRequests curPage={`${page}/${typeSlug}`} />,
				isShow: teamShow?.curUserRole === ETeamRole?.ADMIN || teamShow?.curUserRole === ETeamRole?.CONFIRM_USER,
			},
			{
				id: 3,
				label: t('settings'),
				icon: <SettingsOutlinedIcon className="tt-w-[22px] tt-h-[22px]" />,
				slug: 'settings',
				children: <TeamSettings />,
				isShow: true,
			},
		]
		return items.filter((item) => item.isShow)
	}, [teamShow, viewOnConfirmationTeamProjectsCount,projects])

	useEffect(() => {
		if (typeSlug) {
			setValue(typeSlug)
		}
	}, [typeSlug])

	const openDialog = (components: string) => {
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent(components))
		dispatch(dialogActions.setDialogState({ teamId: team.id }))
	}

	const currentButton = tabs.find((tab: any) => tab.slug === typeSlug)

	return (
		<div className="tt-w-full tt-flex tt-flex-col tt-h-full">
			<div className="tt-sticky tt-px-16 tt-border-b tt-border-gray-200 tt-top-0 tt-pt-24 tt-bg-white z-[999]">
				<div className="tt-flex tt-justify-between tt-min-h-[42px] tt-gap-16 tt-pb-10">
					<div className="tt-flex tt-gap-8 tt-flex-col">
						<div className="tt-flex tt-gap-8 tt-items-center">
							<div className="my-truncate tt-text-2xl tt-font-semibold  tt-flex-1 tt-max-w-full  tt-w-full">
								{t('working_space')} - {team?.name}
							</div>
						</div>
					</div>
					<div>
						<div className="tt-flex tt-items-center tt-gap-6">
							{currentButton?.buttonComponent?.isShow && (
								<Button
									disabled={currentButton?.buttonComponent?.isDisabled}
									onClick={() => openDialog(currentButton?.buttonComponent?.component)}
								>
									{currentButton?.buttonComponent?.label}
								</Button>
							)}
						</div>
					</div>
				</div>

				<Box sx={{ width: '100%', minHeight: 'auto' }}>
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						value={value}
						classes={{
							flexContainer: 'tt-flex tt-gap-18',
						}}
						sx={{ minHeight: 'auto' }}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						{tabs?.map((item: any) => (
							<Tab
								sx={{ minHeight: 'auto', padding: '0.8rem 0 0.8rem 1rem' }}
								iconPosition="start"
								icon={item.icon}
								label={item.label}
								value={item.slug}
							/>
						))}
					</Tabs>
				</Box>
			</div>

			<Routes>
				{tabs?.map((item: any) => (
					<Route
						path={item.slug + '/*'}
						element={
							<div
								id="project-content"
								className={['tt-px-16 tt-py-16 tt-overflow-auto tt-h-full', item.className].join(' ')}
							>
								{item.children}
							</div>
						}
					/>
				))}
				<Route path="/*" element={<Navigate to={currentPage} />} />
			</Routes>
		</div>
	)
}

export default TeamContent
