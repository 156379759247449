import React, { memo } from 'react'
import moment from 'moment'
import { useGetOneTaskQuery, useGetTaskDeadlineQuery } from 'src/old-app/service/Tasks'
import GroupIcon from '@mui/icons-material/Group'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ProtocolAssignee from './ProtocolAssignee'
import FieldContent from '../../shared/FieldContent'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import getUser from 'src/old-app/utils/getUser'
import UserCell from '../../tables/UserCell'
import { useSelector } from 'react-redux'
import { getPriorities } from 'src/old-app/store/tasksSlice'
import TaskPriority from '../../task/TaskPriority'
import { Link, useLocation } from 'react-router-dom'
import TaskPriorityShow from '../../task/TaskPriorityShow'
import { useTranslation } from 'react-i18next'

const ProtocolTaskShow = memo(({ task, index }) => {
	const { t } = useTranslation()
	const { search } = useLocation()
	const { title, actual_deadline, assignee_id: assigneeId, priority, project } = task
	const deadline = actual_deadline?.deadline ? actual_deadline?.deadline : null

	return (
		<div>
			<div className="tt-flex tt-gap-10 tt-w-full">
				<div className="tt-bg-gray-200 tt-p-10 tt-w-16 tt-h-16 tt-flex tt-items-center tt-justify-center tt-text-gray-800 tt-rounded-full">
					{index}
				</div>
				<div className="tt-flex tt-flex-col tt-gap-3 tt-w-full">
					<div className="tt-flex tt-flex-col">
						<div className="tt-font-medium tt-text-xs -tt-mt-12 tt-text-gray-500">{t('task')}</div>
						<Link to={`/protocol/${task.protocol_id}/${task.project_id}/${task?.id}${search}`}>
							<div dangerouslySetInnerHTML={{ __html: title }} className="tt-font-medium tt-anywhere" />
						</Link>
					</div>
					<div className=" tt-text-gray-800 tt-flex tt-gap-24 tt-flex-wrap tt-items-center tt-w-full">
						<FieldContent
							className="tt-flex-1"
							content={task?.assignee_id && <UserCell id={task?.assignee_id} />}
							icon={<GroupOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
							label={t('participant')}
						/>
						{deadline && (
							<FieldContent
								className="tt-flex-1"
								content={<div className="tt-text-gray-800"> {moment(deadline).format('LL')}</div>}
								icon={<CalendarMonthOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('deadline')}
							/>
						)}

						{priority && (
							<FieldContent
								className="tt-flex-1"
								content={<TaskPriorityShow priority={priority} />}
								label={t('priority')}
							/>
						)}
						{project?.name && (
							<FieldContent
								className="tt-flex-1"
								content={
									<Link to={`/projects/${project?.id}`}>
										<span>{project?.name}</span>
									</Link>
								}
								icon={<CalendarMonthOutlinedIcon className="tt-fill-gray-600 tt-w-20 tt-h-20" />}
								label={t('project')}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
})

ProtocolTaskShow.displayName = 'ProtocolTaskShow'
export default ProtocolTaskShow
