import Profile from './Profile'

const ProfileConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'Profile',
			element: <Profile />,
		},
	],
}

export default ProfileConfig
