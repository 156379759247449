/* eslint-disable no-plusplus */
import { Button } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompleteFiles, getFiles, getTask } from 'src/old-app/store/tasksSlice'
import Tabs from '../ui/Tabs'
import AddFileButton from './AddFileButton'
import FilesTable from './FilesTable'
import { useDeleteCompleteFileMutation } from 'src/old-app/service/Tasks'

const items = [
	{ id: 1, label: 'Сначала новые', slug: 'DESC' },
	{ id: 2, label: 'Сначала старые', slug: 'ASC' },
]

const paramsKey = 'file_sort'

const CompleteFiles = ({ id }) => {
	const dispatch = useDispatch()
	const task = useSelector(getTask)

	const [searchParams, setSearchParams] = useSearchParams()
	const taskTab = searchParams.get(paramsKey) ?? items[0].slug
	const [value, setValue] = useState(taskTab)

	useEffect(() => {
		setValue(taskTab)
	}, [taskTab])

	const [deleteCompleteFile] = useDeleteCompleteFileMutation()

	const deleteFileHandler = (fileId) => {
		const data = { file_id: fileId, task_id: task.id }
		deleteCompleteFile(data)
	}

	const hideFileDelete = task?.project?.isEnd ? true : !task?.isMyTask

	return (
		<div className="tt-w-full">
			<div className="tt-mt-16">
				<FilesTable
					id={id}
					files={task?.complete_file ?? []}
					deleteFile={deleteFileHandler}
					hideFileDelete={hideFileDelete}
				/>
			</div>
		</div>
	)
}

export default CompleteFiles
