import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { getTask, getTasks, getTimeSpentInputId } from 'src/old-app/store/tasksSlice'
import { useCreateTimeTrackInputMutation, useUpdateTimeTrackInputMutation } from 'src/old-app/service/Tasks'
import ErrorText from '../../ui/ErrorText'
import { useTranslation } from 'react-i18next'
import DateTimePickerInput from 'src/shared/components/date-time-picker-input/DateTimePickerInput'
import dayjs from 'dayjs'

const SpentTimeInput = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		start: yup.date().required(t('working_start_time_is_required')).typeError(t('invalid_date')),
		stop: yup.date().required(t('working_finish_time_is_required')).typeError(t('invalid_date')),
	})
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const timeSpentInputId = useSelector(getTimeSpentInputId)

	const [createTimeTrackInput] = useCreateTimeTrackInputMutation()
	const [updateTimeTrackInput] = useUpdateTimeTrackInputMutation()

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const createProjectHandler = async (data) => {
		if (dayjs(data.stop).isBefore(data.start)) {
			return setError('stop', { message: t('finish_time_less_start_time') })
		}
		dispatch(interfaceActions.setIsLoading(true))

		if (!timeSpentInputId) {
			await createTimeTrackInput({
				task_id: task.id,
				start: new Date(data.start),
				stop: new Date(data.stop),
			})
		} else {
			await updateTimeTrackInput({
				id: timeSpentInputId,
				body: {
					start: new Date(data.start),
					stop: new Date(data.stop),
				},
			})
		}

		dispatch(dialogActions.setDialog(false))
		dispatch(
			showMessage({
				message: t('spent_time_indicated'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('time_spent')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10 ">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-flex-col tt-gap-4 tt-w-full">
							<span className="tt-text-14  tt-mb-4">{t('start_time')}</span>
							<Controller
								render={({ field: { value, onChange } }) => <DateTimePickerInput value={value} onChange={onChange} />}
								name="start"
								control={control}
							/>
							<ErrorText errors={errors} field="start" />
						</div>
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('finish_time')}</span>
							<Controller
								render={({ field: { value, onChange } }) => <DateTimePickerInput value={value} onChange={onChange} />}
								name="stop"
								control={control}
							/>
							<ErrorText errors={errors} field="stop" />
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={false} rightLabel={t('save')} />
		</form>
	)
}

export default SpentTimeInput
